import styled from "styled-components";

export const ContainerToggleVisualizacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    font-size: 0.875rem;
  }
`

export const TooltipContentContainer = styled.div`
  background-color: #FFF;
  padding: 0.25rem;
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
`;
