import { CalcularDebitos } from "components/CalcularDebitos";
import { LoadingCirculo } from "components/LoadingCirculo";
import { ModalCustom } from "components/ModalCustom";
import { useEffect } from "react";
import { LoadingContainer } from "../MensagemDebitos/style";
import { IModalSolicitarPagamento } from "./interface/IModalSolicitarPagamento";

export function ModalSolicitarPagamento({
  show,
  handleClose,
  todos,
  onConfirm,
  loadingPagamento,
  total,
  buscarValorTodosDebitos,
  loading,
  quantidadeDebitos,
  quantidadeDebitosForaDeSla,
  handleCheck,
}: IModalSolicitarPagamento) {
  useEffect(() => {
    todos && buscarValorTodosDebitos();
  }, [todos, buscarValorTodosDebitos]);

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      size="lg"
      title="Solicitar Pagamento"
      centered={true}
      footer={!(loading || loadingPagamento)}
      footerConfirmButton={quantidadeDebitos > 0}
      footerCloseButton={quantidadeDebitos === 0}
      onConfirm={onConfirm}
    >
      {loadingPagamento || loading ? (
        <LoadingContainer>
          <LoadingCirculo mostrar={loadingPagamento || loading} />
        </LoadingContainer>
      ) : (
        <CalcularDebitos
          total={total}
          linhasSelecionadas={quantidadeDebitos}
          foraDeSla={quantidadeDebitosForaDeSla}
          handleCheck={handleCheck}
          frase={
            todos
              ? `Deseja solicitar o pagamento de todos os débitos?`
              : `Deseja solicitar o pagamento dos débitos selecionados?`
          }
        />
      )}
    </ModalCustom>
  );
}

