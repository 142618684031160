import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const IconeContainer = styled.div`
	padding: 0.75rem;
  background-color: ${({ theme }) => theme.colors.success.bgColor};
  border-radius: 0.5rem;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		color: ${({ theme }) => theme.colors.success.main};
	}
`;
