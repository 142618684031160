import { StatusAdiantamentoEnum } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";

export function handleDisabled(statusAdiantamento: string, formaPgto: string) {
	const naoPodeInformarAdiantamento =
		formaPgto !== FormaPgtoEnum.TRANSFERENCIA ||
		(
			statusAdiantamento !== StatusAdiantamentoEnum.PAGAMENTO_INCOMPLETO &&
			statusAdiantamento !== StatusAdiantamentoEnum.PENDENTE &&
			statusAdiantamento === StatusAdiantamentoEnum.PAGO
		)

	const naoPodeAlterarFormaDePagamento =
		statusAdiantamento === StatusAdiantamentoEnum.AGUARDANDO_VALIDACAO ||
		statusAdiantamento === StatusAdiantamentoEnum.PAGO;

	const buttonDisabled = {
		informarAdiantamento: naoPodeInformarAdiantamento,
		alterarFormaDePagamento: naoPodeAlterarFormaDePagamento,
	};

	return buttonDisabled;
}
