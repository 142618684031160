import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .sucesso {
    color: ${({ theme }) => theme.colors.project.main};
  }

  .erro {
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;

interface PropsContainer {
  statusDebito: string;
}

export const ContainerDiv = styled.button<PropsContainer>`
  border-radius: 15px;
  width: 160px;
  height: 24px;
  text-align: center;

  ${({ statusDebito, theme }) =>
    statusDebito === "processando" &&
    `
    background-color: transparent;
    border: solid 1px #7F7F7F;
    color: #7F7F7F;
  `}

  ${({ statusDebito }) =>
    statusDebito === "sucesso" &&
    `
    background-color: #E5FFF8;
    border: solid 1px #10AC85;
    color: #10AC85;
  `}


${({ statusDebito }) =>
    statusDebito === "falha" &&
    `
    background-color: #FFE4E4;
    border: solid 1px #EE5353;
    color: #EE5353;
  `}

  &:hover {
    cursor: default;
  }
`;
