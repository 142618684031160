import { CompositeContainer } from "components/CompositeContainer";
import { ISolicitacaoLicenciamento, Tabela } from "./containers/Tabela";
import { StringUtils } from "_utils/String.utils";
interface Props {
  loading: boolean;
  dados: ISolicitacaoLicenciamento[];
  totalRows: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (limit: number) => void;
  rowsPerPage: number;
  pagina?: "consulta" | "emissão";
}

export function ListagemSolicitacoesLicenciamento({
  dados,
  loading,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage,
  totalRows,
  pagina = "emissão",
}: Readonly<Props>) {
  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo
          text={`Listagem - ${StringUtils.primeiraLetraMaiuscula(
            pagina
          )} de Licenciamento`}
        />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <Tabela
          loading={loading}
          dados={dados}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          pagina={pagina}
        />
      </CompositeContainer.Body>
    </CompositeContainer.Root>
  );
}

