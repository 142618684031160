import { Loading } from "components/Loading";
import Page401 from "features/core/pages/401";
import { ContextPermissao } from "hooks/ContextPermissao";
import { useContext, useState } from "react";
import TemaInspinia from "_config/temas/Padrao";

const PrivateRoute = ({ component: Component }: any) => {
  const { verificarPermissao } = useContext(ContextPermissao);

  const [permissao, setPermissao] = useState(false);
  const [esperar, setEsperar] = useState(true);

  setTimeout(() => {
    setEsperar(false);
  }, 10000);

  const verificarPermissaoUsuario = async () => {
    const permissaoUsuario = await verificarPermissao();
    setEsperar(false);
    setPermissao(permissaoUsuario);
  };

  verificarPermissaoUsuario();

  if (!permissao && esperar) {
    return <Loading show />;
  }
  if (!permissao) {
    return <Page401 />;
  }

  return (
    <TemaInspinia>
      <Component />
    </TemaInspinia>
  );
};

export default PrivateRoute;
