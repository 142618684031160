import { IconeNaoConsultado } from "../IconeNaoConsultado";
import { Section } from "../Section";

export function DadosLeiloesNacionalSL() {
  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={IconeNaoConsultado} />
        <Section.Titulo titulo="Leilão" />
      </Section.Header>
      <Section.Body>
        <Section.Row>
          <Section.Col>
            <span>Este item não está incluso no tipo de consulta solicitado.</span>
          </Section.Col>
        </Section.Row>
      </Section.Body>
    </Section.Root>
  );
}

