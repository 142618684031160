import styled from "styled-components";

export const Container = styled.div`
  padding-top: 1rem;
  padding-left: 2.75rem;
  display: grid;
  gap: 1rem;
`;

export const DadosLicenciamentoContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

export const DadosLicenciamentoBox = styled.div`
font-size: 14px;
`;

export const DadosLicenciamentoTitle = styled.strong``;

export const DadosLicenciamentoText = styled.p`
  margin-top: 0.5rem;
`;

export const AtencaoContainer = styled.div``;