export const statusPrazoPagamento = [
  {
    value: "vencidos",
    label: "Vencidos"
  },
  {
    value: "aVencer",
    label: "À vencer"
  },
];
