import { Informacao } from "components/Informacao";

interface Props {
  nomeArquivo: string;
}

export function BodyTotal({ nomeArquivo }: Readonly<Props>) {
  const mensagemUnitaria = `Essa placa já foi consultada nas últimas 24 horas. `;
  const mensagemLote = `As placas deste lote já foram consultadas nas últimas 24 horas. `;
  return (
    <Informacao
      mensagem={
        <span>
          {nomeArquivo === "unitario" ? mensagemUnitaria : mensagemLote}
          <strong>Deseja prosseguir com a consulta?</strong>
        </span>
      }
      showBotaoFechar={false}
      type="atencao"
      showLabel={false}
    />
  );
}
