import { yupResolver } from "@hookform/resolvers/yup";
import { FilterButton } from "components/Button/style";
import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect } from "components/DropdownSelect";
import { IFiltrosDetalhesConsultas } from "features/relatorios/consultas/interfaces/FiltrosDetalhesConsultas";
import { filtrosDetalhesRelatorioConsultaScheme } from "features/relatorios/consultas/validate/validator";
import { InputContainer } from "features/relatorios/pagamento/pages/relatorio/containers/FiltrosRelatorioPagamentos/style";
import { useMaskInput } from "hooks/useMaskInput";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter } from "react-icons/fa";
import { ButtonContainer } from "../../../RelatorioConsultas/containers/FiltrosRelatorioConsultas/style";
import {
  STATUS_SOLICITACAO_PAGAMENTO_OPTIONS,
  TIPO_DEBITOS_OPTIONS,
  VENCIMENTO_OPTIONS,
} from "../../constants/options";
import { MaisFiltros } from "components/MaisFiltros";
import { useMaisFiltros } from "hooks/useMaisFiltros";

interface Props {
  handleSubmitFiltros: (data: any) => void;
}

export function FiltrosDetalhesRelatorioConsultas({
  handleSubmitFiltros,
}: Props) {
  const {
    handleAplicarMaisFiltros,
    handleFormSubmit,
    handleNaoUsarMaisFiltros,
  } = useMaisFiltros();

  const { mInputPlaca } = useMaskInput();

  const { handleSubmit, control, reset } = useForm<IFiltrosDetalhesConsultas>({
    resolver: yupResolver(filtrosDetalhesRelatorioConsultaScheme),
  });

  return (
    <Container titulo="Filtros">
      <Form
        noValidate
        onSubmit={handleSubmit((dados) => {
          handleFormSubmit({
            dados,
            excluir: ["renavam"],
            resetCallback: reset,
            submitCallback: handleSubmitFiltros,
          });
        })}
      >
        <Row>
          <InputContainer lg="2">
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    value={value!}
                    placeholder="Digite a placa"
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="statusPagamento"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={STATUS_SOLICITACAO_PAGAMENTO_OPTIONS}
                    title="Status da consulta"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="idTipoDebito"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={TIPO_DEBITOS_OPTIONS}
                    title="Tipo de Débito"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="vencimento"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={VENCIMENTO_OPTIONS}
                    title="Vencimento"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <ButtonContainer>
              <FilterButton type="submit" disabled={false} loading={false}>
                <FaFilter /> Filtrar
              </FilterButton>
              <FilterButton
                type="reset"
                onClick={() => {
                  reset({
                    idTipoDebito: null,
                    placa: null,
                    renavam: null,
                    statusPagamento: null,
                    vencimento: null,
                  });
                }}
                disabled={false}
                loading={false}
                outline={true}
              >
                Limpar
              </FilterButton>
            </ButtonContainer>
          </Col>
          <Col lg="2">
            <MaisFiltros
              cancelarOnClick={() => {
                handleNaoUsarMaisFiltros({
                  excluir: ["renavam"],
                  resetCallback: reset,
                });
              }}
              handleClickAplicarFiltros={handleAplicarMaisFiltros}
              limparTudoOnClick={() => {
                handleNaoUsarMaisFiltros({
                  excluir: ["renavam"],
                  resetCallback: reset,
                });
              }}
            >
              <Form.Group>
                <Form.Label>Renavam</Form.Label>
                <Controller
                  control={control}
                  name="renavam"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      value={value ?? ""}
                      placeholder="Digite o renavam"
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </MaisFiltros>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

