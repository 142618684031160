import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container, IconeContainer, Texto, TextoContainer } from "./style";

interface IModalConfirmacaoSolicitacao {
  show: boolean;
  handleClose: () => void;
}

export function ModalConfirmacaoEmissaoManual({
  show,
  handleClose,
}: IModalConfirmacaoSolicitacao) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Informar emissão manual"
      centered={true}
    >
      <Container>
        <IconeContainer>
          <FaCheck />
        </IconeContainer>
        <TextoContainer>
          <Texto>Emissão manual confirmada com sucesso!</Texto>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}

