import { Form } from "react-bootstrap";
import { DropdownSelect } from "components/DropdownSelect";
import FileUpload from "components/UploadArquivos";
import { PrimaryButton } from "components/Button/style";
import { FaDownload } from "react-icons/fa";
import { useEffect, useState } from "react";
import { CustomInput } from "components/CustomInput";
import { ButtonsContainer, Container, FormRow, InputCol, InputContainer } from "./style";
import { useMaskInput } from "hooks/useMaskInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  solicitarDownloadArquivosUnitarioResolver,
  solicitarDownloadArquivosEmLoteResolver,
} from "../../../../validator";
import {
  BodyOption,
  IBodySolicitarDownload,
} from "features/arquivos/interfaces";
import { Required } from "components/Required";
import { useRenomearArquivos } from "hooks/useRenomearArquivos";

interface Props {
  body: BodyOption;
  solicitarDownload: (body: IBodySolicitarDownload) => Promise<any>;
}

interface IInputValues {
  placa: NullableString;
  tipoImagem: NullableString;
  renomearPara: NullableString;
  arquivo: File | null;
}

const worker = new Worker(
  new URL(
    "../../../../../../_workers/PlanilhaDownloadArquivosWorker.js",
    import.meta.url
  ),
  {
    type: "module",
  }
);

export function SolicitarDownloadBody({ body, solicitarDownload }: Props) {
  const { mInputPlaca } = useMaskInput();
  const [tipoArquivo, setTipoArquivo] = useState(null);
  const [limparArquivo, setLimparArquivo] = useState(false);
  const finalMensagem = body === "lote" ? "em lote" : "individual";
  const { optionsRenomear } = useRenomearArquivos();

  function handleDownloadModelo() {
    const link = document.createElement("a");
    link.download = "Modelo_Baixar_Arquivos.xlsx";
    link.href = "/files/Modelo_Baixar_Arquivos.xlsx";
    link.click();
  }

  const schemaStrategy = {
    individual: solicitarDownloadArquivosUnitarioResolver,
    lote: solicitarDownloadArquivosEmLoteResolver,
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    reset,
  } = useForm<IInputValues>({
    resolver: yupResolver(schemaStrategy[body]),
  });

  useEffect(() => {
    reset();
  }, [body, reset])

  function handleFormSubmit({
    arquivo,
    placa,
    renomearPara,
    tipoImagem,
  }: IInputValues) {
    if (arquivo) {
      submitArquivo(arquivo, tipoImagem!, renomearPara!);
      resetInputs();
      setLimparArquivo(false);
      return;
    }
    submitPlaca(placa, tipoImagem!, renomearPara!);
    resetInputs();
    setLimparArquivo(false);
  }

  async function submitArquivo(
    arquivo: any,
    tipoImagem: string,
    renomearPara: string
  ) {
    worker.postMessage({
      type: "arquivo",
      arquivo,
    });

    worker.onmessage = async ({ data }: MessageEvent<any>) => {
      const { type, dados } = data;
      if (type === "dados") {
        solicitarDownload({
          placas: dados,
          tipoImagem,
          renomearPara,
        });
      }
    };
  }

  async function submitPlaca(
    placa: any,
    tipoImagem: string,
    renomearPara: string
  ) {
    const placaReplace = placa.replace(/-/g, "");

    solicitarDownload({
      placas: [placaReplace],
      tipoImagem,
      renomearPara,
    });
  }

  function resetInputs() {
    reset({
      arquivo: null,
      placa: "",
      renomearPara: null,
      tipoImagem: null,
    });
    setTipoArquivo(null);
    setLimparArquivo(true);
  }

  return (
    <Container>
      <span>
        Insira os dados nos campos abaixo para realizar o download{" "}
        {finalMensagem}
      </span>
      <Form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
        <FormRow>
          <InputCol lg="2">
            <Controller
              control={control}
              name="tipoImagem"
              render={({ field: { onChange, value } }) => (
                <DropdownSelect
                  required={true}
                  options={[
                    {
                      label: "Licenciamento",
                      value: "CRLV",
                    },
                  ]}
                  title="Tipo de arquivo"
                  selectedValue={value}
                  onSelect={(e) => {
                    onChange(e);
                    setTipoArquivo(e);
                  }}
                  mensagemErro={isSubmitted ? errors.tipoImagem?.message : null}
                />
              )}
            />
          </InputCol>
          <InputCol lg="3">
            {body === "lote" ? (
              <InputContainer>
                <span>
                  Arquivo <Required />
                </span>
                <Controller
                  control={control}
                  name="arquivo"
                  render={({ field: { onChange } }) => (
                    <FileUpload
                      onFileUpload={(file) => {
                        onChange(file);
                      }}
                      mensagemErro={errors.arquivo?.message}
                      limparArquivo={limparArquivo}
                    />
                  )}
                />
              </InputContainer>
            ) : (
              <InputContainer>
                <label>
                  Placa <Required />
                </label>
                <Controller
                  control={control}
                  name="placa"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      placeholder="Digite a placa"
                      onChange={(e) => {
                        onChange(mInputPlaca(e));
                      }}
                      value={value!}
                      isValid={isSubmitted && !errors?.placa}
                      isInvalid={!!errors?.placa}
                      mensagemErro={errors.placa?.message}
                    />
                  )}
                />
              </InputContainer>
            )}
          </InputCol>
          {tipoArquivo === "CRLV" && (
            <InputCol lg="2">
              <Controller
                control={control}
                name="renomearPara"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    required={true}
                    title="Renomear para"
                    options={optionsRenomear}
                    selectedValue={value}
                    onSelect={(e) => {
                      onChange(e);
                    }}
                    mensagemErro={
                      isSubmitted ? errors.renomearPara?.message : null
                    }
                  />
                )}
              />
            </InputCol>
          )}
          <InputCol lg="3">
            <ButtonsContainer>
              {body === "lote" && (
                <PrimaryButton
                  type="button"
                  onClick={handleDownloadModelo}
                  buttonWidth="sm"
                  outline
                  variante="primary"
                >
                  Baixar modelo
                  <FaDownload />
                </PrimaryButton>
              )}
              <PrimaryButton type="submit" buttonWidth="xs" variante="primary">
                Buscar
              </PrimaryButton>
            </ButtonsContainer>
          </InputCol>
        </FormRow>
      </Form>
    </Container>
  );
}

