import { yupResolver } from "@hookform/resolvers/yup";
import { FilterButton } from "components/Button/style";
import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect } from "components/DropdownSelect";
import { useMaskInput } from "hooks/useMaskInput";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter } from "react-icons/fa";
import { ButtonContainer, InputContainer } from "./style";
import { MaisFiltros } from "components/MaisFiltros";
import { useMaisFiltros } from "hooks/useMaisFiltros";
import { IFiltro } from "./Interface/IFiltro";
import { STATUS_CONSULTA_OPTIONS } from "../../constants/options";
import { CodigoRetorno } from "features/licenciamento/constants/CodigoRetorno";
import { filtrosDetalhesConsultaScheme } from "features/licenciamento/consulta/validate/validator";

interface Props {
    handleSubmitFiltros: (data: IFiltro) => void;
}

export function Filtros({ handleSubmitFiltros }: Props) {
    const {
        handleAplicarMaisFiltros,
        handleFormSubmit,
        handleNaoUsarMaisFiltros,
    } = useMaisFiltros();
    const { mInputPlaca } = useMaskInput();

    const { handleSubmit, control, reset } = useForm<IFiltro>({
        resolver: yupResolver(filtrosDetalhesConsultaScheme),
    });

    const anoAtual = new Date().getFullYear();

    const qtdAnosAnteriores = 3;
    const anosOptions: { label: string, value: string }[] = [];
    for (let i = anoAtual; i >= anoAtual - qtdAnosAnteriores; i--) {
        const objetoAno = {
            label: i.toString(),
            value: i.toString()
        };
        anosOptions.push(objetoAno);
    }
    anosOptions.push({ label: "Outros", value: "outros" });

    return (
        <Container titulo="Filtros">
            <Form
                noValidate
                onSubmit={handleSubmit((dados) => {
                    handleFormSubmit({
                        dados,
                        excluir: ["renavam"],
                        resetCallback: reset,
                        submitCallback: handleSubmitFiltros,
                    });
                })}
            >
                <Row>
                    <InputContainer lg="2">
                        <Form.Group>
                            <Form.Label>Placa</Form.Label>
                            <Controller
                                control={control}
                                name="placa"
                                render={({ field: { onChange } }) => (
                                    <CustomInput
                                        type="text"
                                        placeholder="Busque por placa"
                                        onChange={(e: any) => {
                                            onChange(mInputPlaca(e).replace(/-/g, ""));
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </InputContainer>
                    <Col lg="2">
                        <Form.Group>
                            <Controller
                                control={control}
                                name="statusConsulta"
                                render={({ field: { onChange, value } }) => (
                                    <DropdownSelect
                                        options={STATUS_CONSULTA_OPTIONS}
                                        title="Status da consulta"
                                        selectedValue={value}
                                        onSelect={onChange}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="1">
                        <Form.Group>
                            <Controller
                                control={control}
                                name="exercicio"
                                render={({ field: { onChange, value } }) => (
                                    <DropdownSelect
                                        options={anosOptions}
                                        title="Exercício"
                                        selectedValue={value}
                                        onSelect={onChange}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="3">
                        <Form.Group>
                            <Controller
                                control={control}
                                name="codigoRetorno"
                                render={({ field: { onChange, value } }) => (
                                    <DropdownSelect
                                        options={CodigoRetorno.map(item => {
                                            return { label: `${item.codigo} - ${item.titulo}`, value: `${item.codigo} - ${item.titulo}` }
                                        })}
                                        title="Código de retorno consulta"
                                        selectedValue={value}
                                        onSelect={onChange}
                                        searchable
                                        maxHeight={300}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="2">
                        <ButtonContainer>
                            <FilterButton type="submit" disabled={false} loading={false}>
                                <FaFilter /> Filtrar
                            </FilterButton>
                            <FilterButton
                                type="reset"
                                onClick={() => {
                                    reset({
                                        statusConsulta: null,
                                        placa: null,
                                        renavam: null,
                                        codigoRetorno: null,
                                        exercicio: null,
                                    });
                                }}
                                disabled={false}
                                loading={false}
                                outline={true}
                            >
                                Limpar
                            </FilterButton>
                        </ButtonContainer>
                    </Col>
                    <Col lg="2">
                        <MaisFiltros
                            cancelarOnClick={() => {
                                handleNaoUsarMaisFiltros({
                                    excluir: ["renavam"],
                                    resetCallback: reset,
                                });
                            }}
                            handleClickAplicarFiltros={handleAplicarMaisFiltros}
                            limparTudoOnClick={() => {
                                handleNaoUsarMaisFiltros({
                                    excluir: ["renavam"],
                                    resetCallback: reset,
                                });
                            }}
                            widthModal="md"
                        >
                            <Form.Group>
                                <Form.Label>Renavam</Form.Label>
                                <Controller
                                    control={control}
                                    name="renavam"
                                    render={({ field: { onChange, value } }) => (
                                        <CustomInput
                                            type="text"
                                            placeholder="Busque por renavam"
                                            onChange={(e: any) => {
                                                onChange(e);
                                            }}
                                            value={value ?? ""}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </MaisFiltros>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

