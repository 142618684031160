import styled from "styled-components";

interface IModalContainerProps {
  width: "sm" | "md" | "lg";
  show: boolean;
}

const widthStrategy = {
  sm: 250,
  md: 325,
  lg: 400,
};

export const ButtonContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const ModalContainer = styled.div<IModalContainerProps>`
  position: absolute;
  ${({ show }) => (!show ? "display:none;" : "")}
  width: ${({ width }) => widthStrategy[width]}px;
  background-color: #fff;
  z-index: 10;
  padding: 0.5rem;
  top: 80px;
  right: 0;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
`;

export const HeaderModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BodyModalContainer = styled.div`
  padding: 0.5rem;
  margin-top: 0.5rem;
  display: grid;
  gap: 1rem;
`;

export const FooterModalContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  padding: 0.5rem;
  align-items: center;
  justify-content: end;
  gap: 1rem;
`;

export const TituloModalContainer = styled.span`
  padding: 8px;
`;

export const ButtonModalCustom = styled.button`
  width: 150px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral20};
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonTexto = styled.span`
  margin-left: 2px;
`;

interface PlaceholderProps {
  selected: boolean;
}

export const Placeholder = styled.span<PlaceholderProps>`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.neutral.neutral80 : theme.colors.neutral.neutral40};
`;

ModalContainer.defaultProps = {
  width: "md",
};
