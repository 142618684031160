import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
`;

export const BodyPergunta = styled.div`
    background-color: ${(props) => props.theme.colors.tags.warning.bgColor};
    padding:7px;
    margin: 10px;
    border-radius:5px;
    text-align: center;

    svg {
        width: 1.2rem;
        height: 1.2rem;
        color: ${(props) => props.theme.colors.warning.main};
        margin-right: 10px;
    }
`;

export const BodyTexto = styled.div`
    text-align: center;
`;

export const ModalBodyContainer = styled.div`

`;