import { yupResolver } from "@hookform/resolvers/yup";
import { StringUtils } from "_utils/String.utils";
import { PrimaryButton } from "components/Button/style";
import { CustomInput } from "components/CustomInput";
import { CustomDatePicker } from "components/DatePicker";
import { DropdownSelect } from "components/DropdownSelect";
import { InternoLw } from "components/InternoLw";
import { MaisFiltros } from "components/MaisFiltros";
import { ButtonContainer } from "features/debito/consulta/pages/Detalhes/Containers/Filtros/style";
import {
  Cobrar,
  STATUS_OPTIONS,
  UFS_LICENCIAMENTO_STRATEGY
} from "features/licenciamento/emissao/constants";
import { filtrosListarSolicitacoesLicenciamento } from "features/licenciamento/emissao/validate/validator";
import { ContextPermissao } from "hooks/ContextPermissao";
import { useEmpresas } from "hooks/useEmpresas";
import { useMaisFiltros } from "hooks/useMaisFiltros";
import { useMaskInput } from "hooks/useMaskInput";
import { useContext, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter } from "react-icons/fa";

interface Props {
  handleSubmitFiltros: (dados: IFiltrosListarSolicitacoesLicenciamento) => void;
  pagina?: "consulta" | "emissão";
}

export interface IFiltrosListarSolicitacoesLicenciamento {
  placa: NullableString;
  dataSolicitacao: Date | null | undefined;
  statusEmissao: "processando" | "finalizado" | null;
  cobranca: NullableString;
  uf: NullableString;
  renavam: NullableString;
  idEmpresa: NullableString;
}

export function FormContainer({
  handleSubmitFiltros,
  pagina = "emissão",
}: Readonly<Props>) {
  const {
    handleAplicarMaisFiltros,
    handleFormSubmit,
    handleNaoUsarMaisFiltros,
  } = useMaisFiltros();

  const { empresasOptions, agrupamentoOptions, criarOptionsAgrupamento } = useEmpresas();
  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);
  const { verificarEmpresa } = useContext(ContextPermissao);
  const usuarioLW = verificarEmpresa();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isSubmitted },
  } = useForm<IFiltrosListarSolicitacoesLicenciamento>({
    resolver: yupResolver(filtrosListarSolicitacoesLicenciamento),
  });
  const { mInputPlaca } = useMaskInput();

  return (
    <Form
      noValidate
      onSubmit={handleSubmit((dados) => {
        handleFormSubmit({
          dados: {
            ...dados,
            placa: dados.placa?.replace(/-/g, ""),
          },
          excluir: ["renavam", "idEmpresa"],
          resetCallback: reset,
          submitCallback: handleSubmitFiltros,
        });
      })}
    >
      <Row>
        <Col lg="2">
          <Form.Group>
            <Form.Label>Placa</Form.Label>
            <Controller
              control={control}
              name="placa"
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  style={{ height: "2.2rem", borderRadius: "0.5rem" }}
                  type="text"
                  placeholder="Digite a placa"
                  value={value!}
                  onChange={(e: any) => onChange(mInputPlaca(e))}
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col lg="2">
          <Form.Group>
            <Form.Label>Data da solicitação</Form.Label>
            <Controller
              control={control}
              name="dataSolicitacao"
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker selected={value} onChange={onChange} />
              )}
            />
          </Form.Group>
        </Col>
        <Col lg="2">
          <Form.Group>
            <Controller
              control={control}
              name="statusEmissao"
              render={({ field: { onChange, value } }) => (
                <DropdownSelect
                  options={STATUS_OPTIONS}
                  title={`Status ${StringUtils.primeiraLetraMaiuscula(pagina)}`}
                  selectedValue={value}
                  onSelect={onChange}
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col lg="2">
          <Form.Group>
            <Controller
              control={control}
              name="uf"
              render={({ field: { onChange, value } }) => (
                <DropdownSelect
                  options={UFS_LICENCIAMENTO_STRATEGY[pagina.replace(/ã/g, "a")]}
                  title="UF"
                  selectedValue={value}
                  onSelect={onChange}
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col lg="2">
          <ButtonContainer>
            <PrimaryButton type="submit" disabled={false}>
              <FaFilter /> Filtrar
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                reset({
                  placa: "",
                  dataSolicitacao: null,
                  renavam: null,
                  statusEmissao: null,
                  idEmpresa: null,
                  uf: null,
                });
              }}
              disabled={false}
              outline={true}
              variante="primary"
              type="button"
            >
              Limpar
            </PrimaryButton>
          </ButtonContainer>
        </Col>
        <Col lg="2" style={{ paddingBottom: "0.5rem" }}>
          <MaisFiltros
            cancelarOnClick={() => {
              handleNaoUsarMaisFiltros<IFiltrosListarSolicitacoesLicenciamento>(
                {
                  excluir: ["renavam", "idEmpresa", "cobranca"],
                  resetCallback: reset,
                }
              );
            }}
            handleClickAplicarFiltros={handleAplicarMaisFiltros}
            limparTudoOnClick={() => {
              handleNaoUsarMaisFiltros<IFiltrosListarSolicitacoesLicenciamento>(
                {
                  excluir: ["renavam", "idEmpresa", "cobranca"],
                  resetCallback: reset,
                }
              );
            }}
          >
            <Form.Group>
              <Form.Label>Renavam</Form.Label>
              <Controller
                control={control}
                name="renavam"
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Digite o renavam"
                    value={value ?? ""}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    mensagemErro={errors.renavam?.message}
                    isValid={
                      isSubmitted &&
                      isValid &&
                      (value ? value.length >= 1 : false)
                    }
                    isInvalid={!!errors.renavam}
                  />
                )}
              />
            </Form.Group>
            {(usuarioLW || (agrupamentoOptions && agrupamentoOptions.length)) ? (
              <Form.Group>
                <Controller
                  control={control}
                  name="idEmpresa"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      options={usuarioLW ? empresasOptions : agrupamentoOptions}
                      title="Empresa"
                      selectedValue={value}
                      onSelect={onChange}
                    />
                  )}
                />
              </Form.Group>
            ) : null}
            <InternoLw>
              <Form.Group>
                <Controller
                  control={control}
                  name="cobranca"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      options={[
                        {
                          value: Cobrar.SIM,
                          label: "Cobrado do cliente",
                        },
                        {
                          value: Cobrar.NAO,
                          label: "Isento de cobrança",
                        },
                      ]}
                      title="Cobrança"
                      selectedValue={value}
                      onSelect={onChange}
                    />
                  )}
                />
              </Form.Group>
            </InternoLw>
          </MaisFiltros>
        </Col>
      </Row>
    </Form>
  );
}

