import { yupResolver } from "@hookform/resolvers/yup";
import { CompositeContainer } from "components/CompositeContainer";
import { Controller, useForm } from "react-hook-form";
import { filtrosDetalhesExtratoVeicularSchema } from "../../validators/filtrosDetalhesExtratoVeicularSchema";
import { IFormDetalhesExtratoVeicular } from "../../interfaces/IFormDetalhesExtratoVeicular";
import { Col, Form, Row } from "react-bootstrap";
import { useMaisFiltros } from "hooks/useMaisFiltros";
import { DropdownSelect } from "components/DropdownSelect";
import { PrimaryButton } from "components/Button/style";
import { FaFilter } from "react-icons/fa";
import { MaisFiltros } from "components/MaisFiltros";
import { CustomInput } from "components/CustomInput";
import { useState } from "react";

export enum StatusConsultaExtratoVeicular {
  CONSULTANDO = "Consultando",
  COM_PENDENCIA = "Com pendência",
  NADA_CONSTA = "Nada Consta",
  FALHA = "Falha",
  DADOS_INCONSISTENTES = "Dados inconsistentes",
}

export enum TiposPendenciasExtratoVeicular {
  IPVA = "IPVA",
  LICENCIAMENTO = "Licenciamento",
  MULTA = "Multa",
  GRAVAME = "Gravame",
  RESTRICOES = "Restrições",
  RECALL = "Recall",
  LEILAO = "Leilão"
}

interface FiltrosDetalhesExtratoVeicularProps {
  submit: (dados: IFormDetalhesExtratoVeicular) => void;
}

export function FiltrosDetalhesExtratoVeicular({ submit }: Readonly<FiltrosDetalhesExtratoVeicularProps>) {
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormDetalhesExtratoVeicular>({ resolver: yupResolver(filtrosDetalhesExtratoVeicularSchema) });
  const { handleAplicarMaisFiltros, handleFormSubmit, handleNaoUsarMaisFiltros } = useMaisFiltros();
  const [desabilitarStatus, setDesabilitarStatus] = useState<boolean>(false);

  function handleLimparFiltros() {
    setDesabilitarStatus(false);
    reset({
      chassi: "",
      placa: "",
      renavam: "",
      tipoConsulta: null,
      statusConsulta: null,
    });
  }

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo text="Filtros" />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <Form
          onSubmit={handleSubmit((dados) => {
            handleFormSubmit({
              dados,
              excluir: ["renavam"],
              resetCallback: reset,
              submitCallback: submit,
            });
          })}
        >
          <Row>
            <Col lg="2">
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    mensagemErro={errors?.placa?.message}
                    placeholder="Busque por placa"
                    value={value ?? ""}
                    onChange={onChange}
                  />
                )}
              />
            </Col>
            <Col lg="2">
              <Form.Label>Chassi</Form.Label>
              <Controller
                control={control}
                name="chassi"
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    mensagemErro={errors?.chassi?.message}
                    placeholder="Busque por chassi"
                    value={value ?? ""}
                    onChange={onChange}
                  />
                )}
              />
            </Col>
            <Col lg="2">
              <Controller
                control={control}
                name="tipoPendencia"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    onSelect={(novoValor) => {
                      onChange(novoValor);
                      if (novoValor) {
                        setValue("statusConsulta", StatusConsultaExtratoVeicular.COM_PENDENCIA);
                        setDesabilitarStatus(true);
                        return;
                      }

                      if (!novoValor) {
                        setValue("statusConsulta", null);
                        setDesabilitarStatus(false);
                      }
                    }}
                    selectedValue={value!}
                    options={[
                      {
                        label: TiposPendenciasExtratoVeicular.IPVA,
                        value: TiposPendenciasExtratoVeicular.IPVA.toLowerCase(),
                      },
                      {
                        label: TiposPendenciasExtratoVeicular.LICENCIAMENTO,
                        value: TiposPendenciasExtratoVeicular.LICENCIAMENTO.toLowerCase(),
                      },
                      {
                        label: TiposPendenciasExtratoVeicular.MULTA,
                        value: TiposPendenciasExtratoVeicular.MULTA.toLowerCase(),
                      },
                      {
                        label: TiposPendenciasExtratoVeicular.GRAVAME,
                        value: TiposPendenciasExtratoVeicular.GRAVAME.toLowerCase(),
                      },
                      {
                        label: TiposPendenciasExtratoVeicular.RESTRICOES,
                        value: "bloqueios",
                      },
                      {
                        label: TiposPendenciasExtratoVeicular.RECALL,
                        value: TiposPendenciasExtratoVeicular.RECALL.toLowerCase(),
                      },
                      {
                        label: TiposPendenciasExtratoVeicular.LEILAO,
                        value: 'leiloes',
                      },
                    ]}
                    title="Tipo de pendência"
                  />
                )}
              />
            </Col>
            <Col lg="2">
              <Controller
                control={control}
                name="statusConsulta"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    onSelect={onChange}
                    selectedValue={value!}
                    disabled={desabilitarStatus}
                    options={[
                      {
                        label: StatusConsultaExtratoVeicular.CONSULTANDO,
                        value: StatusConsultaExtratoVeicular.CONSULTANDO,
                      },
                      {
                        label: StatusConsultaExtratoVeicular.COM_PENDENCIA,
                        value: StatusConsultaExtratoVeicular.COM_PENDENCIA,
                      },
                      {
                        label: StatusConsultaExtratoVeicular.NADA_CONSTA,
                        value: StatusConsultaExtratoVeicular.NADA_CONSTA,
                      },
                      {
                        label: StatusConsultaExtratoVeicular.FALHA,
                        value: StatusConsultaExtratoVeicular.FALHA,
                      },
                      {
                        label: StatusConsultaExtratoVeicular.DADOS_INCONSISTENTES,
                        value: StatusConsultaExtratoVeicular.DADOS_INCONSISTENTES,
                      },
                    ]}
                    title="Status da consulta"
                  />
                )}
              />
            </Col>
            <Col style={{ display: "flex", alignItems: "end", gap: "0.5rem" }} lg="2">
              <PrimaryButton type="submit">
                <FaFilter /> Filtrar
              </PrimaryButton>
              <PrimaryButton variante="primary" outline type="button" onClick={handleLimparFiltros}>
                Limpar
              </PrimaryButton>
            </Col>
            <Col lg="2">
              <MaisFiltros
                cancelarOnClick={() => {
                  handleNaoUsarMaisFiltros({
                    excluir: ["renavam"],
                    resetCallback: handleLimparFiltros,
                  });
                }}
                handleClickAplicarFiltros={handleAplicarMaisFiltros}
                limparTudoOnClick={() => {
                  handleNaoUsarMaisFiltros({
                    excluir: ["renavam"],
                    resetCallback: handleLimparFiltros,
                  });
                }}
              >
                <Form.Label>Renavam</Form.Label>
                <Controller
                  control={control}
                  name="renavam"
                  render={({ field: { onChange, value } }) => (
                    <Form.Control placeholder="Busque por renavam" value={value ?? ""} onChange={onChange} />
                  )}
                />
              </MaisFiltros>
            </Col>
          </Row>
        </Form>
      </CompositeContainer.Body>
    </CompositeContainer.Root>
  );
}

