import { StatusPagamentoEnum } from "../pages/relatorio/containers/StatusPagamento";

interface Props {
  qtdFinalizado: number;
  qtdSolicitado: number;
  qtdSemComprovante: number;
}

export function validaStatusPagamento({ qtdFinalizado, qtdSolicitado, qtdSemComprovante }: Props) {
  if ((qtdFinalizado + qtdSemComprovante) !== qtdSolicitado) {
    return StatusPagamentoEnum.Pendente;
  }
  return StatusPagamentoEnum.Finalizado;
}
