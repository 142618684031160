import styled from "styled-components";

export const ContainerForm = styled.div`
    .arquivoContainer {
        display: grid;
        gap: 0.6rem;
    }
`

export const ContainerTextForm = styled.div`
    color: ${({ theme }) => theme.colors.neutral.neutral50};
    margin-bottom: 16px;
`