import styled from "styled-components";

interface StatusSolicitacaoProps {
  type: "processando" | "finalizado";
}

export const StatusSolicitacaoLicenciamento = styled.div<StatusSolicitacaoProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: ${({ type, theme }) =>
    type === "finalizado"
      ? theme.colors.success.bgColor
      : theme.colors.warning.bgColor};
  border: 1px solid
    ${({ type, theme }) =>
      type === "finalizado"
        ? theme.colors.success.main
        : theme.colors.warning.main};
  color: ${({ type, theme }) =>
    type === "finalizado"
      ? theme.colors.success.main
      : theme.colors.warning.main};
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  width: 115px;
`;

export const DetalhesContainer = styled.div`
  width: 70px;
`;