import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { IDadosVeiculo } from "../../../..";

interface Props {
  dadosVeiculo: IDadosVeiculo;
}

export function DadosVeiculoPDF({ dadosVeiculo }: Readonly<Props>) {
  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Dados do veículo</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Placa</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.placa ?? "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Renavam</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.renavam ?? "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Chassi</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.chassi ?? "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Situação</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.situacao ?? "-"}</Text>
          </View>
        </View>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Município</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.municipio ?? "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Marca / Modelo</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.marcaModelo ?? "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Ano de fabricação/modelo</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.anoFabricacao ? `${dadosVeiculo.anoFabricacao}/${dadosVeiculo.anoModelo}` : "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Cor</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.cor ?? "-"}</Text>
          </View>
        </View>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Combustível</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.combustivel ?? "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Espécie</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.especie ?? "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Categoria</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.categoria ?? "-"}</Text>
          </View>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Número motor</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.codigoMotor ?? "-"}</Text>
          </View>
        </View>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_4}>
            <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Situação chassi</Text>
            <Text style={[STYLES.NEGRITO, STYLES.FONT_SIZE_09]}>{dadosVeiculo.situacaoChassi ?? "-"}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

