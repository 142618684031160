import { toastErro } from "components/Toast";
import { IBodySolicitarDownload } from "features/arquivos/interfaces";
import { useRequest } from "hooks/useRequest";
import { useCallback, useState } from "react";

export function useSolicitacaoDownload() {
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [disponiveis, setDisponiveis] = useState([]);
  const [indisponiveis, setIndisponiveis] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [totalRows, setTotalRows] = useState(0);

  const { get, post, loading } = useRequest("consulta");

  const listarSolicitacoesDownload = useCallback(async () => {
    const { data, hasErro } = await get(
      `/imagens/download?page=${page}&limit=${limit}`,
      {},
      false
    );

    if (hasErro) {
      setSolicitacoes([]);
      return;
    }

    setSolicitacoes(data.items);
    setTotalRows(data.meta.totalItems);
  }, [get, page, limit]);

  const solicitarDownload = useCallback(
    async (body: IBodySolicitarDownload) => {
      const { data, hasErro } = await post("/imagens/download", body);

      if (hasErro) {
        return;
      }
      await listarSolicitacoesDownload();
      return data;
    },
    [post, listarSolicitacoesDownload]
  );

  const realizarDownload = useCallback(async (idSolicitacao: number) => {
    const baseUrl = process.env.REACT_APP_BASE_URL_MS_CONSULTA;
    window.open(`
      ${baseUrl}/imagens/download/${idSolicitacao}
    `);
  }, []);

  const buscarDisponiveis = useCallback(
    async (idSolicitacao: number) => {
      const { hasErro, data } = await get(
        `/imagens/download/${idSolicitacao}/disponiveis`,
        {},
        false
      );

      if (hasErro) {
        setDisponiveis([]);
        return;
      }

      setDisponiveis(data);
    },
    [get]
  );

  const buscarIndisponiveis = useCallback(
    async (idSolicitacao: number) => {
      const { hasErro, data } = await get(
        `/imagens/download/${idSolicitacao}/indisponiveis`,
        {},
        false,
      );

      if (hasErro) {
        setIndisponiveis([]);
        return;
      }

      setIndisponiveis(data);
    },
    [get]
  );

  const buscarDadosPlanilha = useCallback(async () => {
    const { data, hasErro } = await get(`/imagens/download?paginate=0`, {}, false);

    if (hasErro) {
      toastErro("Ocorreu um erro ao buscar os dados");
      return;
    }

    return data;
  }, [get]);

  function handlePageChange(page: number) {
    setPage(page);
  }

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }

  return {
    solicitacoes,
    listarSolicitacoesDownload,
    solicitarDownload,
    totalRows,
    page,
    limit,
    handlePageChange,
    handleLimitChange,
    loading,
    realizarDownload,
    buscarDisponiveis,
    disponiveis,
    setDisponiveis,
    buscarIndisponiveis,
    indisponiveis,
    setIndisponiveis,
    buscarDadosPlanilha,
  };
}

