import { IOptions } from "components/DropdownSelect";
import { useRequest } from "hooks/useRequest";
import { useCallback, useState } from "react";

interface ICombosExtratoVeicular {
  id: number;
  nome: string;
  descricao: string;
}

export function useExtratoVeicular() {
  const [combosConsulta, setCombosConsulta] = useState<any>([]);
  const { get } = useRequest("consulta");

  const buscarCombosConsulta = useCallback(async () => {
    const { data, hasErro } = await get("veiculo/extrato/combos");

    if (hasErro) {
      setCombosConsulta([]);
      return;
    }
    const options = criarOptions(data);
    setCombosConsulta(options);
  }, [get]);

  function criarOptions(dados: ICombosExtratoVeicular[]): IOptions[] {
    return dados.map(({ descricao, nome }) => ({ label: descricao, value: nome }));
  }

  return {
    combosConsulta,
    buscarCombosConsulta,
  };
}

