import { CompositeContainer } from "components/CompositeContainer";
import { InputsContainer } from "../style";
import { Col } from "react-bootstrap";
import { useState } from "react";
import { SolicitarDownloadBody } from "./containers/SolicitarDownloadBody";
import {
  BodyOption,
  IBodySolicitarDownload,
} from "features/arquivos/interfaces";

interface Props {
  solicitarDownload: (body: IBodySolicitarDownload) => Promise<any>;
}

export function SolicitarDownloadImagens({
  solicitarDownload,
}: Props) {
  const [selected, setSelected] = useState<BodyOption>("lote");

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    setSelected(event.target.value as BodyOption);
  }

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo text="Selecione o tipo de download" />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <InputsContainer>
          <div>
            <input
              onChange={handleCheck}
              id="lote"
              type="radio"
              className="custom-radio"
              value="lote"
              checked={selected === "lote"}
            />
            <label htmlFor="lote">Download em lote</label>
          </div>
          <div>
            <input
              onChange={handleCheck}
              id="individual"
              type="radio"
              className="custom-radio"
              value="individual"
              checked={selected === "individual"}
            />
            <label htmlFor="individual">Download individual</label>
          </div>
        </InputsContainer>
        <SolicitarDownloadBody solicitarDownload={solicitarDownload} body={selected} />
      </CompositeContainer.Body>
    </CompositeContainer.Root>
  );
}

