import loadingIconAzul from "_assets/img/loading.png"
import loadingIconVemelho from "_assets/img/loading-red.png"
import loadingIconVerde from "_assets/img/loading-green.png"
import loadingIconCinza from "_assets/img/loading-grey.png"
import loadingIconLaranja from "_assets/img/loading-orange.png"
import { LoadingContainer } from "./style"

interface LoadingProps {
  mostrar: boolean;
  cor?: "azul" | "verde" | "vermelho" | "laranja" | "cinza";
  noWidth?: boolean;
}

const CorIcone = {
  azul: loadingIconAzul,
  vermelho: loadingIconVemelho,
  verde: loadingIconVerde,
  laranja: loadingIconLaranja,
  cinza: loadingIconCinza,
}

export function LoadingCirculo({ mostrar, cor = "cinza", noWidth = false }: LoadingProps) {
  return (
    <LoadingContainer mostrar={mostrar} noWidth={noWidth}>
      <img src={CorIcone[cor]} alt="Loading" />
    </LoadingContainer>
  )
}