import { ModalCustom } from "components/ModalCustom";
import { Container, IconContainer, SpanText } from "../ModalAlterarParaReembolso/style";
import { FaExclamation } from "react-icons/fa";
import { useSolicitacaoPagamentos } from "features/debito/pagamento/hooks/useSolicitacaoPagamentos";
import { useCallback, useState } from "react";
import { ModalConfirmacaoRecebimento } from "../ModalConfirmacaoRecebimento";
import { LoadingCirculo } from "components/LoadingCirculo";

interface Props {
	show: boolean;
	handleClose: Callback;
	listarSolicitacoesPagamento: Callback;
	id: number;
}

export function ModalConfirmarRecebimentoTotal({
	handleClose,
	show,
	listarSolicitacoesPagamento,
	id,
}: Readonly<Props>) {
	const { confirmarRecebimento, loading } = useSolicitacaoPagamentos();
	const [showConfirmacao, setShowConfirmacao] = useState(false);

	const handleConfirmarRecebimento = useCallback(
		async ({ id }: { id: number }) => {
			const { hasErro } = await confirmarRecebimento(id);

			if (hasErro) {
				handleClose();
				return;
			}
			handleClose();
			setShowConfirmacao(true);
		},
		[confirmarRecebimento, handleClose],
	);

	return (
		<>
			<ModalCustom
				footer={!loading}
				footerConfirmButton
				onConfirm={() => handleConfirmarRecebimento({ id })}
				centered
				show={show}
				handleClose={handleClose}
				title="Confirmar recebimento"
			>
				{loading ? (
					<LoadingCirculo mostrar={loading} />
				) : (
					<Container>
						<IconContainer>
							<FaExclamation />
						</IconContainer>
						<SpanText>
							Deseja confirmar o <strong>recebimento do valor total</strong> do adiantamento selecionado?
						</SpanText>
					</Container>
				)}
			</ModalCustom>
			<ModalConfirmacaoRecebimento
				text="Recebimento total confirmado com sucesso!"
				title="Confirmar recebimento"
				show={showConfirmacao}
				handleClose={() => {
					setShowConfirmacao(false);
					listarSolicitacoesPagamento();
				}}
			/>
		</>
	);
}
