import { Container } from "components/Container";
import { toastErro } from "components/Toast";
import { Page } from "containers/Page";
import { useExpandableRow } from "hooks/useExpandableRow";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRelatorioConsultas } from "../../hooks/useRelatorioConsultas";
import { FiltrosDetalhesRelatorioConsultas } from "./containers/FiltrosDetalhesRelatorioConsultas";
import { ResumoDetalhesConsulta } from "./containers/ResumoDetalhesConsulta";
import { TableDetalhesRelatorioConsultas } from "./containers/TableDetalhesRelatorioConsultas";

export function DetalhesRelatorioConsultas() {
  const { id } = useParams();

  const { setRowExpandToggled, rowToExpand } = useExpandableRow();

  const {
    buscarDetalhesConsulta,
    loading,
    page,
    setPage,
    dados,
    totalRows,
    limitDetalhes,
    setLimitDetalhes,
    error,
    filtrosDetalhes,
    setFiltrosDetalhes,
    buscarDadosPlanilha,
    buscarResumoConsulta,
    resumoConsulta,
  } = useRelatorioConsultas();

  useEffect(() => {
    error?.hasErro && toastErro(error.data.erro);
  }, [error]);

  useEffect(() => {
    buscarResumoConsulta(id!);
    buscarDetalhesConsulta(id!);
  }, [
    limitDetalhes,
    page,
    filtrosDetalhes,
    buscarDetalhesConsulta,
    buscarResumoConsulta,
    id,
  ]);

  function handleSubmitFiltros(data: any) {
    setFiltrosDetalhes(data);
  }

  function handleLimitChange(limit: number) {
    setLimitDetalhes(limit);
  }

  function handlePageChange(page: number) {
    setPage(page);
  }

  return (
    <Page title="Relatórios de Consultas e Solicitações">
      <FiltrosDetalhesRelatorioConsultas
        handleSubmitFiltros={handleSubmitFiltros}
      />
      <Container titulo="Relatório Consulta de Débitos">
        <ResumoDetalhesConsulta dados={resumoConsulta} />
        <TableDetalhesRelatorioConsultas
          buscarDadosPlanilha={buscarDadosPlanilha}
          idConsulta={id!}
          dados={dados}
          loading={loading}
          totalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          limit={limitDetalhes}
          rowToExpand={rowToExpand}
          setRowExpandToggled={setRowExpandToggled}
        />
      </Container>
    </Page>
  );
}

