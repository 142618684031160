import { useEffect, useState } from "react";
import { EsqueceuSenha } from "../components/EsqueceuSenha";
import { LoginBox } from "../components/LoginBox";
import { RedefinirSenha } from "../components/RedefinirSenha";
import { useSearchParams } from "react-router-dom";
import { LoginBoxForm, LoginContainer } from "../pages/Login/style";

type telas = "login" | "esqueceuSenha" | "redefinir";

export function LoginBoxContainer() {
  const [mostrarComponente, setMostrarComponente] = useState<telas>("login");
  const [params, serParams] = useSearchParams();

  useEffect(() => {
    const redefinirSenha = params.get("redefinirSenha");
    const definirSenha = params.get("definirSenha");

    if (redefinirSenha === "true" || definirSenha === "true") {
      setMostrarComponente("redefinir");
      return;
    }

    setMostrarComponente("login");
  }, []);

  function handleEsqueceuSenha() {
    setMostrarComponente("esqueceuSenha");
  }

  function handleVoltarAoLogin() {
    setMostrarComponente("login");
  }

  const componentes = {
    login: <LoginBox handleClickEsqueceuSenha={handleEsqueceuSenha} />,
    esqueceuSenha: <EsqueceuSenha handleClickVoltarAoLogin={handleVoltarAoLogin} />,
    redefinir: <RedefinirSenha handleClickVoltarAoLogin={handleVoltarAoLogin} />,
  };

  return (
    <LoginBoxForm>
      <LoginContainer>{componentes[mostrarComponente]}</LoginContainer>
    </LoginBoxForm>
  );
}
