import { Container, Text, Title } from "./style";


interface IErroPagamentos {
  erro: string;
}

export function ErroPagamentos({ erro }: IErroPagamentos) {
  return (
    <Container>
      <Title>Atenção</Title>
      <Text>{erro}</Text>
    </Container>
  )
}