import styled from "styled-components";

export const ViewerContainer = styled.div``;

export const PdfViewerHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral20};
	background-color: ${({ theme }) => theme.colors.neutral.neutral05};
	padding: 0.5rem;
`;

export const ControllsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 33%;
`;

export const ControllsEnd = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 0.5rem;
	padding: 0 0.5rem;
`;

export const PdfButton = styled.button`
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	background: transparent;
	transition: background-color 0.2s ease-in-out;
	border-radius: 0.375rem;

	&:hover {
		background: ${({ theme }) => theme.colors.white};
	}

	&:disabled {
		cursor: not-allowed;
		background: transparent;
	}
`;

export const PdfInputPageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
	padding: 0 0.5rem;
	width: 40%;

	.input-group {
		width: 50%;
	}
`;

export const PdfContainer = styled.div`
	height: 80vh;
	overflow-y: auto;
`;

export const PageSeparator = styled.div`
	height: 24px;
	background-color: ${({ theme }) => theme.colors.neutral.neutral05};
`;

export const PageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0.5rem 0;
	background-color: ${({ theme }) => theme.colors.neutral.neutral05};
`;
