import { LoadingCirculo } from "components/LoadingCirculo";
import { formatarApenasNumeros } from "_utils/Money";
import {
  Container,
  Text,
  TextContainer,
  TitleContainer,
  TotalText,
  TotalTitle,
  ValorContainer
} from "./style";

interface ITotaisProps {
  aPagar: number;
  loading: boolean;
  pagos: number;
}

export function Totais({ aPagar, loading, pagos }: ITotaisProps) {

  return (
    <Container>
      <TitleContainer>
        <TotalTitle>Totais</TotalTitle>
      </TitleContainer>
      <TextContainer>
        <Text>Pago</Text>
        <ValorContainer>
          <span>R$</span>
          {loading ? (
            <LoadingCirculo mostrar={loading} cor="verde" />
          ) : (
            <TotalText>{pagos && formatarApenasNumeros(pagos)}</TotalText>
          )}
        </ValorContainer>
      </TextContainer>
      <TextContainer>
        <Text>A pagar</Text>
        <ValorContainer>
          <span>R$</span>
          {loading ? (
            <LoadingCirculo mostrar={loading} cor="vermelho" />
          ) : (
            <TotalText negative>
              {aPagar && formatarApenasNumeros(aPagar)}
            </TotalText>
          )}
        </ValorContainer>
      </TextContainer>
    </Container>
  );
}

