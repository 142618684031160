import styled from "styled-components";
import { StatusPagamentoEnum } from ".";

interface StatusProps {
  statusPagamento: number;
}

export const StatusContainer = styled.span<StatusProps>`
  padding: 0.2rem 0.6rem;
  border-radius: 1rem;
  text-align: center;
  width: 100px;
  ${({ statusPagamento, theme }) =>
    statusPagamento === StatusPagamentoEnum["Pendente"] &&
    `
        background-color:${theme.colors.tags.warning.bgColor};
        color:${theme.colors.tags.warning.textColor};
        border:1px solid ${theme.colors.tags.warning.textColor};
    `}

  ${({ statusPagamento, theme }) =>
    statusPagamento === StatusPagamentoEnum["Finalizado"] &&
    `
        background-color:${theme.colors.tags.success.bgColor};
        color:${theme.colors.tags.success.textColor};
        border:1px solid ${theme.colors.tags.success.textColor};
    `}
`;
