import { yupResolver } from "@hookform/resolvers/yup";
import { IEmailRedefinirSenha } from "features/Login/@types/IEmailRedefinirSenha";
import { redefinirSenhaSchema } from "features/Login/validator/redefinirSenhaValidator";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  ContainerForm,
  CustomButton,
  FeedbackError,
  FormGroup,
  IconeAlerta,
  IconeEmail,
  InputEmail,
  Label,
  Texto,
  TextoContainer,
  TitleEsqueceu,
  TituloVerifiqueEmail,
  VoltarButton,
} from "./style";
import { useLogin } from "features/Login/hooks/useLogin";
import { Required } from "components/Required";

interface IEsqueceuSenhaProps {
  handleClickVoltarAoLogin: () => void;
}

export function EsqueceuSenha({ handleClickVoltarAoLogin }: IEsqueceuSenhaProps) {
  const [emailEnviado, setEmailEnviado] = useState<boolean>(false);
  const [erro, setErro] = useState<string>();
  const { enviarEmailRedefinirSenha } = useLogin();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IEmailRedefinirSenha>({
    resolver: yupResolver(redefinirSenhaSchema),
  });

  function handleRedefinirSenha({ email }: IEmailRedefinirSenha) {
    if (email.includes("@lwtecnologia")) {
      setErro("Usuário LW não podem redefinir sua senha");
      return;
    }

    enviarEmailRedefinirSenha(email);
    setEmailEnviado(true);
  }

  return (
    <div style={emailEnviado ? { height: "100%" } : {}}>
      {!emailEnviado ? (
        <>
          <TextoContainer>
            <TitleEsqueceu>Esqueceu sua senha?</TitleEsqueceu>
            <Texto>
              Informe seu endereço de e-mail. Enviaremos um link com instruções para redefinição da sua senha.
            </Texto>
          </TextoContainer>
          <ContainerForm onSubmit={handleSubmit(handleRedefinirSenha)}>
            <FormGroup>
              <Label>
                E-mail <Required />
              </Label>
              <InputEmail noBoxShadow validar styleIconeEsquerdo={{ padding: "18px 12px 18px 12px" }} iconeEsquerdo={<IconeEmail style={{ marginLeft: "12px", fontSize: "14px" }} />} {...register("email")} mensagemErro={errors.email?.message} />
              <CustomButton type="submit">Enviar e-mail</CustomButton>
            </FormGroup>
            {erro && (
              <FeedbackError>
                <IconeAlerta /> {erro}
              </FeedbackError>
            )}
          </ContainerForm>
          <VoltarButton onClick={handleClickVoltarAoLogin}>Voltar ao login</VoltarButton>
        </>
      ) : (
        <>
          <TextoContainer>
            <TituloVerifiqueEmail>Verifique sua caixa de entrada</TituloVerifiqueEmail>
            <Texto>Caso as informações fornecidas pertençam a um de nossos usuários cadastrados, enviaremos um e-mail para redefinição de senha.</Texto>
            <Texto>Certifique-se de verificar o lixo eletrônico caso o e-mail não seja localizado.</Texto>
          </TextoContainer>
          <CustomButton onClick={handleClickVoltarAoLogin}>Voltar ao login</CustomButton>
        </>
      )}
    </div>
  );
}
