import { useRequest } from "hooks/useRequest";
import { ILogar } from "../@types/ILogar";
import { useState, useEffect } from "react";

export function useLogin() {
  const [loading, setLoading] = useState<boolean>(false);
  const api = useRequest("api");
  const msUsuarioLw = useRequest("usuarioLw");

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        await crsf();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  async function logar(login: ILogar) {
    try {
      setLoading(true);
      return await msUsuarioLw.post("/v2/auth/plataforma", {
        ...login,
        plataforma: "DEBITOS",
      });
    } finally {
      setLoading(false);
    }
  }

  async function enviarEmailRedefinirSenha(email: string) {
    try {
      setLoading(true);
      return await api.get(`/usuario/email/${email}`);
    } finally {
      setLoading(false);
    }
  }

  async function crsf() {
    try {
      setLoading(true);
      return await msUsuarioLw.get(`/v2/auth/csrf`);
    } finally {
      setLoading(false);
    }
  }

  async function validarToken() {
    try {
      setLoading(true);
      return await msUsuarioLw.get(`/v2/auth/plataforma/valida/token`, {}, false);
    } finally {
      setLoading(false);
    }
  }

  return { logar, loading, enviarEmailRedefinirSenha, crsf, validarToken };
}
