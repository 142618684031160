export enum StatusSolicitacaoPagamentoEnum {
  "Pendente" = 0,
  "Solicitado" = 1,
  "Indisponível" = 2,
  "Expirado" = 3,
  "Possui multas" = 4,
  "Nada consta" = 5,
}

export enum StatusSolicitacaoPagamentoEnumV2 {
  Consultando = 0,
  "Débito disponível" = 1,
  "Débito indisponível" = 2,
  "Nada consta" = 3,
  Falha = 4,
  "Dados inconsistentes" = 5,
  "Pagamento solicitado" = 6,
}

export const StatusSolicitacaoPagamentoEnumV2Keys = Object.keys(StatusSolicitacaoPagamentoEnumV2).filter((key) =>
  Number.isNaN(Number(key))
);

