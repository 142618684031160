import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMenu, ISubmenu } from "interfaces/Menu";
import { useEffect, useState } from "react";
import { FaDollarSign, FaDownload, FaFileInvoice, FaPlus, FaSearch, FaSearchDollar } from "react-icons/fa";
import { RiDashboardFill } from "react-icons/ri";
import { HttpError } from "_services/api/HttpError";
import { IData, IHttpError } from "_services/api/interface/HttpResponse";
import { removeToken, verificarPermissao } from "_services/auth";

export default function useMenu() {
  const [menus, setMenus] = useState<IMenu[]>([]);

  useEffect(() => {
    async function request() {
      try {
        let pagamentoMenu: IMenu = {};
        let cadastroMenu: IMenu = {};
        let licenciamentoMenu: IMenu = {};
        let menuArquivos: IMenu = {};
        let menuExtratoVeicular: IMenu = {};
        let menuConsultarDebitos: IMenu = {};
        let menuRelatorios: IMenu = {};

        const permissaoConsultarDebitos = verificarPermissao("consultaDebitosAnual");
        const permissaoFinanceiro = verificarPermissao("pagamento");
        const permissaoAdm = verificarPermissao("administrador");
        const permissaoConsultarLicenciamentos = verificarPermissao("licenciamento");
        const permissaoEmitirLicenciamentos = verificarPermissao("emitirLicenciamento");
        const permissaoDownload = verificarPermissao("downloadArquivos");
        const permissaoExtratoVeicular = verificarPermissao("extratoVeicular");

        if (permissaoConsultarDebitos) {
          menuConsultarDebitos = {
            nome: "Débitos",
            url: "",
            link: false,
            icone: <FaSearchDollar />,
            subMenu: [
              {
                nome: "Consultar e Solicitar Pagamentos",
                url: "/debito/consulta",
                link: false,
                icone: "",
              },
            ],
          };
        }

        if (permissaoFinanceiro) {
          pagamentoMenu = {
            nome: "Pagamento",
            url: "/debito/pagamento",
            link: false,
            icone: <FaDollarSign />,
            subMenu: [],
          };
        }

        if (permissaoAdm) {
          cadastroMenu = {
            nome: "Cadastro",
            url: "",
            link: false,
            icone: <FaPlus />,
            subMenu: [
              {
                nome: "Empresas",
                url: "/404",
                link: false,
                icone: "",
              },
              {
                nome: "Usuários",
                url: "/cadastro/usuarios",
                link: false,
                icone: "",
              },
            ],
          };
        }

        if (permissaoConsultarLicenciamentos || permissaoEmitirLicenciamentos) {
          licenciamentoMenu = {
            nome: "Licenciamento",
            url: "",
            link: false,
            icone: <FaFileInvoice />,
            subMenu: [],
          };

          if (permissaoConsultarLicenciamentos) {
            licenciamentoMenu.subMenu?.push({
              nome: "Consultar",
              url: "/licenciamento/consulta",
              link: false,
              icone: "",
            });
          }

          if (permissaoEmitirLicenciamentos) {
            licenciamentoMenu.subMenu?.push({
              nome: "Emitir",
              url: "/licenciamento",
              link: false,
              icone: "",
            });
          }
        }

        if (permissaoDownload) {
          menuArquivos = {
            nome: "Arquivos",
            url: "",
            link: false,
            icone: <FaDownload />,
            subMenu: [
              {
                nome: "Download de arquivos",
                url: "/arquivos/download",
                icone: "",
              },
            ],
          };
        }

        if (permissaoExtratoVeicular) {
          menuExtratoVeicular = {
            nome: "Extrato veicular",
            url: "",
            link: false,
            icone: <FaSearch />,
            subMenu: [
              {
                nome: "Consultar Veículo",
                url: "/veiculos/extrato/consultas",
                link: false,
                icone: null,
              },
            ],
          };
        }

        let subMenusRelatorios: ISubmenu[] = [];

        if (permissaoConsultarDebitos || permissaoFinanceiro || permissaoExtratoVeicular) {
          menuRelatorios = {
            nome: "Relatórios",
            url: "",
            link: false,
            icone: <FontAwesomeIcon icon={faFileLines} />,
            subMenu: subMenusRelatorios,
          };

          if (permissaoConsultarDebitos || permissaoFinanceiro) {
            subMenusRelatorios.push(
              {
                nome: "Pagamento",
                url: "/relatorios/pagamento",
                link: false,
                icone: "",
              },
              {
                nome: "Pagamentos Recusados",
                url: "/relatorios/recusados",
                link: false,
                icone: "",
              }
            );
          }

          if (permissaoExtratoVeicular) {
            subMenusRelatorios.push({
              nome: "Extrato Veicular",
              url: "/relatorios/extrato/veicular",
              link: false,
              icone: "",
            });
          }
        }

        const menus = [
          {
            nome: "Dashboard",
            url: "/core/dashboard",
            link: false,
            icone: <RiDashboardFill />,
            subMenu: [],
          },
          cadastroMenu,
          menuConsultarDebitos,
          menuExtratoVeicular,
          licenciamentoMenu,
          pagamentoMenu,
          menuRelatorios,
          menuArquivos,
        ].filter((item) => Object.keys(item).length > 0);

        setMenus(menus);
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);

        if (response.status === 401) {
          removeToken();
        }
      }
    }

    request();
  }, []);

  return [menus];
}

