import { Informacao } from "components/Informacao";
import { ModalCustom } from "components/ModalCustom";
import { InfoContainer } from "./style";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleConfirmarEmissao: () => void;
}

export function ModalConfirmarEmissao({
  show,
  handleClose,
  handleConfirmarEmissao,
}: Readonly<Props>) {
  return (
    <ModalCustom
      centered
      handleClose={handleClose}
      show={show}
      onConfirm={handleConfirmarEmissao}
      footer
      footerConfirmButton
      title="Emitir licenciamento"
      size="lg"
    >
      <span>
        Deseja confirmar a emissão do licenciamento do(s) veículo(s)
        selecionado(s)?
      </span>
      <InfoContainer>
        <Informacao
          className="teste"
          type="atencao"
          mensagem="A emissão do licenciamento irá gerar custos para a LW"
          showLabel={false}
          showBotaoFechar={false}
        />
      </InfoContainer>
    </ModalCustom>
  );
}

