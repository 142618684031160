import { Page } from "containers/Page";
import { Filtros } from "./containers/Filtros";
import { ListarDetalhes } from "./containers/ListarDetalhes";
import { useParams } from "react-router-dom";
import { useDetalheEmissao } from "./hook/useDetalheEmissao";
import { useEffect, useState } from "react";
import { converterDataBr } from "_utils/Data";
import { utils, writeFileXLSX } from "xlsx";
import { StatusEmissaoLicenciamentoEnum } from "./containers/Enum/StatusEmissaoLicenciamento";
import { ModalConfirmacaoEmissao } from "./containers/ListarDetalhes/containers/ModalConfirmacaoEmissao";
import { ModalRenomear } from "./containers/ListarDetalhes/containers/ModalRenomear";
import { ModalEmissaoManual } from "./containers/ListarDetalhes/containers/ModalEmissaoManual";
import { IEmissaoManual } from "./containers/interfaces/IEmissaoManual";
import { ModalConfirmacaoEmissaoManual } from "./containers/ListarDetalhes/containers/ModalConfirmacaoEmissaoManual";

export function DetalheEmissao() {
    const { id: idEmissao } = useParams();
    const [showModalConfirmacaoEmissao, setShowModalConfirmacaoEmissao] = useState<boolean>(false);
    const [erroReemitir, setErroReemitir] = useState<boolean>(false);
    const [loadingReemitir, setLoadingReemitir] = useState<boolean>(false);
    const [showModalRenomear, setShowModalRenomear] = useState<boolean>(false);
    const [padraoSelecionado, setPadraoSelecionado] = useState(null);
    const qtdEmissoesManuaisPermitidas = 1;

    const {
        buscarDetalhes,
        handlePageChange,
        handleLimitChange,
        limit,
        totalRows,
        loading,
        filtroDetalhesEmissao,
        setFiltroDetalhesEmissao,
        dados,
        downloadCrlv,
        exportarCrlv,
        dadosExportar,
        reemitirCrlvs,
        emissaoManual,
        showModalConfirmarEmissaoManual,
        exibirModalEmissaoManual,
        setExibirModalEmissaoManual,
        anoSolicitado,
        setShowModalConfirmarEmissaoManual,
    } = useDetalheEmissao();

    const onSubmit = async (dados: any) => {
        setFiltroDetalhesEmissao({
            placa: dados.placa,
            renavam: dados.renavam,
            exercicio: dados.exercicio,
            statusEmissao: dados.statusEmissao,
        });
    }

    useEffect(() => {
        buscarDetalhes(idEmissao)
        setClearSelectedRows(true)
    }, [filtroDetalhesEmissao, idEmissao, buscarDetalhes])


    const [idsLicenciamentos, setIdsLicenciamentos] = useState<number[]>([]);
    const [clearSelectedRows, setClearSelectedRows] = useState<boolean>(false);
    const [disabledEmissaoManual, setDisabledEmissaoManual] = useState<boolean>(true);

    const getLinhas = (linhas: any[]) => {
        setIdsLicenciamentos(linhas.map((item) => item.id));
        setClearSelectedRows(false);

        if (linhas.length === qtdEmissoesManuaisPermitidas && linhas[0].statusEmissao == StatusEmissaoLicenciamentoEnum.Falha) {
            setDisabledEmissaoManual(false);
            return;
        }
        setDisabledEmissaoManual(true);
    };

    const [showModalDownload, setShowModalDownload] = useState<boolean>(false)
    const handleCloseModalDownload = () => {
        setShowModalDownload(false);
    }
    const onClickDownload = async () => {
        if (idsLicenciamentos.length) {
            setShowModalDownload(true);
            return;
        }
        setShowModalRenomear(true);
    }

    const onConfirmModalRenomear = () => {
        downloadCrlv(idEmissao!, idsLicenciamentos, padraoSelecionado!);
        handleCloseModalRenomear();
    }

    const onConfirmModalDownload = async () => {
        setShowModalDownload(false);
        setShowModalRenomear(true);
    }

    const [showModalExportar, setShowModalExportar] = useState<boolean>(false)
    const handleCloseModalExportar = () => {
        setShowModalExportar(false);
    }
    const onClickExportar = async () => {
        if (idsLicenciamentos.length) {
            setShowModalExportar(true);
            return;
        }
        await exportarCrlv(idEmissao);
    }
    const onConfirmModalExportar = async () => {
        await exportarCrlv(idEmissao, idsLicenciamentos);
        setShowModalExportar(false);
    }

    useEffect(() => {
        if (dadosExportar.length > 0) {
            const dadosPlanilha = dadosExportar.map((item: any) => {
                const detalheEmissao: any = {
                    Placa: item.placa.toUpperCase(),
                    Renavam: item.renavam,
                    Chassi: item.chassi,
                    "CPF/CNPJ": item.cpfCnpjProprietario,
                    CRV: item.crv,
                    UF: item.uf.toUpperCase(),
                    "Exercício": item.ano,
                    "Data de emissão": item.dataConsulta ? converterDataBr(new Date(item.dataConsulta)) : "-",
                    "Observação": item.url && item.observacao !== "Emissão Manual" ? "CRLV emitido com sucesso" : (item.observacao ?? ""),
                    "Status Emissão": StatusEmissaoLicenciamentoEnum[item.statusEmissao],
                    Recibo: item.idRecibo ?? "--",
                    Tentativas: item.tentativas,
                    Link: item.url ?? ""
                };

                if (item.uf.toUpperCase() === "PR") {
                    delete detalheEmissao["CPF/CNPJ"];
                }

                if (item.uf.toUpperCase() !== "MG") {
                    delete detalheEmissao.CRV;
                }

                return detalheEmissao;
            });

            const colsStrategy = {
                MG: [{ wch: 10 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 5 }, { wch: 10 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }],
                PR: [{ wch: 10 }, { wch: 15 }, { wch: 20 }, { wch: 5 }, { wch: 10 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }],
                SP: [{ wch: 10 }, { wch: 15 }, { wch: 20 }, { wch: 15 }, { wch: 5 }, { wch: 10 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }],
            };
            const planilha = utils.json_to_sheet(dadosPlanilha);
            planilha["!cols"] = colsStrategy[dadosExportar[0].uf as "MG" | "PR" | "SP"];
            const workBook = utils.book_new();
            utils.book_append_sheet(workBook, planilha, "CRLVs");
            writeFileXLSX(workBook, `CRLV_${idEmissao}.xlsx`);
        }
    }, [dadosExportar, idEmissao])

    async function onClickEmitir() {
        setLoadingReemitir(true);
        setShowModalConfirmacaoEmissao(true);
        const { hasErro } = await reemitirCrlvs(Number(idEmissao));

        if (hasErro) {
            setErroReemitir(true);
            setLoadingReemitir(false);
        }
        setLoadingReemitir(false);
        await buscarDetalhes(idEmissao);
    }

    function handleCloseModalConfirmacaoEmissao() {
        setShowModalConfirmacaoEmissao(false);
    }

    function handleCloseModalRenomear() {
        setShowModalRenomear(false);
        setPadraoSelecionado(null);
    }

    const onClickEmissaoManual = () => {
        setExibirModalEmissaoManual(true);
    }

    const handleSubmitEmissaoManual = (dados: IEmissaoManual) => {
        if (idEmissao) {
            emissaoManual(idsLicenciamentos[0], dados, idEmissao)
            setClearSelectedRows(true);
        }
    }
    const handleCloseModalEmissaoManual = () => {
        setExibirModalEmissaoManual(false);
    }
    const handleCloseModalConfirmacaoEmissaoManual = () => {
        setShowModalConfirmarEmissaoManual(false);
    }

    return (
        <Page lastPage="Licenciamento" title="Emitir Licenciamentos">
            <Filtros
                onSubmit={onSubmit}
            />
            <ListarDetalhes
                idEmissao={idEmissao}
                loading={loadingReemitir ? !loading : loading}
                dados={dados}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                rowsPerPage={limit}
                totalRows={totalRows}
                setSelectedRows={getLinhas}
                showModalDownload={showModalDownload}
                onConfirmModalDownload={onConfirmModalDownload}
                handleCloseModalDownload={handleCloseModalDownload}
                onClickDownload={onClickDownload}
                showModalExportar={showModalExportar}
                onConfirmModalExportar={onConfirmModalExportar}
                handleCloseModalExportar={handleCloseModalExportar}
                onClickExportar={onClickExportar}
                clearSelectedRows={clearSelectedRows}
                onClickEmitir={onClickEmitir}
                disabledEmitir={idsLicenciamentos.length > 0}
                disabledEmissaoManual={disabledEmissaoManual}
                onClickEmissaoManual={onClickEmissaoManual}
                exibirModalEmissaoManual={exibirModalEmissaoManual}
                anoSolicitado={anoSolicitado}
            />
            <ModalRenomear
                handleClose={handleCloseModalRenomear}
                show={showModalRenomear}
                onConfirm={onConfirmModalRenomear}
                padraoSelecionado={padraoSelecionado}
                setPadraoSelecionado={setPadraoSelecionado}
            />
            <ModalConfirmacaoEmissao
                handleClose={handleCloseModalConfirmacaoEmissao}
                show={showModalConfirmacaoEmissao}
                loading={loadingReemitir}
                erro={erroReemitir}
            />
            <ModalEmissaoManual
                handleClose={handleCloseModalEmissaoManual}
                handleSubmit={handleSubmitEmissaoManual}
                showModal={exibirModalEmissaoManual}
                ano={anoSolicitado}
            ></ModalEmissaoManual>
            <ModalConfirmacaoEmissaoManual
                show={showModalConfirmarEmissaoManual}
                handleClose={handleCloseModalConfirmacaoEmissaoManual}
            ></ModalConfirmacaoEmissaoManual>
        </Page>
    );
}

