import styled from "styled-components";

export const BodyTexto = styled.div`
    text-align: center;
`;

export const ButtonHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;

  button {
    svg {
      width: 0.9rem;
      height: 0.9rem;
      margin-left: 10px;
    }
  };
`;

export const TooltipContainer = styled.div`
  text-align: left;
  color: #FFFFFF;
  background-color: #333333;
  padding: 10px 20px;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
`;