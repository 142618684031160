import { ModalCustom } from "components/ModalCustom";
import { Container, IconeContainer } from "./style";
import { FaCheck } from "react-icons/fa";

interface Props {
  show: boolean;
  handleClose: () => void;
}

export function ModalReconsultarFalhasExtratoVeicular({ handleClose, show }: Readonly<Props>) {
  return (
    <ModalCustom show={show} handleClose={handleClose} title="Consultar novamente" centered>
      <Container>
        <IconeContainer>
          <FaCheck />
        </IconeContainer>
        <span>Solicitação efetuada com sucesso!</span>
        <strong>As consultas com status de falha serão reprocessadas</strong>
      </Container>
    </ModalCustom>
  );
}

