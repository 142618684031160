import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { FaInfoCircle, FaExclamationTriangle, FaPlusCircle } from "react-icons/fa";
import { Container } from "./style";

interface IconeInfoProps {
  statusDebito: string;
  onClick: () => void;
  temMulta: number;
  idTipoDebito: number;
}

export function IconeInfo({ statusDebito, onClick, temMulta, idTipoDebito }: Readonly<IconeInfoProps>) {
  function iconeStrategy(statusDebito: number) {
    if (statusDebito === StatusSolicitacaoPagamentoEnumV2["Débito indisponível"]) {
      return <FaExclamationTriangle onClick={onClick} />;
    }

    if (statusDebito === StatusSolicitacaoPagamentoEnumV2.Falha) {
      return <FaPlusCircle />;
    }

    return (
      <FaInfoCircle
        onClick={statusDebito === StatusSolicitacaoPagamentoEnumV2["Débito disponível"] ? onClick : () => {}}
      />
    );
  }

  return (
    <Container tipoDebito={Number(idTipoDebito)} temMulta={temMulta} statusDebito={statusDebito}>
      {iconeStrategy(Number(statusDebito))}
    </Container>
  );
}

