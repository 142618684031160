import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import styled from "styled-components";

interface Props {
  statusDebito: string;
  temMulta: number;
  tipoDebito: number;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  overflow: hidden;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: ${({ theme }) => theme.colors.neutral.neutral90};
    overflow: hidden;
    cursor: pointer;

    ${({ theme, statusDebito }) =>
      StatusSolicitacaoPagamentoEnumV2[Number(statusDebito)] !== "Débito disponível"
        ? `
        color: ${theme.colors.neutral.neutral40};
      `
        : ""}

    ${({ theme, statusDebito }) =>
      StatusSolicitacaoPagamentoEnumV2[Number(statusDebito)] === "Débito indisponível"
        ? `
        color: ${theme.colors.warning.main};
      `
        : ""}

    ${({ theme, statusDebito }) =>
      StatusSolicitacaoPagamentoEnumV2[Number(statusDebito)] === "Falha" &&
      `
        color: ${theme.colors.danger.main};
        rotate: 45deg;
      `}
  }
`;

