import { intlCurrencyFormat } from "_utils/Money";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { Stronger } from "components/Stronger";

interface Props {
  licenciamentos: any[];
}

export function DadosLicenciamentoNacional({ licenciamentos }: Readonly<Props>) {
  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={licenciamentos.length ? IconePendencia : IconeNadaConsta} />
        <Section.Titulo titulo="Licenciamento" />
      </Section.Header>
      <Section.Body>
        <Section.Row>
          <Section.Col>
            <span>Valor total licenciamentos</span> <br />
            <Stronger>
              {licenciamentos.length
                ? intlCurrencyFormat(licenciamentos.map((item) => item.valor).reduce((prev, acc) => acc + prev) / 100)
                : "-"}
            </Stronger>
          </Section.Col>
        </Section.Row>
      </Section.Body>
    </Section.Root>
  );
}

