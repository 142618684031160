import { Container } from "./style";

interface Props {
  label: string;
  type: "atencao" | "erro" | "info";
}

export function Tag({ label, type }: Props) {
  return (
    <Container type={type}>
      {label}
    </Container>
  );
}
