import { StringUtils } from "_utils/String.utils";
import { Stronger } from "components/Stronger";
import { Row, Col } from "react-bootstrap";
import { Container } from "./style";

export interface IRegistrosRemarketing {
  organizador: string;
  vendedor: string;
  item: string | number;
  dataEvento: string;
  condicoesVeiculo: string;
  situacaoChassi: string;
  condicoesMotor: string;
  condicoesCambio: string;
  condicoesMecanica: string;
  observacao: string;
}

interface Props {
  remarketing: IRegistrosRemarketing;
}

export function BodyRemarketing({ remarketing }: Readonly<Props>) {
  return (
    <Container>
      <Row>
        <Col>
          <span>Organizador</span> <br />
          <Stronger>
            {remarketing.organizador
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.organizador.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
        <Col>
          <span>Vendedor</span> <br />
          <Stronger>{remarketing.vendedor ?? "-"}</Stronger>
        </Col>
        <Col>
          <span>Item</span> <br />
          <Stronger>{remarketing.item ?? "-"}</Stronger>
        </Col>
        <Col>
          <span>Data evento</span> <br />
          <Stronger>{remarketing.dataEvento ?? "-"}</Stronger>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>Condições do veículo</span> <br />
          <Stronger>
            {remarketing.condicoesVeiculo
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.condicoesVeiculo.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
        <Col>
          <span>Situação do chassi</span> <br />
          <Stronger>
            {remarketing.situacaoChassi
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.situacaoChassi.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
        <Col>
          <span>Condições motor</span> <br />
          <Stronger>
            {remarketing.condicoesMotor
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.condicoesMotor.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
        <Col>
          <span>Condições câmbio</span> <br />
          <Stronger>
            {remarketing.condicoesCambio
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.condicoesCambio.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="3" sm="3">
          <span>Condições mecânica</span> <br />
          <Stronger>
            {remarketing.condicoesMecanica
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.condicoesMecanica.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
        <Col>
          <span>Observação</span> <br />
          <Stronger>
            {remarketing.observacao
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.observacao.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
      </Row>
    </Container>
  );
}
