import * as yup from "yup";

const solicitarDownloadBaseSchema = yup.object().shape({
  tipoImagem: yup.string().required("Selecione o tipo de arquivo"),
  renomearPara: yup.string().nullable().when("tipoImagem", {
    is: (tipoImagem: string) => tipoImagem === "CRLV",
    then: (schema) => schema.required("Escolha como nomear o arquivo"),
  }),
});

export const solicitarDownloadArquivosUnitarioResolver = yup
  .object()
  .shape({
    placa: yup.string().required("Placa é obrigatório").nullable(),
  })
  .concat(solicitarDownloadBaseSchema);

export const solicitarDownloadArquivosEmLoteResolver = yup
  .object()
  .shape({
    arquivo: yup
      .mixed()
      .test("file-selected", "Selecione um arquivo", (value) => {
        return value instanceof File && value.size > 0;
      })
      .nullable(),
  })
  .concat(solicitarDownloadBaseSchema);

