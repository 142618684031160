import styled from "styled-components";

export const IconeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const BaseButton = styled.button`
  border: none;
  background-color: transparent;

  svg {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }

  &:disabled {
    svg {
      color: ${({ theme }) => theme.colors.neutral.neutral40} !important;
    }
  }
`;

export const ButtonCheck = styled(BaseButton)`
  svg {
    color: ${({ theme }) => theme.colors.success.main} !important;
  }
`;

export const ButtonExclamacao = styled(BaseButton)`
  svg {
    color: ${({ theme }) => theme.colors.warning.main} !important;
  }
`;

