import { ReactNode, useState } from "react";
import { Col, Row, RowProps } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { StyledCol, StyledRow, ToggleButton } from "./style";

interface Props extends RowProps {
  rowContent: ReactNode;
  toggleContent: ReactNode;
}

export function SectionRowToggle({
  rowContent,
  toggleContent,
  ...rest
}: Readonly<Props>) {
  const [mostrarConteudo, setMostrarConteudo] = useState(false);

  function handleMostrarConteudo() {
    setMostrarConteudo((prev) => !prev);
  }

  return (
    <>
      <StyledRow
        arendondarSomenteBordasSuperior={mostrarConteudo}
        {...rest}
        temconteudoparamostrar={String(Boolean(toggleContent))}
        onClick={handleMostrarConteudo}
      >
        <Col lg="11" sm="11" md="11">
          <Row>{rowContent}</Row>
        </Col>
        <StyledCol lg="1" sm="1" md="1">
          <ToggleButton disabled={!toggleContent} type="button">
            {mostrarConteudo ? <FaChevronUp /> : <FaChevronDown />}
          </ToggleButton>
        </StyledCol>
      </StyledRow>
      {mostrarConteudo && <Row>{toggleContent}</Row>}
    </>
  );
}
