import {
  Container,
  DadosMultaBox,
  DadosMultaContainer,
  DadosMultaText,
  DadosMultaTitle,
} from "./style";
import { formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";

interface IDebitoMultaProps {
  dataInfracao?: string;
  autoInfracao?: number;
  descricao?: string;
  orgaoAutador?: string;
  vencimento?: string;
  valor?: number;
}

export function DebitoMulta({
  autoInfracao,
  dataInfracao,
  descricao,
  orgaoAutador,
  vencimento,
  valor,
}: IDebitoMultaProps) {
  return (
    <Container>
      <DadosMultaContainer>
        <DadosMultaBox lg="2" mostrar={!!autoInfracao}>
          <DadosMultaTitle>Auto infração</DadosMultaTitle>
          <DadosMultaText>{autoInfracao}</DadosMultaText>
        </DadosMultaBox>

        <DadosMultaBox lg="3" mostrar={!!descricao}>
          <DadosMultaTitle>Infração</DadosMultaTitle>
          <DadosMultaText>{descricao}</DadosMultaText>
        </DadosMultaBox>

        <DadosMultaBox lg="2" mostrar={!!dataInfracao}>
          <DadosMultaTitle>Data da infração</DadosMultaTitle>
          <DadosMultaText>{dataInfracao && formataDataISOParaBR(dataInfracao)}</DadosMultaText>
        </DadosMultaBox>

        <DadosMultaBox lg="2" mostrar={!!orgaoAutador}>
          <DadosMultaTitle>Orgão autuador</DadosMultaTitle>
          <DadosMultaText>{orgaoAutador}</DadosMultaText>
        </DadosMultaBox>

        <DadosMultaBox lg="2" mostrar={!!vencimento}>
          <DadosMultaTitle>Vencimento</DadosMultaTitle>
          <DadosMultaText>{vencimento && formataDataISOParaBR(vencimento!)}</DadosMultaText>
        </DadosMultaBox>

        <DadosMultaBox lg="1" mostrar={!!valor}>
          <DadosMultaTitle>Valor</DadosMultaTitle>
          <DadosMultaText>{valor && formatarDinheiro(Number(valor) / 100)}</DadosMultaText>
        </DadosMultaBox>
      </DadosMultaContainer>
    </Container>
  );
}
