import { toastErro } from "components/Toast";
import { useEffect, useState } from "react";
import api from "_services/api";
import { IDashboardHookProps } from "../interfaces/IDashboardHookProps.interface";
import { ITableSolicitacoesPagamentos } from "../interfaces/ITableSolicitacoesPagamentos.interface";

export function useTableSolicitacoesPagamentos({
  empresaSelecionada,
  dataInicial,
  dataFinal,
}: IDashboardHookProps) {
  const [data, setData] = useState<ITableSolicitacoesPagamentos[]>([]);
  const [loading, setLoading] = useState(false);

  const customTableStyle = {
    headRow: {
      style: {
        background: "transparent",
      },
    },
  };

  useEffect(() => {
    buscarDados();
  }, [empresaSelecionada, dataInicial, dataFinal]);

  async function buscarDados(): Promise<void> {
    try {
      const empresaQuery =
        empresaSelecionada && empresaSelecionada != null
          ? `&idEmpresa=${empresaSelecionada}`
          : "";

      const { data: response } = await api.get(
        `/debito/pagamento/solicita/?page=1&limit=5&order=DESC${empresaQuery}`
      );

      setData(response.items);
    } catch (e: any) {
      if (!e.message.includes("404")) {
        toastErro("Erro ao buscar os dados das solicitações de pagamentos");
      }
    }
  }

  return { loading, customTableStyle, data };
}
