import { ReactNode } from "react";
import { Col, ColProps } from "react-bootstrap";

interface Props extends ColProps {
  children: ReactNode;
}

export function SectionCol({ children, ...rest }: Readonly<Props>) {
  return <Col {...rest}>{children}</Col>;
}

