import React, { createContext, useCallback, useMemo } from "react";
import { IMenu } from "../interfaces/Menu";
import { getEmail, getEmpresa, getToken } from "../_services/auth";
import useMenu from "./UseMenu";

export interface ContextPremissaoProps {
  itens: IMenu[];
  verificarPermissao: () => Promise<boolean>;
  verificarEmpresa: () => boolean;
  verificarUsuarioAwLw: () => boolean;
}

interface Props {
  children: React.ReactNode;
}

export const ContextPermissao = createContext<ContextPremissaoProps>({
  itens: [],
  verificarPermissao: async () => false,
  verificarEmpresa: () => false,
  verificarUsuarioAwLw: () => false,
});

const ContextPermisaoProvider: React.FC<Props> = ({ children }) => {
  const [itens] = useMenu();

  const permissaoUrl = useCallback(async () => {
    let arrayAux: any = [];
    itens?.forEach((menu) => {
      arrayAux.push(menu.url, ...[menu.subMenu?.map((subMenu) => subMenu.url)]);
    });
    const urls = arrayAux.flat();

    const urlAtual = window.location.pathname;

    // if (urls?.filter((url: any) => urlAtual === url).length === 0) {
    //   return false;
    // }
    return true;
  }, [itens]);

  const verificarPermissao = useCallback(async () => {
    const token = getToken();

    if (!token || token === null || token === "undefined") {
      return false;
    }

    return permissaoUrl();
  }, [permissaoUrl]);

  const verificarEmpresa = useCallback(() => {
    const ID_LW = "91";

    const empresaUsuario = getEmpresa();

    return empresaUsuario === ID_LW;
  }, []);

  const verificarUsuarioAwLw = useCallback(() => {
    const emailUsuario = getEmail();

    return emailUsuario?.includes("@lwtecnologia") ?? false;
  }, []);

  const memoContexts = useMemo(
    () => ({
      itens,
      verificarEmpresa,
      verificarPermissao,
      verificarUsuarioAwLw,
    }),
    [itens, verificarEmpresa, verificarPermissao, verificarUsuarioAwLw]
  );

  return (
    <ContextPermissao.Provider value={memoContexts}>
      {children}
    </ContextPermissao.Provider>
  );
};
export default ContextPermisaoProvider;

