import { useCallback, useState } from "react";
import { IConsultaExtratoVeicular } from "../../interfaces/IConsultaExtratoVeicular";
import {
  IFiltrosListarConsultasExtratoVeicular,
  IFiltrosListarConsultasUnitariasExtratoVeicular,
} from "../../interfaces/IFiltrosListarConsultasExtratoVeicular";
import { useRequest } from "hooks/useRequest";
import { Data, converterDataHoraBr } from "_utils/Data";
import { useTablePaginationSortSearch } from "containers/Table/hooks/useTablePaginationSortSearch";
import { toastErro } from "components/Toast";
import { utils, writeFileXLSX } from "xlsx";
import { formatarDinheiro } from "_utils/Money";

export function useListarExtratoVeicular() {
  const [extratosVeicularesSelecionados, setExtratosVeicularesSelecionados] = useState<number[]>([]);
  const [consultasSelecionadas, setConsultasSelecionadas] = useState<number[]>([]);
  const [dados, setDados] = useState<IConsultaExtratoVeicular[]>([]);
  const [datasExportar, setDatasExportar] = useState<(Date | null)[]>([null, null]);
  const [filtrosListarExtratosVeiculares, setFiltrosListarExtratosVeiculares] =
    useState<IFiltrosListarConsultasExtratoVeicular | null>(null);

  const [filtrosListarDetalhadosExtratosVeiculares, setFiltrosListarDetalhadosExtratosVeiculares] =
    useState<IFiltrosListarConsultasUnitariasExtratoVeicular | null>(null);
  const [totalRegistros, setTotalRegistros] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRelatorio, setLoadingRelatorio] = useState<boolean>(false);
  const [showOpcoesExportarExcel, setShowOpcoesExportarExcel] = useState<boolean>(false);

  const { handleLimitChange, handlePageChange, paginationSortSearch, limit } = useTablePaginationSortSearch();

  const { get } = useRequest("consulta");

  const listarExtratosVeiculares = useCallback(async () => {
    if (limit > 10) {
      handleLimitChange(10);
    }
    const filtros = ajustarFiltros(filtrosListarExtratosVeiculares);
    setLoading(true);
    const { data, hasErro } = await get(`/veiculo/extrato?${filtros}&order=DESC&${paginationSortSearch}`);

    if (hasErro) {
      setDados([]);
      setTotalRegistros(0);
      setLoading(false);
      return;
    }
    setTotalRegistros(data.meta.totalItems);
    setDados(data.items);
    setLoading(false);
  }, [filtrosListarExtratosVeiculares, get, paginationSortSearch]);

  const listarConsolidados = useCallback(async () => {
    if (limit < 100) {
      handleLimitChange(100);
    }

    const filtros = ajustarFiltrosDetalhados(filtrosListarDetalhadosExtratosVeiculares);
    setLoading(true);
    const { data, hasErro } = await get(`/veiculo/extrato/consolidado?${filtros}&order=DESC&${paginationSortSearch}`);

    if (hasErro) {
      setDados([]);
      setTotalRegistros(0);
      setLoading(false);
      return;
    }
    setTotalRegistros(data.meta.totalItems);
    setDados(data.items);
    setLoading(false);
  }, [get, filtrosListarDetalhadosExtratosVeiculares, paginationSortSearch]);

  const handleExportExtratosConsolidados = useCallback(async () => {
    const queryParams = extratosVeicularesSelecionados.length ? `extratos=${extratosVeicularesSelecionados.join(",")}` : "";
    const filtros = ajustarFiltrosDetalhados(filtrosListarDetalhadosExtratosVeiculares);
    setLoadingRelatorio(true);
    const { data, hasErro } = await get(`/veiculo/extrato/consolidado?${filtros}&paginate=0&${queryParams}`);

    if (hasErro) {
      setLoadingRelatorio(false);
      setShowOpcoesExportarExcel(false);
      toastErro("Ocorreu um erro ao buscar dados da planilha");
      return;
    }

    const dadosPlanilha = data.map((item: any) => ({
      Placa: item.placa,
      Renavam: item.renavam,
      Chassi: item.chassi,
      IPVA: item.ipvas,
      Lic: item.licenciamentos,
      Multa: item.multas,
      Gravame: item.gravames,
      Restrições: item.restricoes,
      Recall: item.recalls,
      "Data/Hora da consulta": converterDataHoraBr(item.dataHoraCadastro),
      "Status da consulta": item.statusExtratoVeicular,
      "Tentativas de consulta": String(item.tentativasConsulta).padStart(2, "0"),
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Dados");
    writeFileXLSX(workBook, "Extrato Veicular.xlsx");
    setLoadingRelatorio(false);
    setShowOpcoesExportarExcel(false);
  }, [get, extratosVeicularesSelecionados, filtrosListarDetalhadosExtratosVeiculares]);

  function ajustarFiltros(filtros: IFiltrosListarConsultasExtratoVeicular | null): string {
    const ajusteFiltros = [
      filtros?.id ? `idConsulta=${filtros.id}` : null,
      filtros?.placa ? `placa=${filtros.placa}` : null,
      filtros?.dataInicial && filtros.dataFinal
        ? `dataInicio=${new Data(filtros.dataInicial).getDataEua()}&dataFim=${new Data(filtros.dataFinal).getDataEua()}`
        : null,
      filtros?.tipoConsulta ? `tipoConsulta=${filtros.tipoConsulta}` : null,
      filtros?.renavam ? `renavam=${filtros.renavam}` : null,
      filtros?.chassi ? `chassi=${filtros.chassi}` : null,
      filtros?.empresa ? `idEmpresa=${filtros.empresa}` : null,
      filtros?.cobrar ? `cobranca=${filtros.cobrar}` : null,
    ]
      .filter((item) => item !== null)
      .join("&");

    return ajusteFiltros;
  }

  function ajustarFiltrosDetalhados(filtros: IFiltrosListarConsultasUnitariasExtratoVeicular | null) {
    const ajusteFiltros = [
      filtros?.id ? `idConsulta=${filtros.id}` : null,
      filtros?.placa ? `placa=${filtros.placa}` : null,
      filtros?.dataInicial && filtros.dataFinal
        ? `dataInicio=${new Data(filtros.dataInicial).getDataEua()}&dataFim=${new Data(filtros.dataFinal).getDataEua()}`
        : null,
      filtros?.tipoConsulta ? `tipoConsulta=${filtros.tipoConsulta}` : null,
      filtros?.renavam ? `renavam=${filtros.renavam}` : null,
      filtros?.chassi ? `chassi=${filtros.chassi}` : null,
      filtros?.empresa ? `idEmpresa=${filtros.empresa}` : null,
      filtros?.cobrar ? `cobranca=${filtros.cobrar}` : null,
      filtros?.tipoPendencia ? `tipoPendencia=${filtros.tipoPendencia}` : null,
      filtros?.statusConsulta ? `statusConsulta=${filtros.statusConsulta}` : null,
    ]
      .filter((item) => item !== null)
      .join("&");

    return ajusteFiltros;
  }

  function ajustarFiltrosExportDetalhes(filtros: IFiltrosListarConsultasUnitariasExtratoVeicular | IFiltrosListarConsultasExtratoVeicular | null) {
    const ajusteFiltros = [
      filtros?.id ? `idConsulta=${filtros.id}` : null,
      filtros?.placa ? `placa=${filtros.placa}` : null,
      datasExportar[0] && datasExportar[1]
        ? `dataInicio=${new Data(datasExportar[0]).getDataEua()}&dataFim=${new Data(datasExportar[1]).getDataEua()}`
        : null,

      filtros?.tipoConsulta ? `tipoConsulta=${filtros.tipoConsulta}` : null,
      filtros?.renavam ? `renavam=${filtros.renavam}` : null,
      filtros?.chassi ? `chassi=${filtros.chassi}` : null,
      filtros?.empresa ? `idEmpresa=${filtros.empresa}` : null,
      filtros?.cobrar ? `cobranca=${filtros.cobrar}` : null,
      filtros && "tipoPendencia" in filtros && filtros?.tipoPendencia ? `tipoPendencia=${filtros.tipoPendencia}` : null,
      filtros && "statusConsulta" in filtros && filtros?.statusConsulta ? `statusConsulta=${filtros.statusConsulta}` : null,
    ]
      .filter((item) => item !== null)
      .join("&");

    return ajusteFiltros;
  }

  const buscarResumoExtratoVeicular = useCallback(
    async (idLoteExtratoVeicular: number) => {
      return get(`/veiculo/extrato/${idLoteExtratoVeicular}/resumo`);
    },
    [get]
  );

  const handleExportExtratosDetalhes = useCallback(async () => {
    const queryParams = extratosVeicularesSelecionados.length ? `extratos=${extratosVeicularesSelecionados.join(",")}` : "";
    let filtros = ajustarFiltrosExportDetalhes(filtrosListarDetalhadosExtratosVeiculares ?? filtrosListarExtratosVeiculares);
    setLoadingRelatorio(true);

    if (consultasSelecionadas.length) {
      filtros += consultasSelecionadas.length ? `idConsulta=${consultasSelecionadas.join(",")}` : "";
    }

    const { data, hasErro } = await get(`/veiculo/extrato/detalhes?${filtros}&paginate=0&${queryParams}`);

    if (hasErro) {
      setLoadingRelatorio(false);
      setShowOpcoesExportarExcel(false);
      toastErro("Ocorreu um erro ao buscar dados da planilha");
      return;
    }
    const tipoConsultaComMultaDetalhada = [
      "PRE_VENDA_NACIONAL", "PRE_VENDA_NACIONAL_SL"
    ];

    const dadosPlanilha = data.map((item: any) => ({
      "Dados do veículo - Placa": item.dadosExtrato.placa ?? "-",
      "Dados do veículo - Chassi": item.dadosExtrato.chassi ?? "-",
      "Dados do veículo - Renavam": item.dadosExtrato.renavam ?? "-",
      "Dados do veículo - Situação": item.dadosExtrato.situacao ?? "-",
      "Dados do veículo - Município": item.dadosExtrato.municipio ?? "-",
      "Dados do veículo - Marca/Modelo": item.dadosExtrato.marcaModelo ?? "-",
      "Dados do veículo - Ano fab/Ano Mod": item.dadosExtrato.ano ?? "-",
      "Dados do veículo - Cor": item.dadosExtrato.cor ?? "-",
      "Dados do veículo - Espécie": item.dadosExtrato.especie ?? "-",
      "Dados do veículo - Categoria": item.dadosExtrato.categoria ?? "-",
      "Dados do veículo - Número motor": item.dadosExtrato.codigoMotor ?? "-",
      "Dados do veículo - Situação Chassi": item.dadosExtrato.situacaoChassi ?? "-",
      "Ipva": item.dadosExtrato.ipva > 0 ? formatarDinheiro(item.dadosExtrato.ipva / 100) : "Nada consta",
      "Licenciamento": item.dadosExtrato.licenciamento > 0 ? formatarDinheiro(item.dadosExtrato.licenciamento / 100) : "Nada consta",
      "Multas - Total": item.multas.total ? formatarDinheiro(item.multas.total / 100) : "Nada consta",
      "Multas - Detran": item.dadosExtrato.tipoConsulta && tipoConsultaComMultaDetalhada.includes(item.dadosExtrato.tipoConsulta)
        ? item.multas.multas.multasDetran
          ? formatarDinheiro(item.multas.multas.multasDetran / 100)
          : "Nada consta"
        : "-",
      "Multas - Cetesb": item.dadosExtrato.tipoConsulta && tipoConsultaComMultaDetalhada.includes(item.dadosExtrato.tipoConsulta)
        ? item.multas.multas.multasCetesb
          ? formatarDinheiro(item.multas.multas.multasCetesb / 100)
          : "Nada consta"
        : "-",
      "Multas - Municipais": item.dadosExtrato.tipoConsulta && tipoConsultaComMultaDetalhada.includes(item.dadosExtrato.tipoConsulta)
        ? item.multas.multas.multasMunicipais
          ? formatarDinheiro(item.multas.multas.multasMunicipais / 100)
          : "Nada consta"
        : "-",
      "Multas - Renainf": item.dadosExtrato.tipoConsulta && tipoConsultaComMultaDetalhada.includes(item.dadosExtrato.tipoConsulta)
        ? item.multas.multas.multasRenainf
          ? formatarDinheiro(item.multas.multas.multasRenainf / 100)
          : "Nada consta"
        : "-",
      "Multas - Dersa": item.dadosExtrato.tipoConsulta && tipoConsultaComMultaDetalhada.includes(item.dadosExtrato.tipoConsulta)
        ? item.multas.multas.multasDersa
          ? formatarDinheiro(item.multas.multas.multasDersa / 100)
          : "Nada consta"
        : "-",
      "Multas - DER": item.dadosExtrato.tipoConsulta && tipoConsultaComMultaDetalhada.includes(item.dadosExtrato.tipoConsulta)
        ? item.multas.multas.multasDer
          ? formatarDinheiro(item.multas.multas.multasDer / 100)
          : "Nada consta"
        : "-",
      "Multas - PRF": item.dadosExtrato.tipoConsulta && tipoConsultaComMultaDetalhada.includes(item.dadosExtrato.tipoConsulta)
        ? item.multas.multas.multasPoliciaRodoviariaFederal
          ? formatarDinheiro(item.multas.multas.multasPoliciaRodoviariaFederal / 100)
          : "Nada consta"
        : "-",
      "Restrições": item.bloqueios ?? "Nada consta",
      "Gravame - Nome da financeira": item.gravames.nomeFinanceira ?? "Nada consta",
      "Gravame - Data Contrato": item.gravames.dataContrato ?? "Nada consta",
      "Gravame - Nome Contratante": item.gravames.nomeContratante ?? "Nada consta",
      "Gravame - Número Contrato": item.gravames.numeroContrato ?? "Nada consta",
      "Gravame - Situação": item.gravames.situacao ?? "Nada consta",
      "Recall - Identificador": item.recalls.identificador ?? "Nada consta",
      "Recall - Data Registro": item.recalls.dataRegistro ? new Data(item.recalls.dataRegistro).getDataBr() : "Nada consta",
      "Recall - Descrição do defeito": item.recalls.descricaoDefeito ?? "Nada consta",
      "Leilão": item.leiloes,
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 20 },
      { wch: 30 },
      { wch: 10 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Dados");
    writeFileXLSX(workBook, "Extrato de Débito.xlsx");
    setLoadingRelatorio(false);
    setShowOpcoesExportarExcel(false);
  }, [get, extratosVeicularesSelecionados, consultasSelecionadas, ajustarFiltrosExportDetalhes, filtrosListarDetalhadosExtratosVeiculares, filtrosListarExtratosVeiculares]);


  return {
    dados,
    setFiltrosListarExtratosVeiculares,
    handleLimitChange,
    handlePageChange,
    totalRegistros,
    loading,
    listarExtratosVeiculares,
    listarConsolidados,
    limit,
    buscarResumoExtratoVeicular,
    setFiltrosListarDetalhadosExtratosVeiculares,
    handleExportExtratosConsolidados,
    setExtratosVeicularesSelecionados,
    setConsultasSelecionadas,
    extratosVeicularesSelecionados,
    consultasSelecionadas,
    handleExportExtratosDetalhes,
    filtrosListarExtratosVeiculares,
    filtrosListarDetalhadosExtratosVeiculares,
    datasExportar,
    setDatasExportar,
    loadingRelatorio,
    showOpcoesExportarExcel,
    setShowOpcoesExportarExcel,
  };
}

