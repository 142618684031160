import temaPadrao from "_config/temas/estilo/base";
import br from "date-fns/locale/pt-BR";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { IMaskInput } from "react-imask";
import { Container, CustomButton } from "./style";

interface IDatePickerProps {
	onChange: (date: any, event: React.SyntheticEvent<any, Event> | undefined) => void;
	selected?: Date | null | undefined;
	rangeSelect?: boolean;
	startDate?: Date;
	endDate?: Date;
	mensagemErro?: string;
	isValid?: boolean;
	isInvalid?: boolean;
	isClearable?: boolean;
	placeholder?: string;
	disableFutureDates?: boolean;
}

export function CustomDatePickerV2({
	onChange,
	selected,
	rangeSelect = false,
	endDate,
	startDate,
	mensagemErro,
	isValid = false,
	isInvalid = false,
	isClearable = true,
	placeholder = "Selecione a data",
	disableFutureDates = false,
}: Readonly<IDatePickerProps>) {
	return (
		<Container>
			<InputGroup>
				<CustomButton
					isClearable={isClearable}
					locale={br}
					dateFormat="dd/MM/yyyy"
					selected={selected}
					onChange={onChange}
					selectsRange={rangeSelect}
					endDate={endDate}
					startDate={startDate}
					placeholderText={placeholder}
          maxDate={disableFutureDates ? new Date() : null}
					customInput={
						<Form.Control
							as={IMaskInput}
							mask={"00/00/0000"}
							placeholder={placeholder}
							isInvalid={isInvalid}
							isValid={isValid}
						/>
					}
				/>
			</InputGroup>
			<p
				style={{
					fontSize: "0.85rem",
					color: temaPadrao.colors.danger.main,
					marginTop: "5px",
				}}
			>
				{mensagemErro}
			</p>
		</Container>
	);
}
