import { yupResolver } from "@hookform/resolvers/yup";
import { FilterButton } from "components/Button/style";
import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect } from "components/DropdownSelect";
import { useQuery } from "features/core/hooks/useQuery";
import {
  TIPO_DEBITOS_OPTIONS,
  VENCIMENTO_OPTIONS,
} from "features/relatorios/recusados/constants/pagamentosRecusadosConstants";
import { filtrosRelatorioPagamentosRecusadosSchema } from "features/relatorios/recusados/validate/validator";
import { useMaskInput } from "hooks/useMaskInput";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter } from "react-icons/fa";
import { ButtonContainer, InputContainer, MaisFiltrosContainer } from "./style";
import { MaisFiltros } from "components/MaisFiltros";
import { useMaisFiltros } from "hooks/useMaisFiltros";

interface IFiltrosRelatorioPagamentos {
  handleSubmitFiltros: (data: any) => void;
}

export function FiltrosRelatorioPagamentosRecusados({
  handleSubmitFiltros,
}: IFiltrosRelatorioPagamentos) {
  const {
    handleAplicarMaisFiltros,
    handleFormSubmit,
    handleNaoUsarMaisFiltros,
  } = useMaisFiltros();
  const { mInputPlaca } = useMaskInput();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(filtrosRelatorioPagamentosRecusadosSchema),
  });

  const query = useQuery();

  useEffect(() => {
    if (query.get("dataRecusa") != null) {
      handleSubmitFiltros({ dataRecusa: query.get("dataRecusa") });
    }
  }, []);

  return (
    <Container titulo="Filtros">
      <Form
        noValidate
        onSubmit={handleSubmit((dados) => {
          handleFormSubmit({
            dados,
            submitCallback: handleSubmitFiltros,
            excluir: ["renavam"],
            resetCallback: reset,
          });
        })}
      >
        <Row>
          <InputContainer lg="2">
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Digite a placa"
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="tipoDebito"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    title="Tipo de Débito"
                    onSelect={onChange}
                    options={TIPO_DEBITOS_OPTIONS}
                    selectedValue={value}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="vencimento"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={VENCIMENTO_OPTIONS}
                    title="Vencimento"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <InputContainer lg="2">
            <Form.Group>
              <Form.Label>Data de recusa</Form.Label>
              <Controller
                control={control}
                name="dataRecusa"
                render={({ field: { onChange } }) => (
                  <CustomInput
                    type="date"
                    onChange={(e: any) => onChange(e.target.value)}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <InputContainer lg="2">
            <ButtonContainer>
              <FilterButton type="submit" disabled={false} loading={false}>
                <FaFilter /> Filtrar
              </FilterButton>
              <FilterButton
                type="reset"
                onClick={() => {
                  reset(() => ({
                    renavam: "",
                    placa: "",
                    tipoDebito: null,
                    vencimento: null,
                    dataRecusa: undefined,
                  }));
                }}
                disabled={false}
                loading={false}
                outline={true}
              >
                Limpar
              </FilterButton>
            </ButtonContainer>
          </InputContainer>
          <Col lg="2">
            <MaisFiltros
              cancelarOnClick={() => {
                handleNaoUsarMaisFiltros({
                  resetCallback: reset,
                  excluir: ["renavam"],
                });
              }}
              handleClickAplicarFiltros={handleAplicarMaisFiltros}
              limparTudoOnClick={() => {
                handleNaoUsarMaisFiltros({
                  resetCallback: reset,
                  excluir: ["renavam"],
                });
              }}
            >
              <Form.Group>
                <Form.Label>Renavam</Form.Label>
                <Controller
                  control={control}
                  name="renavam"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      value={value ?? ""}
                      placeholder="Digite o renavam"
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </MaisFiltros>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

