import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container, IconeContainer, Texto, TextoContainer } from "./style";
interface IModalConfirmacaoSolicitacao {
  show: boolean;
  handleClose: () => void;
  idSolicitacao: number;
}

export function ModalConfirmacaoSolicitacao({
  show,
  handleClose,
  idSolicitacao,
}: IModalConfirmacaoSolicitacao) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Solicitar Pagamento"
      centered={true}
    >
      <Container>
        <IconeContainer>
          <FaCheck />
        </IconeContainer>
        <TextoContainer>
          <Texto>Solicitação de pagamento efetuada com sucesso</Texto>
          <Texto>ID de solicitação: {idSolicitacao}</Texto>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}

