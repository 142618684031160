import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from "react";

export const DropdownMenuContext = createContext({
	open: false,
	toggleMenu: () => {},
	dropdownButtonId: "",
	dropdownMenuId: "",
});

export function DropdownMenuContextProvider({ children }: Readonly<PropsWithChildren>) {
	const [open, setOpen] = useState(false);
	const dropdownButtonId = useMemo(() => `dropdown-button-${String(Math.random()).replace(".", "")}`, []);
	const dropdownMenuId = useMemo(() => `dropdown-menu-${String(Math.random()).replace(".", "")}`, []);

	function toggleMenu() {
		setOpen((prev) => !prev);
	}
	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			const target = event.target as HTMLElement;
			if (open && !target.closest(`#${dropdownButtonId}`) && !target.closest(`#${dropdownMenuId}`)) {
				setOpen(false);
			}
		},
		[open, dropdownButtonId, dropdownMenuId],
	);

	const handlePressEsc = useCallback((event: KeyboardEvent) => {
		if (event.key === "Escape") {
			setOpen(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("keydown", handlePressEsc);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("keydown", handlePressEsc);
		};
	}, [open, handleClickOutside, handlePressEsc]);

	const contextValues = useMemo(
		() => ({
			open,
			toggleMenu,
			dropdownButtonId,
			dropdownMenuId,
		}),
		[open, dropdownButtonId, dropdownMenuId],
	);

	return <DropdownMenuContext.Provider value={contextValues}>{children}</DropdownMenuContext.Provider>;
}
