import { toastErro } from "components/Toast";
import { useEffect, useState } from "react";
import api from "_services/api";
import { IDashboardHookProps } from "../interfaces/IDashboardHookProps.interface";

export function useChartConsultasSolicitacoes({
  empresaSelecionada,
  dataInicial,
  dataFinal,
}: IDashboardHookProps) {
  const estadoInicialGrafico = [
    {
      name: "PLACAS CONSULTADAS",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "PGTOS SOLICITADOS",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];
  const [dados, setDados] = useState(estadoInicialGrafico);

  useEffect(() => {
    buscarDados();
  }, [empresaSelecionada, dataInicial, dataFinal]);

  const state = {
    options: {
      chart: {
        id: "consultasSolicitacoes",
        fontFamily: "Roboto",
      },
      xaxis: {
        categories: [
          "JAN",
          "FEV",
          "MAR",
          "ABR",
          "MAI",
          "JUN",
          "JUL",
          "AGO",
          "SET",
          "OUT",
          "NOV",
          "DEZ",
        ],
      },
      title: {
        text: "Overview - Consultas e Solicitações",
      },
      colors: ["#FAC858", "#73C0DE"],
      yaxis: {
        labels: {
          formatter: (val: number) => val.toLocaleString("pt-br"),
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    series: dados,
  };

  async function buscarDados(): Promise<void> {
    try {
      const empresaQuery =
        empresaSelecionada && empresaSelecionada != null
          ? `&idEmpresa=${empresaSelecionada}`
          : "";

      const dataInicialQuery =
        dataInicial && dataInicial != null
          ? `&dataInicial=${dataInicial}`
          : "";

      if (!dataFinal) {
        return;
      }

      const { data } = await api.get(
        `/debito/dashboard/consultas?${dataInicialQuery}&dataFinal=${dataFinal}${empresaQuery}`
      );

      const newDados = [...dados];

      data.map((m: any) => {
        newDados[0].data[m.mes - 1] = m.placasConsultadas;
        newDados[1].data[m.mes - 1] = m.placasSolicitadoPagamento;
      });

      setDados(newDados);
    } catch (e: any) {
      return setDados(estadoInicialGrafico);
    }
  }

  return { state };
}
