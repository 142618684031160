import styled from "styled-components";

interface TagProps {
  borderColor: string;
  backgroundColor: string;
}

interface SubContainerProps {
  visible?: boolean;
  flexDirection?: string;
  width?: number | string;
}

interface IconBackgroundProps {
  color: string;
  textBackground?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 30px;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 30px;
`;

export const SubContainer = styled.div<SubContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
  gap: 15px;
  padding: 16px;
  width: 100%;
  background-color: ${({ theme, visible }) =>
    visible == false ? "transparent" : theme.colors.white};
  border-radius: 3px;

  .titulo {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  select {
    width: 100%;
    border: none;
  }

  h2 {
    font-weight: bolder;
  }
`;

export const SubContainerShadow = styled.div<SubContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
  gap: 15px;
  padding: 16px;
  width: 100%;
  background-color: ${({ theme, visible }) =>
    visible == false ? "transparent" : theme.colors.white};
  border-radius: 3px;
  box-shadow: 2px 2px 4px rgb(0,0,0,25%);

  .titulo {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  select {
    width: 100%;
    border: none;
  }

  h2 {
    font-weight: bolder;
  }
`;

export const DatePickerContainer = styled.div<SubContainerProps>`
  width: 100%;

  input {
    width: 100%;
    border: none;
    background-color: #efefef;
  }

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    padding: 6px 0;
    border-radius: 10px;
  }
  .react-datepicker__day--today {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--in-selecting-range{
    background-color: ${({ theme }) => theme.colors.project.bgColor};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  button.react-datepicker__close-icon::after {
    background-color: ${({ theme }) => theme.colors.project.main};
  }
`;

export const RowDirection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

export const IconBackground = styled.div<IconBackgroundProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 2rem;
  height: 2rem;
  background-color: ${({ color }) => color};
  border-radius: 50px;
  color: #fff;
  font-weight: bolder;

  p {
    margin: 0;
    background-color: ${({ textBackground }) => textBackground};
    border-radius: 50px;
    width: 75%;
    height: 75%;
    padding-top: 1px;
  }
`;

export const TituloWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  white-space: nowrap;
`;

export const Link = styled.a`
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  cursor: pointer;
`;

export const Tag = styled.div<TagProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 50px;
  color: ${({ borderColor }) => borderColor};
  padding: 8px;
`;
