import { useRequest } from "hooks/useRequest";
import { useState } from "react";
import { IAlterarSenha } from "../@types/IAlterarSenha";

export function useRedefinirSenha() {
  const [loading, setLoading] = useState<boolean>(false);

  const api = useRequest("api");

  async function gravarSenha({ senha, token }: IAlterarSenha) {
    try {
      setLoading(true);
      return await api.patch(`/usuario`, {
        senha: senha,
        data: token,
      });
    } finally {
      setLoading(false);
    }
  }

  return {
    gravarSenha,
    loading,
  };
}
