import { PrimaryButton } from "components/Button/style";
import { Container } from "components/Container";
import { BodyPergunta, BodyTexto, ButtonsContainer, ModalBodyContainer } from "./style";
import { FaDownload, FaExclamationTriangle, FaFileAlt, FaFileExcel, FaFileSignature } from "react-icons/fa";
import { Table } from "containers/Table";
import { IDetalhesEmissao } from "../interfaces/IDetalhesEmissao";
import { IColum } from "containers/Table/interfaces";
import { StatusEmissaoLicenciamento } from "components/StatusEmissaoLicenciamento";
import { converterDataBr } from "_utils/Data";
import { ModalCustom } from "components/ModalCustom";
import { ModalCheckbox } from "./containers/ModalCheckbox";
import { Tooltip } from "components/Tooltip";
import { TentativasCRLV } from "./containers/TentativasCRLV";
import { StatusEmissaoLicenciamentoEnum } from "../Enum/StatusEmissaoLicenciamento";
import { InfoTooltip } from "components/InfoTooltip";
import { ModalEmissaoManual } from "./containers/ModalEmissaoManual";

interface Props {
  idEmissao: string | undefined;
  loading: boolean;
  dados: IDetalhesEmissao[];
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (limit: number) => void;
  totalRows: number;
  rowsPerPage: number;
  setSelectedRows: any;
  showModalDownload: boolean;
  onConfirmModalDownload: () => void;
  handleCloseModalDownload: () => void;
  onClickDownload: () => void;
  showModalExportar: boolean;
  onConfirmModalExportar: () => void;
  handleCloseModalExportar: () => void;
  onClickExportar: () => void;
  clearSelectedRows: boolean;
  onClickEmitir: () => void;
  disabledEmitir: boolean;
  disabledEmissaoManual: boolean;
  onClickEmissaoManual: () => void;
  exibirModalEmissaoManual: boolean;
  anoSolicitado: number;
}

function makeStatusEmissao(statusEmissao: number) {
  return <StatusEmissaoLicenciamento statusEmissao={statusEmissao} />;
}

export function makeTentativasCRLV(tentativas: number, statusEmissao: number) {
  return <TentativasCRLV tentativas={tentativas} statusEmissao={statusEmissao} />;
}

export function ListarDetalhes({
  idEmissao,
  loading = false,
  dados,
  onChangePage,
  onChangeRowsPerPage,
  totalRows,
  rowsPerPage,
  setSelectedRows,
  showModalDownload,
  onConfirmModalDownload,
  handleCloseModalDownload,
  onClickDownload,
  showModalExportar,
  onConfirmModalExportar,
  handleCloseModalExportar,
  onClickExportar,
  clearSelectedRows,
  onClickEmitir,
  disabledEmitir,
  disabledEmissaoManual,
  onClickEmissaoManual,
  anoSolicitado,
}: Readonly<Props>) {
  const quantidadeCaracteresTextoLongo = 70;
  const limiteExibirTentativasComStatusEmitido = 1;
  const widthStrategy = { md: 300, lg: 480 };
  const widthObservacao = `${Math.max(
    ...dados.map((item) =>
      item.observacao && item.observacao.length > quantidadeCaracteresTextoLongo ? widthStrategy.lg : widthStrategy.md
    )
  )}px`;

  const columns: IColum<IDetalhesEmissao>[] = [
    {
      name: <span>ID</span>,
      selector: ({ id }: IDetalhesEmissao) => id,
      omit: true,
    },
    {
      name: <span>Placa</span>,
      selector: ({ placa }: IDetalhesEmissao) => placa?.toUpperCase(),
      width: "100px",
    },
    {
      name: <span>Renavam</span>,
      selector: ({ renavam }: IDetalhesEmissao) => renavam?.toUpperCase(),
      width: "100px",
    },
    {
      name: <span>Chassi</span>,
      selector: ({ chassi }: IDetalhesEmissao) => chassi?.toUpperCase() ?? "--",
      width: "160px",
    },
    {
      name: "CPF/CNPJ",
      selector: ({ cpfCnpjProprietario }: IDetalhesEmissao) => cpfCnpjProprietario ?? "--",
      omit: !dados.filter((item) => item.uf !== "PR").length,
      width: "130px",
    },
    {
      name: <span>CRV</span>,
      selector: ({ crv }: IDetalhesEmissao) => crv ?? "--",
      omit: !dados.filter((item) => item.uf == "MG").length,
    },
    {
      name: <span>UF</span>,
      selector: ({ uf }: IDetalhesEmissao) => uf?.toUpperCase() ?? "--",
      width: "50px",
    },
    {
      name: <span>Exercício</span>,
      selector: ({ ano, statusEmissao }: IDetalhesEmissao) => ano && statusEmissao == StatusEmissaoLicenciamentoEnum.Emitido ? ano : "--",
      width: "90px",
    },
    {
      name: <span>Data de emissão</span>,
      selector: ({ dataConsulta, statusEmissao }: IDetalhesEmissao) =>
        dataConsulta && statusEmissao == StatusEmissaoLicenciamentoEnum.Emitido ? converterDataBr(new Date(dataConsulta)) : "--",
      width: "180px",
    },
    {
      name: <span>Observação</span>,
      selector: ({ observacao, statusEmissao }: IDetalhesEmissao) => {
        if (statusEmissao == StatusEmissaoLicenciamentoEnum.Emitido && observacao !== "Emissão Manual") {
          return "CRLV emitido com sucesso";
        }

        return (observacao && observacao !== "") ? observacao : "--";
      },
      width: widthObservacao,
    },
    {
      name: <span>Status Emissão</span>,
      selector: ({ statusEmissao }: IDetalhesEmissao) => {
        return makeStatusEmissao(statusEmissao);
      },
      width: "170px",
    },
    {
      name: <span>ID Recibo</span>,
      selector: ({ idRecibo }: IDetalhesEmissao) => idRecibo ?? "--",
    },
    {
      name: <span>Tentativas</span>,
      selector: ({ tentativas, statusEmissao }: IDetalhesEmissao) => makeTentativasCRLV(tentativas, Number(statusEmissao)),
    },
  ];
  const bottomTooltipEmitir = 2.3;
  const rightTooltipEmitir = -115;
  const widthTooltipEmitir = 240;

  return (
    <Container titulo={`Detalhes Emissão de Licenciamentos - ID ${idEmissao}${anoSolicitado ? ` Exercício solicitado ${anoSolicitado}` : ""}`}>
      <Table
        loading={loading}
        data={dados}
        columns={columns}
        keyField="id"
        paginationRowsPerPageOptions={[10, 20, 50]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        pagination
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        selectableRows
        setRowsSelected={(rows) => setSelectedRows(rows)}
        clearSelectedRows={clearSelectedRows}
        toolsHeader={
          <ButtonsContainer>
            <PrimaryButton variante="primary" outline onClick={onClickEmissaoManual} disabled={disabledEmissaoManual}>
              Emissão manual <FaFileSignature />
            </PrimaryButton>
            <Tooltip
              bottom={bottomTooltipEmitir}
              right={rightTooltipEmitir}
              width={widthTooltipEmitir}
              trigger={
                <PrimaryButton variante="primary" outline onClick={onClickEmitir} disabled={disabledEmitir}>
                  Reemitir <FaFileAlt />
                </PrimaryButton>
              }
            >
              <InfoTooltip
                mensagem={<span>Emitir novamente todas as placa(s) com status de <strong>Falha</strong></span>}
              ></InfoTooltip>
            </Tooltip>

            <PrimaryButton onClick={onClickDownload}>
              Download CRLV <FaDownload />
            </PrimaryButton>
          </ButtonsContainer>
        }
        exportExcel
        buttonExportVariant="secondary"
        textoBotaoExport="Exportar CRLV"
        handleExport={onClickExportar}
        iconeExport={FaFileExcel}
      />
      <ModalCheckbox
        showModal={showModalDownload}
        handleClose={handleCloseModalDownload}
        onConfirm={onConfirmModalDownload}
        tipoModal="download"
      />

      <ModalCustom
        show={showModalExportar}
        handleClose={handleCloseModalExportar}
        title="Exportar CRLV"
        onConfirm={onConfirmModalExportar}
        centered
        footer
        footerConfirmButton
      >
        <ModalBodyContainer>
          <BodyTexto>
            A seleção do checkbox contempla apenas os veículos listados na página atual, para exportar todos os CRLVs
            disponíveis no lote, clique no botão <strong>Exportar CRLV</strong> sem selecionar nenhum checkbox.
          </BodyTexto>
          <BodyPergunta>
            <FaExclamationTriangle></FaExclamationTriangle>
            Deseja exportar os CRLVs selecionados?
          </BodyPergunta>
        </ModalBodyContainer>
      </ModalCustom>
    </Container>
  );
}

