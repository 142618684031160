import styled from "styled-components";

export const TextoClaro = styled.span`
	color: ${({ theme }) => theme.colors.neutral.neutral40};
`;

export const InputsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
`;

export const InputContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 1rem 0;
	gap: 0.25rem;

	p {
		margin: 0;
	}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
`;