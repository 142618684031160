import { Text, View } from "@react-pdf/renderer";
import { IDadosRestricoes } from "../../../../../DadosRestricoes";
import { STYLES } from "../../../../style";

interface Props {
  restricao: IDadosRestricoes;
}

export function DetalhesRestricoesPDF({ restricao }: Readonly<Props>) {
  return (
    <View style={[STYLES.BORDER_TOP_BOTTOM, STYLES.BACKGROUND_BRANCO, STYLES.PADDING_04, { marginBottom: 6 }]}>
      <View style={STYLES.SECTION_ROW}>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN]}>
          <Text style={STYLES.FONT_SIZE_08}>Tipo de bloqueio</Text>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.tipoBloqueio}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Número do processo</Text>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.numeroProcessoBloqueio}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Data</Text>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.dataBloqueio}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Motivo bloqueio</Text>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.motivoBloqueio}</Text>
        </View>
      </View>
      <View style={STYLES.SECTION_ROW}>
        <View style={[STYLES.SECTION_COL_2, STYLES.SEM_MARGIN]}>
          <Text style={STYLES.FONT_SIZE_08}>Órgão expedidor</Text>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.orgaoExpedidorBloqueio}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Número Munícipio expedidor</Text>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.municipioExpedidorBloqueio}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>UF Órgão expedidor</Text>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.ufOrgaoExpedidorOficioBloqueio}</Text>
        </View>
      </View>
    </View>
  );
}

