import { DropdownContainer, DropdownItemButton, DropdownItemContainer } from "./style";
import { FaDollarSign, FaExclamationCircle, FaReply } from "react-icons/fa";
import { StatusAdiantamentoConst } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";
import { CompoundDropdown } from "components/CompoundDropdown";
import { useMemo, useState } from "react";
import { handleDisabled } from "./_utils/handleDisabled";
import { handleIcone } from "./_utils/handleIcone";
import { ModalAlterarParaReembolso } from "../ModalAlterarParaReembolso";
import { ModalConfirmarRecebimentoTotal } from "../ModalConfirmarRecebimentoTotal";
import { ModalConfirmarRecebimentoParcial } from "../ModalConfirmarRecebimentoParcial";

export interface StatusAdiantamentoProps {
	statusAdiantamento: string;
	idSolicitacaoPagamento: number;
	listarSolicitacoesPagamento: Callback<Promise<any>>;
}

export function StatusAdiantamento({
	idSolicitacaoPagamento,
	statusAdiantamento,
	listarSolicitacoesPagamento,
}: Readonly<StatusAdiantamentoProps>) {
	const [showModalReembolso, setShowModalReembolso] = useState(false);
	const [showModalConfirmarRecebimentoTotal, setShowModalConfirmarRecebimentoTotal] = useState(false);
	const [showModalConfirmarRecebimentoParcial, setShowModalConfirmarRecebimentoParcial] = useState(false);

	const disableButtons = useMemo(() => handleDisabled(statusAdiantamento), [statusAdiantamento]);
	return (
		<>
			<DropdownContainer>
				<CompoundDropdown.Root>
					<CompoundDropdown.Toggle>
						<DropdownItemContainer statusAdiantamento={statusAdiantamento}>
							{handleIcone(statusAdiantamento)}
							{StatusAdiantamentoConst[statusAdiantamento]}
						</DropdownItemContainer>
					</CompoundDropdown.Toggle>
					<CompoundDropdown.Menu>
						<CompoundDropdown.Item>
							<DropdownItemButton
								disabled={disableButtons.recebimentoTotal}
								onClick={() => {
									setShowModalConfirmarRecebimentoTotal(true);
								}}
							>
								<FaDollarSign /> Confirmar recebimento total
							</DropdownItemButton>
						</CompoundDropdown.Item>
						<CompoundDropdown.Item>
							<DropdownItemButton
								disabled={disableButtons.recebimentoParcial}
								onClick={() => {
									setShowModalConfirmarRecebimentoParcial(true);
								}}
							>
								<FaExclamationCircle /> Informar recebimento parcial
							</DropdownItemButton>
						</CompoundDropdown.Item>
						<CompoundDropdown.Item>
							<DropdownItemButton
								disabled={disableButtons.reembolso}
								onClick={() => {
									setShowModalReembolso(true);
								}}
							>
								<FaReply /> Alterar para reembolso
							</DropdownItemButton>
						</CompoundDropdown.Item>
					</CompoundDropdown.Menu>
				</CompoundDropdown.Root>
			</DropdownContainer>
			<ModalAlterarParaReembolso
				id={idSolicitacaoPagamento}
				listarSolicitacoesPagamento={listarSolicitacoesPagamento}
				show={showModalReembolso}
				handleClose={() => setShowModalReembolso(false)}
			/>
			<ModalConfirmarRecebimentoTotal
				id={idSolicitacaoPagamento}
				listarSolicitacoesPagamento={listarSolicitacoesPagamento}
				show={showModalConfirmarRecebimentoTotal}
				handleClose={() => setShowModalConfirmarRecebimentoTotal(false)}
			/>
			<ModalConfirmarRecebimentoParcial
				id={idSolicitacaoPagamento}
				listarSolicitacoesPagamento={listarSolicitacoesPagamento}
				show={showModalConfirmarRecebimentoParcial}
				handleClose={() => setShowModalConfirmarRecebimentoParcial(false)}
			/>
		</>
	);
}
