import styled from "styled-components";

export const TooltipContainer = styled.div`
  text-align: left;
  color: #FFFFFF;
  background-color: #333333;
  padding: 10px 20px;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
  transform: translateX(60%);
`;