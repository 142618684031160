import styled from "styled-components";
import { StatusDebitoPagamento } from "../../Enum/StatusDebitoPagamento";

interface StatusProps {
  statusPagamento: number;
}

export const Container = styled.button<StatusProps>`
  padding: 0.2rem 0.6rem;
  border-radius: 1rem;
  text-align: center;
  width: 100px;
  ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Pendente"] &&
    `
        background-color:${theme.colors.tags.warning.bgColor};
        color:${theme.colors.tags.warning.textColor};
        border:1px solid ${theme.colors.tags.warning.textColor};
    `}

  ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Erro"] &&
    `
        background-color:${theme.colors.tags.danger.bgColor};
        color:${theme.colors.tags.danger.textColor};
        border:1px solid ${theme.colors.tags.danger.textColor};
    `}

    ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Indeferido"] &&
    `
        background-color:${theme.colors.tags.info02.bgColor};
        color:${theme.colors.tags.info02.textColor};
        border:1px solid ${theme.colors.tags.info02.textColor};
    `}

    ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Pago"] &&
    `
        background-color:${theme.colors.tags.success.bgColor};
        color:${theme.colors.tags.success.textColor};
        border:1px solid ${theme.colors.tags.success.textColor};
    `}

    ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Processando"] &&
    `
        background-color:${theme.colors.tags.info.bgColor};
        color:${theme.colors.tags.info.textColor};
        border:1px solid ${theme.colors.tags.info.textColor};
    `}

    ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Recusado"] &&
    `
        background-color:${theme.colors.tags.disabled.bgColor};
        color:${theme.colors.tags.disabled.textColor};
        border:1px solid ${theme.colors.tags.disabled.textColor};
    `}

    ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Pago manual"] &&
    `
        background-color:${theme.colors.tags.disabled.bgColor};
        color:${theme.colors.primary.main};
        border:1px solid ${theme.colors.primary.main};
    `}
`;
