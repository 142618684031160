import { Route, Routes } from "react-router-dom";
import { ConsultarVeiculos } from "../consultar-veiculos/pages/Listar";
import { ConsultarVeiculosDetalhes } from "../consultar-veiculos/pages/Detalhes";
import { ExtratoVeicularDetalhado } from "../consultar-veiculos/pages/ExtratoVeicularDetalhado";
import { ListarExtratosVeicularesContextProvider } from "../consultar-veiculos/pages/Listar/context/ListarExtratosVeicularesContext";

export function ExtratoVeicularRoutes() {
  return (
    <Routes>
      <Route
        path="/consultas"
        element={
          <ListarExtratosVeicularesContextProvider>
            <ConsultarVeiculos />
          </ListarExtratosVeicularesContextProvider>
        }
      />
      <Route path="/consultas/:id" element={<ConsultarVeiculosDetalhes />} />
      <Route
        path="/consultas/:idLoteExtratoVeicular/detalhes/:idExtratoVeicular"
        element={<ExtratoVeicularDetalhado />}
      />
    </Routes>
  );
}
