import axios, { InternalAxiosRequestConfig, AxiosHeaders, HttpStatusCode } from "axios";
import { getToken } from "../auth";

const msFinanceiro = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_MS_FINANCEIRO,
});

msFinanceiro.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    } as unknown as AxiosHeaders;
    config.headers = headers;
  }

  return config;
});

export default msFinanceiro;
