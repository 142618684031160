import { ModalCustom } from "components/ModalCustom";
import { BodyEmissaoManual } from "./containers/BodyEmissaoManual";
import { IEmissaoManual } from "../../../interfaces/IEmissaoManual";
import { ContainerForm } from "./style";

interface Props {
  showModal: boolean;
  handleClose: () => void;
  handleSubmit: (dados: IEmissaoManual) => void;
  ano: string | number;
}

export function ModalEmissaoManual({
  handleClose,
  showModal,
  handleSubmit,
  ano,
}: Readonly<Props>) {
  return (
    <ModalCustom
      show={showModal}
      handleClose={handleClose}
      title="Informar emissão manual"
      disabledConfirmButton
      centered
      size="lg"
    >
      <ContainerForm>
        <BodyEmissaoManual
          ano={ano}
          handleSubmitEmissaoManual={handleSubmit}
          handleClose={handleClose}
          dataEmissao={new Date()}
        ></BodyEmissaoManual>
      </ContainerForm>
    </ModalCustom>
  );
}

