import { useState } from "react";
import { Container, InputContainer } from "./style";

interface Props {
  handleCheckOption: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CheckBody({ handleCheckOption }: Props) {
  const [selectedOption, setSelectedOption] = useState<string>("lote");

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedOption(event.target.value);
  }

  return (
    <Container>
      <InputContainer>
        <input
          type="radio"
          value="lote"
          checked={selectedOption === "lote"}
          onChange={(event) => {
            handleCheck(event);
            handleCheckOption(event);
          }}
          name="lote"
          id="lote"
        />
        <label htmlFor="lote">Consulta em lote</label>
      </InputContainer>
      <InputContainer>
        <input
          type="radio"
          value="individual"
          checked={selectedOption === "individual"}
          onChange={(event) => {
            handleCheck(event);
            handleCheckOption(event);
          }}
          name="individual"
          id="individual"
        />
        <label htmlFor="individual">Consulta unitária</label>
      </InputContainer>
    </Container>
  );
}
