import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
  gap: 30px;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  flex-wrap: wrap;

  .titulo {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }
`;

export const Linha = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background: #e7eaec;
  margin: 25px 0px;
`;

export const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 40%;

  span {
    color: red;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 40%;
  gap: 20px;
`;

export const ContainerCentralizado = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const DivBackGround = styled.div`
  background-color: ${({ theme }) => theme.colors.project.bgColor};
  width: 32px;
  height: 32px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.project.main};
  margin-bottom: 16px;
`;
