import temaPadrao from "_config/temas/estilo/base";
import { StatusAdiantamentoEnum } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";
import { XCircleFill } from "react-bootstrap-icons";
import { FaExclamationTriangle, FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

export function handleIcone(statusAdiantamento: string) {
	if (statusAdiantamento === StatusAdiantamentoEnum.PENDENTE) {
		return <FaExclamationTriangle color={temaPadrao.colors.warning.main} />;
	}

	if (statusAdiantamento === StatusAdiantamentoEnum.PAGO || statusAdiantamento === StatusAdiantamentoEnum.REEMBOLSO) {
		return <FaCheckCircle color={temaPadrao.colors.success.main} />;
	}

	if (statusAdiantamento === StatusAdiantamentoEnum.AGUARDANDO_VALIDACAO) {
		return <FaExclamationCircle color={temaPadrao.colors.purple._50} />;
	}

	if (statusAdiantamento === StatusAdiantamentoEnum.CANCELADO) {
		return <XCircleFill color={temaPadrao.colors.danger.main} />;
	}

	return <FaExclamationCircle color={temaPadrao.colors.blue._90} />;
}