import styled from "styled-components";

export const TooltipContainer = styled.div`
	padding: 0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
`;

export const TriggerContainer = styled.div`
	padding: 0.25rem;
`;

export const ContentContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.neutral.neutral80};
	padding: 0.5rem;
	border-radius: 0.5rem;
  opacity: 0.85;

	span {
		color: ${({ theme }) => theme.colors.white};
		font-size: 0.875rem;
	}
`;
