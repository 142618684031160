import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../../../style";
import temaPadrao from "_config/temas/estilo/base";
import { IDadosRestricoes } from "../../../../../DadosRestricoes";
import { BodyRestricoesPDF } from "../BodyRestricoesPDF";

interface Props {
  restricoes: IDadosRestricoes[];
}

export function RowRestricoesPDF({ restricoes }: Readonly<Props>) {
  return (
    <View>
      <View
        style={[
          STYLES.SECTION_ROW,
          STYLES.DADOS_PENDENCIA,
          {
            backgroundColor:
              restricoes.length > 0 ? temaPadrao.colors.danger.bgColor : temaPadrao.colors.neutral.neutral10,
          },
        ]}
      >
        <View style={[STYLES.SECTION_COL_2, STYLES.SEM_MARGIN]}>
          <Text style={[STYLES.FONT_SIZE_09]}>Restrições</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN]}>
          <Text style={[STYLES.FONT_SIZE_09]}>{restricoes.length}</Text>
        </View>
      </View>
      {restricoes.length > 0 && <BodyRestricoesPDF restricoes={restricoes} />}
    </View>
  );
}

