import { LoadingCirculo } from "components/LoadingCirculo";
import { ModalCustom } from "components/ModalCustom";
import { PrimaryButton } from "components/Button/style";
import { Container, FormaPgtoTitle, LoadingContainer } from "./style";
import { DadosRecibo } from "./Containers/DadosRecibo";
import { DadosResumoRecibo } from "./Containers/DadosResumo";
import { DadosTransferencia } from "./Containers/DadosTransferencia";
import { InformacaoAdiantamento } from "./Containers/InformacaoAdiantamento";
import { FormaPgto } from "./Containers/FormaPgto";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfReciboAdiantamento } from "./Containers/PdfReciboAdiantamento";
import { IDadosPorTipoDebito } from "features/debito/consulta/pages/Detalhes/Containers/ListarDetalhes/interface/IDadosPorTipoDebito";
import { IDadosClienteReciboAdiantamento } from "features/debito/consulta/pages/Detalhes/Containers/ListarDetalhes/interface/IDadosClienteReciboAdiantamento";
import { DadosPagamento } from "./Containers/DadosPagamento";
import { IPagamento } from "features/relatorios/pagamento/interfaces/Pagamento";
import { StatusAdiantamentoEnum } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";
import { IDadosBanco } from "features/debito/consulta/interfaces/IDadosBanco";

interface IDadosModalReciboAdiantamento {
    dadosPorTipoDebito: IDadosPorTipoDebito[];
    valorTotal: number;
    idReciboAdiantamento: number;
    dadosClienteReciboAdiantamento: IDadosClienteReciboAdiantamento;
    pagamentos: IPagamento[];
    statusAdiantamento: StatusAdiantamentoEnum;
    dadosBanco: IDadosBanco
}

interface Props {
    show: boolean;
    handleClose: () => void;
    abrirReciboAdiantamento: () => Promise<void>;
    loading: boolean;
    formaPgto: FormaPgtoEnum | undefined;
    dados: IDadosModalReciboAdiantamento;
}

export function ModalReciboAdiantamentoRelatorio({
    show,
    handleClose,
    abrirReciboAdiantamento,
    loading,
    formaPgto,
    dados,
}: Props) {

    const statusExibirDadosPagamento = [
        StatusAdiantamentoEnum.PAGO,
        StatusAdiantamentoEnum.PAGAMENTO_INCOMPLETO,
        StatusAdiantamentoEnum.AGUARDANDO_VALIDACAO
    ];

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            size="xl"
            title="Recibo de adiantamento"
            centered={true}
            buttonPosition="end"
            footer={!loading}
            footerTools={
                <PrimaryButton
                    onClick={abrirReciboAdiantamento}
                    disabled={!formaPgto}
                >Exportar recibo</PrimaryButton>
            }
        >
            {loading ? (
                <LoadingContainer>
                    <LoadingCirculo mostrar={loading} />
                </LoadingContainer>
            ) : (
                <>
                    <InformacaoAdiantamento />
                    <DadosRecibo
                        formaPgto={formaPgto}
                        valorTotal={dados.valorTotal}
                        dadosClienteReciboAdiantamento={dados.dadosClienteReciboAdiantamento}
                        idReciboAdiantamento={dados.idReciboAdiantamento}
                    />
                    <DadosResumoRecibo
                        valorTotal={dados.valorTotal}
                        dadosPorTipoDebito={dados.dadosPorTipoDebito}
                    />
                    <Container>
                        <FormaPgtoTitle style={{ marginBottom: 24 }}>Forma de pagamento</FormaPgtoTitle>
                        {formaPgto && <FormaPgto formaPgto={formaPgto} />}
                    </Container>
                    {formaPgto === FormaPgtoEnum.TRANSFERENCIA && <>
                        <DadosTransferencia dados={dados.dadosBanco} />
                    </>}
                    {statusExibirDadosPagamento.includes(dados.statusAdiantamento) && <DadosPagamento
                        statusAdiantamento={dados.statusAdiantamento}
                        pagamentos={dados.pagamentos}
                    />}
                </>
            )}
            {formaPgto && <PDFDownloadLink
                style={{ display: "none" }}
                id={`downloadPDF_${dados.idReciboAdiantamento}`}
                document={
                    <PdfReciboAdiantamento
                        dadosClienteReciboAdiantamento={dados.dadosClienteReciboAdiantamento}
                        idReciboAdiantamento={dados.idReciboAdiantamento}
                        valorTotal={dados.valorTotal}
                        dadosPorTipoBoleto={dados.dadosPorTipoDebito}
                        formaPgto={formaPgto}
                        dadosBanco={dados.dadosBanco}
                    />
                }
                fileName={`ReciboAdiantamento_${dados.idReciboAdiantamento}`}
            />}
        </ModalCustom>
    );
}

