import styled from "styled-components";

interface IProgressProps {
  concluido: boolean;
  temErro: boolean;
}

export const Dialog = styled.dialog`
  max-width: 375px;
  max-height: 305px;
  overflow-y: auto;
  background-color: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  margin: 0 25px 0 auto;
  border: 0;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  padding: 0;
  z-index: 10000000;
`;

export const TitleContainer = styled.div`
  width: 100%;
  padding: 0.25rem 1rem;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.white};
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`;

export const IconesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  svg:hover {
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  padding: 0.25rem 1rem;
  color: ${({ theme }) => theme.colors.black};
  display: grid;
  gap: 0.25rem;

  @keyframes girar {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .rodar {
    animation: girar 2s linear infinite;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const ContainerProgresso = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  progress {
    width: 90%;
  }
`;

export const NomeArquivoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 1rem;
`;

export const NomeArquivoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.25rem;
  font-size: 1rem;
  width: 100%;

  div {
    max-width: 310px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    scrollbar-width: none;
    width: 90%;
  }
`;

export const Progress = styled.progress<IProgressProps>`
  accent-color: ${(props) => getProgressColor(props)};
`;

export const ButtonNomeArquivo = styled.button<{
  temErro: boolean;
  temAviso?: boolean;
}>`
  border: none;
  background-color: transparent;
  transition: color ease-in-out 0.3s;
  text-align: start;
  width: 100%;
  transition: color 0.3s ease-in-out;

  svg {
    transition: color 0.3s ease-in-out;
  }

  span {
    color: ${(props) => getTextColor(props)};
    transition: color 0.3s ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutral.neutral30};
    svg {
      color: ${({ theme }) => theme.colors.neutral.neutral30};
    }

    span {
      color: ${(props) => getTextColor(props, true)};

      svg {
        color: ${(props) => getTextColor(props, true)};
      }
    }
  }
`;

function getProgressColor(props: any) {
  if (props.concluido && props.temErro) {
    return props.theme.colors.danger.main;
  }

  if (props.concluido) {
    return props.theme.colors.success.main;
  }

  return props.theme.colors.primary.main;
}

function getTextColor(props: any, hover = false) {
  if (props.temErro) {
    return hover
      ? props.theme.colors.danger.focus
      : props.theme.colors.danger.main;
  }

  if (props.temAviso) {
    return hover
      ? props.theme.colors.warning.focus
      : props.theme.colors.warning.main;
  }

  return hover
    ? props.theme.colors.success.focus
    : props.theme.colors.success.main;
}
