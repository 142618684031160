import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { intlCurrencyFormat } from "_utils/Money";

interface Props {
  ipvas: any[];
}

export function DadosIpvaPDF({ ipvas }: Readonly<Props>) {
  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>IPVA</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>IPVA</Text>
            <Text style={STYLES.FONT_SIZE_09}>
              {ipvas.length ? "Existe débito de IPVA" : "Não existe débito de IPVA"}
            </Text>
          </View>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor total IPVA</Text>
            <Text style={STYLES.FONT_SIZE_09}>
              {ipvas.length
                ? intlCurrencyFormat(ipvas.map((item) => item.valor / 100).reduce((prev, acc) => acc + prev))
                : "-"}
            </Text>
          </View>
        </View>
        {ipvas.length > 0 && (
          <View style={STYLES.SECTION_ROW}>
            <View style={STYLES.SECTION_COL_2}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Descrição</Text>
            </View>
            <View style={STYLES.SECTION_COL_2}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor</Text>
            </View>
          </View>
        )}
        {ipvas.map((ipva) => (
          <View style={[STYLES.SECTION_ROW, STYLES.DADOS_PENDENCIA]} key={ipva.observacao}>
            <View style={[STYLES.SECTION_COL_2, STYLES.SEM_MARGIN]}>
              <Text style={STYLES.FONT_SIZE_09}>{ipva.observacao}</Text>
            </View>
            <View style={[STYLES.SECTION_COL_2, STYLES.SEM_MARGIN]}>
              <Text style={STYLES.FONT_SIZE_09}>{intlCurrencyFormat(ipva.valor / 100)}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

