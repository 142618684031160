import { Col } from "react-bootstrap";
import styled from "styled-components";

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.375rem;
  padding: 0.5rem 0 0.5rem 0.5rem;

  div {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    scrollbar-width: none;
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

