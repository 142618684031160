export const statusConsulta = [
    {
        value: "2",
        label: "Falha",
    },
    {
        value: "1",
        label: "Finalizada",
    },
    {
        value: "0",
        label: "Processando",
    },
];
