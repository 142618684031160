import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container, IconContainer, IconWrapper, TextoContainer } from "./style";

interface Props {
  show: boolean;
  handleClose: () => void;
  idConsulta: number;
}

export function ModalConfirmacaoConsulta({ handleClose, idConsulta, show }: Readonly<Props>) {
  return (
    <ModalCustom onConfirm={handleClose} footerConfirmButton title="Consultar veículo" centered show={show} handleClose={handleClose}>
      <Container>
        <IconContainer>
          <IconWrapper>
            <FaCheck />
          </IconWrapper>
        </IconContainer>
        <TextoContainer>
          <span>Solicitação efetuada com sucesso! <strong>A consulta será processada</strong></span> <br />
          <span>ID de Solicitação: {idConsulta}</span>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}

