import { formatarDinheiro } from "_utils/Money";
import { STYLES } from "../../style";
import { Text, View } from "@react-pdf/renderer";
import { IDadosPorTipoDebito } from "../../../../../ListarDetalhes/interface/IDadosPorTipoDebito";

interface Props {
    dadosPorTipoDebito: IDadosPorTipoDebito[];
    valorTotal: number;
}
export function DadosResumoReciboPDF({
    dadosPorTipoDebito,
    valorTotal,
}: Props) {
    return (
        <View style={STYLES.CONTAINER}>
            <View style={STYLES.CONTAINER_TITLE}>
                <Text>Resumo</Text>
            </View>
            <View style={STYLES.CONTAINER_BODY}>
                <View style={STYLES.SECTION_ROW}>
                    <View style={[STYLES.RESUMO_ITEM_TITLE, STYLES.SECTION_COL_3]}>
                        <Text style={[STYLES.PADDING_BOTTOM_12, STYLES.TEXTO_QUASE_CLARO]}>Descrição</Text>
                        {dadosPorTipoDebito.filter((item) => item.quantidade && item.valor).map((dado) =>
                            <Text>{dado.descricao}</Text>
                        )}
                    </View>
                    <View style={[STYLES.RESUMO_ITEM_TITLE, STYLES.SECTION_COL_3]}>
                        <Text style={[STYLES.PADDING_BOTTOM_12, STYLES.TEXTO_QUASE_CLARO]}>Quantidade</Text>
                        {dadosPorTipoDebito.filter((item) => item.quantidade && item.valor).map((dado) =>
                            <Text>{dado.quantidade}</Text>
                        )}
                    </View>
                    <View style={[STYLES.RESUMO_ITEM_TITLE, STYLES.SECTION_COL_3, { textAlign: "right" }]}>
                        <Text style={[STYLES.PADDING_BOTTOM_12, STYLES.TEXTO_QUASE_CLARO]}>Valor</Text>
                        {dadosPorTipoDebito.filter((item) => item.quantidade && item.valor).map((dado) =>
                            <Text style={{ textAlign: "right" }}>{formatarDinheiro(dado.valor / 100)}</Text>
                        )}
                    </View>
                </View>
                <View style={{ margin: "12px 16px 12px 16px", borderBottom: "1px solid #e6e6e6" }} />
                <View style={STYLES.SECTION_ROW}>
                    <View style={[STYLES.TOTAL_ITEM, STYLES.SECTION_COL_2]}><Text>Total:</Text></View>
                    <View style={[STYLES.TOTAL_ITEM, STYLES.SECTION_COL_2, { textAlign: "right" }]}><Text>{formatarDinheiro(valorTotal / 100)}</Text></View>
                </View>
            </View>
        </View>
    );
}