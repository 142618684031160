import { useEffect, useState } from "react";

export default function useNotificacao() {
  interface Notificacao {
    message?: string;
    url?: string;
    data?: string;
  }

  const [notificacao, setItem] = useState<Notificacao[]>([]);

  useEffect(() => {
    setItem([]);
  }, []);

  return { notificacao };
}
