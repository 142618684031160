import { Tag } from "components/Tag";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { IRelatorioPagamentos } from "features/relatorios/pagamento/interfaces/RelatorioPagamento";
import { IRelatorioPagamentosRecusados } from "features/relatorios/recusados/interfaces/RelatorioPagamentosRecusado";
import { converterDataHoraBr, formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";

const columns: IColum<IRelatorioPagamentos>[] = [
  {
    name: <span>ID Solic. Pgto</span>,
    selector: ({ idSolicitacaoPagamento }) => idSolicitacaoPagamento || "--",
    width: "100px",
  },
  {
    name: <span>Placa</span>,
    selector: ({ placa }) => placa.toUpperCase(),
    width: "120px",
  },
  {
    name: <span>Renavam</span>,
    selector: ({ renavam }) => renavam,
    width: "100px",
  },
  {
    name: <span>Chassi</span>,
    selector: ({ chassi }) => chassi,
    width: "150px",
  },
  {
    name: <span>UF</span>,
    selector: ({ uf }) => uf.toUpperCase(),
    width: "100px",
  },
  {
    name: <span>Débito</span>,
    selector: ({ idTipoDebito }) => TipoDebitos[Number(idTipoDebito)],
    width: "125px",
  },
  {
    name: <span>Vencimento</span>,
    selector: ({ vencimento }) => vencimento ? formataDataISOParaBR(vencimento) : "-",
    width: "120px",
  },
  {
    name: <span>Valor</span>,
    selector: ({ valor }) => formatarDinheiro(valor / 100),
    width: "120px",
  },
  {
    name: <span>Status Solicitação Pgto</span>,
    selector: () => <Tag label="Recusado" type="erro" />,
    width: "140px",
  },
  {
    name: <span>Data Recusa</span>,
    selector: ({ dataRecusa }) =>
      dataRecusa ? converterDataHoraBr(new Date(dataRecusa)) : "-",
    width: "120px",
  },
  {
    name: <span>Motivo</span>,
    selector: ({ motivoRecusa }) => motivoRecusa,
  },
];

interface ITableRelatorioPagamentosRecusados {
  loading: boolean;
  dados: IRelatorioPagamentosRecusados[];
  handleExport: () => void;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (limit: number) => void;
  totalRows: number;
  rowsPerPage: number;
}

export function TableRelatorioPagamentosRecusados({
  dados,
  loading,
  handleExport,
  onChangePage,
  onChangeRowsPerPage,
  totalRows,
  rowsPerPage,
}: ITableRelatorioPagamentosRecusados) {
  return (
    <Table
      totalRows={totalRows}
      loading={loading}
      data={dados}
      columns={columns}
      keyField="id"
      exportExcel
      handleExport={handleExport}
      paginationRowsPerPageOptions={[100, 200, 300]}
      buttonExportVariant="secondary"
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      pagination
      rowsPerPage={rowsPerPage}
    />
  );
}
