import { toastErro } from "components/Toast";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { IRelatorioPagamentos } from "features/relatorios/pagamento/interfaces/RelatorioPagamento";
import { ContextPermissao } from "hooks/ContextPermissao";
import { useContext, useState } from "react";
import { converterDataHoraBr, formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { IRelatorioExtratoVeicular } from "features/relatorios/extratoVeicular/interfaces/IRelatorioExtratoVeicular";

interface ITableRelatorioExtratoVeicular {
    loading: boolean;
    dados: IRelatorioPagamentos[];
    handleExport: () => void;
    onChangePage: (page: number) => void;
    onChangeRowsPerPage: (limit: number) => void;
    totalRows: number;
    queryParams: NullableString;
    rowsPerPage?:number
}

export function TableRelatorioExtratoVeicular({
    dados,
    loading,
    handleExport,
    onChangePage,
    onChangeRowsPerPage,
    totalRows,
    queryParams,
    rowsPerPage
}: ITableRelatorioExtratoVeicular) {
    const { verificarEmpresa } = useContext(ContextPermissao);
    const usuarioLW = verificarEmpresa();

    const columns: IColum<IRelatorioExtratoVeicular>[] = [
        {
            name: <span>Placa</span>,
            selector: ({ placa }: IRelatorioExtratoVeicular) => {
                return (placa ?? "- -");
            },
        },
        {
            name: <span>Renavam</span>,
            selector: ({ renavam }: IRelatorioExtratoVeicular) => {
                return (renavam ?? "- -");
            },
            width: "150px",
        },
        {
            name: <span>Chassi</span>,
            selector: ({ chassi }: IRelatorioExtratoVeicular) => {
                return (chassi ?? "- -");
            },
            width: "150px",
        },
        {
            name: <span>Data/Hora da Consulta</span>,
            selector: ({ dataHoraConsulta }: IRelatorioExtratoVeicular) => <span>{dataHoraConsulta ? converterDataHoraBr(dataHoraConsulta) : "- -"}</span>,
            width: "175px",
        },
        {
            name: "Empresa",
            selector: ({ nomeCliente }: IRelatorioExtratoVeicular) => <span>{nomeCliente && nomeCliente !== "" ? nomeCliente : "- -"}</span>,
            width: "150px",
        },
        {
            name: "Usuário",
            selector: ({ nomeUsuario }: IRelatorioExtratoVeicular) => <span>{nomeUsuario ?? "- -"}</span>,
            width: "150px",
        },
        {
            name: "Tipo de consulta",
            selector: ({ tipoConsulta }: IRelatorioExtratoVeicular) => <span>{tipoConsulta ?? "- -"}</span>,
            width: "150px",
        },
        {
            name: <span>ID Consulta</span>,
            selector: ({ idConsulta }: IRelatorioExtratoVeicular) => idConsulta ?? "- -",
        },
        {
            name: <span>ID Recibo</span>,
            selector: ({ idRecibo }: IRelatorioExtratoVeicular) => idRecibo ?? "- -",
        },
        {
            name: <span>Valor</span>,
            selector: ({ valor }: IRelatorioExtratoVeicular) => valor ? formatarDinheiro(valor / 100) : "- -",
        },
        {
            name: "Isenção de cobrança",
            selector: ({ motivoIsencao }: IRelatorioExtratoVeicular) => <span>{motivoIsencao && motivoIsencao !== "null" ? motivoIsencao.replaceAll("_", " ") : "- -"}</span>,
            width: "190px",
            omit: !usuarioLW,
        },
    ];

    return (
        <>
            <Table
                loading={loading}
                data={dados}
                columns={columns}
                keyField="id"
                exportExcel
                handleExport={handleExport}
                paginationRowsPerPageOptions={[10, 20, 50]}
                buttonExportVariant="secondary"
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination
                totalRows={totalRows}
                rowsPerPage={rowsPerPage}
                noDataContent={!queryParams ? <div> Utilize algum filtro para buscar o relatório</div> : < div > Nenhum registro encontrado</div>}
            />
        </>
    );
}
