import { toastErro } from "components/Toast";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { Container, IconeUpload } from "./style";

interface IValidaExtensao {
  tipoArquivo: "planilha" | "imagem";
  arquivo: File;
}

interface IImportarArquivo {
  tipoArquivo: "planilha" | "imagem";
  inputId: string;
  handleUpload: (arquivos: File) => Promise<void>;
  isInvalid?: boolean;
}

function validaExtensao({ tipoArquivo, arquivo }: IValidaExtensao) {
  const extensao = arquivo.name.split(".")[1];

  if (tipoArquivo === "planilha" && extensao !== "xlsx" && extensao !== "xls") {
    return false;
  }

  if (tipoArquivo === "imagem" && extensao !== "pdf" && extensao !== "jpg" && extensao !== "png"&& extensao !== "jpeg") {
    return false;
  }

  return true;
}

export function ImportarArquivo({ handleUpload, tipoArquivo, inputId, isInvalid = false }: IImportarArquivo) {
  const configuracaoTipoArquivo = {
    planilha: {
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      textoExtensoesValidas: "XLSX ou XLS",
    },
    imagem: {
      accept: `"application/pdf", "image/jpeg", "image/pnh"`,
      textoExtensoesValidas: "JPG, JPEG, PNG ou PDF (máx 800x400)",
    },
  };

  const dropArea = useRef<HTMLDivElement>(null);

  function handleInputFile() {
    const input = document.getElementById(inputId);
    input?.click();
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const files = event.target.files;
    dropArea.current?.classList.remove("dragover");

    if (files!.length > 1) {
      toastErro("Só é possível inserir um arquivo por vez");
      return;
    }

    if (files) {
      const valida = validaExtensao({ tipoArquivo, arquivo: files[0] });

      if (!valida) {
        toastErro("Formato de arquivo inválido");
        return;
      }

      handleUpload(files[0]);
    }
  }

  function handleFileDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    const { files } = event.dataTransfer;
    dropArea.current?.classList.remove("dragover");

    if (files.length > 1) {
      toastErro("Só é possível inserir um arquivo por vez");
      return;
    }

    const valida = validaExtensao({ tipoArquivo, arquivo: files[0] });

    if (!valida) {
      toastErro("Formato de arquivo inválido");
      return;
    }

    handleUpload(files[0]);
  }

  function onDragEnter(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    dropArea.current?.classList.add("dragover");
  }

  function onDragLeave(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    dropArea.current?.classList.remove("dragover");
  }

  return (
    <Container
      ref={dropArea}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={handleFileDrop}
      onClick={handleInputFile}
      isInvalid={isInvalid}
    >
      <input
        id={inputId}
        type="file"
        accept={configuracaoTipoArquivo[tipoArquivo].accept}
        onChange={handleInputChange}
      />
      <IconeUpload/>
      <h4>
        <span>Clique ou solte aqui</span> e faça o upload instantâneo
      </h4>
      <h4>{configuracaoTipoArquivo[tipoArquivo].textoExtensoesValidas}</h4>
    </Container>
  );
}
