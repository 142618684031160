import { useTablePaginationSortSearch } from "containers/Table/hooks/useTablePaginationSortSearch";
import { useCallback, useState } from "react";
import { IFormDetalhesExtratoVeicular } from "../interfaces/IFormDetalhesExtratoVeicular";
import { IDadosDetalhado, IDadosUnitario } from "../containers/ListarDetalhesExtratoVeicular";
import { useRequest } from "hooks/useRequest";
import { useParams } from "react-router-dom";
import { toastErro } from "components/Toast";

export function useDetalhesExtratoVeicular() {
  const [filtros, setFiltros] = useState<IFormDetalhesExtratoVeicular>({
    chassi: null,
    placa: null,
    renavam: null,
    statusConsulta: null,
    tipoPendencia: null,
  });
  const [dadosDetalhados, setDadosDetalhados] = useState<IDadosDetalhado[]>([]);
  const [dadosUnitarios, setDadosUnitarios] = useState<IDadosUnitario[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [renderPdf, setRenderPdf] = useState<boolean>(false);
  const [dadosExtratoVeicular, setDadosExtratoVeicular] = useState<any>(null);
  const { id: idLoteExtratoVeicular } = useParams();
  const { handleLimitChange, handlePageChange, paginationSortSearch, limit } = useTablePaginationSortSearch();
  const { get, patch, loading } = useRequest("consulta");
  const [loadingDownloadPdf, setLoadingDownloadPdf] = useState(false);
  const [buscarPor, setBuscarPor] = useState<"DETALHADOS" | "UNITARIOS">("DETALHADOS");

  const ajustarFiltros = useCallback(
    (filtrosBuscar: IFormDetalhesExtratoVeicular) => {
      let params: string[] = [];

      for (const key of Object.keys(filtrosBuscar)) {
        if (filtrosBuscar[key]) {
          params.push(`${key}=${filtrosBuscar[key]}`);
        }
      }

      return params.length ? `${params.join("&")}&${paginationSortSearch}` : paginationSortSearch;
    },
    [paginationSortSearch]
  );

  const buscarDadosDetalhado = useCallback(async () => {
    setDadosUnitarios([]);
    const queryParams = ajustarFiltros(filtros);

    if (!queryParams) {
      return;
    }

    const { data, hasErro } = await get(`/veiculo/extrato/${idLoteExtratoVeicular}/consolidado?${queryParams}`);

    if (hasErro) {
      setDadosDetalhados([]);
      return;
    }

    setDadosDetalhados(data.items);
    setTotalRows(data.meta.totalItems);
  }, [ajustarFiltros, filtros, get, idLoteExtratoVeicular]);

  const buscarDadosUnitarios = useCallback(async () => {
    setDadosDetalhados([]);
    const queryParams = ajustarFiltros(filtros);
    if (!queryParams) {
      return;
    }

    const { data, hasErro } = await get(`/veiculo/extrato/${idLoteExtratoVeicular}/unitario?${queryParams}`);

    if (hasErro) {
      setDadosUnitarios([]);
      return;
    }

    setDadosUnitarios(data.items);
    setTotalRows(data.meta.totalItems);
  }, [ajustarFiltros, filtros, get, idLoteExtratoVeicular]);

  const buscarDadosPlanilhaDetalhado = useCallback(async () => {
    const { data, hasErro } = await get(`/veiculo/extrato/${idLoteExtratoVeicular}/consolidado?paginate=0`);

    if (hasErro) {
      toastErro("Ocorreu um erro ao buscar dados da planilha");
      return;
    }

    return data;
  }, [get, idLoteExtratoVeicular]);

  const buscarDadosPlanilhaUnitarios = useCallback(async () => {
    const { data, hasErro } = await get(`/veiculo/extrato/${idLoteExtratoVeicular}/unitario?paginate=0`);

    if (hasErro) {
      toastErro("Ocorreu um erro ao buscar dados da planilha");
      return;
    }

    return data;
  }, [get, idLoteExtratoVeicular]);

  const handleExportarPdfExtratoVeicular = useCallback(
    async (idExtratoVeicular: number | string, idLoteExtratoVeicular: number | string) => {
      setLoadingDownloadPdf(true);
      const { data, hasErro } = await get(`veiculo/extrato/${idLoteExtratoVeicular}/detalhes/${idExtratoVeicular}`);

      if (hasErro) {
        toastErro("Ocorreu um erro ao gerar o PDF");
        return;
      }

      setDadosExtratoVeicular(data);
      setRenderPdf(true);

      setTimeout(() => {
        document.getElementById(`downloadPdf${idExtratoVeicular}`)?.click();
        setRenderPdf(false);
        setDadosExtratoVeicular(null);
        setLoadingDownloadPdf(false);
      }, 300);
    },
    [get]
  );

  const reconsultar = useCallback(
    async (idLoteExtratoVeicular: string) => {
      return patch(`veiculo/extrato/${idLoteExtratoVeicular}/reconsulta`, {});
    },
    [patch]
  );

  return {
    buscarDadosDetalhado,
    buscarDadosUnitarios,
    handleLimitChange,
    handlePageChange,
    setFiltros,
    dadosDetalhados,
    dadosUnitarios,
    totalRows,
    limit,
    loading,
    handleExportarPdfExtratoVeicular,
    renderPdf,
    dadosExtratoVeicular,
    loadingDownloadPdf,
    reconsultar,
    buscarDadosPlanilhaDetalhado,
    buscarDadosPlanilhaUnitarios,
    setBuscarPor,
    buscarPor,
  };
}

