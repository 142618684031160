import { Button, Modal, ModalBody } from "react-bootstrap";
import { ButtonClose, Footer, Header, ModalButton, Title } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

type IButtonPosition = "start" | "center" | "end";
interface ModalProps {
	handleClose: () => void;
	show: boolean;
	title?: string;
	subTitle?: string;
	children: any;
	size?: "lg" | "sm" | "xl";
	centered?: boolean;
	footer?: boolean;
	footerTools?: any;
	footerButtonOk?: boolean;
	footerConfirmButton?: boolean;
	disabledConfirmButton?: boolean;
	onConfirm?: () => void;
	footerCloseButton?: boolean;
	formTarget?: string;
	buttonPosition?: IButtonPosition;
	confirmButtonTitle?: string;
}

export function ModalCustom({
	handleClose,
	show,
	title,
	subTitle,
	children,
	size,
	centered,
	footer = false,
	footerTools,
	footerButtonOk,
	footerConfirmButton,
	disabledConfirmButton = false,
	onConfirm,
	footerCloseButton = false,
	formTarget,
	buttonPosition = "center",
	confirmButtonTitle = "Confirmar",
}: ModalProps) {
  return (
    <Modal
      onHide={handleClose}
      show={show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered}
    >
      {title && (
        <Header>
          {title}
          <ButtonClose onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} />
          </ButtonClose>
        </Header>
      )}
      <ModalBody>
        {subTitle && <p>{subTitle}</p>}
        {children}
      </ModalBody>
      {footer && (
        <Footer className={`justify-content-${buttonPosition}`}>
          {footerButtonOk && (
            <Button variant="primary" onClick={handleClose}>
              OK
            </Button>
          )}

					{footerConfirmButton && (
						<>
							<ModalButton variante="primary" onClick={handleClose} outline={true}>
								Cancelar
							</ModalButton>
							<ModalButton type="submit" onClick={onConfirm} disabled={disabledConfirmButton} form={formTarget}>
								{confirmButtonTitle}
							</ModalButton>
						</>
					)}

					{footerCloseButton && (
						<ModalButton onClick={handleClose} variante="primary" outline={true}>
							Fechar
						</ModalButton>
					)}

					{footerTools}
				</Footer>
			)}
		</Modal>
	);
}
