import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const Titulo = styled.h5`
  color: ${({ theme }) => theme.colors.neutral.neutral60};
  margin: 0;
`;