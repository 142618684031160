import { Page } from "containers/Page";
import { FiltrosListarSolictacoesLicenciamento } from "./containers/FiltrosListarSolictacoesLicenciamento";
import { ListagemSolicitacoesLicenciamento } from "./containers/ListagemSolicitacoesLicenciamento";
import { useSolicitacaoLicenciamentos } from "../../hooks/useSolicitacaoLicenciamentos";
import { useEffect, useState } from "react";

export function ListarSolicitacoesLicenciamento() {
  const [loadingPage, setLoadingPage] = useState(false);
  const {
    listarSolicitacoesLicenciamento,
    setFiltros,
    loading,
    solicitacoes,
    totalRows,
    setLimit,
    setPage,
    limit
  } = useSolicitacaoLicenciamentos();

  useEffect(() => {
    listarSolicitacoesLicenciamento();
  }, [listarSolicitacoesLicenciamento]);

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }

  function handlePageChange(page: number) {
    setPage(page);
  }

  return (
    <Page lastPage="Licenciamento" loading={loadingPage} title="Emitir Licenciamentos">
      <FiltrosListarSolictacoesLicenciamento handleSubmit={setFiltros} setLoadingPage={setLoadingPage} />
      <ListagemSolicitacoesLicenciamento
        loading={loading}
        dados={solicitacoes}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        rowsPerPage={limit}
        totalRows={totalRows}
      />
    </Page>
  );
}

