import { GoTriangleUp, GoTriangleDown } from "react-icons/go";
import { DropdownToggleContainer } from "./style";
import { PropsWithChildren } from "react";
import { useDropdownMenu } from "../hook/useDropdownMenu";
import temaPadrao from "_config/temas/estilo/base";

interface Props extends PropsWithChildren {}

export function DropdownToggle({ children }: Readonly<Props>) {
	const { toggleMenu, open, dropdownButtonId } = useDropdownMenu();

	return (
		<DropdownToggleContainer id={dropdownButtonId} onClick={toggleMenu}>
			<div>{children}</div>
			{open ? <GoTriangleUp color={temaPadrao.colors.neutral.neutral70} /> : <GoTriangleDown color={temaPadrao.colors.neutral.neutral70} />}
		</DropdownToggleContainer>
	);
}
