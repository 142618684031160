import { Tooltip } from "components/Tooltip";
import { FaInfoCircle } from "react-icons/fa";
import { StatusEmissaoLicenciamentoEnum } from "../../../Enum/StatusEmissaoLicenciamento";
import { TriggerContainer } from "features/debito/consulta/pages/Detalhes/Containers/TentativasConsultas/style";
import { TooltipContent } from "./style";

interface Props {
  tentativas: number;
  statusEmissao: number;
}

export function TentativasCRLV({ tentativas, statusEmissao }: Readonly<Props>) {
  if (tentativas === 0) {
    return <TriggerContainer>--</TriggerContainer>;
  }

  if (
    statusEmissao === StatusEmissaoLicenciamentoEnum.Emitido ||
    statusEmissao === StatusEmissaoLicenciamentoEnum["Dados inconsistentes"] ||
    statusEmissao === StatusEmissaoLicenciamentoEnum["Exercício indisponível"]
  ) {
    return <TriggerContainer>0{tentativas}</TriggerContainer>;
  }
  

  const bottomTooltip = -1;
  const rightTooltip = 5;
  const widthTooltip = 200;
  const MAXIMO_TENTATIVAS = 3;

  if (tentativas >= MAXIMO_TENTATIVAS) {
    return (
      <Tooltip
        bottom={bottomTooltip}
        right={rightTooltip}
        width={widthTooltip}
        positionRelative={false}
        trigger={<TriggerContainer cursorHelp>{`0${tentativas}`}</TriggerContainer>}
      >
        <TooltipContent>
          <div style={{ marginBottom: "8px" }}>
            <FaInfoCircle style={{ marginRight: "8px", color: "#FF9F42" }} />
            Falha na emissão
          </div>
          <strong>
            3<sup>a</sup> tentativa sem sucesso
          </strong>
          <br />
          <br />
          <span style={{ whiteSpace: "normal" }}>Clique em reemitir ou entre em contato com o suporte</span>
        </TooltipContent>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      bottom={bottomTooltip}
      right={rightTooltip}
      width={widthTooltip}
      positionRelative={false}
      trigger={<TriggerContainer cursorHelp>{`0${tentativas}`}</TriggerContainer>}
    >
      <TooltipContent>
        <div style={{ marginBottom: "8px" }}>
          <FaInfoCircle style={{ marginRight: "8px", color: "#FF9F42" }} />
          Falha na emissão
        </div>
        <div>
          <strong>
            Processando {tentativas + 1}
            <sup>a</sup> tentativa
          </strong>
        </div>
      </TooltipContent>
    </Tooltip>
  );
}

