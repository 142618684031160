import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";

export const ButtonReenviar = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const TextoEtapa = styled.div`
  color: #767f85;
  font-weight: 400;
  font-size: 16px;
  margin: o;
`;

export const TextoCodigo = styled.strong`
  font-size: 14px;
`;

export const Id = styled.div`
  color: #666666;
  font-size: 14px;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;

export const ContainerDescricao = styled.div`
`;

export const NaoRecebido = styled.span`
  color: #767f85;
  font-size: 14px;
`;

export const ErroCodigo = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.danger.bgColor};
  color: ${({ theme }) => theme.colors.neutral.neutral60};
  border-radius: 6px;
`;

export const IconeErro = styled(FaInfoCircle)`
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.danger.main} !important;
`;

export const TitleLogin = styled.h1`
  color: ${(props) => props.color};
  font-size: 32px;
  font-weight: 700;
  padding:0;
  margin: 0;
`;

export const TextoContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;