import { CompositeContainer } from "components/CompositeContainer";
import { TableSolicitacoesDownload } from "./containers/TableSolicitacoesDownload";
import { useEffect } from "react";

interface Props {
  listarSolicitacoesDownload: () => Promise<void>;
  loading: boolean;
  handleLimitChange: (limit: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  solicitacoes: any[];
  totalRows: number;
}

export function ListarSolicitacoesDownload({
  handleLimitChange,
  handlePageChange,
  limit,
  listarSolicitacoesDownload,
  loading,
  solicitacoes,
  totalRows,
}: Props) {
  useEffect(() => {
    listarSolicitacoesDownload();
  }, [listarSolicitacoesDownload]);

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo text="Download de arquivos em lote" />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <TableSolicitacoesDownload
          loading={loading}
          handleLimitChange={handleLimitChange}
          handlePageChange={handlePageChange}
          limit={limit}
          solicitacoes={solicitacoes}
          totalRows={totalRows}
        />
      </CompositeContainer.Body>
    </CompositeContainer.Root>
  );
}

