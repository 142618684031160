import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const consultarVeiculoSchma = yup.object().shape({
  arquivo: yup.mixed().required("Arquivo é obrigatório"),
  tipoDebito: yup.array().required("Tipo débito é obrigatório"),
  uf: yup.string().required("A UF é obrigatória").max(2, "UF inválido").min(2, "UF inválido"),
});

export const consultarVeiculoUnitarioSchema = yup.object().shape({
  placa: yup.string().required("A placa é obrigatória").max(8, "Placa inválida").min(7, "Placa inválida"),
  renavam: yup.string().required("O renavam é obrigatório").max(11, "Renavam inválido").min(11, "Renavam inválido"),
  chassi: yup.string().required("O chassi é obrigatório").max(17, "Chassi inválido").min(17, "Chassi inválido"),
  uf: yup.string().required("A UF é obrigatória").max(2, "UF inválido").min(2, "UF inválido"),
  tipoDebito: yup.array().required("Tipo débito é obrigatório"),
});

const regexPlacas = /^[A-Za-z]{3}-?\d[0-9A-Za-z]\d{2}$/;

export const filtrosDetalhesDebitosSchema = yup.object().shape({
  placa: yup.string().max(8, "Placa inválida").notRequired().trim().matches(regexPlacas, {
    excludeEmptyString: true,
    message: "Placa inválida",
  }),
});
