import { FaUpload } from "react-icons/fa";
import styled from "styled-components";

interface IContainer {
  isInvalid?: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 200px;
  border: 3px dashed ${({ theme, isInvalid }) => isInvalid ? 'red' : theme.colors.neutral.neutral20};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.background};
    cursor: pointer;

    svg {
      color: ${(props) => props.theme.colors.secondary};
    }

    span {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  &.dragover {
    background-color: ${({ theme }) => theme.colors.background};
  }

  svg {
    transition: 0.3s;
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
    background-color: ${(props) => props.theme.colors.project.bgColor};
    color: ${(props) => props.theme.colors.primary.main};
    padding: 1rem;
    border-radius: 8px;
  }

  h4 {
    font-size: 1rem;

    span {
      transition: 0.3s;
      color: ${(props) => props.theme.colors.primary.main};
      font-weight: bold;
    }
  }

  input {
    display: none;
  }
`;

export const IconeUpload = styled(FaUpload)`
  background-color: #FFF !important;
  color: #333333 !important;
  border: 1px solid #333333;
`;
