import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton } from "components/Button/style";
import { CustomInput } from "components/CustomInput";
import { Header } from "components/Header";
import { ModalCustom } from "components/ModalCustom";
import { toastErro } from "components/Toast";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { redirect } from "react-router-dom";
import api from "_services/api";
import { parseJwt } from "_services/auth";
import { FormAlterarSenhaSchema } from "../../validate/FormAlterarSenha.schema";
import {
  Container,
  ContainerCentralizado,
  DivBackGround,
  Linha,
  SubContainer,
  WrapperButtons,
  WrapperInputs,
} from "./style";

export function AlterarSenha() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  function handleModalClose() {
    setModalOpen(!modalOpen);
  }

  const {
    register,
    handleSubmit,
    formState: { isSubmitted, errors },
  } = useForm({
    resolver: yupResolver(FormAlterarSenhaSchema),
  });

  async function handleFormAlterarSenha(data: any) {
    try {
      const senha = data.novaSenha;
      const { sub, email } = parseJwt();

      if (email.includes("@lwtecnologia")) {
        return toastErro(
          "Não é possível redefinir senha para usuários LW Tecnologia"
        );
      }

      await api.patch(`/usuario/${sub}`, {
        senha: senha,
      });

      handleModalClose();
      return (window.location.href = "/core/dashboard");
    } catch {
      toastErro("Ocorreu um erro!");
    }
  }

  return (
    <>
      <Header lastPage="Home" title="Alterar Senha" />
      <Container>
        <SubContainer>
          <p className="titulo">Segurança</p>
          <Linha />
          <br />
          <p className="titulo">Alterar Senha</p>
          <br />
          <p>Sua nova senha deve ser diferente da senha usada anteriormente.</p>
          <br />
          <Form onSubmit={handleSubmit(handleFormAlterarSenha)}>
            <WrapperInputs>
              <label>
                Nova senha<span>*</span>
              </label>
              <CustomInput
                {...register("novaSenha", { required: true })}
                placeholder="Digite"
                type="password"
                size="sm"
                isValid={isSubmitted && !errors.novaSenha}
                isInvalid={!!errors.novaSenha}
                mensagemErro={errors.novaSenha?.message}
              />
            </WrapperInputs>
            <br />
            <br />
            <WrapperInputs>
              <label>
                Confirme a nova senha<span>*</span>
              </label>
              <CustomInput
                {...register("confirmaNovaSenha", { required: true })}
                placeholder="Digite"
                type="password"
                size="sm"
                isValid={isSubmitted && !errors.confirmaNovaSenha}
                isInvalid={!!errors.confirmaNovaSenha}
                mensagemErro={errors.confirmaNovaSenha?.message}
              />
            </WrapperInputs>
            <br />
            <br />
            <WrapperButtons>
              <PrimaryButton outline={true} variante="primary">Cancelar</PrimaryButton>
              <PrimaryButton type="submit">Alterar</PrimaryButton>
            </WrapperButtons>
          </Form>
        </SubContainer>
      </Container>
      <ModalCustom
        handleClose={handleModalClose}
        show={modalOpen}
        title="Alterar senha"
        centered={true}
      >
        <ContainerCentralizado>
          <DivBackGround>
            <FaCheck />
          </DivBackGround>
          <p>Senha alterada com sucesso!</p>
        </ContainerCentralizado>
      </ModalCustom>
    </>
  );
}
