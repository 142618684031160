import { Container } from "./style";
import { StatusEmissaoLicenciamentoEnum } from "features/licenciamento/emissao/pages/Detalhes/containers/Enum/StatusEmissaoLicenciamento";

interface Props {
    statusEmissao: string | number;
}

export function StatusEmissaoLicenciamento({
    statusEmissao,
}: Props) {
    return (
        <Container
            statusEmissao={Number(statusEmissao)}
        >
            {StatusEmissaoLicenciamentoEnum[Number(statusEmissao)]}
        </Container>
    );
}
