import { useCallback, useState } from "react";
import { IFiltrosListarSolicitacoesLicenciamento } from "../pages/Listar/containers/FiltrosListarSolictacoesLicenciamento/containers/FormContainer";
import { useRequest } from "hooks/useRequest";
import { usePaginationSortSearch } from "containers/Table/hooks/usePaginationSortSearch";
import { Paginar } from "_utils/enuns/Paginar.enum";

export function useSolicitacaoLicenciamentos() {
  const { get, loading } = useRequest("consulta");
  const [filtros, setFiltros] =
    useState<IFiltrosListarSolicitacoesLicenciamento>(
      {} as IFiltrosListarSolicitacoesLicenciamento
    );
  const [solicitacoes, setSolicitacoes] = useState<any[]>([]);
  const { totalRows, setTotalRows } = usePaginationSortSearch();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const gerarQueryParams = useCallback((tipo: "consulta" | "emissao") => {
    const filtrosArray = [
      filtros.placa ? `placa=${filtros.placa}` : null,
      filtros.renavam ? `renavam=${filtros.renavam}` : null,
      filtros.idEmpresa ? `idEmpresa=${filtros.idEmpresa}` : null,
      filtros.dataSolicitacao
        ? `dataSolicitacao=${filtros.dataSolicitacao}`
        : null,
      filtros.statusEmissao ? `statusEmissao=${filtros.statusEmissao}` : null,
      filtros.uf ? `uf=${filtros.uf}` : null,
      filtros.cobranca ? `cobranca=${filtros.cobranca}` : null,
      `tipo=${tipo}`
    ].filter((item) => item !== null);

    return `${filtrosArray.join("&")}`;
  }, [filtros]);

  const listarSolicitacoesLicenciamento = useCallback(
    async (
      paginar: number = Paginar.SIM,
      tipo: "consulta" | "emissao" = "emissao"
    ) => {
      let params = gerarQueryParams(tipo);

      const { data, hasErro } = await get(
        `/licenciamento/consultas?${params}&page=${page}&limit=${limit}&paginate=${paginar}`,
        {},
        false,
      );

      if (hasErro) {
        setSolicitacoes([]);
        return;
      }

      if (paginar === Paginar.NAO) {
        return data;
      }

      setSolicitacoes(data.items);
      setTotalRows(data.meta.totalItems);
    },
    [gerarQueryParams, get, setTotalRows, limit, page]
  );

  return {
    listarSolicitacoesLicenciamento,
    setFiltros,
    loading,
    solicitacoes,
    setPage,
    setLimit,
    totalRows,
    limit
  };
}

