import styled from 'styled-components';

interface TagProps {
  type: "atencao" | "erro" | "info";
}

export const Container = styled.div<TagProps>`
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width:95px;
  height: 24px;

${({ theme, type }) => type === "atencao" && 
    `background-color: ${theme.colors.warning.bgColor};
    border: solid 1px ${theme.colors.warning.main};
    color: ${theme.colors.warning.main};`
  }
  ${({ theme, type }) => type === "erro" && 
    `background-color: ${theme.colors.danger.bgColor};
      border: solid 1px ${theme.colors.tags.danger.textColor};
      color: ${theme.colors.tags.danger.textColor};`
  }
  ${({ theme, type }) => type === "info" && 
    `background-color: ${theme.colors.danger.bgColor};
    border: solid 1px ${theme.colors.tags.danger.textColor};
    color: ${theme.colors.tags.danger.textColor};`
  }
`;