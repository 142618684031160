import { CustomInput } from "components/CustomInput";
import { ButtonContainer, InputArquivoWrapper, InputContainer, InputDescricaoWrapper } from "./style";
import { PrimaryButton } from "components/Button/style";
import { FaDownload } from "react-icons/fa";
import FileUpload from "components/UploadArquivos";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { planilhaSchema } from "features/licenciamento/emissao/validate/validator";
import { SelecionarEmpresa } from "../../../BodySP/containers/SelecionarEmpresa";
import { useEffect, useState } from "react";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { Tooltip } from "components/Tooltip";
import { Question } from "components/Icons/Question";
import { Informacao } from "components/Informacao";
import { InfoTooltip } from "components/InfoTooltip";

export interface IPlanilhaLicenciamento {
  descricao: string;
  cobrar: NullableString;
  empresa: NullableString;
  nomeEmpresa: NullableString;
  motivoIsencao: NullableString;
  exercicio: string;
}

export interface BodyModalProps {
  handleCloseModal: () => void;
  listarSolicitacoesLicenciamento: () => Promise<void>;
  handleUploadArquivo?: (file: File | BinaryType) => void;
  handleSubmit?: (dados: any) => void;
  setDadosIndividual?: any;
  buscarNomeEmpresa: (idCliente: NullableString) => string;
  pagina?: "emissao" | "consulta";
}

export function LotePR({
  handleCloseModal,
  handleUploadArquivo,
  handleSubmit,
  buscarNomeEmpresa,
  pagina = "emissao",
}: BodyModalProps) {
  const [optionsExercicio, setOptionsExercicio] = useState<IOptions[]>([]);
  const [optionSeleted, setOptionSelected] = useState<null | number>();

  useEffect(() => {
    const anoAtual = new Date().getFullYear();

    setOptionsExercicio([
      {
        label: `${anoAtual}`,
        value: anoAtual,
      },
      {
        label: `${anoAtual - 1}`,
        value: anoAtual - 1,
      },
    ]);
    setValue("exercicio", `${anoAtual}`);
    setOptionSelected(anoAtual);
  }, []);

  function handleDownloadModeloPR() {
    const link = document.createElement("a");
    link.download = "Modelo_EMITIR_CRLV_PR.xlsx";
    link.href = "/files/Modelo_EMITIR_CRLV_PR.xlsx";
    link.click();
  }

  const {
    formState: { errors, isSubmitted },
    handleSubmit: useHandleSubmit,
    setValue,
    control,
  } = useForm<IPlanilhaLicenciamento>({
    resolver: yupResolver(planilhaSchema),
  });
  const tooltipBottom = 1.3;

  return (
    <Form
      noValidate
      onSubmit={useHandleSubmit((dados) => {
        handleSubmit!({
          ...dados,
          nomeEmpresa: buscarNomeEmpresa(dados.empresa),
          cpfCnpjProprietario: "",
        });
      })}
    >
      <InputContainer>
        <InputDescricaoWrapper>
          <span>Descrição</span>
          <Controller
            control={control}
            name="descricao"
            render={({ field: { onChange, value } }) => (
              <CustomInput
                placeholder="Informe a descrição"
                type="text"
                onChange={onChange}
                value={value}
                isValid={isSubmitted && !errors.descricao}
                isInvalid={!!errors.descricao}
                mensagemErro={errors.descricao?.message}
              />
            )}
          />
        </InputDescricaoWrapper>
        {pagina === "emissao" && (
          <InputDescricaoWrapper
            style={{
              maxWidth: "10rem",
            }}
          >
            <span>
              Exercício{" "}
              <Tooltip
                bottom={tooltipBottom}
                trigger={<Question />}
              >
                <InfoTooltip
                  mensagem={"Informe o ano do licenciamento que deseja emitir"}
                ></InfoTooltip>
              </Tooltip>
            </span>
            <Controller
              control={control}
              name="exercicio"
              render={({ field: { onChange } }) => (
                <DropdownSelect
                  options={optionsExercicio}
                  selectedValue={optionSeleted}
                  onSelect={(e) => {
                    setOptionSelected(e);
                    onChange(e);
                  }}
                  mensagemErro={errors.exercicio?.message}
                />
              )}
            />
          </InputDescricaoWrapper>
        )}
        <InputArquivoWrapper>
          <span>Arquivo</span>
          <FileUpload onFileUpload={(file) => handleUploadArquivo!(file!)} />
        </InputArquivoWrapper>
      </InputContainer>
      <div style={{ paddingTop: "32px" }}>
        <SelecionarEmpresa control={control as any} controller={Controller} errors={errors} />
      </div>
      <ButtonContainer>
        <PrimaryButton variante="primary" outline type="button" onClick={handleDownloadModeloPR}>
          Baixar modelo
          <FaDownload />
        </PrimaryButton>
        <PrimaryButton type="submit" variante="primary">
          Emitir
        </PrimaryButton>
      </ButtonContainer>
    </Form>
  );
}

