import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;

  a {
    padding: 0.5rem 1rem;
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    border-radius: 0.5rem;
  }
`;

export const RotacionarIcone = styled.div`
  svg {
    animation: ${rotate} 2s linear infinite;
  }
`;

