import { Stronger } from "components/Stronger";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { BodyLeiloes, IRegistrosLeiloes } from "./containers/BodyLeiloes";
import { TextoClaro } from "components/TextoClaro";
import {
  BodyRemarketing,
  IRegistrosRemarketing,
} from "./containers/BodyRemarketing";
import {
  BodyInspecaoVeicular,
  IInspecaoVeicular,
} from "./containers/BodyInspecaoVeicular";
import {
  BodyRatingSeguridade,
  IRatingSeguridade,
} from "./containers/BodyRatingSeguridade";
import { StringUtils } from "_utils/String.utils";
import {
  BodyImagensLeilao,
  IImagensLeilao,
} from "./containers/BodyImagensLeilao";

export interface IDadosLeiloes {
  id: number;
  dataHoraCadastro: string;
  dataHoraUltimaConsulta: string;
  status: string;
  tentativasConsulta: number;
  observacao: string;
  tipoPendencia: string;
  indicioSinistro: string;
  score: number;
  inspecaoVeicular: IInspecaoVeicular;
  ratingSeguridade: IRatingSeguridade[];
  registrosLeiloes: IRegistrosLeiloes[];
  registrosRemarketing: IRegistrosRemarketing[];
  imagensLeiloes: IImagensLeilao[];
}

interface Props {
  leiloes: IDadosLeiloes | null;
}

export function DadosLeiloes({ leiloes }: Readonly<Props>) {
  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone
          icone={
            leiloes?.status === "Sucesso" ? IconePendencia : IconeNadaConsta
          }
        />
        <Section.Titulo titulo="Leilão" />
      </Section.Header>
      {(leiloes?.status === "Nada consta" || !leiloes) && (
        <Section.Body>
          <Section.Row>
            <Section.Col>
              <span>Leilão</span> <br />
              <Stronger>Nada consta</Stronger>
            </Section.Col>
          </Section.Row>
        </Section.Body>
      )}
      {leiloes?.status === "Sucesso" && (
        <>
          <Section.Body>
            <Section.Row>
              <Section.Col>
                <span>Score de leilão</span> <br />
                <Stronger>{leiloes?.score ?? "-"}</Stronger>
              </Section.Col>
            </Section.Row>
            <Section.Row>
              <Section.Col style={{ margin: 0 }}>
                <TextoClaro>Dados do leilão</TextoClaro>
                {leiloes?.registrosLeiloes?.length ? (
                  leiloes.registrosLeiloes.map((leilao, index) => (
                    <Section.RowToggle
                      className="sem-margin-nas-divs-filhas"
                      style={{ backgroundColor: "#E6E6E6" }}
                      key={`${index}_${leilao.comitente}`}
                      rowContent={
                        <Section.Col style={{ paddingLeft: 0 }}>
                          Leilão {index + 1}
                        </Section.Col>
                      }
                      toggleContent={<BodyLeiloes leilao={leilao} />}
                    />
                  ))
                ) : (
                  <>
                    <br />
                    <Stronger> - </Stronger>
                  </>
                )}
              </Section.Col>
            </Section.Row>
          </Section.Body>
          <Section.Body style={{ marginTop: "2rem" }}>
            <Section.Col>
              <TextoClaro>Registros de remarketing</TextoClaro>
              {leiloes?.registrosRemarketing?.length ? (
                leiloes?.registrosRemarketing?.map((remarketing, index) => (
                  <Section.RowToggle
                    className="sem-margin-nas-divs-filhas"
                    style={{ backgroundColor: "#E6E6E6" }}
                    key={`${index}_${remarketing.item}`}
                    rowContent={
                      <Section.Col style={{ paddingLeft: 0 }}>
                        Registro de remarketing {index + 1}
                      </Section.Col>
                    }
                    toggleContent={
                      <BodyRemarketing remarketing={remarketing} />
                    }
                  />
                ))
              ) : (
                <>
                  <br />
                  <Stronger> - </Stronger>
                </>
              )}
            </Section.Col>
          </Section.Body>

          <Section.Body style={{ marginTop: "2rem" }}>
            <Section.Row className="sem-margin-nas-divs-filhas">
              <Section.Col style={{ display: "grid", gap: "1rem" }}>
                <TextoClaro>Inspeção veicular</TextoClaro>
                <BodyInspecaoVeicular
                  inspecaoVeicular={leiloes?.inspecaoVeicular}
                />
              </Section.Col>
            </Section.Row>
            <Section.Row style={{ marginTop: "2rem" }}>
              <Section.Col>
                <TextoClaro>Indicio sinistro</TextoClaro> <br />
                <Stronger>
                  {leiloes?.indicioSinistro
                    ? StringUtils.primeiraLetraMaiuscula(
                        leiloes?.indicioSinistro?.split("_").join(" ")
                      )
                    : "-"}
                </Stronger>
              </Section.Col>
            </Section.Row>
            <Section.Row style={{ marginTop: "2rem" }}>
              <Section.Col className="sem-margin-nas-divs-filhas">
                <BodyRatingSeguridade
                  ratingsSeguridade={leiloes?.ratingSeguridade}
                />
              </Section.Col>
            </Section.Row>
            <Section.Row>
              <BodyImagensLeilao imagens={leiloes?.imagensLeiloes} />
            </Section.Row>
          </Section.Body>
        </>
      )}
    </Section.Root>
  );
}
