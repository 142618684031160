import { LoadingCirculo } from "components/LoadingCirculo";
import { ModalCustom } from "components/ModalCustom";
import { PrimaryButton } from "components/Button/style";
import { Container, ContainerMaisOpcoes, FormaPgtoOpcao, FormaPgtoTitle } from "./style";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { IDadosPorTipoDebito } from "../ListarDetalhes/interface/IDadosPorTipoDebito";
import { IDadosClienteReciboAdiantamento } from "../ListarDetalhes/interface/IDadosClienteReciboAdiantamento";
import { LoadingContainer } from "../MensagemDebitos/style";
import { DadosRecibo } from "./Containers/DadosRecibo";
import { DadosResumoRecibo } from "./Containers/DadosResumo";
import { DadosTransferencia } from "./Containers/DadosTransferencia";
import { InformacaoAdiantamento } from "./Containers/InformacaoAdiantamento";
import { FormaPgto } from "./Containers/FormaPgto";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfReciboAdiantamento } from "./Containers/PdfReciboAdiantamento";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";
import { IDadosBanco } from "features/debito/consulta/interfaces/IDadosBanco";

interface IDadosModalReciboAdiantamento {
    dadosPorTipoDebito: IDadosPorTipoDebito[];
    valorTotal: number;
    idReciboAdiantamento: number;
    dadosClienteReciboAdiantamento: IDadosClienteReciboAdiantamento;
    dadosBanco: IDadosBanco;
}

interface Props {
    show: boolean;
    handleClose: () => void;
    solicitarPagamento: () => Promise<void>;
    loading: boolean;
    formaPgto: FormaPgtoEnum | undefined;
    setFormaPgto: (forma: FormaPgtoEnum) => void;
    dados: IDadosModalReciboAdiantamento;
    setMaisOpcoes: (opcao: boolean) => void;
    maisOpcoes: boolean;
    atualizarFormaPgto?: boolean;
}

export function ModalReciboAdiantamento({
    show,
    handleClose,
    solicitarPagamento,
    loading,
    formaPgto,
    setFormaPgto,
    dados,
    setMaisOpcoes,
    maisOpcoes,
    atualizarFormaPgto = false,
}: Props) {
    const handleMaisOpcoes = () => {
        setMaisOpcoes(!maisOpcoes);
    }

    const handleChangeFormaPgto = (forma: FormaPgtoEnum) => {
        setFormaPgto(forma)

        const radioButton = document.getElementById(`radio${forma}`);
        if (radioButton && radioButton instanceof HTMLInputElement) {
            radioButton.checked = true;
        }

        if (forma === FormaPgtoEnum.PIXBOLETO) {
            setMaisOpcoes(false);
        }
    }

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            size="xl"
            title="Recibo de adiantamento"
            centered={true}
            buttonPosition="end"
            footer={!loading}
            footerTools={
                <PrimaryButton
                    onClick={solicitarPagamento}
                    disabled={!formaPgto}
                >{atualizarFormaPgto ? "Atualizar recibo de adiantamento" : "Solicitar pagamento"}</PrimaryButton>
            }
        >
            {loading ? (
                <LoadingContainer>
                    <LoadingCirculo mostrar={loading} />
                </LoadingContainer>
            ) : (
                <>
                    {formaPgto !== FormaPgtoEnum.TRANSFERENCIA && <InformacaoAdiantamento />}
                    <DadosRecibo
                        formaPgto={formaPgto}
                        valorTotal={dados.valorTotal}
                        dadosClienteReciboAdiantamento={dados.dadosClienteReciboAdiantamento}
                        idReciboAdiantamento={dados.idReciboAdiantamento}
                    />
                    <DadosResumoRecibo
                        valorTotal={dados.valorTotal}
                        dadosPorTipoDebito={dados.dadosPorTipoDebito}
                    />
                    <Container>
                        <FormaPgtoTitle>Selecione uma forma de pagamento</FormaPgtoTitle>
                        <FormaPgtoOpcao>
                            <input
                                name="formaPgto"
                                type="radio"
                                className="custom-radio"
                                value={FormaPgtoEnum.PIXBOLETO}
                                id="radioPIXBOLETO"
                                onChange={(e) => handleChangeFormaPgto(FormaPgtoEnum.PIXBOLETO)}
                                checked={formaPgto === FormaPgtoEnum.PIXBOLETO}
                            />
                            <label htmlFor="PIXBOLETO">
                                <FormaPgto formaPgto={FormaPgtoEnum.PIXBOLETO} onClick={handleChangeFormaPgto} />
                            </label>
                        </FormaPgtoOpcao>
                        <FormaPgtoOpcao>
                            <ContainerMaisOpcoes onClick={handleMaisOpcoes}>
                                {
                                    maisOpcoes || formaPgto === FormaPgtoEnum.TRANSFERENCIA
                                        ? <FaMinusCircle style={{ width: "16px", height: "16px" }} />
                                        : <FaPlusCircle style={{ width: "16px", height: "16px" }} />
                                } Mais opções de pagamento
                            </ContainerMaisOpcoes>
                        </FormaPgtoOpcao>
                        <FormaPgtoOpcao display={maisOpcoes || formaPgto === FormaPgtoEnum.TRANSFERENCIA ? "block" : "none"}>
                            <input
                                name="formaPgto"
                                type="radio"
                                className="custom-radio"
                                value={FormaPgtoEnum.TRANSFERENCIA}
                                id="radioTRANSFERENCIA"
                                onChange={(e) => handleChangeFormaPgto(FormaPgtoEnum.TRANSFERENCIA)}
                                checked={formaPgto === FormaPgtoEnum.TRANSFERENCIA}
                            />
                            <label htmlFor="TRANSFERENCIA">
                                <FormaPgto formaPgto={FormaPgtoEnum.TRANSFERENCIA} onClick={handleChangeFormaPgto} />
                            </label>
                        </FormaPgtoOpcao>
                    </Container>
                    {formaPgto === FormaPgtoEnum.TRANSFERENCIA && <>
                        <DadosTransferencia dados={dados.dadosBanco} />
                        <br />
                        <InformacaoAdiantamento />
                    </>}
                </>
            )}
            {formaPgto && <PDFDownloadLink
                style={{ display: "none" }}
                id={`downloadPDF_${dados.idReciboAdiantamento}`}
                document={
                    <PdfReciboAdiantamento
                        dadosClienteReciboAdiantamento={dados.dadosClienteReciboAdiantamento}
                        idReciboAdiantamento={dados.idReciboAdiantamento}
                        valorTotal={dados.valorTotal}
                        dadosPorTipoBoleto={dados.dadosPorTipoDebito}
                        formaPgto={formaPgto}
                        dadosBanco={dados.dadosBanco}
                    />
                }
                fileName={`ReciboAdiantamento_${dados.idReciboAdiantamento}`}
            />}
        </ModalCustom>
    );
}

