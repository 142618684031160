import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { useEffect, useState } from "react";
import { ContainerToggleVisualizacao, TooltipContentContainer } from "./style";
import { CompositeContainer } from "components/CompositeContainer";
import { PrimaryButton } from "components/Button/style";
import { MdRefresh } from "react-icons/md";
import { SemPendencia } from "../SemPendencia";
import { ComPendencia } from "../ComPendencia";
import { FalhaNaConsulta } from "../FalhaNaConsulta";
import { Consultando } from "../Consultando";
import { converterDataHoraBr, formataDataISOParaBR } from "_utils/Data";
import { StatusConsultaExtratoVeicular } from "../StatusConsultaExtratoVeicular";
import { AcoesExtratoVeicular } from "../AcoesExtratoVeicular";
import { StatusExtratoVeicular } from "../StatusConsultaExtratoVeicular/style";
import { intlCurrencyFormat } from "_utils/Money";
import { ModalReconsultarFalhasExtratoVeicular } from "../ModalReconsultarFalhasExtratoVeicular";
import { useParams } from "react-router-dom";
import { useDetalhesExtratoVeicular } from "../../hooks/useDetalhesExtratoVeicular";
import { Tooltip } from "components/Tooltip";
import { Stronger } from "components/Stronger";
import { utils, writeFileXLSX } from "xlsx";
import { TooltipTentativas } from "../TooltipTentativas";
import { ToggleVisualizacaoDetalhesExtratoVeicular } from "../ToggleVisualizacaoDetalhesExtratoVeicular";
import { IHttpResponse } from "_services/api/interface/HttpResponse";
import { NaoPertenceAoCombo } from "../NaoPertenceAoCombo";

export interface IDadosDetalhado {
  idLoteExtratoVeicular: number;
  idExtratoVeicular: number;
  placa: string;
  renavam: string;
  chassi: string;
  ipvas: string;
  licenciamentos: string;
  multas: string;
  gravames: string;
  restricoes: string;
  recalls: string;
  leiloes: string;
  dataHoraCadastro: string;
  statusExtratoVeicular: string;
  tentativasConsulta: number;
  dataHoraUltimaConsulta: string;
}

export interface IDadosUnitario {
  placa: string;
  renavam: string;
  chassi: string;
  tipoPendencia: string;
  dataVencimento: string;
  valor: number;
  status: string;
  tentativasConsulta: number;
}

interface ListarDetalhesExtratoVeicularProps {
  dados: IDadosDetalhado[] | IDadosUnitario[];
  buscarDadosDetalhado: () => Promise<void>;
  buscarDadosUnitarios: () => Promise<void>;
  handlePageChange: (page: number) => void;
  handleLimitChange: (limit: number) => void;
  totalRows: number;
  rowsPerPage: number;
  loading: boolean;
  setBuscarPor: (buscarPor: "DETALHADOS" | "UNITARIOS") => void;
  reconsultarFalhas: (loteExtratoVeicularId: string) => Promise<IHttpResponse>;
}

export function handleStatusConsulta(resultadoItem: string) {
  if (resultadoItem === "Consultando") {
    return <Consultando />;
  }

  if (resultadoItem === "Falha" || resultadoItem === "Dados inconsistentes") {
    return <FalhaNaConsulta />;
  }

  if (resultadoItem === "Nada consta") {
    return <SemPendencia />;
  }

  if (resultadoItem === "NAO_PERTENCE_AO_COMBO") {
    return <NaoPertenceAoCombo />
  }
  return <ComPendencia />;
}

export function gerarColunasDetalhado() {
  return [
    { name: <span>Placa</span>, selector: ({ placa }: IDadosDetalhado) => placa?.toUpperCase() ?? "-", width: "80px" },
    {
      name: <span>Renavam</span>,
      selector: ({ renavam }: IDadosDetalhado) => renavam?.toUpperCase() ?? "-",
      width: "100px",
    },
    {
      name: <span>Chassi</span>,
      selector: ({ chassi }: IDadosDetalhado) => chassi?.toUpperCase() ?? "-",
    },
    {
      name: <span>IPVA</span>,
      selector: ({ ipvas }: IDadosDetalhado) => handleStatusConsulta(ipvas),
      width: "70px",
    },
    {
      name: <span>Lic</span>,
      selector: ({ licenciamentos }: IDadosDetalhado) => handleStatusConsulta(licenciamentos),
      width: "60px",
    },
    {
      name: <span>Multa</span>,
      selector: ({ multas }: IDadosDetalhado) => handleStatusConsulta(multas),
      width: "70px",
    },
    {
      name: <span>Gravame</span>,
      selector: ({ gravames }: IDadosDetalhado) => handleStatusConsulta(gravames),
      width: "85px",
    },
    {
      name: <span>Restrições</span>,
      selector: ({ restricoes }: IDadosDetalhado) => handleStatusConsulta(restricoes),
      width: "100px",
    },
    {
      name: <span>Recall</span>,
      selector: ({ recalls }: IDadosDetalhado) => handleStatusConsulta(recalls),
      width: "70px",
    },
    {
      name: <span>Leilão</span>,
      selector: ({ leiloes }: IDadosDetalhado) => handleStatusConsulta(leiloes),
      width: "70px",
    },
    {
      name: <span>Data/Hora da consulta</span>,
      selector: ({ dataHoraUltimaConsulta, statusExtratoVeicular }: IDadosDetalhado) =>
        statusExtratoVeicular !== "Consultando" ? converterDataHoraBr(new Date(dataHoraUltimaConsulta)) : "-",
    },
    {
      name: <span>Status da consulta</span>,
      selector: ({ statusExtratoVeicular }: IDadosDetalhado) => (
        <StatusConsultaExtratoVeicular statusConsulta={statusExtratoVeicular} />
      ),
      width: "170px",
    },
    {
      name: <span>Tentativas de consulta</span>,
      selector: ({ tentativasConsulta, statusExtratoVeicular }: IDadosDetalhado) => (
        <TooltipTentativas statusExtratoVeicular={statusExtratoVeicular} tentativas={tentativasConsulta} />
      ),
      width: "190px",
    },
    {
      name: <span>Ações</span>,
      selector: ({ idExtratoVeicular, idLoteExtratoVeicular, statusExtratoVeicular }: IDadosDetalhado) => (
        <AcoesExtratoVeicular
          idExtratoVeicular={idExtratoVeicular}
          statusExtratoVeicular={statusExtratoVeicular}
          idLoteExtratoVeicular={idLoteExtratoVeicular}
          resumoLote={true}
        />
      ),
      width: "90px",
    },
  ] as IColum<IDadosDetalhado>[];
}

function gerarColunasUnitarios() {
  return [
    { name: "Placa", selector: ({ placa }: IDadosUnitario) => placa?.toUpperCase() ?? "-" },
    { name: "Renavam", selector: ({ renavam }: IDadosUnitario) => renavam?.toUpperCase() ?? "-" },
    { name: "Chassi", selector: ({ chassi }: IDadosUnitario) => chassi?.toUpperCase() ?? "-" },
    { name: "Tipo de pendência", selector: ({ tipoPendencia }: IDadosUnitario) => tipoPendencia },
    {
      name: "Vencimento",
      selector: ({ dataVencimento }: IDadosUnitario) => (dataVencimento ? formataDataISOParaBR(dataVencimento) : "-"),
    },
    {
      name: "Valor",
      selector: ({ valor }: IDadosUnitario) =>
        valor ? intlCurrencyFormat(valor / 100) : "-",
    },
    {
      name: "Status da consulta",
      selector: ({ status }: IDadosUnitario) => (
        <StatusConsultaExtratoVeicular
          statusConsulta={status === "Sucesso" ? StatusExtratoVeicular.COM_PENDENCIA : status}
        />
      ),
    },
    {
      name: "Tentativas de consulta",
      selector: ({ tentativasConsulta, status }: IDadosUnitario) => (
        <TooltipTentativas statusExtratoVeicular={status} tentativas={tentativasConsulta} />
      ),
    },
  ] as IColum<IDadosUnitario>[];
}

export function ListarDetalhesExtratoVeicular({
  buscarDadosDetalhado,
  buscarDadosUnitarios,
  dados,
  handleLimitChange,
  handlePageChange,
  totalRows,
  rowsPerPage,
  loading,
  setBuscarPor,
  reconsultarFalhas,
}: Readonly<ListarDetalhesExtratoVeicularProps>) {
  const [exibir, setExibir] = useState<"DETALHADOS" | "UNITARIOS">("DETALHADOS");
  const [colunas, setColunas] = useState<any[]>([]);
  const [showModalSucessoReconsultarFalhas, setShowModalSucessoReconsultarFalhas] = useState<boolean>(false);

  const { buscarDadosPlanilhaDetalhado, buscarDadosPlanilhaUnitarios } = useDetalhesExtratoVeicular();

  const { id: loteExtratoVeicularId } = useParams();

  useEffect(() => {
    setColunas(gerarColunasDetalhado());
  }, []);

  function handleTrocarTabela() {
    if (exibir === "DETALHADOS") {
      setExibir("UNITARIOS");
      setBuscarPor("UNITARIOS");
      setColunas(gerarColunasUnitarios());
      buscarDadosUnitarios();
      return;
    }

    setExibir("DETALHADOS");
    setBuscarPor("DETALHADOS");
    setColunas(gerarColunasDetalhado());
    buscarDadosDetalhado();
  }

  async function handleReconsultarFalhas() {
    const { hasErro } = await reconsultarFalhas(loteExtratoVeicularId!);

    if (hasErro) {
      return;
    }

    setShowModalSucessoReconsultarFalhas(true);
  }

  async function handleExportDetalhado() {
    const dados = await buscarDadosPlanilhaDetalhado();

    if (!dados.length) {
      return;
    }

    const dadosPlanilha = dados.map((item: any) => ({
      Placa: item.placa?.toUpperCase() ?? "-",
      Renavam: item.renavam?.toUpperCase() ?? "-",
      Chassi: item.chassi?.toUpperCase() ?? "-",
      Ipva: item.statusExtratoVeicular !== "Consultando" ? item.ipvas : "Consultando",
      Licenciamento: item.statusExtratoVeicular !== "Consultando" ? item.licenciamentos : "Consultando",
      Multa: item.statusExtratoVeicular !== "Consultando" ? item.multas : "Consultando",
      Gravame: item.statusExtratoVeicular !== "Consultando" ? item.gravames : "Consultando",
      Restrições: item.statusExtratoVeicular !== "Consultando" ? item.restricoes : "Consultando",
      Recall: item.statusExtratoVeicular !== "Consultando" ? item.recalls : "Consultando",
      "Data/Hora da consulta": converterDataHoraBr(item.dataHoraCadastro),
      "Status da consulta": item.statusExtratoVeicular,
      "Tentativas de consulta": item.tentativasConsulta,
    }));
    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Detalhes");
    writeFileXLSX(workBook, "Detalhes Extrato Veicular.xlsx");
  }

  async function handleExportUnitarios() {
    const dados = await buscarDadosPlanilhaUnitarios();

    if (!dados.length) {
      return;
    }

    const dadosPlanilha = dados.map((item: any) => ({
      Placa: item.placa?.toUpperCase() ?? "-",
      Renavam: item.renavam?.toUpperCase() ?? "-",
      Chassi: item.chassi?.toUpperCase() ?? "-",
      "Tipo de pendência": item.tipoPendencia ?? "-",
      Vencimento: item.dataVencimento ? formataDataISOParaBR(item.dataVencimento) : "-",
      Valor: item.valor ? intlCurrencyFormat(item.valor / 100) : "-",
      "Status da consulta": item.status,
      "Tentativas de consulta": item.tentativasConsulta,
    }));
    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Detalhes");
    writeFileXLSX(workBook, "Detalhes Extrato Veicular.xlsx");
  }

  const bottomTooltipReconsultar = 2.3;
  const rightTooltipReconsultar = -90;
  const widthTooltipReconsultar = 240;

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo text={`Detalhes Consulta ID ${loteExtratoVeicularId}`} />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <Table
          columns={colunas}
          data={dados}
          keyField="id"
          loading={loading}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          totalRows={totalRows}
          toolsHeader={
            <ContainerToggleVisualizacao>
              <Tooltip
                trigger={
                  <PrimaryButton type="button" onClick={handleReconsultarFalhas} variante="primary" outline>
                    <MdRefresh style={{ transform: "scaleX(-1)" }} /> Consultar novamente
                  </PrimaryButton>
                }
                bottom={bottomTooltipReconsultar}
                right={rightTooltipReconsultar}
                width={widthTooltipReconsultar}
              >
                <TooltipContentContainer>
                  <span>
                    Consultar novamente placa(s) com status de <Stronger>Falha</Stronger>
                  </span>
                </TooltipContentContainer>
              </Tooltip>
            </ContainerToggleVisualizacao>
          }
          exportExcel
          handleExport={exibir === "DETALHADOS" ? handleExportDetalhado : handleExportUnitarios}
          buttonExportVariant="secondary"
          showToggleVisualizacao
          onClickToggleVisualizacao={handleTrocarTabela}
          toggleComponent={<ToggleVisualizacaoDetalhesExtratoVeicular visualizacaoAtual={exibir} />}
          pagination
          rowsPerPage={rowsPerPage}
        />
      </CompositeContainer.Body>
      <ModalReconsultarFalhasExtratoVeicular
        show={showModalSucessoReconsultarFalhas}
        handleClose={() => setShowModalSucessoReconsultarFalhas(false)}
      />
    </CompositeContainer.Root>
  );
}

