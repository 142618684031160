import temaPadrao from "_config/temas/estilo/base";
import { StatusAdiantamentoEnum } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";

export function handleColor(statusAdiantamento: string) {
	if (statusAdiantamento === StatusAdiantamentoEnum.PENDENTE) {
		return temaPadrao.colors.warning.main;
	}

	if (statusAdiantamento === StatusAdiantamentoEnum.PAGO || statusAdiantamento === StatusAdiantamentoEnum.REEMBOLSO) {
		return temaPadrao.colors.success.main;
	}

	if (statusAdiantamento === StatusAdiantamentoEnum.AGUARDANDO_VALIDACAO) {
		return temaPadrao.colors.purple._50;
	}

	if (statusAdiantamento === StatusAdiantamentoEnum.CANCELADO) {
		return temaPadrao.colors.danger.main;
	}

	return temaPadrao.colors.blue._90;
}
