import { FaBarcode } from "react-icons/fa";
import { MdPix } from "react-icons/md";
import styled from "styled-components";

interface IContainer {
    marginTop?: string;
}
export const Container = styled.div<IContainer>`
    margin-top: ${({ marginTop }) => marginTop ?? "32px"};
`;

export const ContainerTitle = styled.div`
    color: ${({ theme }) => theme.colors.neutral.neutral50};
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 8px;
`;

export const ContainerBody = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.neutral05};
    border-radius: 10px;
    padding: 16px 0px 16px 0px;
    gap: 8px;
`;

export const ItemTitle = styled.div`
    color: ${({ theme }) => theme.colors.neutral.neutral60};
    font-size: 14px;
    padding: 8px 16px 0px 16px;
`;

export const ItemBody = styled.div`
    font-size: 14px;
    padding: 8px 16px 0px 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const ContainerDadosCobrancaTitle = styled.div`
    color: ${({ theme }) => theme.colors.neutral.neutral60};
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 16px;
`;

export const ContainerDadosCobranca = styled.div`
    padding: 16px;
    gap: 16px;
`;

export const ContainerDadosCobrancaBody = styled.div`
    font-weight: 400;
    font-size: 14px;
`;

export const DadosCobrancaItemTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const DadosCobrancaItemBody = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const DadosResumo = styled.div`
    display: flex;
`;

interface IResumoItem {
    align?: "left" | "right";
    width: string;
}
export const ResumoItem = styled.div<IResumoItem>`
    text-align: ${({ align }) => align ?? "left"};
    width: ${({ width }) => width};
`;

export const ResumoItemTitle = styled.div`
    font-size: 14px;
    padding: 8px 16px 0px 16px;
    color: ${({ theme }) => theme.colors.neutral.neutral60};
`;

export const ResumoItemBody = styled.div`
    font-size: 14px;
    padding: 8px 16px 0px 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const ContainerTotal = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const TotalItem = styled.div`
    font-size: 20px;
    padding: 0px 16px 0px 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const FormaPgtoTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
`;

export const FormaPgtoBody = styled.div`
    padding: 16px 0px 16px 0px;
    gap: 8px;
`;

interface IFormaPgtoOpcao {
    display?: "block" | "none";
}
export const FormaPgtoOpcao = styled.div<IFormaPgtoOpcao>`
    display: ${({ display }) => display ?? "block"};
    margin: 16px;
`;

export const IconPix = styled(MdPix)`
    color: #30B6A8 !important;
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;

    svg {
        width: 20px !important;
        height: 20px !important;
    }
`;

export const IconBoleto = styled(FaBarcode)`
    width: 20px !important;
    height: 20px !important;

    svg {
        width: 20px !important;
        height: 20px !important;
    }
`;

export const FormaPgtoOpcaoLabel = styled.div`
    display: flex;
    justify-content: left;
    padding-left: 8px;
    cursor: pointer;
`;

export const FormaPgtoContainerIcons = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.neutral05};
    padding: 8px;
    border-radius: 5px;
    margin-right: 8px;
`;

export const FormaPgtoOpcaoLabelTextoPrincipal = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    padding: 0;
`;

export const FormaPgtoOpcaoLabelTextoSecundario = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral.neutral50};
    margin: 0;
    padding: 0;
`;

export const IconTransferencia = styled.img`
	width: 20px;
	height: 20px;
`

export const ContainerMaisOpcoes = styled.span`
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-size: 14px;

    svg {
        width: 16px !important;
        height: 16px !important;
    }
`;

export const TituloItemDadosTransferencia = styled.span`
    color: ${({ theme }) => theme.colors.neutral.neutral60};
    margin-right: 10px;
`;

export const ItemDadosTransferencia = styled.span`
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.neutral90};
`;


