import { LoadingCirculo } from "components/LoadingCirculo";
import { ModalCustom } from "components/ModalCustom";
import { useEffect } from "react";
import { Container, LoadingContainer } from "./style";
import { InfoModalComprovantes } from "../InfoModalComprovantes";

interface Props {
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  qtdComprovantes: number;
  qtdDebitos: number;
  loadingDownload: boolean;
  downloadIniciado: boolean;
  placasSemComprovante: string[];
}

export function ModalConfirmarDownload({
  handleClose,
  onConfirm,
  show,
  loading,
  qtdComprovantes,
  qtdDebitos,
  loadingDownload,
  downloadIniciado,
  placasSemComprovante,
}: Props) {
  const mensagemQtdIguais = `Todos os comprovantes estão disponíveis para download`;

  useEffect(() => {
    if (!loadingDownload) {
      handleClose();
    }
  }, [loadingDownload]);

  return (
    <ModalCustom
      show={show}
      title="Baixar comprovantes"
      handleClose={handleClose}
      centered
      onConfirm={onConfirm}
      footer
      footerConfirmButton
    >
      {loading ? (
        <LoadingContainer>
          <LoadingCirculo mostrar={loading} />
        </LoadingContainer>
      ) : (
        <Container>
          {loadingDownload ? (
            <LoadingContainer>
              <LoadingCirculo mostrar={loadingDownload} />
            </LoadingContainer>
          ) : qtdComprovantes === 0 ? (
            <p>Nenhum comprovante encontrado para a solicitação</p>
          ) : qtdComprovantes !== qtdDebitos ? (
            <InfoModalComprovantes
              placas={placasSemComprovante}
              totalComprovantes={qtdComprovantes}
              totalPagamentos={qtdDebitos}
            />
          ) : (
            <p>{mensagemQtdIguais}</p>
          )}
        </Container>
      )}
    </ModalCustom>
  );
}
