export function formatarDinheiro(amount: number, decimalCount:number = 2, decimal: string = ".", thousands: string = ","){
    return amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  };

  export function formatarApenasNumeros(amount: number, decimalCount:number = 2, decimal: string = ".", thousands: string = ","){
    return amount.toLocaleString('pt-br',  { minimumFractionDigits: 2 });
  };

export function intlCurrencyFormat(valor: number) {
  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(valor);
}