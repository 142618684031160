import { Text, View } from "@react-pdf/renderer";
import { IRegistrosLeiloes } from "../../../../../DadosLeiloes/containers/BodyLeiloes";
import { STYLES } from "../../../../style";
import { StringUtils } from "_utils/String.utils";

interface Props {
  leilao: IRegistrosLeiloes;
}

export function BodyLeiloesPdf({ leilao }: Readonly<Props>) {
  return (
    <View>
      <View style={[STYLES.SECTION_ROW, STYLES.PADDING_04]}>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Lote</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>{leilao.lote ?? "-"}</Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Leiloeiro</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>{leilao.leiloeiro ?? "-"}</Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Comitente</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>{leilao.comitente ?? "-"}</Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Data leilão</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>{leilao.dataLeilao ?? "-"}</Text>
        </View>
      </View>
      <View style={[STYLES.SECTION_ROW, STYLES.PADDING_04]}>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Condições do veículo</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {leilao.condicoesVeiculo
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.condicoesVeiculo.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Situação do chassi</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {leilao.situacaoChassi
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.situacaoChassi.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Condições motor</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {leilao.condicoesMotor
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.condicoesMotor.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Condições câmbio</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {leilao.condicoesCambio
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.condicoesCambio.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
      </View>
      <View style={[STYLES.SECTION_ROW, STYLES.PADDING_04]}>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Condição mecânica</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {leilao.condicoesMecanica
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.condicoesMecanica.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View>
          <Text style={[STYLES.FONT_SIZE_09]}>Observação</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {leilao.observacao
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.observacao.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
      </View>
    </View>
  );
}
