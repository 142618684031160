import { Required } from "components/Required";
import {
  IconContainer,
  Checkbox,
  ListItem,
  OptionText,
  OptionsList,
  OptionsText,
  SelectBody,
  SelectButton,
  SelectContainer,
  SelectTitle,
} from "./style";
import { useEffect, useRef, useState } from "react";
import { FaCheck, FaChevronDown } from "react-icons/fa";
import temaPadrao from "_config/temas/estilo/base";

export function Multiselect({
  options,
  title,
  onChange,
  value,
  required = false,
  mensagemErro = "",
  disabled = false,
}: IMultiselectProps) {
  const [listVisible, setListVisible] = useState<boolean>(false);
  const [optionsCheckeds, setOptionsCheckeds] = useState<IMultiselectOptions[]>(options);
  const selectRef = useRef<HTMLDivElement>(null);

  function handleCheckOption(checkedOption: any) {
    const temporarioOptionsChecked = [...optionsCheckeds];
    const optionIndex = temporarioOptionsChecked.findIndex((item) => item.value === checkedOption);
    temporarioOptionsChecked[optionIndex].checked = !temporarioOptionsChecked[optionIndex].checked;

    setOptionsCheckeds(temporarioOptionsChecked);

    const selectedValues = temporarioOptionsChecked
      .map((option) => (option.checked ? option.value : null))
      .filter((item) => item !== null);
    onChange(selectedValues);
  }

  function handlePlaceholder() {
    const labelsArray = optionsCheckeds
      .map((item) => {
        if (item.checked) {
          return item.label;
        }

        return null;
      })
      .filter((item) => item !== null);

    if (labelsArray.length === 1) {
      return labelsArray[0];
    }

    return `${labelsArray.length} Selecionados`;
  }

  function handleClickOutside(event: MouseEvent) {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setListVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!value.length) {
      setOptionsCheckeds((prev) => prev.map((item) => ({ ...item, checked: false })));
    }
  }, [value]);

  useEffect(() => {
    setOptionsCheckeds(options);
  }, [options]);

  return (
    <SelectContainer>
      <SelectTitle desativado={disabled}>
        {title} {required && <Required />}
      </SelectTitle>
      <SelectBody ref={selectRef} mensagemErro={mensagemErro}>
        <SelectButton onClick={() => {
          if (!disabled) {
            setListVisible((prev) => !prev)
          }
        }}>
          {optionsCheckeds.some((item) => item.checked) ? <OptionText>{handlePlaceholder()}</OptionText> : "Selecione"}
          <IconContainer>
            <FaChevronDown />
          </IconContainer>
        </SelectButton>
        <OptionsList isVisible={listVisible}>
          {options.map((option) => (
            <ListItem key={option.value} onClick={() => handleCheckOption(option.value)}>
              <Checkbox key={option.value}>
                {optionsCheckeds.some((item) => item.value === option.value && item.checked) ? (
                  <FaCheck />
                ) : (
                  <div></div>
                )}
              </Checkbox>
              <OptionsText>{option.label}</OptionsText>
            </ListItem>
          ))}
        </OptionsList>
      </SelectBody>
      {mensagemErro && (
        <p
          style={{
            fontSize: "0.85rem",
            color: temaPadrao.colors.danger.main,
          }}
        >
          {mensagemErro}
        </p>
      )}
    </SelectContainer>
  );
}
