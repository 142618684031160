import { UploadContext } from "contexts/UploadContext";
import { useContext, useEffect, useState } from "react";
import {
  ButtonNomeArquivo,
  ContainerProgresso,
  Dialog,
  IconesContainer,
  ModalBody,
  NomeArquivoContainer,
  NomeArquivoWrapper,
  Progress,
  TitleContainer,
} from "./style";
import {
  FaCheckCircle,
  FaChevronDown,
  FaChevronUp,
  FaExclamationCircle,
  FaFileExcel,
  FaSpinner,
} from "react-icons/fa";
import { Divider } from "components/CalcularDebitos/style";
import { FiX } from "react-icons/fi";
import { XCircleFill } from "react-bootstrap-icons";
import { ModalPlanilhaCorrompida } from "./containers/ModalPlanilhaCorrompida";
import { ModalErrosPlanilha } from "./containers/ModalErrosPlanilha";
import { ModalErroPlanilha } from "./containers/ModalErroPlanilha";
import { ModalAvisosPlanilha } from "./containers/ModalAvisosPlanilha";

export function ModalUpload() {
  const {
    arquivos,
    showBox,
    progresso,
    limparArquivos,
    toggleShowBox,
    erros,
    urlRedirect,
    planilhasCorrompidas,
    erro,
    showModalErros,
    setShowModalErros,
    showModalCorrompida,
    setShowModalCorrompida,
    showModalErro,
    setShowModalErro,
    aviso,
    dadosAviso,
    showModalAvisos,
    setShowModalAvisos,
    loading,
  } = useContext(UploadContext);

  const [arquivosEmAndamento, setArquivosEmAndamento] = useState(0);
  const [bodyAberto, setBodyAberto] = useState(true);
  const [errosUpload, setErrosUpload] = useState<any>();
  const [mensagemErro, setMensagemErro] = useState<string>("");
  const [avisosUpload, setAvisosUpload] = useState<any>();
  const [arquivoAtual, setArquivoAtual] = useState<string | null>(null);

  useEffect(() => {
    if (arquivosEmAndamento > 0) {
      window.onbeforeunload = (ev) => {
        ev.preventDefault();
      };

      return;
    }

    window.onbeforeunload = null;
  }, [arquivosEmAndamento]);

  useEffect(() => {
    if (Object.keys(dadosAviso).length === 1 && dadosAviso.unitario.dados) {
      setArquivoAtual("unitario");
      setAvisosUpload({ dados: dadosAviso.unitario.dados });
    }
  }, [dadosAviso]);

  useEffect(() => {
    const quantidadeEmAndamento = arquivos?.filter(
      (item) => !item.uploadFinalizado
    ).length;

    setArquivosEmAndamento(quantidadeEmAndamento ?? 0);
  }, [arquivos]);

  function toggleBody() {
    setBodyAberto((prev) => !prev);
  }

  function handleCloseModalUpload() {
    toggleShowBox();
    limparArquivos();
  }

  function handleUploadComErro(temErro: boolean, temAviso: boolean = false) {
    if (temErro) {
      return (
        <span>
          <XCircleFill /> Falha
        </span>
      );
    }

    if (temAviso) {
      return (
        <span>
          <FaExclamationCircle />{" "}
          {aviso === "parcial" ? "Processado parcialmente" : "Não iniciado"}
        </span>
      );
    }

    return (
      <span>
        <FaCheckCircle /> Concluído
      </span>
    );
  }

  function handleOpenModalErrosUpload(erros: any) {
    setErrosUpload(erros);
    setShowModalErros(true);
  }

  function handleClickPlanilhaFinalizada(nomeArquivo: string) {
    setArquivoAtual(nomeArquivo);    
    if (urlRedirect[nomeArquivo]) {
      window.open(urlRedirect[nomeArquivo]);
      return;
    }

    if (planilhasCorrompidas[nomeArquivo]) {
      setShowModalCorrompida(true);
      return;
    }

    if (erro && erro[nomeArquivo]) {
      setMensagemErro(erro[nomeArquivo]);
      setShowModalErro(true);
      return;
    }

    if (erros[nomeArquivo]) {
      handleOpenModalErrosUpload(erros[nomeArquivo].erros);
      return;
    }

    if (dadosAviso[nomeArquivo]) {
      handleOpenModalAvisos(dadosAviso[nomeArquivo]);
    }
  }

  function handleOpenModalAvisos(dados: any) {
    setAvisosUpload(dados);
    setShowModalAvisos(true);
  }

  return (
    <Dialog open={showBox}>
      <TitleContainer>
        <span>{`${arquivosEmAndamento} upload${
          arquivosEmAndamento === 1 ? "" : "'s"
        } em progresso`}</span>
        <IconesContainer>
          {bodyAberto ? (
            <FaChevronDown onClick={toggleBody} />
          ) : (
            <FaChevronUp onClick={toggleBody} />
          )}
          {arquivosEmAndamento === 0 && (
            <FiX onClick={handleCloseModalUpload} />
          )}
        </IconesContainer>
      </TitleContainer>
      {bodyAberto && (
        <ModalBody>
          {arquivos && (
            <div>
              {arquivos.map((arquivo, index) => (
                <div key={`${arquivo.nomeArquivo}:${index + 1}`}>
                  <div>
                    {arquivo.uploadFinalizado ? (
                      <ButtonNomeArquivo
                        onClick={() =>
                          handleClickPlanilhaFinalizada(arquivo.nomeArquivo)
                        }
                        temErro={arquivo.temErro}
                        temAviso={arquivo.temAviso}
                      >
                        <NomeArquivoContainer>
                          <NomeArquivoWrapper>
                            <FaFileExcel style={{ marginRight: "0.25rem" }} />
                            <div>{arquivo.nomeArquivo.split(":")[0]}</div>
                          </NomeArquivoWrapper>
                        </NomeArquivoContainer>
                        {handleUploadComErro(arquivo.temErro, arquivo.temAviso)}
                      </ButtonNomeArquivo>
                    ) : (
                      <>
                        <NomeArquivoContainer>
                          <NomeArquivoWrapper>
                            <FaFileExcel style={{ marginRight: "0.25rem" }} />
                            <div>{arquivo.nomeArquivo.split(":")[0]}</div>
                          </NomeArquivoWrapper>
                        </NomeArquivoContainer>
                        <ContainerProgresso>
                          <Progress
                            concluido={arquivo.uploadFinalizado}
                            temErro={arquivo.temErro}
                            value={progresso[arquivo.nomeArquivo]}
                            max={100}
                          />
                          <span>
                            {arquivo.uploadFinalizado ? (
                              <div></div>
                            ) : (
                              <FaSpinner className="rodar" />
                            )}
                          </span>
                        </ContainerProgresso>
                      </>
                    )}
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          )}
        </ModalBody>
      )}
      <ModalErrosPlanilha
        erros={errosUpload}
        handleClose={() => setShowModalErros(false)}
        show={showModalErros}
      />
      <ModalPlanilhaCorrompida
        handleClose={() => setShowModalCorrompida(false)}
        show={showModalCorrompida}
      />
      <ModalErroPlanilha
        handleClose={() => setShowModalErro(false)}
        show={showModalErro}
        mensagemErro={mensagemErro}
      />
      <ModalAvisosPlanilha
        avisos={avisosUpload}
        handleClose={() => setShowModalAvisos(false)}
        show={showModalAvisos}
        arquivoAtual={arquivoAtual}
        loading={loading}
      />
    </Dialog>
  );
}
