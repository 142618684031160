import { StatusDebitoPagamento } from "../../Enum/StatusDebitoPagamento";
import { Container } from "./style";

interface StatusProps {
  status: number;
}

export function Status({ status }: StatusProps) {
  return (
    <Container statusPagamento={Number(status)}>
      {StatusDebitoPagamento[status]}
    </Container>
  );
}
