import styled from "styled-components";
import { StatusDebitoPagamento } from "../../Enum/StatusDebitoPagamento";

interface InfoProps {
  status: any;
}

const habilitado: any = ["Indeferido", "Erro", "Recusado"];

export const Container = styled.div<InfoProps>`
  text-align: center;

  &:hover {
    cursor: ${({ status }) => habilitado.includes(StatusDebitoPagamento[Number(status)])  && 'pointer' }
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;

    ${({ status, theme }) =>
      !habilitado.includes(StatusDebitoPagamento[Number(status)])  && `color: ${theme.colors.neutral.neutral30};`}
  }
`;
