import { Page } from "containers/Page";
import { FiltrosConsultarLicenciamento } from "./containers/FiltrosConsultarLicenciamento";
import { ListagemSolicitacoesLicenciamento } from "features/licenciamento/emissao/pages/Listar/containers/ListagemSolicitacoesLicenciamento";
import { useSolicitacaoLicenciamentos } from "features/licenciamento/emissao/hooks/useSolicitacaoLicenciamentos";
import { useEffect } from "react";
import { Paginar } from "_utils/enuns/Paginar.enum";

export function ListarConsultas() {
  const {
    listarSolicitacoesLicenciamento,
    setFiltros,
    loading,
    solicitacoes,
    totalRows,
    setLimit,
    setPage,
    limit
  } = useSolicitacaoLicenciamentos();

  useEffect(() => {
    listarSolicitacoesLicenciamento(Paginar.SIM, 'consulta');
  }, [listarSolicitacoesLicenciamento]);

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }


  function handlePageChange(page: number) {
    setPage(page);
  }

  return (
    <Page lastPage="Licenciamento" title="Consultar licenciamento">
      <FiltrosConsultarLicenciamento
        handleSubmit={setFiltros}
        pagina="consulta"
      />
      <ListagemSolicitacoesLicenciamento
        dados={solicitacoes}
        loading={loading}
        pagina="consulta"
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        rowsPerPage={limit}
        totalRows={totalRows}
      />
    </Page>
  );
}
