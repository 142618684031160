import { useRequest } from "hooks/useRequest";
import { useCallback } from "react";

interface IVeiculosLicenciamentoPR {
  placa: string;
  renavam: string;
  chassi: string;
  uf: string;
}

interface ISolicitarLicenciamento {
  plataforma: string;
  emitir: string;
  descricao: string;
  veiculos: IVeiculosLicenciamentoPR[];
  cobrar: NullableString;
  idCliente: NullableString;
  nomeCliente: NullableString;
  motivoIsencao: NullableString;
}

export interface IResponseSolicitarLicenciamento {
  id: string | number;
  erros: any[];
}

export function useSolicitacaoLicenciamentoPR() {
  const { loading, post } = useRequest("consulta");

  const solicitarLicenciamento = useCallback(
    async (dados: ISolicitarLicenciamento) => {
      const { data, hasErro } = await post<
        ISolicitarLicenciamento,
        IResponseSolicitarLicenciamento
      >("/licenciamento/consultas", dados);

      if (hasErro) {
        return;
      }

      const { erros, id } = data as IResponseSolicitarLicenciamento;

      return {
        id,
        erros,
      };
    },
    [post]
  );

  return {
    solicitarLicenciamento,
    loading,
  };
}

