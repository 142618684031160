import React from "react";

interface Props {
  children: React.ReactNode;
}

const PageWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div id="page-wrapper" className="gray-bg">
      {children}
    </div>
  );
};

export default PageWrapper;
