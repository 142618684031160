import styled from "styled-components";
import { FaCheckCircle as FaCheckCircleBase, FaExclamationCircle, FaEye, FaEyeSlash, FaInfoCircle, FaLock, FaTimesCircle as FaTimesCircleBase } from "react-icons/fa";
import { Button, Form, Spinner } from "react-bootstrap";
import { CustomInput } from "components/CustomInput";
import { PrimaryButton } from "components/Button/style";

export const ContainerRegrasSenha = styled.div`
  margin-top: 0.5rem;
  font-size: 13px;
  color: #676a6c;
`;

export const FaCheckCircle = styled(FaCheckCircleBase)`
  color: #10AC85;
  margin-right: 0.5rem;
`

export const FaTimesCircle = styled(FaTimesCircleBase)`
  color: #FF3129;
  margin-right: 0.5rem;
`

export const FaCheckCircleDireito = styled(FaCheckCircleBase)`
  font-size: 14px;
  color: #10AC85;
`

export const FaExclamationCircleDireito = styled(FaExclamationCircle)`
  font-size: 14px;
  color: #FF3129;
`

export const ContainerSucesso = styled.div`
`;

export const ContainerNovaSenha = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 2.5rem;
`;

export const TitleNovaSenha = styled.h1`
  color: ${(props) => props.color};
  font-size: 32px;
  font-weight: 700;
  padding:0;
  margin: 0;
`;

export const TextoNovaSenha = styled.p`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const ContainerForm = styled(Form)`
`;

export const CustomButton = styled(PrimaryButton)`
  width: 100%;
  height: 50px;
  margin-top: 3rem;
`;

export const ContainerProgressBar = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const CustomButtonSucesso = styled(PrimaryButton)`
  width: 100%;
  height: 50px;
  margin-top: 4rem;
`;

export const TitleSucesso = styled.h1`
  color: ${(props) => props.color};
  font-size: 32px;
  font-weight: 700;
  padding:0;
  margin: 0;
  margin-bottom: 2.5rem;
`;

export const TextoSucesso = styled.p`
  padding: 0;
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const Carregamento = styled(Spinner).attrs({
  animation: "border",
})`
  color: ${({ theme }) => theme.colors.neutral.neutral00};
`;

export const IconeOlho = styled(FaEye)`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const IconeOlhoCortado = styled(FaEyeSlash)`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const IconeSenha = styled(FaLock)`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const InputNovaSenha = styled(CustomInput).attrs((props) => ({
  type: props.type,
  placeholder: props.placeholder,
  size: "lg",
}))`
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const InputConfirmarNovaSenha = styled(CustomInput).attrs((props) => ({
  type: props.type,
  placeholder: props.placeholder,
  size: "lg",
}))`
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const ErroCodigo = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.danger.bgColor};
  color: ${({ theme }) => theme.colors.neutral.neutral60};
  border-radius: 6px;
`;

export const IconeErro = styled(FaInfoCircle)`
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.danger.main} !important;
`;