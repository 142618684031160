import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { IDadosPorTipoDebito } from "../../../ListarDetalhes/interface/IDadosPorTipoDebito";
import { IDadosClienteReciboAdiantamento } from "../../../ListarDetalhes/interface/IDadosClienteReciboAdiantamento";
import { STYLES } from "./style";
import logoLwDebitos from "_assets/images/logo-lw-debitos.png";
import { DadosReciboPDF } from "./Containers/DadosRecibo";
import { DadosResumoReciboPDF } from "./Containers/DadosResumo";
import { FormaPgtoPDF } from "./Containers/FormaPgto";
import { DadosTransferenciaPDF } from "./Containers/DadosTransferencia";
import { InformacaoAdiantamentoPdf } from "./Containers/InformacaoAdiantamento";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";
import { IDadosBanco } from "features/debito/consulta/interfaces/IDadosBanco";

interface Props {
  formaPgto: FormaPgtoEnum;
  dadosPorTipoBoleto: IDadosPorTipoDebito[];
  valorTotal: number;
  idReciboAdiantamento: number;
  dadosClienteReciboAdiantamento: IDadosClienteReciboAdiantamento;
  dadosBanco: IDadosBanco
}

export function PdfReciboAdiantamento({
  formaPgto,
  dadosPorTipoBoleto,
  valorTotal,
  idReciboAdiantamento,
  dadosClienteReciboAdiantamento,
  dadosBanco,
}: Readonly<Props>) {
  return (
    <Document>
      <Page style={{ padding: 16 }} size="A4">
        <View style={[STYLES.SECTION_ROW]}>
          <View
            style={[
              STYLES.SECTION_COL_1,
              {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #e6e6e6",
                paddingBottom: "9px",
                marginBottom: 8,
              },
            ]}
          >
            <Image src={logoLwDebitos} style={{ width: "15%" }} />
          </View>
        </View>
        <Text style={[
          STYLES.FONT_SIZE_12,
          { margin: 0 }
        ]}>Recibo de adiantamento</Text>
        <InformacaoAdiantamentoPdf />
        <DadosReciboPDF
          formaPgto={formaPgto}
          valorTotal={valorTotal}
          idReciboAdiantamento={idReciboAdiantamento}
          dadosClienteReciboAdiantamento={dadosClienteReciboAdiantamento}
        />
        <DadosResumoReciboPDF
          dadosPorTipoDebito={dadosPorTipoBoleto}
          valorTotal={valorTotal}
        />
        <View style={STYLES.CONTAINER}>
          <View style={STYLES.FORMA_PGTO_TITLE}><Text>Forma de pagamento</Text></View>
          <View style={STYLES.FORMA_PGTO_OPCAO}>
            <FormaPgtoPDF formaPgto={formaPgto} />
          </View>
        </View>
        {formaPgto === FormaPgtoEnum.TRANSFERENCIA && <>
          <DadosTransferenciaPDF dados={dadosBanco} />
        </>}
      </Page>
    </Document>
  );
}
