import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../../../style";
import { intlCurrencyFormat } from "_utils/Money";
import { IDetalhesMultas } from "../../../../../DadosMultas";
import temaPadrao from "_config/temas/estilo/base";

interface Props {
  textoRow: string;
  dadosMultas: IDetalhesMultas[];
}

export function RowMultasPDF({ dadosMultas, textoRow }: Readonly<Props>) {
  return (
    <View>
      <View
        style={[
          STYLES.SECTION_ROW,
          STYLES.DADOS_PENDENCIA,
          {
            backgroundColor:
              dadosMultas.length > 0 ? temaPadrao.colors.danger.bgColor : temaPadrao.colors.neutral.neutral10,
          },
        ]}
      >
        <View style={[STYLES.SECTION_COL_3, STYLES.SEM_MARGIN]}>
          <Text style={[STYLES.FONT_SIZE_09]}>{textoRow}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_3, STYLES.SEM_MARGIN]}>
        </View>
        <View style={[STYLES.SECTION_COL_3, STYLES.SEM_MARGIN]}>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {intlCurrencyFormat(
              dadosMultas
                .map((multa) => (multa.valorComDesconto ? multa.valorComDesconto : multa.valor + multa.valorJuro))
                .reduce((acc, cur) => acc + cur / 100, 0)
            )}
          </Text>
        </View>
      </View>
    </View>
  );
}

