import styled from "styled-components";

export const LoadingContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
`;

export const DadosErrosBox = styled.div`
  font-size: 14px;
`;

export const DadosErrosTitle = styled.strong``;

export const DadosErrosText = styled.p`
  margin-top: 0.5rem;
`;

export const AtencaoContainer = styled.div`
  padding-top: 1rem;
  padding-left: 2.75rem;
  display: grid;
  gap: 1rem;
`;
