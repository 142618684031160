import styled from "styled-components";

export const Container = styled.div`
  padding-top: 1rem;
  padding-left: 2.75rem;
  display: grid;
  gap: 1rem;
`;

export const DadosIpvaContainer = styled.div`
  display: grid;
  gap: 2rem;
`;

export const DadosIpvaBox = styled.div`
font-size: 14px;
`;

export const DadosIpvaTitle = styled.strong``;

export const DadosIpvaText = styled.p``;

export const AtencaoContainer = styled.div``;