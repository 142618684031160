import styled from "styled-components";

interface FeedbackProps{
  show:boolean;
}

export const Container = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .my-3 {
    text-align: start;
    width: 100%;
  }
`;

export const Divider = styled.hr`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.neutral40};
`;

export const Titulo = styled.p`
  font-size: 16px;
`

export const Feedback = styled.p<FeedbackProps>`
  color: ${({ theme }) => theme.colors.danger.main};
  ${({ show }) => !show && "display: none;"}
`