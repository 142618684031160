import { useContext } from "react";
import { DropdownMenuContext } from "../context/DropdownMenu.context";

export function useDropdownMenu() {
	const context = useContext(DropdownMenuContext);

	if (!context) {
		throw new Error("Para utilizar o dropdown é necessário utilizar o DropdownMenu.Root");
	}

	return context;
}
