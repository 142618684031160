import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.warning.bgColor};
  padding: 10px 20px;
  border-radius: 0.5rem;
`;

export const TextoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    color: ${(props) => props.theme.colors.warning.main};
    margin-right: 10px;
  }
`;
