import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { IFiltrosDetalhesEmissao } from "../interfaces/IFiltrosDetalhesEmissao";
import { yupResolver } from "@hookform/resolvers/yup";
import { filtrarDetalhesEmissaoSchema } from "features/licenciamento/emissao/validate/validator";
import { useMaskInput } from "hooks/useMaskInput";
import { DropdownSelect } from "components/DropdownSelect";
import { ButtonContainer } from "./style";
import { FilterButton } from "components/Button/style";
import { FaFilter } from "react-icons/fa";

interface Props {
  onSubmit: (dados: any) => void;
}

export function Filtros({ onSubmit }: Props) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isSubmitted },
  } = useForm<IFiltrosDetalhesEmissao>({
    resolver: yupResolver(filtrarDetalhesEmissaoSchema),
  });

  const { mInputPlaca } = useMaskInput();
  const anoAtual = new Date().getFullYear();
  const anoOutros = 1;
  return (
    <Container titulo="Filtros">
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="2">
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Busque por placa"
                    value={value ?? ""}
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                    mensagemErro={errors.placa?.message}
                    isValid={
                      isSubmitted &&
                      isValid &&
                      (value ? value.length >= 1 : false)
                    }
                    isInvalid={!!errors.placa}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Form.Label>Renavam</Form.Label>
              <Controller
                control={control}
                name="renavam"
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Busque por renavam"
                    value={value ?? ""}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    mensagemErro={errors.renavam?.message}
                    isValid={
                      isSubmitted &&
                      isValid &&
                      (value ? value.length >= 1 : false)
                    }
                    isInvalid={!!errors.renavam}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="statusEmissao"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={[
                      { label: "Emitido", value: "2" },
                      { label: "Processando", value: "1" },
                      { label: "Falha", value: "3" },
                      { label: "Dados inconsistentes", value: "4" },
                      { label: "Exercício Indisponível", value: "5" },
                    ]}
                    title="Status emissão"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="exercicio"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={[
                      { label: `${anoAtual}`, value: `${anoAtual}` },
                      { label: `${anoAtual - 1}`, value: `${anoAtual - 1}` },
                      { label: `${anoAtual - 2}`, value: `${anoAtual - 2}` },
                      { label: `${anoAtual - 3}`, value: `${anoAtual - 3}` },
                      { label: `Outros`, value: `${anoOutros}` },
                    ]}
                    title="Exercício"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <ButtonContainer>
              <FilterButton type="submit" disabled={false} loading={false}>
                <FaFilter /> Filtrar
              </FilterButton>
              <FilterButton
                onClick={() =>
                  reset({
                    placa: null,
                    statusEmissao: undefined,
                    renavam: null,
                    exercicio: undefined,
                  })
                }
                disabled={false}
                loading={false}
                outline={true}
              >
                Limpar
              </FilterButton>
            </ButtonContainer>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

