import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container, IconeContainer, Strong, Texto, TextoContainer } from "./style";

interface IModalConfirmacaoSolicitacao {
  show: boolean;
  handleClose: () => void;
  idSolicitacao: number;
}

export function ModalConfirmacao({
  show,
  handleClose,
  idSolicitacao,
}: IModalConfirmacaoSolicitacao) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Pagamento de débitos"
      centered={true}
    >
      <Container>
        <IconeContainer>
          <FaCheck />
        </IconeContainer>
        <TextoContainer>
          <Texto>Solicitação efetuada com sucesso!</Texto>
          <Strong>Os pagamentos serão processados.</Strong>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}

