import { Alert, Button, Col, Form, Spinner, Tabs } from "react-bootstrap";
import {
  FaEye,
  FaEyeSlash,
  FaFileContract,
  FaInfoCircle,
  FaLock,
  FaQuestionCircle,
  FaRegAddressCard,
  FaUserAlt,
} from "react-icons/fa";
import styled from "styled-components";
import { CustomInput } from "../../../../components/CustomInput";
import { PrimaryButton } from "components/Button/style";

export const Container = styled.div``;

export const FeedbackError = styled(Alert).attrs({
  variant: "danger",
})`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

interface TabsStyledProps {
  host: string;
}
export const TabsStyled = styled(Tabs) <TabsStyledProps>`
  margin-top: 1rem;

  &.nav-tabs {
    border-bottom: 0;
  }

  .nav-link {
    border-radius: 10px 10px 0px 0px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.neutral.neutral50};
    background-color: ${(props) => props.theme.colors.neutral.neutral05};
    font-size: 1rem;
    padding: 0.5vh;
    width: 120px;
  }

  .nav-link.active {
    color: ${(props) => props.theme.colors.white};
    background-color: ${({ theme, host }) => {
    return theme.colors.layout.p00;
  }};
    font-weight: 600;
  }
`;

export const TabUsuario = styled.div``;

export const TextoContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const Texto = styled.div`
  font-family: sans-serif;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #767f85;
`;

export const Title = styled.h1`
  color: ${(props) => props.color};
  font-size: 32px;
  font-weight: 700;
  padding:0;
  margin: 0;
`;

export const CustomButton = styled(PrimaryButton)`
  height: 50px;
  width: 100%;
  border-radius: 5px;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const ContainerForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FormGroup = styled(Form.Group)`
  margin-top: 1.5rem;
`;

export const IconeEmail = styled(FaUserAlt)`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const IconeSenha = styled(FaLock)`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const IconeOlho = styled(FaEye)`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const IconeOlhoCortado = styled(FaEyeSlash)`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const IconeRenavam = styled(FaFileContract)`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const IconePlaca = styled(FaRegAddressCard)`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const IconeInterrogacao = styled(FaQuestionCircle)`
  color: ${({ theme }) => theme.colors.project.main};
  margin-bottom: 5px;
  margin-left: 8px;
  margin-right: 8px;
`;

export const IconeAlerta = styled(FaInfoCircle)`
  color: #dc3545;
  vertical-align: initial;
  margin-right: 8px;
  min-height: 15px;
  min-width: 15px;
`;

export const Dica = styled.div`
  background-color: #1A1A1A;
  color: #FFF;
  padding: 16px;
  border-radius: 5px;
`;

export const InputEmail = styled(CustomInput).attrs({
  type: "email",
  placeholder: "Insira seu email",
  size: "lg",
})`
  border-left: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`;

export const InputCodigo = styled(CustomInput)`
  border-left: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  &::placeholder {
    font-size: 1rem;
  }
`;

export const InputSenha = styled(CustomInput).attrs((props) => ({
  type: props.type,
  placeholder: "Insira sua senha",
  size: "lg",
}))`
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const InputNovaSenha = styled(CustomInput).attrs((props) => ({
  type: props.type,
  placeholder: "Insira sua nova senha",
  size: "lg",
}))`
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const InputConfirmarNovaSenha = styled(CustomInput).attrs((props) => ({
  type: props.type,
  placeholder: "Confirme sua nova senha",
  size: "lg",
}))`
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const InputRenavam = styled(CustomInput).attrs({
  type: "text",
  placeholder: "Insira seu renavam",
  size: "lg",
})`
  border-left: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`;

export const InputPlaca = styled(CustomInput).attrs({
  type: "text",
  placeholder: "Insira sua placa",
  size: "lg",
})`
  border-left: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Coluna = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

interface TextButtonProps {
  host: string;
}
export const TextButton = styled.span<TextButtonProps>`
  cursor: pointer;
  color: ${({ theme, host }) => {
    return theme.colors.layout.p00;
  }} !important;
  font-size: 0.9rem;

  :hover {
    text-decoration: underline;
  }
`;

export const TextButtonEsqueceuSenha = styled.span<TextButtonProps>`
  cursor: pointer;
  color: ${({ theme, host }) => {
    return theme.colors.layout.p00;
  }} !important;
  margin-top: 2rem;
  font-size: 0.9rem;
  text-align: center;

  :hover {
    text-decoration: underline;
  }
`;

export const TextoCentralizado = styled.span`
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
`;

export const Carregamento = styled(Spinner).attrs({
  animation: "border",
})`
  color: ${({ theme }) => theme.colors.neutral.neutral00};
`;

export const ButtonReenviar = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.project.main};
`;

interface IDescricaoProps {
  centralizar?: boolean;
}

export const ContainerDescricao = styled.div<IDescricaoProps>`
  ${({ centralizar }) => centralizar ? "text-align: center;" : ""}
`;

export const NaoRecebido = styled.span`
  color: #767f85;
  font-size: 14px;
`;

export const ErroCodigo = styled.div`
  margin-top: 0.5rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.danger.bgColor};
  color: ${({ theme }) => theme.colors.neutral.neutral60};
  border-radius: 6px;
`;

export const IconeErro = styled(FaInfoCircle)`
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.danger.main} !important;
`;