import { Col } from "react-bootstrap";
import styled from "styled-components";

export const ButtonContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Container = styled.div`
  label {
    font-size: 0.875rem
  }
`;