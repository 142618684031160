import { PrimaryButton } from "components/Button/style";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { Col, Form, Row } from "react-bootstrap";
import { ButtonContainer, Container } from "./style";
import { useSolicitacaoLicenciamentoSP } from "../../hooks/useSolicitacaoLicenciamentoSP";
import { Controller, useForm } from "react-hook-form";
import { solicitarLicenciamentoSPSchema } from "features/licenciamento/emissao/validate/validator";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMaskInput } from "hooks/useMaskInput";
import { LoadingCirculo } from "components/LoadingCirculo";
import { SelecionarEmpresa } from "../SelecionarEmpresa";
import { useContext, useEffect, useState } from "react";
import { ContextPermissao } from "hooks/ContextPermissao";
import { Cobrar } from "features/licenciamento/emissao/constants";
import { Tooltip } from "components/Tooltip";
import { Question } from "components/Icons/Question";
import { InfoTooltip } from "components/InfoTooltip";

export interface IFormSolicitarLicenciamento {
  placa: string;
  renavam: string;
  chassi: string;
  cpfCnpjProprietario: string;
  descricao: string;
  empresa: NullableString;
  nomeEmpresa: NullableString;
  cobrar: Cobrar;
  motivoIsencao: NullableString;
  crv?: string;
  exercicio: string;
}

export enum EmitirLicenciamento {
  Nao = "0",
  Sim = "1",
}

export interface BodyModalProps {
  handleCloseModal: () => void;
  listarSolicitacoesLicenciamento: () => Promise<void>;
  handleUploadArquivo?: (file: File | BinaryType) => void;
  handleSubmit?: (dados: any) => void;
  setDadosIndividual?: any;
  setShowModalConfirmar?: any;
  buscarNomeEmpresa: (idCliente: NullableString) => string;
  confirmarEmissao?: () => Promise<void>;
  pagina?: "consulta" | "emissao";
}

export function IndividualSP({
  handleCloseModal,
  setDadosIndividual,
  setShowModalConfirmar,
  buscarNomeEmpresa,
  confirmarEmissao,
  pagina = "emissao",
}: Readonly<BodyModalProps>) {
  const { loading } = useSolicitacaoLicenciamentoSP();
  const { mInputPlaca, mInputRenavam, mInputChassi, mInputCPFCNPJ } = useMaskInput();
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);
  const [optionsExercicio, setOptionsExercicio] = useState<IOptions[]>([]);
  const [optionSelected, setOptionSelected] = useState<null | number>();

  useEffect(() => {
    const anoAtual = new Date().getFullYear();
    setOptionSelected(anoAtual);
    setValue("exercicio", `${anoAtual}`);
    setOptionsExercicio([
      {
        label: `${anoAtual}`,
        value: anoAtual,
      },
      {
        label: `${anoAtual - 1}`,
        value: anoAtual - 1,
      },
    ]);
  }, []);
  const tooltipBottom = 1.3;

  const {
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
  } = useForm<IFormSolicitarLicenciamento>({
    resolver: yupResolver(solicitarLicenciamentoSPSchema),
  });

  function formatCpfCnpj(cpfCnpj: string) {
    return cpfCnpj.replace(/[.\-/]/g, "");
  }

  async function handleSubmitForm(dados: IFormSolicitarLicenciamento) {
    const cpfCnpj = formatCpfCnpj(dados.cpfCnpjProprietario);
    setDadosIndividual({
      ...dados,
      cpfCnpjProprietario: cpfCnpj,
      nomeEmpresa: buscarNomeEmpresa(dados.empresa),
    });

    if (verificarUsuarioAwLw()) {
      setShowModalConfirmar(true);
      handleCloseModal();
      return;
    }

    if (confirmarEmissao) {
      confirmarEmissao();
      handleCloseModal();
    }
  }

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit(handleSubmitForm)}>
        {loading ? (
          <LoadingCirculo mostrar={loading} />
        ) : (
          <>
            <Row style={{ paddingTop: "32px" }}>
              <Col lg="2" style={{ paddingTop: "3px" }}>
                <Form.Group>
                  <DropdownSelect
                    disabled
                    onSelect={() => { }}
                    options={[
                      {
                        label: "SP",
                        value: "SP",
                      },
                    ]}
                    selectedValue="SP"
                    title="UF"
                  />
                </Form.Group>
              </Col>
              <Col lg="3">
                <Form.Group>
                  <Form.Label>Placa</Form.Label>
                  <Controller
                    control={control}
                    name="placa"
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        type="text"
                        placeholder="Digite a placa"
                        value={value}
                        onChange={(e: any) => {
                          onChange(mInputPlaca(e));
                        }}
                        isValid={isSubmitted && !errors.placa}
                        isInvalid={!!errors.placa}
                        mensagemErro={errors.placa?.message}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col lg="3">
                <Form.Group>
                  <Form.Label>Renavam</Form.Label>
                  <Controller
                    control={control}
                    name="renavam"
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        value={value}
                        type="text"
                        placeholder="Digite o renavam"
                        isValid={isSubmitted && !errors.renavam}
                        isInvalid={!!errors.renavam}
                        onChange={(event) => {
                          onChange(mInputRenavam(event));
                        }}
                        mensagemErro={errors.renavam?.message}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group>
                  <Form.Label>Chassi</Form.Label>
                  <Controller
                    control={control}
                    name="chassi"
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        type="text"
                        value={value}
                        placeholder="Digite o chassi"
                        isValid={isSubmitted && !errors.chassi}
                        isInvalid={!!errors.chassi}
                        onChange={(event) => {
                          onChange(mInputChassi(event));
                        }}
                        mensagemErro={errors.chassi?.message}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ paddingTop: "32px", marginTop: "0.5rem" }}>
              <Col lg="4">
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }}>CPF/CNPJ do Proprietário</Form.Label>
                  <Controller
                    control={control}
                    name="cpfCnpjProprietario"
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        type="text"
                        value={value}
                        isValid={isSubmitted && !errors.cpfCnpjProprietario}
                        isInvalid={!!errors.cpfCnpjProprietario}
                        onChange={(event) => onChange(mInputCPFCNPJ(event))}
                        mensagemErro={errors.cpfCnpjProprietario?.message}
                        placeholder="Digite o CPF/CNPJ"
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              {pagina === "emissao" && (
                <Col md="3">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "14px" }}>
                      Exercício{" "}
                      <Tooltip
                        bottom={tooltipBottom}
                        trigger={<Question />}
                      >
                        <InfoTooltip
                          mensagem={"Informe o ano do licenciamento que deseja emitir"}
                        ></InfoTooltip>
                      </Tooltip>
                    </Form.Label>

                    <Controller
                      control={control}
                      name="exercicio"
                      render={({ field: { onChange } }) => (
                        <DropdownSelect
                          onSelect={(e) => {
                            setOptionSelected(e);
                            onChange(e);
                          }}
                          options={optionsExercicio}
                          selectedValue={optionSelected}
                          mensagemErro={errors.exercicio?.message}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col>
                <Form.Group>
                  <Form.Label>Descrição</Form.Label>
                  <Controller
                    control={control}
                    name="descricao"
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        type="text"
                        isValid={isSubmitted && !errors.descricao}
                        isInvalid={!!errors.descricao}
                        mensagemErro={errors.descricao?.message}
                        placeholder="Digite a descricao"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div style={{ marginTop: "32px" }}>
              <SelecionarEmpresa controller={Controller} control={control as any} errors={errors} />
            </div>
            <Row style={{ paddingTop: "32px" }}>
              <ButtonContainer lg="12" style={{ justifyContent: "end" }}>
                <PrimaryButton type="submit">Emitir</PrimaryButton>
              </ButtonContainer>
            </Row>
          </>
        )}
      </Form>
    </Container>
  );
}

