import styled from "styled-components";

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const IconeContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.project.bgColor};
  padding: 0.5rem;
  border-radius: 0.5rem;

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.project.main};
  }
`;

export const MensagemSucessoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

