import { Row } from "react-bootstrap";
import { StyledCol } from "./style";
import { IDetalhesMultas } from "../..";
import { ToggleMultas } from "../ToggleMultas";
import { Stronger } from "components/Stronger";

interface Props {
  detalhes: IDetalhesMultas[];
}

export function BodyMultas({ detalhes }: Readonly<Props>) {
  return (
    <div>
      <Row>
        <StyledCol lg="3" md="3" sm="3">
          <Stronger>Auto de infração</Stronger>
        </StyledCol>
        <StyledCol lg="6" md="6" sm="6">
          <Stronger>Descrição</Stronger>
        </StyledCol>
        <StyledCol style={{ justifyContent: "center" }} lg="1" md="1" sm="1">
          <Stronger>Data</Stronger>
        </StyledCol>
        <StyledCol style={{ justifyContent: "end" }} lg="2" md="2" sm="2">
          <Stronger>Valor (R$)</Stronger>
        </StyledCol>
      </Row>
      {detalhes.map((detalhe) => (
        <ToggleMultas key={detalhe.autoInfracao} dados={detalhe} />
      ))}
    </div>
  );
}

