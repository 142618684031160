import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../../../style";
import temaPadrao from "_config/temas/estilo/base";
import { BodyRestricoesPDF } from "../BodyRestricoesPDF";
import { IDadosRestricoes } from "../../../../../DadosRestricoes";

interface Props {
  restricao: IDadosRestricoes;
}

export function RowRestricoesPDF({ restricao }: Readonly<Props>) {
  return (
    <View>
      <View
        style={[
          STYLES.SECTION_ROW,
          STYLES.DADOS_PENDENCIA,
          {
            backgroundColor:
              temaPadrao.colors.neutral.neutral10,
          },
        ]}
      >
        <View style={[STYLES.SECTION_COL_1, STYLES.SEM_MARGIN]}>
          <Text style={[STYLES.FONT_SIZE_09]}>{restricao.motivoBloqueio}</Text>
        </View>
      </View>
      {restricao.motivoBloqueio === 'Comunicação de venda' && <BodyRestricoesPDF restricao={restricao} />}
    </View>
  );
}

