import { PrimaryButton } from "components/Button/style";
import { ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import styled from "styled-components";

export const Header = styled(ModalHeader)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 12px 16px 12px 16px;
  height: 3rem;
`
export const Title = styled(ModalTitle)`
  font-size: 1rem;
  font-weight: 600;
`

export const Footer = styled(ModalFooter)`
  display: flex;
  justify-content: center;
`

export const ModalButton = styled(PrimaryButton)`
  width: 200px;
`

export const ButtonClose = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  display: flex;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;