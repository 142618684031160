import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1.5rem;
  display: grid;
  gap: 2rem;
`;

export const FormRow = styled(Row)`
  width: 100%;
  padding-left: 0.5rem;
`

export const InputContainer = styled.div`
  height: 94px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const InputCol = styled(Col)`
  padding-left: 0;
  padding-right: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
`;

