import { Col, Row } from "react-bootstrap";
import { Stronger } from "components/Stronger";
import { Container } from "./style";
import { StringUtils } from "_utils/String.utils";

export interface IRegistrosLeiloes {
  lote: string;
  leiloeiro: string;
  comitente: string;
  dataLeilao: string;
  condicoesVeiculo: string;
  situacaoChassi: string;
  condicoesMotor: string;
  condicoesCambio: string;
  condicoesMecanica: string;
  observacao: string;
}

interface Props {
  leilao: IRegistrosLeiloes;
}

export function BodyLeiloes({ leilao }: Readonly<Props>) {
  return (
    <Container>
      <Row>
        <Col>
          <span>Lote</span> <br />
          <Stronger>{leilao.lote}</Stronger>
        </Col>
        <Col>
          <span>Leiloeiro</span> <br />
          <Stronger>{leilao.leiloeiro}</Stronger>
        </Col>
        <Col>
          <span>Comitente</span> <br />
          <Stronger>{leilao.comitente ?? "-"}</Stronger>
        </Col>
        <Col>
          <span>Data leilão</span> <br />
          <Stronger>{leilao.dataLeilao}</Stronger>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>Condições do veículo</span> <br />
          <Stronger>
            {leilao.condicoesVeiculo
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.condicoesVeiculo.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
        <Col>
          <span>Situação do chassi</span> <br />
          <Stronger>
            {leilao.situacaoChassi
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.situacaoChassi.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
        <Col>
          <span>Condições motor</span> <br />
          <Stronger>
            {leilao.condicoesMotor
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.condicoesMotor.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
        <Col>
          <span>Condições câmbio</span> <br />
          <Stronger>
            {leilao.condicoesCambio
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.condicoesCambio.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="3" sm="3">
          <span>Condições mecânica</span> <br />
          <Stronger>
            {leilao.condicoesMecanica
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.condicoesMecanica.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
        <Col>
          <span>Observação</span> <br />
          <Stronger>
            {leilao.observacao
              ? StringUtils.primeiraLetraMaiuscula(
                leilao.observacao.split("_").join(" ")
              )
              : "-"}
          </Stronger>
        </Col>
      </Row>
    </Container>
  );
}
