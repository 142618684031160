import { ErroPagamentos } from "../ErroPagamentos";
import { IndeferidoPagamentos } from "../IndeferidoPagamentos";
import { RecusadoPagamentos } from "../RecusadoPagamentos";

interface MensagemPagamentosProps {
  status: string;
  erro: string;
  valorConsulta: number;
  valorDisponivel: number;
  juros: number;
}

interface ComponenteMensagemProps {
  Indeferido: string;
  Recusado: string;
  Erro: string;
}

export function MensagemPagamentos({
  status,
  erro,
  valorConsulta,
  valorDisponivel,
  juros,
}: MensagemPagamentosProps) {
  const componenteMensagem = {
    Indeferido: (
      <IndeferidoPagamentos
        erro={erro}
        valorConsulta={valorConsulta}
        valorDisponivel={valorDisponivel}
        diferenca={juros}
      />
    ),
    Recusado: <RecusadoPagamentos erro={erro} />,
    Erro: <ErroPagamentos erro={erro} />,
  };

  return (
    <div>{componenteMensagem[status as keyof ComponenteMensagemProps]}</div>
  );
}

