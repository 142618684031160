import { IDadosBanco } from "features/debito/consulta/interfaces/IDadosBanco";
import { STYLES } from "../../style";
import { Text, View } from "@react-pdf/renderer";

interface IProps {
    dados: IDadosBanco
}
export function DadosTransferenciaPDF({
    dados
}: IProps) {
    return (
        <View style={STYLES.CONTAINER}>
            <View style={STYLES.CONTAINER_TITLE}><Text>Dados bancários</Text></View>
            <View style={[STYLES.CONTAINER_BODY, { fontSize: 11 }]}>
                <View style={{ margin: "0px 16px 0px 16px", gap: 8 }}>
                    <View style={STYLES.SECTION_ROW}>
                        <View style={STYLES.SECTION_COL_10P}>
                            <Text style={STYLES.TEXTO_QUASE_CLARO}>Banco</Text>
                        </View>
                        <View style={STYLES.SECTION_COL_4}>
                            <Text>{dados.codigoBanco} - {dados.nomeBanco}</Text>
                        </View>
                    </View>
                    <View style={STYLES.SECTION_ROW}>
                        <View style={STYLES.SECTION_COL_10P}>
                            <Text style={STYLES.TEXTO_QUASE_CLARO}>Agência</Text>
                        </View>
                        <View style={STYLES.SECTION_COL_4}>
                            <Text>{dados.agencia}</Text>
                        </View>
                    </View>
                    <View style={STYLES.SECTION_ROW}>
                        <View style={STYLES.SECTION_COL_10P}>
                            <Text style={STYLES.TEXTO_QUASE_CLARO}>Conta</Text>
                        </View>
                        <View style={STYLES.SECTION_COL_4}>
                            <Text>{dados.numeroConta}</Text>
                        </View>
                    </View>
                    <View style={STYLES.SECTION_ROW}>
                        <View style={STYLES.SECTION_COL_10P}>
                            <Text style={STYLES.TEXTO_QUASE_CLARO}>CNPJ</Text>
                        </View>
                        <View style={STYLES.SECTION_COL_4}>
                            <Text>25.462.742/0001-97</Text>
                        </View>
                    </View>
                    <View style={STYLES.SECTION_ROW}>
                        <View style={[STYLES.SECTION_COL_10P, { marginBottom: 0 }]}>
                            <Text style={STYLES.TEXTO_QUASE_CLARO}>Nome</Text>
                        </View>
                        <View style={[STYLES.SECTION_COL_4, { marginBottom: 0 }]}>
                            <Text>LW Tecnologia Ltda</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
}