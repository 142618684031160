import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../../../style";
import { IDadosRestricoes } from "../../../../../DadosRestricoes";

interface Props {
  restricao: IDadosRestricoes;
}

export function BodyRestricoesPDF({ restricao }: Readonly<Props>) {
  return (
    <View>
      <View style={STYLES.SECTION_ROW}>
        <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
          <Text style={STYLES.FONT_SIZE_08}>Indicador de comunicação de venda</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
          <Text style={STYLES.FONT_SIZE_08}>Data comunicação</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
          <Text style={STYLES.FONT_SIZE_08}>Data venda</Text>
        </View>
      </View>
      <View style={STYLES.SECTION_ROW}>
        <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.indicadorComunicacaoDeVenda ?? "Não consta comunicação de vendas"}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.dataComunicacao ?? "Não consta em nossa base"}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
          <Text style={STYLES.FONT_SIZE_08}>{restricao.dataVenda ?? "Não consta em nossa base"}</Text>
        </View>
      </View>
    </View>
  );
}

