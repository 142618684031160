import { useState } from "react";
import { Page, Document, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import {
	ControllsContainer,
	ControllsEnd, PageContainer,
	PageSeparator,
	PdfButton,
	PdfContainer,
	PdfInputPageContainer,
	PdfViewerHeader, ViewerContainer
} from "./style";
import {
	FaCaretLeft,
	FaCaretRight,
	FaDownload,
	FaPrint, FaSearchMinus,
	FaSearchPlus,
	FaStepBackward,
	FaStepForward
} from "react-icons/fa";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect } from "components/DropdownSelect";
import { salvarPdf } from "_utils/pdf/MergeArquivosParaPdf";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "pdfjs-dist/build/pdf.worker.min.mjs";

interface Props {
	pdf: Blob;
	handleClose: Callback;
	show: boolean;
}

export function PdfViewer({ pdf, handleClose, show }: Readonly<Props>) {
	const ESCALA = {
		MINIMA: 0.25,
		MAXIMA: 2,
		STEP: 0.25,
	};
	const [numPages, setNumPages] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [escala, setEscala] = useState<number>(1);

	function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
		setNumPages(numPages);
	}

	function handlePdfPageChange(pdfPageNumber: number) {
		const pageDiv = document.querySelector(`div[data-page-number="${pdfPageNumber}"]`);

		if (pageDiv) {
			pageDiv.scrollIntoView({ behavior: "smooth" });
		}
	}

	function handleEscalaPdfChange(escalaPdf: number) {
		if (escalaPdf < ESCALA.MINIMA) {
			setEscala(ESCALA.MINIMA);
			return;
		}

		if (escalaPdf > ESCALA.MAXIMA) {
			setEscala(ESCALA.MAXIMA);
			return;
		}

		setEscala(escalaPdf);
	}

	function handleScrollDocument(event: any) {
		const scrollMaximo = event.target.scrollHeight;
		const scrollTotal = event.target.scrollTop;
		const scrollPagina = scrollMaximo / numPages;
		const paginaAtual = Math.ceil(scrollTotal / scrollPagina);

		setPageNumber(paginaAtual > 0 ? paginaAtual : 1);
	}

	function imprimirPdf(pdf: Blob) {
		const pdfBlobUrl = URL.createObjectURL(pdf);
		const iframe = document.createElement("iframe");
		iframe.style.display = "none";
		iframe.src = pdfBlobUrl;
		document.body.appendChild(iframe);
		iframe.onload = () => {
			iframe.focus();
			iframe.contentWindow?.print();
		};
	}


	return (
		<Modal onHide={handleClose} show={show} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<ViewerContainer>
				<PdfViewerHeader>
					<ControllsContainer>
						<PdfButton disabled={pageNumber === 1} onClick={() => handlePdfPageChange(1)}>
							<FaStepBackward size={12} />
						</PdfButton>
						<PdfButton disabled={pageNumber === 1} onClick={() => handlePdfPageChange(pageNumber - 1)}>
							<FaCaretLeft />
						</PdfButton>
						<PdfInputPageContainer>
							<CustomInput value={pageNumber} onChange={(e) => handlePdfPageChange(Number(e.target.value))} />
							<span>de {numPages}</span>
						</PdfInputPageContainer>
						<PdfButton disabled={pageNumber === numPages} onClick={() => handlePdfPageChange(pageNumber + 1)}>
							<FaCaretRight />
						</PdfButton>
						<PdfButton disabled={pageNumber === numPages} onClick={() => handlePdfPageChange(numPages)}>
							<FaStepForward size={12} />
						</PdfButton>
					</ControllsContainer>
					<ControllsContainer>
						<PdfButton disabled={escala === ESCALA.MINIMA} onClick={() => handleEscalaPdfChange(escala - ESCALA.STEP)}>
							<FaSearchMinus />
						</PdfButton>
						<PdfButton disabled={escala === ESCALA.MAXIMA} onClick={() => handleEscalaPdfChange(escala + ESCALA.STEP)}>
							<FaSearchPlus />
						</PdfButton>
						<DropdownSelect
							onSelect={(value) => handleEscalaPdfChange(value)}
							options={[
								{
									label: "25%",
									value: 0.25,
								},
								{
									label: "50%",
									value: 0.5,
								},
								{
									label: "75%",
									value: 0.75,
								},
								{
									label: "100%",
									value: 1,
								},
								{
									label: "125%",
									value: 1.25,
								},
								{
									label: "150%",
									value: 1.5,
								},
								{
									label: "175%",
									value: 1.75,
								},
								{
									label: "200%",
									value: 2,
								},
							]}
							selectedValue={escala}
						/>
					</ControllsContainer>
					<ControllsContainer>
						<ControllsEnd>
							<PdfButton onClick={() => salvarPdf(pdf)}>
								<FaDownload />
							</PdfButton>
							<PdfButton onClick={() => imprimirPdf(pdf)}>
								<FaPrint />
							</PdfButton>
							<PdfButton onClick={handleClose}>
								<FontAwesomeIcon icon={faXmark} />
							</PdfButton>
						</ControllsEnd>
					</ControllsContainer>
				</PdfViewerHeader>
				<PdfContainer onScroll={handleScrollDocument}>
					<Document loading={!numPages} file={pdf} onLoadSuccess={onDocumentLoadSuccess} noData="Carregando...">
						<PageContainer>
							{numPages === 1 ? (
								<Page pageNumber={pageNumber} scale={escala} renderAnnotationLayer={false} />
							) : (
								Array.from({ length: numPages }, (_, i) => i + 1).map((page) => (
									<Page key={`page-${page}`} pageNumber={page} scale={escala} renderAnnotationLayer={false}>
										{page < numPages && <PageSeparator />}
									</Page>
								))
							)}
						</PageContainer>
					</Document>
				</PdfContainer>
			</ViewerContainer>
		</Modal>
	);
}
