import { FaExclamationTriangle } from "react-icons/fa";
import { Container, TextoContainer } from "./style";

interface InformacaoRecusarPagamentosProps {
  mensagem: string;
}

export function InformacaoPagamentos({ mensagem }: InformacaoRecusarPagamentosProps) {
  return (
    <Container>
      <TextoContainer>
        <FaExclamationTriangle />
        {mensagem}
      </TextoContainer>
    </Container>
  )
}
