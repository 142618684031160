import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { intlCurrencyFormat } from "_utils/Money";

interface Props {
  licenciamentos: any[];
}

export function DadosLicenciamentoPDF({ licenciamentos }: Readonly<Props>) {
  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Licenciamento</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Licenciamento</Text>
            <Text style={STYLES.FONT_SIZE_09}>
              {licenciamentos.length ? "Existe débito de licenciamento" : "Não existe débito de licenciamento"}
            </Text>
          </View>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor total licenciamento</Text>
            <Text style={STYLES.FONT_SIZE_09}>
              {licenciamentos.length
                ? intlCurrencyFormat(licenciamentos.map((item) => item.valor / 100).reduce((prev, acc) => acc + prev))
                : "-"}
            </Text>
          </View>
        </View>
        {licenciamentos.length > 0 && (
          <View style={STYLES.SECTION_ROW}>
            <View style={STYLES.SECTION_COL_2}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Descrição</Text>
            </View>
            <View style={STYLES.SECTION_COL_2}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor</Text>
            </View>
          </View>
        )}
        {licenciamentos.map((licenciamento) => (
          <View style={[STYLES.SECTION_ROW, STYLES.DADOS_PENDENCIA]} key={licenciamento.observacao}>
            <View style={[STYLES.SECTION_COL_2, STYLES.SEM_MARGIN]}>
              <Text style={STYLES.FONT_SIZE_09}>{licenciamento.observacao}</Text>
            </View>
            <View style={[STYLES.SECTION_COL_2, STYLES.SEM_MARGIN]}>
              <Text style={STYLES.FONT_SIZE_09}>{intlCurrencyFormat(licenciamento.valor / 100)}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

