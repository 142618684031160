import { StatusConsulta } from "features/licenciamento/consulta/enum/StatusConsulta"

export const STATUS_CONSULTA_OPTIONS = [
    {
        value: StatusConsulta.Consultado.toString(),
        label: "Consultado"
    },
    {
        value: StatusConsulta.Processando.toString(),
        label: "Processando",
    },
    {
        value: StatusConsulta.Falha.toString(),
        label: "Falha",
    },
    {
        value: StatusConsulta["Dados inconsistentes"].toString(),
        label: "Dados inconsistentes",
    }
]