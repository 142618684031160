import { saveAs } from "file-saver";
import { PDFDocument } from "pdf-lib";

async function mergeArquivosParaPdf(arquivos: (string | ArrayBuffer)[]) {
  // Criar um novo documento PDF para a mesclagem
  const mergedPdf = await PDFDocument.create();

  await Promise.all(
    arquivos.map(async (arquivo: ArrayBuffer | string, index: number) => {
      // arquivo = {} as
      if (typeof arquivo === "string") {
        const response = await fetch(arquivo);
        arquivo = await response.arrayBuffer();
      }

      await adicionarNovaPagina(mergedPdf, arquivo, index);
    })
  );

  // Serializar o documento PDF mesclado para um ArrayBuffer
  const mergedPdfBytes = await mergedPdf.save();

  const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
  return blob;
}

async function adicionarNovaPagina(
  pdfMerge: PDFDocument,
  arquivo: ArrayBuffer,
  index: number
) {
  const bytes = new Uint8Array(arquivo);
  const tipoArquivo = identificarTipoBytes(bytes);

  if (tipoArquivo == "pdf") {
    const newPdfDoc = await PDFDocument.load(arquivo);
    const pages2 = await pdfMerge.copyPages(
      newPdfDoc,
      newPdfDoc.getPageIndices()
    );
    pages2.forEach((page: any) => {
      pdfMerge.addPage(page);
    });
    return;
  }

  let image: any = "";
  if (tipoArquivo == "jpeg") {
    image = await pdfMerge.embedJpg(bytes);
  } else if (tipoArquivo == "png") {
    image = await pdfMerge.embedPng(bytes);
  }

  const page = pdfMerge.addPage([600, 800]);

  const scaleFactor = Math.min(500 / image.width, 600 / image.height);

  page.drawImage(image, {
    x: (600 - image.width * scaleFactor) / 2,
    y: 800 - image.height * scaleFactor - 50 * (index + 1),
    width: image.width * scaleFactor,
    height: image.height * scaleFactor,
  });

  return;
}

function identificarTipoBytes(bytes: Uint8Array) {
  const isPdf =
    bytes[0] === 0x25 &&
    bytes[1] === 0x50 &&
    bytes[2] === 0x44 &&
    bytes[3] === 0x46;

  if (isPdf) return "pdf";

  const isPng =
    bytes[0] === 0x89 &&
    bytes[1] === 0x50 &&
    bytes[2] === 0x4e &&
    bytes[3] === 0x47 &&
    bytes[4] === 0x0d &&
    bytes[5] === 0x0a &&
    bytes[6] === 0x1a &&
    bytes[7] === 0x0a;

  if (isPng) return "png";

  const isJpeg = bytes[0] === 0xff && bytes[1] === 0xd8 && bytes[2] === 0xff;

  if (isJpeg) return "jpeg";

  return "unknown";
}

function salvarPdf(data: Blob | string, filename?: string) {
  saveAs(data, filename);
}

export { identificarTipoBytes, mergeArquivosParaPdf, salvarPdf };
