import { ComponentPropsWithoutRef, ReactNode } from "react";
import { Container } from "./style";

interface Props extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
}

export function SectionBody({ children, ...rest }: Readonly<Props>) {
  return <Container {...rest}>{children}</Container>;
}
