import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  gap: 1rem;
  width: 20%;
`;

export const SelecioneText = styled.span`
  color: ${({ theme }) => theme.colors.neutral.neutral40};
`;

