import styled from "styled-components";

export const ImagensContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 1rem;

  img {
    width: 24%;
    height: auto;
  }
`;
