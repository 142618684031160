import { TextoClaro } from "components/TextoClaro";
import { Col, Row } from "react-bootstrap";
import { ImagensContainer } from "./style";

export interface IImagensLeilao {
  url: string;
}

interface Props {
  imagens: IImagensLeilao[];
}

export function BodyImagensLeilao({ imagens }: Readonly<Props>) {
  return (
    <>
      <Row>
        <Col>
          <TextoClaro>Fotos</TextoClaro>
        </Col>
      </Row>
      <Row>
        <ImagensContainer>
          {imagens?.length ? (
            imagens?.map((imagem, index) => (
              <img
                key={imagem.url}
                alt={`Foto ${(index + 1).toString().padStart(2, "0")}`}
                src={imagem.url}
              />
            ))
          ) : (
            <Col>Nenhuma foto disponível</Col>
          )}
        </ImagensContainer>
      </Row>
    </>
  );
}
