import { useCallback, useState } from "react";
import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { formataData } from "_utils/Data";
import { IFiltrosConsultas } from "../interfaces/FiltrosConsultas";
import { IFiltrosDetalhesConsultas } from "../interfaces/FiltrosDetalhesConsultas";
import { HttpStatusCode } from "axios";

export function useRelatorioConsultas() {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [dados, setDados] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [limitDetalhes, setLimitDetalhes] = useState<number>(100);
  const [filtros, setFiltros] = useState<IFiltrosConsultas>();
  const [filtrosDetalhes, setFiltrosDetalhes] =
    useState<IFiltrosDetalhesConsultas>();
  const [resumoConsulta, setResumoConsulta] = useState(null);

  const buscarConsultas = useCallback(async () => {
    setLoading(true);
    const ajuste = [
      filtros?.dataConsulta
        ? `dataInicio=${formataData(
            filtros.dataConsulta[0].toISOString()
          )}&dataFim=${formataData(filtros.dataConsulta[1].toISOString())}`
        : null,
      filtros?.idConsulta ? `id=${filtros.idConsulta}` : null,
      filtros?.idEmpresa ? `empresaId=${filtros.idEmpresa}` : null,
      filtros?.placa ? `placa=${filtros.placa}` : null,
      filtros?.renavam ? `renavam=${filtros.renavam}` : null,
    ];

    const params = ajuste.filter((item) => item !== null).join("&");

    try {
      const { status, data } = await api.get(
        `/debito/consulta/?${params}&page=${page}&limit=${limit}&order=DESC`
      );

      setDados(data.items);
      setTotalRows(data.meta.totalItems);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData | any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      setDados([]);
      return response;
    } finally {
      setLoading(false);
    }
  }, [page, limit, filtros]);

  const buscarDetalhesConsulta = useCallback(
    async (id: string | number) => {
      setLoading(true);
      const ajuste = [
        filtrosDetalhes?.idTipoDebito
          ? `tipoDebito=${filtrosDetalhes.idTipoDebito}`
          : null,
        filtrosDetalhes?.placa ? `placa=${filtrosDetalhes.placa}` : null,
        filtrosDetalhes?.statusPagamento ||
        filtrosDetalhes?.statusPagamento === 0
          ? `status=${filtrosDetalhes.statusPagamento}`
          : null,
        filtrosDetalhes?.vencimento
          ? `vencimento=${filtrosDetalhes.vencimento}`
          : null,
        filtrosDetalhes?.placa ? `placa=${filtrosDetalhes.placa}` : null,
        filtrosDetalhes?.renavam ? `renavam=${filtrosDetalhes.renavam}` : null,
      ];

      const params = ajuste.filter((item) => item !== null).join("&");

      try {
        const { status, data } = await api.get(
          `/debito/consulta/${id}/detalhes?${params}&page=${page}&limit=${limitDetalhes}`
        );

        setDados(data.items);
        setTotalRows(data.meta.totalItems);
        const response = new HttpResponse(false, status, data);
        setError(response);
        return response;
      } catch (error: IHttpError<IData | any> | any) {
        const response = new HttpError<IData>(true, error);
        if (error.status !== HttpStatusCode.NotFound) {
          setError(response);
        }
        setDados([]);
        return response;
      } finally {
        setLoading(false);
      }
    },
    [filtrosDetalhes, page, limitDetalhes]
  );

  async function buscarDadosPlanilha(idConsulta: number | string) {
    const ajuste = [
      filtrosDetalhes?.idTipoDebito
        ? `tipoDebito=${filtrosDetalhes.idTipoDebito}`
        : null,
      filtrosDetalhes?.placa ? `placa=${filtrosDetalhes.placa}` : null,
      filtrosDetalhes?.statusPagamento || filtrosDetalhes?.statusPagamento === 0
        ? `status=${filtrosDetalhes.statusPagamento}`
        : null,
      filtrosDetalhes?.vencimento
        ? `vencimento=${filtrosDetalhes.vencimento}`
        : null,
      filtrosDetalhes?.placa ? `placa=${filtrosDetalhes.placa}` : null,
      filtrosDetalhes?.renavam ? `renavam=${filtrosDetalhes.renavam}` : null,
    ];

    const params = ajuste.filter((item) => item !== null).join("&");

    try {
      const { status, data } = await api.get(
        `/debito/consulta/${idConsulta}/detalhes?${params}&paginate=0`
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData | any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoading(false);
    }
  }

  const buscarResumoConsulta = useCallback(async (id: number | string) => {
    try {
      const { status, data } = await api.get(`/debito/consulta/${id}/resumo`);

      const response = new HttpResponse(false, status, data);
      setResumoConsulta(data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData | any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    dados,
    loading,
    page,
    setPage,
    limit,
    setLimit,
    buscarConsultas,
    error,
    totalRows,
    buscarDetalhesConsulta,
    setLimitDetalhes,
    limitDetalhes,
    filtros,
    setFiltros,
    filtrosDetalhes,
    setFiltrosDetalhes,
    buscarDadosPlanilha,
    resumoConsulta,
    buscarResumoConsulta,
  };
}
