import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../../../style";
import { IDetalhesMultas } from "../../../../../DadosMultas";
import { DetalhesMultasPDF } from "../DetalhesMultas";

interface Props {
  dadosMultas: IDetalhesMultas[];
}

export function BodyMultasPDF({ dadosMultas }: Readonly<Props>) {
  return (
    <View>
      {dadosMultas?.map((multa) => (
        <View key={multa.autoInfracao}>
          <View style={STYLES.SECTION_ROW}>
            <View style={STYLES.SECTION_COL_4}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Auto de infração</Text>
            </View>
            <View style={STYLES.SECTION_COL_2}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Descrição</Text>
            </View>
            <View style={STYLES.SECTION_COL_4}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Data da infração</Text>
            </View>
            <View style={STYLES.SECTION_COL_4}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor (R$)</Text>
            </View>
          </View>
          <View key={multa.autoInfracao}>
            <View style={STYLES.SECTION_ROW}>
              <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
                <Text style={STYLES.FONT_SIZE_08}>{multa.autoInfracao}</Text>
              </View>
              <View style={[STYLES.SECTION_COL_2, { paddingRight: 6 }]}>
                <Text style={STYLES.FONT_SIZE_08}>{multa.descricaoArtigo}</Text>
              </View>
              <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
                <Text style={STYLES.FONT_SIZE_09}>{multa.dataInfracao}</Text>
              </View>
              <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
                <Text style={STYLES.FONT_SIZE_09}>
                  {multa.valorComDesconto
                   ? (multa.valorComDesconto / 100).toFixed(2).toLocaleString()
                   :((multa.valor + multa.valorJuro) / 100).toFixed(2).toLocaleString()}
                </Text>
              </View>
            </View>
            <DetalhesMultasPDF multa={multa} />
          </View>
        </View>
      ))}
    </View>
  );
}

