import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const filtrosRelatorioPagamentoSchema = yup.object().shape({
  placa: yup
    .string()
    .optional().nullable(),
  idPagamento: yup.string().optional().nullable(),
  dataSolicitacao: yup.array().optional().nullable(),
  idEmpresa: yup.number().optional().nullable(),
  statusPagamento: yup.string().optional().nullable(),
  statusAdiantamento: yup.string().optional().nullable(),
  idConsulta: yup.string().optional().nullable(),
});

export const filtrosDetalhesRelatorioPagamentoSchema = yup.object().shape({
  placa: yup
    .string()
    .optional().nullable(),
  vencimento: yup.string().optional().nullable(),
  statusPagamento: yup.string().optional().nullable(),
  tipoDebito: yup.string().optional().nullable(),
});