import { SectionBody } from "./body";
import { SectionCol } from "./col";
import { SectionHeader } from "./header";
import { SectionIcone } from "./icone";
import { SectionRoot } from "./root";
import { SectionRow } from "./row";
import { SectionRowToggle } from "./rowToggle";
import { SectionTitulo } from "./titulo";

export const Section = {
  Root: SectionRoot,
  Header: SectionHeader,
  Titulo: SectionTitulo,
  Icone: SectionIcone,
  Body: SectionBody,
  Row: SectionRow,
  Col: SectionCol,
  RowToggle: SectionRowToggle,
};

