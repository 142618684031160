import { IDadosBanco } from "features/debito/consulta/interfaces/IDadosBanco";
import { Container, ContainerBody, ContainerTitle, ItemDadosTransferencia, TituloItemDadosTransferencia } from "../../style";

interface IProps {
    dados: IDadosBanco
}
export function DadosTransferencia({
    dados
}: IProps) {
    return (
        <Container>
            <ContainerTitle>
                Dados bancários
            </ContainerTitle>
            <ContainerBody>
                <table style={{ margin: "0px 16px 0px 16px" }}>
                    <tbody>
                        <tr>
                            <td><TituloItemDadosTransferencia>Banco</TituloItemDadosTransferencia></td>
                            <td><ItemDadosTransferencia>{dados.codigoBanco} - {dados.nomeBanco}</ItemDadosTransferencia></td>
                        </tr>
                        <tr>
                            <td><TituloItemDadosTransferencia>Agência</TituloItemDadosTransferencia></td>
                            <td><ItemDadosTransferencia>{dados.agencia}</ItemDadosTransferencia></td>
                        </tr>
                        <tr>
                            <td><TituloItemDadosTransferencia>Conta</TituloItemDadosTransferencia></td>
                            <td><ItemDadosTransferencia>{dados.numeroConta}</ItemDadosTransferencia></td>
                        </tr>
                        <tr>
                            <td><TituloItemDadosTransferencia>CNPJ</TituloItemDadosTransferencia></td>
                            <td><ItemDadosTransferencia>25.462.742/0001-97</ItemDadosTransferencia></td>
                        </tr>
                        <tr>
                            <td><TituloItemDadosTransferencia>Nome</TituloItemDadosTransferencia></td>
                            <td><ItemDadosTransferencia>LW Tecnologia Ltda</ItemDadosTransferencia></td>
                        </tr>
                    </tbody>
                </table>
            </ContainerBody>
        </Container>
    );
}