import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../../../style";
import { IRegistrosRemarketing } from "../../../../../DadosLeiloes/containers/BodyRemarketing";
import { BodyRemarketingPdf } from "../BodyRemarketingPdf";

interface Props {
  textoRow: string;
  dadosRemarketing: IRegistrosRemarketing;
}

export function RowRemarketingPDF({
  dadosRemarketing,
  textoRow,
}: Readonly<Props>) {
  return (
    <View>
      <View style={[STYLES.SECTION_ROW, STYLES.DADOS_PENDENCIA]}>
        <View style={[STYLES.SEM_MARGIN]}>
          <Text style={[STYLES.FONT_SIZE_09]}>{textoRow}</Text>
        </View>
      </View>
      {dadosRemarketing && (
        <BodyRemarketingPdf remarketing={dadosRemarketing} />
      )}
    </View>
  );
}
