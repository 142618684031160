import { Route, Routes } from "react-router-dom";
import { DetalhesRelatorioPagamento } from "../pages/detalhes";
import { RelatorioPagamentos } from "../pages/relatorio";

export function RelatorioPagamentoRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RelatorioPagamentos />} />
      <Route path="/:id" element={<DetalhesRelatorioPagamento />}/>
    </Routes>
  )
}
