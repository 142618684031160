import { ReactElement } from "react";
import { TooltipContainer } from "./style";

interface IInfoTooltipProps {
    mensagem: string | ReactElement;
}
export function InfoTooltip({ mensagem }: IInfoTooltipProps) {
    return (
        <TooltipContainer>
            {mensagem}
        </TooltipContainer>
    )
}