import { ILogar } from "features/Login/@types/ILogar";
import { useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import {
  FormGroup,
  IconeEmail,
  IconeOlho,
  IconeOlhoCortado,
  IconeSenha,
  InputEmail,
  InputSenha,
  Texto,
  TextoContainer,
  Title,
} from "../LoginBox/style";

interface Props {
  register: UseFormRegister<ILogar>;
  errors: FieldErrors<ILogar>;
}

export function LoginForm({ register, errors }: Props) {
  const [inputType, setInputType] = useState("password");

  function handleMostrarSenha() {
    setInputType((prev) => (prev === "password" ? "text" : "password"));
  }

  return (
    <>
      <TextoContainer>
        <Title>Login</Title>
        <Texto>
          Insira seus dados para efetuar o login.
        </Texto>
      </TextoContainer>

      <FormGroup>
        <InputEmail noBoxShadow validar styleIconeEsquerdo={{ padding: "18px 12px 18px 12px" }} iconeEsquerdo={<IconeEmail style={{ marginLeft: "12px", fontSize: "14px" }} />} {...register("email")} mensagemErro={errors.email?.message} />
      </FormGroup>
      <FormGroup>
        <InputSenha
          type={inputType}
          iconeEsquerdo={<IconeSenha style={{ marginLeft: "12px", fontSize: "14px" }} />}
          iconeDireito={inputType === "password" ? <IconeOlho /> : <IconeOlhoCortado />}
          onClickMostrarSenha={handleMostrarSenha}
          {...register("senha")}
          mensagemErro={errors.senha?.message}
          noBoxShadow
          validar
        />
      </FormGroup>
    </>
  );
}
