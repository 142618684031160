import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const filtrosRelatorioConsultasSchema = yup.object().shape({
  placa: yup
    .string()
    .optional().nullable(),
  idConsulta: yup.number().optional().nullable(),
  dataConsulta: yup.array().optional().nullable(),
  idEmpresa: yup.number().optional().nullable(),
});

export const filtrosDetalhesRelatorioConsultaScheme = yup.object().shape({
  placa: yup.string().optional().nullable(),
  idTipoDebito: yup.number().optional().nullable(),
  vencimento: yup.string().optional().nullable(),
  statusPagamento: yup.number().optional().nullable(),
})