import { STYLES } from "../../style";
import { Text, View } from "@react-pdf/renderer";

export function InformacaoAdiantamentoPdf() {
    const mensagemInfo = <Text style={STYLES.TEXTO_QUASE_CLARO}>O pagamento dos débitos será realizado <Text style={STYLES.NEGRITO}>após a identificação do adiantamento</Text>. Caso a identificação ocorra fora do prazo de SLA de 05 (cinco) dias antes do vencimento dos débitos, <Text style={STYLES.NEGRITO}>poderá ocorrer a incidência de juros</Text>.</Text>;
    return (
        <View style={STYLES.CONTAINER_INFO}>
            <View style={STYLES.TEXTO_CONTAINER_INFO}>
                <Text>{mensagemInfo}</Text>
            </View>
        </View>
    );
}