import { FaEllipsisH, FaEllipsisV } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Container } from "./style";

interface DetalhesProps {
  path: string;
  state?: any;
}

export function RedirectDetalhes({ path, state }: DetalhesProps) {
  return (
    <Container>
      <Link state={state} to={path}>
        <FaEllipsisH />
      </Link>
    </Container>
  );
}

export function RedirectDetalhesVertical({ path, state }: DetalhesProps) {
  return (
    <Container>
      <Link state={state} to={path}>
        <FaEllipsisV style={{ fontSize: "16px" }} />
      </Link>
    </Container>
  );
}
