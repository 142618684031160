import { Container, Titulo } from "./style";

interface Props {
  titulo: string;
}

export function SectionTitulo({ titulo }: Readonly<Props>) {
  return (
    <Container>
      <Titulo>{titulo}</Titulo>
    </Container>
  );
}

