import { Col, Row } from "react-bootstrap";
import { Stronger } from "components/Stronger";
import { StringUtils } from "_utils/String.utils";
import { ItemInspecaoContainer } from "./style";
import { TextoClaro } from "components/TextoClaro";

export interface IInspecaoVeicular {
  dataInspecao: string;
  link: string;
  garantia: string;
  portaMalas: string;
  frente: string;
  frenteDireita: string;
  frenteEsquerda: string;
  portaFrenteDireita: string;
  portaFrenteEsquerda: string;
  portaTraseiraDireita: string;
  portaTraseiraEsquerda: string;
  pneuFrenteDireita: string;
  pneuFrenteEsquerda: string;
  pneuTraseiraDireita: string;
  pneuTraseiraEsquerda: string;
  bancosDianteiros: string;
  bancosTraseiros: string;
  teto: string;
  observacoes: string;
}

interface Props {
  inspecaoVeicular: IInspecaoVeicular;
}

export function BodyInspecaoVeicular({ inspecaoVeicular }: Readonly<Props>) {
  return (
    <>
      <Row>
        <Col lg="3" md="3" sm="3">
          <span>Data</span> <br />
          <Stronger>
            {inspecaoVeicular?.dataInspecao
              ? inspecaoVeicular?.dataInspecao
              : "-"}
          </Stronger>
        </Col>
        <Col lg="3" md="3" sm="3">
          <span>Link</span> <br />
          <Stronger>
            {inspecaoVeicular?.link
              ? StringUtils.primeiraLetraMaiuscula(
                  inspecaoVeicular?.link?.split("_").join(" ")
                )
              : "-"}
          </Stronger>
        </Col>
        <Col lg="3" md="3" sm="3">
          <span>Garantia</span> <br />
          <Stronger>
            {inspecaoVeicular?.garantia
              ? StringUtils.primeiraLetraMaiuscula(
                  inspecaoVeicular?.garantia?.split("_").join(" ")
                )
              : "-"}
          </Stronger>
        </Col>
      </Row>
      <Row>
        <TextoClaro style={{ padding: 0, marginBottom: "1rem" }}>
          Checklist do veículo
        </TextoClaro>
        <Col lg="6" md="6" sm="6">
          <ItemInspecaoContainer>
            <span>
              Porta malas:{" "}
              {inspecaoVeicular?.portaMalas
                ? inspecaoVeicular?.portaMalas
                : "-"}
            </span>
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            <span>
              Frente:{" "}
              {inspecaoVeicular?.frente ? inspecaoVeicular?.frente : "-"}
            </span>
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Frente direita:{" "}
            {inspecaoVeicular?.frenteDireita
              ? inspecaoVeicular?.frenteDireita
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Frente esquerda:{" "}
            {inspecaoVeicular?.frenteEsquerda
              ? inspecaoVeicular?.frenteEsquerda
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Porta frente direita:{" "}
            {inspecaoVeicular?.portaFrenteDireita
              ? inspecaoVeicular?.portaFrenteDireita
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Porta frente esquerda:{" "}
            {inspecaoVeicular?.portaFrenteEsquerda
              ? inspecaoVeicular?.portaFrenteEsquerda
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Porta traseira direita:{" "}
            {inspecaoVeicular?.portaTraseiraDireita
              ? inspecaoVeicular?.portaTraseiraDireita
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Porta traseira esquerda:{" "}
            {inspecaoVeicular?.portaTraseiraEsquerda
              ? inspecaoVeicular?.portaTraseiraEsquerda
              : "-"}
          </ItemInspecaoContainer>
        </Col>
        <Col lg="6" md="6" sm="6">
          <ItemInspecaoContainer>
            Pneu frente direita:{" "}
            {inspecaoVeicular?.pneuFrenteDireita
              ? inspecaoVeicular?.pneuFrenteDireita
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Pneu frente esquerda:{" "}
            {inspecaoVeicular?.pneuFrenteEsquerda
              ? inspecaoVeicular?.pneuFrenteEsquerda
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Pneu traseira direita:{" "}
            {inspecaoVeicular?.pneuTraseiraDireita
              ? inspecaoVeicular?.pneuTraseiraDireita
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Pneu traseira esquerda:{" "}
            {inspecaoVeicular?.pneuTraseiraEsquerda
              ? inspecaoVeicular?.pneuTraseiraEsquerda
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Bancos dianteiros:{" "}
            {inspecaoVeicular?.bancosDianteiros
              ? inspecaoVeicular?.bancosDianteiros
              : "-"}
          </ItemInspecaoContainer>
          <ItemInspecaoContainer>
            Bancos traseiros:{" "}
            {inspecaoVeicular?.bancosTraseiros
              ? inspecaoVeicular?.bancosTraseiros
              : "-"}
          </ItemInspecaoContainer>
          <div>
            Teto: {inspecaoVeicular?.teto ? inspecaoVeicular?.teto : "-"}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ItemInspecaoContainer>
            <span>Observações</span> <br />
            <Stronger>
              {inspecaoVeicular?.observacoes
                ? inspecaoVeicular?.observacoes
                : "-"}
            </Stronger>
          </ItemInspecaoContainer>
        </Col>
      </Row>
    </>
  );
}
