import { toastErro } from "components/Toast";
import { StatusSolicitacaoPagamentoEnum, StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { IDetalhesConsulta } from "features/debito/consulta/interfaces/IDetalhesConsulta";
import { useCallback, useEffect, useState } from "react";
import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { IFiltro } from "../Containers/Filtros/Interface/IFiltro";
import { useRequest } from "hooks/useRequest";
import { HttpStatusCode } from "axios";
import { Data } from "_utils/Data";

export function useDetalhes() {
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState<IDetalhesConsulta[]>([]);
  const [resumo, setResumo] = useState();
  const [tiposDebitos, setTipoDebitos] = useState([]);
  const [filtro, setFiltro] = useState<IFiltro>();
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);
  const [totalRows, setTotalRows] = useState<number>(0);
  const { patch, get } = useRequest("consulta");
  const [idCliente, setIdCliente] = useState<number>(0);

  function handleStatusDebitos(item: any) {
    if (
      Number(item.statusDebito) === StatusSolicitacaoPagamentoEnumV2["Débito disponível"]
    ) {
      item.selectable = true;
      return;
    }
    item.selectable = false;
  }

  useEffect(() => {
    if (error) {
      if (error.hasErro) return toastErro(`${error.data.message}`);
    }
  }, [error]);

  useEffect(() => {
    listarTiposDebitos();
  }, []);

  const reconsultarDebitos = useCallback(async (idConsulta: any) => {
    return patch(`/consultas/${idConsulta}/reconsultar`, {}, {}, false);
  }, [patch])

  const buscarResumoConsultaV2 = useCallback(async (id: any) => {
    try {
      const listaParams = [
        filtro?.placa ? `placa=${filtro.placa}` : null,
        filtro?.idTipoDebito ? `tipoDebito=${filtro.idTipoDebito}` : null,
        filtro?.vencimento ? `vencimento=${filtro.vencimento}` : null,
        filtro?.statusPagamento || filtro?.statusPagamento === 0
          ? `status=${filtro.statusPagamento}`
          : null,
        filtro?.renavam ? `renavam=${filtro.renavam}` : null,
        filtro?.finalPlaca ? `finalPlaca=${filtro.finalPlaca}` : null,
        filtro?.cotaIpva ? `cotaIpva=${filtro.cotaIpva}` : null,
        filtro?.dataInicio && filtro?.dataFim ? `dataInicio=${new Data(filtro.dataInicio).getDataEua()}&dataFim=${new Data(filtro.dataFim).getDataEua()}` : null,
        filtro?.idRecibo ? `idRecibo=${filtro.idRecibo}` : null,
      ];

      const param = listaParams.filter((item) => item !== null).join("&");
      const { status, data, hasErro } = await get(`/consultas/${id}/resumoV2?${param}`);

      if (hasErro) {
        return
      }
      setResumo(data)
      setIdCliente(data.idEmpresa);
    } catch (error: IHttpError<IData | any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoading(false);
    }
  }, [filtro]);

  const buscarDetalhes = useCallback(
    async (idConsulta: any) => {
      const listaParams = [
        filtro?.placa ? `placa=${filtro.placa}` : null,
        filtro?.idTipoDebito ? `tipoDebito=${filtro.idTipoDebito}` : null,
        filtro?.vencimento ? `vencimento=${filtro.vencimento}` : null,
        filtro?.statusPagamento || filtro?.statusPagamento === 0
          ? `status=${filtro.statusPagamento}`
          : null,
        filtro?.renavam ? `renavam=${filtro.renavam}` : null,
        filtro?.finalPlaca ? `finalPlaca=${filtro.finalPlaca}` : null,
        filtro?.cotaIpva ? `cotaIpva=${filtro.cotaIpva}` : null,
        filtro?.dataInicio && filtro?.dataFim ? `dataInicio=${new Data(filtro.dataInicio).getDataEua()}&dataFim=${new Data(filtro.dataFim).getDataEua()}` : null,
        filtro?.idRecibo ? `idRecibo=${filtro.idRecibo}` : null,
      ];

      const param = listaParams.filter((item) => item !== null).join("&");

      setLoading(true);
      const { hasErro, data } = await get(
        `consultas/${idConsulta}/detalhes?${param}&limit=${limit}&page=${page}&order=DESC`,
        {},
        false,
      );

      if (hasErro) {
        setDados([]);
        setLoading(false);
        return;
      }

      data.items.forEach(handleStatusDebitos);

      setDados(data.items);
      setTotalRows(data.meta.totalItems);
      setLoading(false);
    },
    [filtro, limit, page, get]
  );

  const buscarValorTodosDebitos = useCallback(
    async (idConsulta: any) => {
      const listaParams = [
        filtro?.placa ? `placa=${filtro.placa}` : null,
        filtro?.idTipoDebito ? `tipoDebito=${filtro.idTipoDebito}` : null,
        filtro?.vencimento ? `vencimento=${filtro.vencimento}` : null,
        filtro?.statusPagamento || filtro?.statusPagamento === 0
          ? `status=${filtro.statusPagamento}`
          : null,
        filtro?.renavam ? `renavam=${filtro.renavam}` : null,
        filtro?.finalPlaca ? `finalPlaca=${filtro.finalPlaca}` : null,
        filtro?.cotaIpva ? `cotaIpva=${filtro.cotaIpva}` : null,
        filtro?.dataInicio && filtro?.dataFim ? `dataInicio=${new Data(filtro.dataInicio).getDataEua()}&dataFim=${new Data(filtro.dataFim).getDataEua()}` : null
      ];

      const param = listaParams.filter((item) => item !== null).join("&");

      setLoading(true);
      try {
        const { status, data } = await get(
          `consultas/${idConsulta}/detalhes?${param}&paginate=0`,
          {},
          false,
        );

        const debitos = data
          .map((item: any) => {
            if (
              Number(item.statusDebito) ===
              StatusSolicitacaoPagamentoEnum["Pendente"]
            ) {
              return item;
            }
            return null;
          })
          .filter((item: any) => item !== null);

        const response = new HttpResponse(false, status, debitos);
        setError(response);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);
        if (response.status !== HttpStatusCode.NotFound) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [filtro, get]
  );

  async function listarTiposDebitos() {
    try {
      const { status, data } = await api.get("/debito/tipos");
      setTipoDebitos(data);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (response.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    }
  }

  async function buscarTodosDebitosConsulta(idConsulta: string) {
    setLoading(true);
    try {
      const listaParams = [
        filtro?.placa ? `placa=${filtro.placa}` : null,
        filtro?.idTipoDebito ? `tipoDebito=${filtro.idTipoDebito}` : null,
        filtro?.vencimento ? `vencimento=${filtro.vencimento}` : null,
        filtro?.statusPagamento || filtro?.statusPagamento === 0
          ? `status=${filtro.statusPagamento}`
          : null,
        filtro?.renavam ? `renavam=${filtro.renavam}` : null,
        filtro?.finalPlaca ? `finalPlaca=${filtro.finalPlaca}` : null,
        filtro?.cotaIpva ? `cotaIpva=${filtro.cotaIpva}` : null,
        filtro?.dataInicio && filtro?.dataFim ? `dataInicio=${new Data(filtro.dataInicio).getDataEua()}&dataFim=${new Data(filtro.dataFim).getDataEua()}` : null
      ];
      const param = listaParams.filter((item) => item !== null).join("&");

      const { status, data } = await get(
        `consultas/${idConsulta}/debitos/disponiveis?${param}`,
        {},
        false,
      );
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (response.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoading(false);
    }
  }

  return {
    dados,
    tiposDebitos,
    loading,
    filtro,
    setFiltro,
    buscarDetalhes,
    setLimit,
    setLoading,
    buscarValorTodosDebitos,
    limit,
    setPage,
    page,
    totalRows,
    buscarTodosDebitosConsulta,
    reconsultarDebitos,
    buscarResumoConsultaV2,
    resumo,
    idCliente,
  };
}

