import { IHttpResponse } from "_services/api/interface/HttpResponse";
import { ModalCustom } from "components/ModalCustom";
import { FiCheck, FiX } from "react-icons/fi";
import { IconeContainer, MensagemContainer, ModalBody } from "./style";

interface Props {
  handleClose: () => void;
  dados: IHttpResponse | null;
}

export function ModalReconsultarDebitos({ handleClose, dados }: Readonly<Props>) {
  return (
    <ModalCustom handleClose={handleClose} show={dados?.data} title="Consultar novamente" centered>
      <ModalBody>
        {dados?.hasErro ? (
          <MensagemContainer>
            <IconeContainer
              erro={dados?.hasErro}
            >
              <FiX />
            </IconeContainer>
            <strong>{dados?.data.message}</strong>
          </MensagemContainer>
        ) : (
          <MensagemContainer>
            <IconeContainer
              erro={dados?.hasErro!}
            >
              <FiCheck />
            </IconeContainer>
            <span>Solicitação realizada com sucesso</span>
            <strong>{dados?.data.message}</strong>
          </MensagemContainer>
        )}
      </ModalBody>
    </ModalCustom>
  );
}

