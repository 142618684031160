import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { PrimaryButton } from "components/Button/style";
import { Container } from "components/Container";
import { LoadingScreen } from "components/LoadingScreen";
import { toastErro } from "components/Toast";
import { TooltipBootstrap } from "components/TooltipBootstrap";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { useCallback } from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import { FaCreditCard, FaDollarSign } from "react-icons/fa";
import { utils, writeFileXLSX } from "xlsx";
import { StatusDebitoPagamento } from "../../Enum/StatusDebitoPagamento";
import { usePagamento } from "../../hook/usePagamento";
import { IDetalhesPagamentos } from "../../interfaces/IDetalhesPagamentos";
import { ITablePagamentos } from "../../interfaces/ITablePagamentos";
import { IconeInfo } from "../IconeInfo";
import { MensagemPagamentos } from "../MensagemPagamentos";
import { ModalPagamento } from "../ModalPagamento";
import { ModalRecusarPagamento } from "../ModalRecusarPagamento";
import { Status } from "../Status";
import { ButtonHeader, ContainerTable } from "./style";

export function TablePagamentos({
  dados,
  idSolicitacao,
  setRowExpandToggled,
  rowToExpand,
  show,
  handleClose,
  todos,
  showPagarTodos,
  showPagarSelecionados,
  realizarPagamento,
  setSelectedRows,
  selectedRows,
  recusarPagamentos,
  handleShowModalRecusarPagamento,
  handleCloseModalRecusarPagamento,
  showRecusar,
  showFeedback,
  getMotivoRecusa,
  loading,
  valor,
  total,
  desconto,
  acrecimos,
  todosDebitos,
  onChangePage,
  onChangeRowsPerPage,
  handleBuscarDebitos,
  loadingBuscarTodos,
  clearSelectedRows,
  totalRows,
  rowsPerPage,
  setShowPagamentoManual,
  disableManual,
}: ITablePagamentos) {
  const { buscarDadosPlanilha, loadingDadosPlanilha } = usePagamento();
  const quantidadeCaracteresTextoLongo = 70;
  const widthStrategy = { md: 200, lg: 480 };
  const widthObservacao = `${Math.max(
    ...dados.map((item) =>
      item.mensagemErro && item.mensagemErro.length > quantidadeCaracteresTextoLongo
        ? widthStrategy.lg
        : widthStrategy.md
    )
  )}px`;

  const columns: IColum<IDetalhesPagamentos>[] = [
    {
      name: <span>Id</span>,
      selector: ({ id }) => id,
      omit: true,
    },
    {
      name: "DebitoPagamentoId",
      selector: ({ debitoPagamentoId }) => debitoPagamentoId,
      omit: true,
    },
    {
      name: <span>Placa</span>,
      selector: ({ placa }) => placa.toUpperCase(),
      sortable: true,
      sortField: "placa",
      width: "90px",
    },
    {
      name: <span>Renavam</span>,
      selector: ({ renavam }) => renavam,
      width: "100px",
    },
    {
      name: <span>Chassi</span>,
      selector: ({ chassi }) => chassi,
      width: "150px",
    },
    {
      name: <span>UF</span>,
      selector: ({ uf }) => uf.toUpperCase(),
      sortable: true,
      sortField: "uf",
    },
    {
      name: <span>Débito</span>,
      selector: ({ idTipoDebito }) => TipoDebitos[idTipoDebito] || "-",
      sortable: true,
      sortField: "idTipoDebito",
      width: "115px",
    },
    {
      name: <span>Vencimento</span>,
      selector: ({ vencimento }) => (vencimento ? formataDataISOParaBR(vencimento) : "- -"),
      sortable: true,
      sortField: "vencimento",
      width: "110px",
    },
    {
      name: <span>Valor</span>,
      selector: ({ valor }) => formatarDinheiro(valor / 100),
      width: "110px",
    },
    {
      name: <span>Juros</span>,
      selector: ({ juros }) => formatarDinheiro(juros / 100),
      width: "110px",
    },
    {
      name: <span>Valor Total</span>,
      selector: ({ valor, valorUltimaConsulta }) => {
        const valorAtualizado = valorUltimaConsulta
          ? formatarDinheiro(valorUltimaConsulta / 100)
          : formatarDinheiro(valor / 100);
        return valorAtualizado;
      },
      width: "110px",
    },
    {
      name: <span>Prazo para Pagamento</span>,
      selector: ({ dataPrazoPagamento }) => formataDataISOParaBR(dataPrazoPagamento),
      width: "110px",
    },
    {
      name: <span>Observação</span>,
      selector: ({ mensagemErro, status }) =>
        mensagemErro && Number(status) !== StatusDebitoPagamento.Pago ? mensagemErro : "",
      width: widthObservacao,
    },
    {
      name: <span>Info</span>,
      selector: (linha) => <IconeInfo status={linha.status} onClick={() => setRowExpandToggled(linha)} />,
      width: "60px",
    },
    {
      name: <span>Status Pagamento</span>,
      selector: ({ status }) => <Status status={status} />,
      width: "120px",
    },
  ];

  const ExpandedComponent: React.FC<ExpanderComponentProps<IDetalhesPagamentos>> = ({ data }) => {
    return (
      <MensagemPagamentos
        status={StatusDebitoPagamento[Number(data.status)]}
        erro={data.mensagemErro}
        valorConsulta={data.valor}
        valorDisponivel={data.valorUltimaConsulta}
        juros={data.juros}
      />
    );
  };

  const handleExport = useCallback(async () => {
    const { data, hasErro } = await buscarDadosPlanilha(idSolicitacao);
    
    if (hasErro) {
      toastErro(data.message);
      return;
    }

    const dadosPlanilha = data.map((item: any) => ({
      Placa: item.placa.toUpperCase(),
      Renavam: item.renavam,
      Chassi: item.chassi,
      UF: item.uf?.toUpperCase(),
      Debito: TipoDebitos[Number(item.idTipoDebito)],
      Vencimento: formataDataISOParaBR(item.vencimento.toString()),
      "Valor Total": formatarDinheiro(item.valor / 100),
      "Prazo Pagamento": formataDataISOParaBR(item.dataPrazoPagamento?.toString()),
      Juros: formatarDinheiro(item.juros / 100),
      Observação: item.mensagemErro ?? "",
      Status: StatusDebitoPagamento[item.status],
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 8 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Detalhes");
    writeFileXLSX(workBook, "detalhes_solicitacoes.xlsx");
  }, [buscarDadosPlanilha, idSolicitacao]);

  return (
    <Container titulo={`Detalhes Solicitação de Pagamento - ID ${idSolicitacao}`}>
      <ContainerTable>
        <Table
          columns={columns}
          data={dados}
          keyField="id"
          loading={loading}
          setRowsSelected={(row) => setSelectedRows(row)}
          selectableRows={true}
          clearSelectedRows={clearSelectedRows}
          rowToExpand={rowToExpand}
          expandableRowsComponent={ExpandedComponent}
          exportExcel={true}
          handleExport={handleExport}
          buttonExportVariant="secondary"
          toolsHeader={
            <ButtonHeader>
              <PrimaryButton
                outline={true}
                variante="primary"
                onClick={handleShowModalRecusarPagamento}
                buttonWidth="lg"
              >
                Recusar solicitação <FontAwesomeIcon icon={faCircleXmark} />
              </PrimaryButton>

              <TooltipBootstrap
                show={!!disableManual}
                placement="bottom"
                childrenToolTip={
                  <>
                    Selecione {selectedRows.length === 0 ? "" : "apenas"} um débito para
                    <br />
                    informar o pagamento manual
                  </>
                }
              >
                <PrimaryButton
                  onClick={() => setShowPagamentoManual(true)}
                  disabled={disableManual}
                  variante="primary"
                  outline
                  buttonWidth="lg"
                >
                  Pagamento manual {<FaCreditCard />}
                </PrimaryButton>
              </TooltipBootstrap>

              <PrimaryButton onClick={todos ? showPagarTodos : showPagarSelecionados}>
                Pagar {<FaDollarSign />}
              </PrimaryButton>
            </ButtonHeader>
          }
          pagination={true}
          paginationRowsPerPageOptions={[100, 200, 300]}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
        />
      </ContainerTable>
      <ModalPagamento
        show={show}
        loading={loadingBuscarTodos}
        handleClose={handleClose}
        todos={todos}
        realizarPagamento={realizarPagamento}
        todosDebitos={todosDebitos}
        selectedRows={selectedRows}
        valor={valor}
        total={total}
        desconto={desconto}
        acrecimos={acrecimos}
        handleBuscarDebitos={handleBuscarDebitos}
      />
      <ModalRecusarPagamento
        show={showRecusar}
        selectedRows={selectedRows}
        handleClose={handleCloseModalRecusarPagamento}
        todos={todos}
        recusarPagamentos={recusarPagamentos}
        showFeedback={showFeedback}
        getMotivoRecusa={getMotivoRecusa}
        idSolicitacao={idSolicitacao}
        handleBuscarDebitos={handleBuscarDebitos}
        loadingBuscarTodos={loadingBuscarTodos}
      />
      <LoadingScreen mostrar={loadingDadosPlanilha} />
    </Container>
  );
}
