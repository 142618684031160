import { converterDataHoraBr, formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { LoadingScreen } from "components/LoadingScreen";
import { toastErro } from "components/Toast";
import { IRelatorioPagamentos } from "features/relatorios/pagamento/interfaces/RelatorioPagamento";
import { validaStatusPagamento } from "features/relatorios/pagamento/utils/validaStatusPagamento";
import { useCallback } from "react";
import { utils, writeFileXLSX } from "xlsx";
import { StatusPagamentoEnum } from "../StatusPagamento";
import { TableRelatorioPagamentos } from "./TableRelatorioPagamentos";
import { Container, Divider, Title } from "./style";
import { StatusAdiantamentoConst } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";

interface IListaPagamentosProps {
  dados: IRelatorioPagamentos[];
  loading: boolean;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (limit: number) => void;
  totalRows: number;
  handleDownload: (idSolicitacaoPagamento: number) => void;
  loadingDownload: boolean;
  downloadIniciado: boolean;
  buscarDadosPlanilhaRelatorio(): any;
  loadingDadosPlanilha: boolean;
  listarSolicitacoes: CallbackWithArgs<Promise<void>>;
  buscarDados: () => void;
}

export function ListaPagamentos({
  dados,
  loading,
  onChangePage,
  onChangeRowsPerPage,
  totalRows,
  handleDownload,
  loadingDownload,
  downloadIniciado,
  buscarDadosPlanilhaRelatorio,
  loadingDadosPlanilha,
  listarSolicitacoes,
  buscarDados,
}: IListaPagamentosProps) {
  const handleExport = useCallback(async () => {
    const { data, hasErro } = await buscarDadosPlanilhaRelatorio();

    if (hasErro) {
      toastErro(data.message);
      return;
    }

    const dadosPlanilha = data.map((item: IRelatorioPagamentos) => ({
      "ID Consulta": item.idConsulta,
      "ID Pagamento": item.id,
      "ID Recibo": item.idsReciboFinanceiro,
      "Data/Hora Solicitação": converterDataHoraBr(item.dataHora),
      Empresa: item.empresa,
      Usuario: item.nomeUsuario,
      "Qtd Debitos": item.qtdSolicitado,
      "Valor da Solicitação": item.valorTotal / 100,
      "Pagamentos Recusados": (item.valorTotalRecusado ?? 0) / 100,
      "valor para Pagamento": (item.valorTotal - (item.valorTotalRecusado ?? 0)) / 100,
      "Valor Pago": item.valorTotalPago / 100,
      "Prazo para Pagamento": formataDataISOParaBR(
        item.dataMenorPrazoPagamento
      ),
      "Status Pagamento":
        StatusPagamentoEnum[
        validaStatusPagamento({
          qtdFinalizado: item.qtdFinalizado,
          qtdSolicitado: item.qtdSolicitado,
          qtdSemComprovante: item.qtdSemComprovante
        })
        ],
      "Adiantamento": StatusAdiantamentoConst[item.statusAdiantamento],
      "Pagamentos Realizados": item.qtdFinalizado,
      "Pagamentos Não Realizados": item.qtdSolicitado - item.qtdFinalizado,
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 10 },
      { wch: 14 },
      { wch: 10 },
      { wch: 20 },
      { wch: 40 },
      { wch: 25 },
      { wch: 12 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 23 },
      { wch: 23 },
      { wch: 25 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Relatório Pagamentos");
    writeFileXLSX(workBook, "relatórios-pagamentos.xlsx");
  }, [buscarDadosPlanilhaRelatorio]);

  return (
    <Container>
      <Title>Relatório de Pagamentos</Title>
      <Divider />
      <TableRelatorioPagamentos
        handleExport={handleExport}
        dados={dados}
        loading={loading}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        totalRows={totalRows}
        handleDownload={handleDownload}
        loadingDownload={loadingDownload}
        buscarDados={buscarDados}
      />
      <LoadingScreen mostrar={loadingDadosPlanilha} />
    </Container>
  );
}
