import { STYLES } from "../../style";
import { formatarDinheiro } from "_utils/Money";
import { converterDataBr } from "_utils/Data";
import { Text, View } from "@react-pdf/renderer";
import { useMask } from "hooks/useMask";
import { IDadosClienteReciboAdiantamento } from "features/debito/consulta/pages/Detalhes/Containers/ListarDetalhes/interface/IDadosClienteReciboAdiantamento";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";

interface Props {
    formaPgto: FormaPgtoEnum | undefined;
    valorTotal: number;
    idReciboAdiantamento: number;
    dadosClienteReciboAdiantamento: IDadosClienteReciboAdiantamento;
}
export function DadosReciboPDF({
    formaPgto,
    idReciboAdiantamento,
    valorTotal,
    dadosClienteReciboAdiantamento,
}: Props) {
    const { mCEP } = useMask();
    return (
        <View style={STYLES.CONTAINER}>
            <View style={STYLES.CONTAINER_TITLE}>
                <Text>Dados do recibo</Text>
            </View>
            <View style={STYLES.CONTAINER_BODY}>
                <View style={STYLES.SECTION_ROW}>
                    <View style={STYLES.SECTION_COL_3}>
                        <Text style={[STYLES.ITEM_TITLE, STYLES.TEXTO_QUASE_CLARO]}>Recibo</Text>
                        <Text style={STYLES.ITEM_BODY}>{idReciboAdiantamento}</Text>
                    </View>
                    <View style={STYLES.SECTION_COL_3}>
                        <Text style={[STYLES.ITEM_TITLE, STYLES.TEXTO_QUASE_CLARO]}>Data de emissão</Text>
                        <Text style={STYLES.ITEM_BODY}>{converterDataBr(new Date())}</Text>
                    </View>
                    <View style={STYLES.SECTION_COL_3}>
                        <Text style={[STYLES.ITEM_TITLE, STYLES.TEXTO_QUASE_CLARO]}>Valor</Text>
                        <Text style={STYLES.ITEM_BODY}>{formatarDinheiro(valorTotal / 100)}</Text>
                    </View>
                </View>
                <View style={STYLES.CONTAINER_DADOS_COBRANCA}>
                    <View style={STYLES.CONTAINER_DADOS_COBRANCA_TITLE}>
                        <Text style={[STYLES.TEXTO_QUASE_CLARO]}>Dados para cobrança</Text>
                    </View>
                    <View style={STYLES.CONTAINER_DADOS_COBRANCA_BODY}>
                        <View style={STYLES.SECTION_ROW}>
                            <View style={[STYLES.SECTION_COL_1, { gap: 8 }]}>
                                <View style={STYLES.DADOS_COBRANCA_ITEM_TITLE}>
                                    <Text>{dadosClienteReciboAdiantamento.razaoSocial}</Text>
                                </View>
                                <View style={STYLES.DADOS_COBRANCA_ITEM_BODY}>
                                    <Text>{dadosClienteReciboAdiantamento.cnpj}</Text>
                                </View>
                                <View style={STYLES.DADOS_COBRANCA_ITEM_BODY}>
                                    <Text>{`${dadosClienteReciboAdiantamento.endereco}, ${dadosClienteReciboAdiantamento.numero} - ${dadosClienteReciboAdiantamento.bairro} - ${dadosClienteReciboAdiantamento.cidade} - ${dadosClienteReciboAdiantamento.estado} - ${mCEP(dadosClienteReciboAdiantamento.cep)}`}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
}