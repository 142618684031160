import styled from "styled-components";

interface VerTodosProps {
    show: boolean;
}

interface BodyResumoProps {
    naoQuebrarLinha?: boolean;
    width?: string;
}

export const ContainerBackground = styled.div`
    border-radius:10px;  
    margin: 2rem 0;
    width: 100%;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.neutral.neutral00};
`;

export const TituloDadoResumo = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BodyDadoResumo = styled.div<BodyResumoProps>`
    font-weight: bold;
    ${(props) => props.naoQuebrarLinha ? "white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" : ""}
    ${(props) => props.width ? `width: ${props.width}` : ""}
`;

export const LinkVerTodos = styled.span<VerTodosProps>`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.main};
    ${(props) => !props.show ? "display: none;" : ""}
`;