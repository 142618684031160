import { CompositeContainer } from "components/CompositeContainer";
import { FaSearch } from "react-icons/fa";
import { FiltrosContainer } from "../FiltrosContainer";
import { useEffect, useState } from "react";
import { ModalSolicitarConsultaExtratoVeicular } from "../ModalSolicitarConsultaExtratoVeicular";
import { useExtratoVeicular } from "features/extrato-veicular/hooks/useExtratoVeicular";
import { useEmpresas } from "hooks/useEmpresas";
import { ModalConfirmacaoConsulta } from "../ModalConfirmacaoConsulta";
import { FiltrosUnitarios } from "../FiltrosUnitarios";

interface Props {
  handleSubmit: (dados: any) => void;
  visualizacaoAtual: "LOTES" | "DETALHADOS";
}

export function FiltrosExtratoVeicular({ handleSubmit, visualizacaoAtual }: Readonly<Props>) {
  const { buscarCombosConsulta, combosConsulta } = useExtratoVeicular();
  const { agrupamentoOptions, criarOptionsAgrupamento } = useEmpresas();
  const [showModalSolicitar, setShowModalSolicitar] = useState(false);
  const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);
  const [idConsulta, setIdConsulta] = useState<number | null>(null);

  useEffect(() => {
    buscarCombosConsulta();
  }, [buscarCombosConsulta]);

  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo text="Filtros" />
        <CompositeContainer.ActionButton
          text="Consultar veiculo"
          iconeBotao={FaSearch}
          onClick={() => setShowModalSolicitar(true)}
        />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        {visualizacaoAtual === "LOTES" ? (
          <FiltrosContainer
            agrupamentoOptions={agrupamentoOptions}
            combosConsulta={combosConsulta}
            handleSubmitForm={handleSubmit}
          />
        ) : (
          <FiltrosUnitarios
            agrupamentoOptions={agrupamentoOptions}
            combosConsulta={combosConsulta}
            handleSubmitForm={handleSubmit}
          />
        )}
        <ModalSolicitarConsultaExtratoVeicular
          show={showModalSolicitar}
          handleClose={() => {
            setShowModalSolicitar(false);
          }}
          agrupamentoOptions={agrupamentoOptions}
          combosConsulta={combosConsulta}
          listarConsultas={handleSubmit}
          showConfirmacao={() => setShowModalConfirmacao(true)}
          setIdConsulta={setIdConsulta}
        />
        <ModalConfirmacaoConsulta
          handleClose={() => setShowModalConfirmacao(false)}
          show={showModalConfirmacao}
          idConsulta={idConsulta!}
        />
      </CompositeContainer.Body>
    </CompositeContainer.Root>
  );
}

