import styled from "styled-components";

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .teste {
    margin-top: 1rem;
    margin-bottom: 0;
    width: 55%;
  }
`;