import { Route, Routes } from "react-router-dom";
import { DetalhesPagamento } from "../pages/Detalhes";
import { Listar } from "../pages/Listar";

function PagamentoRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Listar />} />
      <Route path="/:id" element={<DetalhesPagamento />}/>
    </Routes>
  );
}
export { PagamentoRoutes };
