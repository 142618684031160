import * as yup from "yup";
import { portugues } from "_utils/validador";
import { Cobrar } from "features/licenciamento/emissao/constants";

yup.setLocale(portugues);

export const filtrosDetalhesConsultaScheme = yup.object().shape({
  placa: yup.string().optional().nullable(),
  exercicio: yup.string().optional().nullable(),
  statusConsulta: yup.string().optional().nullable(),
  codigoRetorno: yup.string().optional().nullable(),
});

export const cobrancaSchema = yup.object().shape({
  empresa: yup.string().required("Selecione a empresa"),
  cobrar: yup
    .string()
    .required("Selecione a opção de cobrança")
    .oneOf([Cobrar.SIM, Cobrar.NAO]),
  motivoIsencao: yup.string().when("cobrar", {
    is: (cobrar: string) => cobrar === "NAO",
    then: (schema) =>
      schema.required("Informe o motivo da isenção de cobrança"),
  }),
});