import { useCallback, useEffect, useState } from "react";
import { Data } from "../../../_utils/Data";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { IDadosPorTipoDebito } from "features/debito/consulta/pages/Detalhes/Containers/ListarDetalhes/interface/IDadosPorTipoDebito";

export function useCount() {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [valor, setValor] = useState<number>(0);
  const [desconto, setDesconto] = useState<number>(0);
  const [acrecimos, setAcrecimos] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [quantidadeDebitos, setQuantidadeDebitos] = useState<number>(0);
  const [quantidadeDebitosForaDeSla, setQuantidadeDebitosForaDeSla] =
    useState<number>(0);
  const [autorizadoPagamento, setAutorizadoPagamento] =
    useState<boolean>(false);
  const [dadosPorTipoDebito, setDadosPorTipoDebito] = useState<IDadosPorTipoDebito[]>([]);

  const calcularSla = useCallback(() => {
    selectedRows.forEach(({ vencimento, dataPrazoPagamento }) => {
      const data = new Data();
      const slaSolicitacaoDebitos = -2;
      if (
        data.getDiferenca(vencimento) >= slaSolicitacaoDebitos ||
        data.getDiferenca(dataPrazoPagamento) >= slaSolicitacaoDebitos
      ) {
        setQuantidadeDebitosForaDeSla((prevState) => prevState + 1);
        setAutorizadoPagamento(false);
      }
    });
  }, [selectedRows]);

  useEffect(() => {
    setValor(0);
    setDesconto(0);
    setAcrecimos(0);
    setTotal(0);
    setQuantidadeDebitosForaDeSla(0);
    setQuantidadeDebitos(selectedRows.length);
    calcularSla();

    if (selectedRows.length > 0) {
      setValor(
        selectedRows.map((item) => item.total).reduce((item, i) => item + i)
      );
      setDesconto(
        selectedRows.map((item) => item.desconto).reduce((item, i) => item + i)
      );
      setAcrecimos(
        selectedRows.map((item) => item.acrescimo).reduce((item, i) => item + i)
      );
      setTotal(
        selectedRows.map((item) => item.total).reduce((item, i) => item + i)
      );
      setDadosPorTipoDebito([
        {
          descricao: "Pagamento IPVA",
          quantidade: selectedRows.filter((item) => Number(item.debito) === TipoDebitos.IPVA).length,
          valor: selectedRows.filter((item) => Number(item.debito) === TipoDebitos.IPVA).map((item) => item.total).reduce((item, i) => item + i, 0)
        },
        {
          descricao: "Pagamento Licenciamento",
          quantidade: selectedRows.filter((item) => Number(item.debito) === TipoDebitos.Licenciamento).length,
          valor: selectedRows.filter((item) => Number(item.debito) === TipoDebitos.Licenciamento).map((item) => item.total).reduce((item, i) => item + i, 0)
        },
        {
          descricao: "Pagamento Multa",
          quantidade: selectedRows.filter((item) => Number(item.debito) === TipoDebitos.Multa).length,
          valor: selectedRows.filter((item) => Number(item.debito) === TipoDebitos.Multa).map((item) => item.total).reduce((item, i) => item + i, 0)
        },
        {
          descricao: "Pagamento DPVAT",
          quantidade: selectedRows.filter((item) => Number(item.debito) === TipoDebitos.DPVAT).length,
          valor: selectedRows.filter((item) => Number(item.debito) === TipoDebitos.DPVAT).map((item) => item.total).reduce((item, i) => item + i, 0)
        },
      ]);
    }
  }, [selectedRows, calcularSla]);

  useEffect(() => {
    if (quantidadeDebitosForaDeSla === 0) {
      setAutorizadoPagamento(true);
    }
  }, [quantidadeDebitosForaDeSla]);

  const limparSelectedRows = () => setSelectedRows([]);

  return {
    total,
    desconto,
    valor,
    acrecimos,
    selectedRows,
    setSelectedRows,
    setTotal,
    limparSelectedRows,
    quantidadeDebitos,
    quantidadeDebitosForaDeSla,
    setQuantidadeDebitos,
    setQuantidadeDebitosForaDeSla,
    setAutorizadoPagamento,
    autorizadoPagamento,
    setDadosPorTipoDebito,
    dadosPorTipoDebito,
  };
}

