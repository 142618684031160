import { yupResolver } from "@hookform/resolvers/yup";
import { formatarDinheiro } from "_utils/Money";
import { CustomInput } from "components/CustomInput";
import { CustomDatePickerV2 } from "components/DatePickerV2";
import { ImportarArquivo } from "components/ImportarArquivo";
import { ModalCustom } from "components/ModalCustom";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { pagamentoManual } from "features/debito/pagamento/validate/validator";
import { useMaskInput } from "hooks/useMaskInput";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Arquivo, FaCheckCircleGreen, FaTrashCustom, MensagemErro } from "./style";
import { useRequest } from "hooks/useRequest";

interface SalvarPagamentoManual {
  dataPagamento: Date;
  valorPagamento: string | number;
  arquivo?: File;
}

interface Props {
  show: boolean;
  setShowPagamentoManual(show: boolean): void;
  selectedRows: any[];
  openShowConfirmarPagamentoManual(): void;
  dados: any[];
  handleClearRows(): void;
}

export function ModalPagamentoManual({
  show,
  setShowPagamentoManual,
  selectedRows,
  openShowConfirmarPagamentoManual,
  dados,
  handleClearRows,
}: Props) {
  const { mDinheiroInput } = useMaskInput();
  const { post } = useRequest("consulta");

  const [nomeArquivo, setNomeArquivo] = useState<string>();
  const [erroArquivo, setErroArquivo] = useState<string>();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<SalvarPagamentoManual>({
    resolver: yupResolver(pagamentoManual),
  });

  useEffect(() => {
    reset({
      arquivo: undefined,
      valorPagamento: ''
    });

    setNomeArquivo("");
    setErroArquivo("");
  }, [show]);

  function handleClose() {
    setShowPagamentoManual(false);
  }

  async function submit(pagamentoManual: SalvarPagamentoManual) {
    setErroArquivo("");
    if (!pagamentoManual.arquivo) {
      setErroArquivo("Comprovante é obrigatório");
      return;
    }

    if (typeof pagamentoManual.valorPagamento == "string") {
      pagamentoManual.valorPagamento = pagamentoManual.valorPagamento.replace(/\./g, "");
      pagamentoManual.valorPagamento = pagamentoManual.valorPagamento.replace(",", ".");
      pagamentoManual.valorPagamento = pagamentoManual.valorPagamento.replace("R$ ", "");
    }

    const body = new FormData();
    body.append("valorPagamento", `${pagamentoManual.valorPagamento}`);
    body.append("dataPagamento", `${pagamentoManual.dataPagamento}`);
    body.append("arquivo", pagamentoManual.arquivo);

    const { hasErro } = await post(
      `/pagamento/debito/${selectedRows[0].id}`,
      body,
      {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      },
      true
    );

    if (hasErro) {
      return;
    }

    const indexLinha = dados.findIndex((item) => item.id === selectedRows[0].id);
    dados[indexLinha].status = "6";
    dados[indexLinha].selectable = false;

    handleClearRows();
    setShowPagamentoManual(false);
    openShowConfirmarPagamentoManual();
  }

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      size="lg"
      title="Informar pagamento manual"
      centered
      footerConfirmButton
      footer
      formTarget="formPagamentoManual"
      buttonPosition="end"
      confirmButtonTitle="Confirmar pagamento"
    >
      <p>Dados de pagamento</p>
      {selectedRows[0] ? (
        <form onSubmit={handleSubmit(submit)} id="formPagamentoManual">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Placa</Form.Label>
                <Form.Control type="text" value={selectedRows[0].placa} disabled />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Débito</Form.Label>
                <Form.Control type="text" value={TipoDebitos[selectedRows[0].idTipoDebito]} disabled />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Data de vencimento</Form.Label>
                <Form.Control
                  type="text"
                  value={new Date(selectedRows[0].dataPrazoPagamento).toLocaleDateString()}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Valor</Form.Label>
                <Form.Control type="text" value={formatarDinheiro(selectedRows[0].valor / 100)} disabled />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Form.Group>
                <Form.Label>Data do pagamento</Form.Label>
                <Controller
                  control={control}
                  name="dataPagamento"
                  render={({ field: { onChange, value } }) => (
                    <CustomDatePickerV2
                      onChange={onChange}
                      selected={value}
                      mensagemErro={errors.dataPagamento?.message}
                      isValid={isSubmitted && !errors.dataPagamento}
                      isInvalid={!!errors.dataPagamento}
                      isClearable={false}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Valor do pagamento</Form.Label>
                <CustomInput
                  type="text"
                  placeholder="R$ 000,00"
                  {...register("valorPagamento")}
                  isValid={isSubmitted && !errors.valorPagamento}
                  isInvalid={!!errors.valorPagamento}
                  onChange={mDinheiroInput}
                  iconeDireito={false}
                  iconeEsquerdo={false}
                  mensagemErro={errors.valorPagamento?.message}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>Comprovante de pagamento</Form.Label>
                <ImportarArquivo
                  inputId="inputPlanilha"
                  isInvalid={!!erroArquivo}
                  handleUpload={async (a: File) => {
                    setNomeArquivo(a.name);
                    setValue("arquivo", a);
                    setErroArquivo("");
                  }}
                  tipoArquivo="imagem"
                />
              </Form.Group>
            </Col>
          </Row>
          {erroArquivo && <MensagemErro>{erroArquivo}</MensagemErro>}
          {nomeArquivo && (
            <Row className="mt-3">
              <Col>
                <Arquivo>
                  <Row>
                    <Col md={10}>
                      <strong>{nomeArquivo}</strong>
                    </Col>
                    <Col className="d-flex flex-row-reverse">
                      <div className="d-flex justify-content-around">
                        <FaTrashCustom
                          onClick={() => {
                            setNomeArquivo("");
                            setValue("arquivo", undefined);
                          }}
                        />
                        <FaCheckCircleGreen />
                      </div>
                    </Col>
                  </Row>
                </Arquivo>
              </Col>
            </Row>
          )}
        </form>
      ) : (
        ""
      )}
    </ModalCustom>
  );
}
