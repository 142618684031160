import { LoadingCirculo } from "components/LoadingCirculo";
import { ModalCustom } from "components/ModalCustom";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { formatarDinheiro } from "_utils/Money";
import { IModalPagamento } from "../../interfaces/IModalPagamento";
import { InformacaoPagamentos } from "../InformacaoPagamentos";
import {
  Container,
  Divider,
  LoadingContainer,
  Strong,
  Texto,
  TotaisContainer,
  TotalLinhas,
} from "./style";

export function ModalPagamento({
  show,
  handleClose,
  todos,
  realizarPagamento,
  valor,
  total,
  todosDebitos,
  selectedRows,
  loading,
  handleBuscarDebitos,
  acrecimos,
}: IModalPagamento) {
  const mensagemTodos = `Deseja realizar o pagamento de todos os débitos?`;
  const mensagemSelecionados = `Deseja realizar o pagamento dos débitos selecionados?`;

  useEffect(() => {
    show && todos && handleBuscarDebitos();
  }, [show, todos]);

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      size="lg"
      title="Realizar Pagamento"
      centered={true}
      footerConfirmButton={true}
      footer={!loading}
      onConfirm={realizarPagamento}
    >
      <Container>
        {loading ? (
          <LoadingContainer>
            <LoadingCirculo mostrar={loading} />
          </LoadingContainer>
        ) : (
          <>
            <Texto>{todos ? mensagemTodos : mensagemSelecionados}</Texto>
            <InformacaoPagamentos mensagem="Confira abaixo o valor total e quantidade de débitos antes de confirmar o pagamento" />
            <Divider />
            <TotaisContainer>
              <Col lg="4">
                <Strong>Total de pagamentos</Strong>
              </Col>
              <Col lg="3">
                <Strong>Valor</Strong>
              </Col>
              <Col lg="2">
                <Strong>Juros</Strong>
              </Col>
              <Col lg="3">
                <Strong>Total</Strong>
              </Col>
            </TotaisContainer>
            <Divider bold semMargem />
            <TotaisContainer>
              <Col lg="4">
                <TotalLinhas>
                  {selectedRows.length > 0
                    ? selectedRows.length
                    : todosDebitos.length}
                </TotalLinhas>
              </Col>
              <Col lg="3">
                <TotalLinhas>{formatarDinheiro(valor / 100)}</TotalLinhas>
              </Col>
              <Col lg="2">
                <TotalLinhas>{formatarDinheiro(acrecimos / 100)}</TotalLinhas>
              </Col>
              <Col lg="3">
                <TotalLinhas>{formatarDinheiro(total / 100)}</TotalLinhas>
              </Col>
            </TotaisContainer>
          </>
        )}
      </Container>
    </ModalCustom>
  );
}

