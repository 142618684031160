import { Data } from "_utils/Data";
import { CompositeContainer } from "components/CompositeContainer";
import { FormContainer, IFiltrosListarSolicitacoesLicenciamento } from "features/licenciamento/emissao/pages/Listar/containers/FiltrosListarSolictacoesLicenciamento/containers/FormContainer";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { ModalEmitirLicenciamento } from "features/licenciamento/emissao/pages/Listar/containers/ModalEmitirLicenciamento";

interface Props {
  handleSubmit: (dados: IFiltrosListarSolicitacoesLicenciamento) => void;
  pagina: "consulta" | "emissão";
}

export function FiltrosConsultarLicenciamento({ handleSubmit, pagina }: Readonly<Props>) {
  const [showModalConsultar, setShowModalConsultar] = useState<boolean>(false)
  function handleSubmitFiltros(dados: any) {
    if (dados.dataSolicitacao) {
      const data = new Data(dados.dataSolicitacao);
      dados.dataSolicitacao = data.getDataEua();
    }

    handleSubmit(dados);
  }

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo text="Fitros" />
        <CompositeContainer.ActionButton
          onClick={() => setShowModalConsultar(true)}
          iconeBotao={FaSearch}
          text="Consultar licenciamento"
        />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <FormContainer handleSubmitFiltros={handleSubmitFiltros} pagina={pagina} />
      </CompositeContainer.Body>
      <ModalEmitirLicenciamento 
        showModal={showModalConsultar}
        handleCloseModal={() => setShowModalConsultar(false)}
        listarSolicitacoesLicenciamento={async () => { 
          handleSubmitFiltros({})
        }}
        setLoadingPage={() => {}}
        pagina="consulta"
      />
    </CompositeContainer.Root>
  );
}

