import styled from "styled-components";

export const Container = styled.div`
  font-size: 14px;
  padding-top: 1rem;
  padding-left: 2.75rem;
`;

export const Title = styled.strong`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
`;