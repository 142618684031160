import { FaCheckCircle, FaTrash } from "react-icons/fa";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: grid;
  place-items: center;
  gap: 1rem;
`;

export const IconeContainer = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  background-color: ${({ theme }) => theme.colors.primary.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.primary.main};
    width: 2rem;
    height: 2rem;
  }
`;

export const TextoContainer = styled.div`
  display: grid;
  place-items: center;
  gap: 1rem;
`;

export const Texto = styled.p`
  margin: 0;
  padding: 0;
`;

export const Strong = styled.strong`
  margin: 0;
  padding: 0;
`;

export const Arquivo = styled.div`
  border: 1px solid #7f7f7f;
  border-radius: 5px;
  padding: 1rem;
  color: #7f7f7f;
`;

export const FaCheckCircleGreen = styled(FaCheckCircle)`
  color: #10ac85;
  margin-left: 1rem;
`;

export const FaTrashCustom = styled(FaTrash)`
  cursor: pointer;
`;

export const MensagemErro = styled.p`
  color: ${({ theme }) => theme.colors.danger.main};
  font-size: 0.85rem;
  margin-top: 0.5rem;
`;
