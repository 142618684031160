import { ModalCustom } from "components/ModalCustom";
import {
  BodyItems,
  BodyPlacas,
  HeaderBody,
  HeaderObservacaoWrapper,
  HeaderPlacaWrapper,
  ObservacaoDiv,
  PlacasDiv,
} from "./style";
import { Informacao } from "components/Informacao";

interface IDados {
  placa: string;
}
interface Props {
  mostrar: boolean;
  disponiveis: IDados[];
  indisponiveis: IDados[];
  handleCloseModal: () => void;
}

export function ModalDadosConsultaImagem({
  disponiveis,
  indisponiveis,
  mostrar,
  handleCloseModal,
}: Props) {
  return (
    <ModalCustom
      handleClose={handleCloseModal}
      show={mostrar}
      title="Download de arquivos"
      centered={true}
    >
      {disponiveis.length > 0 && (
        <div>
          <span>Arquivos disponíveis</span>
          <BodyPlacas>
            <HeaderBody>
              <HeaderPlacaWrapper>
                <strong>Placas</strong>
              </HeaderPlacaWrapper>
              <HeaderObservacaoWrapper>
                <strong>Observação</strong>
              </HeaderObservacaoWrapper>
            </HeaderBody>
            {disponiveis.map(({ placa }) => (
              <BodyItems key={placa}>
                <PlacasDiv>{placa}</PlacasDiv>
                <ObservacaoDiv>
                  Arquivo CRLV disponível para download
                </ObservacaoDiv>
              </BodyItems>
            ))}
          </BodyPlacas>
        </div>
      )}
      {indisponiveis.length > 0 && (
        <div>
          <Informacao
            mensagem="Arquivos não encontrados"
            type="atencao"
            showBotaoFechar={false}
            showLabel={false}
          />
          <BodyPlacas>
            <HeaderBody>
              <HeaderPlacaWrapper>
                <strong>Placas</strong>
              </HeaderPlacaWrapper>
              <HeaderObservacaoWrapper>
                <strong>Observação</strong>
              </HeaderObservacaoWrapper>
            </HeaderBody>
            {indisponiveis.map(({ placa }) => (
              <BodyItems key={placa}>
                <PlacasDiv>{placa}</PlacasDiv>
                <ObservacaoDiv>
                  Arquivo CRLV não encontrado
                </ObservacaoDiv>
              </BodyItems>
            ))}
          </BodyPlacas>
        </div>
      )}
    </ModalCustom>
  );
}

