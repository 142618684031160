import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../../../style";
import { IDetalhesMultas } from "../../../../../DadosMultas";
import { intlCurrencyFormat } from "_utils/Money";
import { converterDataBr } from "_utils/Data";

interface Props {
  multa: IDetalhesMultas;
}

export function DetalhesMultasPDF({ multa }: Readonly<Props>) {
  return (
    <View style={[STYLES.BORDER_TOP_BOTTOM, STYLES.BACKGROUND_BRANCO, STYLES.PADDING_04, { marginBottom: 6 }]}>
      <View style={STYLES.SECTION_ROW}>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN]}>
          <Text style={STYLES.FONT_SIZE_08}>Auto de infração</Text>
          <Text style={STYLES.FONT_SIZE_08}>{multa.autoInfracao}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Data/hora da infração</Text>
          <Text style={STYLES.FONT_SIZE_08}>{multa.dataInfracao}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Vencimento auto</Text>
          <Text style={STYLES.FONT_SIZE_08}>{converterDataBr(new Date(multa.dataVencimento))}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Situação</Text>
          <Text style={STYLES.FONT_SIZE_08}>{multa.situacao}</Text>
        </View>
      </View>
      <View style={STYLES.SECTION_ROW}>
        <View style={STYLES.SECTION_COL_1}>
          <Text style={STYLES.FONT_SIZE_08}>Descrição</Text>
          <Text style={STYLES.FONT_SIZE_08}>{multa.descricaoArtigo}</Text>
        </View>
      </View>
      <View style={STYLES.SECTION_ROW}>
        <View style={STYLES.SECTION_COL_2}>
          <Text style={STYLES.FONT_SIZE_08}>Local</Text>
          <Text style={STYLES.FONT_SIZE_08}>{multa.endereco}</Text>
        </View>
        <View style={STYLES.SECTION_COL_2}>
          <Text style={STYLES.FONT_SIZE_08}>Órgão competente</Text>
          <Text style={STYLES.FONT_SIZE_08}>{multa.orgao}</Text>
        </View>
      </View>
      <View style={STYLES.SECTION_ROW}>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN]}>
          <Text style={STYLES.FONT_SIZE_08}>Valor original</Text>
          <Text style={STYLES.FONT_SIZE_08}>{intlCurrencyFormat(multa.valor / 100)}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Valor desconto</Text>
          <Text style={STYLES.FONT_SIZE_08}>
            {intlCurrencyFormat((multa.valor - multa.valorComDesconto) / 100) ?? "-"}
          </Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Valor juros</Text>
          <Text style={STYLES.FONT_SIZE_08}>{intlCurrencyFormat(multa.valorJuro / 100)}</Text>
        </View>
        <View style={[STYLES.SECTION_COL_4, STYLES.SEM_MARGIN, STYLES.PADDING_04]}>
          <Text style={STYLES.FONT_SIZE_08}>Total</Text>
          <Text style={STYLES.FONT_SIZE_08}>
            {intlCurrencyFormat(
              multa.valorComDesconto ? multa.valorComDesconto / 100 : (multa.valor + multa.valorJuro) / 100
            )}
          </Text>
        </View>
      </View>
    </View>
  );
}

