import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { IDadosRestricoes } from "../..";
import { Stronger } from "components/Stronger";
import {
  ContanierComBordaInferior,
  DetalhesRestricoesContainer,
  StyledButton,
  StyledCol,
  StyledRow,
} from "./style";

interface Props {
  dados: IDadosRestricoes;
}

export function ToggleRestricoes({ dados }: Readonly<Props>) {
  const [showDetalhes, setShowDetalhes] = useState(false);

  function handleToggleDetalhes() {
    setShowDetalhes((prev) => !prev);
  }
  return (
    <>
      <ContanierComBordaInferior>
        <Row key={dados.numeroProcessoBloqueio}>
          <StyledCol lg="3" md="3" sm="3">
            <Stronger>Tipo de bloqueio</Stronger>
          </StyledCol>
          <StyledCol style={{ justifyContent: "start" }} lg="3" md="3" sm="3">
            <Stronger>Número do processo</Stronger>
          </StyledCol>
          <StyledCol style={{ justifyContent: "start" }} lg="2" md="2" sm="2">
            <Stronger>Data</Stronger>
          </StyledCol>
        </Row>
      </ContanierComBordaInferior>
      <Row onClick={handleToggleDetalhes} style={{ cursor: "pointer" }}>
        <StyledCol lg="3" md="3" sm="3">
          <StyledButton type="button">
            {showDetalhes ? (
              <FaMinusCircle color="green" />
            ) : (
              <FaPlusCircle color="green" />
            )}
          </StyledButton>
          {dados.tipoBloqueio}
        </StyledCol>
        <StyledCol style={{ justifyContent: "start" }} lg="3" md="3" sm="3">
          {dados.numeroProcessoBloqueio}
        </StyledCol>
        <StyledCol style={{ justifyContent: "start" }} lg="2" md="2" sm="2">
          {dados.dataBloqueio}
        </StyledCol>
      </Row>
      {showDetalhes && (
        <DetalhesRestricoesContainer>
          <StyledRow>
            <Col>
              <span>Tipo de bloqueio</span> <br />
              <Stronger>{dados.tipoBloqueio}</Stronger>
            </Col>
            <Col>
              <span>Número do processo</span> <br />
              <Stronger>{dados.numeroProcessoBloqueio}</Stronger>
            </Col>
            <Col>
              <span>Data</span> <br />
              <Stronger>{dados.dataBloqueio}</Stronger>
            </Col>
            <Col>
              <span>Motivo bloqueio</span> <br />
              <Stronger>{dados.motivoBloqueio}</Stronger>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <span>Órgão expedidor</span> <br />
              <Stronger>{dados.orgaoExpedidorBloqueio}</Stronger>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <span>Município expedidor</span> <br />
              <Stronger>{dados.municipioExpedidorBloqueio}</Stronger>
            </Col>
            <Col>
              <span>UF Órgão expedidor ofício</span> <br />
              <Stronger>{dados.ufOrgaoExpedidorOficioBloqueio}</Stronger>
            </Col>
          </StyledRow>
        </DetalhesRestricoesContainer>
      )}
    </>
  );
}
