import { toastErro } from "components/Toast";
import { Page } from "containers/Page";
import { useEffect } from "react";
import { useRelatorioPagamentos } from "../../hooks/useRelatorioPagamentos";
import { FiltrosRelatorioPagamentos } from "./containers/FiltrosRelatorioPagamentos";
import { ListaPagamentos } from "./containers/ListaPagamentos";
import { Totais } from "./containers/Totais";
import { ContainerBackground } from "./style";

export function RelatorioPagamentos() {
  const {
    buscarDados,
    dados,
    loadingDados,
    totais,
    loadingTotal,
    setLimit,
    setPage,
    limit,
    page,
    error,
    totalRows,
    baixarComprovanteLote,
    setFiltros,
    filtros,
    loadingComprovantes,
    downloadIniciado,
    buscarDadosPlanilhaRelatorio,
    loadingDadosPlanilha,
  } = useRelatorioPagamentos();

  useEffect(() => {
    buscarDados();
  }, [limit, page, filtros]);

  useEffect(() => {
    if (error?.hasErro && error.data.status != "404") {
      toastErro(error.data.erro);
    }
  }, [error]);

  function handlePageChange(page: number) {
    setPage(page);
  }

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }
  async function handleSubmitFiltros(data: any) {
    if (
      data.dataSolicitacao &&
      data.dataSolicitacao[0] &&
      !data.dataSolicitacao[1]
    ) {
      data.dataSolicitacao[1] = data.dataSolicitacao[0];
    }

    setFiltros(data);
  }

  return (
    <Page title="Relatório de Pagamento">
      <FiltrosRelatorioPagamentos handleSubmitFiltros={handleSubmitFiltros} />
      <ContainerBackground>
        <Totais
          aPagar={Number(totais.aPagar / 100)}
          loading={loadingTotal}
          pagos={Number(totais.valorPago / 100)}
        />
        <ListaPagamentos
          loadingDadosPlanilha={loadingDadosPlanilha}
          buscarDadosPlanilhaRelatorio={buscarDadosPlanilhaRelatorio}
          loading={loadingDados}
          dados={dados}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          totalRows={totalRows}
          handleDownload={baixarComprovanteLote}
          loadingDownload={loadingComprovantes}
          downloadIniciado={downloadIniciado}
          listarSolicitacoes={handleSubmitFiltros}
          buscarDados={buscarDados}
        />
      </ContainerBackground>
    </Page>
  );
}
