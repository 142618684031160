import { ISolicitacaoPagamento } from "../interfaces/ISolicitacaoPagamento";
import { useCallback, useState } from "react";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { HttpResponse } from "_services/api/HttpResponse";
import { HttpError } from "_services/api/HttpError";
import { IFiltrosSolicitacaoPagamento } from "../pages/Listar/containers/FiltrosSolicitacaoPagamento/interfaces/IFiltrosSolicitacaoPagamento";
import { formataData } from "_utils/Data";
import { HttpStatusCode } from "axios";
import { useRequest } from "hooks/useRequest";

export function useListar() {
  const [errorListarSolicitacoes, setErrorListarSolicitacoes] =
    useState<IHttpResponse<IData | any>>();
  const [solicitacoesPagamento, setSolicitacoesPagamento] = useState<
    ISolicitacaoPagamento[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [filtros, setFiltros] = useState<IFiltrosSolicitacaoPagamento>();
  const [totalRows, setTotalRows] = useState<number>(0);

  const { get } = useRequest("consulta")

  function handleTablePageChange(page: number) {
    setPage(page);
  }

  function handleTableLimitChange(limit: number) {
    setLimit(limit);
  }

  const listarSolicitacoesPagamento = useCallback(async () => {
    const ajuste = [
      filtros?.empresa ? `empresaId=${filtros.empresa}` : null,
      filtros?.sort ? `sort=${filtros.sort}` : null,
      filtros?.prazoPagamento
        ? `prazoPagamento=${formataData(filtros.prazoPagamento.toISOString())}`
        : null,
      filtros?.placa ? `placa=${filtros.placa}` : null,
      filtros?.renavam ? `renavam=${filtros.renavam}` : null,
      filtros?.idConsulta ? `consultaId=${filtros.idConsulta}` : null,
      filtros?.idSolicitacaoPagamento ? `idSolicitacao=${filtros.idSolicitacaoPagamento}` : null,
      filtros?.statusAdiantamento ? `statusAdiantamento=${filtros.statusAdiantamento}` : null,
    ];
    const queryParam = ajuste.filter((item) => item !== null).join("&");

    setLoading(true);
    try {
      const { status, data } = await get(
        `/pagamento/solicita/?${
          queryParam || ""
        }&page=${page}&limit=${limit}&status=pendente&order=DESC`
      );
      setSolicitacoesPagamento(data.items);
      setTotalRows(data.meta.totalItems);
      const response = new HttpResponse(false, status, data);
      setErrorListarSolicitacoes(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setErrorListarSolicitacoes(response);
        setSolicitacoesPagamento([]);
      }
      return response;
    } finally {
      setLoading(false);
    }
  }, [page, limit, filtros, get]);

  return {
    solicitacoesPagamento,
    errorListarSolicitacoes,
    listarSolicitacoesPagamento,
    loading,
    filtros,
    page,
    limit,
    handleTablePageChange,
    handleTableLimitChange,
    setFiltros,
    totalRows,
  };
}

