import { Container, ContainerDiv } from "./style";

interface Props {
  statusConsulta: number;
}

export function StatusConsulta({ statusConsulta }: Props) {
  const statusStrategy = [
    { status: "processando", texto: "Processando" },
    { status: "sucesso", texto: "Finalizada" },
    { status: "falha", texto: "Falha" },
  ]

  return (
    <Container>
      <ContainerDiv statusDebito={`${statusStrategy[statusConsulta].status}`}>{`${statusStrategy[statusConsulta].texto}`}</ContainerDiv>
    </Container>
  );
}
