import { Page } from "containers/Page";
import { ListagemExtratoVeicular } from "./containers/ListagemExtratoVeicular";
import { FiltrosExtratoVeicular } from "./containers/FiltrosExtratoVeicular";
import { useContext, useEffect, useState } from "react";
import { IConsultaExtratoVeicular } from "./interfaces/IConsultaExtratoVeicular";
import { converterDataHoraBr } from "_utils/Data";
import { RedirectDetalhes } from "components/RedirectDetalhes";
import { ToggleVisualizacaoExtratoVeicular } from "./containers/ToggleVisualizacaoExtratoVeicular";
import {
  IDadosDetalhado,
  handleStatusConsulta,
} from "../Detalhes/containers/ListarDetalhesExtratoVeicular";
import { StatusConsultaExtratoVeicular } from "../Detalhes/containers/StatusConsultaExtratoVeicular";
import { TooltipTentativas } from "../Detalhes/containers/TooltipTentativas";
import { AcoesExtratoVeicular } from "../Detalhes/containers/AcoesExtratoVeicular";
import { IColum } from "containers/Table/interfaces";
import { ListarExtratosVeicularesContext } from "./context/ListarExtratosVeicularesContext";
import { useSearchParams } from "react-router-dom";

function makeRedirect(id: number) {
  return <RedirectDetalhes path={`/veiculos/extrato/consultas/${id}`} />;
}

function gerarColunasLotes() {
  return [
    {
      selector: ({ id }: IConsultaExtratoVeicular) => id,
      name: "ID Consulta",
      width: "120px",
    },
    {
      selector: ({ tipoConsulta }: IConsultaExtratoVeicular) => tipoConsulta,
      name: "Tipo de consulta",
      width: "180px",
    },
    {
      selector: ({ dataHoraCadastro }: IConsultaExtratoVeicular) =>
        converterDataHoraBr(new Date(dataHoraCadastro)),
      name: "Data/Hora solicitação",
      width: "180px",
    },
    {
      selector: ({ nomeEmpresa }: IConsultaExtratoVeicular) => nomeEmpresa,
      name: "Solicitante",
    },
    {
      selector: ({ nomeCliente }: IConsultaExtratoVeicular) =>
        nomeCliente ?? "-",
      name: "Empresa",
    },
    {
      selector: ({ nomeUsuario }: IConsultaExtratoVeicular) => nomeUsuario,
      name: "Usuário",
    },
    {
      selector: ({ qtdPlacasSolicitadasConsulta }: IConsultaExtratoVeicular) =>
        qtdPlacasSolicitadasConsulta,
      name: "Qtd. de placas",
    },
    {
      selector: ({ id }: IConsultaExtratoVeicular) => makeRedirect(id),
      name: "Detalhes",
      width: "105px",
    },
  ];
}

function gerarColunasDetalhados() {
  return [
    {
      name: <span>Placa</span>,
      selector: ({ placa }: IDadosDetalhado) => placa?.toUpperCase() ?? "-",
      width: "80px",
    },
    {
      name: <span>Renavam</span>,
      selector: ({ renavam }: IDadosDetalhado) => renavam?.toUpperCase() ?? "-",
      width: "100px",
    },
    {
      name: <span>Chassi</span>,
      selector: ({ chassi }: IDadosDetalhado) => chassi?.toUpperCase() ?? "-",
      width: "165px",
    },
    {
      name: <span style={{ width: "100%", textAlign: "center" }}>IPVA</span>,
      selector: ({ ipvas }: IDadosDetalhado) => handleStatusConsulta(ipvas),
      width: "70px",
    },
    {
      name: <span style={{ width: "100%", textAlign: "center" }}>Lic</span>,
      selector: ({ licenciamentos }: IDadosDetalhado) =>
        handleStatusConsulta(licenciamentos),
      width: "60px",
    },
    {
      name: <span style={{ width: "100%", textAlign: "center" }}>Multa</span>,
      selector: ({ multas }: IDadosDetalhado) => handleStatusConsulta(multas),
      width: "70px",
    },
    {
      name: <span style={{ width: "100%", textAlign: "center" }}>Gravame</span>,
      selector: ({ gravames }: IDadosDetalhado) =>
        handleStatusConsulta(gravames),
      width: "85px",
    },
    {
      name: <span style={{ width: "100%", textAlign: "center" }}>Restrições</span>,
      selector: ({ restricoes }: IDadosDetalhado) =>
        handleStatusConsulta(restricoes),
      width: "100px",
    },
    {
      name: <span style={{ width: "100%", textAlign: "center" }}>Recall</span>,
      selector: ({ recalls }: IDadosDetalhado) => handleStatusConsulta(recalls),
      width: "70px",
    },
    {
      name: <span style={{ width: "100%", textAlign: "center" }}>Leilão</span>,
      selector: ({ leiloes }: IDadosDetalhado) => handleStatusConsulta(leiloes),
      width: "70px",
    },
    {
      name: <span>Data/Hora da consulta</span>,
      selector: ({
        dataHoraUltimaConsulta,
        statusExtratoVeicular,
      }: IDadosDetalhado) =>
        statusExtratoVeicular !== "Consultando"
          ? converterDataHoraBr(new Date(dataHoraUltimaConsulta))
          : "-",
    },
    {
      name: <span>Status da consulta</span>,
      selector: ({ statusExtratoVeicular }: IDadosDetalhado) => (
        <StatusConsultaExtratoVeicular statusConsulta={statusExtratoVeicular} />
      ),
      width: "170px",
    },
    {
      name: <span>Tentativas de consulta</span>,
      selector: ({
        tentativasConsulta,
        statusExtratoVeicular,
      }: IDadosDetalhado) => (
        <TooltipTentativas
          statusExtratoVeicular={statusExtratoVeicular}
          tentativas={tentativasConsulta}
        />
      ),
      width: "190px",
    },
    {
      name: <span>Ações</span>,
      selector: ({
        idExtratoVeicular,
        idLoteExtratoVeicular,
        statusExtratoVeicular,
      }: IDadosDetalhado) => (
        <AcoesExtratoVeicular
          idExtratoVeicular={idExtratoVeicular}
          statusExtratoVeicular={statusExtratoVeicular}
          idLoteExtratoVeicular={idLoteExtratoVeicular}
          resumoLote={true}
        />
      ),
      width: "90px",
    },
  ];
}

export function ConsultarVeiculos() {
  const {
    setFiltrosListarExtratosVeiculares,
    dados,
    handleLimitChange,
    handlePageChange,
    totalRegistros,
    loading,
    listarExtratosVeiculares,
    listarConsolidados,
    limit,
    setFiltrosListarDetalhadosExtratosVeiculares,
    exibir,
    setExibir,
    handleExportExtratosConsolidados,
    handleExportExtratosDetalhes,
    setExtratosVeicularesSelecionados,
    setConsultasSelecionadas,
    extratosVeicularesSelecionados,
    consultasSelecionadas,
    filtrosListarExtratosVeiculares,
    filtrosListarDetalhadosExtratosVeiculares,
    setDatasExportar,
    datasExportar,
    loadingRelatorio,
    showOpcoesExportarExcel,
    setShowOpcoesExportarExcel,
  } = useContext(ListarExtratosVeicularesContext);

  const [colunas, setColunas] = useState<IColum<any>[]>([]);

  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (exibir === "DETALHADOS") {
      listarConsolidados();
      setColunas(gerarColunasDetalhados());
      setFiltrosListarExtratosVeiculares(null);
      return;
    }
    setFiltrosListarDetalhadosExtratosVeiculares(null);
    listarExtratosVeiculares();
    setColunas(gerarColunasLotes());
  }, [
    exibir,
    listarConsolidados,
    listarExtratosVeiculares,
    setFiltrosListarExtratosVeiculares,
    setFiltrosListarDetalhadosExtratosVeiculares,
  ]);

  useEffect(() => {
    if (params.get("recarregar") === "SIM") {
      setParams(undefined);
      if (exibir === "DETALHADOS") {
        listarConsolidados();
        setColunas(gerarColunasDetalhados());
        setFiltrosListarExtratosVeiculares(null);
        return;
      }
      setFiltrosListarDetalhadosExtratosVeiculares(null);
      listarExtratosVeiculares();
      setColunas(gerarColunasLotes());
    }
  }, [
    exibir,
    listarConsolidados,
    listarExtratosVeiculares,
    setFiltrosListarExtratosVeiculares,
    setFiltrosListarDetalhadosExtratosVeiculares,
    params,
    setParams,
  ]);

  function toggleExibir() {
    if (exibir === "DETALHADOS") {
      setExibir("LOTES");
      return;
    }

    setExibir("DETALHADOS");
  }

  return (
    <Page title="Extrato veicular">
      <FiltrosExtratoVeicular
        visualizacaoAtual={exibir}
        handleSubmit={
          exibir === "DETALHADOS"
            ? setFiltrosListarDetalhadosExtratosVeiculares
            : setFiltrosListarExtratosVeiculares
        }
      />
      <ListagemExtratoVeicular
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        totalRows={totalRegistros}
        dados={dados}
        loading={loading}
        loadingRelatorio={loadingRelatorio}
        handleToggleTabela={toggleExibir}
        rowsPerPage={limit}
        colunas={colunas}
        toggleComponent={
          <ToggleVisualizacaoExtratoVeicular visualizacaoAtual={exibir} />
        }
        visualizacaoAtual={exibir}
        handleExportExtratosConsolidados={handleExportExtratosConsolidados}
        handleExportExtratosDetalhes={handleExportExtratosDetalhes}
        setExtratosVeicularesSelecionados={setExtratosVeicularesSelecionados}
        setConsultasSelecionadas={setConsultasSelecionadas}
        extratosVeicularesSelecionados={extratosVeicularesSelecionados}
        consultasSelecionadas={consultasSelecionadas}
        datas={filtrosListarDetalhadosExtratosVeiculares && filtrosListarDetalhadosExtratosVeiculares.dataInicial && filtrosListarDetalhadosExtratosVeiculares.dataFinal
          ? [filtrosListarDetalhadosExtratosVeiculares.dataInicial, filtrosListarDetalhadosExtratosVeiculares.dataFinal]
          : filtrosListarExtratosVeiculares && filtrosListarExtratosVeiculares.dataInicial && filtrosListarExtratosVeiculares.dataFinal
            ? [filtrosListarExtratosVeiculares.dataInicial, filtrosListarExtratosVeiculares.dataFinal]
            : null
        }
        setDatasExportar={setDatasExportar}
        datasExportar={datasExportar}
        showOpcoesExportarExcel={showOpcoesExportarExcel}
        setShowOpcoesExportarExcel={setShowOpcoesExportarExcel}
      />
    </Page>
  );
}
