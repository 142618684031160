import { Informacao } from "components/Informacao";
import { ModalCustom } from "components/ModalCustom";
import { ModalBody } from "./styles";

interface Props {
  handleClose: () => void;
  show: boolean;
  onConfirm: () => void;
}

export function ModalInformacaoExportar({ handleClose, onConfirm, show }: Readonly<Props>) {
  return (
    <ModalCustom
      title="Exportar"
      handleClose={handleClose}
      show={show}
      centered
      footer
      footerConfirmButton
      onConfirm={onConfirm}
    >
      <ModalBody>
        <span>
          A seleção do checkbox contempla apenas as placas listadas na página atual, para exportar as placas
          de todas as páginas, clique no botão <strong>Exportar</strong> sem selecionar nenhum checkbox.
        </span>
        <Informacao
          mensagem="Deseja exportar apenas as placas selecionadas?"
          showBotaoFechar={false}
          showLabel={false}
          type="atencao"
          className="informacao"
        />
      </ModalBody>
    </ModalCustom>
  );
}
