import styled from "styled-components";

export const Container = styled.div`
  margin-top: 2rem;
  width: 100%;
  padding-top: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  .rdt_TableCol {
    padding: 0 4px;
    margin: 0 6px;
    
    div {
      display: flex;
      align-items: center;
      justify-content: start;
    }
  }

  .rdt_TableCell {
    padding: 0 4px;
    margin: 0 6px;
    justify-content: start;
    
    div {
      display: flex;
      align-items: center;

      span {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        scrollbar-width: none;
      }

      &:hover {
        span {
          text-overflow: clip;
          white-space: normal;
        }
      } 
    }
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
`;

export const Divider = styled.hr`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;