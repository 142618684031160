import { intlCurrencyFormat } from "_utils/Money";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { Stronger } from "components/Stronger";

interface Props {
  licenciamentos: any[];
}

export function DadosLicenciamento({ licenciamentos }: Readonly<Props>) {
  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={licenciamentos.length ? IconePendencia : IconeNadaConsta} />
        <Section.Titulo titulo="Licenciamento" />
      </Section.Header>
      <Section.Body>
        <Section.Row>
          <Section.Col>
            <span>Licenciamento</span> <br />
            <Stronger>
              {licenciamentos.length ? "Existe débito de licenciamento" : "Não existe débito de licenciamento"}
            </Stronger>
          </Section.Col>
          <Section.Col>
            <span>Valor total licenciamentos</span> <br />
            <Stronger>
              {licenciamentos.length
                ? intlCurrencyFormat(licenciamentos.map((item) => item.valor).reduce((prev, acc) => acc + prev) / 100)
                : "-"}
            </Stronger>
          </Section.Col>
        </Section.Row>
        {licenciamentos.length > 0 && (
          <>
            <Section.Row>
              <Section.Col style={{ margin: 0 }}>
                <span style={{ color: "#8a8a8a" }}>Descrição</span>
              </Section.Col>
              <Section.Col style={{ margin: 0 }}>
                <span style={{ color: "#8a8a8a" }}>Valor</span>
              </Section.Col>
            </Section.Row>
            {licenciamentos.map((licenciamento) => (
              <Section.Row
                style={{
                  backgroundColor: "#E6E6E6",
                  borderRadius: "0.75rem",
                  marginTop: "0.5rem",
                  padding: "0.5rem 0.25rem",
                }}
                key={licenciamento.descricao}
              >
                <Section.Col style={{ margin: 0 }}>{licenciamento.observacao}</Section.Col>
                <Section.Col style={{ margin: 0 }}>{intlCurrencyFormat(licenciamento.valor / 100)}</Section.Col>
              </Section.Row>
            ))}
          </>
        )}
      </Section.Body>
    </Section.Root>
  );
}

