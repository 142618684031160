import { FaList, FaListAlt } from "react-icons/fa";
import { ToggleContainer } from "./style";

interface Props {
  visualizacaoAtual: "DETALHADOS" | "UNITARIOS";
}

export function ToggleVisualizacaoDetalhesExtratoVeicular({ visualizacaoAtual }: Readonly<Props>) {
  return (
    <ToggleContainer>
      {visualizacaoAtual === "DETALHADOS" ? (
        <div>
          <FaList /> Visualização unitária
        </div>
      ) : (
        <div>
          <FaListAlt /> Visualização detalhada
        </div>
      )}
    </ToggleContainer>
  );
}

