import styled from "styled-components";

export const Container = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;

  input {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    accent-color: ${({ theme }) => theme.colors.project.main};
    margin-right: 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }

  label {
    &:hover {
      cursor: pointer;
    }
  }
`;
