import { toastErro } from "components/Toast";
import { useEffect, useState } from "react";
import api from "_services/api";
import { IDashboardHookProps } from "../interfaces/IDashboardHookProps.interface";

export function useChartPagamentosRealizados({
  empresaSelecionada,
  dataInicial,
  dataFinal,
}: IDashboardHookProps) {
  const estadoInicialGrafico = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const [data, setData] = useState<number[]>(estadoInicialGrafico);

  useEffect(() => {
    buscarDados();
  }, [empresaSelecionada, dataInicial, dataFinal]);

  const state = {
    options: {
      chart: {
        id: "pagamentosRealizados",
        fontFamily: "Roboto",
      },
      xaxis: {
        categories: [
          "JAN",
          "FEV",
          "MAR",
          "ABR",
          "MAI",
          "JUN",
          "JUL",
          "AGO",
          "SET",
          "OUT",
          "NOV",
          "DEZ",
        ],
      },
      title: {
        text: "Overview - Pagamentos realizados",
      },
      colors: ["#91CC75"],
      yaxis: {
        labels: {
          formatter: (val: number) => val.toLocaleString("pt-br"),
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    series: [
      {
        name: "PGTOS REALIZADOS",
        data: data,
      },
    ],
  };

  async function buscarDados(): Promise<void> {
    try {
      const empresaQuery =
        empresaSelecionada && empresaSelecionada != null
          ? `&idEmpresa=${empresaSelecionada}`
          : "";

      const dataInicialQuery =
        dataInicial && dataInicial != null
          ? `&dataInicial=${dataInicial}`
          : "";

      if (!dataFinal) {
        return;
      }

      const { data: response } = await api.get(
        `/debito/dashboard/pagamentos?${dataInicialQuery}&dataFinal=${dataFinal}${empresaQuery}`
      );

      const newData = [...data];

      response.map((m: any) => {
        newData[m.mes - 1] = m.total;
      });

      setData(newData);
    } catch (e: any) {
      if (e.message.includes("404")) {
        return setData(estadoInicialGrafico);
      }

      toastErro("Erro ao buscar os dados do dashboard de pagamentos");
    }
  }

  return { state };
}
