import { Informacao } from "components/Informacao";

export function InformacaoAdiantamento() {
    const mensagemInfo = <span>O pagamento dos débitos será realizado <strong>após a identificação do adiantamento</strong>. Caso a identificação ocorra fora do prazo de SLA de 05 (cinco) dias antes do vencimento dos débitos, <strong>poderá ocorrer a incidência de juros</strong>.</span>;
    return (
        <Informacao
            mensagem={mensagemInfo}
            showLabel={false}
            showBotaoFechar={false}
            type="atencao"
            style={{ boxShadow: "none" }}
        />
    );
}