import { useState } from "react";

export function useLinha() {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const limparSelectedRows = () => setSelectedRows([]);

  return {
    selectedRows,
    setSelectedRows,
    limparSelectedRows,
  };
}
