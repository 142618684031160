import { PropsWithChildren } from "react";
import { Container } from "./style";
import { DropdownMenuContextProvider } from "../context/DropdownMenu.context";

interface Props extends PropsWithChildren {}

export function DropdownRoot({ children }: Readonly<Props>) {
	return (
		<DropdownMenuContextProvider>
			<Container>{children}</Container>
		</DropdownMenuContextProvider>
	);
}
