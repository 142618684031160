import styled from "styled-components";
import { handleColor } from "./_utils/handleColor";

export const DropdownContainer = styled.div`
	min-width: 200px;
`;

export const DropdownItemButton = styled.button`
	background-color: transparent;
	border: none;
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
	padding: 0.5rem 0.25rem;

	svg {
		width: 0.875rem !important;
		height: 0.875rem !important;
	}

	&:hover:not([disabled]) {
		background-color: ${({ theme }) => theme.colors.neutral.neutral00};
	}

	&:disabled {
		svg {
			color: ${({ theme }) => theme.colors.neutral.neutral30};
		}

		cursor: not-allowed;
	}
`;

interface DropdownItemContainerProps {
	statusAdiantamento: string;
}

export const DropdownItemContainer = styled.div<DropdownItemContainerProps>`
	display: flex;
	gap: 0.25rem;
	color: ${({ statusAdiantamento }) => handleColor(statusAdiantamento)};
`;
