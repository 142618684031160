import { Col, Row } from "react-bootstrap";
import { ContainerBackground, Title } from "./style";
import React from "react";

interface Props {
  children: any;
  titulo: string;
  headersComponets?: React.ReactNode;
}

export function Container({ children, titulo, headersComponets = null }: Props) {
  return (
    <ContainerBackground>
      <Row>
        <Col>
          <Title>{titulo}</Title>
        </Col>
        {headersComponets && <Col className="d-flex flex-row-reverse">{headersComponets}</Col>}
      </Row>
      <hr />
      {children}
    </ContainerBackground>
  );
}
