import { intlCurrencyFormat } from "_utils/Money";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { Stronger } from "components/Stronger";

interface Props {
  ipvas: any[];
}

export function DadosIpvaNacional({ ipvas }: Readonly<Props>) {
  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={ipvas.length ? IconePendencia : IconeNadaConsta} />
        <Section.Titulo titulo="IPVA" />
      </Section.Header>
      <Section.Body>
        <Section.Row>
          <Section.Col>
            <span>Valor total IPVA</span> <br />
            <Stronger>
              {ipvas.length
                ? intlCurrencyFormat(ipvas.map((item) => item.valor / 100).reduce((prev, acc) => acc + prev))
                : "-"}
            </Stronger>
          </Section.Col>
        </Section.Row>
      </Section.Body>
    </Section.Root>
  );
}

