import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError, IHttpResponse } from "_services/api/interface/HttpResponse";
import { usePaginationSortSearch } from "containers/Table/hooks/usePaginationSortSearch";
import { useRequest } from "hooks/useRequest";
import { useCallback, useState } from "react";
import { IConsultaSolicitada } from "../interfaces/IConsultaSolicitada";
import { HttpStatusCode } from "axios";
import { IFiltro } from "../pages/Listar/containers/Filtros/Interface/IFiltro";
import { Data } from "_utils/Data";

export function useListar() {
  const msConsulta = useRequest("consulta");
  const { totalRows, setTotalRows } = usePaginationSortSearch();

  const [loading, setLoading] = useState(false);
  const [consultasSolicitada, setConsultasSolicitada] = useState<IConsultaSolicitada[]>([]);
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [filtro, setFiltro] = useState<IFiltro | null>(null);


  const listarConsultasSolicitada = useCallback(
    async (filtros: IFiltro | null = null) => {
      let filtro = "";
      if (filtros) {
        let { cotaIpva, dataFim, dataInicio, id, placa, tipoDebito, renavam, chassi, idEmpresa, cobranca, statusConsulta } = filtros;
        let ipva = null;
        let licenciamento = null;

        if (tipoDebito && tipoDebito.length > 0) {
          ipva = "1";
          licenciamento = "1";

          if (!tipoDebito.includes("IPVA")) {
            ipva = "0";
          }

          if (!tipoDebito.includes("LICENCIAMENTO")) {
            licenciamento = "0";
          }
        }

        filtro = [
          dataInicio ? `dataInicio=${new Data(dataInicio).getDataEua()}` : null,
          dataFim ? `dataFim=${new Data(dataFim).getDataEua()}` : null,
          id ? `id=${id}` : null,
          cotaIpva ? `cotaIpva=${cotaIpva}` : null,
          placa ? `placa=${placa}` : null,
          renavam ? `renavam=${renavam}` : null,
          chassi ? `chassi=${chassi}` : null,
          idEmpresa ? `empresaId=${idEmpresa}` : null,
          cobranca ? `cobranca=${cobranca}` : null,
          ipva ? `ipva=${ipva}` : null,
          licenciamento ? `licenciamento=${licenciamento}` : null,
          statusConsulta ? `status=${statusConsulta}` : null,
        ]
          .filter((item) => item)
          .join("&");
      }

      setLoading(true);
      try {
        const { status, data } = await msConsulta.get(`/consultas/?page=${page}&limit=${limit}&order=DESC&${filtro}`);
        setConsultasSolicitada(data.items);
        setTotalRows(data.meta.totalItems);
        const response = new HttpResponse(false, status, data);
        setError(response);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);
        if (response.status !== HttpStatusCode.NotFound) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [page, limit, setTotalRows,filtro]
  );

  return {
    error,
    loading,
    consultasSolicitada,
    setPage,
    setLimit,
    listarConsultasSolicitada,
    totalRows,
    page,
    limit,
    filtro, 
    setFiltro,
  };
}
