import axios, { InternalAxiosRequestConfig, AxiosHeaders, HttpStatusCode } from "axios";
import { getToken } from "../auth";

const msUsuarioLw = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_USUARIO_LW,
});

msUsuarioLw.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    } as unknown as AxiosHeaders;
    config.headers = headers;
  }

  return config;
});

export default msUsuarioLw;
