import styled from "styled-components";

interface PropsContainer {
  statusDebito: string;
}

export const Container = styled.button<PropsContainer>`
  border-radius: 15px;
  width: 160px;
  height: 24px;
  text-align: center;

  ${({ statusDebito, theme }) =>
    statusDebito === "Débito disponível" &&
    `
    background-color: ${theme.colors.tags.info02.bgColor};
    border: solid 1px ${theme.colors.tags.info02.textColor};
    color: ${theme.colors.tags.info02.textColor};
  `}

  ${({ statusDebito, theme }) =>
    statusDebito === "Pagamento solicitado" &&
    `
    background-color: ${theme.colors.tags.info.bgColor};
    border: solid 1px ${theme.colors.tags.info.textColor};
    color: ${theme.colors.tags.info.textColor};
  `}

${({ statusDebito, theme }) =>
    statusDebito === "Consultando" &&
    `
    background-color: ${theme.colors.tags.disabled.bgColor};
    border: solid 1px ${theme.colors.tags.disabled.textColor};
    color: ${theme.colors.tags.disabled.textColor};
  `}  

${({ statusDebito, theme }) =>
    statusDebito === "Débito indisponível" &&
    `
    background-color: ${theme.colors.tags.warning.bgColor};
    border: solid 1px ${theme.colors.tags.warning.textColor};
    color: ${theme.colors.tags.warning.textColor}; 
    disabled: true;
  `}

  ${({ statusDebito, theme }) =>
    statusDebito === "Falha" &&
    `
      background-color: ${theme.colors.tags.danger.bgColor};
      border: solid 1px ${theme.colors.tags.danger.textColor};
      color: ${theme.colors.tags.danger.textColor}; 
      disabled: true;
  `}

  ${({ statusDebito, theme }) =>
    statusDebito === "Nada consta" &&
    `
      background-color: ${theme.colors.tags.success.bgColor};
      border: solid 1px ${theme.colors.tags.success.textColor};
      color: ${theme.colors.tags.success.textColor}; 
      disabled: true;
  `}

  ${({ statusDebito, theme }) =>
    statusDebito === "Dados inconsistentes" &&
    `
      background-color: ${theme.colors.tags.warning02.bgColor};
      border: solid 1px ${theme.colors.tags.warning02.textColor};
      color: ${theme.colors.tags.warning02.textColor}; 
      disabled: true;
  `}

  &:hover {
    cursor: default;
  }
`;

