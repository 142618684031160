import { useCallback, useEffect, useState } from "react";
import { useRequest } from "./useRequest";
import { IOptions } from "components/DropdownSelect";

export function useRenomearArquivos() {
  const [optionsRenomear, setOptionsRenomear] = useState<IOptions[]>([]);
  const { get } = useRequest("consulta");

  const buscarPadroesRenomearArquivos = useCallback(async () => {
    const { data, hasErro } = await get("/arquivos/padroes", {}, false);

    if (hasErro) {
      setOptionsRenomear([{ label: "{placa}-{ano}-CRLV", value: "{placa}-{ano}-CRLV" }]);
      return;
    }

    montarOptionsRenomearArquivos(data);
  }, [get]);

  useEffect(() => {
    buscarPadroesRenomearArquivos();
  }, [buscarPadroesRenomearArquivos]);

  function montarOptionsRenomearArquivos(dados: any[]) {
    const options: IOptions[] = dados.map((item) => ({ label: item.padrao, value: item.padrao }));
    options.unshift({ label: "{placa}-{ano}-CRLV", value: "{placa}-{ano}-CRLV" })
    setOptionsRenomear(options);
  }

  return {
    optionsRenomear,
  };
}

