import { Col } from "react-bootstrap";
import styled from "styled-components";

export const ButtonContainer = styled(Col)`
  display: flex;
  height: 100px;
  align-items: center;
  gap: 1rem;

  button {
    margin-bottom: 0.5rem;
  }
`;

export const DatePickerContainer = styled.div`
  display: grid;
  gap: 1rem;

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    padding: 6px 0;
    border-radius: 10px;
  }
  .react-datepicker__day--today {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--in-selecting-range{
    background-color: ${({ theme }) => theme.colors.project.bgColor};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  button.react-datepicker__close-icon::after {
    background-color: ${({ theme }) => theme.colors.project.main};
  }
`;

export const InputContainer = styled(Col)`
  display: grid;
  height: 100px;

  @media (max-width: 1000px) {
    max-width: ${({ lg }) => lg === '3' ? '23.4%' : '14.3%'};
  }

  @media (min-width: 1250px) {
    max-width: ${({ lg }) => lg === '3' ? '22%' : '15%'};;
  }

  div {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 0.5rem;
  }

  label {
    font-size: 14px;
    margin: 0;
  }

  input {
    height: 2.2rem;
    width: 100% !important;
  }
`;
