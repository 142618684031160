import { ModalCustom } from "components/ModalCustom";
import { Container, IconContainer, SpanText } from "../ModalAlterarParaReembolso/style";
import { FaExclamation } from "react-icons/fa";
import { useSolicitacaoPagamentos } from "features/debito/pagamento/hooks/useSolicitacaoPagamentos";
import { useCallback, useState } from "react";
import { StatusAdiantamentoEnum } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";
import { ModalConfirmacaoRecebimento } from "../ModalConfirmacaoRecebimento";

interface Props {
	show: boolean;
	handleClose: Callback;
	listarSolicitacoesPagamento: Callback;
	id: number;
}

export function ModalConfirmarRecebimentoParcial({
	handleClose,
	show,
	listarSolicitacoesPagamento,
	id,
}: Readonly<Props>) {
	const { atualizarSolicitacaoPagamento } = useSolicitacaoPagamentos();
	const [showConfirmacao, setShowConfirmacao] = useState(false);

	const handleAtualizarStatusAdiantamento = useCallback(
		async ({ id }: { id: number }) => {
			const { hasErro } = await atualizarSolicitacaoPagamento({
				id,
				dados: {
					statusAdiantamento: StatusAdiantamentoEnum.PAGAMENTO_INCOMPLETO,
				},
			});

			if (hasErro) {
				return;
			}
			handleClose();
			setShowConfirmacao(true);
		},
		[atualizarSolicitacaoPagamento, handleClose],
	);

	return (
		<>
			<ModalCustom
				footer
				footerConfirmButton
				onConfirm={() => handleAtualizarStatusAdiantamento({ id })}
				centered
				show={show}
				handleClose={handleClose}
				title="Informar recebimento parcial"
			>
				<Container>
					<IconContainer>
						<FaExclamation />
					</IconContainer>
					<SpanText>
						Deseja confirmar o <strong>recebimento parcial do valor</strong> do adiantamento selecionado?
					</SpanText>
				</Container>
			</ModalCustom>
			<ModalConfirmacaoRecebimento
				text="Recebimento parcial informado com sucesso"
				title="Informar recebimento parcial"
				show={showConfirmacao}
				handleClose={() => {
					setShowConfirmacao(false);
					listarSolicitacoesPagamento();
				}}
			/>
		</>
	);
}
