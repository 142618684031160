import { CompositeContainer } from "components/CompositeContainer";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { ReactNode, useEffect, useState } from "react";
import { IConsultaExtratoVeicular } from "../../interfaces/IConsultaExtratoVeicular";
import { OpcoesExportarExcel } from "../OpcoesExportarExcel";
import { ExportExcel } from "components/ExportExcel";
import { FaDownload } from "react-icons/fa";
import { ModalInformacaoExportar } from "../ModalInformacaoExportar";
import { ModalPeriodoExportar } from "../ModalPeriodoExportar";

interface Props {
  dados: IConsultaExtratoVeicular[];
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rows: number) => void;
  totalRows: number;
  loading: boolean;
  handleToggleTabela: () => void;
  rowsPerPage: number;
  colunas: IColum<any>[];
  toggleComponent: ReactNode;
  visualizacaoAtual: "LOTES" | "DETALHADOS";
  handleExportExtratosConsolidados: () => {};
  handleExportExtratosDetalhes: () => {};
  setExtratosVeicularesSelecionados: (ids: number[]) => void;
  setConsultasSelecionadas: (ids: number[]) => void;
  extratosVeicularesSelecionados: number[];
  consultasSelecionadas: number[];
  datas: Date[] | null;
  setDatasExportar: (datas: (Date | null)[]) => void;
  datasExportar: (Date | null)[];
  loadingRelatorio: boolean;
  showOpcoesExportarExcel: boolean;
  setShowOpcoesExportarExcel: (dado: boolean) => void;
}

const intervalorDiasDentroLimite = (startDate: Date, endDate: Date) => {
  const limiteDias = 90;
  const diffTempoMs = Math.abs(endDate.getTime() - startDate.getTime());
  const converterMsParaDias = 1000 * 60 * 60 * 24;
  const diffDias = Math.ceil(diffTempoMs / converterMsParaDias);
  if (diffDias <= limiteDias) {
    return true;
  }
  return false;
}

export function ListagemExtratoVeicular({
  dados,
  onChangePage,
  onChangeRowsPerPage,
  totalRows,
  loading,
  handleToggleTabela,
  rowsPerPage,
  colunas,
  toggleComponent,
  visualizacaoAtual,
  handleExportExtratosConsolidados,
  handleExportExtratosDetalhes,
  setExtratosVeicularesSelecionados,
  setConsultasSelecionadas,
  extratosVeicularesSelecionados,
  consultasSelecionadas,
  datas,
  setDatasExportar,
  datasExportar,
  loadingRelatorio,
  showOpcoesExportarExcel,
  setShowOpcoesExportarExcel,
}: Readonly<Props>) {
  const [showModalInfoExportar, setShowModalInfoExportar] = useState<boolean>(false);
  const [tipoExport, setTipoExport] = useState<"LISTAGEM" | "DETALHES">("DETALHES");
  const [showModalPeriodoExportar, setShowModalPeriodoExportar] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean[]>([false, false, false, false]);
  const [showTextoRegra, setShowTextoRegra] = useState<boolean>(false);
  const handleExport = () => {
    setShowOpcoesExportarExcel(!showOpcoesExportarExcel);
  }
  const handleOnConfirmExportarListagem = () => {
    setShowModalInfoExportar(false);
    handleExportExtratosConsolidados();
  }
  const handleOnConfirmExportarDetalhes = () => {
    setShowModalInfoExportar(false);
    handleExportExtratosDetalhes();
  }
  const handleOnClickExportarListagem = () => {
    if (!extratosVeicularesSelecionados.length) {
      handleExportExtratosConsolidados();
      return;
    }
    setShowModalInfoExportar(true);
    setTipoExport("LISTAGEM");
  }
  const handleOnClickExportarDetalhes = () => {
    if (!consultasSelecionadas.length && !extratosVeicularesSelecionados.length) {
      setShowModalPeriodoExportar(true);
      if (datas && datas[0] && datas[1]) {
        setDatasExportar([datas[0], datas[1]])
        if (!intervalorDiasDentroLimite(datas[0], datas[1])) {
          setShowTextoRegra(true);
        }
      }
      return;
    }
    setShowModalInfoExportar(true);
    setTipoExport("DETALHES");
  }
  const onConfirmPeriodoExportar = () => {
    handleExportExtratosDetalhes();
    setDatasExportar([null, null]);
    setShowModalPeriodoExportar(false);
    setShowTextoRegra(false);
    setPressed([false, false, false, false]);
  }
  const [clearSelectedRows, setClearSelectedRows] = useState<boolean>(false);
  const getLinhas = (linhas: any[]) => {
    if (visualizacaoAtual === "DETALHADOS") {
      setExtratosVeicularesSelecionados(linhas.map(linha => linha.idExtratoVeicular))
      return;
    }
    setConsultasSelecionadas(linhas.map(linha => linha.id));
  };

  useEffect(() => {
    setClearSelectedRows(true);
    setConsultasSelecionadas([]);
    setExtratosVeicularesSelecionados([]);
  }, [visualizacaoAtual, setClearSelectedRows, setConsultasSelecionadas, setExtratosVeicularesSelecionados]);

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo text="Listagem de Consultas Realizadas" />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <Table
          columns={colunas}
          data={dados}
          keyField="id"
          loading={loading}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          totalRows={totalRows}
          clearSelectedRows={clearSelectedRows}
          setRowsSelected={(row) => getLinhas(row)}
          selectableRows={true}
          exportExcel
          exportComponent={
            <div>
              <ExportExcel
                texto={"Exportar"}
                variant={"secondary"}
                onClick={handleExport}
                icone={FaDownload}
                onClickToggleVisualizacao={handleToggleTabela}
                showToggleVisualizacao
                toggleComponent={toggleComponent}
              />
              <OpcoesExportarExcel
                aberto={showOpcoesExportarExcel}
                onClickListagem={handleOnClickExportarListagem}
                onClickDetalhes={handleOnClickExportarDetalhes}
                showOpcaoListagem={visualizacaoAtual === "DETALHADOS"}
                loading={loadingRelatorio}
              />
            </div>
          }
          buttonExportVariant="secondary"
          showToggleVisualizacao
          onClickToggleVisualizacao={handleToggleTabela}
          pagination
          rowsPerPage={rowsPerPage}
          paginationRowsPerPageOptions={
            visualizacaoAtual === "LOTES" ? [10, 20, 30, 50] : [100, 200, 300]
          }
          toggleComponent={toggleComponent}
        />
      </CompositeContainer.Body>
      <ModalInformacaoExportar
        show={showModalInfoExportar}
        onConfirm={tipoExport === "DETALHES" ? handleOnConfirmExportarDetalhes : handleOnConfirmExportarListagem}
        handleClose={() => { setShowModalInfoExportar(false) }}
      />
      <ModalPeriodoExportar
        onConfirm={onConfirmPeriodoExportar}
        handleClose={() => {
          setShowModalPeriodoExportar(false)
          setDatasExportar([null, null])
          setShowTextoRegra(false)
          setPressed([false, false, false, false])
        }}
        show={showModalPeriodoExportar}
        setDatasExportar={setDatasExportar}
        datasExportar={datasExportar}
        setPressed={setPressed}
        pressed={pressed}
        setShowTextoRegra={setShowTextoRegra}
        showTextoRegra={showTextoRegra}
        intervalorDiasDentroLimite={intervalorDiasDentroLimite}
      />
    </CompositeContainer.Root>
  );
}
