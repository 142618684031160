import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { IconContainer, SpanText, Container } from "./style";

interface Props {
	show: boolean;
	handleClose: Callback;
	text: string;
	title: string;
}

export function ModalConfirmacaoRecebimento({ handleClose, show, text, title }: Readonly<Props>) {
	return (
		<ModalCustom centered show={show} handleClose={handleClose} title={title}>
			<Container>
				<IconContainer>
					<FaCheck />
				</IconContainer>
				<SpanText>{text}</SpanText>
			</Container>
		</ModalCustom>
	);
}
