import { Col } from "react-bootstrap";
import styled from "styled-components";

export const ResumoContainer = styled(Col)`
  border-radius:10px;  
  margin: 1rem;
  width: 100%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.neutral.neutral00};
`;

export const TituloDadoResumo = styled.div`
`;

export const BodyDadoResumo = styled.div`
  font-weight: bold;
`;

