import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1rem;
  min-height: 100px;
  margin-top: 20px;

  label {
    margin-bottom: 0.25rem;
  }
`;
