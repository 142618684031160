import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { ElementType } from "react";
import { Control, ControllerProps } from "react-hook-form";

interface Props {
  controller: ElementType<ControllerProps>;
  control: Control;
  errors: any;
  options: IOptions[];
  disabled?: boolean;
}

export function SelectComboConsultas({ control, controller: Controller, errors, options, disabled = false }: Readonly<Props>) {
  return (
    <Controller
      control={control}
      name="tipoConsulta"
      render={({ field: { onChange, value } }) => (
        <DropdownSelect
          mensagemErro={errors?.tipoConsulta?.message}
          onSelect={onChange}
          selectedValue={value}
          options={options}
          required
          title="Tipo de consulta"
          disabled={disabled}
        />
      )}
    />
  );
}

