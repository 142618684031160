import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { IDadosLeiloes } from "../../../DadosLeiloes";
import { RowLeiloesPDF } from "./containers/RowLeiloesPdf";
import { RowRemarketingPDF } from "./containers/RowRemarketingPdf";
import { BodyInspecaoVeicularPdf } from "./containers/BodyInspecaoVeicularPdf";
import { StringUtils } from "_utils/String.utils";
import { BodyRatingSeguridadePdf } from "./containers/BodyRatingSeguridadePdf";
import { BodyImagensPdf } from "./containers/BodyImagensPdf";

interface Props {
  leiloes: IDadosLeiloes;
}

export function DadosLeiloesPDF({ leiloes }: Readonly<Props>) {
  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Leilão</Text>
      {leiloes?.status === "Sucesso" ? (
        <>
          <View style={STYLES.SECTION}>
            <View style={STYLES.SECTION_COL_3}>
              <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>
                Score de leilão
              </Text>
              <Text style={STYLES.FONT_SIZE_09}>{leiloes.score ?? "-"}</Text>
            </View>
            <View style={STYLES.SECTION_ROW}>
              <View style={{ width: "100%" }}>
                <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>
                  Dados do leilão
                </Text>
                {leiloes.registrosLeiloes?.length ? (
                  leiloes.registrosLeiloes.map((leilao, index) => (
                    <View key={`${index}_${leilao.comitente}`}>
                      <RowLeiloesPDF
                        dadosLeilao={leilao}
                        textoRow={`Leilão ${index + 1}`}
                      />
                    </View>
                  ))
                ) : (
                  <Text> - </Text>
                )}
              </View>
            </View>
          </View>
          <View style={STYLES.SECTION}>
            <View style={STYLES.SECTION_ROW}>
              <View style={{ width: "100%" }}>
                <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>
                  Registros de remarketing
                </Text>
                {leiloes.registrosRemarketing?.length ? (
                  leiloes.registrosRemarketing?.map((remarketing, index) => (
                    <View key={`${index}_${remarketing.vendedor}`}>
                      <RowRemarketingPDF
                        dadosRemarketing={remarketing}
                        textoRow={`Remarketing ${index + 1}`}
                      />
                    </View>
                  ))
                ) : (
                  <Text> - </Text>
                )}
              </View>
            </View>
          </View>
          <View style={STYLES.SECTION}>
            <View style={STYLES.SECTION_ROW}>
              <View style={{ width: "100%" }}>
                <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>
                  Inspeção veicular
                </Text>
                <BodyInspecaoVeicularPdf
                  inspecaoVeicular={leiloes?.inspecaoVeicular}
                />
              </View>
            </View>
            <View style={{ marginTop: "10px" }}>
              <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>
                Indicio sinistro
              </Text>
              <Text style={STYLES.FONT_SIZE_09}>
                {StringUtils.primeiraLetraMaiuscula(leiloes.indicioSinistro) ??
                  "-"}
              </Text>
            </View>
            <View style={{ marginTop: "10px" }}>
              <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>
                Rating seguridade
              </Text>
              {leiloes.ratingSeguridade.map((ratingSeguridade) => (
                <BodyRatingSeguridadePdf
                  key={ratingSeguridade.tipo}
                  ratingSeguridade={ratingSeguridade}
                />
              ))}
            </View>
          </View>
          <BodyImagensPdf imagens={leiloes.imagensLeiloes} />
        </>
      ) : (
        <View style={STYLES.SECTION}>
          <View style={STYLES.SECTION_ROW}>
            <View style={STYLES.SECTION_COL_1}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>
                Leilão
              </Text>
              <Text style={STYLES.FONT_SIZE_09}>Nada consta</Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}
