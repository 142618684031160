import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";

export function DadosLeiloesPDFNacionalSL() {
  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Leilão</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_1}>
            <Text style={STYLES.FONT_SIZE_09}>
              Este item não está incluso no tipo de consulta solicitado.
            </Text>
          </View>

        </View>
      </View>
    </View>
  );
}

