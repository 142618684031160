import { Stronger } from "components/Stronger";
import { Section } from "../Section";
import { FaDownload, FaSpinner } from "react-icons/fa";
import { ButtonContainer, RotacionarIcone } from "./style";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfExtratoVeicular } from "../PdfExtratoVeicular";
import { IDetalhesExtratoVeicular } from "../..";
import temaPadrao from "_config/temas/estilo/base";

export interface IDadosProprietario {
  nomeProprietario: string;
  cpfCnpjProprietario: string;
}

interface Props {
  detalhesExtratoVeicular: IDetalhesExtratoVeicular;
}

export function DadosProprietario({ detalhesExtratoVeicular }: Readonly<Props>) {
  return (
    <Section.Root>
      <Section.Header>
        <Section.Titulo titulo={detalhesExtratoVeicular.dadosVeiculo[0].cpfCnpjProprietario ? "Dados do proprietário" : ""} />
        <ButtonContainer>
          <PDFDownloadLink
            style={{ color: temaPadrao.colors.primary.main }}
            fileName={`Extrato Veicular - ${detalhesExtratoVeicular.dadosVeiculo[0].placa ?? "-"}`}
            document={<PdfExtratoVeicular dadosPDF={detalhesExtratoVeicular} />}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <RotacionarIcone>
                  <FaSpinner />
                </RotacionarIcone>
              ) : (
                <>
                  <FaDownload /> Exportar
                </>
              )
            }
          </PDFDownloadLink>
        </ButtonContainer>
      </Section.Header>
      {detalhesExtratoVeicular.dadosVeiculo[0].cpfCnpjProprietario && (
        <Section.Body>
          <Section.Row>
            <Section.Col>
              <span>CPF/CNPJ</span>
              <br />
              <Stronger>{detalhesExtratoVeicular.dadosVeiculo[0].cpfCnpjProprietario ?? "-"}</Stronger>
            </Section.Col>
            <Section.Col>
              <span>Nome</span>
              <br />
              <Stronger>{detalhesExtratoVeicular.dadosVeiculo[0].nomeProprietario ?? "-"}</Stronger>
            </Section.Col>
          </Section.Row>
        </Section.Body>
      )}
    </Section.Root>
  );
}

