import axios, { InternalAxiosRequestConfig, AxiosHeaders, HttpStatusCode } from "axios";
import { getToken } from "../auth";

const msConsulta = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_MS_CONSULTA,
});

msConsulta.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      const headers = {
        origin: "debitos.lwtecnologia.com.br",
        Authorization: `Bearer ${token}`,
        ...config.headers,
      } as unknown as AxiosHeaders;
      config.headers = headers;
    }

    return config;
  }
);

msConsulta.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([HttpStatusCode.Unauthorized].includes(error.response.status)) {
      window.location.href = "/?m=idNull";
      return;
    }

    throw error;
  }
);

export default msConsulta;
