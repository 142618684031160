import { formatarDinheiro } from "_utils/Money";
import { AtencaoContainer, Container, DadosBox, DadosContainer, DadosText, DadosTitle } from "./style";

interface IndeferidoPagamentosProps {
    erro: string;
    valorConsulta: number;
    valorDisponivel: number;
    diferenca: number;
}

export function IndeferidoPagamentos({
    erro,
    valorConsulta,
    valorDisponivel,
    diferenca
}: IndeferidoPagamentosProps) {
    return (
        <Container>

            <AtencaoContainer>
                <DadosBox>
                    <DadosTitle>Atenção</DadosTitle>
                    <DadosText>{erro}</DadosText>
                </DadosBox>
            </AtencaoContainer>

            <DadosContainer>
                <DadosBox>
                    <DadosTitle>
                        Valor da consulta
                    </DadosTitle>
                    <DadosText>
                        {formatarDinheiro(valorConsulta / 100)}
                    </DadosText>
                </DadosBox>
                <DadosBox>
                    <DadosTitle>
                        Valor disponível para pagamento
                    </DadosTitle>
                    <DadosText>
                        {formatarDinheiro(valorDisponivel / 100)}
                    </DadosText>
                </DadosBox>
                <DadosBox>
                    <DadosTitle>
                        Diferença
                    </DadosTitle>
                    <DadosText>
                        {formatarDinheiro(diferenca / 100)}
                    </DadosText>
                </DadosBox>
            </DadosContainer>

        </Container>
    );
}
