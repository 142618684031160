import { DownloadIconFunction, DownloadLinkFunction } from "components/DownloadLink";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { ContextPermissao } from "hooks/ContextPermissao";
import { useContext } from "react";
import {
  adicionarHora,
  converterDataBr,
  Data,
  formataDataISOParaBR,
} from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { Status } from "../Status";

interface Props {
  dados: any[];
  onChangeRowsPerPage: (limit: number) => void;
  onChangePage: (page: number) => void;
  loading: boolean;
  handleExport: () => void;
  handleDownload: (
    debitoPagamentoID: number,
    placa: string,
    idTipoDebito: number
  ) => void;
  rowsPerPage: number;
  totalRows: number;
}

export function TableDetalhesRelatorioPagamento({
  dados,
  loading,
  onChangePage,
  onChangeRowsPerPage,
  handleExport,
  handleDownload,
  rowsPerPage,
  totalRows,
}: Props) {
  const { verificarEmpresa } = useContext(ContextPermissao);
  const usuarioLW = verificarEmpresa();

  const columns: IColum<any>[] = [
    {
      name: <span>Placa</span>,
      selector: ({ placa }) => placa.toUpperCase(),
    },
    {
      name: <span>Renavam</span>,
      selector: ({ renavam }) => renavam,
    },
    {
      name: <span>Chassi</span>,
      selector: ({ chassi }) => chassi,
      width: "150px",
    },
    {
      name: <span>UF</span>,
      selector: ({ uf }) => uf.toUpperCase(),
      width: "90px",
    },
    {
      name: <span>Débito</span>,
      selector: ({ idTipoDebito }) => TipoDebitos[idTipoDebito],
      width: "150px",
    },
    {
      name: <span>Vencimento</span>,
      selector: ({ vencimento }) => vencimento ? formataDataISOParaBR(vencimento) : '- -',
    },
    {
      name: <span>Valor</span>,
      selector: ({ valor }) => formatarDinheiro(valor / 100),
    },
    {
      name: <span>Juros LW</span>,
      selector: ({ juros }) => formatarDinheiro(juros / 100),
      width: "90x",
      omit: !usuarioLW,
    },
    {
      name: <span>Valor Pago</span>,
      selector: ({ valorPago }) => {
        const valorAtualizado = valorPago ? formatarDinheiro(valorPago / 100) : formatarDinheiro(0);
        return valorAtualizado;
      },
      omit: !usuarioLW,
    },
    {
      name: <span>Status Pagamento</span>,
      selector: ({ status }) => <Status status={status} />,
      width: "120px",
    },
    {
      name: <span>Data Pagamento</span>,
      selector: ({ dataPagamento }) => dataPagamento ? converterDataBr(dataPagamento) : '- -',
    },
    {
      name: <span>ID Recibo</span>,
      selector: ({ reciboFinanceiroId }) =>
        reciboFinanceiroId ? reciboFinanceiroId : "- -",
    },
    {
      name: <span>Ações</span>,
      selector: ({
        debitoPagamentoId,
        reciboRendimentoId,
        dataComprovante,
        placa,
        idTipoDebito,
        bucketName,
        objectName,
        urlComprovante,
      }) => {
        let disableDownload: boolean = true;

        const dataHoraComprovante = adicionarHora(
          dataComprovante,
          10,
          "m",
          "YYYY-MM-DD HH:mm:ss"
        );

        const dataAtual = new Data().getDataHoraEua();

        if (reciboRendimentoId && dataHoraComprovante) {
          disableDownload = dataAtual > dataHoraComprovante ? false : true;
        }

        if (bucketName && objectName) {
          disableDownload = false;
        }

        return (
          <DownloadIconFunction
            disabled={disableDownload}
            handleDonwload={() =>
              window.open(urlComprovante)
            }
          />
        );
      },
      width: "80px"
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      loading={loading}
      keyField="id"
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      pagination
      paginationRowsPerPageOptions={[100, 200, 300]}
      exportExcel
      handleExport={handleExport}
      buttonExportVariant="secondary"
      rowsPerPage={rowsPerPage}
      totalRows={totalRows}
    />
  );
}
