import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container, IconeContainer } from "./style";

interface Props {
	show: boolean;
	handleClose: Callback;
}

export function ModalConfirmacaoAdiantamentoRealizado({ handleClose, show }: Readonly<Props>) {
	return (
		<ModalCustom handleClose={handleClose} show={show} title="Informar pagamento de adiantamento" centered>
      <Container>
        <IconeContainer>
          <FaCheck />
        </IconeContainer>
        <span>Pagamento informado com sucesso!</span>
      </Container>
		</ModalCustom>
	);
}
