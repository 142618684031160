import { formatarDinheiro } from "_utils/Money";
import { DebitoMulta } from "../DebitoMulta";
import {
  AtencaoContainer,
  Container,
  DadosLicenciamentoBox,
  DadosLicenciamentoContainer,
  DadosLicenciamentoText,
  DadosLicenciamentoTitle,
} from "./style";

interface IDebitoMultaProps {
  licenciamentos?: any[];
  temMulta?: number;
}

export function DebitoLicenciamento({
  licenciamentos,
  temMulta,
}: IDebitoMultaProps) {
  return (
    <Container>
      <DadosLicenciamentoContainer>
        {licenciamentos?.map((licenciamento) =>
          !licenciamento.autoInfracao && (
            <DadosLicenciamentoBox key={licenciamento.descricao}>
              <DadosLicenciamentoTitle>
                {licenciamento.descricao}
              </DadosLicenciamentoTitle>
              <DadosLicenciamentoText>
                {formatarDinheiro(Number(licenciamento.valor) / 100)}
              </DadosLicenciamentoText>
            </DadosLicenciamentoBox>
          )
        )}
      </DadosLicenciamentoContainer>
      {temMulta ? (
        <AtencaoContainer>
          <DadosLicenciamentoBox>
            <DadosLicenciamentoTitle>Atenção</DadosLicenciamentoTitle>
            <DadosLicenciamentoText>
              Este veículo possui multas que estão listadas abaixo e impedem o
              pagamento do licenciamento.
              <br />
              Após o pagamento das multas, faça uma
              nova consulta para solicitar este pagamento.
            </DadosLicenciamentoText>
          </DadosLicenciamentoBox>
          <DadosLicenciamentoBox>
            {licenciamentos?.map((multa) =>
              multa.autoInfracao ? (
                <DebitoMulta
                  key={multa.autoInfracao}
                  autoInfracao={multa.autoInfracao}
                  dataInfracao={multa.dataInfracao}
                  descricao={multa.descricao}
                  orgaoAutador={multa.orgaoAutuador}
                  valor={multa.valor}
                  vencimento={multa.vencimento}
                />
              ) : (
                <div key={multa.autoInfracao}></div>
              )
            )}
          </DadosLicenciamentoBox>
        </AtencaoContainer>
      ) : (
        <div></div>
      )}
    </Container>
  );
}

