import styled from "styled-components";

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  align-items: center;
  gap: 1rem;

  button {
    margin-bottom: 0.5rem;
  }
`;

export const DatePickerContainer = styled.div`
  display: grid;
  gap: 0.5rem;

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    padding: 6px 0;
    border-radius: 10px;
  }
  .react-datepicker__day--today {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--in-selecting-range{
    background-color: ${({ theme }) => theme.colors.project.bgColor};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  button.react-datepicker__close-icon::after {
    background-color: ${({ theme }) => theme.colors.project.main};
  }
`;