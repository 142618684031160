import { Text, View } from "@react-pdf/renderer";
import { IDadosRestricoes } from "../../../../../DadosRestricoes";
import { STYLES } from "../../../../style";
import { DetalhesRestricoesPDF } from "../DetalhesRestricoesPDF";

interface Props {
  restricoes: IDadosRestricoes[];
}

export function BodyRestricoesPDF({ restricoes }: Readonly<Props>) {
  return (
    <View>
      {restricoes.map((restricao) => (
        <View
          style={{
            padding: 6,
          }}
          key={restricao.numeroProcessoBloqueio}
        >
          <View style={STYLES.SECTION_ROW}>
            <View style={[STYLES.SECTION_COL_2, { paddingRight: 4 }]}>
              <Text style={STYLES.FONT_SIZE_08}>Tipo de bloqueio</Text>
            </View>
            <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
              <Text style={STYLES.FONT_SIZE_08}>Número do processo</Text>
            </View>
            <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
              <Text style={STYLES.FONT_SIZE_08}>Data</Text>
            </View>
          </View>
          <View style={STYLES.SECTION_ROW}>
            <View style={[STYLES.SECTION_COL_2, { paddingRight: 4 }]}>
              <Text style={STYLES.FONT_SIZE_08}>{restricao.tipoBloqueio}</Text>
            </View>
            <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
              <Text style={STYLES.FONT_SIZE_08}>{restricao.numeroProcessoBloqueio}</Text>
            </View>
            <View style={[STYLES.SECTION_COL_4, { paddingRight: 4 }]}>
              <Text style={STYLES.FONT_SIZE_08}>{restricao.dataBloqueio}</Text>
            </View>
          </View>
          <DetalhesRestricoesPDF restricao={restricao} />
        </View>
      ))}
    </View>
  );
}

