import { Image, Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../../../style";

interface Props {
  imagens: {
    url: string;
  }[];
}

export function BodyImagensPdf({ imagens }: Readonly<Props>) {
  return (
    <View style={[STYLES.SECTION, STYLES.GAP_02]}>
      <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Fotos</Text>

      <View style={STYLES.IMAGEM_CONTAINER}>
        {imagens.length ? (
          imagens.map((imagem) => (
            <Image
              key={imagem.url}
              src={imagem.url}
              style={{ width: "150px" }}
            />
          ))
        ) : (
          <Text style={STYLES.FONT_SIZE_08}>Nenhuma imagem disponível</Text>
        )}
      </View>
    </View>
  );
}
