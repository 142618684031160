import { ReactNode } from "react";
import { RowProps } from "react-bootstrap";
import { StyledRow } from "./style";

interface Props extends RowProps {
  children: ReactNode;
}

export function SectionRow({ children, ...rest }: Readonly<Props>) {
  return <StyledRow {...rest}>{children}</StyledRow>;
}
