import { DropdownMenuItem } from "./Item";
import { DropdownMenu } from "./Menu";
import { DropdownRoot } from "./Root";
import { DropdownToggle } from "./Toggle";

export const CompoundDropdown = {
	Root: DropdownRoot,
	Toggle: DropdownToggle,
	Menu: DropdownMenu,
	Item: DropdownMenuItem,
};
