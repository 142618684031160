import { Table } from "containers/Table";
import { Container } from "components/Container";
interface IListarPagamentosProps {
  columns: any[];
  dados: any[];
  loading: boolean;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (page: number) => void;
  rowsPerPage: number;
  totalRows: number;
}

export function ListarSolicitacoes({
  columns,
  dados,
  loading,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage,
  totalRows,
}: IListarPagamentosProps) {
  return (
    <Container titulo="Solicitações de Pagamento">
      <Table
        keyField="id"
        data={dados}
        columns={columns}
        loading={loading}
        pagination={true}
        paginationRowsPerPageOptions={[10, 20, 50]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        totalRows={totalRows}
      />
    </Container>
  );
}
