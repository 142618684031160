import { DropdownSelect } from "components/DropdownSelect";
import { LoadingCirculo } from "components/LoadingCirculo";
import { ModalCustom } from "components/ModalCustom";
import { useRenomearArquivos } from "hooks/useRenomearArquivos";

interface Props {
  handleClose: () => void;
  onConfirm: () => void;
  show: boolean;
  padraoSelecionado: any;
  setPadraoSelecionado: (value: any) => void;
}

export function ModalRenomear({
  handleClose,
  onConfirm,
  show,
  padraoSelecionado,
  setPadraoSelecionado,
}: Readonly<Props>) {
  const { optionsRenomear } = useRenomearArquivos();

  return (
    <ModalCustom
      handleClose={handleClose}
      show={show}
      centered
      title="Download CRLV"
      onConfirm={onConfirm}
      footer
      footerConfirmButton
    >
      <div style={{ marginBottom: "1rem" }}>
        <span>Selecione a nomenclatura que deseja atribuir aos arquivos.</span>
      </div>
      {optionsRenomear.length ? (
        <DropdownSelect
          onSelect={setPadraoSelecionado}
          options={optionsRenomear}
          selectedValue={padraoSelecionado}
          title="Renomear para"
        />
      ) : (
        <LoadingCirculo mostrar={true} />
      )}
    </ModalCustom>
  );
}

