import { useState } from "react";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
    IData,
    IHttpError,
    IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { HttpStatusCode } from "axios";
import { useRequest } from "./useRequest";
import { ConfigServicosIdCliente } from "interfaces/ConfigServicosIdCliente";

export function useFinanceiro() {
    const msFinanceiro = useRequest("financeiro");
    const [error, setError] = useState<IHttpResponse<IData | any>>();
    const [configServicos, setConfigServicos] = useState<ConfigServicosIdCliente[]>([]);

    const listarConfigServicosPorIdCliente = async (idCliente: string | number) => {
        try {
            const { status, data } = await msFinanceiro.get(`recibos/configs/${idCliente}/servicos`);
            const rersponse = new HttpResponse(false, status, data);
            setError(rersponse);
            setConfigServicos(data)
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            if (error.status !== HttpStatusCode.NotFound) {
                setError(response);
            }
            return response;
        }
    };

    return {
        listarConfigServicosPorIdCliente,
        error,
        configServicos,
        setConfigServicos,
    };
}

