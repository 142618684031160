import { StatusDebitoPagamento } from "../Enum/StatusDebitoPagamento";

export const statusPagamento = [
  {
    value: StatusDebitoPagamento.Pendente,
    label: "Pendente",
    checked: false,
  },
  {
    value: StatusDebitoPagamento.Pago,
    label: "Pago",
    checked: false,
  },
  {
    value: StatusDebitoPagamento.Erro,
    label: "Erro",
    checked: false,
  },
  {
    value: StatusDebitoPagamento.Indeferido,
    label: "Indeferido",
    checked: false,
  },
  {
    value: StatusDebitoPagamento.Recusado,
    label: "Recusado",
    checked: false,
  },
  {
    value: StatusDebitoPagamento.Processando,
    label: "Processando",
    checked: false,
  },
  {
    value: StatusDebitoPagamento['Pago manual'],
    label: "Pagamento Manual",
    checked: false,
  },
];
