import { useCallback, useEffect, useState } from "react";
import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { HttpStatusCode } from "axios";

export function useEmpresas(buscarEmpresas = true) {
  const [empresas, setEmpresas] = useState<any[]>([]);
  const [empresasOptions, setEmpresasOptions] = useState<any[]>([]);
  const [empresasAgrupamento, setEmpresasAgrupamento] = useState<any[]>([]);
  const [agrupamentoOptions, setAgrupamentoOptions] = useState<any[]>([]);
  const [error, setError] = useState<IHttpResponse<IData | any>>();

  const listarTodasEmpresas = useCallback(async () => {
    try {
      const { status, data } = await api.get("/empresa/");
      const rersponse = new HttpResponse(false, status, data);
      setError(rersponse);
      setEmpresas(data);
      criarOptionsEmpresas(data);
      return rersponse;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    }
  }, []);

  const listarEmpresasAgrupamento = useCallback(async () => {
    try {
      const { status, data } = await api.get(
        "https://api.sistemamultas.com.br/api/v1/clientes/self/configuracao/agrupamento", {
          headers: {
            plataforma: 'debitos',
          }
        }
      );
      const response = new HttpResponse(false, status, data);
      setError(response);
      setEmpresasAgrupamento(response.data);

      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    }
  }, []);

  useEffect(() => {
    if (buscarEmpresas) {
      listarTodasEmpresas();
      listarEmpresasAgrupamento();
    }
  }, [listarTodasEmpresas, listarEmpresasAgrupamento]);

  function criarOptionsEmpresas(empresas: any[]) {
    empresas.sort((a, b) =>
      a.nome.localeCompare(b.nome, "br", { ignorePunctuation: true })
    );

    const optionsEmpresas = empresas.map((item) => ({
      value: item.uuidCliente,
      label: item.nomeCliente,
    }));

    setEmpresasOptions(optionsEmpresas);
  }

  const criarOptionsAgrupamento = useCallback(async () => {
    empresasAgrupamento.sort((a: any, b: any) =>
      a.razaoSocial.localeCompare(b.razaoSocial, "br", {
        ignorePunctuation: true,
      })
    );

    const optionsAgrupamento = empresasAgrupamento.map((item: any) => ({
      value: item.idClienteAgrupamento,
      label: item.razaoSocial,
    }));

    setAgrupamentoOptions(optionsAgrupamento);
  }, [empresasAgrupamento]);

  function buscarNomeEmpresa(idCliente: NullableString) {
    return empresasAgrupamento.find((item) => item.idClienteAgrupamento === Number(idCliente))?.razaoSocial;
}

  return {
    empresas,
    error,
    empresasOptions,
    criarOptionsAgrupamento,
    agrupamentoOptions,
    empresasAgrupamento,
    buscarNomeEmpresa,
  };
}

