import { StatusContainer } from "./style";

export enum StatusPagamentoEnum {
  "Pendente" = 0,
  "Finalizado" = 1,
}

interface IStatusPagamento {
  status: number;
}

export function StatusPagamento({ status }: IStatusPagamento) {
  return (
    <StatusContainer statusPagamento={status}>
      {StatusPagamentoEnum[status]}
    </StatusContainer>
  )
}