import {
  Container,
  DadosIpvaBox,
  DadosIpvaContainer,
  DadosIpvaText,
  DadosIpvaTitle,
} from "./style";

interface IDebitoMultaProps {
  exercicio?: number;
}

export function DebitoIpva({ exercicio }: IDebitoMultaProps) {
  return (
    <Container>
      <DadosIpvaContainer>
        <DadosIpvaBox>
          <DadosIpvaTitle>Exercício</DadosIpvaTitle>
          <DadosIpvaText>{exercicio}</DadosIpvaText>
        </DadosIpvaBox>
      </DadosIpvaContainer>
    </Container>
  );
}

