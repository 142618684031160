import { formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { toastErro } from "components/Toast";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { IDetalhesConsulta } from "features/debito/consulta/interfaces/IDetalhesConsulta";
import { IconeInfo } from "features/debito/consulta/pages/Detalhes/Containers/IconeInfo";
import { ExpandedComponentConsulta, makeStatusDebitosConsulta, makeTentativasConsulta } from "features/debito/consulta/pages/Detalhes/Containers/ListarDetalhes";
import { useCallback } from "react";
import { Col } from "react-bootstrap";
import { utils, writeFileXLSX } from "xlsx";

interface Props {
  dados: any[];
  loading: boolean;
  totalRows: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (limit: number) => void;
  limit: number;
  setRowExpandToggled: any;
  rowToExpand: any;
  idConsulta: string | number;
  buscarDadosPlanilha(id: number | string): any;
}

export function TableDetalhesRelatorioConsultas({
  dados,
  loading,
  totalRows,
  onChangePage,
  onChangeRowsPerPage,
  limit,
  rowToExpand,
  setRowExpandToggled,
  idConsulta,
  buscarDadosPlanilha,
}: Readonly<Props>) {
  function makeIconeInfo(debito: any) {
    return (
      <IconeInfo
        statusDebito={debito.statusDebito}
        onClick={() => setRowExpandToggled(debito)}
        temMulta={debito.temMulta}
        idTipoDebito={debito.debito}
      />
    );
  }

  const quantidadeCaracteresTextoLongo = 70;
  const widthStrategy = { md: 200, lg: 350 };
  const widthObservacao = `${Math.max(...dados.map((item) =>
    item.descricao && item.descricao.length > quantidadeCaracteresTextoLongo ? widthStrategy.lg : widthStrategy.md
  ))}px`;

  const columns: IColum<IDetalhesConsulta>[] = [
    {
      name: <Col>Placa</Col>,
      selector: ({ placa }: IDetalhesConsulta) => placa.toUpperCase(),
    },
    {
      name: <Col>Renavam</Col>,
      selector: ({ renavam }) => renavam,
      width: "100px",
    },
    {
      name: <span>Chassi</span>,
      selector: ({ chassi }) => chassi,
      width: "150px",
    },
    {
      name: <Col>UF</Col>,
      selector: ({ uf }: IDetalhesConsulta) => uf.toUpperCase(),
      width: "80px",
    },
    {
      name: <Col>Débito</Col>,
      selector: ({ debito }: IDetalhesConsulta) => TipoDebitos[debito],
      width: "115px",
    },
    {
      name: <Col>Vencimento</Col>,
      selector: ({ vencimento }: IDetalhesConsulta) =>
        vencimento ? formataDataISOParaBR(vencimento) : "--",
      width: "125px",
    },
    {
      name: <Col>Valor Total</Col>,
      selector: ({ total }) => {
        const totalAtualizado = total / 100;
        return formatarDinheiro(totalAtualizado) || "--";
      },
      width: "120px",
    },
    {
      name: <span>Observação</span>,
      selector: ({ descricao }) => descricao && descricao !== 'null' ? descricao : '--',
      width: widthObservacao,
    },
    {
      name: <Col>Info</Col>,
      selector: (debito: IDetalhesConsulta) => makeIconeInfo(debito),
      width: "120px",
    },
    {
      name: <Col>Status da consulta</Col>,
      selector: ({ statusDebito }) => makeStatusDebitosConsulta(statusDebito),
      sortable: true,
      sortField: "statusDebito",
      width: "200px",
    },
    {
      name: <Col>Tentativas da consulta</Col>,
      selector: ({ tentativas, statusDebito }) => makeTentativasConsulta(tentativas, Number(statusDebito))
    }
  ];

  const handleExport = useCallback(async () => {
    const { data, hasErro } = await buscarDadosPlanilha(idConsulta);

    if (hasErro) {
      toastErro(data.message);
      return;
    }

    const dadosPlanilha = data.map((item: any) => ({
      Placa: item.placa.toUpperCase(),
      Renavam: item.renavam,
      Chassi: item.chassi,
      UF: item.uf.toUpperCase(),
      Débito: TipoDebitos[item.debito],
      Vencimento: item.vencimento
        ? formataDataISOParaBR(item.vencimento)
        : "Nada Consta",
      Total: item.total ? formatarDinheiro(item.total / 100) : "Nada Consta",
      "Status Solicitação Pgto": item.statusDebito
        ? StatusSolicitacaoPagamentoEnumV2[Number(item.statusDebito)]
        : "Nada Consta",
      "Observação": item.descricao,
      Tentativas: item.tenativas,
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 5 },
      { wch: 12 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 20 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Relatório Consultas");
    writeFileXLSX(workBook, "relatório-consultas.xlsx");
  }, [buscarDadosPlanilha, idConsulta]);

  return (
    <Table
      columns={columns}
      data={dados}
      loading={loading}
      keyField="id"
      pagination
      paginationRowsPerPageOptions={[100, 200, 300]}
      totalRows={totalRows}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      rowsPerPage={limit}
      rowToExpand={rowToExpand}
      expandableRowsComponent={ExpandedComponentConsulta}
      exportExcel
      handleExport={handleExport}
      buttonExportVariant="secondary"
    />
  );
}
