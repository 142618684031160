import { Page } from "containers/Page";
import { SolicitarDownloadImagens } from "./containers/SolicitarDownloadImagens";
import { ListarSolicitacoesDownload } from "./containers/ListarSolicitacoesDownload";
import { useSolicitacaoDownload } from "./hooks/useSolicitacaoDownload";
import { useEffect } from "react";

export function DownloadArquivos() {
  const {
    handleLimitChange,
    handlePageChange,
    loading,
    listarSolicitacoesDownload,
    solicitarDownload,
    limit,
    totalRows,
    solicitacoes,
  } = useSolicitacaoDownload();

  useEffect(() => {
    listarSolicitacoesDownload();
  }, [listarSolicitacoesDownload])

  return (
    <Page title="Download de arquivos">
      <SolicitarDownloadImagens
        solicitarDownload={solicitarDownload}
      />
      <ListarSolicitacoesDownload
        solicitacoes={solicitacoes}
        listarSolicitacoesDownload={listarSolicitacoesDownload}
        loading={loading}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
        limit={limit}
        totalRows={totalRows}
      />
    </Page>
  );
}

