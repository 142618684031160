import { useState } from "react";

export function useExpandableRow() {
  const [rowToExpand, setRowToExpand] = useState();

  function setRowExpandToggled(row: any) {
    const rowExpanded = !row.defaultExpanded;
    row.defaultExpanded = rowExpanded;
    setRowToExpand(rowExpanded ? row : null);
  }
  return {
    rowToExpand,
    setRowExpandToggled,
  };
}