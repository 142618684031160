import { ModalCustom } from "components/ModalCustom";
import { Alert } from "react-bootstrap";
import { Container, IconFaExclamationTriangle, Texto } from "./style";

interface IModalConfirmacaoSolicitacao {
  show: boolean;
  handleClose(): void;
  onConfirm(): void;
}

export function ModalConfirmacaoPagamentoSelecionados({ show, handleClose, onConfirm }: IModalConfirmacaoSolicitacao) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      size="lg"
      title="Pagar débitos"
      centered={true}
      footer
      footerConfirmButton
      onConfirm={onConfirm}
    >
      <Container>
        <Texto>
          A seleção do checkbox contempla apenas os débitos listados na página atual, para realizar o pagamento de todos
          os débitos disponíveis no lote, clique no botão <b>Pagar</b> sem selecionar nenhum checkbox.
        </Texto>
      </Container>
      <Alert variant="warning">
        <IconFaExclamationTriangle /> Deseja realizar o pagamento apenas dos débitos selecionados?
      </Alert>
    </ModalCustom>
  );
}
