import styled from "styled-components";

export const DropdownToggleContainer = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 0.875rem;
	padding: 0.5rem;
	border: 1px solid ${({ theme }) => theme.colors.neutral.neutral30};
	border-radius: 0.5rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	svg {
		width: 1rem !important;
		height: 1rem !important;
	}
`;
