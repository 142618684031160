import { ComponentProps, ReactNode } from "react";

interface Props extends ComponentProps<"div"> {
  children: ReactNode;
}

export function CompositeContainerBody({ children, ...rest }: Readonly<Props>) {
  return <div {...rest}>{children}</div>;
}

