import styled from "styled-components";

interface IconeContainerProps {
  erro: boolean;
}

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MensagemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const IconeContainer = styled.div<IconeContainerProps>`
  padding: 0.875rem;
  background-color: ${({ erro, theme }) => erro ? theme.colors.danger.bgColor : theme.colors.project.bgColor};
  border-radius: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${({ erro, theme }) => erro ? theme.colors.danger.main : theme.colors.project.main }
  }
`;