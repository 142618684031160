import { Col } from "react-bootstrap";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  align-items: center;
  gap: 1rem;
`;

export const DatePickerContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

export const InputContainer = styled(Col)`
  display: grid;
  height: 100px;

  @media (max-width: 1000px) {
    max-width: ${({ lg }) => lg === '3' ? '23.4%' : '14.3%'};
  }

  @media (min-width: 1250px) {
    max-width: ${({ lg }) => lg === '3' ? '22%' : '15%'};;
  }

  label {
    font-size: 14px;
  }

  input {
    margin-top: 0.5rem;
    height: 32px;
  }
`;
