import { Route, Routes } from "react-router-dom";
import { RelatorioPagamentosRecusados } from "../pages/relatorio";

export function RelatorioPagamentosRecusadosRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RelatorioPagamentosRecusados />} />
    </Routes>
  )
}
