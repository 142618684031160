import { TextoClaro } from "components/TextoClaro";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { ToggleRestricoes } from "./containers/ToggleRestricoes";
import { Stronger } from "components/Stronger";
import { IDadosRestricoes } from "../DadosRestricoes";

interface Props {
  restricoes: IDadosRestricoes[];
}

export function DadosRestricoesNacional({ restricoes }: Readonly<Props>) {
  const haPendencia = restricoes.length && !!restricoes.filter(
    (restricao) => {
      return restricao.motivoBloqueio !== 'Comunicação de venda'
        || (restricao.motivoBloqueio === 'Comunicação de venda' && restricao.indicadorComunicacaoDeVenda !== 'NAO CONSTA COMUNICACAO DE VENDAS')
    }
  ).length;

  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={haPendencia ? IconePendencia : IconeNadaConsta} />
        <Section.Titulo titulo="Restrições" />
      </Section.Header>
      <Section.Body>
        {haPendencia ? (
          <>
            <Section.Row>
              <Section.Col lg="12" md="12" sm="12">
                <TextoClaro>Descrição</TextoClaro>
              </Section.Col>
            </Section.Row>
            {restricoes.map((restricao) => restricao.motivoBloqueio === 'Comunicação de venda'
              ?
              <Section.RowToggle
                style={{
                  backgroundColor: "#E6E6E6",
                  borderRadius: "0.75rem",
                  marginTop: "0.5rem",
                  padding: "0.5rem 0.25rem",
                }}
                rowContent={
                  <>
                    <Section.Col lg="12" md="12" sm="12">
                      {restricao.motivoBloqueio}
                    </Section.Col>
                  </>
                }
                toggleContent={
                  restricoes.map((restricao) => <ToggleRestricoes dados={restricao} />)
                }
              />
              :
              <Section.Row>
                <Section.Col lg="12" md="12" sm="12">
                  {restricao.motivoBloqueio}
                </Section.Col>
              </Section.Row>
            )}
          </>
        ) : (
          <Section.Row>
            <Section.Col>
              <span>Restrições</span> <br />
              <Stronger>Nada consta</Stronger>
            </Section.Col>
          </Section.Row>
        )}
      </Section.Body>
    </Section.Root>
  );
}

