import styled from "styled-components";

export const ModalBody = styled.div`
  display: grid;
  gap: 32px;
`;

export const DatePickerBody = styled.div`
  display: grid;
  gap: 0.2rem;
`;

export const DivButtons = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 105px 105px 105px 105px;
`

export const DivTextoSelecione = styled.div`
  color: ${({ theme }) => theme.colors.neutral.neutral70};
`

interface IIcon {
  show: boolean;
}
export const SpanIcon = styled.span<IIcon>`
  ${({ show }) => show ? "" : "display:none;"}
`;

interface ITextoRegra {
  show: boolean;
}
export const DivTextoRegra = styled.div<ITextoRegra>`
  ${({ show }) => show ? "" : "display:none;"}
  color: ${({ theme }) => theme.colors.danger.main};
  font-size: 12px;
  margin-left: 16px;
`