import { Informacao } from "components/Informacao";
import { Container, Placa, PlacasContainer, TextoNegrito } from "./style";

interface Props {
  totalComprovantes: number;
  totalPagamentos: number;
  placas: string[];
}

export function InfoModalComprovantes({
  totalComprovantes,
  totalPagamentos,
  placas,
}: Props) {
  const mensagem = `Foram localizados ${totalComprovantes} comprovantes de ${totalPagamentos} débitos solicitados.`;

  return (
    <Container>
      <Informacao
        type="atencao"
        mensagem={mensagem}
        showBotaoFechar={false}
        showLabel={false}
      />
      <span>Deseja realizar o download dos comprovantes disponíveis?</span>
      <TextoNegrito>Comprovantes não localizados - placas</TextoNegrito>
      <PlacasContainer>
        {placas.map((placa) => (
          <Placa key={placa}>{placa}</Placa>
        ))}
      </PlacasContainer>
    </Container>
  );
}
