import React from "react";
import { Container, Description, Grid, Title } from "./estilo";
const Page401 = () => {
  return (
    <Container>
      <Grid>
        <Title>
          <h1>401</h1>
          <h3>Página não autorizada.</h3>
        </Title>
        <Description>
          Desculpe, mas você não tem autorização para acessar essa pagina.
          <h2>
            {" "}
            <a href="/">Acesse a página inicial</a>{" "}
          </h2>
        </Description>
      </Grid>
    </Container>
  );
};

export default Page401;
