import { Container } from "components/Container";
import { LoadingScreen } from "components/LoadingScreen";
import { toastErro } from "components/Toast";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { StatusDebitoPagamento } from "features/debito/pagamento/pages/Detalhes/Enum/StatusDebitoPagamento";
import { IRelatorioPagamentos } from "features/relatorios/pagamento/interfaces/RelatorioPagamento";
import { useCallback } from "react";
import { utils, writeFileXLSX } from "xlsx";
import { converterDataHora, formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { Resumo } from "../Resumo";
import { TableDetalhesRelatorioPagamento } from "../TableDetalhesRelatorioPagamento";
import { statusAdapter } from "../Status";

interface Props {
  resumo: IRelatorioPagamentos | null;
  dados: any[];
  loading: boolean;
  onChangeRowsPerPage: (limit: number) => void;
  onChangePage: (page: number) => void;
  handleDownload: (
    debitoPagamentoId: number,
    placa: string,
    idTipoDebito: number
  ) => void;
  rowsPerPage: number;
  totalRows: number;
  buscarDadosPlanilhaDetalhes(id: number | string): any;
  loadingDadosPlanilha: boolean;
}

export function DetalhesContainer({
  dados,
  resumo,
  loading,
  onChangePage,
  onChangeRowsPerPage,
  handleDownload,
  rowsPerPage,
  totalRows,
  buscarDadosPlanilhaDetalhes,
  loadingDadosPlanilha,
}: Props) {
  const handleExport = useCallback(async () => {
    const { data, hasErro } = await buscarDadosPlanilhaDetalhes(resumo?.id!);

    if (hasErro) {
      toastErro(data.message);
      return;
    }

    const dadosPlanilha = data.map((dado: any) => ({
      PLACA: dado.placa.toUpperCase(),
      RENAVAM: dado.renavam,
      CHASSI: dado.chassi,
      UF: dado.uf?.toUpperCase(),
      DEBITO: TipoDebitos[dado.idTipoDebito],
      VENCIMENTO: formataDataISOParaBR(dado.vencimento),
      VALOR: formatarDinheiro(dado.valor / 100),
      "JUROS LW": formatarDinheiro(dado.juros / 100),
      "VALOR PAGO": formatarDinheiro(dado.valorPago / 100),
      STATUS_PAGAMENTO:
        StatusDebitoPagamento[statusAdapter(Number(dado.status))],
      DATA_PAGAMENTO: converterDataHora(dado.dataPrazoPagamento),
      ID_RECIBO: dado.reciboFinanceiroId || "--",
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 4 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Relatórios Pagamento");
    writeFileXLSX(workBook, "detalhes-relatorio-pagamento.xlsx");
  }, [buscarDadosPlanilhaDetalhes, resumo]);

  return (
    <Container titulo="Detalhes">
      <Resumo dados={resumo!} />
      <TableDetalhesRelatorioPagamento
        handleExport={handleExport}
        dados={dados}
        loading={loading}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleDownload={handleDownload}
        rowsPerPage={rowsPerPage}
        totalRows={totalRows}
      />
      <LoadingScreen mostrar={loadingDadosPlanilha} />
    </Container>
  );
}

