import styled from "styled-components";

export const RatingSeguridadeContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding: 1rem;
  display: grid;
  gap: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral30};
  margin: 0;
`;
