import { toastErro } from "components/Toast";
import { Page } from "containers/Page";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRelatorioPagamentos } from "../../hooks/useRelatorioPagamentos";
import { DetalhesContainer } from "./containers/DetalhesContainer";
import { FIltrosDetalhesRelatorioPagamento } from "./containers/FiltrosDetalhesRelatorioPagamento";

export function DetalhesRelatorioPagamento() {
  const { id } = useParams();

  const {
    dadosRelatorio,
    buscarDetalhes,
    limitDetalhes,
    setLimitDetalhes,
    page,
    setPage,
    loadingDados,
    baixarComprovanteUnitario,
    loading,
    error,
    filtrosDetalhes,
    setFiltrosDetalhes,
    totalRows,
    buscarDadosPlanilhaDetalhes,
    loadingDadosPlanilha,
    buscarResumoSolicitacaoPagamento,
    resumoSolicitacaoPagamento,
  } = useRelatorioPagamentos();

  useEffect(() => {
    buscarResumoSolicitacaoPagamento(id!);
    buscarDetalhes(id!);
  }, [
    buscarDetalhes,
    buscarResumoSolicitacaoPagamento,
    id,
    filtrosDetalhes,
    limitDetalhes,
    page,
  ]);

  useEffect(() => {
    error?.hasErro && toastErro(error.data.erro);
  }, [error]);

  function handleSubmitFiltros(data: any) {
    setFiltrosDetalhes(data);
  }

  function handlePageChange(page: number) {
    setPage(page);
  }

  function handleLimitChange(limit: number) {
    setLimitDetalhes(limit);
  }

  return (
    <Page title="Relatório de pagamentos" loading={loading}>
      <FIltrosDetalhesRelatorioPagamento
        handleSubmitFiltros={handleSubmitFiltros}
      />
      <DetalhesContainer
        loadingDadosPlanilha={loadingDadosPlanilha}
        buscarDadosPlanilhaDetalhes={buscarDadosPlanilhaDetalhes}
        resumo={resumoSolicitacaoPagamento}
        dados={dadosRelatorio}
        loading={loadingDados}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        handleDownload={baixarComprovanteUnitario}
        rowsPerPage={limitDetalhes}
        totalRows={totalRows}
      />
    </Page>
  );
}

