import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0.25rem;

  svg {
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;
