import { FaExclamationTriangle } from "react-icons/fa";
import { ButtonCheck, ButtonExclamacao, IconeContainer } from "./style";
import { useSolicitacaoDownload } from "features/arquivos/pages/Download/hooks/useSolicitacaoDownload";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { ModalDadosConsultaImagem } from "../ModalDadosConsultaImagem";
interface Props {
  idSolicitacaoDownload: number;
  disabledDisponiveis: boolean;
  disabledIndisponiveis: boolean;
}

export function IconeDetalhes({
  disabledDisponiveis,
  disabledIndisponiveis,
  idSolicitacaoDownload,
}: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    buscarIndisponiveis,
    buscarDisponiveis,
    disponiveis,
    setDisponiveis,
    indisponiveis,
    setIndisponiveis,
  } = useSolicitacaoDownload();

  useEffect(() => {
    if (disponiveis.length > 0) {
      setShowModal(true);
    }
  }, [disponiveis, setDisponiveis]);

  useEffect(() => {
    if (indisponiveis.length > 0) {
      setShowModal(true);
    }
  }, [indisponiveis, setIndisponiveis]);

  function handleCloseModal() {
    setShowModal(false);
    setIndisponiveis([]);
    setDisponiveis([]);
  }

  return (
    <IconeContainer>
      <ButtonCheck
        onClick={() => buscarDisponiveis(idSolicitacaoDownload)}
        disabled={disabledDisponiveis}
      >
        <Icon icon="mdi:file-check" />
      </ButtonCheck>
      <ButtonExclamacao
        onClick={() => buscarIndisponiveis(idSolicitacaoDownload)}
        disabled={disabledIndisponiveis}
      >
        <FaExclamationTriangle />
      </ButtonExclamacao>
      <ModalDadosConsultaImagem
        disponiveis={disponiveis}
        indisponiveis={indisponiveis}
        mostrar={showModal}
        handleCloseModal={handleCloseModal}
      />
    </IconeContainer>
  );
}

