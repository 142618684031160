import { StatusAdiantamentoEnum } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";

export function handleDisabled(statusAdiantamento: string) {
	const naoPodeInformarRecebimento =
		statusAdiantamento === StatusAdiantamentoEnum.PENDENTE ||
		statusAdiantamento === StatusAdiantamentoEnum.PAGO ||
		statusAdiantamento === StatusAdiantamentoEnum.REEMBOLSO ||
		statusAdiantamento === StatusAdiantamentoEnum.CANCELADO;

	const naoPodeMudarParaReembolso =
		statusAdiantamento === StatusAdiantamentoEnum.AGUARDANDO_VALIDACAO ||
		statusAdiantamento === StatusAdiantamentoEnum.PAGAMENTO_INCOMPLETO ||
		statusAdiantamento === StatusAdiantamentoEnum.REEMBOLSO ||
		statusAdiantamento === StatusAdiantamentoEnum.PAGO;

	const buttonDisabled = {
		recebimentoTotal: naoPodeInformarRecebimento,
		recebimentoParcial: naoPodeInformarRecebimento,
		reembolso: naoPodeMudarParaReembolso,
	};

	return buttonDisabled;
}