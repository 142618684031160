import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const filtrosRelatorioExtratoVeicularSchema = yup.object().shape({
    placa: yup.string().optional().nullable(),
    periodoConsulta: yup.array().optional().nullable(),
    idConsulta: yup.string().optional().nullable(),
    idRecibo: yup.string().optional().nullable(),
    chassi: yup.string().optional().nullable(),
    renavam: yup.string().optional().nullable(),
    tipoConsulta: yup.string().optional().nullable(),
    idEmpresa: yup.number().optional().nullable(),
    cobranca: yup.string().optional().nullable(),
});