import React, { useEffect, useState } from "react";
import { ExportExcel } from "components/ExportExcel";
import { Search } from "components/Search";
import DataTable, {
  Alignment,
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import { useSelectedRows } from "./hooks/useSelectedRows";
import { useTablePaginationSortSearch } from "./hooks/useTablePaginationSortSearch";
import { ContainerTable, customStyle } from "./style";
import { ToolsAction } from "./ToolsAction";
import { ToolsHeaderTable } from "./ToolsHeaderTable";
import { TableProps } from "./interfaces/ITable";
import { LoadingCirculo } from "components/LoadingCirculo";
import { PaginationComponent } from "components/CustomPagination";
import { FaDownload } from "react-icons/fa";

export function Table({
  keyField,
  title,
  columns,
  data,
  loading,
  defaultSortFieldId,
  showHeader = false,
  persistTableHead = false,
  noSubHeader = false,
  noTableHead = false,
  pagination = false,
  totalRows = 0,
  setPaginationSortSearch,
  selectableRows = false,
  setRowsSelected,
  clearSelectedRows,
  selectableRowsNoSelectAll = false,
  selectableRowsSingle = false,
  tools,
  toolsHeader,
  exportExcel = false,
  rowToExpand,
  expandableRowsComponent,
  handleExport,
  buttonExportVariant,
  paginationRowsPerPageOptions,
  onChangeRowsPerPage,
  onChangePage,
  rowsPerPage,
  customTableStyle,
  iconeExport: IconeExport = FaDownload,
  textoBotaoExport = "Exportar",
  showToggleVisualizacao = false,
  onClickToggleVisualizacao,
  noDataContent = <div> Nenhum registro encontrado</div>,
  exportComponent = false,
  toggleComponent = <div></div>,
}: TableProps) {
  const { setSort, setOrder, setSearch, paginationSortSearch } =
    useTablePaginationSortSearch();

  const {
    selectedRows,
    setSelectedRows,
    toggledClearRows,
    setToggleClearRows,
  } = useSelectedRows();

  const [rowsPerPageComponente, setRowsPerPageComponente] = useState<number>(1);

  useEffect(() => {
    if (!rowsPerPage) return;
    setRowsPerPageComponente(rowsPerPage);
  }, [rowsPerPage]);

  useEffect(() => {
    if (clearSelectedRows == null) return;

    if (clearSelectedRows) return handleClearRows();
  }, [clearSelectedRows]);

  useEffect(() => {
    if (setPaginationSortSearch && pagination)
      setPaginationSortSearch(paginationSortSearch);
  }, [paginationSortSearch]);

  useEffect(() => {
    if (setRowsSelected && selectedRows) setRowsSelected(selectedRows);
  }, [selectedRows]);

  const handleChange = ({ selectedRows }: any) => {
    setSelectedRows(selectedRows);
  };
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelectedRows([]);
  };

  const handleSort = async (
    column: TableColumn<any>,
    sortDirection: string
  ) => {
    const sort = column.sortField ? column.sortField : "";
    setSort(sort);
    setOrder(sortDirection);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Linhas por página",
    rangeSeparatorText: "de",
  };

  const actionTools = <ToolsAction>{tools}</ToolsAction>;
  const actionHeader = (
    <ToolsHeaderTable
      tools={toolsHeader}
      exportExcel={
        exportExcel ? (
          exportComponent ? (
            exportComponent
          ) : (
            <ExportExcel
              texto={textoBotaoExport}
              variant={buttonExportVariant}
              onClick={handleExport}
              icone={IconeExport}
              onClickToggleVisualizacao={onClickToggleVisualizacao}
              showToggleVisualizacao={showToggleVisualizacao}
              toggleComponent={toggleComponent}
            />
          )
        ) : (
          ""
        )
      }
      search={
        pagination ? <Search setSearch={setSearch} disabled={loading} /> : ""
      }
    />
  );

  const style = { ...customStyle, ...customTableStyle };

  return (
    <ContainerTable>
      <DataTable
        keyField={keyField ? keyField : ""}
        title={title ? title : null}
        fixedHeader
        fixedHeaderScrollHeight="100vh"
        highlightOnHover
        persistTableHead={persistTableHead}
        noTableHead={noTableHead}
        noHeader={!showHeader}
        columns={columns}
        data={data}
        customStyles={style}
        progressPending={loading}
        progressComponent={<LoadingCirculo mostrar={loading} />}
        noDataComponent={noDataContent}
        striped
        responsive
        subHeader={!noSubHeader} // header de pesquisa
        subHeaderAlign={Alignment.RIGHT}
        subHeaderWrap
        subHeaderComponent={actionHeader}
        //SELECT
        selectableRows={selectableRows}
        selectableRowsHighlight
        selectableRowsNoSelectAll={selectableRowsNoSelectAll} //não mostrar todos
        selectableRowsSingle={selectableRowsSingle} //escolher somente 1
        selectableRowDisabled={(row) => row.selectable === false}
        // selectableRowsVisibleOnly
        // selectableRowsComponent={Checkbox}
        onSelectedRowsChange={handleChange}
        clearSelectedRows={toggledClearRows}
        //EXPANDIR
        expandableRows
        expandableRowExpanded={(row) => row === rowToExpand}
        expandableRowsHideExpander //esconder o icone de abrir
        expandableRowsComponent={expandableRowsComponent}
        // expandOnRowClicked //click em toda a linha
        // expandOnRowDoubleClicked
        // onRowExpandToggled={(state, row) => } //pega row atual
        // onRowClicked={(row, mouse)=>  }
        //PAGINATION
        paginationPerPage={rowsPerPage}
        paginationComponentOptions={paginationComponentOptions}
        paginationComponent={({ rowsPerPage, ...restante }) => (
          <PaginationComponent
            rowsPerPage={rowsPerPageComponente}
            {...restante}
          />
        )}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        pagination={pagination}
        // paginationPerPage={10}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        // SORT
        defaultSortFieldId={defaultSortFieldId ? defaultSortFieldId : 0}
        onSort={handleSort}
        sortServer
        actions={actionTools}
      />
    </ContainerTable>
  );
}
