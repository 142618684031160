import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  gap: 1rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.project.main};
`;

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.project.bgColor};
  border-radius: 1rem;
  padding: 0.75rem 1rem;
  font-size: 1.25rem;
`;

export const TextoContainer = styled.div`
  display: grid;
  text-align: center;
  gap: 0.25rem;
`;
