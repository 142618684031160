import { FaCheckCircle } from "react-icons/fa";
import { Container } from "./style";

export function IconeNadaConsta() {
  return (
    <Container>
      <FaCheckCircle />
    </Container>
  );
}
