import { Col } from "react-bootstrap";
import styled from "styled-components";

export const InputContainer = styled(Col)`
  display: grid;
  height: 100px;
  max-width: 18%;

  label {
    font-size: 14px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  align-items: center;
  gap: 1rem;

  button {
    margin-bottom: 0.5rem;
  }
`;