import styled from "styled-components";

export const BodyPlacas = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.neutral10};
  padding: 1rem;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 0.5rem;
  gap: 1.5rem;
`;

export const HeaderBody = styled.div`
  display: flex;
  align-items: center;
`;

export const BodyItems = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

export const PlacasDiv = styled.div`
  width: 120px;
`;

export const ObservacaoDiv = styled.div`
  width: 100%;
`;

export const HeaderPlacaWrapper = styled.div`
  width: 120px;
`;

export const HeaderObservacaoWrapper = styled.div`
  width: 100%;
`;
