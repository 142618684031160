import { toastErro } from "components/Toast";
import { useEffect, useState } from "react";
import api from "_services/api";
import { IUsuarioKeycloak } from "../interfaces/UsuarioKeycloak.interface";

export function useListarUsuarios() {
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState<IUsuarioKeycloak[]>([]);
  const [filtro, setFiltro] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);

  useEffect(() => {
    listarUsuarios();
  }, [filtro, page, limit]);

  function handlePageChange(page: number): void {
    setPage(page);
  }

  function handleRowsPerPageChange(limit: number): void {
    setLimit(limit);
  }

  async function handleFormSubmit({ filtro }: any) {
    setFiltro(filtro);
  }

  async function listarUsuarios(): Promise<any> {
    setLoading(true);
    try {
      const { data } = await api.get(`/usuario/?search=${filtro}&page=${page}&limit=${limit}`);

      setUsuarios(data.usuarios);
      setTotalRows(data.paginacao.totalUsuarios);
    } catch {
      toastErro("Erro ao buscar usuários");
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    usuarios,
    totalRows,
    handleFormSubmit,
    setFiltro,
    handlePageChange,
    handleRowsPerPageChange,
  };
}
