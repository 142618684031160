import { useCallback, useEffect, useState } from "react";
import { useRequest } from "hooks/useRequest";
import { toastErro } from "components/Toast";
import { useNavigate } from "react-router-dom";

export function useExtratoVeicularDetalhado(
  idExtratoVeicular: string | undefined,
  idLoteExtratoVeicular: string | undefined
) {
  const [detalhesExtratoVeicular, setDetalhesExtratoVeicular] = useState<any>(null);
  const { loading, get } = useRequest("consulta");
  const navigate = useNavigate();

  const buscarDetalhesExtratoVeicular = useCallback(async () => {
    const { data, hasErro } = await get(`veiculo/extrato/${idLoteExtratoVeicular}/detalhes/${idExtratoVeicular}`);

    if (hasErro) {
      toastErro("Não foi possível acessar os detalhes");
      navigate(`/veiculos/extrato/consultas/${idLoteExtratoVeicular}`);
      return;
    }

    setDetalhesExtratoVeicular(data);
  }, [idExtratoVeicular, get, navigate, idLoteExtratoVeicular]);

  useEffect(() => {
    buscarDetalhesExtratoVeicular();
  }, [buscarDetalhesExtratoVeicular]);

  return {
    detalhesExtratoVeicular,
    loading,
  };
}

