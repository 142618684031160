import { parseJwt } from "_services/auth";
import { Empresa } from "_utils/enuns/Empresa.enum";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export function InternoLw({ children }: Props) {
  const { uuid_cliente } = parseJwt();
  
  if (uuid_cliente == Empresa.LW) {
    return <>{children}</>;
  }

  return null;
}
