import { converterDataHoraBr, formataDataISOParaBR } from "_utils/Data";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { IconeDownload } from "./containers/IconeDownload";
import { IconeDetalhes } from "./containers/IconeDetalhes";
import { useSolicitacaoDownload } from "features/arquivos/pages/Download/hooks/useSolicitacaoDownload";
import { utils, writeFileXLSX } from "xlsx";

interface ISolicitacaoDownload {
  id: number;
  dataHora: string | Date;
  empresa: string;
  nomeUsuario: string;
  qtdSolicitados: number;
  qtdEncontrados: number;
}

interface Props {
  loading: boolean;
  handleLimitChange: (limit: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  totalRows: number;
  solicitacoes: ISolicitacaoDownload[];
}

const columns: IColum<ISolicitacaoDownload>[] = [
  {
    name: "ID Consulta",
    selector: ({ id }: ISolicitacaoDownload) => id,
    width: "105px",
  },
  {
    name: "Data/Hora Solicitação",
    selector: ({ dataHora }: ISolicitacaoDownload) =>
      converterDataHoraBr(new Date(dataHora)),
    width: "175px",
  },
  {
    name: <span>Empresa</span>,
    selector: ({ empresa }: ISolicitacaoDownload) => empresa,
  },
  {
    name: <span>Usuário</span>,
    selector: ({ nomeUsuario }: ISolicitacaoDownload) => nomeUsuario,
  },
  {
    name: <span>Arquivos Solicitados</span>,
    selector: ({ qtdSolicitados }: ISolicitacaoDownload) => qtdSolicitados,
  },
  {
    name: <span>Arquivos Encontrados</span>,
    selector: ({ qtdEncontrados }: ISolicitacaoDownload) => qtdEncontrados,
  },
  {
    name: <span>Arquivos Não Encontrados</span>,
    selector: ({ qtdSolicitados, qtdEncontrados }: ISolicitacaoDownload) =>
      qtdSolicitados - qtdEncontrados,
  },
  {
    name: "Baixar",
    selector: ({ qtdEncontrados, id }: ISolicitacaoDownload) => (
      <IconeDownload
        idSolicitacaoDownload={id}
        disabled={qtdEncontrados === 0}
      />
    ),
    width: "80px",
  },
  {
    name: "Detalhes",
    selector: ({
      qtdEncontrados,
      qtdSolicitados,
      id,
    }: ISolicitacaoDownload) => (
      <IconeDetalhes
        idSolicitacaoDownload={id}
        disabledDisponiveis={qtdEncontrados === 0}
        disabledIndisponiveis={qtdSolicitados === qtdEncontrados}
      />
    ),
    width: "80px",
  },
];

export function TableSolicitacoesDownload({
  handleLimitChange,
  handlePageChange,
  limit,
  loading,
  solicitacoes,
  totalRows,
}: Props) {
  const { buscarDadosPlanilha } = useSolicitacaoDownload();

  async function handleDownload() {
    const dados = await buscarDadosPlanilha();

    if (!dados.length) {
      return;
    }

    const dadosPlanilha = dados.map((dado: any) => ({
      ID: dado.id,
      "Data Hora": converterDataHoraBr(dado.dataHora),
      Empresa: dado.empresa,
      Usuário: dado.nomeUsuario,
      Solicitados: dado.qtdSolicitados,
      Encontrados: dado.qtdEncontrados,
      "Não Encontrados":
        Number(dado.qtdSolicitados) - Number(dado.qtdEncontrados),
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 5 },
      { wch: 20 },
      { wch: 40 },
      { wch: 40 },
      { wch: 10 },
      { wch: 10 },
      { wch: 15 },
    ];

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, planilha);
    writeFileXLSX(workbook, "Solicitações-Download-Arquivos.xlsx");
  }

  return (
    <Table
      columns={columns}
      data={solicitacoes}
      keyField="id"
      loading={loading}
      exportExcel={true}
      handleExport={handleDownload}
      buttonExportVariant="secondary"
      rowsPerPage={limit}
      pagination={true}
      totalRows={totalRows}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleLimitChange}
    />
  );
}

