import { STYLES } from "../../style";
import imgSetas from "_assets/img/icon_arrow_right_left.png";
import imgPixBarcode from "_assets/img/pix_e_barcode.png";
import { Image, Text, View } from "@react-pdf/renderer";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";

interface Props {
    formaPgto: FormaPgtoEnum;
}
export function FormaPgtoPDF({
    formaPgto
}: Props) {
    return (
        <>
            {formaPgto === FormaPgtoEnum.PIXBOLETO
                ? <View style={STYLES.FORMA_PGTO_OPCAO_LABEL}>
                    <View style={STYLES.FORMA_PGTO_CONTAINER_ICONS}>
                        < Image src={imgPixBarcode} style={{
                            height: "16px !important",
                        }} />
                    </View>
                    <View>
                        <View style={STYLES.FORMA_PGTO_OPCAO_LABEL_TEXTO_PRINCIPAL}>
                            <Text>Pix ou boleto bancário</Text>
                        </View>
                        <View style={STYLES.FORMA_PGTO_OPCAO_LABEL_TEXTO_SECUNDARIO}>
                            <Text>Pix - pagamento confirmado em até 1 hora • Boleto bancário - pagamento confirmado em até 1 dia útil</Text>
                        </View>
                    </View>
                </View>
                : <View style={STYLES.FORMA_PGTO_OPCAO_LABEL}>
                    <View style={STYLES.FORMA_PGTO_CONTAINER_ICONS}>
                        <Image style={STYLES.ICON_TRANSFERENCIA} src={imgSetas} />
                    </View>
                    <View>
                        <View style={STYLES.FORMA_PGTO_OPCAO_LABEL_TEXTO_PRINCIPAL}>
                            <Text>Transferência bancária</Text>
                        </View>
                        <View style={STYLES.FORMA_PGTO_OPCAO_LABEL_TEXTO_SECUNDARIO}>
                            <Text>O pagamento é confirmado em até 1 dia útil.</Text>
                        </View>
                    </View>
                </View>
            }
        </>


    );
}