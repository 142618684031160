import { ModalCustom } from "components/ModalCustom";
import { ButtonsContainer, InputContainer, InputsWrapper, TextoClaro } from "./style";
import FileUpload from "components/UploadArquivos";
import { CustomDatePickerV2 } from "components/DatePickerV2";
import { PrimaryButton } from "components/Button/style";
import { useSolicitacaoPagamentos } from "features/debito/pagamento/hooks/useSolicitacaoPagamentos";
import { useState } from "react";
import { ModalConfirmacaoAdiantamentoRealizado } from "../ListaPagamentos/ModalConfirmacaoAdiantamentoRealizado";
import { LoadingCirculo } from "components/LoadingCirculo";

interface Props {
	show: boolean;
	handleClose: Callback;
	idSolicitacaoPagamento: number | string;
	buscarDados: () => void;
}

export function ModalInformarAdiantamento({
	handleClose,
	show,
	idSolicitacaoPagamento,
	buscarDados,
}: Readonly<Props>) {
	const [arquivo, setArquivo] = useState<File | null>(null);
	const [data, setData] = useState<Date | null>(null);
	const [showConfirmacaoAdiantamentoRealizado, setShowConfirmacaoAdiantamentoRealizado] = useState(false);

	const { informarPagamento, loading } = useSolicitacaoPagamentos();

	async function handleUploadComprovante() {
		if (!arquivo || !data) {
			return;
		}

		const { hasErro } = await informarPagamento(Number(idSolicitacaoPagamento), arquivo, data);

		if (hasErro) {
			fecharModalELimparState();
			return;
		}
		
		fecharModalELimparState();
		setShowConfirmacaoAdiantamentoRealizado(true);
	}

	function fecharModalELimparState() {
		handleClose();
		setArquivo(null);
		setData(null);
	}

	return (
		<>
			<ModalCustom
				show={show}
				handleClose={fecharModalELimparState}
				title="Informar pagamento de adiantamento"
				centered
				size="lg"
			>
				{loading ? (
					<LoadingCirculo mostrar={loading} />
				) : (
					<>
						<TextoClaro>Dados de pagamento</TextoClaro>
						<InputsWrapper>
							<InputContainer>
								<span>Data do pagamento</span>
								<CustomDatePickerV2 disableFutureDates selected={data} onChange={(e) => setData(e)} />
							</InputContainer>
							<InputContainer>
								<span>Comprovante de pagamento</span>
								<FileUpload
									onFileUpload={(file) => setArquivo(file)}
									inputAccept="image/jpeg,image/png,application/pdf"
								/>
							</InputContainer>
						</InputsWrapper>
						<ButtonsContainer>
							<PrimaryButton onClick={fecharModalELimparState} variante="primary" outline>
								Cancelar
							</PrimaryButton>
							<PrimaryButton disabled={!arquivo || !data} onClick={handleUploadComprovante}>
								Confirmar pagamento
							</PrimaryButton>
						</ButtonsContainer>
					</>
				)}
			</ModalCustom>
			<ModalConfirmacaoAdiantamentoRealizado
				show={showConfirmacaoAdiantamentoRealizado}
				handleClose={() => {
					setShowConfirmacaoAdiantamentoRealizado(false);
					buscarDados();
				}}
			/>
		</>
	);
}
