import { FaSpinner } from "react-icons/fa";
import { Container } from "./style";

export function Consultando() {
  return (
    <Container>
      <FaSpinner />
    </Container>
  );
}
