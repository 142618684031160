import { FaList } from "react-icons/fa";
import { ToggleContainer } from "./style";
import { Grid3x3GapFill } from "react-bootstrap-icons";

interface Props {
  visualizacaoAtual: "DETALHADOS" | "LOTES";
}

export function ToggleVisualizacaoExtratoVeicular({ visualizacaoAtual }: Readonly<Props>) {
  return (
    <ToggleContainer>
      {visualizacaoAtual === "DETALHADOS" ? (
        <div>
          <FaList /> Visualização unitária
        </div>
      ) : (
        <div>
          <Grid3x3GapFill /> Visualização em lote
        </div>
      )}
    </ToggleContainer>
  );
}

