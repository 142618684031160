import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const FormAlterarSenhaSchema = yup.object().shape({
  novaSenha: yup.string().required("Nova senha é obrigatório"),
  confirmaNovaSenha: yup
    .string()
    .required("Confirmar a nova senha é obrigatório")
    .test(
      "is-valid",
      (d) => "A confirmação de senha precisa ser igual ao campo nova senha",
      (value, schema) => {
        if (value !== schema.parent.novaSenha) {
          return false;
        }
        return true;
      }
    ),
});
