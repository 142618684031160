import styled from "styled-components";

export const ButtonHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

export const Id = styled.div`
  display: none;
`;

export const TooltipContemt = styled.div`
  color: #FFFFFF;
  background-color: #333333;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: start;
  font-size: 0.85rem;
`;

