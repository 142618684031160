import styled from "styled-components";

export const ContainerCentralizado = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const DivBackGround = styled.div`
  background-color: ${({ theme }) => theme.colors.project.bgColor};
  width: 32px;
  height: 32px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.project.main};
  margin-bottom: 16px;
`;

export const DivRelatorioPagamentos = styled.div`
    color: ${({ theme }) => theme.colors.neutral.neutral50};
    font-size: 14px;
`;

export const Link = styled.a`
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  cursor: pointer;

  &:hover {
        color: ${({ theme }) => theme.colors.neutral.neutral90};
    }
`;

export const DivCopiarPix = styled.div`
    margin: 24px;
    cursor: pointer;
    font-size: 15px;
    weight: 500;
`;