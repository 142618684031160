import React from "react";
import { ExportExcel, Search, Tools, ToolsHeaderTableContainer } from "./style";

interface ToolsExportProps {
  search?: any;
  exportExcel?: any;
  tools?: any;
}
function ToolsHeaderTable({ exportExcel, search, tools }: ToolsExportProps) {
  return (
    <ToolsHeaderTableContainer>
      <Tools>{tools}</Tools>
      {search && <Search>{search}</Search>}
      <ExportExcel>{exportExcel}</ExportExcel>
    </ToolsHeaderTableContainer>
  );
}
export { ToolsHeaderTable };

