import { Page } from "containers/Page";
import { ContainerBackground } from "./style";
import { FiltrosRelatorioExtratoVeicular } from "./containers/FiltrosRelatorioExtratoVeicular";
import { ListaRelatorioExtratoVeicular } from "./containers/ListaRelatorioExtratoVeicular";
import { useEffect, useState } from "react";
import { useRelatorioExtratoVeicular } from "../../hooks/useRelatorioExtratoVeicular";
import { useExtratoVeicular } from "features/extrato-veicular/hooks/useExtratoVeicular";

export function RelatorioExtratoVeicular() {

    const {
        buscarDados,
        dados,
        loadingDados,
        criarQueryParamsRelatorio,
        setLimit,
        setPage,
        limit,
        page,
        queryParams,
        totalRows,
        loadingDadosPlanilha,
        buscarDadosPlanilha,
        gerarDadosResumo,
        resumoExtratoVeicular,
        intervaloDatas,
        forceUpdate,
    } = useRelatorioExtratoVeicular();
    const { buscarCombosConsulta, combosConsulta } = useExtratoVeicular();

    useEffect(() => {
        buscarCombosConsulta();
    }, []);


    useEffect(() => {
        buscarDados();
        gerarDadosResumo();
    }, [limit, page, queryParams, buscarDados, gerarDadosResumo, forceUpdate]);

    useEffect(() => {
        isEllipsisActive()
    }, [dados]);

    function handlePageChange(page: number) {
        setPage(page);
    }

    function handleLimitChange(limit: number) {
        setLimit(limit);
    }
    async function handleSubmitFiltros(data: any) {
        criarQueryParamsRelatorio(data);
    }

    const [showVerTodos, setShowVerTodos] = useState<boolean>(false);
    const isEllipsisActive = () => {
        const element = document.getElementById('divIdsRecibo')
        if (!element) {
            return false
        }
        setShowVerTodos((element.offsetWidth < element.scrollWidth));
    }

    return (
        <Page title="Relatório Extrato veicular">
            <FiltrosRelatorioExtratoVeicular
                handleSubmitFiltros={handleSubmitFiltros}
                combosConsulta={combosConsulta}
            />
            <ContainerBackground>
                <ListaRelatorioExtratoVeicular
                    dados={dados}
                    loading={loadingDados}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleLimitChange}
                    totalRows={totalRows}
                    buscarDadosPlanilhaRelatorio={buscarDadosPlanilha}
                    loadingDadosPlanilha={loadingDadosPlanilha}
                    dadosResumo={resumoExtratoVeicular}
                    showVerTodos={showVerTodos}
                    idsRecibos={resumoExtratoVeicular.idsRecibo}
                    queryParams={queryParams}
                    intervaloDatas={intervaloDatas}
                    rowsPerPage={limit}
                />
            </ContainerBackground>
        </Page>
    );
}
