import { Controller, useForm } from "react-hook-form";
import { IFiltrosSolicitacaoPagamento } from "./interfaces/IFiltrosSolicitacaoPagamento";
import { Col, Form, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { filtrarSolicitacoesPagamentoSchema } from "features/debito/pagamento/validate/validator";
import { DropdownSelect } from "components/DropdownSelect";
import { CustomDatePicker } from "components/DatePicker";
import { FilterButton } from "components/Button/style";
import { FaFilter } from "react-icons/fa";
import { ButtonContainer, DatePickerContainer } from "./style";
import { Container } from "components/Container";
import { useEmpresas } from "hooks/useEmpresas";
import { useEffect } from "react";
import { HttpResponse } from "_services/api/HttpResponse";
import { HttpError } from "_services/api/HttpError";
import { IData } from "_services/api/interface/HttpResponse";
import { CustomInput } from "components/CustomInput";
import { useMaskInput } from "hooks/useMaskInput";
import { MaisFiltros } from "components/MaisFiltros";
import { useMaisFiltros } from "hooks/useMaisFiltros";
import { statusAdiantamentoOptions } from "./consts/statusAdiantamentoOptions";

interface IFiltrarSolicitacaoPagamentoProps {
	listarSolicitacoesPagamento: (queryParams?: string) => Promise<HttpResponse<any, any> | HttpError<IData, any>>;
	handleSubmitFilters: (data: IFiltrosSolicitacaoPagamento) => void;
}

export function FiltrarSolicitacaoPagamento({
	listarSolicitacoesPagamento,
	handleSubmitFilters,
}: Readonly<IFiltrarSolicitacaoPagamentoProps>) {
	const { handleAplicarMaisFiltros, handleFormSubmit, handleNaoUsarMaisFiltros } = useMaisFiltros();

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors, isValid, isSubmitted },
	} = useForm<IFiltrosSolicitacaoPagamento>({
		resolver: yupResolver(filtrarSolicitacoesPagamentoSchema),
	});
	const { mInputPlaca } = useMaskInput();

	const { agrupamentoOptions, criarOptionsAgrupamento } = useEmpresas();

	useEffect(() => {
		criarOptionsAgrupamento();
	}, [criarOptionsAgrupamento]);

	const orderBy = [
		{
			value: "dataHora",
			label: "Data da solicitação",
		},
		{
			value: "dataMenorPrazoPagamento",
			label: "Prazo para pagamento",
		},
	];

	return (
		<Container titulo="Filtros">
			<Form
				noValidate
				onSubmit={handleSubmit((dados) => {
					handleFormSubmit({
						dados,
						excluir: ["sort", "renavam", "empresa", "prazoPagamento"],
						resetCallback: reset,
						submitCallback: handleSubmitFilters,
					});
				})}
			>
				<Row>
					<Col lg="2">
						<Form.Group>
							<Form.Label>ID Pagamento</Form.Label>
							<Controller
								control={control}
								name="idSolicitacaoPagamento"
								render={({ field: { onChange, value } }) => (
									<CustomInput
										type="text"
										placeholder="Busque por id"
										value={value ?? ""}
										onChange={(e: any) => {
											onChange(e);
										}}
										mensagemErro={errors.idSolicitacaoPagamento?.message}
										isValid={isSubmitted && isValid && (value ? value.length >= 1 : false)}
										isInvalid={!!errors.idSolicitacaoPagamento}
									/>
								)}
							/>
						</Form.Group>
					</Col>
					<Col lg="2">
						<Form.Group>
							<Form.Label>Placa</Form.Label>
							<Controller
								control={control}
								name="placa"
								render={({ field: { onChange, value } }) => (
									<CustomInput
										type="text"
										placeholder="Digite a placa"
										value={value ?? ""}
										onChange={(e: any) => {
											onChange(mInputPlaca(e).replace(/-/g, ""));
										}}
										mensagemErro={errors.placa?.message}
										isValid={isSubmitted && isValid && (value ? value.length >= 1 : false)}
										isInvalid={!!errors.placa}
									/>
								)}
							/>
						</Form.Group>
					</Col>
					<Col lg="2">
						<Form.Group>
							<Form.Label>ID Consulta</Form.Label>
							<Controller
								control={control}
								name="idConsulta"
								render={({ field: { onChange, value } }) => (
									<CustomInput
										type="text"
										placeholder="Busque por id"
										value={value ?? ""}
										onChange={(e: any) => {
											onChange(e);
										}}
										mensagemErro={errors.idConsulta?.message}
										isValid={isSubmitted && isValid && (value ? value.length >= 1 : false)}
										isInvalid={!!errors.idConsulta}
									/>
								)}
							/>
						</Form.Group>
					</Col>
					<Col lg="2">
						<Form.Group>
							<Controller
								control={control}
								name="statusAdiantamento"
								render={({ field: { onChange, value } }) => (
									<DropdownSelect
										options={statusAdiantamentoOptions}
										title="Status adiantamento"
										selectedValue={value}
										onSelect={onChange}
									/>
								)}
							/>
						</Form.Group>
					</Col>
					<Col lg="2">
						<ButtonContainer>
							<FilterButton type="submit" disabled={false} loading={false}>
								<FaFilter /> Filtrar
							</FilterButton>
							<FilterButton
								onClick={() =>
									reset({
										empresa: null,
										placa: null,
										prazoPagamento: null,
										renavam: null,
										sort: null,
										idConsulta: null,
										idSolicitacaoPagamento: null,
										statusAdiantamento: null,
									})
								}
								disabled={false}
								loading={false}
								outline={true}
							>
								Limpar
							</FilterButton>
						</ButtonContainer>
					</Col>
					<Col lg="2">
						<MaisFiltros
							cancelarOnClick={() => {
								handleNaoUsarMaisFiltros({
									excluir: ["sort", "renavam", "empresa", "prazoPagamento"],
									resetCallback: reset,
								});
							}}
							handleClickAplicarFiltros={handleAplicarMaisFiltros}
							limparTudoOnClick={() => {
								handleNaoUsarMaisFiltros({
									excluir: ["sort", "renavam", "empresa", "prazoPagamento"],
									resetCallback: reset,
								});
							}}
							widthModal="md"
						>
							{agrupamentoOptions?.length ? (
								<Form.Group>
									<Controller
										control={control}
										name="empresa"
										render={({ field: { onChange, value } }) => (
											<DropdownSelect
												options={agrupamentoOptions}
												title="Empresa"
												searchable
												selectedValue={value}
												onSelect={onChange}
											/>
										)}
									/>
								</Form.Group>
							) : null}
							<Form.Group>
								<Controller
									control={control}
									name="prazoPagamento"
									render={({ field: { onChange, value } }) => (
										<DatePickerContainer>
											<span>Prazo para pagamento</span>
											<CustomDatePicker selected={value} onChange={onChange} />
										</DatePickerContainer>
									)}
								/>
							</Form.Group>
							<Form.Group>
								<Controller
									control={control}
									name="sort"
									render={({ field: { onChange, value } }) => (
										<DropdownSelect selectedValue={value} options={orderBy} title="Ordenar" onSelect={onChange} />
									)}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Renavam</Form.Label>
								<Controller
									control={control}
									name="renavam"
									render={({ field: { onChange, value } }) => (
										<CustomInput
											type="text"
											placeholder="Digite o renavam"
											value={value ?? ""}
											onChange={(e: any) => {
												onChange(e);
											}}
											mensagemErro={errors.renavam?.message}
											isValid={isSubmitted && isValid && (value ? value.length >= 1 : false)}
											isInvalid={!!errors.renavam}
										/>
									)}
								/>
							</Form.Group>
						</MaisFiltros>
					</Col>
				</Row>
			</Form>
		</Container>
	);
}
