import { mergeArquivosParaPdf } from "_utils/pdf/MergeArquivosParaPdf";
import { PdfViewer } from "components/PdfViewer";
import { useEffect, useState } from "react";

interface Props {
	comprovantes: string[];
	show: boolean;
	handleClose: Callback;
}

export function ModalComprovantes({ comprovantes, handleClose, show }: Readonly<Props>) {
	const [pdfMerge, setPdfMerge] = useState<Blob>();

	useEffect(() => {
		if (show) {
			async function mergePdfs() {
				const merge = await mergeArquivosParaPdf(comprovantes);
				setPdfMerge(merge);
			}

			mergePdfs();
		}
	}, [comprovantes, show]);

	return <PdfViewer pdf={pdfMerge!} show={show} handleClose={handleClose} />;
}
