import { Required } from "components/Required";
import { InformacaoPagamentos } from "features/debito/pagamento/pages/Detalhes/containers/InformacaoPagamentos";
import { Form } from "react-bootstrap";
import { Container, Divider, Feedback, Titulo } from "./style";

interface Props {
  todos: boolean;
  showFeedback:boolean;
  getMotivoRecusa:any;
  quantidadeDebitosSelecionados: number;
}
export function RecusarDebitos({
  quantidadeDebitosSelecionados,
  todos,
  showFeedback,
  getMotivoRecusa,
}: Props) {

  const setMotivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    getMotivoRecusa(event.target.value);
  }

  const mensagemTodos = "Está ação recusará o pagamento de todos os débitos retornados pelo filtro";

  const mensagemSelecionados = `Solicitações selecionadas: ${quantidadeDebitosSelecionados}`;

  return (
    <Container>
      <Titulo>Deseja recusar {todos ? "todos os débitos" : "os débitos selecionados"}?</Titulo>
      <InformacaoPagamentos
        mensagem={todos ? mensagemTodos : mensagemSelecionados}
      />
      <Divider />
      <Form.Group className="my-3">
        <Form.Label>Motivo <Required /></Form.Label>
        <Form.Control
          type="text"
          id="motivo"
          name="motivo"
          placeholder="Digite o motivo de recusa"
          className="bg-white"
          onChange={setMotivo}
        />
        <Feedback id="feedbackMotivo" show={showFeedback}>Motivo de recusa obrigatório</Feedback>
      </Form.Group>
    </Container>
  );
}
