import { useMask } from "./useMask";

export function useMaskInput() {
  const {
    mNum,
    mCEP,
    mCPF,
    mCNPJ,
    mCPFCNPJ,
    mTel,
    mCoin,
    mUF,
    mNumeroRomanos,
    mSite,
    mDate,
    mTime,
    mDateTime,
    mArea,
    mPlaca,
    mDinheiro,
    mPlacaV2,
    mDateRange,
  } = useMask();

  function mInputTel(event: any): any {
    let valor = event.target.value;

    if (valor.length >= 16) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mTel(valor);
    }
    event.target.value = valor;
    return valor;
  }

  function mInputCNPJ(event: any): any {
    let valor = event.target.value;

    if (valor.length >= 19) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mCNPJ(valor);
    }
    event.target.value = valor;
    return valor;
  }

  function mInputCPFCNPJ(event: any): any {
    let valor = event.target.value;

    if (valor.length >= 19) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mCPFCNPJ(valor);
    }
    event.target.value = valor;
    return valor;
  }

  function mInputCPF(event: any): any {
    let valor = event.target.value;

    if (valor.length >= 15) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mCPF(valor);
    }
    event.target.value = valor;
    return valor;
  }

  function mInputCEP(event: any): any {
    let valor = event.target.value;

    if (valor.length >= 10) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mCEP(valor);
    }
    event.target.value = valor;
    return valor;
  }

  function mInputNum(event: any): any {
    let valor = event.target.value;

    valor = mNum(valor);

    event.target.value = valor;
    return valor;
  }

  function mInputUF(event: any): any {
    let valor = event.target.value;

    if (valor.length >= 3) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mUF(valor);
    }
    event.target.value = valor;
    return valor;
  }

  function mInputCoin(event: any): any {
    let valor = event.target.value;

    valor = mCoin(valor);

    event.target.value = valor;
    return valor;
  }

  function mInputNumeroRomanos(event: any): any {
    let valor = event.target.value;

    valor = mNumeroRomanos(valor);

    event.target.value = valor;
    return valor;
  }

  function mInputSite(event: any): any {
    let valor = event.target.value;

    valor = mSite(valor);

    event.target.value = valor;
    return valor;
  }

  function mInputDate(event: any): any {
    let valor = event.target.value;

    if (valor.length >= 11) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mDate(valor);
    }
    event.target.value = valor;
    return valor;
  }

  function mInputTime(event: any): any {
    let valor = event.target.value;
    if (valor.length >= 9) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mTime(valor);
    }
    event.target.value = valor;
    return valor;
  }

  function mInputDateTime(event: any): any {
    let valor = event.target.value;
    if (valor.length >= 20) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mDateTime(valor);
    }
    event.target.value = valor;
    return valor;
  }

  function mInputArea(event: any): any {
    let valor = event.target.value;

    valor = mArea(valor);

    event.target.value = valor;
    return valor;
  }

  function mInputPlaca(event: any): any {
    let valor = event.target.value;
    valor = valor.toUpperCase()
    
    if (valor.length >= 9) {
      valor = valor.substring(0, valor.length - 1);
    }

    const regexPlacaMercosulCarro = /^[a-zA-Z]{3}\d[a-zA-Z]\d{2}/g;
    if (valor.length >= 8 && regexPlacaMercosulCarro.test(valor)) {
      valor = valor.substring(0, valor.length - 1);
    } else {
      valor = mPlaca(valor);
    }

    event.target.value = valor;
    return valor;
  }

  function mInputPlacaV2(event: any) {
    let valor = event.target.value;
    
    valor = mPlacaV2(valor)
  
    event.target.value = valor;    
    return valor
  }

  function mInputRenavam(event: any): any {
    let valor = event.target.value;
    valor = valor.replace(/\D/g, "");
    
    if (valor.length >= 12) {
      valor = valor.substring(0, valor.length - 1);
    }

    event.target.value = valor;
    return valor;
  }

  function mInputChassi(event: any): any {
    let valor = event.target.value;
    valor = valor.toUpperCase()
    
    if (valor.length >= 18) {
      valor = valor.substring(0, valor.length - 1);
    }

    event.target.value = valor;
    return valor;
  }

  function mInputCRV(event: any): any {
    let valor = event.target.value;
    valor = valor.replace(/\D/g, "");
    
    if (valor.length > 12) {
      valor = valor.substring(0, valor.length - 1);
    }

    event.target.value = valor;
    return valor;
  }

  function mDinheiroInput(event: any) {
    let valor = event.target.value;
    valor = mDinheiro(valor)
  
    event.target.value = valor;    
    return valor
  }

  function mDataRangeInput(event: any) {
    let valor = event.target.value;

    if (event.keyCode === 8 && valor.length > 0) {
      valor = valor.slice(0, -1);
      return valor;
    }
    valor = mDateRange(valor);

    event.target.value = valor;
    return valor;
  }

  return {
    mDataRangeInput,
    mDinheiroInput,
    mInputNum,
    mInputCEP,
    mInputCPF,
    mInputCNPJ,
    mInputCPFCNPJ,
    mInputTel,
    mInputCoin,
    mInputUF,
    mInputNumeroRomanos,
    mInputSite,
    mInputDate,
    mInputTime,
    mInputDateTime,
    mInputArea,
    mInputPlaca,
    mInputRenavam,
    mInputChassi,
    mInputCRV,
    mInputPlacaV2,
  };
}
