import { PrimaryButton } from "components/Button/style";
import { ModalCustom } from "components/ModalCustom";
import { UploadContext } from "contexts/UploadContext";
import { useContext } from "react";
import { BodyContainer, ButtonContainer, LoadingContainer } from "./style";
import { BodyParcial } from "./containers/BodyParcial";
import { BodyTotal } from "./containers/BodyTotal";
import { LoadingCirculo } from "components/LoadingCirculo";

interface Props {
  show: boolean;
  handleClose: () => void;
  avisos: any;
  arquivoAtual: null | string;
  loading?: boolean;
}

export function ModalAvisosPlanilha({
  avisos,
  handleClose,
  show,
  arquivoAtual,
  loading = false,
}: Readonly<Props>) {
  const { aviso, setShowModalAvisos, handleReconsultar } =
    useContext(UploadContext);

  return (
    <ModalCustom
      size="lg"
      handleClose={handleClose}
      show={show}
      title="Placas já consultadas"
      footer
      centered
    >
      {loading ? (
        <LoadingContainer>
          <LoadingCirculo mostrar={loading} />
        </LoadingContainer>
      ) : (
        <div>
          <BodyContainer>
            {aviso === "parcial" ? (
              <BodyParcial dados={avisos?.dados?.dados?.jaConsultados} />
            ) : (
              <BodyTotal nomeArquivo={arquivoAtual!} />
            )}
          </BodyContainer>
          <ButtonContainer>
            <PrimaryButton
              onClick={() => {
                handleReconsultar({ ...avisos?.dados, nomeArquivo: arquivoAtual });
              }}
              outline
              variante="primary"
            >
              Consultar novamente
            </PrimaryButton>
            <PrimaryButton onClick={() => setShowModalAvisos(false)}>
              Não consultar
            </PrimaryButton>
          </ButtonContainer>
        </div>
      )}

    </ModalCustom>
  );
}

