import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  gap: 1rem;
  width: 100%;
`

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

export const SelectContainer = styled.div`
  width: 70%;
`;

export const RadioContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  div {
    display: flex;
    gap: 1.5rem;

    div {
      display: flex;
      gap: 0.5rem;

      label {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.neutral.neutral60};
      }

      input {
        width: 2.2rem;
        cursor: pointer;
      }
    }
  }
`;

export const SpanErro = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.danger.main}
`;