import { Col, Row } from "react-bootstrap";

interface Props {
    quantidadeLinhas: number;
    quantidadeColunas: number;
    limiteQuantidadeLinhas: number;
    dados: any[];
}

export function PreencherTabelaVertical({
    quantidadeLinhas,
    quantidadeColunas,
    limiteQuantidadeLinhas,
    dados
}: Props) {
    const gerarLinhasTabela = () => {
        const arrayLinhas = [];
        for (let linha = 0; linha < quantidadeLinhas; linha++) {
            arrayLinhas.push(<Row>
                {
                    gerarColunasTabela(linha).map(item => {
                        return item
                    })
                }
            </Row>);
        }
        return arrayLinhas
    }

    const gerarColunasTabela = (linha: number) => {
        const arrayColunas = [];
        for (let coluna = 0; coluna < quantidadeColunas; coluna++) {
            arrayColunas.push(
                <Col className={"text-center"}>{dados[linha + limiteQuantidadeLinhas * coluna] ?? ""}</Col>
            );
        }
        return arrayColunas
    }

    return (
        <div>
            {
                gerarLinhasTabela().map(item => {
                    return item
                })
            }
        </div>
    );
}
