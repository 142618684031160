import { Text, View } from "@react-pdf/renderer";
import { IAutuacoes, IMultas } from "../../../DadosMultas";
import { STYLES } from "../../style";
import { intlCurrencyFormat } from "_utils/Money";
import { RowMultasPDF } from "./containers/RowMultas";

interface Props {
  total: number;
  multas: IMultas | null;
  autuacoes: IAutuacoes | null;
  totalAutuacoes: number;
}

export function DadosMultasPDF({ multas, autuacoes, totalAutuacoes, total }: Readonly<Props>) {
  function handleNaoExisteDebitos(dados: any) {
    const temDebitos = Object.keys(dados).some((key) => Array.isArray(dados[key]) && dados[key].length > 0);

    return temDebitos;
  }

  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Multas/Autuações</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Multas</Text>
            <Text style={STYLES.FONT_SIZE_09}>
              {handleNaoExisteDebitos(multas) ? "Existe débito de multas" : "Não existe débito de multas"}
            </Text>
          </View>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor total de multas</Text>
            <Text style={STYLES.FONT_SIZE_09}>{total > 0 ? intlCurrencyFormat(total) : "-"}</Text>
          </View>
        </View>
        {multas && handleNaoExisteDebitos(multas) && (
          <>
            <View style={STYLES.SECTION_ROW}>
              <View style={STYLES.SECTION_COL_2}>
                <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Descrição</Text>
              </View>
              <View style={STYLES.SECTION_COL_4}>
                <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Quantidade</Text>
              </View>
              <View style={STYLES.SECTION_COL_4}>
                <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor total</Text>
              </View>
            </View>
            <RowMultasPDF
              dadosMultas={multas.processoderecerusoobrigatorias}
              textoRow="Multas em processo de recurso obrigatórias"
            />
            <RowMultasPDF
              dadosMultas={multas.dividaativaexecjudicialsobjudice}
              textoRow="Multas em dívida ativa/exec judicial/sob judice"
            />
            <RowMultasPDF dadosMultas={multas.obrigatorias} textoRow="Multas obrigatórias" />
            <RowMultasPDF
              dadosMultas={multas.processoderecerusonaoobrigatorias}
              textoRow="Multas em processo de recurso não obrigatórias"
            />
            <RowMultasPDF dadosMultas={multas.impostas} textoRow="Multas impostas" />
          </>
        )}
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Autuações</Text>
            <Text style={STYLES.FONT_SIZE_09}>
              {handleNaoExisteDebitos(autuacoes) ? "Existe débito de autuações" : "Não existe débito de autuações"}
            </Text>
          </View>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor total de autuações</Text>
            <Text style={STYLES.FONT_SIZE_09}>{totalAutuacoes > 0 ? intlCurrencyFormat(totalAutuacoes) : "-"}</Text>
          </View>
        </View>
        {autuacoes && handleNaoExisteDebitos(autuacoes) && (
          <>
            <View style={[{ marginTop: "4" }, STYLES.SECTION_ROW]}>
              <View style={STYLES.SECTION_COL_2}>
                <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Descrição</Text>
              </View>
              <View style={STYLES.SECTION_COL_4}>
                <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Quantidade</Text>
              </View>
              <View style={STYLES.SECTION_COL_4}>
                <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor total</Text>
              </View>
            </View>
            <RowMultasPDF dadosMultas={autuacoes.notificadas} textoRow="Autuações notificadas" />
            <RowMultasPDF dadosMultas={autuacoes.processodedefesa} textoRow="Autuações em processo de defesa" />
            <RowMultasPDF
              dadosMultas={autuacoes.atuacaoemprocessodenotificacao}
              textoRow="Autuações em processo de notificação"
            />
          </>
        )}
      </View>
    </View>
  );
}

