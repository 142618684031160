import { Col, Row } from "react-bootstrap";
import { IDadosResumoRelatorioExtratoVeicular, IIntervaloDatas } from "features/relatorios/extratoVeicular/interfaces/IDadosResumoRelatorioExtratoVeicular";
import { formatarDinheiro } from "_utils/Money";
import { BodyDadoResumo, ContainerBackground, LinkVerTodos, TituloDadoResumo } from "./style";
import { JSXElementConstructor } from "react";

interface IResumoRelatorioExtratoVeicular {
    dadosResumo: IDadosResumoRelatorioExtratoVeicular;
    intervaloDatas: IIntervaloDatas;
    onClickVerTodos: () => void;
    showVerTodos: boolean;
}

export function ResumoRelatorioExtratoVeicular({
    dadosResumo,
    onClickVerTodos,
    showVerTodos,
    intervaloDatas,
}: IResumoRelatorioExtratoVeicular) {

    return (
        <ContainerBackground>
            <Row>
                <Col lg="3">
                    <TituloDadoResumo>Total de consultas</TituloDadoResumo>
                    <BodyDadoResumo>{dadosResumo.totalConsultas ? dadosResumo.totalConsultas : "- -"}</BodyDadoResumo>
                </Col>
                <Col lg="3">
                    <TituloDadoResumo>Período</TituloDadoResumo>
                    <BodyDadoResumo>{
                        intervaloDatas.dataInicial || intervaloDatas.dataFinal
                            ? `${intervaloDatas.dataInicial ?? ""}${intervaloDatas.dataInicial && intervaloDatas.dataFinal ? " à " : ""}${intervaloDatas.dataFinal ?? ""}`
                            : (dadosResumo.dataInicio || dadosResumo.dataFim
                                ? `${dadosResumo.dataInicio ?? ""}${dadosResumo.dataInicio && dadosResumo.dataFim && dadosResumo.dataInicio !== dadosResumo.dataFim ? " à " : ""}${dadosResumo.dataFim && dadosResumo.dataInicio !== dadosResumo.dataFim ? dadosResumo.dataFim : ""}`
                                : "- -")
                    }</BodyDadoResumo>
                </Col>
                <Col lg="3">
                    <TituloDadoResumo>Valor total</TituloDadoResumo>
                    <BodyDadoResumo>{dadosResumo.valor ? formatarDinheiro(dadosResumo.valor / 100) : "- -"}</BodyDadoResumo>
                </Col>
                <Col lg="3">
                    <TituloDadoResumo>
                        <span>ID Recibo</span>
                        <LinkVerTodos
                            onClick={onClickVerTodos}
                            show={showVerTodos}
                        >Ver todos</LinkVerTodos>
                    </TituloDadoResumo>
                    <BodyDadoResumo
                        naoQuebrarLinha
                        width={"80%"}
                        id={"divIdsRecibo"}
                    >{dadosResumo.idsRecibo.length ? dadosResumo.idsRecibo.join(", ") : "- -"}</BodyDadoResumo>
                </Col>
            </Row>
        </ContainerBackground>
    );
}
