import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  span {
    font-size: 14px;
  }

  strong {
    font-size: 14px;
  }
`;

export const IconeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary.bgColor};

  svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

