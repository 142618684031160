import { yupResolver } from "@hookform/resolvers/yup";
import { CustomInput } from "components/CustomInput";
import { CustomDatePickerV2 } from "components/DatePickerV2";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { emissaoManual } from "features/licenciamento/emissao/validate/validator";
import { Required } from "components/Required";
import FileUpload from "components/UploadArquivos";
import { IEmissaoManual } from "../../../../interfaces/IEmissaoManual";
import { PrimaryButton } from "components/Button/style";
import { useEffect } from "react";
import { ContainerTextForm } from "../style";

interface Props {
    handleSubmitEmissaoManual: (dados: IEmissaoManual) => void;
    ano: number | string;
    handleClose: () => void;
    dataEmissao: Date;
}

export function BodyEmissaoManual({
    handleSubmitEmissaoManual,
    ano,
    handleClose,
    dataEmissao
}: Props) {

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors, isSubmitted },
    } = useForm<IEmissaoManual>({
        resolver: yupResolver(emissaoManual),
    });

    useEffect(() => {
        setValue("dataEmissao", new Date())
        setValue("exercicio", ano)
    }, [dataEmissao])

    return (
        <form onSubmit={handleSubmit((dados) => handleSubmitEmissaoManual(dados))} id="formEmissaoManual">
            <ContainerTextForm>Dados da emissão do CRLV</ContainerTextForm>
            <Row>
                <Col md="3">
                    <Form.Group>
                        <Form.Label>Data de emissão</Form.Label>
                        <Controller
                            control={control}
                            name="dataEmissao"
                            render={({ field: { onChange, value } }) => (
                                <CustomDatePickerV2
                                    onChange={onChange}
                                    selected={value}
                                    placeholder="Selecione a data"
                                    mensagemErro={errors.dataEmissao?.message}
                                    isValid={isSubmitted && !errors.dataEmissao}
                                    isInvalid={!!errors.dataEmissao}
                                    isClearable={false}
                                />
                            )}
                        />
                    </Form.Group>
                </Col>
                <Col md="2">
                    <Form.Group>
                        <Form.Label>Exercício</Form.Label>
                        <CustomInput
                            type="text"
                            {...register("exercicio")}
                            isValid={isSubmitted && !errors.exercicio}
                            isInvalid={!!errors.exercicio}
                            iconeDireito={false}
                            iconeEsquerdo={false}
                            value={ano}
                            disabled
                            mensagemErro={errors.exercicio?.message}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <div className="arquivoContainer">
                        <span>
                            Arquivo
                        </span>
                        <Controller
                            control={control}
                            name="arquivo"
                            render={({ field: { onChange } }) => (
                                <FileUpload 
                                maxWidth="436px" 
                                onFileUpload={(file) => onChange(file)} 
                                mensagemErro={errors?.arquivo?.message as string} 
                                inputAccept=".jpg, .jpeg, .png, .pdf"
                                />
                            )}
                        />
                    </div>
                </Col>
            </Row>
            <div className="d-flex justify-content-end mt-4">
                <PrimaryButton type="button" className="me-2" outline variante="primary" onClick={handleClose}>
                    Cancelar
                </PrimaryButton>
                <PrimaryButton type="submit">Confirmar emissão</PrimaryButton>
            </div>
        </form>
    );
}
