import { FaInfoCircle } from "react-icons/fa";
import { StatusDebitoPagamento } from "../../Enum/StatusDebitoPagamento";
import { Container } from "./style";

interface IconeInfoProps {
  status: string;
  onClick: () => void;
}

export function IconeInfo({ status, onClick }: IconeInfoProps) {
  const habilitado: any = ["Indeferido", "Erro", "Recusado"];

  return (
    <Container status={status}>
      {habilitado.includes(StatusDebitoPagamento[Number(status)]) ? (
        <FaInfoCircle onClick={onClick} />
      ) : (
        <FaInfoCircle onClick={() => {}} />
      )}
    </Container>
  );
}
