import { converterDataHoraBr, formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { LoadingScreen } from "components/LoadingScreen";
import { toastErro } from "components/Toast";
import { IRelatorioPagamentos } from "features/relatorios/pagamento/interfaces/RelatorioPagamento";
import { useCallback, useContext, useState } from "react";
import { utils, writeFileXLSX } from "xlsx";
import { Container, Divider, Title } from "./style";
import { IRelatorioExtratoVeicular } from "features/relatorios/extratoVeicular/interfaces/IRelatorioExtratoVeicular";
import { TableRelatorioExtratoVeicular } from "./TableRelatorioExtratoVeicular";
import { ResumoRelatorioExtratoVeicular } from "./ResumoRelatorioExtratoVeicular";
import { IDadosResumoRelatorioExtratoVeicular, IIntervaloDatas } from "features/relatorios/extratoVeicular/interfaces/IDadosResumoRelatorioExtratoVeicular";
import { ModalIdsRecibo } from "./ModalIdsRecibo";
import { ContextPermissao } from "hooks/ContextPermissao";

interface IListaRelatorioExtratoVeicularProps {
    dados: IRelatorioPagamentos[];
    loading: boolean;
    onChangePage: (page: number) => void;
    onChangeRowsPerPage: (limit: number) => void;
    totalRows: number;
    buscarDadosPlanilhaRelatorio(): any;
    loadingDadosPlanilha: boolean;
    dadosResumo: IDadosResumoRelatorioExtratoVeicular;
    showVerTodos: boolean;
    idsRecibos: number[];
    queryParams: NullableString;
    intervaloDatas: IIntervaloDatas;
    rowsPerPage?:number
}

export function ListaRelatorioExtratoVeicular({
    dados,
    loading,
    onChangePage,
    onChangeRowsPerPage,
    totalRows,
    buscarDadosPlanilhaRelatorio,
    loadingDadosPlanilha,
    dadosResumo,
    showVerTodos,
    idsRecibos,
    queryParams,
    intervaloDatas,
    rowsPerPage,
}: IListaRelatorioExtratoVeicularProps) {
    const { verificarEmpresa } = useContext(ContextPermissao);
    const usuarioLW = verificarEmpresa();
    const handleExport = useCallback(async () => {
        const { data, hasErro } = await buscarDadosPlanilhaRelatorio();

        if (hasErro) {
            toastErro(data.message);
            return;
        }

        const dadosPlanilha = data.map((item: IRelatorioExtratoVeicular) => {
            const dados: any = {
                "Placa": item.placa,
                "Renavam": item.renavam,
                "Chassi": item.chassi,
                "Data/Hora da Consulta": item.dataHoraConsulta ? converterDataHoraBr(item.dataHoraConsulta) : "--",
                "Empresa": item.nomeCliente && item.nomeCliente !== "" ? item.nomeCliente : "--",
                "Usuário": item.nomeUsuario ?? "--",
                "Tipo de consulta": item.tipoConsulta ?? "--",
                "ID Consulta": item.idConsulta ?? "--",
                "ID Recibo": item.idRecibo ?? "--",
                "Valor": item.valor ? item.valor / 100 : "--",
                "Isenção de Cobrança": item.motivoIsencao && item.motivoIsencao !== "null" ? item.motivoIsencao.replaceAll("_", " ") : "--",
            }

            if (!usuarioLW) {
                delete dados["Isenção de Cobrança"];
            }

            return dados;
        });

        const planilha = utils.json_to_sheet(dadosPlanilha);
        planilha["!cols"] = [
            { wch: 10 },
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 25 },
            { wch: 15 },
        ];

        if (usuarioLW) {
            planilha["!cols"].push({ wch: 25 });
        }
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, planilha, "Relatório Extrato Veicular");
        writeFileXLSX(workBook, "relatórios-extrato-veicular.xlsx");
    }, [buscarDadosPlanilhaRelatorio]);

    const [showModalRecibos, setShowModalRecibos] = useState<boolean>(false);
    const fecharModalRecibos = () => {
        setShowModalRecibos(false);
    }
    const abrirModalRecibos = () => {
        setShowModalRecibos(true);
    }

    return (
        <Container>
            <Title>Relatório</Title>
            <Divider />
            <ResumoRelatorioExtratoVeicular
                dadosResumo={dadosResumo}
                onClickVerTodos={abrirModalRecibos}
                showVerTodos={showVerTodos}
                intervaloDatas={intervaloDatas}
            />
            <TableRelatorioExtratoVeicular
                handleExport={handleExport}
                dados={dados}
                loading={loading}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                totalRows={totalRows}
                queryParams={queryParams}
                rowsPerPage={rowsPerPage}
            />
            <ModalIdsRecibo
                show={showModalRecibos}
                handleClose={fecharModalRecibos}
                idsRecibos={idsRecibos}
            />
            <LoadingScreen mostrar={loadingDadosPlanilha} />
        </Container>
    );
}
