import { ModalCustom } from "components/ModalCustom";
import { Container, DadosContainer, ResumoContainer, TextoNegrito } from "./style";
import { TextoClaro } from "components/TextoClaro";
import { Col, Row } from "react-bootstrap";
import { useListarExtratoVeicular } from "features/extrato-veicular/consultar-veiculos/pages/Listar/hooks/useListarExtratoVeicular";
import { useCallback, useEffect, useState } from "react";
import { LoadingCirculo } from "components/LoadingCirculo";
import { converterDataHoraBr } from "_utils/Data";

export function ModalResumoLote({
  show,
  handleClose,
  idLoteExtratoVeicular,
}: Readonly<{
  show: boolean;
  handleClose: () => void;
  idLoteExtratoVeicular: number;
}>) {
  const { buscarResumoExtratoVeicular, loading } = useListarExtratoVeicular();
  const [dadosResumo, setDadosResumo] = useState<any>(null);

  const handleBuscarResumo = useCallback(async () => {
    const { data, hasErro } = await buscarResumoExtratoVeicular(idLoteExtratoVeicular);

    if (hasErro) {
      handleClose();
      return;
    }

    setDadosResumo(data);
  }, [idLoteExtratoVeicular, buscarResumoExtratoVeicular, handleClose]);

  useEffect(() => {
    if (show) {
      handleBuscarResumo();
    }
  }, [handleBuscarResumo, show]);

  return (
    <ModalCustom show={show} handleClose={handleClose} title="Extrato veicular" centered size="lg">
      <Container>
        <TextoClaro>Dados da consulta</TextoClaro>
        {loading ? (
          <LoadingCirculo mostrar={loading} />
        ) : (
          <ResumoContainer>
            <Row>
              <Col>
                <DadosContainer>
                  <span>ID Consulta</span>
                  <TextoNegrito>{dadosResumo?.id ?? "-"}</TextoNegrito>
                </DadosContainer>
              </Col>
              <Col>
                <DadosContainer>
                  <span>Tipo de consulta</span>
                  <TextoNegrito>{dadosResumo?.nomeCombo ?? "-"}</TextoNegrito>
                </DadosContainer>
              </Col>
              <Col>
                <DadosContainer>
                  <span>Data / hora solicitação</span>
                  <TextoNegrito>{converterDataHoraBr(dadosResumo?.dataHora) ?? "-"}</TextoNegrito>
                </DadosContainer>
              </Col>
            </Row>
            <Row>
              <Col>
                <DadosContainer>
                  <span>Solicitante</span>
                  <TextoNegrito>{dadosResumo?.nomeEmpresa ?? "-"}</TextoNegrito>
                </DadosContainer>
              </Col>
              <Col>
                <DadosContainer>
                  <span>Empresa</span>
                  <TextoNegrito>{dadosResumo?.nomeCliente ?? "-"}</TextoNegrito>
                </DadosContainer>
              </Col>
              <Col>
                <DadosContainer>
                  <span>Usuário</span>
                  <TextoNegrito>{dadosResumo?.nomeUsuario ?? "-"}</TextoNegrito>
                </DadosContainer>
              </Col>
            </Row>
          </ResumoContainer>
        )}
      </Container>
    </ModalCustom>
  );
}

