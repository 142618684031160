import styled from "styled-components";

export const Container = styled.div`
  * {
    font-family: "Roboto", sans-serif;
  }

  a {
    text-decoration: none;
  }

  .nav {
    font-size: 16px;
    font-weight: 400;

    svg {
      font-size: 16px;
    }
  }

  .nav > li > a {
    color: ${({ theme }) => theme.colors.colorTextMenu};
    padding: 14px 20px 14px 25px;
    display: block;
  }
  .nav.metismenu > li {
    display: block;
    width: 100%;
    position: relative;
    background: ${({ theme }) => theme.colors.textLight};
    cursor: pointer;
  }
  .nav.metismenu .dropdown-menu > li > a {
    padding: 3px 20px;
    display: block;
  }
  .nav.navbar-right > li > a {
    color: #999c9e;
  }
  .nav > li.active > a {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.project.main} !important;
  }
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background: #fff;
  }
  .nav.navbar-top-links > li > a:hover,
  .nav.navbar-top-links > li > a:focus {
    background-color: transparent;
  }
  .nav > li > a i {
    margin-right: 6px;
  }
  .nav.navbar-top-links .dropdown-alerts a {
    font-size: 12px;
  }
  .nav > li.active {
    border-left: none;
    background: ${({ theme }) => theme.colors.neutral.neutral05} !important;
  }

  .nav.nav-second-level > li.active {
    border-left: none;
    border-right: none;
  }
  .nav.nav-second-level.collapse[style] {
    height: auto !important;
  }
  ul.nav-second-level {
    //background: ${({ theme }) => theme.colors.neutral.neutral05};
  }
  ul.nav-second-level li.active{
    background: ${({ theme }) => theme.colors.neutral.neutral05} !important;
  }
  .nav.navbar-top-links a {
    font-size: 14px;
  }
  .nav.navbar-top-links .dropdown-toggle::after {
    display: none;
  }
  .nav.nav-third-level > li.active {
    border: none;
  }
  .nav > li .nav-second-level .active > a {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.neutral.neutral80} !important;
    opacity: 1;
  }

  .dropdown-menu > li > a {
    color: ${({ theme }) => theme.colors.text};
  }

  .navbar-default .nav > li > a,
  .navbar-default .nav > li > a {
    color: ${({ theme }) => theme.colors.neutral.neutral80};
  }
  .navbar-default .nav > li > a:focus {
    background-color: ${({ theme }) => theme.colors.neutral.neutral05};
    color: ${({ theme }) => theme.colors.neutral.neutral80};
    opacity: 1;
  }
  .navbar-default {
    background-color: ${({ theme }) => theme.colors.textLight};
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
  .navbar-default .special_link a {
    background: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.textLight};
  }
  .navbar-default .special_link a:hover {
    background: ${({ theme }) => theme.colors.primary.main}!important;
    color: ${({ theme }) => theme.colors.textLight};
  }
  .navbar-default .special_link a span.label {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .navbar-default .landing_link a {
    color: ${({ theme }) => theme.colors.success.main};
    color: ${({ theme }) => theme.colors.textLight};
  }
  .navbar-default .landing_link a:hover {
    background: ${({ theme }) => theme.colors.primary.main} !important;
    color: ${({ theme }) => theme.colors.textLight};
  }
  .navbar-default .landing_link a span.label {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.success.main};
  }

  .navbar-static-side {
    transition: width 0s;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.1) !important;
  }
  @media (min-width: 768px) {
    .navbar-static-side {
      z-index: 1;
      width: 273px;
    }
  }

  .metismenu .plus-minus,
  .metismenu .plus-times {
    float: right;
  }
  .metismenu .arrow {
    float: right;
    line-height: 1.42857;
  }
  .metismenu .glyphicon.arrow:before {
    content: "\e079";
  }
  .metismenu .active > a > .glyphicon.arrow:before {
    content: "\e114";
  }
  .metismenu .fa.arrow:before {
    content: "\f104";
  }
  .metismenu .active > a > .fa.arrow:before {
    content: "\f107";
  }
  .metismenu .ion.arrow:before {
    content: "\f3d2";
  }
  .metismenu .active > a > .ion.arrow:before {
    content: "\f3d0";
  }
  .metismenu .fa.plus-minus:before,
  .metismenu .fa.plus-times:before {
    content: "\f067";
  }
  .metismenu .active > a > .fa.plus-times {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .metismenu .active > a > .fa.plus-minus:before {
    content: "\f068";
  }
  .metismenu .collapse {
    display: none;
  }
  .metismenu .collapse.in {
    display: block;
  }
  .metismenu .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
  }

  .nav-header {
    padding: 33px 25px;
    background-color: ${({ theme }) => theme.colors.primary.main};
    background-image: url("patterns/header-profile.png");
  }
  .nav-header a {
    color: ${({ theme }) => theme.colors.colorTextMenu};
  }
  .nav-header .text-muted {
    color: ${({ theme }) => theme.colors.colorTextMenu} !important;
    opacity: 0.4;
  }

  .profile-element .dropdown-toggle::after {
    display: none;
  }

  .logo-element {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textLight};
    display: none;
    padding: 18px 0;
  }

  .nav-second-level li,
  .nav-third-level li {
    border-bottom: none !important;
  }
  .nav-second-level li a {
    font-weight: 400;
    font-size: 14px;
    padding: 10px 10px 11px 10px;
    padding-left: 52px;
  }

  .nav-third-level li a,
  .mini-navbar .nav-second-level li .nav-third-level li a {
    padding-left: 62px;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
  }
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

  #wrapper {
    width: 100%;
    overflow-x: hidden;
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
  }
  .wrapper {
    padding: 0 20px;
  }
  .wrapper-content {
    padding: 20px 10px 40px;
  }

  #page-wrapper {
    min-height: 100vh;
  }
  #page-wrapper {
    padding: 0rem 15px 5rem 30px;
    position: relative !important;
    flex-shrink: 1;
    width: calc(100% - 273px);
  }
  @media (min-width: 768px) {
    #page-wrapper {
      position: inherit;
    }
  }
  @media (min-width: 768px) {
    #page-wrapper {
      position: inherit;
      min-height: 100vh;
    }
  }
  @media (max-width: 768px) {
    #page-wrapper {
      position: inherit;
      margin: 0 0 0 0;
      min-height: 100vh;
      width: 100%;
    }
  }
  @media print {
    #page-wrapper {
      margin: 0;
    }
  }

  .gray-bg {
    background-color: ${(props) => props.theme.colors.background};
  }
  .white-bg {
    background-color: #ffffff;
  }

  .react-datepicker-popper {
    position: sticky;
    z-index: 100;
  }
`;
