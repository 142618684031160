import { ElementType } from "react";

interface Props {
  icone: ElementType;
}

export function SectionIcone({ icone: Icone }: Readonly<Props>) {
  return <Icone />;
}

