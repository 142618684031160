import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const IconeLink = styled(Link)`
  color: ${({ theme }) => theme.colors.neutral.neutral70};
  transition: color 0.2s ease-in-out;

  svg {
    width: 1rem !important;
    height: 1rem !important;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutral.neutral50};
  }
`;

export const ButtonIcon = styled.button`
  border: none;
  background: transparent;
  font-size: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.neutral.neutral70} !important;
    width: 1rem !important;
    height: 1rem !important;
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.neutral.neutral50} !important;
    }
  }

  &:disabled {
    svg {
      color: ${({ theme }) => theme.colors.neutral.neutral50} !important;
    }
  }
`;

