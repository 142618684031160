import styled from "styled-components";

export const LoadingScreen = styled.div`
  background-color: rgba(00, 00, 00, 0.4);
  position: fixed;
  z-index: 1000000;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`