import { FaExclamationCircle } from "react-icons/fa";
import { Container } from "./style";

export function ComPendencia() {
  return (
    <Container>
      <FaExclamationCircle />
    </Container>
  );
}

