import { useRequest } from "hooks/useRequest";
import { IFiltro } from "../Containers/Filtros/Interface/IFiltro";
import { IDadosClienteReciboAdiantamento } from "../Containers/ListarDetalhes/interface/IDadosClienteReciboAdiantamento";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";

interface IBodySolicitarPagamento {
  idConsulta: number;
  idsDebitos: number[];
  autorizadoPagamento: boolean;
  filtros?: IFiltro;
  idReciboAdiantamento: number;
  formaPgto: FormaPgtoEnum;
  cliente: IDadosClienteReciboAdiantamento;
}

interface ISolicitarPagamento {
  idConsulta: any;
  idsDebitos: any[];
  autorizadoPagamento: boolean;
  filtros?: IFiltro;
  idReciboAdiantamento: number;
  formaPgto: FormaPgtoEnum;
  cliente: IDadosClienteReciboAdiantamento;
}

export function useSolicitarPagamento() {
  const { post, get, loading: loadingPagamento } = useRequest("consulta");

  async function solicitarPagamento({
    idConsulta,
    idsDebitos,
    autorizadoPagamento,
    filtros,
    idReciboAdiantamento,
    formaPgto,
    cliente,
  }: ISolicitarPagamento) {
    const body: IBodySolicitarPagamento = {
      idConsulta,
      idsDebitos,
      autorizadoPagamento,
      idReciboAdiantamento,
      formaPgto,
      cliente
    };

    if (filtros) {
      body.filtros = filtros;
    }

    return await post<IBodySolicitarPagamento, any>(`pagamento/solicita/`, body);
  }

  async function buscarDadosClienteEIdRecibo(idCliente: number) {
    return await get(`pagamento/solicita/cliente/${idCliente}/recibo/dados`);
  }

  const buscarDadosReciboAdiantamento = async (idSolicitacaoPagamento: number) => {
    return await get(`pagamento/solicita/${idSolicitacaoPagamento}/recibo/adiantamento`);
  }

  return {
    solicitarPagamento,
    loadingPagamento,
    buscarDadosClienteEIdRecibo,
    buscarDadosReciboAdiantamento,
  };
}

