import React, { useState } from "react";
import useNotificacao from "../../hooks/UseNotificacao";
import { ContagemNotificacao, ContainerNotificacao } from "./estilo";

interface Props {}

const Notificacoes: React.FC<Props> = () => {
  const { notificacao } = useNotificacao();
  const [activeNoticacoes, setActiveNoticacoes] = useState<boolean>(false);

  return (
    <ContainerNotificacao
      onClick={() => setActiveNoticacoes(!activeNoticacoes)}
    >
      {notificacao.length > 0 ? (
        <ContagemNotificacao>{notificacao.length}</ContagemNotificacao>
      ) : (
        ""
      )}
    </ContainerNotificacao>
  );
};
export default Notificacoes;
