import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError, IHttpResponse } from "_services/api/interface/HttpResponse";
import msConsulta from "_services/api/ms_consulta";
import { HttpStatusCode } from "axios";
import { useState } from "react";
import { IConsultaSolicitada, IConsultaSolicitadaV2 } from "../interfaces/IConsultaSolicitada";

export function useSolicitacaoConsulta() {
  const [loading, setLoading] = useState(false);
  const [veiculo, setVeiculo] = useState<IConsultaSolicitada[]>([]);
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [errorFile, setErrorFile] = useState<IHttpResponse<IData | any>>();

  async function solicitarConsulta(dados: IConsultaSolicitadaV2): Promise<HttpResponse> {
    setLoading(true);
    try {
      const { data, status } = await msConsulta.post(`consultas`, dados);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    } finally {
      setLoading(false);
    }
  }

  async function solicitarConsultaArquivo(dados: any): Promise<HttpResponse> {
    setLoading(true);

    try {
      let licenciamento = dados.tipoDebito.find((item: string) => item == "LICENCIAMENTO") ? "1" : "0";
      let ipva = dados.tipoDebito.find((item: string) => item == "IPVA") ? "1" : "0";

      const formData = new FormData();
      formData.append("arquivo", dados.arquivo);
      formData.append("nomePlanilha", dados.nomeArquivo);
      formData.append("nomeArquivo", dados.nomeArquivo);
      formData.append("socketId", dados.socketId);
      formData.append("licenciamento", licenciamento);
      formData.append("ipva", ipva);
      formData.append("cobrar", dados.cobrar);
      formData.append("cotaIpva", dados.cotaIpva);
      formData.append("descricao", dados.descricao);
      formData.append("empresa", dados.empresa);
      formData.append("motivoIsencao", dados.motivoIsencao);
      formData.append("empresaNome", dados.empresaNome);
      formData.append("uf", dados.uf);

      const { status, data } = await msConsulta.post("/consultas/arquivo", formData);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    } finally {
      setLoading(false);
    }
  }

  async function buscarPorPlaca(placa: string) {
    setLoading(true);
    try {
      const { status, data } = await api.get(`/debito/consulta/?placa=${placa}`);
      const response = new HttpResponse(false, status, data);
      setError(response);
      setVeiculo(data);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoading(false);
    }
  }

  async function removerTodasConsultas() {
    setLoading(true);
    try {
      const { status, data } = await api.delete(`/debito/consulta/`);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    } finally {
      setLoading(false);
    }
  }

  async function removerConsulta(consultaSolicitada: IConsultaSolicitada) {
    setLoading(true);
    try {
      const { status, data } = await api.delete(`/debito/consulta/${consultaSolicitada.id}`);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    } finally {
      setLoading(false);
    }
  }

  return {
    error,
    errorFile,
    loading,
    setLoading,
    buscarPorPlaca,
    veiculo,
    solicitarConsulta,
    removerConsulta,
    removerTodasConsultas,
    solicitarConsultaArquivo,
  };
}
