import styled from "styled-components";
import { FilterButton, PrimaryButton } from "components/Button/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
  gap: 30px;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;

  .titulo {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }
`;

export const Linha = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background: #e7eaec;
  margin: 25px 0px;
`;

export const InputFiltro = styled.input`
  display: flex;
  width: 40%;
  border: 1px solid #979797;
  border-radius: 3px;
`;

export const WrapperFiltro = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: stretch;
  align-items: stretch;
  margin: 6px 0;
  max-width: 60%;
`;

export const TextoAzul = styled.p`
  color: ${({ theme }) => theme.colors.primary.main};
  margin: 0;
  cursor: pointer;
`;

export const ContainerCentralizado = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const WrapperButtonModal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  margin-top: 25px 0;
`;

export const DivBackGround = styled.div`
  background-color: ${({ theme }) => theme.colors.project.bgColor};
  width: 32px;
  height: 32px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.project.main};
  margin-bottom: 16px;
`;

export const Button = styled(PrimaryButton)`
  width: 100px;
`;

export const ButtonFiltro = styled(FilterButton)`
  width: 100px;
  height: 100%;
`;
