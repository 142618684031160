import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export const ResumoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.neutral05};
  padding: 2rem;
  display: grid;
  gap: 2rem;
  border-radius: 0.5rem;
`;

export const DadosContainer = styled.div`
  display: grid;
  font-size: 14px;
`;

export const TextoNegrito = styled.span`
  font-weight: 500;
`; 