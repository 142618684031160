import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { RowRestricoesPDF } from "./containers/RowRestricoesPDF";
import { IDadosRestricoes } from "../../../DadosRestricoes";

interface Props {
  restricoes: IDadosRestricoes[];
}

export function DadosRestricoesPDFNacional({ restricoes }: Readonly<Props>) {
  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Restrições</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          {restricoes.length && !!restricoes.filter(
            (restricao) => {
              return restricao.motivoBloqueio !== 'Comunicação de venda' || (restricao.motivoBloqueio === 'Comunicação de venda' && restricao.indicadorComunicacaoDeVenda !== 'NAO CONSTA COMUNICACAO DE VENDAS')
            }
          ).length ? (
            <View>
              <View style={STYLES.SECTION_ROW}>
                <View style={STYLES.SECTION_COL_1}>
                  <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Descrição</Text>
                </View>
              </View>
              {restricoes.map((restricao) => <RowRestricoesPDF restricao={restricao} />)}
            </View>
          ) : (
            <View>
              <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Restrições</Text>
              <Text style={STYLES.FONT_SIZE_08}>Nada consta</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

