import { Row } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
`;

export const Divider = styled.hr`
  border: solid 1px #000000;
  height: 2px;
  background-color: #000000;
`;

export const TotalLinhas = styled.p`
  text-align: left;
  font-size: 14px;
`;

export const Titulo = styled.span`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  gap: 0.5em;
  justify-content: center;
`;

export const TextoLg = styled.p`
  font-size: 18px;
  font-weight: normal;
`

export const TotaisContainer = styled(Row)`
  padding: 1.5rem;
  align-items: center;
  justify-content: space-between;

  .col {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
    }
  }
`;

export const TableHeader = styled(Row)`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const ContagemDebitos = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const SLAContainer = styled.div`
  display: grid;
  text-align: start;
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
`

export const TooltipContainer = styled.div`
  text-align: left;
  font-weight: 400;
  color: #FFFFFF;
  background-color: #333333;
  padding: 10px 20px;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
`;

