import { Col, Row, RowProps } from "react-bootstrap";
import styled from "styled-components";

interface StyledRowProps extends RowProps {
  temconteudoparamostrar: "true" | "false";
  arendondarSomenteBordasSuperior?: "true" | "false";
}

export const StyledRow = styled(Row)<StyledRowProps>`
  justify-content: space-between;
  background-color: ${({ theme, temconteudoparamostrar }) =>
    temconteudoparamostrar === "true"
      ? theme.colors.danger.bgColor
      : theme.colors.neutral.neutral10};

  ${({ arendondarSomenteBordasSuperior }) => {
    if (arendondarSomenteBordasSuperior) {
      return `border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
`;
    }
    return `border-radius: 0.75rem;
`;
  }}

  margin-top: 0.5rem;
  padding: 0.5rem 0.25rem;

  &:hover {
    cursor: ${({ temconteudoparamostrar }) =>
      temconteudoparamostrar === "true" ? "pointer" : "normal"};
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const ToggleButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;
