import { StatusSolicitacaoPagamentoEnumV2} from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { Container } from "./style";

interface Props {
  statusDebito: string;
}

export function StatusSolicitacaoPagamento({
  statusDebito,
}: Props) {
  return (
    <Container
      statusDebito={StatusSolicitacaoPagamentoEnumV2[Number(statusDebito)]}
    >
      {StatusSolicitacaoPagamentoEnumV2[Number(statusDebito)]}
    </Container>
  );
}
