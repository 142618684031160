import styled from "styled-components";

export const AcoesContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
`;

export const ButtonComprovante = styled.button`
	border: none;
	background-color: transparent;

	svg {
		width: 1.25rem !important;
		height: 1.25rem !important;
		transition: color 0.25s ease-in-out;

		&:hover {
			color: ${({ theme }) => theme.colors.neutral.neutral60};
		}
	}

	&:disabled {
		svg {
			color: ${({ theme }) => theme.colors.neutral.neutral60};
		}
	}

	.rotate {
		animation: rotate 2s linear infinite;

		@keyframes rotate {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}
`;
