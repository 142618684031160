import { yupResolver } from "@hookform/resolvers/yup";
import { useLogin } from "features/Login/hooks/useLogin";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Codigo2Fatores } from "../Codigo2Fatores";
import { Carregamento, ContainerForm, CustomButton, ButtonReenviar, ContainerDescricao, ErroCodigo, IconeErro, NaoRecebido } from "./style";
import { LoginForm } from "../Login";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ILogar } from "features/Login/@types/ILogar";
import { loginSchema } from "features/Login/validator/loginValidator";
import { parseJwt, removeToken, setLocalStore, setToken } from "_services/auth";
import { LoadingScreen } from "components/LoadingScreen";
import { ModalErroToken } from "./ModalErroToken";

interface ILoginProps {
  handleClickEsqueceuSenha: () => void;
}

export function LoginBox({ handleClickEsqueceuSenha }: ILoginProps) {
  const { logar, loading, crsf, validarToken } = useLogin();
  const [params, serParams] = useSearchParams();

  const [loadingLogin, setLoadingLogin] = useState<boolean>(true);
  const [usuarioBloqueado, setUsuarioBloqueado] = useState<boolean>(false);
  const [idEmail, setIdEmail] = useState<string>("");
  const [etapaAtivarCodigo, setEtapaAtivarCodigo] = useState<boolean>(false);
  const [erro, setError] = useState<string>("");
  const [erroCodigo, setErroCodigo] = useState<string>("");
  const [codigoDigitado, setCodigoDigitado] = useState<string>("");
  const [reenviar, setReenviar] = useState<boolean>(false);
  const [crsfCodigo, setCrsfCodigo] = useState<string>("");
  const [codigoExpirado, setCodigoExpirado] = useState<boolean>(false);
  const [validarTokenUrl, setValidarTokenUrl] = useState<boolean>(true);
  const [modalErroToken, setModalErroToken] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<ILogar>({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    handleUrl();
  }, []);

  async function run() {
    const { data } = await crsf();

    setCrsfCodigo(data.tokenCSRF);

    const { data: validaToken, hasErro } = await validarToken();

    if (!hasErro) {
      if (validaToken) {
        navigate("/core/dashboard");
        return;
      }
    }

    setLoadingLogin(false);
    removeToken();

    const strategyMensagem: { [key: string]: string } = {
      idNull: "Sessão expirada.",
      err: "Não foi possível validar o usuário.",
      segValida: "A validação do usuário não foi possível.",
    };

    const mensagem = params.get("m");

    if (mensagem) {
      if (Object.keys(strategyMensagem).includes(mensagem)) {
        setError(strategyMensagem[mensagem]);
      }
    }
  }

  async function handleUrl() {
    const tokenLwDebitosUrl = params.get("token")
    if (tokenLwDebitosUrl && validarTokenUrl) {
      setToken(tokenLwDebitosUrl);
      setValidarTokenUrl(false);

      const { data: validaToken, hasErro } = await validarToken();

      if (hasErro || !validaToken) {
        removeToken();
        setModalErroToken(true);
        return;
      }

      const { email, name, uuid_cliente } = parseJwt();

      setLocalStore(tokenLwDebitosUrl, name, email, uuid_cliente);

      navigate(`/core/dashboard`);

      return;
    }

    run();
  }

  const onClickModalErroToken = () => {
    window.location.assign(`/`);
  }

  async function handleLogar(login: ILogar) {
    handleLimparErros();

    if (etapaAtivarCodigo) {
      if (!login.codigo && !reenviar) {
        setErroCodigo("Código é obrigatório");
        return;
      }

      if (login.codigo) {
        login.codigo = login.codigo.replace(/\s/g, "");
      }
    }

    setReenviar(false);

    const { data, hasErro }: any = await logar({ ...login, csrf: crsfCodigo });

    if (hasErro) {
      if (
        data.message ===
        "Código de acesso expirado"
      ) {
        setCodigoExpirado(true);
        setError("Código expirado. Clique no botão abaixo para reenviar.");
        return;
      }

      setError(data.message);

      if (
        data.message ===
        "Acesso bloqueado. Por favor, utilize os links abaixo para redefinir sua senha ou retornar a tela de login."
      ) {
        setUsuarioBloqueado(true);
      }

      return;
    }

    if (data.idEnvioEmail) {
      setIdEmail(data.idEnvioEmail);
      setEtapaAtivarCodigo(true);
      return;
    }

    setToken(data.token);

    const { email, name, uuid_cliente } = parseJwt();

    setLocalStore(data.token, name, email, uuid_cliente);

    navigate(`/core/dashboard`);
  }

  function handleReenviar() {
    setReenviar(true);
    setValue("codigo", "");
    handleSubmit(logar);
  }

  function handleLimparErros() {
    setErroCodigo("");
    setError("");
    setCodigoExpirado(false);
  }

  function handleVoltarParaLogin() {
    setValue("codigo", "");
    setEtapaAtivarCodigo(false);
    handleLimparErros();
    setCodigoExpirado(false);
  }

  return (
    <div>
      <LoadingScreen mostrar={loadingLogin} backgroundColor="#FFFFFF" />
      <ContainerForm id="formLogin" onSubmit={handleSubmit(handleLogar)}>
        {!etapaAtivarCodigo ? (
          <LoginForm errors={errors} register={register} />
        ) : (
          <Codigo2Fatores
            idEmail={idEmail}
            handleLimparErros={handleLimparErros}
            control={control}
            setCodigoDigitado={setCodigoDigitado}
            erroCodigo={erroCodigo}
          />
        )}

        {(erro || erroCodigo || errors.email || errors.senha || errors.codigo) && (
          <ErroCodigo>
            <table><tbody>
              <tr>
                <td style={{ verticalAlign: "text-top" }}><IconeErro /></td>
                <td>{erro !== "" ? erro : (erroCodigo !== "" ? erroCodigo : [
                  errors.email?.message,
                  errors.senha?.message,
                  errors.codigo?.message
                ].filter(item => item).join(". "))}
                </td>
              </tr>
            </tbody></table>

          </ErroCodigo>
        )}

        <CustomButton
          style={(codigoExpirado && etapaAtivarCodigo) ? { display: "none" } : {}}
          type={!usuarioBloqueado ? "submit" : "button"}
          onClick={!usuarioBloqueado ? () => { } : handleClickEsqueceuSenha}
        >
          {loading ? <Carregamento /> : !usuarioBloqueado ? "Fazer login" : "Redefinir senha"}
        </CustomButton>

        <ContainerDescricao centralizar={etapaAtivarCodigo}>
          {!usuarioBloqueado && !codigoExpirado && etapaAtivarCodigo && (
            <>
              <div>
                <NaoRecebido>Não recebeu o código de acesso? Verifique sua caixa de spam ou</NaoRecebido>
              </div>
              <div>
                <ButtonReenviar onClick={handleReenviar} form="formLogin" type="submit">
                  clique para reenviar
                </ButtonReenviar>
                {codigoDigitado && erro && (
                  <><NaoRecebido> ou </NaoRecebido><ButtonReenviar onClick={handleVoltarParaLogin}>voltar para o login</ButtonReenviar></>
                )}
              </div>
            </>
          )}
          <div>
            {usuarioBloqueado && etapaAtivarCodigo && (
              <ButtonReenviar onClick={handleVoltarParaLogin}>Voltar ao login</ButtonReenviar>
            )}
            {!usuarioBloqueado && !etapaAtivarCodigo && (
              <ButtonReenviar onClick={handleClickEsqueceuSenha}>Esqueci minha senha</ButtonReenviar>
            )}
          </div>
        </ContainerDescricao>
      </ContainerForm>
      {!usuarioBloqueado && codigoExpirado && etapaAtivarCodigo && <CustomButton
        form="formLogin"
        type="submit"
        onClick={handleReenviar}
      >
        {loading ? <Carregamento /> : "Reenviar"}
      </CustomButton>}
      <ModalErroToken
        show={modalErroToken}
        onClick={onClickModalErroToken}
      />
    </div>
  );
}

