import { Page } from "containers/Page";
import { Table } from "containers/Table";
import { useChartConsultasSolicitacoes } from "features/core/hooks/useChartConsultasSolicitacoes";
import { useChartPagamentosRealizados } from "features/core/hooks/useChartPagamentosRealizados";
import { useTableSolicitacoesPagamentos } from "features/core/hooks/useTableSolicitacoesPagamentos";
import { useValoresDashboard } from "features/core/hooks/useValoresDashboard";
import { ITableSolicitacoesPagamentos } from "features/core/interfaces/ITableSolicitacoesPagamentos.interface";
import { ContextPermissao } from "hooks/ContextPermissao";
import { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { parseJwt } from "_services/auth";
import { Data, converterDataEua } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { useEmpresas } from "../../../../hooks/useEmpresas";
import {
  Container,
  ContainerWrapper,
  DatePickerContainer,
  IconBackground,
  Link,
  RowDirection,
  SubContainer,
  SubContainerShadow,
  Tag,
  TituloWrapper,
} from "./style";
import { FaAngleRight, FaCheckDouble, FaDollarSign, FaIdCard, FaSearch, FaTimes } from "react-icons/fa";
import ReactDatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR"

function Dashboard() {
  const { nome_cliente, uuid_cliente } = parseJwt();
  const { empresas, agrupamentoOptions, criarOptionsAgrupamento } = useEmpresas();
  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);
  const { verificarEmpresa } = useContext(ContextPermissao);
  const usuarioLW = verificarEmpresa();
  const [empresaSelecionada, setEmpresaSelecionada] = useState<number>(
    uuid_cliente
  );

  const hoje = new Date();
  const primeiroDia = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
  const ultimoDia = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);

  const [dateRange, setDateRange] = useState<Array<Date | null>>([primeiroDia, ultimoDia]);

  const [startDate, endDate] = dateRange;

  function handleDateRangeSelect(update: Array<Date | null>) {
    setDateRange(update);
  }

  function handleDataDigitada(value: string) {
    const dateRangePadrao = /^\d{2}\/\d{2}\/\d{4}-\d{2}\/\d{2}\/\d{4}$/;
    if (value && dateRangePadrao.test(value.replaceAll(" ", ""))) {
      const datas = value.replaceAll(" ", "").split("-").map((item: string) => {
        const partes = item.split("/");
        return new Date(Number(partes[2]), Number(partes[1]) - 1, Number(partes[0]));
      });

      handleDateRangeSelect(datas);
    }
  }

  const { state: statePagamentsRealizados } = useChartPagamentosRealizados({
    empresaSelecionada,
    dataInicial: startDate,
    dataFinal: endDate
  });
  const { state: stateConsultasSolicitacoes } = useChartConsultasSolicitacoes({
    empresaSelecionada,
    dataInicial: startDate,
    dataFinal: endDate
  });
  const { customTableStyle, loading, data } = useTableSolicitacoesPagamentos({
    empresaSelecionada,
    dataInicial: startDate,
    dataFinal: endDate
  });

  const {
    totalPago,
    placasConsultadas,
    pagamentosRealizados,
    licenciamentosEmitidos,
    pagamentosRecusados,
  } = useValoresDashboard({
    empresaSelecionada,
    dataInicial: startDate,
    dataFinal: endDate
  });

  const columns = [
    {
      name: "ID Solicitação",
      selector: (row: ITableSolicitacoesPagamentos) => row.id,
    },
    {
      name: "Empresa",
      selector: (row: ITableSolicitacoesPagamentos) => row.empresa,
    },
    {
      name: "Data Solicitação",
      selector: (row: ITableSolicitacoesPagamentos) =>
        new Data(row.dataHora).getDataBr(),
    },
    {
      name: "Qtd Débitos",
      selector: (row: ITableSolicitacoesPagamentos) => row.qtdSolicitado,
    },
    {
      name: "Valor Total",
      selector: (row: ITableSolicitacoesPagamentos) =>
        formatarDinheiro(row.valorTotal / 100),
    },
    {
      name: "Prazo para Pgto",
      selector: (row: ITableSolicitacoesPagamentos) =>
        new Data(row.dataMenorPrazoPagamento).getDataBr(),
    },
    {
      name: "Status",
      selector: (row: ITableSolicitacoesPagamentos) => {
        const finalizado = row.qtdSolicitado == row.qtdFinalizado;

        return (
          <Tag
            backgroundColor={finalizado ? "#DBFFF6" : "#FFEFCC"}
            borderColor={finalizado ? "#10AC85" : "#FF9F42"}
          >
            {finalizado ? "Finalizado" : "Pendente"}
          </Tag>
        );
      },
    },
  ];

  return (
    <Page title="Dashboard">
      <Container>
        <ContainerWrapper>
          <SubContainer>
            <strong>Periodo: </strong>
            <DatePickerContainer>
              <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                locale={br}
                startDate={startDate}
                endDate={endDate}
                selectsRange={true}
                onChange={(update) => {
                  handleDateRangeSelect(update)
                }}
                onChangeRaw={(event: any) => {
                  handleDataDigitada(event.target.value);
                }}
              />
            </DatePickerContainer>
          </SubContainer>
          <SubContainer>
            <strong>Empresa: </strong>
            <select
              value={empresaSelecionada}
              onChange={(e) => setEmpresaSelecionada(parseInt(e.target.value))}
            >
              {usuarioLW ? (
                empresas.map(({ uuidCliente, nomeCliente }) => (
                  <option value={uuidCliente} key={uuidCliente}>
                    {nomeCliente}
                  </option>
                ))
              ) : agrupamentoOptions && agrupamentoOptions.length ? (
                agrupamentoOptions.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))
              ) : (
                <option value={uuid_cliente}>{nome_cliente}</option>
              )
              }
            </select>
          </SubContainer>
          <SubContainer visible={false}></SubContainer>
        </ContainerWrapper>
        <ContainerWrapper>
          <SubContainerShadow flexDirection="column">
            <IconBackground color="#ABE9D9" textBackground="#10ac85">
              <p><FaDollarSign /></p>
            </IconBackground>
            <RowDirection>
              <strong>Total Pago (R$)</strong>
            </RowDirection>
            <h2>{totalPago}</h2>
          </SubContainerShadow>
          <SubContainerShadow flexDirection="column">
            <IconBackground color="#E9E9FE" textBackground="#2529F4">
              <p><FaSearch /></p>
            </IconBackground>
            <RowDirection>
              <strong>Placas Consultadas</strong>
            </RowDirection>
            <h2>{placasConsultadas}</h2>
          </SubContainerShadow>
          <SubContainerShadow flexDirection="column">
            <IconBackground color="#FFEFCC" textBackground="#FF9F42">
              <p><FaCheckDouble /></p>
            </IconBackground>
            <RowDirection>
              <strong>Pagamentos realizados</strong>
            </RowDirection>
            <h2>{pagamentosRealizados}</h2>
          </SubContainerShadow>
          <SubContainerShadow flexDirection="column">
            <IconBackground color="#D2F7FF" textBackground="#0ABDE2">
              <p><FaIdCard /></p>
            </IconBackground>
            <RowDirection>
              <strong>Licenciamentos emitidos</strong>
            </RowDirection>
            <h2>{licenciamentosEmitidos}</h2>
          </SubContainerShadow>
          <SubContainerShadow flexDirection="column">
            <IconBackground color="#FFE4E4" textBackground="#EE5353">
              <p><FaTimes /></p>
            </IconBackground>
            <RowDirection>
              <strong>Pagamentos Recusados</strong>
            </RowDirection>
            <TituloWrapper>
              <h2>{pagamentosRecusados}</h2>
              <Link
                href={`/relatorios/recusados?dataInicialRecusa=${converterDataEua(new Date(`${startDate}`))}&dataFinalRecusa=${converterDataEua(new Date(`${endDate}`))}&idEmpresa=${empresaSelecionada}`}
              >
                <FaAngleRight />
              </Link>
            </TituloWrapper>
          </SubContainerShadow>
        </ContainerWrapper>
        <SubContainer>
          <Chart
            options={{
              ...statePagamentsRealizados.options,
            }}
            series={statePagamentsRealizados.series}
            type="line"
            height={450}
            style={{ display: "flex", width: "50%" }}
          />
          <Chart
            options={{
              ...stateConsultasSolicitacoes.options,
            }}
            series={stateConsultasSolicitacoes.series}
            type="line"
            height={450}
            style={{ display: "flex", width: "50%" }}
          />
        </SubContainer>
        <SubContainer flexDirection="column">
          <strong>Últimas solicitações de pagamento</strong>
          <Table
            columns={columns}
            data={data}
            loading={loading}
            keyField="idSolicitacao"
            customTableStyle={customTableStyle}
          />
        </SubContainer>
      </Container>
    </Page>
  );
}

export { Dashboard };
