import styled from "styled-components";

export const IconeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  width: 80px;

  svg {
    width: 1rem !important;
    color: #333333 !important;
  }

  &:disabled {
    svg {
      color: ${({ theme }) => theme.colors.neutral.neutral40} !important;
    }
  }
`;

