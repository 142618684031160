import { Page } from "containers/Page";
import { FiltrosDetalhesExtratoVeicular } from "./containers/FiltrosDetalhesExtratoVeicular";
import { useDetalhesExtratoVeicular } from "./hooks/useDetalhesExtratoVeicular";
import { ListarDetalhesExtratoVeicular } from "./containers/ListarDetalhesExtratoVeicular";
import { useCallback, useEffect } from "react";

export function ConsultarVeiculosDetalhes() {
  const {
    buscarDadosDetalhado,
    buscarDadosUnitarios,
    dadosDetalhados,
    dadosUnitarios,
    setFiltros,
    handleLimitChange,
    handlePageChange,
    totalRows,
    limit,
    loading,
    buscarPor,
    setBuscarPor,
    reconsultar,
  } = useDetalhesExtratoVeicular();

  useEffect(() => {
    if (buscarPor === "DETALHADOS") {
      buscarDadosDetalhado();
      return;
    }

    buscarDadosUnitarios();
  }, [buscarDadosDetalhado, buscarPor, buscarDadosUnitarios]);

  const handleReconsultar = useCallback(
    async (idLoteExtratoVeicular: string) => {
      const response = await reconsultar(idLoteExtratoVeicular);

      if (!response.hasErro) {
        if (buscarPor === "DETALHADOS") {
          buscarDadosDetalhado();
        }

        if (buscarPor === "UNITARIOS") {
          buscarDadosUnitarios();
        }
      }

      return response;
    },
    [reconsultar, buscarPor, buscarDadosDetalhado, buscarDadosUnitarios]
  );

  return (
    <Page redirectDoIcone="/veiculos/extrato/consultas" title="Extrato veicular">
      <FiltrosDetalhesExtratoVeicular submit={setFiltros} />
      <ListarDetalhesExtratoVeicular
        dados={dadosDetalhados.length ? dadosDetalhados : dadosUnitarios}
        buscarDadosDetalhado={buscarDadosDetalhado}
        buscarDadosUnitarios={buscarDadosUnitarios}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
        totalRows={totalRows}
        rowsPerPage={limit}
        loading={loading}
        setBuscarPor={setBuscarPor}
        reconsultarFalhas={handleReconsultar}
      />
    </Page>
  );
}

