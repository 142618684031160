import { Informacao } from "components/Informacao";
import { Table } from "containers/Table";

const customTableStyle = {
  table: {
    style: {
      maxHeight: "350px",
    },
  },
};

export function BodyParcial({ dados }: { dados: any[] }) {
  const columns = [
    {
      name: "Placa",
      selector: (dado: any) => dado.placa,
    },
    {
      name: "Chassi",
      selector: (dado: any) => dado.chassi,
    },
    {
      name: "Renavam",
      selector: (dado: any) => dado.renavam,
    },
    {
      name: "UF",
      selector: (dado: any) => dado.uf,
    },
  ];
  return (
    <>
      <Informacao
        mensagem={
          <span>
            As placas listadas abaixo já foram consultadas nas últimas 24 horas.{" "}
            <strong>Deseja prosseguir com a consulta?</strong>
          </span>
        }
        showBotaoFechar={false}
        type="atencao"
        showLabel={false}
      />
      <Table
        showHeader={false}
        noSubHeader
        totalRows={dados.length}
        data={dados}
        columns={columns}
        loading={false}
        keyField="id"
        customTableStyle={customTableStyle}
      />
      <Informacao
        mensagem={
          <span>
            Ao clicar na opção <strong>Não consultar</strong>, apenas as placas
            desta listagem serão desconsideradas, {" "}
            <strong>as demais placas já estão sendo consultadas</strong>
          </span>
        }
        style={{
          marginTop: '2rem'
        }}
        showBotaoFechar={false}
        showLabel={false}
      />
    </>
  );
}
