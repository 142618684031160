import { formatarDinheiro } from "_utils/Money";
import { Container, ContainerBody, ContainerTitle, ContainerTotal, DadosResumo, ResumoItem, ResumoItemBody, ResumoItemTitle, TotalItem } from "../../style";
import { IDadosPorTipoDebito } from "../../../ListarDetalhes/interface/IDadosPorTipoDebito";

interface Props {
    dadosPorTipoDebito: IDadosPorTipoDebito[];
    valorTotal: number;
}
export function DadosResumoRecibo({
    dadosPorTipoDebito,
    valorTotal,
}: Props) {
    return (
        <Container>
            <ContainerTitle>
                Resumo
            </ContainerTitle>
            <ContainerBody>
                <DadosResumo>
                    <ResumoItem width="50%">
                        <ResumoItemTitle>Descrição</ResumoItemTitle>
                    </ResumoItem>

                    <ResumoItem align="left" width="20%">
                        <ResumoItemTitle>Quantidade</ResumoItemTitle>
                    </ResumoItem>

                    <ResumoItem align="right" width="30%">
                        <ResumoItemTitle>Valor</ResumoItemTitle>
                    </ResumoItem>
                </DadosResumo>
                {dadosPorTipoDebito.filter((item) => item.quantidade && item.valor).map((dado) =>
                    <DadosResumo>
                        <ResumoItem width="50%">
                            <ResumoItemBody>{dado.descricao}</ResumoItemBody>
                        </ResumoItem>
                        <ResumoItem align="left" width="20%">
                            <ResumoItemBody>{dado.quantidade}</ResumoItemBody>
                        </ResumoItem>
                        <ResumoItem align="right" width="30%">
                            <ResumoItemBody>{formatarDinheiro(dado.valor / 100)}</ResumoItemBody>
                        </ResumoItem>
                    </DadosResumo>
                )}
                <hr style={{ margin: "16px" }} />
                <ContainerTotal>
                    <TotalItem>Total:</TotalItem>
                    <TotalItem>{formatarDinheiro(valorTotal / 100)}</TotalItem>
                </ContainerTotal>
            </ContainerBody>
        </Container>
    );
}