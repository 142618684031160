import { useState } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";

interface IFormSubmit<T extends FieldValues> {
  dados: T;
  submitCallback: (dados: T) => void;
  resetCallback: UseFormReset<T>;
  excluir: Array<keyof T>;
}

interface ILimparMaisFiltros<T extends FieldValues> {
  resetCallback: UseFormReset<T>;
  excluir: Array<keyof T>;
}

export function useMaisFiltros() {
  const [usarMaisFiltros, setUsarMaisFiltros] = useState<boolean>(false);

  function handleAplicarMaisFiltros() {
    setUsarMaisFiltros(true);
  }

  function handleNaoUsarMaisFiltros<T extends FieldValues>({
    excluir,
    resetCallback,
  }: ILimparMaisFiltros<T>) {
    setUsarMaisFiltros(false);

    resetCallback((dados) => {
      const dadosExcluidos = { ...dados };

      excluir.forEach((key) => {
        delete dadosExcluidos[key];
      });

      return {
        ...dadosExcluidos,
      };
    });
  }

  function handleFormSubmit<T extends FieldValues>({
    dados,
    excluir,
    resetCallback,
    submitCallback,
  }: IFormSubmit<T>) {
    const dadosExcluidos = { ...dados };
    resetCallback({ ...dados });

    excluir.forEach((key) => {
      delete dadosExcluidos[key];
    });

    if (!usarMaisFiltros) {
      submitCallback({
        ...dadosExcluidos,
      });
    }

    if (usarMaisFiltros) {
      submitCallback(dados);
    }
  }

  return {
    handleAplicarMaisFiltros,
    handleNaoUsarMaisFiltros,
    handleFormSubmit,
  };
}

