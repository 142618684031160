import { Stronger } from "components/Stronger";
import { Section } from "../../../Section";
import { IDadosRestricoes } from "../../../DadosRestricoes";

interface Props {
  dados: IDadosRestricoes;
}

export function ToggleRestricoes({ dados }: Readonly<Props>) {

  return (
    <>
      <Section.Row>
        <Section.Col lg="3" md="3" sm="3">
          <span>Indicador de comunicação de venda</span> <br />
          <Stronger>{dados.indicadorComunicacaoDeVenda ?? "Não consta comunicação de vendas"}</Stronger>
        </Section.Col>
        <Section.Col lg="3" md="3" sm="3">
          <span>Data comunicação</span> <br />
          <Stronger>{dados.dataComunicacao ?? "Não consta em nossa base"}</Stronger>
        </Section.Col>
        <Section.Col lg="3" md="3" sm="3">
          <span>Data venda</span> <br />
          <Stronger>{dados.dataVenda ?? "Não consta em nossa base"}</Stronger>
        </Section.Col>
      </Section.Row>
    </>
  );
}
