import { LoadingCirculo } from "components/LoadingCirculo";
import { Container } from "./style";

interface ILoadingScreen {
  mostrar: boolean;
  backgroundColor?: string;
}

export function LoadingScreen({ mostrar = false, ...props }: ILoadingScreen) {
  return (
    <Container mostrar={mostrar} {...props}>
      <LoadingCirculo mostrar={mostrar} />
    </Container>
  );
}
