export const STATUS_PAGAMENTO_OPTIONS = [
  {
    value: "pendente",
    label: "Pendente"
  },
  {
    value: "finalizado",
    label: "Finalizado"
  }
]

export const STATUS_PAGAMENTO_DETALHES_OPTIONS = [
  {
    value: "0,2,3,5",
    label: "Pendente"
  },
  {
    value: "1",
    label: "Pago"
  },
  {
    value: "4",
    label: "Recusado"
  },
]

export const TIPO_DEBITOS_OPTIONS = [
  {
    value: "1",
    label: "IPVA"
  },
  {
    value: "2",
    label: "LICENCIAMENTO"
  }
]

export const VENCIMENTO_OPTIONS = [
  {
    value: "vencidos",
    label: "Vencidos"
  },
  {
    value: "aVencer",
    label: "À vencer"
  }
]