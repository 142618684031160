import { IRelatorioPagamentos } from "features/relatorios/pagamento/interfaces/RelatorioPagamento";
import { Col, Row } from "react-bootstrap";
import { converterDataHoraBr } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { BodyDadoResumo, ResumoContainer, TituloDadoResumo } from "./style";

interface Props {
  dados: IRelatorioPagamentos;
}

export function Resumo({ dados }: Props) {
  return (
    <Row>
      <ResumoContainer >
        <Row>
          <Col>
            <TituloDadoResumo>Empresa</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.empresa ? dados.empresa : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>Usuário</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.nomeUsuario ? dados.nomeUsuario : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>ID Consulta</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.consultasId ? dados.consultasId : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>ID Pagamento</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.id ? dados.id : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>Data/Hora solicitação</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.dataHora ? converterDataHoraBr(dados.dataHora) : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>Pagamentos solicitados</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.qtdSolicitado ? dados.qtdSolicitado : '- -'}</BodyDadoResumo>
          </Col>
        </Row>

        <Row style={{ marginTop: "30px" }}>
          <Col>
            <TituloDadoResumo>Valor da solicitação</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.valorTotal ? formatarDinheiro(dados.valorTotal / 100) : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>Pagamentos recusados</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.valorTotalRecusado ? formatarDinheiro(dados.valorTotalRecusado / 100) : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>Valor para pagamento</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.valorTotal ? formatarDinheiro((dados.valorTotal - (dados.valorTotalRecusado ?? 0)) / 100) : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>Juros</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.valorTotalJuros ? formatarDinheiro(dados.valorTotalJuros / 100) : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>Valor pago</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.valorTotalPago ? formatarDinheiro(dados.valorTotalPago / 100) : '- -'}</BodyDadoResumo>
          </Col>
          <Col>
            <TituloDadoResumo>ID Recibo de pagamento</TituloDadoResumo>
            <BodyDadoResumo>{dados && dados.idsReciboFinanceiro ? dados.idsReciboFinanceiro.replaceAll(',', ', ') : '- -'}</BodyDadoResumo>
          </Col>
        </Row>

      </ResumoContainer>
    </Row>
  );
}
