import styled from "styled-components";

interface TotalTextProps {
  negative?: boolean;
  warning?: boolean;
}

export const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 7rem;
  padding-top: 2rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: end;
  width: 10%;
  height: 70px;
`;

export const TotalTitle = styled.strong`
  font-size: 28px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 10%;
  height: 70px;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.neutral.neutral40};
  align-self: end;
  margin-bottom: 0.3rem;
`;

export const ValorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.neutral.neutral40};
    margin-right: 0.5rem;
  }
`;

export const TotalText = styled.h3<TotalTextProps>`
  color: ${({ negative, warning, theme }) =>
    negative
      ? theme.colors.danger.main
      : warning
      ? theme.colors.warning.main
      : theme.colors.success.main};
  font-weight: 700;
`;

