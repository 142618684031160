import { ButtonToggleVisualizacao, Container, ExportExcelContainer } from "./style";
import { ElementType, ReactNode } from "react";

interface IExportExcel {
  onClick?: () => void;
  variant?: "primary" | "secondary";
  icone: ElementType;
  texto: string;
  showToggleVisualizacao?: boolean;
  onClickToggleVisualizacao?: () => void;
  toggleComponent?: ReactNode;
}

function ExportExcel({
  onClick,
  variant = "primary",
  icone: Icone,
  texto,
  onClickToggleVisualizacao = () => {},
  showToggleVisualizacao,
  toggleComponent,
}: Readonly<IExportExcel>) {
    return (
    <Container>
      {showToggleVisualizacao && (
        <ButtonToggleVisualizacao
          onClick={onClickToggleVisualizacao}
          type="button"
        >
          {toggleComponent}
        </ButtonToggleVisualizacao>
      )}
      {onClick && (
        <ExportExcelContainer variant={variant} onClick={onClick}>
          {texto}
          <Icone />
        </ExportExcelContainer>
      )}
    </Container>
  );
}
export { ExportExcel };

