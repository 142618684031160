import { FunnelFill } from "react-bootstrap-icons";
import {
  ButtonContainer,
  ButtonTexto,
  ModalContainer,
  TituloModalContainer,
  HeaderModalContainer,
  BodyModalContainer,
  FooterModalContainer,
} from "./style";
import { PrimaryButton } from "components/Button/style";
import { Button } from "react-bootstrap";
import { ReactNode, useEffect, useRef, useState } from "react";

interface ButtonModalProps {
  children: ReactNode;
  handleClickAplicarFiltros: () => void;
  limparTudoOnClick: () => void;
  widthModal?: "sm" | "md" | "lg";
  showMaisFiltrosDefault?: boolean;
  cancelarOnClick: () => void;
}

export function MaisFiltros({
  children,
  limparTudoOnClick,
  widthModal = "md",
  showMaisFiltrosDefault = false,
  cancelarOnClick,
  handleClickAplicarFiltros,
}: ButtonModalProps) {
  const [aberto, setAberto] = useState(showMaisFiltrosDefault);
  const ref = useRef<HTMLDivElement>(null);

  function handleClickOutside(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setAberto(false);
    }
  }

  function handleAplicarFiltros() {
    setAberto(false);
    handleClickAplicarFiltros();
  }

  function handleCancelar() {
    setAberto(false);
    cancelarOnClick();
  }

  function toggleModal() {
    setAberto((state) => !state);
  }

  useEffect(() => {
    if (aberto) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [aberto]);

  return (
    <ButtonContainer>
      <PrimaryButton
        outline={true}
        variante="black"
        onClick={toggleModal}
        type="button"
        buttonWidth="sm"
      >
        <FunnelFill />
        <ButtonTexto>Mais filtros</ButtonTexto>
      </PrimaryButton>
      <ModalContainer
        width={widthModal}
        show={aberto}
        data-testid="maisFiltrosContainer"
        ref={ref}
      >
        <HeaderModalContainer>
          <TituloModalContainer>
            <strong>Filtros</strong>
          </TituloModalContainer>
          <Button
            variant="link"
            onClick={limparTudoOnClick}
            style={{ textDecoration: "none", fontSize: "13px", color: "#009933" }}
          >
            Limpar tudo
          </Button>
        </HeaderModalContainer>
        <BodyModalContainer>{children}</BodyModalContainer>
        <FooterModalContainer>
          <PrimaryButton
            outline={true}
            variante="primary"
            buttonWidth="lg"
            onClick={handleCancelar}
            type="button"
          >
            Cancelar
          </PrimaryButton>
          <PrimaryButton
            type="button"
            buttonWidth="lg"
            onClick={handleAplicarFiltros}
          >
            Aplicar
          </PrimaryButton>
        </FooterModalContainer>
      </ModalContainer>
    </ButtonContainer>
  );
}

