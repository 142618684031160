import { useRequest } from "hooks/useRequest";
import { useCallback } from "react";
import { ISolicitacaoPagamento } from "../interfaces/ISolicitacaoPagamento";
import { Data } from "_utils/Data";
import { IDadosClienteReciboAdiantamento } from "features/debito/consulta/pages/Detalhes/Containers/ListarDetalhes/interface/IDadosClienteReciboAdiantamento";

interface IAtualizarFormaPgto {
	idReciboAdiantamento: number;
	valorTotal: number;
	formaPgto: "PIXBOLETO" | "TRANSFERENCIA";
	cliente: IDadosClienteReciboAdiantamento;
}

export function useSolicitacaoPagamentos() {
	const { patch, get, post, loading } = useRequest("consulta");

	const atualizarSolicitacaoPagamento = useCallback(
		({ id, dados }: { id: number; dados: Partial<ISolicitacaoPagamento> }) => {
			return patch(
				`pagamento/solicita/atualiza/${id}`,
				{
					...dados,
				},
				{},
				true,
			);
		},
		[patch],
	);

	const confirmarRecebimento = useCallback(
		async (id: number) => {
			return post(`pagamento/solicita/${id}/recebimento`, null, {}, true);
		},
		[post],
	);

	const atualizarFormaPgto = useCallback(
		({ id, dados }: { id: number; dados: IAtualizarFormaPgto }) => {
			return patch(
				`pagamento/solicita/atualiza/${id}/forma`,
				{
					...dados,
				},
				{},
				true,
			);
		},
		[patch],
	);

	const buscarComprovantes = useCallback(
		(id: number) => {
			return get(`pagamento/solicita/${id}/comprovantes`, {});
		},
		[get],
	);

	const informarPagamento = useCallback(
		(id: number, arquivo: File, dataPagamento: Date) => {
			const formData = new FormData();
			formData.append("arquivo", arquivo);
			formData.append("dataPagamento", new Data(dataPagamento).getDataEua());

			return post(`pagamento/solicita/${id}/comprovantes`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
		},
		[post],
	);

	return {
		atualizarSolicitacaoPagamento,
		buscarComprovantes,
		informarPagamento,
		atualizarFormaPgto,
		loading,
		confirmarRecebimento,
	};
}
