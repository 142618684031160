import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.project.main};
  color: ${({ theme }) => theme.colors.textLight};
`;

export const ButtonSideBar = styled.div`
  display: flex !important;
  align-items: center !important;
  color: #FFFFFF;
  background-color: transparent;
  gap: 24px;
  margin-left: 16px;
`

export const NavImage = styled.img`
	width: 135.54px;
	height: 32px;
`

export const NavItem = styled.li`
  padding: 10px 8px;
  cursor: pointer;
`

export const Arrow = styled(FontAwesomeIcon)`
`

export const ContainerNomeUsuario=styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
`

export const ContainerAbrirMenu=styled.span`
  padding: 8px;
`