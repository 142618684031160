import { ModalCustom } from "components/ModalCustom";
import { ContainerBackground } from "./style";
import { Col, Row } from "react-bootstrap";
import { PreencherTabelaVertical } from "components/PreencherTabelaVertical";

interface Props {
    show: boolean;
    handleClose: () => void;
    idsRecibos: number[];
}

export function ModalIdsRecibo({
    show,
    handleClose,
    idsRecibos,
}: Readonly<Props>) {
    const limiteQuantidadeColunas = 3;
    const limiteQuantidadeLinhas = 6;
    const quantidadeLinhas = idsRecibos.length > limiteQuantidadeLinhas ? limiteQuantidadeLinhas : idsRecibos.length;
    const quantidadeColunasNecessarias = Math.ceil(idsRecibos.length / limiteQuantidadeLinhas);
    const quantidadeColunas = quantidadeColunasNecessarias > limiteQuantidadeColunas ? limiteQuantidadeColunas : quantidadeColunasNecessarias;

    return (
        <ModalCustom
            centered
            handleClose={handleClose}
            show={show}
            title="Recibos"
        >
            <ContainerBackground>
                <PreencherTabelaVertical
                    quantidadeLinhas={quantidadeLinhas}
                    quantidadeColunas={quantidadeColunas}
                    limiteQuantidadeLinhas={limiteQuantidadeLinhas}
                    dados={idsRecibos}
                />
            </ContainerBackground>
        </ModalCustom>
    );
}

