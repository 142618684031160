import { Container } from "components/Container";
import { RedirectDetalhes } from "components/RedirectDetalhes";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { ISolicitacaoConsulta } from "interfaces/SolicitacaoConsulta";
import { converterDataHoraBr } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";

interface Props {
  dados: any[];
  loading: boolean;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (limit: number) => void;
  limit: number;
  totalRows: number;
}

const columns: IColum<ISolicitacaoConsulta>[] = [
  {
    name: <span>ID Consulta</span>,
    selector: ({ id }: ISolicitacaoConsulta) => id,
    width: "90px",
  },
  {
    name: <span>Data / Hora</span>,
    selector: ({ dataHora }: ISolicitacaoConsulta) =>
      converterDataHoraBr(dataHora),
  },
  {
    name: <span>Empresa</span>,
    selector: ({ empresa }: ISolicitacaoConsulta) => empresa,
    width: "200px",
  },
  {
    name: <span>Usuário</span>,
    selector: ({ nomeUsuario }: ISolicitacaoConsulta) => nomeUsuario,
    width: "200px",
  },
  {
    name: <span>Placas Consultadas</span>,
    selector: ({ qtdPlacasConsultadas }: ISolicitacaoConsulta) =>
      qtdPlacasConsultadas,
  },
  {
    name: <span>Débitos Disponíveis</span>,
    selector: ({ qtdDebitosDisponiveis }: ISolicitacaoConsulta) =>
      qtdDebitosDisponiveis,
  },
  {
    name: <span>Pagamentos Solicitados</span>,
    selector: ({ qtdPlacasSolicitadoPagamento }: ISolicitacaoConsulta) =>
      qtdPlacasSolicitadoPagamento,
  },
  {
    name: <span>Valor Total</span>,
    selector: ({ valorTotal }: ISolicitacaoConsulta) => {
      const valorAtualizado = valorTotal / 100 || 0;
      return formatarDinheiro(valorAtualizado);
    }
  },
  {
    name: <span>Detalhes</span>,
    selector: (consulta: ISolicitacaoConsulta) => (
      <RedirectDetalhes
        path={`/relatorios/consulta/${consulta.id}`}
        state={{ data: consulta }}
      />
    ),
    width: "90px",
  },
];

export function TableRelatorioConsultas({
  dados,
  loading,
  onChangePage,
  onChangeRowsPerPage,
  limit,
  totalRows,
}: Props) {
  return (
    <Container titulo="Relatório Consultas de Débitos">
      <Table
        data={dados}
        loading={loading}
        keyField="id"
        columns={columns}
        pagination
        paginationRowsPerPageOptions={[10, 20, 50]}
        rowsPerPage={limit}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        totalRows={totalRows}
      />
    </Container>
  );
}

