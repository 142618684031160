import { Tooltip } from "components/Tooltip";
import { FaInfoCircle } from "react-icons/fa";
import { ContentContainer, TooltipContainer, TriggerContainer } from "./style";
import temaPadrao from "_config/temas/estilo/base";

export function TooltipAdiantamento() {
	return (
		<TooltipContainer>
			<span>Adiantamento</span>
			<Tooltip
				positionRelative={false}
				trigger={
					<TriggerContainer>
						<FaInfoCircle color={temaPadrao.colors.success.main} />
					</TriggerContainer>
				}
				right={-33}
				bottom={-4.25}
        width={275}
			>
				<ContentContainer>
					<span>
						Utilize esta coluna para visualizar o recibo de adiantamento de cada lote e informar o seu pagamento.
					</span>
				</ContentContainer>
			</Tooltip>
		</TooltipContainer>
	);
}
