import { StyleSheet } from "@react-pdf/renderer";
import temaPadrao from "_config/temas/estilo/base";

export const STYLES = StyleSheet.create({
  FONT_SIZE_08: {
    fontSize: 8,
  },
  FONT_SIZE_09: {
    fontSize: 9,
  },
  FONT_SIZE_10: {
    fontSize: 10,
  },
  FONT_SIZE_12: {
    fontSize: 12,
  },
  CONTAINER: {
    marginTop: "24px",
  },
  TEXTO_CLARO: {
    color: temaPadrao.colors.neutral.neutral50,
  },
  TEXTO_QUASE_CLARO: {
    color: temaPadrao.colors.neutral.neutral60,
  },
  SECTION: {
    backgroundColor: temaPadrao.colors.neutral.neutral05,
    padding: 10,
    borderRadius: 8,
  },
  SECTION_ROW: {
    display: "flex",
    flexDirection: "row",
  },
  SECTION_COL_1: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  SECTION_COL_2: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  SECTION_COL_3: {
    width: "33%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  SECTION_COL_4: {
    width: "25%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  SECTION_COL_6: {
    width: "16.7%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  SECTION_COL_10P: {
    width: "10%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  SECTION_COL_12: {
    width: "8.3%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  NEGRITO: {
    fontWeight: "black",
    color: temaPadrao.colors.text,
  },
  DADOS_PENDENCIA: {
    backgroundColor: temaPadrao.colors.neutral.neutral10,
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
    padding: 6,
  },
  SEM_MARGIN: {
    margin: 0,
  },
  BORDER_TOP_BOTTOM: {
    borderTop: `1px solid ${temaPadrao.colors.neutral.neutral20}`,
    borderBottom: `1px solid ${temaPadrao.colors.neutral.neutral20}`,
  },
  BACKGROUND_BRANCO: {
    backgroundColor: temaPadrao.colors.white,
  },
  PADDING_04: {
    padding: 4,
  },
  PADDING_BOTTOM_12: {
    paddingBottom: 12,
  },
  IMAGEM_CONTAINER: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  GAP_02: {
    gap: 2,
  },
  CONTAINER_TITLE: {
    color: temaPadrao.colors.neutral.neutral50,
    fontWeight: "bold",
    fontSize: "12px",
    marginBottom: 8,
  },
  CONTAINER_BODY: {
    backgroundColor: temaPadrao.colors.neutral.neutral05,
    borderRadius: "10px",
    padding: "16px 0px 16px 0px",
    gap: "8px",
  },
  ITEM_TITLE: {
    fontSize: "11px",
    padding: "8px 16px 0px 16px",
  },
  ITEM_BODY: {
    fontSize: "11px",
    padding: "3px 16px 0px 16px",
    fontWeight: "bold",
  },
  CONTAINER_DADOS_COBRANCA: {
    padding: "16px",
  },
  CONTAINER_DADOS_COBRANCA_TITLE: {
    color: temaPadrao.colors.neutral.neutral50,
    fontWeight: "bold",
    fontSize: "11px",
    marginBottom: "12px",
  },
  CONTAINER_DADOS_COBRANCA_BODY: {
    fontSize: "11px"
  },
  DADOS_COBRANCA_ITEM_TITLE: {
    fontSize: "11px",
    fontWeight: "bold",
  },
  DADOS_COBRANCA_ITEM_BODY: {
    fontSize: "11px",
  },
  DADOS_RESUMO: {
    display: "flex",
    justifyContent: "space-between",
  },
  RESUMO_ITEM_TITLE: {
    fontSize: "11px",
    padding: "8px 16px 0px 16px",
    fontWeight: "bold",
  },
  RESUMO_ITEM_BODY: {
    fontSize: "11px",
    padding: "8px 16px 0px 16px",
  },
  CONTAINER_TOTAL: {
    display: "flex",
    justifyContent: "space-between",
  },
  TOTAL_ITEM: {
    fontSize: "15px",
    padding: "0px 16px 0px 16px",
    fontWeight: "bold",
  },
  FORMA_PGTO_TITLE: {
    fontWeight: "bold",
    fontSize: "12px",
  },
  FORMA_PGTO_OPCAO: {
    margin: "16px 0px 0px 4px",
  },
  FORMA_PGTO_OPCAO_LABEL: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: "8px",
  },
  FORMA_PGTO_CONTAINER_ICONS: {
    backgroundColor: temaPadrao.colors.neutral.neutral05,
    padding: "8px",
    borderRadius: "5px",
    marginRight: "8px"
  },
  FORMA_PGTO_OPCAO_LABEL_TEXTO_PRINCIPAL: {
    fontWeight: "bold",
    fontSize: "12px",
    margin: 0,
    padding: 0
  },
  FORMA_PGTO_OPCAO_LABEL_TEXTO_SECUNDARIO: {
    fontSize: "11px",
    color: temaPadrao.colors.neutral.neutral50,
    margin: "3px 0 0 0",
    padding: 0,
  },
  ICON_TRANSFERENCIA: {
    width: "16px",
    height: "16px",
  },
  CONTAINER_INFO: {
    backgroundColor: "#FFEFCC",
    padding: "10px 10px",
    borderRadius: "8px",
    alignItems: "center",
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
    fontSize: 11,
  },
  ICON_CONTAINER_INFO: {
    width: "5%",
    alignItems: "center",
  },
  TEXTO_CONTAINER_INFO: {
    width: "100%",
  },
});
