import styled from 'styled-components';

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContainerNumeros = styled.div`
  padding-top: 16px;
  width: 50%;
  text-align: center;
`

export const ContainerTituloTabela = styled.div`
  width: 50%;
  text-align: center;
`

export const ContainerDescricao = styled.div`
  margin-bottom: 24px;
`