import {
  faKey,
  faRightFromBracket,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getEmail, getNome, parseJwt, removeToken } from "_services/auth";
import { UsuarioMenuContainer, UsuarioMenuLink } from "./estilo";

interface Props {
  aberto: boolean;
}

export function UsuarioMenu({ aberto }: Props) {
  const { nome_cliente } = parseJwt();

  return (
    <UsuarioMenuContainer aberto={aberto}>
      <p>
        <strong>USUÁRIO</strong>
      </p>
      <FontAwesomeIcon icon={faUserCircle} size="5x" />
      <br />
      <br />
      <p>
        <strong>{getNome()}</strong>
      </p>
      <p>{nome_cliente}</p>
      <p>{getEmail()}</p>
      <hr />
      <UsuarioMenuLink to="/usuario/alterarSenha">
        <FontAwesomeIcon icon={faKey} /> Segurança
      </UsuarioMenuLink>
      <UsuarioMenuLink to="/" onClick={() => removeToken()}>
        <FontAwesomeIcon icon={faRightFromBracket} /> Sair
      </UsuarioMenuLink>
    </UsuarioMenuContainer>
  );
}
