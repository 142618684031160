import { Route, Routes } from "react-router-dom";
import { RelatorioExtratoVeicular } from "../pages/relatorio";

export function RelatorioExtratoVeicularRoutes() {
    return (
        <Routes>
            <Route path="/" element={<RelatorioExtratoVeicular />} />
        </Routes>
    )
}
