import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const filtrosRelatorioPagamentosRecusadosSchema = yup.object().shape({
  placa: yup
    .string()
    .optional().nullable(),
  tipoDebito: yup.number().optional().nullable(),
  vencimento: yup.string().optional().nullable(),
  dataRecusa: yup.date().optional(),
});
