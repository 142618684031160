import { IOptions } from "components/DropdownSelect";
import {
  StatusSolicitacaoPagamentoEnumV2,
  StatusSolicitacaoPagamentoEnumV2Keys
} from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";

export const TIPO_DEBITOS_OPTIONS = [
  {
    value: TipoDebitos.IPVA,
    label: "IPVA",
  },
  {
    value: TipoDebitos.Licenciamento,
    label: "Licenciamento",
  },
];

export const VENCIMENTO_OPTIONS = [
  {
    value: "vencidos",
    label: "Vencidos",
  },
  {
    value: "aVencer",
    label: "À Vencer",
  },
];

export const STATUS_SOLICITACAO_PAGAMENTO_OPTIONS: IOptions[] = StatusSolicitacaoPagamentoEnumV2Keys.map((status) => ({
  label: status,
  value: StatusSolicitacaoPagamentoEnumV2[status as keyof typeof StatusSolicitacaoPagamentoEnumV2],
}));
