import styled from "styled-components";

interface IContainer {
  aberto: boolean;
}

export const Container = styled.div<IContainer>`
  color: ${({ theme }) => theme.colors.neutral.neutral70};
  position: absolute;
  right: 0rem;
  top: 2.5rem;
  background-color: #ffffff;
  box-shadow: -4px 4px 4px rgb(0,0,0,25%);
  padding: 16px;
  border-radius: 5px;
  z-index: 100;
  font-size: 14px;
  display: ${({ aberto }) => (aberto ? "block" : "none")};
`;

export const Titulo = styled.div`
  font-weight: 600;
  text-align: left;
  margin-bottom: 8px;

  svg {
    width: 14px;
    height: 14px;
    margin-bottom: 5px;
  }
`

export const Opcao = styled.div`
  font-weight: 400;
  text-align: left;
  padding: 8px 0px 8px 0px;
`

export const TextoOpcao = styled.span`
  cursor: pointer;
`
