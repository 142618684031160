import styled from "styled-components";

export const Container = styled.div`
  padding-top: 1rem;
  padding-left: 2.75rem;
  display: grid;
  gap: 1rem;
`;

export const DadosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 4rem;
`;

export const DadosBox = styled.div`
font-size: 14px;
`;

export const DadosTitle = styled.strong``;

export const DadosText = styled.p``;

export const AtencaoContainer = styled.div``;