import { CustomInput } from "components/CustomInput";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { Col, Form } from "react-bootstrap";
import { ButtonsContainer, DatePickerContainer, FormContainer, InputContainer } from "./style";
import { useEffect } from "react";
import { PrimaryButton } from "components/Button/style";
import { FaFilter } from "react-icons/fa";
import { MaisFiltros } from "components/MaisFiltros";
import { Controller, useForm } from "react-hook-form";
import { IFiltrosListarConsultasExtratoVeicular } from "../../interfaces/IFiltrosListarConsultasExtratoVeicular";
import { yupResolver } from "@hookform/resolvers/yup";
import { filtrosExtratoVeicularSchema } from "../../validators/filtrosExtratoVeicularValidator";
import { useMaisFiltros } from "hooks/useMaisFiltros";
import ReactDatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { useMaskInput } from "hooks/useMaskInput";

interface Props {
  handleSubmitForm: (dados: IFiltrosListarConsultasExtratoVeicular) => void;
  agrupamentoOptions: IOptions[];
  combosConsulta: IOptions[];
}

export function FiltrosContainer({ handleSubmitForm, agrupamentoOptions, combosConsulta }: Readonly<Props>) {
  const { handleAplicarMaisFiltros, handleFormSubmit, handleNaoUsarMaisFiltros } = useMaisFiltros();
  const { mDataRangeInput } = useMaskInput();

  const {
    control,
    handleSubmit,
    reset,
  } = useForm<IFiltrosListarConsultasExtratoVeicular>({
    resolver: yupResolver(filtrosExtratoVeicularSchema),
  });

  useEffect(() => {
    reset();
  }, [reset]);

  function handleDataDigitada(value: string) {
    const dateRangePadrao = /^\d{2}\/\d{2}\/\d{4}-\d{2}\/\d{2}\/\d{4}$/;
    if (value && dateRangePadrao.test(value.replaceAll(" ", ""))) {
      const datas = value
        .replaceAll(" ", "")
        .split("-")
        .map((item: string) => {
          const partes = item.split("/");
          return new Date(Number(partes[2]), Number(partes[1]) - 1, Number(partes[0]));
        });

      return datas;
    }
  }

  function handleLimparFiltros() {
    reset({
      chassi: null,
      cobrar: null,
      dataFinal: undefined,
      dataInicial: undefined,
      empresa: null,
      id: null,
      placa: null,
      renavam: null,
      tipoConsulta: null,
    });
  }

  return (
    <Form
      onSubmit={handleSubmit((dados) => {
        if (dados.periodo?.length) {
          dados.dataInicial = dados.periodo[0];
          dados.dataFinal = dados.periodo[1];
        }

        handleFormSubmit({
          dados,
          excluir: ["cobrar", "empresa", "renavam", "chassi"],
          resetCallback: reset,
          submitCallback: handleSubmitForm,
        });
      })}
    >
      <FormContainer>
        <InputContainer lg="1">
          <Form.Label>ID Consulta</Form.Label>
          <Controller
            control={control}
            name="id"
            render={({ field: { onChange, value } }) => (
              <CustomInput placeholder="Busque por ID" onChange={onChange} value={value ?? ""} />
            )}
          />
        </InputContainer>
        <Col lg="2">
          <Form.Label>Placa</Form.Label>
          <Controller
            control={control}
            name="placa"
            render={({ field: { onChange, value } }) => (
              <CustomInput placeholder="Busque por placa" onChange={onChange} value={value ?? ""} />
            )}
          />
        </Col>
        <Col lg="3">
          <Form.Label>Período de consulta</Form.Label>
          <Controller
            name="periodo"
            control={control}
            render={({ field: { onChange: onChangeHook, value } }) => (
              <DatePickerContainer>
                <ReactDatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  locale={br}
                  placeholderText="Período de consulta"
                  startDate={value ? value[0] : null}
                  endDate={value ? value[1] : null}
                  selectsRange={true}
                  onChange={(update) => {
                    onChangeHook(update);
                  }}
                  onChangeRaw={(event: any) => {
                    if (!event.target.value) {
                      return;
                    }
                    onChangeHook(handleDataDigitada(mDataRangeInput(event)));
                  }}
                />
              </DatePickerContainer>
            )}
          />
        </Col>
        <Col lg="2">
          <Controller
            control={control}
            name="tipoConsulta"
            render={({ field: { onChange, value } }) => (
              <DropdownSelect
                onSelect={onChange}
                options={combosConsulta}
                selectedValue={value}
                title="Tipo de consulta"
              />
            )}
          />
        </Col>
        <Col lg="2">
          <ButtonsContainer>
            <PrimaryButton type="submit">
              <FaFilter />
              Filtrar
            </PrimaryButton>
            <PrimaryButton variante="primary" outline type="button" onClick={handleLimparFiltros}>
              Limpar
            </PrimaryButton>
          </ButtonsContainer>
        </Col>
        <Col>
          <MaisFiltros
            cancelarOnClick={() => {
              handleNaoUsarMaisFiltros({
                excluir: ["cobrar", "empresa", "renavam", "chassi"],
                resetCallback: reset,
              });
            }}
            handleClickAplicarFiltros={handleAplicarMaisFiltros}
            limparTudoOnClick={() => {
              handleNaoUsarMaisFiltros({
                excluir: ["cobrar", "empresa", "renavam", "chassi"],
                resetCallback: reset,
              });
            }}
          >
            <div>
              <Form.Label>Renavam</Form.Label>
              <Controller
                control={control}
                name="renavam"
                render={({ field: { onChange, value } }) => (
                  <CustomInput placeholder="Busque por renavam" value={value ?? ""} onChange={onChange} />
                )}
              />
            </div>
            <div>
              <Form.Label>Chassi</Form.Label>
              <Controller
                control={control}
                name="chassi"
                render={({ field: { onChange, value } }) => (
                  <CustomInput placeholder="Busque por chassi" value={value ?? ""} onChange={onChange} />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="empresa"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={agrupamentoOptions}
                    searchable={true}
                    onSelect={onChange}
                    selectedValue={value}
                    title="Empresa"
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="cobrar"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={[
                      { label: "Cobrado do cliente", value: "SIM" },
                      { label: "Isento de cobrança", value: "NAO" },
                    ]}
                    onSelect={onChange}
                    selectedValue={value}
                    title="Cobrança"
                  />
                )}
              />
            </div>
          </MaisFiltros>
        </Col>
      </FormContainer>
    </Form>
  );
}

