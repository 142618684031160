import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../../../style";
import { IRegistrosLeiloes } from "../../../../../DadosLeiloes/containers/BodyLeiloes";
import { BodyLeiloesPdf } from "../BodyLeiloesPdf";

interface Props {
  textoRow: string;
  dadosLeilao: IRegistrosLeiloes;
}

export function RowLeiloesPDF({ dadosLeilao, textoRow }: Readonly<Props>) {
  return (
    <View>
      <View
        style={[
          STYLES.SECTION_ROW,
          STYLES.DADOS_PENDENCIA,
        ]}
      >
        <View style={[STYLES.SEM_MARGIN]}>
          <Text style={[STYLES.FONT_SIZE_09]}>{textoRow}</Text>
        </View>
      </View>
      {dadosLeilao && <BodyLeiloesPdf leilao={dadosLeilao} />}
    </View>
  );
}
