import styled from "styled-components";

export enum StatusExtratoVeicular {
  COM_PENDENCIA = "Com pendência",
  NADA_CONSTA = "Nada consta",
  CONSULTANDO = "Consultando",
  FALHA = "Falha",
  SUCESSO = "Sucesso",
  DADOS_INCONSISTENTES = "Dados inconsistentes",
}

interface Props {
  statusConsulta: string;
}

export const Container = styled.div<Props>`
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 0.25rem 0;

  ${({ statusConsulta, theme }) =>
    statusConsulta === StatusExtratoVeicular.CONSULTANDO &&
    `
    background-color: ${theme.colors.neutral.neutral00};
    color: ${theme.colors.neutral.neutral60};
    border: 1px solid ${theme.colors.neutral.neutral60};
  `}

  ${({ statusConsulta, theme }) =>
    statusConsulta === StatusExtratoVeicular.NADA_CONSTA &&
    `
    background-color: ${theme.colors.success.bgColor};
    color: ${theme.colors.success.main};
    border: 1px solid ${theme.colors.success.main};
  `}

  ${({ statusConsulta, theme }) =>
    statusConsulta === StatusExtratoVeicular.COM_PENDENCIA &&
    `
    background-color: ${theme.colors.warning.bgColor};
    color: ${theme.colors.warning.main};
    border: 1px solid ${theme.colors.warning.main};
  `}

  ${({ statusConsulta, theme }) =>
    statusConsulta === StatusExtratoVeicular.FALHA &&
    `
    background-color: ${theme.colors.danger.bgColor};
    color: ${theme.colors.danger.main};
    border: 1px solid ${theme.colors.danger.main};
  `}

  ${({ statusConsulta, theme }) =>
    statusConsulta === StatusExtratoVeicular.DADOS_INCONSISTENTES &&
    `
    background-color: ${theme.colors.tags.warning02.bgColor};
    color: ${theme.colors.tags.warning02.textColor};
    border: 1px solid ${theme.colors.tags.warning02.textColor};
  `}
`;

Container.defaultProps = {
  statusConsulta: StatusExtratoVeicular.CONSULTANDO,
};

