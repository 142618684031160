import { Route, Routes } from "react-router-dom";
import { ListarSolicitacoesLicenciamento } from "../emissao/pages/Listar";
import { DetalheEmissao } from "../emissao/pages/Detalhes";
import { DetalheConsulta } from "../consulta/pages/Detalhes";
import { ListarConsultas } from "../consulta/pages/Listar";

export function LicenciamentoRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ListarSolicitacoesLicenciamento />} />
      <Route path="/consulta" element={<ListarConsultas />} />
      <Route path="/emissao/:id" element={<DetalheEmissao />} />
      <Route path="/consulta/:id" element={<DetalheConsulta />} />
    </Routes>
  );
}
