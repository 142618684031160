import { Page } from "containers/Page";
import { ListarDetalhesConsulta } from "./containers/Listagem";
import { Filtros } from "./containers/Filtros";
import { useConsultaLicenciamento } from "../../hooks/useConsultaLicenciamento";
import { IFiltro } from "./containers/Filtros/Interface/IFiltro";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IDetalhesConsulta } from "./containers/interfaces/IDetalhesConsulta";
import { StatusConsulta } from "../../enum/StatusConsulta";
import { formataDataISOParaBR } from "_utils/Data";
import { utils, writeFileXLSX } from "xlsx";
import { ModalEmitirLicenciamentos } from "./containers/ModalEmitirLicenciamentos";
import { useLinha } from "features/debito/pagamento/pages/Detalhes/hook/useLinha";
import { ModalConfirmarEmitirLicenciamentosCustos } from "./containers/ModalConfirmarEmitirLicenciamentosCustos";
import { ModalConfirmarEmitirLicenciamentosSelecionados } from "./containers/ModalConfirmarEmitirLicenciamentosSelecionados";
import { ContextPermissao } from "hooks/ContextPermissao";
import { EmitirLicenciamento } from "features/licenciamento/emissao/pages/Listar/containers/ModalEmitirLicenciamento/containers/BodySP/containers/IndividualSP";
import { useEmpresas } from "hooks/useEmpresas";
import { ModalEmitirSucesso } from "./containers/ModalEmitirSucesso";
import { ModalSucessoReconsulta } from "./containers/ModalSucessoReconsulta";
import { ModalCrlvsIndisponiveis } from "./containers/ModalCrlvsIndisponiveis";

export function DetalheConsulta() {
    const { id: idConsulta } = useParams();
    const {
        buscarDetalhes,
        detalhesConsulta,
        setFiltrosDetalhes,
        filtrosDetalhes,
        page,
        limit,
        totalRows,
        setPage,
        setLimit,
        consultarNovamente,
        loading,
        setIdsLicenciamentos,
        idsLicenciamentos,
        emitirLicenciamentosConsulta,
        verificarCrlvsEmissao,
    } = useConsultaLicenciamento();

    const [showModalSucessoReconsulta, setShowModalSucessoReconsulta] = useState<boolean>(false);
    const [showModalCrlvsIndisponiveis, setShowModalCrlvsIndisponiveis] = useState<boolean>(false);
    function handlePageChange(page: number): void {
        setPage(page);
    }

    function handleRowsPerPageChange(limit: number): void {
        setLimit(limit);
    }

    const handleSubmit = (dados: IFiltro) => {
        setFiltrosDetalhes(dados)
    }

    useEffect(() => {
        if (idConsulta) {
            buscarDetalhes(idConsulta);
        }
    }, [filtrosDetalhes, page, limit]);

    const onClickConsultar = async () => {
        if (idConsulta) {
            const { hasErro } = await consultarNovamente(idConsulta);

            if (hasErro) {
                return;
            }

            setShowModalSucessoReconsulta(true);
            await buscarDetalhes(idConsulta);

        }
    }

    const handleExport = () => {
        const dadosPlanilha = detalhesConsulta.map((item: IDetalhesConsulta) => ({
            Placa: item.placa?.toUpperCase(),
            Renavam: item.renavam,
            Chassi: item.chassi,
            "CPF/CNPJ": item.cpfCnpjProprietario,
            UF: item.uf?.toUpperCase(),
            "Cód. de Retorno": item.codigoRetorno,
            "Exercício": item.ano,
            "Data da emissão": item.dataEmissao ? formataDataISOParaBR(item.dataEmissao) : "",
            "Observação": item.observacao ?? "",
            "Status da Consulta": StatusConsulta[Number(item.statusEmissao)],
            "Data da consulta": item.dataConsulta ? formataDataISOParaBR(item.dataConsulta) : "",
            "Tentativas de consulta": item.tentativas,
            "Recibo": item.idRecibo,
        }));

        const planilha = utils.json_to_sheet(dadosPlanilha);
        planilha["!cols"] = [
            { wch: 12 },
            { wch: 15 },
            { wch: 15 },
            { wch: 17 },
            { wch: 5 },
            { wch: 15 },
            { wch: 12 },
            { wch: 15 },
            { wch: 20 },
            { wch: 17 },
            { wch: 15 },
            { wch: 20 },
            { wch: 10 },
        ];
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, planilha, "Detalhes");
        writeFileXLSX(workBook, `Consulta_CRLV_${idConsulta}.xlsx`);
    }

    const { verificarEmpresa } = useContext(ContextPermissao);
    const [dadosEmissao, setDadosEmissao] = useState<any>();
    const usuarioLW = verificarEmpresa();
    const submitFormEmitir = (dados: any) => {        
        setDadosEmissao(dados)
        setShowModalEmitir(false);

        if ((
            (selectedRows.length && selectedRows[0].uf == "SP") || (!selectedRows.length && detalhesConsulta[0].uf == "SP")
        ) && usuarioLW) {
            setShowConfirmarCustos(true);
            return;
        }
        emitirLicenciamentos();
    }

    const [showModalEmitir, setShowModalEmitir] = useState<boolean>(false);
    const handleCloseModal = () => {
        setShowModalEmitir(false);
    }

    const onClickEmitir = async () => {
        const crlvsIndisponiveis = await verificarCrlvsEmissao(idConsulta!);

        if (crlvsIndisponiveis) {
            setShowModalCrlvsIndisponiveis(true);
            return;
        }

        const statusConsultado = 1;
        if (selectedRows.length && selectedRows.length == detalhesConsulta.filter((item) => Number(item.statusEmissao) == statusConsultado).length) {
            setShowConfirmarSelecionados(true);
            return;
        }
        setShowModalEmitir(true);
    }

    const { setSelectedRows, selectedRows, limparSelectedRows } = useLinha();
    const getLinhas = (linhas: any[]) => {
        setSelectedRows(linhas);
        setIdsLicenciamentos(linhas.map((item) => item.id));
        setClearSelectedRows(false);
    };

    const [showConfirmarCustos, setShowConfirmarCustos] = useState<boolean>(false);
    const handleCloseConfirmarCustos = () => {
        setShowConfirmarCustos(false);
    }
    const handleConfirmarCustos = () => {
        setShowConfirmarCustos(false);
        emitirLicenciamentos();
    }
    const [showConfirmarSelecionados, setShowConfirmarSelecionados] = useState<boolean>(false);
    const handleCloseConfirmarSelecionados = () => {
        setShowConfirmarSelecionados(false);
    }
    const handleConfirmarSelecionados = () => {
        setShowConfirmarSelecionados(false);
        setShowModalEmitir(true);
    }

    const [showSucesso, setShowSucesso] = useState<boolean>(false);
    const [idRetornoEmitir, setIdRetornoEmitir] = useState<number>(0);
    const handleCloseSucesso = () => {
        setShowSucesso(false);
    }

    const { empresasAgrupamento } = useEmpresas();

    function buscarNomeEmpresa(idCliente: NullableString) {
        return empresasAgrupamento.find((item) => item.idClienteAgrupamento === Number(idCliente))?.razaoSocial;
    }

    async function emitirLicenciamentos() {
        if (idConsulta) {
            const { hasErro, data } = await emitirLicenciamentosConsulta(idConsulta, {
                cobrar: dadosEmissao.cobrar,
                idCliente: dadosEmissao.empresa,
                nomeCliente: buscarNomeEmpresa(dadosEmissao.empresa),
                plataforma: "DEBITOS",
                emitir: EmitirLicenciamento.Sim,
                motivoIsencao: dadosEmissao.motivoIsencao ?? "",
                idsLicenciamentos: idsLicenciamentos,
                exercicio: Number(dadosEmissao.exercicio)
            });

            if (hasErro) {
                return;
            }

            setClearSelectedRows(true);
            setIdRetornoEmitir(data.id)
            setShowSucesso(true);
        }
    }

    const [clearSelectedRows, setClearSelectedRows] = useState<boolean>(false);

    return (
        <Page lastPage="Licenciamento" title="Consultar Licenciamentos">
            <Filtros
                handleSubmitFiltros={handleSubmit}
            />
            <ListarDetalhesConsulta
                idConsulta={idConsulta}
                loading={loading}
                dados={detalhesConsulta}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                totalRows={totalRows}
                rowsPerPage={limit}
                setSelectedRows={getLinhas}
                onClickConsultar={onClickConsultar}
                onClickEmitir={onClickEmitir}
                onClickExportar={handleExport}
                clearSelectedRows={clearSelectedRows}
                disabledBotaoConsultar={idsLicenciamentos.length > 0}
            />
            <ModalEmitirLicenciamentos
                showModal={showModalEmitir}
                handleCloseModal={handleCloseModal}
                uf="SP"
                submitForm={submitFormEmitir}
            />
            <ModalConfirmarEmitirLicenciamentosCustos
                show={showConfirmarCustos}
                handleClose={handleCloseConfirmarCustos}
                handleConfirmarEmissao={handleConfirmarCustos}
            />
            <ModalConfirmarEmitirLicenciamentosSelecionados
                show={showConfirmarSelecionados}
                handleClose={handleCloseConfirmarSelecionados}
                handleConfirmarEmissao={handleConfirmarSelecionados}
            />
            <ModalEmitirSucesso
                show={showSucesso}
                handleClose={handleCloseSucesso}
                idSolicitacao={idRetornoEmitir}
            />
            <ModalSucessoReconsulta
                show={showModalSucessoReconsulta}
                handleClose={() => setShowModalSucessoReconsulta(false)}
            />
            <ModalCrlvsIndisponiveis
                show={showModalCrlvsIndisponiveis}
                handleClose={() => setShowModalCrlvsIndisponiveis(false)}
            />
        </Page>
    );
}

