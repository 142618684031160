import { Stronger } from "components/Stronger";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";

export interface IDadosGravame {
  statusVeiculo: string;
  numeroRestricao: number;
  numeroContrato: number;
  dataContrato: string;
  cpfCnpjFinanciado: string;
  responsavel: string;
  agenteFinanceiro: string;
  codigo: number;
  nomeContratante: string;
  situacaoRestricao: string;
  tipoFinanciamentoRestricao: string;
  dataHoraAtualizacao: string;
}

interface Props {
  gravames: IDadosGravame[] | null;
}

export function DadosGravame({ gravames }: Readonly<Props>) {
  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={gravames?.length ? IconePendencia : IconeNadaConsta} />
        <Section.Titulo titulo="Gravame" />
      </Section.Header>
      <Section.Body>
        {gravames?.length ? (
          gravames.map((gravame) => (
            <>
              <Section.Row key={gravame.numeroContrato}>
                <Section.Col>
                  <span>Situação restrição</span> <br />
                  <Stronger>{gravame.situacaoRestricao ?? "-"}</Stronger>
                </Section.Col>
                <Section.Col>
                  <span>Número contrato</span> <br />
                  <Stronger>{gravame.numeroContrato ?? "-"}</Stronger>
                </Section.Col>
                <Section.Col>
                  <span>Tipo financiamento da restrição</span> <br />
                  <Stronger>{gravame.tipoFinanciamentoRestricao ?? "-"}</Stronger>
                </Section.Col>
                <Section.Col>
                  <span>Data contrato</span> <br />
                  <Stronger>{gravame.dataContrato ?? "-"}</Stronger>
                </Section.Col>
              </Section.Row>
              <Section.Row>
                <Section.Col lg="3">
                  <span>Nome contratante</span> <br />
                  <Stronger>{gravame.nomeContratante ?? "-"}</Stronger>
                </Section.Col>
                <Section.Col lg="3">
                  <span>Agente financeiro</span> <br />
                  <Stronger>{gravame.agenteFinanceiro ?? "-"}</Stronger>
                </Section.Col>
                <Section.Col lg="3">
                  <span>Data de atualização</span> <br />
                  <Stronger>{gravame.dataHoraAtualizacao ?? "-"}</Stronger>
                </Section.Col>
              </Section.Row>
            </>
          ))
        ) : (
          <Section.Row>
            <Section.Col>
              <span>Gravame</span> <br />
              <Stronger>Nada consta</Stronger>
            </Section.Col>
          </Section.Row>
        )}
      </Section.Body>
    </Section.Root>
  );
}

