import { yupResolver } from "@hookform/resolvers/yup";
import { FilterButton } from "components/Button/style";
import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { CustomDatePicker } from "components/DatePicker";
import { DropdownSelect } from "components/DropdownSelect";
import { IFiltrosConsultas } from "features/relatorios/consultas/interfaces/FiltrosConsultas";
import { filtrosRelatorioConsultasSchema } from "features/relatorios/consultas/validate/validator";
import {
  ButtonContainer,
  InputContainer,
} from "features/relatorios/pagamento/pages/relatorio/containers/FiltrosRelatorioPagamentos/style";
import { ContextPermissao } from "hooks/ContextPermissao";
import { useEmpresas } from "hooks/useEmpresas";
import { useMaskInput } from "hooks/useMaskInput";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter } from "react-icons/fa";
import { DatePickerContainer } from "./style";
import { MaisFiltros } from "components/MaisFiltros";
import { useMaisFiltros } from "hooks/useMaisFiltros";

interface IFiltrosRelatorioConsultas {
  handleSubmitFiltros: (data: IFiltrosConsultas) => void;
}

export function FiltrosRelatorioConsultas({
  handleSubmitFiltros,
}: IFiltrosRelatorioConsultas) {
  const {
    handleAplicarMaisFiltros,
    handleFormSubmit,
    handleNaoUsarMaisFiltros,
  } = useMaisFiltros();

  const { mInputPlaca } = useMaskInput();
  const { verificarEmpresa } = useContext(ContextPermissao);

  const usuarioLW = verificarEmpresa();

  const { empresasOptions, agrupamentoOptions, criarOptionsAgrupamento } = useEmpresas();
  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);

  const { handleSubmit, control, reset } = useForm<IFiltrosConsultas>({
    resolver: yupResolver(filtrosRelatorioConsultasSchema),
  });

  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null]);

  const [startDate, endDate] = dateRange;

  function handleDateRangeSelect(update: Array<Date | null>) {
    setDateRange(update);
  }

  return (
    <Container titulo="Filtros">
      <Form
        noValidate
        onSubmit={handleSubmit((dados) => {
          handleFormSubmit<IFiltrosConsultas>({
            dados,
            resetCallback: reset,
            submitCallback: handleSubmitFiltros,
            excluir: ["renavam"],
          });
        })}
      >
        <Row>
          <InputContainer lg="2">
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    value={value!}
                    placeholder="Digite a placa"
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          {(usuarioLW || (agrupamentoOptions && agrupamentoOptions.length)) ? (
            <Col lg="2">
              <Form.Group>
                <Controller
                  control={control}
                  name="idEmpresa"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      options={usuarioLW ? empresasOptions : agrupamentoOptions}
                      title="Empresa"
                      searchable
                      selectedValue={value}
                      onSelect={onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          ) : null}
          <InputContainer lg="2">
            <Form.Group>
              <Form.Label>ID Consulta</Form.Label>
              <Controller
                control={control}
                name="idConsulta"
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    value={value!}
                    placeholder="Digite o ID"
                    onChange={onChange}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="dataConsulta"
                render={({ field: { onChange } }) => (
                  <DatePickerContainer>
                    <span>Data da Consulta</span>
                    <CustomDatePicker
                      onChange={(update) => {
                        onChange(update);
                        handleDateRangeSelect(update);
                      }}
                      rangeSelect={true}
                      startDate={startDate!}
                      endDate={endDate!}
                    />
                  </DatePickerContainer>
                )}
              />
            </Form.Group>
          </Col>
          <ButtonContainer lg="2">
            <FilterButton type="submit" disabled={false} loading={false}>
              <FaFilter /> Filtrar
            </FilterButton>
            <FilterButton
              type="reset"
              onClick={() => {
                reset({
                  dataConsulta: null,
                  idConsulta: null,
                  idEmpresa: null,
                  placa: null,
                  renavam: null,
                });
                setDateRange([null, null]);
              }}
              disabled={false}
              loading={false}
              outline={true}
            >
              Limpar
            </FilterButton>
          </ButtonContainer>
          <Col lg="2">
            <MaisFiltros
              cancelarOnClick={() => {
                handleNaoUsarMaisFiltros<IFiltrosConsultas>({
                  excluir: ["renavam"],
                  resetCallback: reset,
                });
              }}
              handleClickAplicarFiltros={handleAplicarMaisFiltros}
              limparTudoOnClick={() => {
                handleNaoUsarMaisFiltros<IFiltrosConsultas>({
                  excluir: ["renavam"],
                  resetCallback: reset,
                });
              }}
            >
              <Form.Group>
                <Form.Label>Renavam</Form.Label>
                <Controller
                  control={control}
                  name="renavam"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      value={value ?? ""}
                      placeholder="Digite o renavam"
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </MaisFiltros>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

