import { Page } from "containers/Page";
import { toastErro } from "components/Toast";
import { useEffect } from "react";
import { useRelatorioConsultas } from "../../hooks/useRelatorioConsultas";
import { FiltrosRelatorioConsultas } from "./containers/FiltrosRelatorioConsultas";
import { TableRelatorioConsultas } from "./containers/TableRelatorioConsultas";

export function RelatorioConsultas() {
  const {
    buscarConsultas,
    error,
    page,
    setPage,
    limit,
    setLimit,
    dados,
    loading,
    totalRows,
    setFiltros,
    filtros,
  } = useRelatorioConsultas();

  useEffect(() => {
    if (error?.hasErro && error.data.status != "404") {
      toastErro(error.data.erro);
    }
  }, [error]);

  useEffect(() => {
    buscarConsultas();
  }, [filtros, buscarConsultas, page, limit]);

  function handleSubmitFiltros(data: any) {
    if (data.dataConsulta && data.dataConsulta[0] && !data.dataConsulta[1]) {
      data.dataConsulta[1] = data.dataConsulta[0];
    }
    setFiltros(data);
  }

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }

  function handlePageChange(page: number) {
    setPage(page);
  }

  return (
    <Page title="Relatórios de Consultas e Solicitações">
      <FiltrosRelatorioConsultas handleSubmitFiltros={handleSubmitFiltros} />
      <TableRelatorioConsultas
        totalRows={totalRows}
        limit={limit}
        dados={dados}
        loading={loading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
      />
    </Page>
  );
}
