import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";

export const tiposDebitos = [
  {
    value: TipoDebitos.IPVA,
    label: "IPVA",
  },
  {
    value: TipoDebitos.Licenciamento,
    label: "Licenciamento",
  },
];
