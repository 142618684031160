import { Row } from "react-bootstrap";
import styled from "styled-components";

export const InputsLoteContainer = styled(Row)`
  padding: 1rem 0;
  height: 110px;

  .arquivoContainer {
    display: grid;
    gap: 0.25rem;
  }
`;
