import { IOptions } from "components/DropdownSelect";

export const MotivosIsencaoOptions: IOptions[] = [
  {
    label: "Processo operacional",
    value: "Processo_operacional",
  },
  {
    label: "Cortesia",
    value: "Cortesia",
  },
  {
    label: "Cobrança já realizada",
    value: "Cobrança_já_realizada",
  },
];
