import { DropdownContainer, DropdownItemButton, DropdownItemContainer } from "./style";
import { FaDollarSign, FaEdit, FaEye } from "react-icons/fa";
import { StatusAdiantamentoConst, StatusAdiantamentoEnum } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";
import { CompoundDropdown } from "components/CompoundDropdown";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { handleDisabled } from "./_utils/handleDisabled";
import { handleIcone } from "./_utils/handleIcone";
import { ModalReciboAdiantamentoRelatorio } from "../../ModalReciboAdiantamento";
import { useSolicitarPagamento } from "features/debito/consulta/pages/Detalhes/hook/useSolicitarPagamento";
import { toastErro } from "components/Toast";
import { IDadosClienteReciboAdiantamento } from "features/debito/consulta/pages/Detalhes/Containers/ListarDetalhes/interface/IDadosClienteReciboAdiantamento";
import { IDadosPorTipoDebito } from "features/debito/consulta/pages/Detalhes/Containers/ListarDetalhes/interface/IDadosPorTipoDebito";
import { IPagamento } from "features/relatorios/pagamento/interfaces/Pagamento";
import { mergeArquivosParaPdf } from "_utils/pdf/MergeArquivosParaPdf";
import { ModalInformarAdiantamento } from "../../ModalInformarAdiantamento";
import { ModalReciboAdiantamento } from "features/debito/consulta/pages/Detalhes/Containers/ModalReciboAdiantamento";
import { useSolicitacaoPagamentos } from "features/debito/pagamento/hooks/useSolicitacaoPagamentos";
import { ModalSucessoAtualizarSolicitarPagamento } from "../../ModalSucessoAtualizarSolicitarPagamento";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";
import { IDadosBanco } from "features/debito/consulta/interfaces/IDadosBanco";

export interface StatusAdiantamentoProps {
	statusAdiantamento: StatusAdiantamentoEnum;
	idSolicitacaoPagamento: number;
	formaPgtoSalva: FormaPgtoEnum;
	idReciboAdiantamento: number;
	buscarDados: () => void;
}

export function StatusAdiantamentoRelatorioPagamento({
	idSolicitacaoPagamento,
	statusAdiantamento,
	formaPgtoSalva,
	idReciboAdiantamento,
	buscarDados,
}: Readonly<StatusAdiantamentoProps>) {
	const [dadosBanco, setDadosBanco] = useState<IDadosBanco>({ codigoBanco: "", nomeBanco: "", agencia: "", numeroConta: "" });
	const [showModalInformarAdiantamento, setShowModalInformarAdiantamento] = useState(false);
	const [showModalAlterarFormaPgto, setShowModalAlterarFormaPgto] = useState(false);
	const [showModalVisualizarRecibo, setShowModalVisualizarRecibo] = useState(false);
	const [valorTotal, setValorTotal] = useState<number>(0);
	const [dadosPorTipoDebito, setDadosPorTipoDebito] = useState<IDadosPorTipoDebito[]>([]);
	const [dadosClienteReciboAdiantamento, setDadosClienteReciboAdiantamento] = useState<IDadosClienteReciboAdiantamento>({
		endereco: "",
		numero: "",
		bairro: "",
		cidade: "",
		estado: "",
		cep: "",
		cnpj: "",
		razaoSocial: "",
		nomeFantasia: "",
		id: 0,
	})
	const [pagamentos, setPagamentos] = useState<IPagamento[]>([]);
	const [linkBoleto, setLinkBoleto] = useState<string>("");
	const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
	const [maisOpcoes, setMaisOpcoes] = useState<boolean>(false);
	const [formaPgto, setFormaPgto] = useState<FormaPgtoEnum>(formaPgtoSalva);
	const [codigoPix, setCodigoPix] = useState<string>("");
	const [showModalSucessoSolicitacaoPagamento, setShowModalSucessoSolicitacaoPagamento] = useState<boolean>(false);

	const disableButtons = useMemo(() => handleDisabled(statusAdiantamento, formaPgto), [statusAdiantamento]);
	const { buscarDadosReciboAdiantamento, loadingPagamento } = useSolicitarPagamento();
	const { atualizarFormaPgto, loading } = useSolicitacaoPagamentos();

	const handleShowModalRecibo = async (funcaoShow: Dispatch<SetStateAction<boolean>>) => {
		funcaoShow(true);
		const { data, hasErro } = await buscarDadosReciboAdiantamento(idSolicitacaoPagamento)
		if (hasErro) {
			toastErro(data.message);
			funcaoShow(false);
			return;
		}

		if (
			!data.cliente.endereco ||
			!data.cliente.numero ||
			!data.cliente.bairro ||
			!data.cliente.cidade ||
			!data.cliente.estado ||
			!data.cliente.cep ||
			!data.cliente.cnpj ||
			!data.cliente.razaoSocial ||
			!data.cliente.nomeFantasia
		) {
			toastErro("Não foi possível obter todos os dados necessários para o pagamento");
			return;
		}

		setDadosClienteReciboAdiantamento({
			endereco: data.cliente.endereco,
			numero: data.cliente.numero,
			bairro: data.cliente.bairro,
			cidade: data.cliente.cidade,
			estado: data.cliente.estado,
			cep: data.cliente.cep,
			cnpj: data.cliente.cnpj,
			razaoSocial: data.cliente.razaoSocial,
			nomeFantasia: data.cliente.nomeFantasia,
			id: data.cliente.id,
		});

		setDadosBanco(data.banco);
		setValorTotal(data.solicitacaoPagamento.valorTotal);
		setDadosPorTipoDebito([
			{
				descricao: "Pagamento IPVA",
				quantidade: Number(data.debitos.dadosPorTipoDebito.IPVA.quantidade),
				valor: Number(data.debitos.dadosPorTipoDebito.IPVA.valor),
			},
			{
				descricao: "Pagamento Licenciamento",
				quantidade: Number(data.debitos.dadosPorTipoDebito.Licenciamento.quantidade),
				valor: Number(data.debitos.dadosPorTipoDebito.Licenciamento.valor),
			},
			{
				descricao: "Pagamento Multa",
				quantidade: Number(data.debitos.dadosPorTipoDebito.Multa.quantidade),
				valor: Number(data.debitos.dadosPorTipoDebito.Multa.valor),
			},
			{
				descricao: "Pagamento DPVAT",
				quantidade: Number(data.debitos.dadosPorTipoDebito.DPVAT.quantidade),
				valor: Number(data.debitos.dadosPorTipoDebito.DPVAT.valor),
			},
		]);

		if (data.pagamentos && data.pagamentos.length) {
			setPagamentos(data.pagamentos);
		}

		if (data.solicitacaoPagamento && data.solicitacaoPagamento.linkBoleto) {
			setLinkBoleto(data.solicitacaoPagamento.linkBoleto);
		}
	}

	const resetDados = (resetFormaPgto: boolean = false) => {
		setDadosClienteReciboAdiantamento({
			endereco: "",
			numero: "",
			bairro: "",
			cidade: "",
			estado: "",
			cep: "",
			cnpj: "",
			razaoSocial: "",
			nomeFantasia: "",
			id: 0,
		})

		setValorTotal(0);
		setDadosPorTipoDebito([]);
		setPagamentos([]);
		if (resetFormaPgto) {
			setFormaPgto(formaPgtoSalva);
		}
	}

	const abrirPdfReciboAdiantamento = async () => {
		const downloadElement = document.getElementById(`downloadPDF_${idReciboAdiantamento}`);

		try {
			setLoadingPdf(true);
			if (downloadElement && downloadElement instanceof HTMLAnchorElement) {
				const itensPdf = [downloadElement.href];
				if (formaPgto === FormaPgtoEnum.PIXBOLETO) {
					itensPdf.push(linkBoleto);
				}
				const blobPdf = await mergeArquivosParaPdf(itensPdf)
				const url = URL.createObjectURL(blobPdf);
				window.open(url)
			} else {
				throw new Error("PDF não encontrado.")
			}

		} catch (e) {
			toastErro("Não foi possível baixar o arquivo. Por favor, entre em contato com o suporte.")
		}

		setLoadingPdf(false);
	}

	const atualizarFormaPgtoSolicitacao = async () => {
		const { data, hasErro } = await atualizarFormaPgto({
			id: idSolicitacaoPagamento,
			dados: {
				formaPgto,
				cliente: dadosClienteReciboAdiantamento,
				idReciboAdiantamento,
				valorTotal,
			}
		});

		if (hasErro) {
			setShowModalAlterarFormaPgto(false);
			resetDados(true);
			return;
		}

		setCodigoPix(data.codigoPix);

		const downloadElement = document.getElementById(`downloadPDF_${idReciboAdiantamento}`);

		try {
			setLoadingPdf(true);
			if (downloadElement && downloadElement instanceof HTMLAnchorElement) {
				const itensPdf = [downloadElement.href];
				if (formaPgto === FormaPgtoEnum.PIXBOLETO) {
					itensPdf.push(data.linkBoleto);
				}
				const blobPdf = await mergeArquivosParaPdf(itensPdf)
				const url = URL.createObjectURL(blobPdf);
				setShowModalSucessoSolicitacaoPagamento(true);
				window.open(url)
			} else {
				throw new Error("PDF não encontrado.")
			}

		} catch (e) {
			toastErro("Não foi possível baixar o arquivo. Por favor, entre em contato com o suporte.")
		}


		buscarDados();
		setLoadingPdf(false);
		setShowModalAlterarFormaPgto(false);
		resetDados();
	}

	return (
		<>
			<DropdownContainer>
				<CompoundDropdown.Root>
					<CompoundDropdown.Toggle>
						<DropdownItemContainer statusAdiantamento={statusAdiantamento}>
							{handleIcone(statusAdiantamento)}
							{StatusAdiantamentoConst[statusAdiantamento]}
						</DropdownItemContainer>
					</CompoundDropdown.Toggle>
					<CompoundDropdown.Menu>
						<CompoundDropdown.Item>
							<DropdownItemButton
								disabled={disableButtons.informarAdiantamento}
								onClick={() => {
									setShowModalInformarAdiantamento(true);
								}}
							>
								<FaDollarSign /> Informar adiantamento
							</DropdownItemButton>
						</CompoundDropdown.Item>
						<CompoundDropdown.Item>
							<DropdownItemButton
								disabled={disableButtons.alterarFormaDePagamento}
								onClick={() => {
									handleShowModalRecibo(setShowModalAlterarFormaPgto);
								}}
							>
								<FaEdit /> Alterar forma de pagamento
							</DropdownItemButton>
						</CompoundDropdown.Item>
						<CompoundDropdown.Item>
							<DropdownItemButton
								onClick={() => {
									handleShowModalRecibo(setShowModalVisualizarRecibo);
								}}
							>
								<FaEye /> Visualizar
							</DropdownItemButton>
						</CompoundDropdown.Item>
					</CompoundDropdown.Menu>
				</CompoundDropdown.Root>
			</DropdownContainer>
			<ModalReciboAdiantamentoRelatorio
				show={showModalVisualizarRecibo}
				handleClose={() => { setShowModalVisualizarRecibo(false); resetDados(true) }}
				abrirReciboAdiantamento={abrirPdfReciboAdiantamento}
				loading={loadingPagamento || loadingPdf}
				formaPgto={formaPgto}
				dados={{
					dadosPorTipoDebito,
					valorTotal,
					idReciboAdiantamento,
					dadosClienteReciboAdiantamento,
					statusAdiantamento,
					pagamentos,
					dadosBanco,
				}}
			/>
			<ModalInformarAdiantamento
				show={showModalInformarAdiantamento}
				handleClose={() => setShowModalInformarAdiantamento(false)}
				idSolicitacaoPagamento={idSolicitacaoPagamento}
				buscarDados={buscarDados}
			/>
			<ModalReciboAdiantamento
				show={showModalAlterarFormaPgto}
				loading={loadingPagamento || loading || loadingPdf}
				formaPgto={formaPgto}
				setFormaPgto={setFormaPgto}
				handleClose={() => { setShowModalAlterarFormaPgto(false); resetDados(true) }}
				solicitarPagamento={atualizarFormaPgtoSolicitacao}
				dados={{
					dadosPorTipoDebito,
					valorTotal,
					idReciboAdiantamento,
					dadosClienteReciboAdiantamento,
					dadosBanco,
				}}
				maisOpcoes={maisOpcoes}
				setMaisOpcoes={setMaisOpcoes}
				atualizarFormaPgto
			/>
			<ModalSucessoAtualizarSolicitarPagamento
				show={showModalSucessoSolicitacaoPagamento}
				handleClose={() => {
					setCodigoPix("");
					setShowModalSucessoSolicitacaoPagamento(false);
				}}
				codigoPix={codigoPix}
			/>
		</>
	);
}
