import { Informacao } from "components/Informacao";
import { ModalCustom } from "components/ModalCustom";
import { ModalBody } from "./styles";

interface Props {
  handleClose: () => void;
  show: boolean;
  onConfirm: () => void;
}

export function ModalInformacaoPagamento({ handleClose, onConfirm, show }: Readonly<Props>) {
  return (
    <ModalCustom
      title="Solicitar pagamento"
      handleClose={handleClose}
      show={show}
      centered
      footer
      footerConfirmButton
      onConfirm={onConfirm}
    >
      <ModalBody>
        <span>
          A seleção do checkbox contempla apenas os débitos listados na página atual, para solicitar o pagamento de
          todos os débitos disponíveis no lote, clique no botão <strong>Solicitar pagamento</strong> sem selecionar
          nenhum checkbox
        </span>
        <Informacao
          mensagem="Deseja solicitar o pagamento dos débitos selecionados?"
          showBotaoFechar={false}
          showLabel={false}
          type="atencao"
          className="informacao"
        />
      </ModalBody>
    </ModalCustom>
  );
}
