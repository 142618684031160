import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";
import { FormaPgtoContainerIcons, FormaPgtoOpcaoLabel, FormaPgtoOpcaoLabelTextoPrincipal, FormaPgtoOpcaoLabelTextoSecundario, IconBoleto, IconPix, IconTransferencia } from "../../style";
import imgSetas from "_assets/img/icon_arrow_right_left.png";

interface Props {
    formaPgto: FormaPgtoEnum;
}
export function FormaPgto({
    formaPgto,
}: Props) {
    return (
        <>
            {formaPgto === FormaPgtoEnum.PIXBOLETO
                ? <FormaPgtoOpcaoLabel>
                    <FormaPgtoContainerIcons>
                        <IconPix />
                        <IconBoleto />
                    </FormaPgtoContainerIcons>
                    <div>
                        <FormaPgtoOpcaoLabelTextoPrincipal>Pix ou boleto bancário</FormaPgtoOpcaoLabelTextoPrincipal>
                        <FormaPgtoOpcaoLabelTextoSecundario>Pix - pagamento confirmado em até 1 hora • Boleto bancário - pagamento confirmado em até 1 dia útil</FormaPgtoOpcaoLabelTextoSecundario>
                    </div>
                </FormaPgtoOpcaoLabel>
                : <FormaPgtoOpcaoLabel>
                    <FormaPgtoContainerIcons>
                        <IconTransferencia src={imgSetas} />
                    </FormaPgtoContainerIcons>
                    <div>
                        <FormaPgtoOpcaoLabelTextoPrincipal>Transferência bancária</FormaPgtoOpcaoLabelTextoPrincipal>
                        <FormaPgtoOpcaoLabelTextoSecundario>O pagamento é confirmado em até 1 dia útil.</FormaPgtoOpcaoLabelTextoSecundario>
                    </div>
                </FormaPgtoOpcaoLabel>}
        </>


    );
}