import { Container } from "./style";
import { LoteSP } from "./containers/LoteSP";
import { IndividualSP } from "./containers/IndividualSP";
import { useEmpresas } from "hooks/useEmpresas";

interface Props {
  checkedBody: string | null;
  handleCloseModal: () => void;
  listarSolicitacoesLicenciamento: () => Promise<void>;
  handleUploadArquivo: (file: BinaryType | File) => void;
  handleSubmit: (dados: any) => void;
  setDadosIndividual: any;
  setShowModalConfirmar: any;
  confirmarEmissao: () => Promise<void>;
  pagina?: "consulta" | "emissao";
}

export function BodySP({
  checkedBody,
  handleCloseModal,
  listarSolicitacoesLicenciamento,
  handleUploadArquivo,
  handleSubmit,
  setDadosIndividual,
  setShowModalConfirmar,
  confirmarEmissao,
  pagina = "emissao",
}: Readonly<Props>) {
  const { empresasAgrupamento } = useEmpresas();

  function buscarNomeEmpresa(idCliente: NullableString) {
    return empresasAgrupamento.find(
      (item) => item.idClienteAgrupamento === Number(idCliente)
    )?.razaoSocial;
  }

  const bodyStrategy: { [key: string]: React.JSX.Element } = {
    lote: (
      <LoteSP
        handleCloseModal={handleCloseModal}
        listarSolicitacoesLicenciamento={listarSolicitacoesLicenciamento}
        handleUploadArquivo={handleUploadArquivo}
        handleSubmit={handleSubmit}
        buscarNomeEmpresa={buscarNomeEmpresa}
        pagina={pagina}
      />
    ),
    individual: (
      <IndividualSP
        handleCloseModal={handleCloseModal}
        listarSolicitacoesLicenciamento={listarSolicitacoesLicenciamento}
        setDadosIndividual={setDadosIndividual}
        setShowModalConfirmar={setShowModalConfirmar}
        buscarNomeEmpresa={buscarNomeEmpresa}
        confirmarEmissao={confirmarEmissao}
        pagina={pagina}
      />
    ),
  };

  const mensagem =
    pagina === "consulta"
      ? "Preencha as informações abaixo para realizar a consulta do licenciamento"
      : "Preencha as informações abaixo para realizar a emissão do licenciamento";

  return (
    <Container>
      <span>{ mensagem }</span>
      {bodyStrategy[checkedBody!]}
    </Container>
  );
}

