import styled from "styled-components";

export const ModalBody = styled.div`
  text-align: center;
  display: grid;
  gap: 1rem;

  .informacao {
    margin: 0;
    width: 100%;
    font-size: 0.95rem;
  }
`;