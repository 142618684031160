import { yupResolver } from "@hookform/resolvers/yup";
import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { CustomDatePicker } from "components/DatePicker";
import { filtrosRelatorioPagamentoSchema } from "features/relatorios/pagamento/validate/validator";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useMaskInput } from "hooks/useMaskInput";
import { DropdownSelect } from "components/DropdownSelect";
import { FilterButton } from "components/Button/style";
import { FaFilter } from "react-icons/fa";
import { ButtonContainer, DatePickerContainer, InputContainer } from "./style";
import { STATUS_PAGAMENTO_OPTIONS } from "features/relatorios/pagamento/constants/pagamentoConstants";
import { useEmpresas } from "hooks/useEmpresas";
import { IFiltros } from "features/relatorios/pagamento/interfaces/Filtros";
import { ContextPermissao } from "hooks/ContextPermissao";
import { MaisFiltros } from "components/MaisFiltros";
import { useMaisFiltros } from "hooks/useMaisFiltros";
import { STATUS_ADIANTAMENTO_OPTIONS } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";

interface IFiltrosRelatorioPagamentos {
  handleSubmitFiltros: (data: IFiltros) => void;
}

export function FiltrosRelatorioPagamentos({
  handleSubmitFiltros,
}: IFiltrosRelatorioPagamentos) {
  const {
    handleAplicarMaisFiltros,
    handleFormSubmit,
    handleNaoUsarMaisFiltros,
  } = useMaisFiltros();
  const { mInputPlaca } = useMaskInput();
  const { verificarEmpresa } = useContext(ContextPermissao);

  const usuarioLW = verificarEmpresa();
  const { empresasOptions, agrupamentoOptions, criarOptionsAgrupamento } = useEmpresas();
  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);

  const { handleSubmit, control, reset } = useForm<IFiltros>({
    resolver: yupResolver(filtrosRelatorioPagamentoSchema),
  });

  const [dateRange, setDateRange] = useState<any>([null, null]);

  const [startDate, endDate] = dateRange;

  function handleDateRangeSelect(update: any) {
    setDateRange(update);
  }

  return (
    <Container titulo="Filtros">
      <Form
        noValidate
        onSubmit={handleSubmit((dados) =>
          handleFormSubmit<IFiltros>({
            dados,
            submitCallback: handleSubmitFiltros,
            resetCallback: reset,
            excluir: ["renavam", "dataSolicitacao", "idConsulta", "idEmpresa"],
          })
        )}
      >
        <Row className="d-flex justify-content-between">
          <InputContainer lg="2">
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Digite a placa"
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="statusPagamento"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    title="Status pagamento"
                    onSelect={onChange}
                    options={STATUS_PAGAMENTO_OPTIONS}
                    selectedValue={value!}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <InputContainer lg="2">
            <Form.Group>
              <Form.Label>ID pagamento</Form.Label>
              <Controller
                control={control}
                name="idPagamento"
                render={({ field: { onChange } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Busque por ID de Pagamento"
                    onChange={onChange}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="statusAdiantamento"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    title="Status adiantamento"
                    onSelect={onChange}
                    options={STATUS_ADIANTAMENTO_OPTIONS}
                    selectedValue={value!}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <ButtonContainer lg="2">
            <FilterButton type="submit" disabled={false} loading={false}>
              <FaFilter /> Filtrar
            </FilterButton>
            <FilterButton
              type="reset"
              onClick={() => {
                reset({
                  dataSolicitacao: null,
                  idEmpresa: null,
                  idPagamento: null,
                  placa: "",
                  renavam: "",
                  statusPagamento: "",
                  idConsulta: null,
                });
                setDateRange([null, null]);
              }}
              disabled={false}
              loading={false}
              outline={true}
            >
              Limpar
            </FilterButton>
          </ButtonContainer>
          <Col lg="2">
            <MaisFiltros
              cancelarOnClick={() => {
                handleNaoUsarMaisFiltros<IFiltros>({
                  excluir: ["renavam", "dataSolicitacao", "idConsulta", "idEmpresa"],
                  resetCallback: reset,
                });
                setDateRange([null, null]);
              }}
              handleClickAplicarFiltros={handleAplicarMaisFiltros}
              limparTudoOnClick={() => {
                handleNaoUsarMaisFiltros<IFiltros>({
                  excluir: ["renavam", "dataSolicitacao", "idConsulta", "idEmpresa"],
                  resetCallback: reset,
                });
                setDateRange([null, null]);
              }}
            >
              <Form.Group>
                <Form.Label>Renavam</Form.Label>
                <Controller
                  control={control}
                  name="renavam"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      value={value ?? ""}
                      placeholder="Digite o renavam"
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Controller
                  control={control}
                  name="dataSolicitacao"
                  render={({ field: { onChange } }) => (
                    <DatePickerContainer>
                      <span>Data da solicitação</span>
                      <CustomDatePicker
                        onChange={(update) => {
                          onChange(update);
                          handleDateRangeSelect(update);
                        }}
                        rangeSelect={true}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </DatePickerContainer>
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>ID consulta</Form.Label>
                <Controller
                  control={control}
                  name="idConsulta"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      value={value ?? ""}
                      placeholder="Busque por ID de consulta"
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Form.Group>
              {(usuarioLW || (agrupamentoOptions && agrupamentoOptions.length)) ? (
                <Form.Group>
                  <Controller
                    control={control}
                    name="idEmpresa"
                    render={({ field: { onChange, value } }) => (
                      <DropdownSelect
                        options={usuarioLW ? empresasOptions : agrupamentoOptions}
                        title="Empresa"
                        searchable
                        selectedValue={value}
                        onSelect={onChange}
                      />
                    )}
                  />
                </Form.Group>
              ) : null}
            </MaisFiltros>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

