import styled from "styled-components";

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  div {
    align-items: center;
  }

  input {
    margin-right: 1rem;
    width: 1rem;
    height: 1rem;
  }

  label {
    &:hover {
      cursor: pointer;
    }
  }
`;
