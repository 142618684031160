import temaPadrao from "_config/temas/estilo/base";
import React, { ReactNode } from "react";
import { Form, FormControl, FormControlProps, InputGroup } from "react-bootstrap";

interface CustomInputProps extends FormControlProps {
  iconeEsquerdo?: ReactNode;
  iconeDireito?: ReactNode;
  iconeDireitoInterno?: ReactNode;
  sucesso?: boolean;
  noBoxShadow?: boolean;
  mensagemErro?: string | any;
  styleIconeEsquerdo?: React.CSSProperties;
  validar?: boolean;
  onClickMostrarSenha?: () => void;
}

export const CustomInput: React.FC<CustomInputProps> = React.forwardRef(
  (
    {
      iconeEsquerdo,
      iconeDireito,
      iconeDireitoInterno,
      sucesso,
      noBoxShadow,
      mensagemErro,
      styleIconeEsquerdo,
      validar,
      onClickMostrarSenha,
      value,
      ...props
    }: CustomInputProps,
    ref
  ) => {
    return (
      <InputGroup>
        {iconeEsquerdo && (
          <InputGroup.Text
            className="bg-white"
            style={
              {
                ...mensagemErro && validar
                  ? { borderColor: "red" }
                  : { borderColor: `${props.isValid || sucesso ? "green" : ""}` },
                ...styleIconeEsquerdo
              }

            }
          >
            {iconeEsquerdo}
          </InputGroup.Text>
        )}
        <FormControl style={{
          borderRadius: "5px",
          ...noBoxShadow ? { boxShadow: "none" } : null,
          ...mensagemErro && validar
            ? { borderColor: "red" }
            : { borderColor: `${props.isValid || sucesso ? "green" : "#CED4DA"}` },
        }}
          value={value} {...props} ref={ref} />
        {iconeDireitoInterno && (
          <InputGroup.Text
            className="bg-white pointer"
            style={
              {
                padding: "17px 0px 17px 24px",
                ...mensagemErro && validar
                  ? { borderTopColor: "red", borderBottomColor: "red" }
                  : { borderTopColor: `${props.isValid || sucesso ? "green" : ""}`, borderBottomColor: `${props.isValid || sucesso ? "green" : ""}` }
              }
            }
          >
            {iconeDireitoInterno}
          </InputGroup.Text>
        )}
        {iconeDireito && (
          <InputGroup.Text
            className="bg-white pointer"
            style={
              {
                padding: "17px 24px 17px 8px",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
                cursor: "pointer",
                ...mensagemErro && validar
                  ? { borderTopColor: "red", borderBottomColor: "red", borderRightColor: "red", borderLeftStyle: "none" }
                  : {
                    borderTopColor: `${props.isValid || sucesso ? "green" : ""}`,
                    borderBottomColor: `${props.isValid || sucesso ? "green" : ""}`,
                    borderRightColor: `${props.isValid || sucesso ? "green" : ""}`,
                    borderLeftStyle: "none"
                  }
              }
            }
            onClick={onClickMostrarSenha}
          >
            {iconeDireito}
          </InputGroup.Text>
        )}
        {mensagemErro && (
          <Form.Control.Feedback
            type="invalid"
            style={{
              fontSize: "0.85rem",
              color: temaPadrao.colors.danger.main,
            }}
          >
            {mensagemErro}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    );
  }
);
