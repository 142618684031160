import { LoadingCirculo } from "components/LoadingCirculo";
import SpinerCuston from "containers/SpinerCuston";
import { LoadingContainer } from "./style";

interface ModalProps {
    show: boolean;
}

export function Loading({show}: ModalProps) {
    return (
        <LoadingContainer display={show ? "flex" : "none"}>
            <LoadingCirculo mostrar={show} />
        </LoadingContainer>
    );
}