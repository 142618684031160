import { Informacao } from "components/Informacao";
import { ModalCustom } from "components/ModalCustom";
import { InfoContainer } from "./style";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleConfirmarEmissao: () => void;
}

export function ModalConfirmarEmitirLicenciamentosSelecionados({
  show,
  handleClose,
  handleConfirmarEmissao,
}: Props) {
  return (
    <ModalCustom
      centered
      handleClose={handleClose}
      show={show}
      onConfirm={handleConfirmarEmissao}
      footer
      footerConfirmButton
      title="Emitir licenciamento"
      size="lg"
    >
      <span>
        A seleção do checkbox contempla apenas os veículos listados na página atual, para realizar a emissão de todos os CRLVs disponíveis no lote, clique no botão <strong>Emitir CRLV</strong> sem selecionar nenhum checkbox.
      </span>
      <InfoContainer>
        <Informacao
          className="teste"
          type="atencao"
          mensagem="Deseja realizar a emissão apenas dos CRLVs selecionados?"
          showLabel={false}
          showBotaoFechar={false}
        />
      </InfoContainer>
    </ModalCustom>
  );
}

