import * as yup from "yup";
import { portugues } from "_utils/validador";
import { Cobrar, UFS_QUE_CONSULTAMOS } from "../constants";
import { parseJwt } from "_services/auth";

yup.setLocale(portugues);
const regexPlacas = /^[A-Za-z]{3}-?\d[0-9A-Za-z]\d{2}$/;

const { uuid_cliente } = parseJwt();
const ID_LW = "91";

export const filtrosListarSolicitacoesLicenciamento = yup.object().shape({
  placa: yup.string().nullable().matches(regexPlacas, {
    excludeEmptyString: true,
    message: "Placa inválida",
  }),
  dataSolicitacao: yup.date().nullable(),
  statusEmissao: yup.string().oneOf(["finalizado", "processando"]).nullable(),
  uf: yup.string().nullable().min(2).max(2).oneOf(UFS_QUE_CONSULTAMOS),
  renavam: yup.string().nullable().max(11),
  idEmpresa: yup.string().nullable(),
});

export const filtrarDetalhesEmissaoSchema = yup.object().shape({
  placa: yup.string().optional().max(8).nullable(),
  renavam: yup.string().optional().max(11).nullable(),
  exercicio: yup.string().optional().nullable(),
  statusEmissao: yup.string().oneOf(["1", "2", "3", "4", "5"]).optional().nullable(),
});

const cobrancaSchema = yup.object().shape({
  empresa: yup.string().required("Selecione a empresa"),
  cobrar: yup
    .string()
    .when("empresa", {
      is: () => uuid_cliente === ID_LW,
      then: (schema) => schema.required('Selecione a opção de cobrança'),
    })
    .oneOf([Cobrar.SIM, Cobrar.NAO]),
  motivoIsencao: yup.string().when("cobrar", {
    is: (cobrar: string) => cobrar === Cobrar.NAO,
    then: (schema) =>
      schema.required("Informe o motivo da isenção de cobrança"),
  }),
});

export const solicitarLicenciamentoSPSchema = yup
  .object()
  .shape({
    placa: yup
      .string()
      .required("Informe a placa")
      .min(7)
      .max(8)
      .matches(regexPlacas, {
        excludeEmptyString: true,
        message: "Placa inválida",
      }),
    descricao: yup.string().required("Informe a descrição"),
    renavam: yup.string().required("Informe o renavam").max(11),
    cpfCnpjProprietario: yup
      .string()
      .required("Informe o CPF/CNPJ")
      .min(11, "Informe um CPF/CNPJ válido")
      .max(18, "Informe um CPF/CNPJ válido"),
    chassi: yup.string().required("Informe o chassi").length(17),
    exercicio: yup.string().required("Exercício é obrigatório")
  })
  .concat(cobrancaSchema);

export const solicitarLicenciamentoPRSchema = yup
  .object()
  .shape({
    placa: yup
      .string()
      .required("Informe a placa")
      .min(7)
      .max(8)
      .matches(regexPlacas, {
        excludeEmptyString: true,
        message: "Placa inválida",
      }),
    descricao: yup.string().required("Informe a descrição"),
    renavam: yup.string().required("Informe o renavam").max(11),
    chassi: yup.string().required("Informe o chassi").length(17),
    exercicio: yup.string().required("Exercício é obrigatório")
  })
  .concat(cobrancaSchema);

export const solicitarLicenciamentoMGSchema = yup
  .object()
  .shape({
    placa: yup
      .string()
      .required("Informe a placa")
      .min(7)
      .max(8)
      .matches(regexPlacas, {
        excludeEmptyString: true,
        message: "Placa inválida",
      }),
    descricao: yup.string().required("Informe a descrição"),
    renavam: yup.string().required("Informe o renavam").max(11),
    chassi: yup.string().required("Informe o chassi").length(17),
    cpfCnpjProprietario: yup
      .string()
      .required("Informe o CPF/CNPJ")
      .min(11, "Informe um CPF/CNPJ válido")
      .max(18, "Informe um CPF/CNPJ válido"),
    crv: yup.string().required("Informe o CRV"),
    exercicio: yup.string().required("Informe o exercício"),
  })
  .concat(cobrancaSchema);

export const planilhaSchema = yup
  .object()
  .shape({
    descricao: yup.string().required("Descrição é obrigatória"),
    exercicio: yup.string().required("Exercício é obrigatório")
  })
  .concat(cobrancaSchema);

export const emissaoManual = yup.object()
  .shape({
    dataEmissao: yup.string().required("Data de emissão é obrigatória"),
    exercicio: yup.string().required("Informe o ano"),
    arquivo: yup
      .mixed()
      .test("file-selected", "Selecione um arquivo", (value) => {
        return value instanceof File && value.size > 0;
      })
      .required("Selecione um arquivo")
      .nonNullable(),
  });