import { yupResolver } from "@hookform/resolvers/yup";
import { CompositeContainer } from "components/CompositeContainer";
import { ModalCustom } from "components/ModalCustom";
import { toastErro, toastSucesso } from "components/Toast";
import { BodyOption } from "features/arquivos/interfaces";
import { useSolicitacaoConsulta } from "features/debito/consulta/hooks/useSolicitacaoConsulta";
import { IConsultaSolicitada } from "features/debito/consulta/interfaces/IConsultaSolicitada";
import { consultarVeiculoUnitarioSchema } from "features/debito/consulta/validate/validator";
import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BodyLote } from "./BodyLote";
import { BodyUnitario } from "./BodyUnitario";
import { CheckBody } from "./CheckBody";
import { ModalSolicitacaoEnviada } from "./ModalSolicitacaoEnviada";
import { useSocket } from "hooks/useSocket";
import { UploadContext } from "contexts/UploadContext";
import { IOptions } from "components/DropdownSelect";
import { ServicosPortalInterno } from "features/debito/consulta/enum/ServicosPortalInterno";
import { ModalValorConsultarDebitos } from "../ModalValorConsultarDebitos";
import { OpcaoCota } from "features/debito/consulta/enum/OpcaoCota";
import { ConfigServicosIdCliente } from "interfaces/ConfigServicosIdCliente";
import { IOptionsCheck } from "features/debito/consulta/interfaces/IOptionsCheck";
interface SolicitarConsultaProps {
  listarConsultasSolicitada: () => void;
  show: boolean;
  setShowConsultar(show: boolean): void;
  setOptionsUf(options: IOptions[]): void;
  setUfEscolhida(uf: NullableString): void;
  setTipoDebitoEscolhido(tipo: Array<"IPVA" | "LICENCIAMENTO"> | null): void;
  setOptionsCotaIpva(options: IOptions[]): void;
  setConfigServicos(config: ConfigServicosIdCliente[]): void;
  listarConfigServicosPorIdCliente(idCliente: string | number): void;
  setOptionsTipoDebito(options: IOptionsCheck[]): void;
  optionsUf: IOptions[];
  tipoDebitoEscolhido: Array<"IPVA" | "LICENCIAMENTO"> | null;
  ufEscolhida: NullableString;
  optionsCotaIpva: IOptions[];
  configServicos: ConfigServicosIdCliente[];
  optionsTipoDebito: IOptionsCheck[];
}

export function SolicitarConsultaV2({
  listarConsultasSolicitada,
  show,
  setShowConsultar,
  setOptionsUf,
  setUfEscolhida,
  setTipoDebitoEscolhido,
  setOptionsCotaIpva,
  setConfigServicos,
  listarConfigServicosPorIdCliente,
  setOptionsTipoDebito,
  optionsUf,
  tipoDebitoEscolhido,
  ufEscolhida,
  optionsCotaIpva,
  configServicos,
  optionsTipoDebito,
}: SolicitarConsultaProps) {
  const idsServicoConsultaDebitosIPVA = [
    ServicosPortalInterno.CONSULTA_IPVA_COTA_PARCELADA,
    ServicosPortalInterno.CONSULTA_IPVA_COTA_UNICA,
  ];
  const idsServicoConsultaDebitosLicenciamento = [
    ServicosPortalInterno.CONSULTA_LICENCIAMENTO,
  ];
  const [body, setBody] = useState<BodyOption>("lote");
  const [modalSize, setModalSize] = useState("lg");
  const { errorFile, error } = useSolicitacaoConsulta();
  const { socket } = useSocket();
  const [opcaoCotaEsolhida, setOpcaoCotaEscolhida] = useState<OpcaoCota.PARCELADA | OpcaoCota.UNICA | null>(null);
  const [onConfirmModalValor, setOnConfirmModalValor] = useState<() => void>(() => { });
  const [showModalValorConsulta, setShowModalValorConsulta] = useState<boolean>(false);
  const [qtdLinhas, setQtdLinhas] = useState<number>(0);
  const [valorConsulta, setValorConsulta] = useState<number>(0);
  const [loadingModalValorConsulta, setLoadingModalValorConsulta] = useState<boolean>(false);
  const ufsHabilitadasIpva = ["PR", "SP"];
  const ufsHabilitadasLicenciamento = ["PR", "SP"];

  const { modalAberto } = useContext(UploadContext);

  useEffect(() => {
    if (modalAberto) {
      handleClose();
    }
  }, [modalAberto]);

  const { reset } = useForm<IConsultaSolicitada>({
    resolver: yupResolver(consultarVeiculoUnitarioSchema),
  });

  useEffect(() => {
    const sizeStrategy = {
      lote: "lg",
      individual: "xl",
    };

    setModalSize(sizeStrategy[body]);
  }, [body]);

  function handleClose() {
    setShowConsultar(false);
    setBody("lote");
  }

  const close = useCallback(() => {
    setShowConsultar(false);
    setBody("lote");
  }, []);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (!error.hasErro) {
      toastSucesso("Solicitação realizada");
      reset();
      listarConsultasSolicitada();
    }
    if (error.hasErro) {
      const { data } = error;
      toastErro(`${data.message}`);
    }
  }, [error, listarConsultasSolicitada, reset]);

  useEffect(() => {
    if (!errorFile) return;
    if (!errorFile.hasErro) {
      listarConsultasSolicitada();
    }
  }, [errorFile, listarConsultasSolicitada]);

  function handleCheckBody(event: ChangeEvent<HTMLInputElement>) {
    reset();
    setOptionsUf([]);
    setUfEscolhida(null);
    setTipoDebitoEscolhido(null);
    setOptionsCotaIpva([]);
    setConfigServicos([]);
    setBody(event.target.value as BodyOption);
  }

  function handleChangeEmpresa(idEmpresa: number | string) {
    listarConfigServicosPorIdCliente(idEmpresa)
  }

  useEffect(() => {
    if (configServicos && configServicos.length) {
      setOptionsUf(configServicos
        .filter(item => item.uf &&
          (
            (idsServicoConsultaDebitosIPVA.includes(item.idServico) && ufsHabilitadasIpva.includes(item.uf)) ||
            (idsServicoConsultaDebitosLicenciamento.includes(item.idServico) && ufsHabilitadasLicenciamento.includes(item.uf))
          )).map(item => item.uf).filter((value, index, self) => {
            return self.indexOf(value) === index;
          })
        .map(uf => { return { value: uf, label: uf } }));
      return;
    }
    setOptionsUf([]);
  }, [configServicos]);

  useEffect(() => {
    if (configServicos && configServicos.length && ufEscolhida) {
      const options: IOptionsCheck[] = [];
      if (configServicos.some(item => [
        ServicosPortalInterno.CONSULTA_IPVA_COTA_PARCELADA,
        ServicosPortalInterno.CONSULTA_IPVA_COTA_UNICA
      ].includes(item.idServico) && item.uf === ufEscolhida)) {
        options.push({
          value: "IPVA",
          label: "IPVA",
          checked: false,
        })
      }

      if (configServicos.some(item => item.idServico == ServicosPortalInterno.CONSULTA_LICENCIAMENTO && item.uf === ufEscolhida)) {
        options.push({
          value: "LICENCIAMENTO",
          label: "Licenciamento",
          checked: false,
        })
      }

      setOptionsTipoDebito(options);
      return;
    }
    setOptionsTipoDebito([]);
  }, [configServicos, ufEscolhida]);

  useEffect(() => {
    if (tipoDebitoEscolhido && !tipoDebitoEscolhido.includes("IPVA")) {
      setOptionsCotaIpva([]);
      return
    }

    if (configServicos && configServicos.length && ufEscolhida && tipoDebitoEscolhido) {
      const options: IOptions[] = [];
      if (configServicos.some(item => item.uf === ufEscolhida && item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_PARCELADA)) {
        options.unshift({
          label: "Parcelado",
          value: OpcaoCota.PARCELADA,
        })
      }
      if (configServicos.some(item => item.uf === ufEscolhida && item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_UNICA)) {
        options.unshift({
          label: "Única",
          value: OpcaoCota.UNICA,
        })
      }
      setOptionsCotaIpva(options);
      return;
    }
    setOptionsCotaIpva([]);
  }, [ufEscolhida, configServicos, tipoDebitoEscolhido]);

  useEffect(() => {

    if (tipoDebitoEscolhido && configServicos && configServicos.length && ufEscolhida) {
      let custoUnitario = 0;
      if (tipoDebitoEscolhido.includes("IPVA") && opcaoCotaEsolhida && opcaoCotaEsolhida == OpcaoCota.PARCELADA) {
        configServicos.filter(
          item => item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_PARCELADA &&
            item.uf === ufEscolhida
        ).forEach(item => {
          custoUnitario += Number(item.valor)
        })
      }

      if (tipoDebitoEscolhido.includes("IPVA") && opcaoCotaEsolhida && opcaoCotaEsolhida == OpcaoCota.UNICA) {
        configServicos.filter(
          item => item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_UNICA &&
            item.uf === ufEscolhida
        ).forEach(item => {
          custoUnitario += Number(item.valor)
        })
      }

      if (tipoDebitoEscolhido.includes("LICENCIAMENTO")) {
        configServicos.filter(
          item => item.idServico == ServicosPortalInterno.CONSULTA_LICENCIAMENTO &&
            item.uf === ufEscolhida
        ).forEach(item => {
          custoUnitario += Number(item.valor)
        })
      }

      setValorConsulta(custoUnitario)
      return;
    }
  }, [configServicos, opcaoCotaEsolhida, tipoDebitoEscolhido, ufEscolhida]);

  const bodyStrategy: { [key: string]: React.JSX.Element } = {
    lote: <BodyLote
      socket={socket}
      close={close}
      handleChangeEmpresa={handleChangeEmpresa}
      optionsUf={optionsUf}
      setTipoDebitoEscolhido={setTipoDebitoEscolhido}
      setUfEscolhida={setUfEscolhida}
      optionsCotaIpva={optionsCotaIpva}
      setOnConfirmModalValor={setOnConfirmModalValor}
      setShowModalValor={setShowModalValorConsulta}
      setLoadingModalValor={setLoadingModalValorConsulta}
      setValorConsulta={setValorConsulta}
      setQtdLinhas={setQtdLinhas}
      setOpcaoCotaEscolhida={setOpcaoCotaEscolhida}
      optionsTipoDebito={optionsTipoDebito}
      listarConfigServicos={listarConfigServicosPorIdCliente}
    />,
    individual: <BodyUnitario
      listarConsultasSolicitada={listarConsultasSolicitada}
      close={close} handleChangeEmpresa={handleChangeEmpresa}
      optionsUf={optionsUf}
      setTipoDebitoEscolhido={setTipoDebitoEscolhido}
      setUfEscolhida={setUfEscolhida}
      optionsCotaIpva={optionsCotaIpva}
      setOpcaoCotaEscolhida={setOpcaoCotaEscolhida}
      setOnConfirmModalValor={setOnConfirmModalValor}
      setShowModalValor={setShowModalValorConsulta}
      setLoadingModalValor={setLoadingModalValorConsulta}
      setValorConsulta={setValorConsulta}
      setQtdLinhas={setQtdLinhas}
      optionsTipoDebito={optionsTipoDebito}
      listarConfigServicos={listarConfigServicosPorIdCliente}
    />,
  };

  const closeModalValorConsulta = () => {
    setShowModalValorConsulta(false);
    setOnConfirmModalValor(() => { });
    setQtdLinhas(0);
    setValorConsulta(0);
  }

  return (
    <>
      <ModalCustom show={show} handleClose={handleClose} title="Consultar débitos" centered size={modalSize as any}>
        <CompositeContainer.Body>
          <CheckBody handleCheckOption={handleCheckBody} />
          {bodyStrategy[body]}
        </CompositeContainer.Body>
      </ModalCustom>
      <ModalValorConsultarDebitos
        show={showModalValorConsulta}
        loading={loadingModalValorConsulta}
        qtdLinhas={qtdLinhas}
        valorConsulta={valorConsulta}
        handleClose={closeModalValorConsulta}
        onConfirm={onConfirmModalValor}
      />
    </>
  );
}
