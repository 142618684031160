import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData } from "_services/api/interface/HttpResponse";
import { useSolicitarExtratoVeicular } from "features/extrato-veicular/consultar-veiculos/pages/Listar/hooks/useExtratoVeicular";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

type TObjetoStringNumber = { [key: string]: number };

export interface IArquivoUpload {
  progressoUpload: number;
  uploadFinalizado: boolean;
  nomeArquivo: string;
  temErro: boolean;
  temAviso?: boolean;
}

interface IAtualizarProgressoUploadProps {
  nomeArquivo: string;
  progressoAtual: number;
  temErro: boolean;
  temAviso?: boolean;
}

interface IErrosUpload {
  [x: string]: {
    erros: any[];
  };
}

interface IAvisosUpload {
  [x: string]: {
    dados: any;
  };
}

interface ISucessoUpload {
  [x: string]: string;
}

interface IReconsultarCallbacks {
  response: HttpError<IData, any> | HttpResponse<any, any>;
  baseUrlRedirect: string;
}

export interface IUploadContext {
  showBox: boolean;
  toggleShowBox: () => void;
  arquivos: IArquivoUpload[] | null;
  progresso: TObjetoStringNumber;
  atualizarProgressoUpload: ({
    nomeArquivo,
    progressoAtual,
    temErro,
    temAviso,
  }: IAtualizarProgressoUploadProps) => void;
  adicionarArquivo: (arquivo: IArquivoUpload[]) => void;
  limparArquivos: () => void;
  erros: IErrosUpload;
  setErrosUpload: (nomeArquivo: string, erros: any[]) => void;
  urlRedirect: ISucessoUpload;
  setSucessoUpload: (nomeArquivo: string, urlRedirect: string) => void;
  planilhasCorrompidas: { [x: string]: boolean };
  setPlanilhaCorrompida: (nomeArquivo: string) => void;
  setErroUpload: (nomeArquivo: string, erros: string) => void;
  erro: any;
  showModalErros: boolean;
  setShowModalErros: (show: boolean) => void;
  showModalCorrompida: boolean;
  setShowModalCorrompida: (show: boolean) => void;
  showModalErro: boolean;
  setShowModalErro: (show: boolean) => void;
  modalAberto: boolean;
  setModalAberto: (aberto: boolean) => void;
  aviso: "parcial" | "total";
  setAviso: (aviso: "parcial" | "total") => void;
  dadosAviso: IAvisosUpload;
  setAvisosUpload: (nomeArquivo: string, dados: any) => void;
  showModalAvisos: boolean;
  setShowModalAvisos: (show: boolean) => void;
  handleReconsultar: (dados: any) => void;
  loading: boolean;
}

export const UploadContext = createContext<IUploadContext>({
  showBox: false,
  toggleShowBox: () => {},
  arquivos: [
    {
      nomeArquivo: "",
      progressoUpload: 0,
      uploadFinalizado: false,
      temErro: false,
    },
  ],
  progresso: {},
  atualizarProgressoUpload: () => {},
  adicionarArquivo: () => {},
  limparArquivos: () => {},
  erros: {},
  setErrosUpload: (nomeArquivo: string, erros: any[]) => {},
  setErroUpload: (nomeArquivo: string, erros: string) => {},
  urlRedirect: {},
  setSucessoUpload: (nomeArquivo: string, urlRedirect: string) => {},
  planilhasCorrompidas: {},
  setPlanilhaCorrompida: (nomeArquivo: string) => {},
  erro: "",
  showModalErros: false,
  setShowModalErros: (show: boolean) => {},
  showModalCorrompida: false,
  setShowModalCorrompida: (show: boolean) => {},
  showModalErro: false,
  setShowModalErro: (show: boolean) => {},
  modalAberto: false,
  setModalAberto: (show: boolean) => {},
  aviso: "parcial",
  dadosAviso: {},
  setAvisosUpload: (nomeArquivo: string, dados: any) => {},
  setAviso: (aviso) => {},
  showModalAvisos: false,
  setShowModalAvisos: (show: boolean) => {},
  handleReconsultar: (dados: any) => {},
  loading: false,
});

export const UploadContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [showBox, setShowBox] = useState(false);
  const [arquivos, setArquivos] = useState<IArquivoUpload[]>([]);
  const [erros, setErros] = useState<IErrosUpload>({});
  const [erro, setErro] = useState();
  const [urlRedirect, setUrlRedirect] = useState<ISucessoUpload>({});
  const [planilhasCorrompidas, setPlanilhasCorrompidas] = useState<any>({});
  const [progresso, setProgresso] = useState<TObjetoStringNumber>({});
  const [showModalErros, setShowModalErros] = useState(false);
  const [showModalCorrompida, setShowModalCorrompida] = useState(false);
  const [showModalErro, setShowModalErro] = useState(false);
  const [modalAberto, setModalAberto] = useState(false);
  const [aviso, setAviso] = useState<"parcial" | "total">("parcial");
  const [dadosAviso, setDadosAviso] = useState<IAvisosUpload>({
    unitario: {
      dados: {},
    },
  });
  const [showModalAvisos, setShowModalAvisos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [_, setParams] = useSearchParams();

  useEffect(() => {
    setModalAberto(false);
    if (showModalErro || showModalCorrompida || showModalErros) {
      setModalAberto(true);
    }
  }, [showModalErro, showModalErros, showModalCorrompida]);

  const { reconsultarPlacasExtratoVeicular } = useSolicitarExtratoVeicular(
    null,
    false
  );

  function toggleShowBox() {
    setShowBox((prev) => !prev);
  }

  const finalizarProgressoUpload = useCallback(
    (nomeArquivo: string, temErro = false, temAviso = false) => {
      setArquivos((prevArquivos) => {
        const indexArquivo = prevArquivos.findIndex(
          (item) => item.nomeArquivo === nomeArquivo
        );

        if (indexArquivo !== -1) {
          const finalizado: IArquivoUpload = {
            ...prevArquivos[indexArquivo],
            uploadFinalizado: true,
            temErro,
            temAviso,
          };

          const novosArquivos = [...prevArquivos];
          novosArquivos[indexArquivo] = finalizado;

          return novosArquivos;
        }

        return prevArquivos;
      });
    },
    []
  );

  const atualizarProgressoUpload = useCallback(
    ({
      nomeArquivo,
      progressoAtual,
      temErro,
      temAviso = false,
    }: IAtualizarProgressoUploadProps) => {
      setProgresso((prev) => ({
        ...prev,
        [nomeArquivo]:
          prev[nomeArquivo] > progressoAtual
            ? prev[nomeArquivo]
            : progressoAtual,
      }));

      if (progressoAtual >= 100) {
        finalizarProgressoUpload(nomeArquivo, temErro, temAviso);
      }
    },
    [finalizarProgressoUpload]
  );

  function handleInitialProgress(arquivos: any[]) {
    arquivos.forEach((arquivo) => {
      setProgresso((prev) => ({
        ...prev,
        [arquivo.nomeArquivo]: arquivo.progressoUpload,
      }));
    });
  }

  const adicionarArquivo = useCallback((novosArquivos: IArquivoUpload[]) => {
    setArquivos((arquivosAnteriores) => [
      ...(arquivosAnteriores ?? []),
      ...novosArquivos,
    ]);
    handleInitialProgress(novosArquivos);
  }, []);

  const limparArquivos = useCallback(() => {
    setArquivos([]);
    setProgresso({});
  }, []);

  const setPlanilhaCorrompida = useCallback((nomeArquivo: string) => {
    setPlanilhasCorrompidas((prev: any) => ({ ...prev, [nomeArquivo]: true }));
  }, []);

  const setErroUpload = useCallback((nomeArquivo: string, erro: string) => {
    setErro((prev: any) => ({ ...prev, [nomeArquivo]: erro }));
  }, []);

  const setErrosUpload = useCallback((nomeArquivo: string, erros: any[]) => {
    setErros((prev: any) => ({ ...prev, [nomeArquivo]: erros }));
  }, []);

  const setSucessoUpload = useCallback(
    (nomeArquivo: string, urlRedirect: string) => {
      setUrlRedirect((prev: any) => ({ ...prev, [nomeArquivo]: urlRedirect }));
    },
    []
  );

  const setAvisosUpload = useCallback((nomeArquivo: string, dados: any) => {
    setDadosAviso((prev: any) => ({
      ...prev,
      [nomeArquivo]: {
        dados,
      },
    }));
  }, []);

  const handleReconsultar = useCallback(
    async (dados: any) => {
      const strategy: {
        [x: string]: (dados: any) => Promise<IReconsultarCallbacks>;
      } = {
        EXTRATO_VEICULAR: (dadosReconsultar: any) =>
          reconsultarPlacasExtratoVeicular(dadosReconsultar),
      };

      setLoading(true);
      const { response, baseUrlRedirect } = await strategy[dados.strategy](
        dados
      );

      setLoading(false);
      if (response.hasErro) {
        setErrosUpload(dados.nomeArquivo, []);
        return;
      }

      atualizarProgressoUpload({
        nomeArquivo: dados.nomeArquivo,
        progressoAtual: 100,
        temErro: false,
        temAviso: false,
      });

      setShowModalAvisos(false);
      setSucessoUpload(
        dados.nomeArquivo,
        `${baseUrlRedirect}/${response.data.idSolicitacao}`
      );

      setParams({ recarregar: "SIM" });
    },
    [
      reconsultarPlacasExtratoVeicular,
      atualizarProgressoUpload,
      setErrosUpload,
      setSucessoUpload,
      setParams,
    ]
  );

  const values = useMemo(
    () => ({
      showBox,
      toggleShowBox,
      arquivos,
      progresso,
      atualizarProgressoUpload,
      adicionarArquivo,
      limparArquivos,
      erros,
      setErrosUpload,
      urlRedirect,
      setSucessoUpload,
      planilhasCorrompidas,
      setErroUpload,
      setPlanilhaCorrompida,
      erro,
      showModalErros,
      setShowModalErros,
      showModalCorrompida,
      setShowModalCorrompida,
      showModalErro,
      setShowModalErro,
      modalAberto,
      setModalAberto,
      aviso,
      setAviso,
      dadosAviso,
      setAvisosUpload,
      showModalAvisos,
      setShowModalAvisos,
      handleReconsultar,
      loading,
    }),
    [
      atualizarProgressoUpload,
      showBox,
      adicionarArquivo,
      arquivos,
      progresso,
      limparArquivos,
      erros,
      setErrosUpload,
      urlRedirect,
      setSucessoUpload,
      planilhasCorrompidas,
      setPlanilhaCorrompida,
      setErroUpload,
      erro,
      showModalErros,
      setShowModalErros,
      showModalCorrompida,
      setShowModalCorrompida,
      showModalErro,
      setShowModalErro,
      modalAberto,
      setModalAberto,
      aviso,
      setAviso,
      dadosAviso,
      setAvisosUpload,
      showModalAvisos,
      setShowModalAvisos,
      handleReconsultar,
      loading,
    ]
  );

  return (
    <UploadContext.Provider value={values}>{children}</UploadContext.Provider>
  );
};
