import { FaReply } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  BackButton,
  Breadcrumb,
  HeaderContainer,
  ListItem,
  StrongText,
  Title,
} from "./style";

interface HeaderProps {
  lastPage?: string;
  title: string;
  redirect?: string;
  titleRedirect?: string;
  redirectDoIcone?: NullableString;
}

export function Header({
  lastPage,
  title,
  redirect = "/",
  titleRedirect = "",
  redirectDoIcone = null,
}: HeaderProps) {
  titleRedirect = titleRedirect ? titleRedirect : redirect;
  const navigate = useNavigate();

  function pageBack() {
    if (redirectDoIcone) {
      navigate(redirectDoIcone);
      return;
    }
    window.history.back();
  }

  return (
    <HeaderContainer>
      <Title>{title}</Title>
      <Breadcrumb>
        <ListItem className="reply">
          <BackButton onClick={pageBack}>
            {" "}
            <FaReply />{" "}
          </BackButton>
        </ListItem>
        <ListItem>
          <Link to="/core/dashboard">{lastPage ?? "Dashboard"} </Link>
        </ListItem>
        <ListItem className="breadcrumb-item">
          {redirect?.substring(-4, 4) && (
            <Link to={redirect}>{titleRedirect.replaceAll("/", " / ")} </Link>
          )}
        </ListItem>
        <ListItem className="active">
          <StrongText> {title}</StrongText>
        </ListItem>
      </Breadcrumb>
    </HeaderContainer>
  );
}
