import { Route, Routes } from "react-router-dom";
import { Listar } from "../pages/listar";

function CadastroUsuarioRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Listar />} />
    </Routes>
  );
}
export { CadastroUsuarioRoutes };
