import { Tooltip } from "components/Tooltip";
import { MensagemTooltipContainer, TooltipContent, TriggerContainer } from "./style";
import { FaInfoCircle } from "react-icons/fa";
import { StatusExtratoVeicular } from "../StatusConsultaExtratoVeicular/style";

interface Props {
  tentativas: number;
  statusExtratoVeicular: string;
}

export function TooltipTentativas({ tentativas, statusExtratoVeicular }: Readonly<Props>) {
  if (tentativas === 0) {
    return <TriggerContainer>--</TriggerContainer>;
  }

  const statusSemTooltip = [
    StatusExtratoVeicular.NADA_CONSTA,
    StatusExtratoVeicular.COM_PENDENCIA,
    StatusExtratoVeicular.DADOS_INCONSISTENTES,
  ];

  if (statusSemTooltip.some((status) => status === statusExtratoVeicular)) {
    return <TriggerContainer>{String(tentativas).padStart(2, "0")}</TriggerContainer>;
  }

  const bottomTooltip = tentativas >= 3 ? -6.5 : -4.0;
  const widthTooltip = 240;
  const marginLeft = 30;

  return (
    <Tooltip
      trigger={<TriggerContainer cursorHelp={true}>{String(tentativas).padStart(2, "0")}</TriggerContainer>}
      bottom={bottomTooltip}
      width={widthTooltip}
      marginLeft={marginLeft}
      positionRelative={false}
    >
      <TooltipContent>
        <span>
          <FaInfoCircle /> Falha na consulta
        </span>
        {
          tentativas >= 3 ? (
            <MensagemTooltipContainer>
              <strong>3ª tentativa sem sucesso.</strong>
              <span>Entre em contato com o suporte ou tente novamente mais tarde.</span>
            </MensagemTooltipContainer>
          ) : (
            <strong>Processando {tentativas + 1}ª tentativa</strong>
          )
        }
      </TooltipContent>
    </Tooltip>
  );
}

