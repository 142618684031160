import temaPadrao from "_config/temas/estilo/base";
import { Button } from "react-bootstrap";
import styled from "styled-components";

interface ButtonProps {
  outline?: boolean;
  variante?: "primary" | "black";
  buttonWidth?: "xs" | "sm" | "md" | "lg";
}

const corStrategy = {
  primary: {
    principal: temaPadrao.colors.primary.main,
    hover: temaPadrao.colors.primary.hover,
    active: temaPadrao.colors.primary.main,
  },
  black: {
    principal: temaPadrao.colors.neutral.neutral80,
    hover: temaPadrao.colors.neutral.neutral40,
    active: temaPadrao.colors.neutral.neutral40,
  },
};

enum ButtonWidth {
  xs = 25,
  sm = 50,
  md = 75,
  lg = 100,
}

enum ButtonWidthSmallScreen {
  xs = 50,
  sm = 70,
  md = 90,
  lg = 100,
}

export const PrimaryButton = styled.button<ButtonProps>`
  padding: 0.5rem 1rem;
  height: 2.2rem;
  border-radius: 5px;
  border: ${(props) => (props.outline && props.variante ? `solid 1px ${corStrategy[props.variante].principal}` : `solid 1px ${props.theme.colors.primary.main}`)};
  background-color: ${(props) => (props.outline ? "#ffffff" : props.theme.colors.primary.main)};
  color: ${(props) =>
    props.outline && props.variante ? corStrategy[props.variante].principal : props.theme.colors.textLight};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  &:disabled {
    background-color: ${(props) => (props.outline ? "#ffffff" : "#B3B3B3")} !important;
    border: ${(props) => (props.outline ? `solid 1px #B3B3B3` : `solid 1px #B3B3B3`)} !important;
    color: ${(props) => (props.outline ? "#B3B3B3" : "#ffffff")} !important;
    svg {
      color: ${(props) => (props.outline ? "#B3B3B3" : "#ffffff")} !important;
    }
  }

  &:active {
    background-color: ${(props) =>
    props.outline && props.variante
      ? corStrategy[props.variante].hover
      : (props) => props.theme.colors.primary.pressed};
    border: ${(props) =>
    props.outline && props.variante
      ? `solid 1px ${corStrategy[props.variante].hover}`
      : `solid 1px ${props.theme.colors.primary.main}`};
    color: ${(props) => (props.outline && props.variante ? props.theme.colors.textLight : "")};
  }

  &:hover {
    background-color: ${(props) => (props.outline && props.variante ? "#ffffff" : props.theme.colors.primary.hover)};
    border: ${(props) => (props.outline && props.variante ? `solid 1px ${corStrategy[props.variante].hover}` : `solid 1px ${props.theme.colors.primary.hover}`)};
    color: ${(props) => (props.outline && props.variante ? corStrategy[props.variante].hover : "")};
    svg {
      color: ${(props) =>
    props.outline && props.variante ? corStrategy[props.variante].hover : props.theme.colors.textLight};
    }
  }

  svg {
    width: 0.85rem !important;
    height: 0.85rem !important;
    color: ${(props) =>
    props.outline && props.variante
      ? corStrategy[props.variante].principal
      : props.theme.colors.textLight} !important;
  }
  
  @media (max-width: 1370px) {
    font-size: 12px;
  }
`;

export const SuccessButton = styled.button<ButtonProps>`
  width: 150px;
  height: 2.2rem;
  border-radius: 8px;
  border: ${(props) => (props.outline ? `solid 2px ${props.theme.colors.success.main}` : 0)};
  background-color: ${(props) => (props.outline ? "#ffffff" : props.theme.colors.success.main)};
  color: ${(props) => (props.outline ? props.theme.colors.success.main : props.theme.colors.textLight)};
  font-weight: bold;
  transition: 0.3s;

  &:active {
    background-color: ${(props) => props.theme.colors.success.pressed};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.success.hover};
    color: ${(props) => (props.outline ? props.theme.colors.textLight : "")};
  }
`;

export const FilterButton = styled(Button) <ButtonProps>`
  background-color: ${(props) => (props.outline ? "#ffffff" : props.theme.colors.primary.main)};
  border: ${(props) => (props.outline ? `solid 1px ${props.theme.colors.primary.main}` : 0)};
  color: ${(props) => (props.outline ? props.theme.colors.primary.main : props.theme.colors.textLight)};
  border-radius: 0.5rem;
  border: solid 1px ${(props) => (props.theme.colors.primary.main)};
  width: 80px;
  height: 2.2rem;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    background-color: ${(props) => (props.outline ? "#ffffff" : props.theme.colors.primary.hover)};
    border: solid 1px ${(props) => props.theme.colors.primary.hover};
    color: ${(props) => (props.outline ? props.theme.colors.primary.hover : "")};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.primary.pressed} !important;
    color: ${(props) => (props.outline ? props.theme.colors.textLight : "")};
    border: solid 1px ${({ theme }) => theme.colors.primary.pressed} !important;
  }
`;

PrimaryButton.defaultProps = {
  buttonWidth: "md",
};
