import styled from "styled-components";
import temaPadrao from "_config/temas/estilo/base";

export const ToolsActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerTable = styled.div`
  button {
    svg {
      color: #000000;
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const paginationComponentOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: false,
  noRowsPerPage: false,
  selectAllRowsItemText: "Todos",
};

export const paginationRowsPerPageOptions =  [100, 150, 200, 300]

export const customStyle = {
  headCells: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start !important",
      fontSize: "1rem",
      fontWeight: "bold",
      padding: "16px"
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      border: "none",
      background: temaPadrao.colors.neutral.neutral10,
      color: temaPadrao.colors.neutral.neutral80,
      minWidth: "0",
      marginBottom: "0.75rem",
      paddingLeft: "14px",
    },
  },
  pagination: {
    style: {
      justifyContent: "center",
      pageButtonsStyle: {},
    },
  },
  cells: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "16px",
    },
  },
  rows: {
    style: {
      display: "flex",
      height: '52px' ,
      margin: '4px 0',
      alignItems: "center",
      justifyContent: "flex-start",
      "&:not(:last-of-type)": {
        borderBottomWidth: "0px",
      },
      borderRadius: "10px",
      paddingLeft: "14px",
    },
    stripedStyle: {
      background: temaPadrao.colors.neutral.neutral05,
      borderRadius: "10px",
    },
    selectedHighlightStyle:{
      background:`${temaPadrao.colors.project.bgColor} !important`
    }
  },
};
