import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { IDadosGravame } from "../../../DadosGravame";

interface Props {
  gravames: IDadosGravame[] | null;
}

export function DadosGravamePDF({ gravames }: Readonly<Props>) {
  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Gravame</Text>
      <View style={STYLES.SECTION}>
        {gravames && gravames?.length > 0  ? (
          gravames.map((gravame) => (
            <>
              <View style={STYLES.SECTION_ROW}>
                <View style={[STYLES.SECTION_COL_3, { marginRight: 6 }]}>
                  <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Situação restrição</Text>
                  <Text style={STYLES.FONT_SIZE_09}>{gravame.situacaoRestricao}</Text>
                </View>
                <View style={STYLES.SECTION_COL_4}>
                  <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Número contrato</Text>
                  <Text style={STYLES.FONT_SIZE_09}>{gravame.numeroContrato}</Text>
                </View>
                <View style={STYLES.SECTION_COL_4}>
                  <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Tipo financiamento da restrição</Text>
                  <Text style={STYLES.FONT_SIZE_09}>{gravame.tipoFinanciamentoRestricao}</Text>
                </View>
                <View style={STYLES.SECTION_COL_4}>
                  <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Data contrato</Text>
                  <Text style={STYLES.FONT_SIZE_09}>{gravame.dataContrato}</Text>
                </View>
              </View>
              <View style={STYLES.SECTION_ROW}>
                <View style={[STYLES.SECTION_COL_3, { marginRight: 6 }]}>
                  <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Nome contratante</Text>
                  <Text style={STYLES.FONT_SIZE_09}>{gravame.nomeContratante}</Text>
                </View>
                <View style={STYLES.SECTION_COL_4}>
                  <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Agente financeiro</Text>
                  <Text style={STYLES.FONT_SIZE_09}>{gravame.agenteFinanceiro}</Text>
                </View>
                <View style={STYLES.SECTION_COL_4}>
                  <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Data de atualização</Text>
                  <Text style={STYLES.FONT_SIZE_09}>{gravame.dataHoraAtualizacao}</Text>
                </View>
                <View style={STYLES.SECTION_COL_4}></View>
              </View>
            </>
          ))
        ) : (
          <View style={STYLES.SECTION_ROW}>
            <View style={STYLES.SECTION_COL_1}>
              <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Gravame</Text>
              <Text style={STYLES.FONT_SIZE_09}>Nada consta</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

