import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { IconeContainer, MensagemSucessoContainer, ModalBody } from "./style";
import { LoadingCirculo } from "components/LoadingCirculo";
import { FiX } from "react-icons/fi";

interface Props {
  handleClose: () => void;
  show: boolean;
  loading: boolean;
  erro: boolean;
}

function ModalBodyContent({ erro }: Readonly<{ erro: boolean }>) {
  if (erro) {
    return (
      <>
        <IconeContainer cor='erro'>
          <FiX />
        </IconeContainer>
        <span>Nenhum licenciamento com falha encontrado</span>
      </>
    );
  }

  return (
    <>
      <MensagemSucessoContainer>
        <IconeContainer cor='sucesso'>
          <FaCheck />
        </IconeContainer>
        <span>Solicitação efetuada com sucesso!</span>
      </MensagemSucessoContainer>
      <strong>As emissões com status de falha serão reprocessadas.</strong>
    </>
  );
}

export function ModalConfirmacaoEmissao({ handleClose, show, erro, loading }: Readonly<Props>) {
  return (
    <ModalCustom handleClose={handleClose} show={show} title="Reemitir licenciamento" centered>
      <ModalBody>{loading ? <LoadingCirculo mostrar={loading} /> : <ModalBodyContent erro={erro} />}</ModalBody>
    </ModalCustom>
  );
}

