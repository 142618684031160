import React from "react";

import NavHeader from "./NavHeader";
import Menu from "./Menu/index";
import ContextMenuProvider from "./Menu/ContextMenu";

interface Props {
  alterMiniNavbar(): void;
  activeMiniNavbar: boolean;
  aberto: boolean;
  setAberto(aberto: boolean): void;
}

function SlideBar({
  alterMiniNavbar,
  activeMiniNavbar,
  aberto,
  setAberto,
}: Props) {
  return (
    <nav
      className="navbar-default navbar-static-side"
      role="navigation"
      onClick={() => {
        if (aberto) {
          setAberto(false);
        }
      }}
    >
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <ContextMenuProvider>
            <Menu
              alterMiniNavbar={alterMiniNavbar}
              activeMiniNavbar={activeMiniNavbar}
            />
          </ContextMenuProvider>
        </ul>
      </div>
    </nav>
  );
}

export default SlideBar;
