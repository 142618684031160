export enum StatusAdiantamentoEnum {
	PENDENTE = "PENDENTE",
	PAGO = "PAGO",
	CANCELADO = "CANCELADO",
	AGUARDANDO_VALIDACAO = "AGUARDANDO_VALIDACAO",
	PAGAMENTO_INCOMPLETO = "PAGAMENTO_INCOMPLETO",
	REEMBOLSO = "REEMBOLSO",
}

export const StatusAdiantamentoConst: { [x: string]: string } = {
	PENDENTE: "Pendente",
	PAGO: "Pago",
	CANCELADO: "Cancelado",
	AGUARDANDO_VALIDACAO: "Aguardando validação",
	PAGAMENTO_INCOMPLETO: "Pagamento incompleto",
	REEMBOLSO: "Reembolso",
};

export const STATUS_ADIANTAMENTO_OPTIONS = [
	{ value: "PENDENTE", label: "Pendente" },
	{ value: "PAGO", label: "Pago" },
	{ value: "CANCELADO", label: "Cancelado" },
	{ value: "AGUARDANDO_VALIDACAO", label: "Aguardando validação" },
	{ value: "PAGAMENTO_INCOMPLETO", label: "Pagamento incompleto" },
	{ value: "REEMBOLSO", label: "Reembolso" },
]
