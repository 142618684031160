import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "./estilo";
import Main from "./Main";
import Navbar from "./NavBar";
import { useUsuarioMenu } from "./NavBar/hooks/useUsuarioMenu";
import PageWrapper from "./PageWrapper";
import SlideBar from "./SlideBar";
import { useSlideBar } from "./SlideBar/hooks/useSlideBar";
import Wrapper from "./Wrapper";
import { ReactNode } from "react";

interface Prop {
  children: ReactNode;
}

export default function TemaInspinia({ children }: Prop) {
  const { activeMiniNavbar, alterMiniNavbar } = useSlideBar();

  const { aberto, setAberto } = useUsuarioMenu();

  return (
    <Container>
      <Main activeMiniNavbar={activeMiniNavbar}>
        <Navbar
          alterMiniNavbar={alterMiniNavbar}
          activeMiniNavbar={activeMiniNavbar}
          aberto={aberto}
          setAberto={setAberto}
        />
        <Wrapper>
          <SlideBar
            alterMiniNavbar={alterMiniNavbar}
            activeMiniNavbar={activeMiniNavbar}
            aberto={aberto}
            setAberto={setAberto}
          />
          <PageWrapper>

            <div
              onClick={() => {
                if (aberto) {
                  setAberto(false);
                }
              }}
            >
              {children}
            </div>
          </PageWrapper>
        </Wrapper>
      </Main>
    </Container>
  );
}
