import styled from "styled-components";

const ToolsHeaderTableContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Tools = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  width: 40%;

  @media (max-width: 1600px) {
    width: 45%;
  }

  @media (max-width: 1399px) {
    width: 50%;
  }
`;

const Search = styled.div`
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  display: none;
  width: 10%;
`;

const ExportExcel = styled.div`
  text-align: center;
  width: 30%;
  display: flex;
  justify-content: end;
  position: inherit;
  right: 0;
  padding: 0 10px;
`;

export { ToolsHeaderTableContainer, Tools, Search, ExportExcel };
