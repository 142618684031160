import { yupResolver } from "@hookform/resolvers/yup";
import { FilterButton } from "components/Button/style";
import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect } from "components/DropdownSelect";
import {
  STATUS_PAGAMENTO_DETALHES_OPTIONS,
  TIPO_DEBITOS_OPTIONS,
  VENCIMENTO_OPTIONS,
} from "features/relatorios/pagamento/constants/pagamentoConstants";
import { filtrosDetalhesRelatorioPagamentoSchema } from "features/relatorios/pagamento/validate/validator";
import { useMaskInput } from "hooks/useMaskInput";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter } from "react-icons/fa";
import { InputContainer } from "../../../relatorio/containers/FiltrosRelatorioPagamentos/style";
import { ButtonContainer } from "./style";
import { MaisFiltros } from "components/MaisFiltros";
import { useMaisFiltros } from "hooks/useMaisFiltros";

interface Props {
  handleSubmitFiltros: (data: any) => void;
}

export function FIltrosDetalhesRelatorioPagamento({
  handleSubmitFiltros,
}: Props) {
  const {
    handleAplicarMaisFiltros,
    handleFormSubmit,
    handleNaoUsarMaisFiltros,
  } = useMaisFiltros();
  const { mInputPlaca } = useMaskInput();

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(filtrosDetalhesRelatorioPagamentoSchema),
  });

  return (
    <Container titulo="Filtros">
      <Form
        noValidate
        onSubmit={handleSubmit((dados) => {
          handleFormSubmit({
            dados,
            excluir: ["renavam"],
            resetCallback: reset,
            submitCallback: handleSubmitFiltros,
          });
        })}
      >
        <Row>
          <InputContainer lg="2">
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Digite a placa"
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="statusPagamento"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    title="Status Pagamento"
                    onSelect={onChange}
                    options={STATUS_PAGAMENTO_DETALHES_OPTIONS}
                    selectedValue={value}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="vencimento"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={VENCIMENTO_OPTIONS}
                    title="Vencimento"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="tipoDebito"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={TIPO_DEBITOS_OPTIONS}
                    title="Tipo Debito"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <ButtonContainer>
              <FilterButton type="submit" disabled={false} loading={false}>
                <FaFilter /> Filtrar
              </FilterButton>
              <FilterButton
                type="reset"
                onClick={() => {
                  reset();
                }}
                disabled={false}
                loading={false}
                outline={true}
              >
                Limpar
              </FilterButton>
            </ButtonContainer>
          </Col>
          <Col lg="2">
            <MaisFiltros
              cancelarOnClick={() =>
                handleNaoUsarMaisFiltros({
                  excluir: ["renavam", "statusPagamento"],
                  resetCallback: reset,
                })
              }
              handleClickAplicarFiltros={handleAplicarMaisFiltros}
              limparTudoOnClick={() =>
                handleNaoUsarMaisFiltros({
                  excluir: ["renavam", "statusPagamento"],
                  resetCallback: reset,
                })
              }
            >
              <Form.Group>
                <Form.Label>Renavam</Form.Label>
                <Controller
                  control={control}
                  name="renavam"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      placeholder="Digite o renavam"
                      value={value ?? ""}
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </MaisFiltros>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

