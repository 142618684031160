import styled from "styled-components";

interface IconeContainerProps {
  cor: "erro" | "sucesso";
}

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const IconeContainer = styled.div<IconeContainerProps>`
  background-color: ${({ theme, cor }) =>
    cor === "sucesso" ? theme.colors.project.bgColor : theme.colors.danger.bgColor};
  padding: 0.5rem;
  border-radius: 0.5rem;

  svg {
    font-size: 1.5rem;
    color: ${({ theme, cor }) => (cor === "sucesso" ? theme.colors.project.main : theme.colors.danger.main)};
  }
`;

export const MensagemSucessoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

