import styled from "styled-components";

interface ExportButtonProps {
  variant: "primary" | "secondary";
}

const ExportExcelContainer = styled.div<ExportButtonProps>`
  background-color: ${({ theme, variant }) => (variant === "primary" ? theme.colors.primary.main : theme.colors.white)};
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  border-radius: 0.5rem;
  color: ${({ theme, variant }) => (variant === "primary" ? theme.colors.white : theme.colors.primary.main)};
  cursor: pointer;
  font-weight: bold;
  ${({ variant, theme }) => variant === "secondary" && `border: 1px solid ${theme.colors.primary.main}`};

  &:hover {
    color: ${({ theme, variant }) =>
      variant === "primary" ? theme.colors.neutral.neutral10 : theme.colors.primary.hover};
    ${({ variant, theme }) => variant === "secondary" && `border: 1px solid ${theme.colors.primary.hover}`};
    transition: all ease-in-out 0.25s;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: end;
  gap: 1.5rem;
  width: 100%;
  width: 100%;
`;

const ButtonToggleVisualizacao = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.neutral.neutral70};
  font-weight: bold;
  transition: color ease-in-out 0.2s;

  svg {
    color: ${({ theme }) => theme.colors.neutral.neutral70} !important;
    width: 1rem !important;
    height: 1rem;
    transition: color ease-in-out 0.2s;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutral.neutral50};

    svg {
      color: ${({ theme }) => theme.colors.neutral.neutral50} !important;
    }
  }

  @media (max-width: 1370px) {
    font-size: 0.75rem;
  }
`;

export { ExportExcelContainer, ButtonToggleVisualizacao, Container };

