import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container, IconeContainer, Texto, TextoContainer } from "./style";

interface IModalConfirmacaoPagamentoManual {
  show: boolean;
  handleClose: () => void;
}

export function ModalConfirmacaoPagamentoManual({ show, handleClose }: IModalConfirmacaoPagamentoManual) {
  return (
    <ModalCustom show={show} handleClose={handleClose} title="Pagamento manual" centered={true}>
      <Container>
        <IconeContainer>
          <FaCheck />
        </IconeContainer>
        <TextoContainer>
          <Texto>Pagamento informado com sucesso!</Texto>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}
