import { Page } from "containers/Page";
import { useEffect } from "react";
import { useRelatorioPagamentosRecusados } from "../../hooks/useRelatorioPagamentosRecusados";
import { FiltrosRelatorioPagamentosRecusados } from "./containers/FiltrosRelatorioPagamentosRecusados";
import { ListarPagamentosRecusados } from "./containers/ListarPagamentosRecusados";

export function RelatorioPagamentosRecusados() {
  const {
    buscarDados,
    dados,
    loadingDados,
    criarQueryParamsRelatorio,
    setLimit,
    setPage,
    limit,
    page,
    queryParams,
    totalRows,
  } = useRelatorioPagamentosRecusados();

  useEffect(() => {
    buscarDados();
  }, [limit, page, queryParams, buscarDados]);

  function handlePageChange(page: number) {
    setPage(page);
  }

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }
  async function handleSubmitFiltros(data: any) {
    criarQueryParamsRelatorio(data);
  }

  return (
    <Page title="Pagamentos Recusados">
      <FiltrosRelatorioPagamentosRecusados
        handleSubmitFiltros={handleSubmitFiltros}
      />
      <ListarPagamentosRecusados
        totalRows={totalRows}
        rowsPerPage={limit}
        loading={loadingDados}
        dados={dados}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
      />
    </Page>
  );
}
