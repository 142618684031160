import styled from "styled-components";

export const InputContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 1rem;
  height: 100px;
`;

export const InputDescricaoWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 0.5rem;
  text-align: start;
`;

export const InputArquivoWrapper = styled.div`
  width: 75%;
  display: grid;
  gap: 0.5rem;
  text-align: start;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
  margin-top: 2rem;
`;
