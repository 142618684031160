import { IPagamento } from "features/relatorios/pagamento/interfaces/Pagamento";
import { BaixarVisualizarContainer, BaixarVisualizarItem, Container, ContainerBody, ContainerTitle, CursorPointer, ItemBody, ItemTitle, StatusAdiantamentoContainer, VerticalDivider } from "../../style";
import { Col, Row } from "react-bootstrap";
import { converterStringDataEuaParaBr } from "_utils/Data";
import { StatusAdiantamentoConst, StatusAdiantamentoEnum } from "features/debito/pagamento/enum/StatusAdiantamentoEnum";
import { handleIcone } from "features/debito/pagamento/pages/Listar/containers/StatusAdiantamento/_utils/handleIcone";
import { FaDownload, FaEye } from "react-icons/fa";

interface Props {
  statusAdiantamento: StatusAdiantamentoEnum;
  pagamentos: IPagamento[];
}
export function DadosPagamento({
  statusAdiantamento,
  pagamentos,
}: Props) {

  const onClickBaixar = async (index: number) => {
    if (!pagamentos[index] || !pagamentos[index].url || !pagamentos[index].arquivo) {
      return;
    }
    const response = await fetch(pagamentos[index].url);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.download = `${pagamentos[index].arquivo}`;
    link.href = url;
    link.click();
  }
  const onClickVisualizar = (index: number) => {
    if (!pagamentos[index] || !pagamentos[index].url || !pagamentos[index].arquivo) {
      return;
    }
    window.open(`${pagamentos[index].url}`)
  }
  return (
    <Container>
      <ContainerTitle>
        Dados de pagamento
      </ContainerTitle>
      <ContainerBody>
        <Row>
          <Col md="3">
            <ItemTitle>Status do adiantamento</ItemTitle>
            <StatusAdiantamentoContainer
              statusAdiantamento={statusAdiantamento}
            >
              {handleIcone(statusAdiantamento)}
              <span style={{ marginLeft: 5 }}>{StatusAdiantamentoConst[statusAdiantamento]}</span>
            </StatusAdiantamentoContainer>
          </Col>
          {pagamentos && pagamentos.length <= 1 && <>
            <Col md="3">
              <ItemTitle>Data de pagamento</ItemTitle>
              <ItemBody>{pagamentos[0] && pagamentos[0].dataPagamento ? converterStringDataEuaParaBr(pagamentos[0].dataPagamento.split("T")[0]) : "--"}</ItemBody>
            </Col>
            <Col>
              <ItemTitle>Comprovante de pagamento</ItemTitle>
              <ItemBody>{pagamentos[0] && pagamentos[0].arquivo ? pagamentos[0].arquivo.split("/")[1] : "--"}</ItemBody>
            </Col>
            <Col>
              <BaixarVisualizarContainer disabled={!pagamentos[0]?.url}>
                <BaixarVisualizarItem
                  disabled={!pagamentos[0]?.url}
                  onClick={() => onClickBaixar(0)}
                >
                  Baixar <FaDownload />
                </BaixarVisualizarItem>
                <VerticalDivider />
                <BaixarVisualizarItem
                  disabled={!pagamentos[0]?.url}
                  onClick={() => onClickVisualizar(0)}
                >
                  Visualizar <FaEye />
                </BaixarVisualizarItem>
              </BaixarVisualizarContainer>
            </Col>
          </>}
        </Row>
        {pagamentos && pagamentos.length > 1 && pagamentos.map((pagamento, index) => {
          return (
            <Row key={pagamento.url} style={{ marginTop: 8 }}>
              <Col md="3">
                <ItemTitle>Data de pagamento</ItemTitle>
                <ItemBody>{pagamento.dataPagamento ? converterStringDataEuaParaBr(pagamento.dataPagamento.split("T")[0]) : "--"}</ItemBody>
              </Col>
              <Col>
                <ItemTitle>Comprovante de pagamento</ItemTitle>
                <ItemBody>{pagamento.arquivo ? pagamento.arquivo.split("/")[1] : "--"}</ItemBody>
              </Col>
              <Col>
                <BaixarVisualizarContainer disabled={!pagamento.url}>
                  <BaixarVisualizarItem
                    disabled={!pagamento.url}
                    onClick={() => onClickBaixar(index)}
                  >
                    Baixar <FaDownload />
                  </BaixarVisualizarItem>
                  <BaixarVisualizarItem
                    disabled={!pagamento.url}
                    onClick={() => onClickVisualizar(index)}
                  >
                    Visualizar <FaEye />
                  </BaixarVisualizarItem>
                </BaixarVisualizarContainer>
              </Col>
            </Row >
          )
        })
        }
      </ContainerBody>
    </Container>
  );
}