import { FaDownload } from "react-icons/fa";
import { Container, StyledIcon, StyledLink } from "./style";

interface IDownloadLink {
  link: string;
  disabled?: boolean;
}

interface IDownloadLinkFunction {
  disabled?: boolean;
  handleDonwload: () => void;
}

export function DownloadLink({ link, disabled = false }: IDownloadLink) {
  return (
    <Container>
      <StyledLink download href={link} disabled={disabled}>
        Download <FaDownload />
      </StyledLink>
    </Container>
  );
}

export function DownloadLinkFunction({
  disabled = false,
  handleDonwload,
}: IDownloadLinkFunction) {
  return (
    <Container>
      <StyledLink download disabled={disabled} onClick={disabled ? () => { } : handleDonwload}>
        Download <FaDownload />
      </StyledLink>
    </Container>
  );
}

export function DownloadIconFunction({
  disabled = false,
  handleDonwload,
}: IDownloadLinkFunction) {
  return (
    <Container>
      <StyledIcon download disabled={disabled} onClick={disabled ? () => { } : handleDonwload}>
        <FaDownload />
      </StyledIcon>
    </Container>
  );
}
