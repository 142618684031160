export const TIPO_DEBITOS_OPTIONS = [
  {
    value: "1",
    label: "IPVA"
  },
  {
    value: "2",
    label: "LICENCIAMENTO"
  }
]

export const VENCIMENTO_OPTIONS = [
  {
    value: "vencidos",
    label: "Vencidos"
  },
  {
    value: "aVencer",
    label: "À vencer"
  }
]