import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container, IconeContainer, Texto, TextoContainer } from "./style";


interface IModalEmitirSucesso {
    show: boolean;
    handleClose: () => void;
    idSolicitacao: number;
}

export function ModalEmitirSucesso({
    show,
    handleClose,
    idSolicitacao,
}: IModalEmitirSucesso) {
    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Emitir licenciamento"
            centered={true}
            size="lg"
        >
            <Container>
                <IconeContainer>
                    <FaCheck />
                </IconeContainer>
                <TextoContainer>
                    <Texto>Solicitação efetuada com sucesso! <strong>A emissão está sendo processada.</strong></Texto>
                    <Texto>ID de solicitação: {idSolicitacao}</Texto>
                </TextoContainer>
            </Container>
        </ModalCustom>
    );
}
