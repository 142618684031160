import { Question } from "components/Icons/Question";
import { InfoTooltip } from "components/InfoTooltip";
import { Required } from "components/Required";
import { Tooltip } from "components/Tooltip";
import React, { useEffect, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import {
  Container,
  DropdownButton,
  DropdownContainer,
  DropdownMenu,
  Span,
  SpanMensagemErro,
  Title,
} from "./style";

interface IDropdownSelect {
  options: IOptions[];
  title?: string;
  searchable?: boolean;
  onSelect: (e: any) => void;
  selectedValue: any;
  disabled?: boolean;
  mensagemErro?: NullableString;
  required?: boolean;
  maxHeight?: number;
  tooltipText?: string;
  tooltipWidth?: number;
  tooltipBottom?: number;
  height?: string;
}

export interface IOptions {
  value: string | number;
  label: string;
}

interface ICustomToggleProps {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  selected?: boolean;
  comErro: boolean;
  height?: string;
}

interface ICustomMenuProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  labeledBy?: string;
  searchable: boolean;
  maxHeight?: number;
}

const CustomToggle = React.forwardRef(
  (props: ICustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <DropdownButton
      comErro={props.comErro}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick!(e);
      }}
      selected={props.selected!}
      height={props.height}
    >
      {props.children}
      <RiArrowDropDownLine />
    </DropdownButton>
  )
);

const CustomMenu = React.forwardRef(
  (props: ICustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
    const [value, setValue] = useState("");

    return (
      <DropdownMenu
        ref={ref}
        style={props.style}
        className={props.className}
        aria-labelledby={props.labeledBy}
        searchable={props.searchable}
        maxHeight={props.maxHeight}
      >
        <FormControl
          autoFocus
          placeholder={`Pesquise`}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(props.children).filter(
            (child: any) =>
              !value ||
              child.props.children.toLowerCase().includes(value.toLowerCase())
          )}
        </ul>
      </DropdownMenu>
    );
  }
);

export function DropdownSelect({
  options,
  title,
  searchable = false,
  onSelect,
  selectedValue,
  disabled = false,
  mensagemErro = null,
  required = false,
  maxHeight = 0,
  tooltipWidth = 185,
  tooltipBottom = -3.7,
  tooltipText,
  height = "2.2rem",
}: IDropdownSelect) {
  const [selected, setSelected] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>("Selecione");

  useEffect(() => {
    setSelected(!!selectedValue);
    const selectedOption =
      options.find((option) => String(option.value) === String(selectedValue))
        ?.label ?? "Selecione";
    setSelectedLabel(selectedOption);
  }, [selectedValue, options]);

  return (
    <Container>
      {title && (
        <Title desativado={disabled}>
          {title} {required && <Required />}{" "}
          {tooltipText && (
            <Tooltip
              bottom={tooltipBottom}
              width={tooltipWidth}
              trigger={<Question />}
            >
              <InfoTooltip mensagem={tooltipText}></InfoTooltip>
            </Tooltip>
          )}
        </Title>
      )}
      <DropdownContainer onSelect={onSelect}>
        <Dropdown.Toggle
          selected={selected}
          comErro={!!mensagemErro}
          height={height}
          as={CustomToggle}
        >
          <Span desativado={disabled}>{selectedLabel}</Span>
        </Dropdown.Toggle>

        {!disabled && (
          <Dropdown.Menu
            searchable={searchable}
            as={CustomMenu}
            maxHeight={maxHeight}
          >
            <Dropdown.Item key="Selecione">Selecione</Dropdown.Item>
            {options.map((option) => (
              <Dropdown.Item
                key={`${option.value}${option.label}`}
                eventKey={option.value}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
        {mensagemErro && <SpanMensagemErro>{mensagemErro}</SpanMensagemErro>}
      </DropdownContainer>
    </Container>
  );
}
