import { StatusConsulta } from "features/licenciamento/consulta/enum/StatusConsulta";
import { Container } from "./style";

interface Props {
    statusConsulta: string;
}

export function StatusConsultaLicenciamento({
    statusConsulta,
}: Props) {
    return (
        <Container
            statusConsulta={Number(statusConsulta)}
        >
            {StatusConsulta[Number(statusConsulta)]}
        </Container>
    );
}
