import { Stronger } from "components/Stronger";
import { TextoClaro } from "components/TextoClaro";
import { Row } from "react-bootstrap";
import { RatingSeguridadeContainer } from "./style";

export interface IRatingSeguridade {
  tipo: "ACEITACAO_SEGURO" | "FIPE_PARCIAL" | "VISTORIA_ESPECIAL";
  indicacaoProbabilidade: string;
  descricao: string;
}

export enum RatingSeguridade {
  "ACEITACAO_SEGURO" = "Aceitação seguro",
  "FIPE_PARCIAL" = "Fipe parcial",
  "VISTORIA_ESPECIAL" = "Vistoria especial",
}

interface Props {
  ratingsSeguridade: IRatingSeguridade[];
}

export function BodyRatingSeguridade({ ratingsSeguridade }: Readonly<Props>) {
  return (
    <>
      <TextoClaro>Rating seguridade</TextoClaro>
      {ratingsSeguridade.length ? (
        ratingsSeguridade.map((rating) => (
          <Row className="sem-margin-nas-divs-filhas" key={rating.tipo}>
            <RatingSeguridadeContainer className="sem-margin-nas-divs-filhas">
              <Stronger>{RatingSeguridade[rating.tipo]}</Stronger> <br />
              <div>
                <span>
                  Indicador de probabilidade:{" "}
                  {rating.indicacaoProbabilidade
                    ? rating.indicacaoProbabilidade
                    : "-"}
                </span>{" "}
                <br />
                <span>Descrição: {rating.descricao}</span>
              </div>
            </RatingSeguridadeContainer>
          </Row>
        ))
      ) : (
        <Stronger> - </Stronger>
      )}
    </>
  );
}
