import { useEmpresas } from "hooks/useEmpresas";
import { ButtonContainer, RadioContainer, SpanErro, TooltipContainer } from "./style";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { Controller, useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { cobrancaSchema } from "features/licenciamento/consulta/validate/validator";
import { Col, Form, Row } from "react-bootstrap";
import { PrimaryButton } from "components/Button/style";
import { Tooltip } from "components/Tooltip";
import { Question } from "components/Icons/Question";
import { ContextPermissao } from "hooks/ContextPermissao";
import { Cobrar, MotivosIsencaoOptions } from "features/licenciamento/emissao/constants";

interface IFormEmitir {
  cobrar: NullableString;
  empresa: NullableString;
  motivoIsencao: NullableString;
  exercicio: string;
}

interface Props {
  submitForm: (dados: any) => void;
}

export function BodySP({ submitForm }: Props) {
  const { criarOptionsAgrupamento, agrupamentoOptions } = useEmpresas();
  const [optionsExercicio, setOptionsExercicio] = useState<IOptions[]>([]);
  const [optionSeleted, setOptionSelected] = useState<null | number>();

  useEffect(() => {
    const anoAtual = new Date().getFullYear();

    setOptionsExercicio([
      {
        label: `${anoAtual}`,
        value: anoAtual,
      },
      {
        label: `${anoAtual - 1}`,
        value: anoAtual - 1,
      },
    ]);
    setValue("exercicio", `${anoAtual}`);
    setOptionSelected(anoAtual);
  }, []);

  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<IFormEmitir>({
    resolver: yupResolver(cobrancaSchema),
  });

  const [cobranca, setCobranca] = useState<string | null>(null);
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);

  const bottomTooltipConsultar = 1.3;
  const rightTooltipConsultar = 50;
  const widthTooltipConsultar = 350;
  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Row style={{ marginTop: "16px" }}>
        <Col md={5}>
          <Form.Group>
            <Form.Label>Empresa</Form.Label>
            <Controller
              name="empresa"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DropdownSelect
                  onSelect={onChange}
                  options={agrupamentoOptions}
                  searchable={true}
                  selectedValue={value}
                  required
                  mensagemErro={errors.empresa?.message}
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Exercício{" "}
              <Tooltip
                bottom={bottomTooltipConsultar}
                right={rightTooltipConsultar}
                width={widthTooltipConsultar}
                trigger={<Question />}
              >
                <TooltipContainer>Informe o ano do licenciamento que deseja emitir</TooltipContainer>
              </Tooltip>
            </Form.Label>
            <Controller
              control={control}
              name="exercicio"
              render={({ field: { onChange } }) => (
                <DropdownSelect
                  options={optionsExercicio}
                  selectedValue={optionSeleted}
                  onSelect={(e) => {
                    setOptionSelected(e);
                    onChange(e);
                  }}
                  mensagemErro={errors.exercicio?.message}
                />
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          {verificarUsuarioAwLw() && (
            <RadioContainer>
              <span>Gerar recibo de cobrança</span>
              <div>
                <div>
                  <Controller
                    name="cobrar"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <input
                        id="radioSim"
                        type="radio"
                        value={Cobrar.SIM}
                        onChange={(event: any) => {
                          setCobranca("SIM");
                          onChange(event);
                        }}
                        checked={cobranca === "SIM"}
                      />
                    )}
                  />
                  <label htmlFor="radioSim">Sim</label>
                </div>
                <div>
                  <Controller
                    name="cobrar"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <input
                        id="radioNao"
                        type="radio"
                        value={Cobrar.NAO}
                        onChange={(event: any) => {
                          setCobranca("NAO");
                          onChange(event);
                        }}
                        checked={cobranca === "NAO"}
                      />
                    )}
                  />
                  <label htmlFor="radioNao">Não</label>
                </div>
              </div>
              <SpanErro>{errors.cobrar?.message}</SpanErro>
            </RadioContainer>
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        {cobranca === "NAO" && (
          <Controller
            control={control}
            name="motivoIsencao"
            render={({ field: { onChange, value } }) => (
              <DropdownSelect
                onSelect={onChange}
                options={MotivosIsencaoOptions}
                selectedValue={value}
                title="Motivo da isenção da cobrança"
                mensagemErro={errors.motivoIsencao?.message}
              />
            )}
          />
        )}
      </Row>
      <Row style={{ marginTop: "24px" }}>
        <ButtonContainer lg="12" style={{ justifyContent: "end" }}>
          <PrimaryButton type="submit">Emitir</PrimaryButton>
        </ButtonContainer>
      </Row>
    </Form>
  );
}
