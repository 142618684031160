import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

interface LoadingProps {
  mostrar: boolean;
  noWidth: boolean;
}

export const LoadingContainer = styled.div<LoadingProps>`
  ${(props) => (props.noWidth ? "" : `width: 100%`)};
  height: 2.4rem;
  animation: ${rotate} 2s linear infinite;
  display: ${(props) => (props.mostrar ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  align-self: center;
`;
