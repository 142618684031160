import { Col } from "react-bootstrap";
import styled from "styled-components";

export const ButtonContainer = styled(Col)`
  display: flex;
  height: 100px;
  align-items: center;
  gap: 1rem;

  button {
    margin-bottom: 0.5rem;
  }
`;

export const DatePickerContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

export const InputContainer = styled(Col)`
    display: grid;
    height: 100px;

    label {
        font-size: 14px;
    }
`;
