import { Data } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { Col, Row } from "react-bootstrap";
import { Container } from "./style";

interface Props {
  id?: string;
  resumo: any;
}

export function Resumo({ id, resumo }: Props) {
  return (
    <Container>
      {resumo && (
        <>
          <Row className="mb-4">
            <Col className="d-flex flex-column">
              <div>Empresa</div>
              <div>
                <b>{resumo.empresa}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Usuário</div>
              <div>
                <b>{resumo.nomeUsuario}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>ID Consulta</div>
              <div>
                <b>{resumo.consultaId}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Data/hora solicitação</div>
              <div>
                <b>{resumo.dataHora ? new Data(resumo.dataHora).getDataHoraBr() : "-"}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Placas solicitadas</div>
              <div>
                <b>{resumo.qtdPlacasSolicitadas}</b>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column">
              <div>Placas consultadas</div>
              <div>
                <b>{resumo.qtdPlacasConsultadas}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Débitos disponíveis</div>
              <div>
                <b>{resumo.qtdDebitosDisponiveis}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Pagamento solicitados</div>
              <div>
                <b>{resumo.qtdPlacasSolicitadoPagamento}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Valor da solicitação</div>
              <div>
                <b>{resumo.valorTotal ? formatarDinheiro(resumo.valorTotal / 100) : "-"}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>ID Recibo</div>
              <div>
                <b>{resumo.idRecibo || "-"}</b>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
