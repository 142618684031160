import { IOptions } from "components/DropdownSelect";

export const UF_OPTIONS = [
  {
    value: "MG",
    label: "MG",
  },
  {
    value: "PR",
    label: "PR",
  },
  {
    value: "SP",
    label: "SP",
  },
];

export const UFS_QUE_CONSULTAMOS = ["MG", "PR", "SP"];

export const UF_CONSULTAS_OPTIONS = [
  {
    value: "SP",
    label: "SP",
  },
];

export const UFS_LICENCIAMENTO_STRATEGY: { [key: string]: IOptions[] } = {
  consulta: UF_CONSULTAS_OPTIONS,
  emissao: UF_OPTIONS,
};
