import { DropdownSelect } from "components/DropdownSelect";
import { Cobrar, MotivosIsencaoOptions } from "features/licenciamento/emissao/constants";
import { ContextPermissao } from "hooks/ContextPermissao";
import { useEmpresas } from "hooks/useEmpresas";
import { ChangeEvent, ElementType, useContext, useEffect, useState } from "react";
import { Control, ControllerProps } from "react-hook-form";
import { Container, InputsContainer, RadioContainer, SelectContainer, SpanErro } from "./style";

interface Props {
  controller: ElementType<ControllerProps>;
  control: Control;
  errors: any;
  licenciamento?: boolean;
  handleChangeEmpresa?: (idEmpresa: number | string) => void;
  setCobrado?: (cobrado: boolean) => void;
  maxHeight?: number;
}

export function SelecionarEmpresa({ control, controller: Controller, errors, licenciamento = true, handleChangeEmpresa = (idEmpresa) => { }, setCobrado = (cobrado) => { }, maxHeight = 0 }: Readonly<Props>) {
  const [cobranca, setCobranca] = useState<string | null>(null);
  const { criarOptionsAgrupamento, agrupamentoOptions } = useEmpresas();
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);

  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);

  return (
    <Container>
      {licenciamento && <span>Selecione a empresa para qual deseja emitir o licenciamento</span>}
      <InputsContainer>
        <SelectContainer>
          <Controller
            name="empresa"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DropdownSelect
                onSelect={(e) => {
                  onChange(e);
                  handleChangeEmpresa(e);
                }}
                options={agrupamentoOptions}
                selectedValue={value}
                title="Empresa"
                searchable={true}
                required
                mensagemErro={errors.empresa?.message}
                maxHeight={maxHeight}
              />
            )}
          />
        </SelectContainer>
        {verificarUsuarioAwLw() && (
          <RadioContainer>
            <span>Gerar recibo de cobrança</span>
            <div>
              <div>
                <Controller
                  name="cobrar"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <input
                      id="radioSim"
                      type="radio"
                      value={Cobrar.SIM}
                      onChange={(event: any) => {
                        setCobranca("SIM");
                        onChange(event);
                        setCobrado(true);
                      }}
                      checked={cobranca === "SIM"}
                    />
                  )}
                />
                <label htmlFor="radioSim">Sim</label>
              </div>
              <div>
                <Controller
                  name="cobrar"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <input
                      id="radioNao"
                      type="radio"
                      value={Cobrar.NAO}
                      onChange={(event: any) => {
                        setCobranca("NAO");
                        onChange(event);
                        setCobrado(false);
                      }}
                      checked={cobranca === "NAO"}
                    />
                  )}
                />
                <label htmlFor="radioNao">Não</label>
              </div>
            </div>
            <SpanErro>{errors.cobrar?.message}</SpanErro>
          </RadioContainer>
        )}
      </InputsContainer>
      {cobranca === "NAO" && (
        <Controller
          control={control}
          name="motivoIsencao"
          render={({ field: { onChange, value } }) => (
            <DropdownSelect
              onSelect={onChange}
              options={MotivosIsencaoOptions}
              selectedValue={value}
              title="Motivo da isenção da cobrança"
              mensagemErro={errors.motivoIsencao?.message}
            />
          )}
        />
      )}
    </Container>
  );
}
