import { Container, TextoContainer, TitleContainer } from "./style";
import {
  FaExclamationTriangle,
  FaCheck,
  FaExclamationCircle,
} from "react-icons/fa";
import { HiX } from "react-icons/hi";
import React, { ReactNode, useState } from "react";

interface IInformacao {
  type?: "atencao" | "erro" | "info" | "sucesso" | "branco";
  mensagem: string | ReactNode;
  showBotaoFechar?: boolean;
  showLabel?: boolean;
  className?: string;
  title?: boolean;
  style?: React.CSSProperties | undefined;
}

export function Informacao({
  type = "info",
  mensagem,
  showBotaoFechar = true,
  showLabel = true,
  className,
  title = true,
  style
}: IInformacao) {
  const [mostrar, setMostrar] = useState(true);

  function handleClickBotaoFechar() {
    setMostrar(false);
  }

  const cardStrategy = {
    atencao: (
      <span>
        <FaExclamationTriangle />
        {showLabel && "Atenção"}
      </span>
    ),
    sucesso: (
      <span>
        <FaCheck /> 
        {showLabel && "Sucesso"}
      </span>
    ),
    erro: (
      <span>
        <FaExclamationCircle /> 
        {showLabel && "Erro"}
      </span>
    ),
    info: (
      <span>
        <FaExclamationCircle /> 
        {showLabel && "Info"}
      </span>
    ),
    branco: (
      <span>
        <FaExclamationCircle /> 
        {showLabel && "Info"}
      </span>
    ),
  };

  return (
    <Container className={className} mostrar={mostrar} showLabel={showLabel} type={type} style={style}>
      {title && type !== 'branco' && <TitleContainer type={type}>
        {cardStrategy[type]}
        {showBotaoFechar && (
          <HiX className="btnFechar" onClick={handleClickBotaoFechar} />
        )}
      </TitleContainer>}
      <TextoContainer>{mensagem}</TextoContainer>
    </Container>
  );
}

