import { Col } from "react-bootstrap";
import { formatarDinheiro } from "_utils/Money";
import {
  ContagemDebitos,
  Container,
  Divider,
  Titulo,
  TotaisContainer,
  TotalLinhas,
  SLAContainer,
  TextoLg,
  InputContainer,
  TooltipContainer,
} from "./style";
import { Informacao } from "components/Informacao";
import { Tooltip } from "components/Tooltip";
import { FaInfoCircle } from "react-icons/fa";

interface Props {
  total: number;
  frase: string;
  linhasSelecionadas: number;
  foraDeSla: number;
  handleCheck: (checked: boolean) => void;
}

export function CalcularDebitos({ total, linhasSelecionadas, frase, foraDeSla, handleCheck }: Props) {
  const mensagemInfo = `
    Você está solicitando o pagamento dos débitos listados abaixo fora do prazo de SLA
    de cinco dias antes do vencimento, poderá ocorrer a incidência de juros nestes débitos.
    Deseja autorizar o pagamento?`;
  const mensagemTooltip = `
    Sem adição de juros e multas de débitos fora do prazo de SLA para pagamento.
  `;
  const bottomTooltipConsultar = 1.3;
  const rightTooltipConsultar = -1700;
  const widthTooltipConsultar = 350;

  return (
    <Container>
      {linhasSelecionadas > 0 ? (
        <>
          {foraDeSla > 0 && (
            <SLAContainer>
              <Informacao mensagem={mensagemInfo} type="atencao" />
              <Titulo>
                Quantidade de débitos fora do prazo de SLA: <TextoLg>{foraDeSla}</TextoLg>
              </Titulo>
              <InputContainer>
                <input
                  onChange={(e) => handleCheck(e.target.checked)}
                  type="checkbox"
                  name="autorizarSla"
                  id="autorizarSla"
                />
                <label htmlFor="autorizarSla">Estou ciente e desejo autorizar o pagamento dos débitos</label>
              </InputContainer>
            </SLAContainer>
          )}
          <Titulo>{foraDeSla === 0 && frase}</Titulo>
          <Divider />
          <TotaisContainer>
            <Col>
              <TotalLinhas>
                Total de pagamentos solicitados:
                <ContagemDebitos> {linhasSelecionadas}</ContagemDebitos>
              </TotalLinhas>
            </Col>
            <Col>
              <TotalLinhas>
                Valor total:{" "}
                <ContagemDebitos>
                  {formatarDinheiro(total / 100)}{" "}
                  {foraDeSla > 0 && (
                    <Tooltip
                      bottom={bottomTooltipConsultar}
                      right={rightTooltipConsultar}
                      width={widthTooltipConsultar}
                      trigger={<FaInfoCircle />}
                    >
                      <TooltipContainer>{mensagemTooltip}</TooltipContainer>
                    </Tooltip>
                  )}
                </ContagemDebitos>
              </TotalLinhas>
            </Col>
          </TotaisContainer>
        </>
      ) : (
        <div>Nenhum débito disponível para pagamento</div>
      )}
    </Container>
  );
}

