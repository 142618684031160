import { StatusDebitoPagamento } from "features/debito/pagamento/pages/Detalhes/Enum/StatusDebitoPagamento";
import styled from "styled-components";

interface StatusProps {
  statusPagamento: number;
}

export const Container = styled.button<StatusProps>`
  padding: 0.2rem 0.6rem;
  border-radius: 1rem;
  text-align: center;
  width: 100px;
  ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Pendente"] &&
    `
        background-color:${theme.colors.tags.warning.bgColor};
        color:${theme.colors.tags.warning.textColor};
        border:1px solid ${theme.colors.tags.warning.textColor};
    `}

    ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Pago"] &&
    `
        background-color:${theme.colors.tags.success.bgColor};
        color:${theme.colors.tags.success.textColor};
        border:1px solid ${theme.colors.tags.success.textColor};
    `}

    ${({ statusPagamento, theme }) =>
    statusPagamento === StatusDebitoPagamento["Recusado"] &&
    `
        background-color:${theme.colors.tags.disabled.bgColor};
        color:${theme.colors.tags.disabled.textColor};
        border:1px solid ${theme.colors.tags.disabled.textColor};
    `}
`;
