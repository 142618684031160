import { DownloadIconFunction } from "components/DownloadLink";
import { RedirectDetalhesVertical } from "components/RedirectDetalhes";
import { toastErro } from "components/Toast";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { useRelatorioPagamentos } from "features/relatorios/pagamento/hooks/useRelatorioPagamentos";
import { IRelatorioPagamentos } from "features/relatorios/pagamento/interfaces/RelatorioPagamento";
import { validaStatusPagamento } from "features/relatorios/pagamento/utils/validaStatusPagamento";
import { useState } from "react";
import { converterDataHoraBr, formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { ModalConfirmarDownload } from "../../ModalConfirmarDownload";
import { StatusPagamento } from "../../StatusPagamento";
import { SpanVermelho } from "./style";
import { makeStatusAdiantamento } from "../StatusAdiantamento/patterns/factories/makeStatusAdiantamento";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";
import { TooltipAdiantamento } from "./TooltipAdiantamento";

interface ITableRelatorioPagamentos {
	loading: boolean;
	dados: IRelatorioPagamentos[];
	handleExport: () => void;
	onChangePage: (page: number) => void;
	onChangeRowsPerPage: (limit: number) => void;
	handleDownload: (idSolicitacao: number) => void;
	totalRows: number;
	loadingDownload: boolean;
	buscarDados: () => void;
}

export function TableRelatorioPagamentos({
	dados,
	loading,
	handleExport,
	onChangePage,
	onChangeRowsPerPage,
	handleDownload,
	totalRows,
	loadingDownload,
	buscarDados,
}: Readonly<ITableRelatorioPagamentos>) {
	const {
		buscarTotalComprovantes,
		loadingComprovantes,
		qtdComprovantes,
		qtdDebitos,
		placasSemComprovante,
		downloadIniciado,
	} = useRelatorioPagamentos();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [idSolicitacaoPagamento, setIdSolicitacaoPagamento] = useState<number>();

	const columns: IColum<IRelatorioPagamentos>[] = [
		{
			name: <span>ID Consulta</span>,
			selector: ({ idConsulta }: IRelatorioPagamentos) => idConsulta,
			width: "120px",
		},
		{
			name: <span>ID Pagamento</span>,
			selector: ({ id }: IRelatorioPagamentos) => id,
			width: "120px",
		},
		{
			name: <span>ID Recibo</span>,
			selector: ({ idsReciboFinanceiro }: IRelatorioPagamentos) => {
				return (idsReciboFinanceiro && <span>{idsReciboFinanceiro.replaceAll(",", ", ")}</span>) || "--";
			},
			width: "80px",
		},
		{
			name: <span>Data/Hora Solicitação</span>,
			selector: ({ dataHora }: IRelatorioPagamentos) => <span>{converterDataHoraBr(dataHora)}</span>,
			width: "175px",
		},
		{
			name: <span>Empresa</span>,
			selector: ({ empresa }: IRelatorioPagamentos) => <span>{empresa}</span>,
			width: "125px",
		},
		{
			name: "Usuário",
			selector: ({ nomeUsuario }: IRelatorioPagamentos) => <span>{nomeUsuario}</span>,
			width: "125px",
		},
		{
			name: <span>Qtd Débitos Solicitados</span>,
			selector: ({ qtdSolicitado }: IRelatorioPagamentos) => qtdSolicitado,
			width: "90x",
		},
		{
			name: <span>Valor da Solicitação</span>,
			selector: ({ valorTotal }: IRelatorioPagamentos) => formatarDinheiro(valorTotal / 100),
		},
		{
			name: <span>Pagamentos Recusados</span>,
			selector: ({ valorTotalRecusado }: IRelatorioPagamentos) => (
				<SpanVermelho>{formatarDinheiro((valorTotalRecusado ?? 0) / 100)}</SpanVermelho>
			),
		},
		{
			name: <span>Valor para Pagamento</span>,
			selector: ({ valorTotal, valorTotalRecusado, valorTotalPago, valorTotalJuros }: IRelatorioPagamentos) =>
				formatarDinheiro((valorTotal - (valorTotalRecusado ?? 0)) / 100),
		},
		{
			name: <span>Valor Pago</span>,
			selector: ({ valorTotalPago }: IRelatorioPagamentos) => formatarDinheiro(valorTotalPago / 100),
		},
		{
			name: <span>Prazo para Pagamento</span>,
			selector: ({ dataMenorPrazoPagamento }: IRelatorioPagamentos) => formataDataISOParaBR(dataMenorPrazoPagamento),
			width: "120px",
		},
		{
			name: <span>Status Pagamento</span>,
			selector: ({ qtdFinalizado, qtdSolicitado, qtdSemComprovante }: IRelatorioPagamentos) => (
				<StatusPagamento status={validaStatusPagamento({ qtdFinalizado, qtdSolicitado, qtdSemComprovante })} />
			),
			width: "150px",
		},
		{
			name: <TooltipAdiantamento />,
			selector: ({ id, statusAdiantamento, formaPgto, idReciboAdiantamento }: IRelatorioPagamentos) =>
				makeStatusAdiantamento({
					statusAdiantamento: statusAdiantamento,
					idSolicitacaoPagamento: id,
					formaPgtoSalva: FormaPgtoEnum[formaPgto],
					buscarDados,
					idReciboAdiantamento,
				}),
			width: "220px",
		},
		{
			name: <span>Pagamentos Realizados</span>,
			selector: ({ qtdFinalizado }: IRelatorioPagamentos) => qtdFinalizado,
		},
		{
			name: <span>Pagamentos Não Realizados</span>,
			selector: ({ qtdFinalizado, qtdSolicitado }: IRelatorioPagamentos) => qtdSolicitado - qtdFinalizado,
		},
		{
			name: <span>Ações</span>,
			selector: ({ id, qtdComprovante }: IRelatorioPagamentos) => {
				return (
					<>
						<DownloadIconFunction disabled={qtdComprovante === 0} handleDonwload={() => handleShowModal(id)} />
						<RedirectDetalhesVertical path={`/relatorios/pagamento/${id}`} />
					</>
				);
			},
			width: "80px",
		},
	];

	function handleCloseModal() {
		setShowModal(false);
	}

	async function handleShowModal(idSolicitacaoPagamento: number) {
		setShowModal(true);
		setIdSolicitacaoPagamento(idSolicitacaoPagamento);
		const { data, hasErro } = await buscarTotalComprovantes(idSolicitacaoPagamento);

		if (hasErro) {
			toastErro(data.message);
			handleCloseModal();
		}
	}

	return (
		<>
			<Table
				loading={loading}
				data={dados}
				columns={columns}
				keyField="id"
				exportExcel
				handleExport={handleExport}
				paginationRowsPerPageOptions={[10, 20, 50]}
				buttonExportVariant="secondary"
				onChangePage={onChangePage}
				onChangeRowsPerPage={onChangeRowsPerPage}
				pagination
				totalRows={totalRows}
			/>
			<ModalConfirmarDownload
				handleClose={handleCloseModal}
				onConfirm={() => handleDownload(idSolicitacaoPagamento!)}
				show={showModal}
				loading={loadingComprovantes}
				qtdComprovantes={qtdComprovantes}
				qtdDebitos={qtdDebitos}
				loadingDownload={loadingDownload}
				downloadIniciado={downloadIniciado}
				placasSemComprovante={placasSemComprovante}
			/>
		</>
	);
}
