import { yupResolver } from "@hookform/resolvers/yup";
import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { CustomDatePicker } from "components/DatePicker";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useMaskInput } from "hooks/useMaskInput";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { FilterButton } from "components/Button/style";
import { FaFilter } from "react-icons/fa";
import { ButtonContainer, InputContainer } from "./style";
import { useEmpresas } from "hooks/useEmpresas";
import { ContextPermissao } from "hooks/ContextPermissao";
import { MaisFiltros } from "components/MaisFiltros";
import { useMaisFiltros } from "hooks/useMaisFiltros";
import { filtrosRelatorioExtratoVeicularSchema } from "features/relatorios/extratoVeicular/validate/validator";
import { IFiltros } from "features/relatorios/extratoVeicular/interfaces/IFiltros";
import { MotivosIsencaoOptions } from "features/licenciamento/emissao/constants";

interface IFiltrosRelatorioExtratoVeicular {
    handleSubmitFiltros: (data: IFiltros) => void;
    combosConsulta: IOptions[];
}

export function FiltrosRelatorioExtratoVeicular({
    handleSubmitFiltros,
    combosConsulta,
}: IFiltrosRelatorioExtratoVeicular) {
    const {
        handleAplicarMaisFiltros,
        handleFormSubmit,
        handleNaoUsarMaisFiltros,
    } = useMaisFiltros();
    const { mInputPlaca, mInputNum } = useMaskInput();
    const { verificarEmpresa } = useContext(ContextPermissao);

    const usuarioLW = verificarEmpresa();
    const { empresasOptions, agrupamentoOptions, criarOptionsAgrupamento } = useEmpresas();
    useEffect(() => {
        criarOptionsAgrupamento();
    }, [criarOptionsAgrupamento]);

    const { handleSubmit, control, reset } = useForm<IFiltros>({
        resolver: yupResolver(filtrosRelatorioExtratoVeicularSchema),
    });

    const [dateRange, setDateRange] = useState<any>([null, null]);

    const [startDate, endDate] = dateRange;

    function handleDateRangeSelect(update: any) {
        setDateRange(update);
    }

    return (
        <Container titulo="Filtros">
            <Form
                noValidate
                onSubmit={handleSubmit((dados) =>
                    handleFormSubmit<IFiltros>({
                        dados,
                        submitCallback: handleSubmitFiltros,
                        resetCallback: reset,
                        excluir: ["renavam", "idEmpresa", "chassi", "tipoConsulta", "idConsulta", "cobranca"],
                    })
                )}
            >
                <Row className="d-flex justify-content-between">
                    <InputContainer lg="2">
                        <Form.Group>
                            <Form.Label>Placa</Form.Label>
                            <Controller
                                control={control}
                                name="placa"
                                render={({ field: { onChange } }) => (
                                    <CustomInput
                                        type="text"
                                        placeholder="Busque por placa"
                                        onChange={(e: any) => {
                                            onChange(mInputPlaca(e).replace(/-/g, ""));
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </InputContainer>
                    <InputContainer lg="3">
                        <Form.Group>
                            <Form.Label style={{ height: "21px" }}>Período da consulta</Form.Label>
                            <Controller
                                control={control}
                                name="periodoConsulta"
                                render={({ field: { onChange } }) => (
                                    <CustomDatePicker
                                        onChange={(update) => {
                                            onChange(update);
                                            handleDateRangeSelect(update);
                                        }}
                                        rangeSelect={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                )}
                            />
                        </Form.Group>
                    </InputContainer>
                    <InputContainer lg="2">
                        <Form.Group>
                            <Form.Label>ID Recibo</Form.Label>
                            <Controller
                                control={control}
                                name="idRecibo"
                                render={({ field: { onChange, value } }) => (
                                    <CustomInput
                                        type="text"
                                        placeholder="Busque por ID"
                                        value={value ?? ""}
                                        onChange={(e: any) => {
                                            onChange(`${mInputNum(e)}`);
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </InputContainer>
                    <ButtonContainer lg="2">
                        <FilterButton type="submit" disabled={false} loading={false}>
                            <FaFilter /> Filtrar
                        </FilterButton>
                        <FilterButton
                            type="reset"
                            onClick={() => {
                                setDateRange([null, null])
                                reset({
                                    idEmpresa: null,
                                    placa: "",
                                    renavam: "",
                                    chassi: "",
                                    idConsulta: null,
                                    idRecibo: null,
                                    tipoConsulta: null,
                                    cobranca: null,
                                });
                            }}
                            disabled={false}
                            loading={false}
                            outline={true}
                        >
                            Limpar
                        </FilterButton>
                    </ButtonContainer>
                    <InputContainer lg="1"></InputContainer>
                    <Col lg="2">
                        <MaisFiltros
                            cancelarOnClick={() => {
                                handleNaoUsarMaisFiltros<IFiltros>({
                                    excluir: ["renavam", "idEmpresa", "chassi", "tipoConsulta", "idConsulta", "cobranca"],
                                    resetCallback: reset,
                                });
                            }}
                            handleClickAplicarFiltros={handleAplicarMaisFiltros}
                            limparTudoOnClick={() => {
                                handleNaoUsarMaisFiltros<IFiltros>({
                                    excluir: ["renavam", "idEmpresa", "chassi", "tipoConsulta", "idConsulta", "cobranca"],
                                    resetCallback: reset,
                                });
                            }}
                        >
                            <Form.Group>
                                <Form.Label>Chassi</Form.Label>
                                <Controller
                                    control={control}
                                    name="chassi"
                                    render={({ field: { onChange, value } }) => (
                                        <CustomInput
                                            type="text"
                                            value={value ?? ""}
                                            placeholder="Busque por chassi"
                                            onChange={(e: any) => {
                                                onChange(e);
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Renavam</Form.Label>
                                <Controller
                                    control={control}
                                    name="renavam"
                                    render={({ field: { onChange, value } }) => (
                                        <CustomInput
                                            type="text"
                                            value={value ?? ""}
                                            placeholder="Busque por renavam"
                                            onChange={(e: any) => {
                                                onChange(e);
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="tipoConsulta"
                                    render={({ field: { onChange, value } }) => (
                                        <DropdownSelect
                                            options={combosConsulta}
                                            title="Tipo de consulta"
                                            searchable
                                            selectedValue={value}
                                            onSelect={onChange}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>ID Consulta</Form.Label>
                                <Controller
                                    control={control}
                                    name="idConsulta"
                                    render={({ field: { onChange, value } }) => (
                                        <CustomInput
                                            type="text"
                                            placeholder="Busque por ID"
                                            value={value ?? ""}
                                            onChange={(e: any) => {
                                                onChange(`${mInputNum(e)}`);
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                            {(usuarioLW || (agrupamentoOptions && agrupamentoOptions.length)) ? (
                                <Form.Group>
                                    <Controller
                                        control={control}
                                        name="idEmpresa"
                                        render={({ field: { onChange, value } }) => (
                                            <DropdownSelect
                                                options={agrupamentoOptions}
                                                title="Empresa"
                                                searchable={true}
                                                selectedValue={value}
                                                onSelect={onChange}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            ) : null}
                            {usuarioLW && (
                                <Form.Group>
                                    <Controller
                                        control={control}
                                        name="cobranca"
                                        render={({ field: { onChange, value } }) => (
                                            <DropdownSelect
                                                options={[
                                                    { label: "Cobrado do cliente", value: "SIM" },
                                                    { label: "Isento de cobrança", value: "NAO" },
                                                ]}
                                                title="Cobrança"
                                                searchable
                                                selectedValue={value}
                                                onSelect={onChange}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            )}
                        </MaisFiltros>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

