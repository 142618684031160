import { ModalCustom } from "components/ModalCustom";
import { BodySP } from "./containers/BodySP";

interface Props {
    showModal: boolean;
    handleCloseModal: () => void;
    submitForm: (dados: any) => void;
    uf: "SP";
}

export function ModalEmitirLicenciamentos({
    showModal = false,
    handleCloseModal,
    submitForm,
    uf,
}: Props) {

    const bodyStrategy: { [key: string]: React.JSX.Element } = {
        SP: (<BodySP
            submitForm={submitForm}
        />),
    };

    return (
        <ModalCustom
            show={showModal}
            title="Emitir licenciamento"
            centered
            size="lg"
            handleClose={handleCloseModal}
        >
            {bodyStrategy[uf]}
        </ModalCustom>
    );
}

