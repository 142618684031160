import { StatusEmissaoLicenciamentoEnum } from "features/licenciamento/emissao/pages/Detalhes/containers/Enum/StatusEmissaoLicenciamento";
import styled from "styled-components";

interface PropsContainer {
  statusEmissao: StatusEmissaoLicenciamentoEnum;
}

const colorStrategy = (theme: any) => {
  return {
    0: {
      backgroundColor: theme.colors.tags.disabled.bgColor,
      border: theme.colors.tags.disabled.textColor,
      color: theme.colors.tags.disabled.textColor,
    },
    1: {
      backgroundColor: theme.colors.tags.success.bgColor,
      border: theme.colors.tags.success.textColor,
      color: theme.colors.tags.success.textColor,
    },
    2: {
      backgroundColor: theme.colors.tags.danger.bgColor,
      border: theme.colors.tags.danger.textColor,
      color: theme.colors.tags.danger.textColor,
    },
    3: {
      backgroundColor: theme.colors.tags.warning02.bgColor,
      border: theme.colors.tags.warning02.textColor,
      color: theme.colors.tags.warning02.textColor,
    },
    4: {
      backgroundColor: theme.colors.primary.bgColor,
      border: theme.colors.primary.main,
      color: theme.colors.primary.main,
    },
  };
};

export const Container = styled.button<PropsContainer>`
  border-radius: 15px;
  width: 150px;
  height: 24px;
  text-align: center;
  background-color: ${({ statusEmissao, theme }) => colorStrategy(theme)[statusEmissao].backgroundColor};
  border: solid 1px ${({ statusEmissao, theme }) => colorStrategy(theme)[statusEmissao].border};
  color: ${({ statusEmissao, theme }) => colorStrategy(theme)[statusEmissao].color};

  &:hover {
    cursor: default;
  }
`;
