import styled from "styled-components";

interface DropwdownMenuContainerProps {
	visible: boolean;
}

export const DropdownMenuContainer = styled.div<DropwdownMenuContainerProps>`
	height: auto;
	display: ${({ visible }) => (visible ? "grid" : "none")} !important;
	background-color: ${({ theme }) => theme.colors.neutral.neutral10};
	position: absolute;
	top: 105%;
	border-radius: 0.5rem;
	width: calc(100% + 10px);
	z-index: 100;
	-webkit-box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
`;
