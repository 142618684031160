import { LoadingCirculo } from "components/LoadingCirculo";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { useDebitosDetalhes } from "features/debito/consulta/hooks/useDetalhesDebitos";
import { useEffect, useState } from "react";
import { DebitoIpva } from "../DebitoIpva";
import { DebitoLicenciamento } from "../DebitoLicenciamento";
import {
  AtencaoContainer,
  DadosErrosBox,
  DadosErrosText,
  DadosErrosTitle,
  LoadingContainer,
} from "./style";

interface MensagemDebitosProps {
  idDebito: number;
  tipoDebito: any;
  exercicio?: number;
  temMulta?: number;
  descricao?: string;
}

type TBodyDetalhes = "ipva" | "errosSemMulta" | "licenciamento" | null;

export function MensagemDebitos({
  idDebito,
  tipoDebito,
  exercicio,
  temMulta,
  descricao,
}: MensagemDebitosProps) {
  const { buscarDebitosDetalhes, debitosDetalhes, error, loading } =
    useDebitosDetalhes();
  const [body, setBody] = useState<TBodyDetalhes>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await buscarDebitosDetalhes(idDebito);

        if (TipoDebitos[tipoDebito] === "IPVA") {
          setBody("ipva");
        }
        if (error?.hasErro && !temMulta) {
          setBody("errosSemMulta");
        }
        if (TipoDebitos[tipoDebito] === "Licenciamento" && !error?.hasErro) {
          setBody("licenciamento");
        }
      } catch (error) {
        setBody(null);
      }
    }

    fetchData();
  }, [buscarDebitosDetalhes, idDebito, error?.hasErro, temMulta, tipoDebito]);

  function renderBody(body: TBodyDetalhes) {
    if (!body) {
      return <div></div>;
    }

    if (body === "ipva") {
      return <DebitoIpva exercicio={exercicio} />;
    }

    if (body === "licenciamento") {
      return (
        <DebitoLicenciamento
          licenciamentos={debitosDetalhes}
          temMulta={temMulta}
        />
      );
    }

    return (
      <AtencaoContainer>
        <DadosErrosBox>
          <DadosErrosTitle>Atenção</DadosErrosTitle>
          <DadosErrosText>{descricao}</DadosErrosText>
        </DadosErrosBox>
      </AtencaoContainer>
    );
  }

  return (
    <>
      {loading ? (
        <LoadingContainer>
          <LoadingCirculo mostrar={loading} />
        </LoadingContainer>
      ) : (
        renderBody(body)
      )}
    </>
  );
}

