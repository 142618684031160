import { useCallback } from "react";
import { TableRelatorioPagamentosRecusados } from "./TableRelatorioPagamentosRecusados";
import { utils, writeFileXLSX } from "xlsx";
import { converterDataHoraBr, formataDataISOParaBR } from "_utils/Data";
import { Container } from "components/Container";
import { IRelatorioPagamentosRecusados } from "features/relatorios/recusados/interfaces/RelatorioPagamentosRecusado";
import { formatarDinheiro } from "_utils/Money";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { toastErro } from "components/Toast";
import { useRelatorioPagamentosRecusados } from "features/relatorios/recusados/hooks/useRelatorioPagamentosRecusados";

export interface IListarPagamentosRecusados {
  dados: IRelatorioPagamentosRecusados[];
  loading: boolean;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (limit: number) => void;
  totalRows: number;
  rowsPerPage: number;
}

export function ListarPagamentosRecusados({
  dados,
  loading,
  onChangePage,
  onChangeRowsPerPage,
  totalRows,
  rowsPerPage,
}: IListarPagamentosRecusados) {
  const { buscarDadosPlanilha } = useRelatorioPagamentosRecusados();

  const handleExport = useCallback(async () => {
    const { data, hasErro } = await buscarDadosPlanilha();

    if (hasErro) {
      toastErro(data.message);
      return;
    }

    const dadosPlanilha = data.map((item: IRelatorioPagamentosRecusados) => ({
      "ID Solicitação Pagamento": item.idSolicitacaoPagamento,
      Placa: item.placa.toUpperCase(),
      Renavam: item.renavam,
      Chassi: item.chassi,
      UF: item.uf.toUpperCase(),
      Débito: TipoDebitos[Number(item.idTipoDebito)],
      Vencimento: formataDataISOParaBR(item.vencimento),
      Valor: item.valor / 100,
      "Status Solicitação Pagamento": "Recusado",
      "Data Recusa": item.dataRecusa
        ? converterDataHoraBr(new Date(item.dataRecusa))
        : "--",
      Motivo: item.motivoRecusa,
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 25 },
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 5 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 25 },
      { wch: 15 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(
      workBook,
      planilha,
      "Relatório Pagamentos Recusados"
    );
    writeFileXLSX(workBook, "relatórios-pagamentos-recusados.xlsx");
  }, [buscarDadosPlanilha]);

  return (
    <Container titulo="Pagamentos Recusados">
      <TableRelatorioPagamentosRecusados
        totalRows={totalRows}
        handleExport={handleExport}
        dados={dados}
        loading={loading}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
      />
    </Container>
  );
}
