import { useState } from "react";
import { Container, InputContainer } from "./style";

interface Props {
  handleChange: (body: "LOTE" | "INDIVIDUAL") => void;
}

export function HeaderModal({ handleChange }: Readonly<Props>) {
  const [selecionado, setSelecionado] = useState<"LOTE" | "INDIVIDUAL">("INDIVIDUAL");

  function handleCheckboxSelected(event: any) {
    handleChange(event.target.value);
    setSelecionado(event.target.value);
  }

  return (
    <Container>
      <InputContainer>
        <input
          onChange={handleCheckboxSelected}
          id="individual"
          value="INDIVIDUAL"
          name="individual"
          type="radio"
          checked={selecionado === "INDIVIDUAL"}
        />
        <label htmlFor="individual">Consulta unitária</label>
      </InputContainer>
      <InputContainer>
        <input
          onChange={handleCheckboxSelected}
          id="lote"
          value="LOTE"
          name="lote"
          type="radio"
          checked={selecionado === "LOTE"}
        />
        <label htmlFor="lote">Consulta em lote</label>
      </InputContainer>
    </Container>
  );
}

