import { ResumoContainer } from "features/relatorios/pagamento/pages/detalhes/containers/Resumo/style";
import { Row } from "react-bootstrap";
import { converterDataHoraBr } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";

interface Props {
  dados: any;
}

export function ResumoDetalhesConsulta({ dados }: Props) {
  return (
    <Row >
      <ResumoContainer  style={{ display: 'flex'}} >
        <ResumoContainer  style={{ width: '50%'}}>
          <p>ID Consulta: {dados ? dados.consultaId : "- -"}</p>
          <p>Data/Hora Consulta: {dados && converterDataHoraBr(dados.dataHora)}</p>
          <p>Empresa: {dados ? dados.empresa : "- -"}</p>
          <p>Usuário: {dados ? dados.nomeUsuario : "- -"}</p>
        </ResumoContainer>
        <ResumoContainer  style={{ width: '50%'}}>
          <p>Placas consultadas: {dados ? dados.qtdPlacasConsultadas : "- -"}</p>
          <p>Débitos disponíveis: {dados ? dados.qtdDebitosDisponiveis : "- -"}</p>
          <p>Pagamentos solicitados: {dados ? dados.qtdPlacasSolicitadoPagamento : "- -"}</p>
          <p>Valor total: {dados ? formatarDinheiro(dados.valorTotal / 100) : "- -" }</p>
        </ResumoContainer>
      </ResumoContainer>
    </Row>
  );
}