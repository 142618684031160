import { useCallback, useState } from "react";
import { IFiltrosDetalhesEmissao } from "../containers/interfaces/IFiltrosDetalhesEmissao";
import { IDetalhesEmissao } from "../containers/interfaces/IDetalhesEmissao";
import { IDetalhesEmissaoExportar } from "../containers/interfaces/IDetalhesEmissaoExportar";
import { getToken } from "_services/auth";
import { useRequest } from "hooks/useRequest";
import { Paginar } from "_utils/enuns/Paginar.enum";
import { IEmissaoManual } from "../containers/interfaces/IEmissaoManual";
import { converterDataHoraEua } from "_utils/Data";

export function useDetalheEmissao() {
  const { get, loading, post } = useRequest("consulta");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [dados, setDados] = useState<IDetalhesEmissao[]>([]);
  const [filtroDetalhesEmissao, setFiltroDetalhesEmissao] = useState<IFiltrosDetalhesEmissao>(
    {} as IFiltrosDetalhesEmissao
  );
  const [dadosExportar, setDadosExportar] = useState<IDetalhesEmissaoExportar[]>([]);
  const [showModalConfirmarEmissaoManual, setShowModalConfirmarEmissaoManual] = useState<boolean>(false);
  const [anoSolicitado, setAnoSolicitado] = useState<number>(0);
  const [exibirModalEmissaoManual, setExibirModalEmissaoManual] = useState<boolean>(false);

  function handlePageChange(page: number) {
    setPage(page);
  }

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }

  const buscarDetalhes = useCallback(
    async (idEmissao: any) => {
      const descontoComecaEmZero = 1;
      const ajuste = [
        filtroDetalhesEmissao?.placa ? `placa=${filtroDetalhesEmissao.placa}` : null,
        filtroDetalhesEmissao?.renavam ? `renavam=${filtroDetalhesEmissao.renavam}` : null,
        filtroDetalhesEmissao?.exercicio ? `exercicio=${filtroDetalhesEmissao.exercicio}` : null,
        filtroDetalhesEmissao?.statusEmissao
          ? `status=${Number(filtroDetalhesEmissao.statusEmissao) - descontoComecaEmZero}`
          : null,
      ];

      const queryParam = ajuste.filter((item) => item !== null).join("&");

      const { hasErro, data } = await get(
        `/licenciamento/consultas/${idEmissao}?${queryParam}&page=${page}&limit=${limit}`,
        {},
        false,
      );
      if (hasErro) {
        setDados([]);
        setAnoSolicitado(0)
        return;
      }
      setDados(data.items);
      setAnoSolicitado(data.items[0].exercicioSolicitado)
      setTotalRows(data.meta.totalItems);
    },
    [filtroDetalhesEmissao, limit, page, get]
  );

  async function downloadCrlv(
    idEmissao: string,
    idsLicenciamentos: number[] = [],
    padraoRenomear: string = "{placa}_{ano}_CL"
  ) {
    const ajuste = [
      filtroDetalhesEmissao?.placa ? `placa=${filtroDetalhesEmissao.placa}` : null,
      filtroDetalhesEmissao?.renavam ? `renavam=${filtroDetalhesEmissao.renavam}` : null,
      filtroDetalhesEmissao?.exercicio ? `exercicio=${filtroDetalhesEmissao.exercicio}` : null,
      filtroDetalhesEmissao?.statusEmissao ? `status=${filtroDetalhesEmissao.statusEmissao}` : null,
      idsLicenciamentos.length > 0 ? `idsLicenciamentos=${idsLicenciamentos.join(",")}` : null,
    ];

    const queryParam = ajuste.filter((item) => item !== null).join("&");
    const token = getToken();
    const msConsulta = process.env.REACT_APP_BASE_URL_MS_CONSULTA;
    const urlDownload = `${msConsulta}/licenciamento/consultas/${idEmissao}/download?${queryParam}&padrao=${padraoRenomear}&authorization=${encodeURIComponent(
      `Bearer ${token}`
    )}`;

    const { hasErro } = await get(urlDownload);
    if (!hasErro) {
      window.open(urlDownload);
    }
  }

  async function exportarCrlv(idEmissao: any, idsLicenciamentos: any[] = []) {
    const ajuste = [
      filtroDetalhesEmissao?.placa ? `placa=${filtroDetalhesEmissao.placa}` : null,
      filtroDetalhesEmissao?.renavam ? `renavam=${filtroDetalhesEmissao.renavam}` : null,
      filtroDetalhesEmissao?.exercicio ? `exercicio=${filtroDetalhesEmissao.exercicio}` : null,
      filtroDetalhesEmissao?.statusEmissao ? `status=${filtroDetalhesEmissao.statusEmissao}` : null,
      idsLicenciamentos.length > 0 ? `idsLicenciamentos=${idsLicenciamentos.join(",")}` : null,
    ];

    const queryParam = ajuste.filter((item) => item !== null).join("&");
    const { hasErro, data } = await get(`/licenciamento/consultas/${idEmissao}?${queryParam}&paginate=${Paginar.NAO}`, {}, false);

    if (hasErro) {
      return;
    }

    setDadosExportar(data);
  }

  async function reemitirCrlvs(id: number) {
    return await post(`licenciamento/consultas/${id}/reconsulta`, {}, {}, false);
  }

  async function emissaoManual(idLicenciamento: number, dados: IEmissaoManual, idEmissao: number | string) {
    setExibirModalEmissaoManual(false);
    const { hasErro, data } = await post(`/licenciamento/consultas/manual/${idLicenciamento}`,
      {
        ...dados,
        dataEmissao: converterDataHoraEua(dados.dataEmissao),
        extensao: dados.arquivo?.name.split(".")[1]
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

    if (hasErro) {
      return;
    }

    buscarDetalhes(idEmissao);
    setShowModalConfirmarEmissaoManual(true);
  }

  return {
    buscarDetalhes,
    handlePageChange,
    handleLimitChange,
    page,
    limit,
    totalRows,
    loading,
    filtroDetalhesEmissao,
    setFiltroDetalhesEmissao,
    dados,
    downloadCrlv,
    exportarCrlv,
    dadosExportar,
    reemitirCrlvs,
    emissaoManual,
    showModalConfirmarEmissaoManual,
    setShowModalConfirmarEmissaoManual,
    exibirModalEmissaoManual,
    setExibirModalEmissaoManual,
    anoSolicitado
  };
}


