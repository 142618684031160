import { useCallback, useState } from "react";
import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { converterDataEua } from "_utils/Data";
import { HttpStatusCode } from "axios";

export function useRelatorioPagamentosRecusados() {
  const [dados, setDados] = useState<any[]>([]);
  const [loadingDados, setLoadingDados] = useState<boolean>(false);
  const [loadingDadosPlanilha, setLoadingDadosPlanilha] =
    useState<boolean>(false);
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [queryParams, setQueryParams] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);
  const [totalRows, setTotalRows] = useState<number>(0);

  function criarQueryParamsRelatorio(data: any) {
    const placa = data.placa ? `placa=${data.placa.toLowerCase()}` : null;
    const tipoDebito = data.tipoDebito ? `tipoDebito=${data.tipoDebito}` : null;
    const vencimento = data.vencimento ? `vencimento=${data.vencimento}` : null;
    const dataRecusa = data.dataRecusa
      ? `dataRecusa=${converterDataEua(data.dataRecusa)}`
      : null;
    const renavam = data.renavam ? `renavam=${data.renavam.toLowerCase()}` : null;


    const params = [placa, tipoDebito, vencimento, dataRecusa, renavam]
      .filter((item) => item !== null)
      .join("&");
    setQueryParams(params);
  }

  const buscarDados = useCallback(async () => {
    const queryParamsUrl = window.location.href.split("?")[1];
    setLoadingDados(true);
    try {
      const { status, data } = await api.get(
        `/debito/pagamento/recusados/?${queryParamsUrl || ""}&${queryParams || ""
        }&page=${page}&limit=${limit}`
      );
      setDados(data.items);
      setTotalRows(data.meta.totalItems);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      setDados([]);
      return response;
    } finally {
      setLoadingDados(false);
    }
  }, [limit, page, queryParams]);

  async function buscarDadosPlanilha() {
    setLoadingDadosPlanilha(true);
    try {
      const { status, data } = await api.get(
        `/debito/pagamento/recusados/?${queryParams}&paginate=0`
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoadingDadosPlanilha(false);
    }
  }

  return {
    buscarDados,
    dados,
    error,
    loadingDados,
    criarQueryParamsRelatorio,
    setPage,
    page,
    setLimit,
    limit,
    queryParams,
    buscarDadosPlanilha,
    loadingDadosPlanilha,
    totalRows,
  };
}
