import { FaDownload, FaEye, FaInfoCircle } from "react-icons/fa";
import { ButtonIcon, Container, IconeLink } from "./style";
import { StatusExtratoVeicular } from "../StatusConsultaExtratoVeicular/style";
import { useDetalhesExtratoVeicular } from "../../hooks/useDetalhesExtratoVeicular";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfExtratoVeicular } from "../../../ExtratoVeicularDetalhado/containers/PdfExtratoVeicular";
import { LoadingCirculo } from "components/LoadingCirculo";
import { LoadingScreen } from "containers/Page/style";
import { useState } from "react";
import { ModalResumoLote } from "./containers/ModalResumoLote";

interface Props {
  idLoteExtratoVeicular: number;
  idExtratoVeicular: number;
  statusExtratoVeicular: string;
  resumoLote?: boolean;
}

export function AcoesExtratoVeicular({
  idExtratoVeicular,
  statusExtratoVeicular,
  idLoteExtratoVeicular,
  resumoLote,
}: Readonly<Props>) {
  const [showModalResumoLote, setShowModalResumoLote] = useState(false);
  const {
    handleExportarPdfExtratoVeicular,
    renderPdf,
    dadosExtratoVeicular,
    loadingDownloadPdf,
  } = useDetalhesExtratoVeicular();

  const statusNaoDesativarDetalhes: string[] = [
    StatusExtratoVeicular.CONSULTANDO,
    StatusExtratoVeicular.FALHA,
    StatusExtratoVeicular.DADOS_INCONSISTENTES,
  ];

  return (
    <Container>
      {resumoLote && (
        <ButtonIcon onClick={() => setShowModalResumoLote(true)}>
          <FaInfoCircle />
        </ButtonIcon>
      )}
      <ButtonIcon
        title="Exportar"
        disabled={statusNaoDesativarDetalhes.includes(
          `${statusExtratoVeicular}`
        )}
        onClick={() =>
          handleExportarPdfExtratoVeicular(
            idExtratoVeicular,
            idLoteExtratoVeicular
          )
        }
      >
        <FaDownload />
      </ButtonIcon>
      {renderPdf && (
        <PDFDownloadLink
          style={{ display: "none" }}
          id={`downloadPdf${idExtratoVeicular}`}
          document={<PdfExtratoVeicular dadosPDF={dadosExtratoVeicular} />}
          fileName={`Extrato Veicular - ${
            dadosExtratoVeicular.dadosVeiculo[0].placa ?? "-"
          }`}
        />
      )}
      {loadingDownloadPdf && (
        <LoadingScreen>
          <LoadingCirculo mostrar={loadingDownloadPdf} />
        </LoadingScreen>
      )}
      {statusNaoDesativarDetalhes.includes(`${statusExtratoVeicular}`) ? (
        <ButtonIcon disabled={true}>
          <FaEye />
        </ButtonIcon>
      ) : (
        <IconeLink
          title="Visualizar"
          to={`/veiculos/extrato/consultas/${idLoteExtratoVeicular}/detalhes/${idExtratoVeicular}`}
        >
          <FaEye />
        </IconeLink>
      )}
      <ModalResumoLote
        idLoteExtratoVeicular={idLoteExtratoVeicular}
        show={showModalResumoLote}
        handleClose={() => setShowModalResumoLote(false)}
      />
    </Container>
  );
}
