import { Container } from "./style";

interface Props {
  statusConsulta: string;
}

export function StatusConsultaExtratoVeicular({ statusConsulta }: Readonly<Props>) {
  return (
    <Container statusConsulta={statusConsulta}>
      <span>{statusConsulta}</span>
    </Container>
  );
}

