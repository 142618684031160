export const tipoDebitos = [
  {
    value: "IPVA",
    label: "IPVA",
    checked: false,
  },
  {
    value: "LICENCIAMENTO",
    label: "Licenciamento",
    checked: false,
  },
];
