import * as xlsx from 'xlsx';

export function lerPlanilha(arquivo: File) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = (event) => {
            const arrayBuffer = event.target?.result as ArrayBuffer;
            const workbook = xlsx.read(new Uint8Array(arrayBuffer));
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            resolve(xlsx.utils.sheet_to_json(worksheet));
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsArrayBuffer(arquivo);
    });
}