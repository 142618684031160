import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { StyledCol, StyledButton } from "../BodyMultas/style";
import { IDetalhesMultas } from "../..";
import { DetalhesMultasContainer, StyledRow } from "./style";
import { Stronger } from "components/Stronger";
import { intlCurrencyFormat } from "_utils/Money";
import { converterDataBr } from "_utils/Data";

interface Props {
  dados: IDetalhesMultas;
}

export function ToggleMultas({ dados }: Readonly<Props>) {
  const [showDetalhes, setShowDetalhes] = useState(false);

  function handleToggleDetalhes() {
    setShowDetalhes((prev) => !prev);
  }
  return (
    <>
      <Row style={{ cursor: "pointer" }} key={dados.autoInfracao} onClick={handleToggleDetalhes}>
        <StyledCol lg="3" md="3" sm="3">
          <StyledButton type="button">{showDetalhes ? <FaMinusCircle color="green" /> : <FaPlusCircle  color="green" />}</StyledButton>
          {dados.autoInfracao}
        </StyledCol>
        <StyledCol lg="6" md="6" sm="6">
          <div>{dados.descricaoArtigo}</div>
        </StyledCol>
        <StyledCol style={{ justifyContent: "center" }} lg="1" md="1" sm="1">
          {dados.dataInfracao}
        </StyledCol>
        <StyledCol style={{ justifyContent: "end" }} lg="2" md="2" sm="2">
          {dados.valorComDesconto
            ? (dados.valorComDesconto / 100).toFixed(2).toLocaleString()
            : ((dados.valor + dados.valorJuro) / 100).toFixed(2).toLocaleString()}
        </StyledCol>
      </Row>
      <hr style={{ margin: 0 }} />
      {showDetalhes && (
        <DetalhesMultasContainer>
          <StyledRow>
            <Col>
              <span>Auto de infração</span> <br />
              <Stronger>{dados.autoInfracao}</Stronger>
            </Col>
            <Col>
              <span>Data/hora da infração</span> <br />
              <Stronger>
                {dados.dataInfracao} {dados.horaInfracao}
              </Stronger>
            </Col>
            <Col>
              <span>Vencimento auto</span> <br />
              <Stronger>{converterDataBr(new Date(dados.dataVencimento))}</Stronger>
            </Col>
            <Col>
              <span>Situação</span> <br />
              <Stronger>{dados.situacao}</Stronger>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <span>Descrição</span> <br />
              <Stronger>{dados.descricaoArtigo}</Stronger>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <span>Local</span> <br />
              <Stronger>{dados.endereco}</Stronger>
            </Col>
            <Col>
              <span>Órgão competente</span> <br />
              <Stronger>{dados.orgao}</Stronger>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <span>Valor original</span> <br />
              <Stronger>{intlCurrencyFormat(dados.valor / 100)}</Stronger>
            </Col>
            <Col>
              <span>Valor desconto</span> <br />
              <Stronger>
                {intlCurrencyFormat(dados.valorComDesconto ? (dados.valor - dados.valorComDesconto) / 100 : 0) ?? "-"}
              </Stronger>
            </Col>
            <Col>
              <span>Valor juros</span> <br />
              <Stronger>{intlCurrencyFormat(dados.valorJuro / 100) ?? "-"}</Stronger>
            </Col>
            <Col>
              <span>Total</span> <br />
              <Stronger>
                {intlCurrencyFormat(
                  dados.valorComDesconto ? dados.valorComDesconto / 100 : (dados.valor + dados.valorJuro) / 100
                )}
              </Stronger>
            </Col>
          </StyledRow>
        </DetalhesMultasContainer>
      )}
    </>
  );
}

