import { CustomInput } from "components/CustomInput";
import { Header } from "components/Header";
import { LoadingCirculo } from "components/LoadingCirculo";
import { ModalCustom } from "components/ModalCustom";
import { Colum } from "components/Table";
import { toastErro } from "components/Toast";
import { Table } from "containers/Table";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaCheck, FaFilter, FaKey } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "_services/api";
import { verificarPermissao } from "_services/auth";
import { useListarUsuarios } from "../../hooks/useListarUsuarios";
import { IUsuarioKeycloak } from "../../interfaces/UsuarioKeycloak.interface";
import {
  Button,
  ButtonFiltro,
  Container,
  ContainerCentralizado,
  DivBackGround,
  Linha,
  SubContainer,
  TextoAzul,
  WrapperButtonModal,
  WrapperFiltro,
} from "./style";

export function Listar() {
  const navigate = useNavigate();

  useEffect(() => {
    const permissao = verificarPermissao("administrador");

    if (!permissao) {
      return navigate("/401");
    }
  }, []);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalSucessoOpen, setModalSucessoOpen] = useState<boolean>(false);
  const [usuarioSelecionado, setUsuarioSelecionado] =
    useState<IUsuarioKeycloak>({} as IUsuarioKeycloak);
  const {
    loading,
    usuarios,
    handleFormSubmit,
    setFiltro,
    handlePageChange,
    handleRowsPerPageChange,
    totalRows,
  } = useListarUsuarios();
  const [inputFiltro, setInputFiltro] = useState("");
  const [loadingConfirmar, setLoadingConfirmar] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitted, errors },
  } = useForm();

  const handleModalState = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalSucessoState = () => {
    setModalSucessoOpen(!modalSucessoOpen);
  };

  const handleLimparFiltro = () => {
    setInputFiltro("");
    reset({
      filtro: "",
    });
  };

  const handleEnviarEmail = async () => {
    setLoadingConfirmar(true);
    try {
      const { email } = usuarioSelecionado;

      await api.get(`/usuario/email/${email}/acesso`);
      setLoadingConfirmar(false);
      handleModalState();
      handleModalSucessoState();
    } catch (e: any) {
      toastErro(e.message);
      setLoadingConfirmar(false);
    }
  };

  const columns: Colum[] = [
    { name: "UUID", selector: (row: IUsuarioKeycloak) => row.id },
    { name: "Nome", selector: (row: IUsuarioKeycloak) => row.firstName },
    { name: "E-mail", selector: (row: IUsuarioKeycloak) => row.email },
    {
      name: "Senha",
      selector: (row: IUsuarioKeycloak) => (
        <TextoAzul
          onClick={() => {
            setUsuarioSelecionado(row);
            handleModalState();
          }}
        >
          Enviar e-mail <FaKey />
        </TextoAzul>
      ),
    },
  ];

  return (
    <>
      <Header lastPage="Cadastro" title="Usuários" />
      <Container>
        <SubContainer>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <p className="titulo">Filtros</p>
            <Linha />
            <label>Nome ou E-mail</label>
            <WrapperFiltro>
              <CustomInput
                {...register("filtro")}
                value={inputFiltro}
                onChange={(e: any) => setInputFiltro(e.target.value)}
              />
              <ButtonFiltro type="submit">
                <FaFilter size={12} />
                Filtrar
              </ButtonFiltro>
              <Button outline={true} variante="primary" onClick={handleLimparFiltro}>
                Limpar
              </Button>
            </WrapperFiltro>
          </Form>
        </SubContainer>
        <SubContainer>
          <p className="titulo">Listagem - Usuários</p>
          <Linha />
          <Table
            columns={columns}
            loading={loading}
            data={usuarios}
            keyField={"id"}
            pagination={true}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            totalRows={totalRows}
          />
        </SubContainer>
      </Container>
      <ModalCustom
        title="Definição de Senha"
        show={modalOpen}
        handleClose={handleModalState}
        centered={true}
        size="lg"
      >
        <ContainerCentralizado>
          {loadingConfirmar ? (
            <>
              <br />
              <br />
              <LoadingCirculo mostrar={loadingConfirmar} />
              <br />
              <br />
            </>
          ) : (
            <>
              <p>
                Deseja confirmar o envio de e-mail para definição de senha do
                usuário abaixo?
              </p>
              <p>
                <strong>Usuário: </strong>
                {usuarioSelecionado.firstName}
              </p>
              <WrapperButtonModal>
                <Button outline={true} variante="primary" onClick={handleModalState}>
                  Cancelar
                </Button>
                <Button onClick={handleEnviarEmail}>Confirmar</Button>
              </WrapperButtonModal>
            </>
          )}
        </ContainerCentralizado>
      </ModalCustom>
      <ModalCustom
        title="Definição de Senha"
        show={modalSucessoOpen}
        handleClose={handleModalSucessoState}
        centered={true}
      >
        <ContainerCentralizado>
          <DivBackGround>
            <FaCheck />
          </DivBackGround>
          <p>E-mail enviado com sucesso!</p>
        </ContainerCentralizado>
      </ModalCustom>
    </>
  );
}
