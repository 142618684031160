import styled from "styled-components";

interface LinkProps {
  disabled?: boolean
}

export const Container = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const StyledLink = styled.a<LinkProps>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: ${({ disabled, theme }) => disabled ? theme.colors.neutral.neutral50 : theme.colors.primary.main};
    cursor: ${({ disabled }) => disabled && "default"};

    &:hover {
      color: ${({ disabled, theme }) => disabled && theme.colors.neutral.neutral50}
    }
`;

export const StyledIcon = styled.a<LinkProps>`
    width: 100%;
    display: flex;
    text-decoration: none;
    margin-left: 12px;
    color: ${({ disabled, theme }) => disabled ? theme.colors.neutral.neutral50 : theme.colors.neutral.neutral80};
    cursor: ${({ disabled }) => disabled && "default"};

    &:hover {
      color: ${({ disabled, theme }) => disabled ? theme.colors.neutral.neutral50 : theme.colors.neutral.neutral80}
    }
`;