import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.5rem;
  padding-bottom: 1rem;

  input {
    width: 1.25rem;
    height: 1.25rem;
    accent-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;

  input {
    accent-color: ${({ theme }) => theme.colors.project.main};
    &:hover {
      cursor: pointer;
    }
  }

  label {
    cursor: pointer;
  }
`;