import { yupResolver } from "@hookform/resolvers/yup";
import { FilterButton } from "components/Button/style";
import { Container } from "components/Container";
import { CustomDatePicker } from "components/DatePicker";
import { DropdownSelect } from "components/DropdownSelect";
import { filtrarPagamentos } from "features/debito/pagamento/validate/validator";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter } from "react-icons/fa";
import { statusPagamento } from "../../constants/statusPagamento";
import { statusPrazoPagamento } from "../../constants/statusPrazoPagamento";
import { tiposDebitos } from "../../constants/tiposDebitos";
import { IFiltrosPagamentos } from "./interfaces/IFiltrosPagamentos";
import { ButtonContainer, DatePickerContainer } from "./style";
import { Multiselect } from "components/Multiselect";
import { CustomInput } from "components/CustomInput";
import { useMaskInput } from "hooks/useMaskInput";
import { MaisFiltros } from "components/MaisFiltros";
import { useMaisFiltros } from "hooks/useMaisFiltros";

interface Props {
  getFiltro: (data: IFiltrosPagamentos) => void;
}

export function Filtros({ getFiltro }: Props) {
  const { handleAplicarMaisFiltros, handleFormSubmit, handleNaoUsarMaisFiltros } = useMaisFiltros();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = useForm<IFiltrosPagamentos>({
    resolver: yupResolver(filtrarPagamentos),
  });

  const { mInputPlaca } = useMaskInput();

  return (
    <Container titulo="Filtros">
      <Form
        noValidate
        onSubmit={handleSubmit((dados) => {
          handleFormSubmit({
            dados,
            resetCallback: reset,
            submitCallback: getFiltro,
            excluir: ["renavam", "statusPrazoPagamento"],
          });
        })}
      >
        <Row>
          <Col lg="2">
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Digite a placa"
                    value={value!}
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                    mensagemErro={errors.placa?.message}
                    isValid={isSubmitted && isValid && (value ? value.length >= 1 : false)}
                    isInvalid={!!errors.placa}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="statusPagamento"
                render={({ field: { onChange, value } }) => (
                  <Multiselect
                    options={statusPagamento}
                    onChange={onChange}
                    title="Status Pagamento"
                    value={value ?? []}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="idTipoDebito"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={tiposDebitos}
                    title="Tipo de Débito"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="prazoPagamento"
                render={({ field: { onChange, value } }) => (
                  <DatePickerContainer>
                    <span>Prazo para pagamento</span>
                    <CustomDatePicker selected={value!} onChange={onChange} />
                  </DatePickerContainer>
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <ButtonContainer>
              <FilterButton type="submit" disabled={false} loading={false}>
                <FaFilter /> Filtrar
              </FilterButton>
              <FilterButton
                onClick={() => {
                  reset({
                    idTipoDebito: null,
                    placa: "",
                    prazoPagamento: null,
                    renavam: null,
                    statusPagamento: [],
                    statusPrazoPagamento: null,
                  });
                }}
                disabled={false}
                loading={false}
                outline={true}
              >
                Limpar
              </FilterButton>
            </ButtonContainer>
          </Col>
          <Col lg="2">
            <MaisFiltros
              cancelarOnClick={() => {
                handleNaoUsarMaisFiltros<IFiltrosPagamentos>({
                  excluir: ["renavam", "statusPrazoPagamento"],
                  resetCallback: reset,
                });
              }}
              handleClickAplicarFiltros={handleAplicarMaisFiltros}
              limparTudoOnClick={() => {
                handleNaoUsarMaisFiltros<IFiltrosPagamentos>({
                  excluir: ["renavam", "statusPrazoPagamento"],
                  resetCallback: reset,
                });
              }}
            >
              <Form.Group>
                <Form.Label>Renavam</Form.Label>
                <Controller
                  control={control}
                  name="renavam"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      placeholder="Digite o renavam"
                      value={value ?? ""}
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                      mensagemErro={errors.renavam?.message}
                      isValid={isSubmitted && isValid && (value ? value.length >= 1 : false)}
                      isInvalid={!!errors.renavam}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Controller
                  control={control}
                  name="statusPrazoPagamento"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      options={statusPrazoPagamento}
                      title="Status Prazo de Pagamento"
                      selectedValue={value}
                      onSelect={onChange}
                    />
                  )}
                />
              </Form.Group>
            </MaisFiltros>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
