import { PrimaryButton } from "components/Button/style";
import { ButtonContainer } from "./style";
import { ElementType } from "react";

interface Props {
  onClick: () => void;
  text: string;
  iconeBotao: ElementType;
}

export function CompositeContainerActionButton({ onClick, text, iconeBotao: IconeBotao }: Props) {
  return (
    <ButtonContainer>
      <PrimaryButton onClick={onClick}>{text} <IconeBotao /></PrimaryButton>
    </ButtonContainer>
  );
}
