import { Container } from "./style";
import { LotePR } from "./containers/LotePR";
import { IndividualPR } from "./containers/IndividualPR";
import { useEmpresas } from "hooks/useEmpresas";

interface Props {
  checkedBody: string | null;
  handleCloseModal: () => void;
  listarSolicitacoesLicenciamento: () => Promise<void>;
  handleUploadArquivo: (file: BinaryType | File) => void;
  handleSubmit: (dados: any) => void;
  handleSubmitIndividual: (dados: any) => void;
  setDadosIndividual: any;
  pagina?: "consulta" | "emissao";
}

export function BodyPR({
  checkedBody,
  handleCloseModal,
  listarSolicitacoesLicenciamento,
  handleUploadArquivo,
  handleSubmit,
  handleSubmitIndividual,
  setDadosIndividual,
  pagina = "emissao",
}: Props) {
  const { empresasAgrupamento } = useEmpresas();

  function buscarNomeEmpresa(idCliente: NullableString) {
    return empresasAgrupamento.find((item) => item.idClienteAgrupamento === Number(idCliente))?.razaoSocial;
  }

  const bodyStrategy: { [key: string]: React.JSX.Element } = {
    lote: (
      <LotePR
        handleCloseModal={handleCloseModal}
        listarSolicitacoesLicenciamento={listarSolicitacoesLicenciamento}
        handleUploadArquivo={handleUploadArquivo}
        handleSubmit={handleSubmit}
        buscarNomeEmpresa={buscarNomeEmpresa}
        pagina={pagina}
      />
    ),
    individual: (
      <IndividualPR
        handleCloseModal={handleCloseModal}
        listarSolicitacoesLicenciamento={listarSolicitacoesLicenciamento}
        setDadosIndividual={setDadosIndividual}
        buscarNomeEmpresa={buscarNomeEmpresa}
        handleFormSubmit={handleSubmitIndividual}
        pagina={pagina}
      />
    ),
  };

  return (
    <Container>
      <span>
        Preencha as informações abaixo para realizar a emissão do licenciamento
      </span>
      {bodyStrategy[checkedBody!]}
    </Container>
  );
}

