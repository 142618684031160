import { ModalCustom } from "components/ModalCustom";
import { SelecionarUf } from "./containers/SelecionarUf";
import { BodyPR } from "./containers/BodyPR";
import { BodySP } from "./containers/BodySP";
import { useCallback, useContext, useEffect, useState } from "react";
import { HeaderModal } from "./containers/HeaderModal";
import { toastErro, toastSucesso } from "components/Toast";
import {
  IResponseSolicitarLicenciamento,
  useSolicitacaoLicenciamentoSP,
} from "./containers/BodySP/hooks/useSolicitacaoLicenciamentoSP";
import { IPlanilhaLicenciamento } from "./containers/BodySP/containers/LoteSP";
import { ModalConfirmarEmissao } from "./containers/ModalConfirmarEmissao";
import { EmitirLicenciamento, IFormSolicitarLicenciamento } from "./containers/BodySP/containers/IndividualSP";
import { ContextPermissao } from "hooks/ContextPermissao";
import { Cobrar } from "features/licenciamento/emissao/constants";
import { BodyMG } from "./containers/BodyMG";

type UFS = "MG" | "PR" | "SP";

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
  listarSolicitacoesLicenciamento: () => Promise<void>;
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>;
  pagina?: "emissao" | "consulta";
}

const worker = new Worker(new URL("../../../../_workers/LicenciamentoWorker.js", import.meta.url), {
  type: "module",
});

export function ModalEmitirLicenciamento({
  showModal = false,
  handleCloseModal,
  listarSolicitacoesLicenciamento,
  setLoadingPage,
  pagina = "emissao",
}: Readonly<Props>) {
  const [checkedBody, setCheckedBody] = useState<string | null>("lote");
  const [submit, setSubmit] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<UFS | null>(null);
  const [dadosPlanilha, setDadosPlanilha] = useState([]);
  const [showModalConfirmar, setShowModalConfirmar] = useState(false);
  const [descricao, setDescricao] = useState<string | null>(null);
  const [dadosIndividual, setDadosIndividual] = useState<IFormSolicitarLicenciamento | null>(null);
  const [dadosCobranca, setDadosCobranca] = useState<any>(null);
  const [exercicio, setExercicio] = useState<string>("");

  const { verificarEmpresa } = useContext(ContextPermissao);
  const { solicitarLicenciamento } = useSolicitacaoLicenciamentoSP();

  useEffect(() => {
    if (showModal) {
      setSelectedValue(null);
    }
  }, [showModal]);

  const handleStatesOnCloseModal = useCallback(() => {
    handleCloseModal();
    setCheckedBody("lote");
  }, [handleCloseModal]);

  const handleConfirmarEmissao = useCallback(async () => {
    let cobrar = Cobrar.SIM;
    setLoadingPage(true);
    if (dadosCobranca) {
      cobrar = dadosCobranca.cobrar;
    }

    if (dadosIndividual) {
      cobrar = dadosIndividual.cobrar;
    }

    const { id } = (await solicitarLicenciamento({
      exercicio: selectedValue == "SP" && !dadosPlanilha.length ? dadosIndividual!.exercicio : exercicio,
      descricao: descricao ?? dadosIndividual!.descricao,
      emitir: pagina === "emissao" ? EmitirLicenciamento.Sim : EmitirLicenciamento.Nao,
      plataforma: "DEBITOS",
      veiculos:
        dadosPlanilha.length > 0
          ? dadosPlanilha
          : [
            {
              chassi: dadosIndividual!.chassi,
              cpfCnpjProprietario:
                selectedValue !== "PR"
                  ? dadosIndividual!.cpfCnpjProprietario
                  : "",
              placa: dadosIndividual!.placa,
              renavam: dadosIndividual!.renavam,
              uf: selectedValue!,
              crv: selectedValue == "MG" ? dadosIndividual!.crv : null,
              ano: selectedValue == "MG" ? dadosIndividual!.exercicio : null,
            },
          ],
      cobrar,
      idCliente: dadosCobranca ? dadosCobranca.empresa : dadosIndividual?.empresa,
      nomeCliente:
        dadosCobranca && dadosCobranca.nomeEmpresa ? dadosCobranca.nomeEmpresa : dadosIndividual?.nomeEmpresa,
      motivoIsencao: dadosCobranca ? dadosCobranca.motivoIsencao : dadosIndividual?.motivoIsencao,
    })) as IResponseSolicitarLicenciamento;

    if (!id) {
      toastErro("Ocorreu um erro ao solicitar os licenciamentos");
      setShowModalConfirmar(false);
      setDescricao(null);
      setLoadingPage(false);
      return;
    }

    toastSucesso("Solicitações realizadas com sucesso");
    listarSolicitacoesLicenciamento();
    setShowModalConfirmar(false);
    setDescricao(null);
    setLoadingPage(false);
  }, [
    dadosPlanilha,
    descricao,
    listarSolicitacoesLicenciamento,
    solicitarLicenciamento,
    dadosIndividual,
    dadosCobranca,
    pagina,
    selectedValue,
    exercicio,
    setLoadingPage,
  ]);

  useEffect(() => {
    if (submit) {
      handleConfirmarEmissao();
      handleStatesOnCloseModal();
    }

    setSubmit(false);
  }, [submit, dadosIndividual, dadosPlanilha]);

  function handleChangeModalBody(selected: UFS) {
    worker.postMessage({
      type: "uf",
      uf: selected,
    });

    worker.onmessage = async ({ data }: MessageEvent<any>) => {
      const { type, message } = data;

      if (type === "ufInvalida") {
        handleStatesOnCloseModal();
        toastErro(message);
      }
    };

    setSelectedValue(selected);
    setDescricao(null);
    setDadosIndividual(null);
    setDadosCobranca(null);
    setDadosPlanilha([]);
  }

  function handleUploadArquivo(arquivo: BinaryType | File) {
    setLoadingPage(true);
    if (!arquivo) {
      toastErro("Insira um arquivo!");
      return;
    }

    worker.postMessage({
      type: "planilha",
      arquivo,
    });

    worker.onmessage = async ({ data }: MessageEvent<any>) => {
      const { type, dados, erros } = data;
      setLoadingPage(false);
      if (type === "dados") {
        setDadosPlanilha(dados);
        return;
      }

      if (
        type === "erros" &&
        erros[0].message ===
        "Planilha enviada de forma incorreta, utilize o modelo"
      ) {
        toastErro(erros[0].message);
        return;
      }

      if (type === "erros") {
        let todosErros: string[] = erros.map((element: any) => element.erros.map((element2: any) => element2)).flat();

        if(todosErros.length > 0) {
          todosErros = [...new Set(todosErros)];
        }

        todosErros.forEach(item => toastErro(item))
        
      }
    };
  }

  async function handleSubmit({
    descricao,
    cobrar,
    empresa,
    nomeEmpresa,
    motivoIsencao,
    exercicio,
  }: IPlanilhaLicenciamento) {
    if (!dadosPlanilha.length) {
      toastErro("Não foi possível processar a planilha ou ela foi enviada vazia");
      return;
    }
    setExercicio(exercicio);
    setDescricao(descricao);
    setDadosCobranca({
      cobrar,
      empresa,
      nomeEmpresa,
      motivoIsencao,
    });

    if (selectedValue !== "SP") {
      handleStatesOnCloseModal();
      return;
    }

    if (verificarEmpresa() && pagina === "emissao") {
      handleStatesOnCloseModal();
      setShowModalConfirmar(true);
      return;
    }

    setSubmit(true);
  }

  function handleCheckOption(event: React.ChangeEvent<HTMLInputElement>) {
    setCheckedBody(event.target.value);
  }

  const bodyStrategy: { [key: string]: React.JSX.Element } = {
    MG: (
      <BodyMG
        listarSolicitacoesLicenciamento={listarSolicitacoesLicenciamento}
        checkedBody={checkedBody}
        handleCloseModal={handleStatesOnCloseModal}
        handleUploadArquivo={handleUploadArquivo}
        handleSubmit={handleSubmit}
        handleSubmitIndividual={handleSubmitIndividual}
        setDadosIndividual={setDadosIndividual}
        pagina={pagina}
      />
    ),
    PR: (
      <BodyPR
        listarSolicitacoesLicenciamento={listarSolicitacoesLicenciamento}
        checkedBody={checkedBody}
        handleCloseModal={handleStatesOnCloseModal}
        handleUploadArquivo={handleUploadArquivo}
        handleSubmit={handleSubmit}
        handleSubmitIndividual={handleSubmitIndividual}
        setDadosIndividual={setDadosIndividual}
        pagina={pagina}
      />
    ),
    SP: (
      <BodySP
        listarSolicitacoesLicenciamento={listarSolicitacoesLicenciamento}
        checkedBody={checkedBody}
        handleCloseModal={handleStatesOnCloseModal}
        handleUploadArquivo={handleUploadArquivo}
        handleSubmit={handleSubmit}
        setDadosIndividual={setDadosIndividual}
        setShowModalConfirmar={setShowModalConfirmar}
        confirmarEmissao={handleConfirmarEmissao}
        pagina={pagina}
      />
    ),
  };

  async function handleSubmitIndividual({
    descricao,
    cobrar,
    empresa,
    nomeEmpresa,
    motivoIsencao,
    exercicio,
  }: IFormSolicitarLicenciamento) { 
    setExercicio(exercicio);
    setDescricao(descricao);
    setDadosCobranca({
      cobrar,
      empresa,
      nomeEmpresa,
      motivoIsencao,
    });

    handleStatesOnCloseModal();
  }
  useEffect(() => {
    if (dadosCobranca && selectedValue !== "SP") {
      handleConfirmarEmissao();
    }
  }, [dadosCobranca]);
  return (
    <>
      <ModalCustom
        show={showModal}
        handleClose={handleStatesOnCloseModal}
        title={pagina === "emissao" ? "Emitir licenciamentos" : "Consultar licenciamentos"}
        centered
        size="xl"
      >
        {selectedValue ? (
          <>
            <HeaderModal handleCheckOption={handleCheckOption} pagina={pagina} />
            {bodyStrategy[selectedValue]}
          </>
        ) : (
          <SelecionarUf selected={selectedValue!} handleSelect={handleChangeModalBody} pagina={pagina} />
        )}
      </ModalCustom>
      <ModalConfirmarEmissao
        show={showModalConfirmar}
        handleClose={() => {
          setShowModalConfirmar(false);
          setDadosIndividual(null);
          setDadosPlanilha([]);
        }}
        handleConfirmarEmissao={handleConfirmarEmissao}
      />
    </>
  );
}
