import { FaFileExcel } from "react-icons/fa";
import { Container, Opcao, TextoOpcao, Titulo } from "./style";
import { LoadingCirculo } from "components/LoadingCirculo";

interface Props {
    aberto: boolean;
    onClickListagem: () => void;
    onClickDetalhes: () => void;
    showOpcaoListagem: boolean;
    loading: boolean;
}

export function OpcoesExportarExcel({ aberto, onClickListagem, onClickDetalhes, showOpcaoListagem, loading }: Props) {

    return (
        <Container aberto={aberto}>
            {loading
                ? (<LoadingCirculo mostrar={loading} />)
                : (
                    <>
                        <Titulo><FaFileExcel /> Excel</Titulo>
                        {showOpcaoListagem && < Opcao > <TextoOpcao onClick={onClickListagem}>Listagem de consultas</TextoOpcao></Opcao>}
                        <Opcao><TextoOpcao onClick={onClickDetalhes}>Detalhes das consultas</TextoOpcao></Opcao>
                    </>
                )
            }
        </Container >
    );
}
