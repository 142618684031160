import styled from "styled-components";

interface LoadingProps {
    display: "none" | "flex"
}

export const LoadingContainer = styled.div<LoadingProps>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0,0,0,0.3);
    display: ${props => props.display};
    align-items: center;
    justify-content: center;

    div {
        width: 50px;
        height: 50px;
    }
`