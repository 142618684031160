import { ModalCustom } from "components/ModalCustom";
import { FaCheck, FaCopy } from "react-icons/fa";
import { ContainerCentralizado, DivBackGround, DivCopiarPix, DivRelatorioPagamentos, Link } from "./style";
import { PrimaryButton } from "components/Button/style";
import { toastSucesso } from "components/Toast";

interface Props {
  show: boolean;
  handleClose: () => void;
  codigoPix: string;
  id: number;
}

export function ModalSucessoSolicitarPagamento({
  show,
  handleClose,
  codigoPix,
  id,
}: Props) {
  const copiarAreaTransferencia = () => {
    navigator.clipboard.writeText(codigoPix)
    toastSucesso("Copiado para área de transferência!");
  }
  return (
    <ModalCustom
      title="Solicitar pagamentos"
      show={show}
      handleClose={handleClose}
      centered={true}
      size="lg"
    >
      <ContainerCentralizado>
        <DivBackGround>
          <FaCheck />
        </DivBackGround>
        <div> Solicitação de pagamento efetuada com sucesso - <strong>ID Pagamento: {id}</strong></div>
        <DivRelatorioPagamentos>Você também pode visualizar o recibo de adiantamento na tela <Link href="/relatorios/pagamento">Relatório de pagamento</Link></DivRelatorioPagamentos>
        {codigoPix && <DivCopiarPix onClick={copiarAreaTransferencia}>Copiar código pix para pagamento <FaCopy /></DivCopiarPix>}
        <PrimaryButton
          onClick={handleClose}
          style={{ marginTop: codigoPix ? "" : "24px" }}
        >Fechar</PrimaryButton>
      </ContainerCentralizado>
    </ModalCustom>
  );
}

