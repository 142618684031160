import { FaTimesCircle } from "react-icons/fa";
import { Container } from "./style";

export function NaoPertenceAoCombo() {
  return (
    <Container>
      <FaTimesCircle />
    </Container>
  );
}
