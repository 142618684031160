import { getToken } from "_services/auth";
import axios, { AxiosHeaders, HttpStatusCode, InternalAxiosRequestConfig } from "axios";

export class AxiosFactory {
  make(url: string, urlsNaoExpulsar: string[] = []) {
    const api = axios.create({
      baseURL: url,
    });

    api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
      const token = getToken();
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
          ...config.headers,
        } as unknown as AxiosHeaders;
        config.headers = headers;
      }


      
      
      return config;
    });

    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if(!urlsNaoExpulsar.includes(error.request.responseURL)) {
          if ([HttpStatusCode.Unauthorized].includes(error.response.status)) {
            window.location.href = "/?m=idNull";
            return;
          }
        }

        throw error;
      }
    );

    return api;
  }
}
