import { converterDataHoraBr } from "_utils/Data";
import { RedirectDetalhes } from "components/RedirectDetalhes";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { DetalhesContainer, StatusSolicitacaoLicenciamento } from "./style";
import { FaCheck } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSolicitacaoLicenciamentos } from "features/licenciamento/emissao/hooks/useSolicitacaoLicenciamentos";
import { Paginar } from "_utils/enuns/Paginar.enum";
import { utils, writeFileXLSX } from "xlsx";
import { useContext } from "react";
import { ContextPermissao } from "hooks/ContextPermissao";

export interface ISolicitacaoLicenciamento {
  id: number;
  dataHora: string;
  nomeUsuario: string;
  empresa: string;
  descricao: string;
  uf: string;
  nomeCliente: string;
  idsRecibos: string;
  motivoIsencao: string;
  qtdPlacasSolicitadas: number;
  qtdPlacasConsultadas: number;
  qtdDisponiveis: number;
}

interface Props {
  dados: ISolicitacaoLicenciamento[];
  loading: boolean;
  onChangePage: (page: number) => void;
  totalRows: number;
  onChangeRowsPerPage: (limit: number) => void;
  rowsPerPage: number;
  pagina: "emissão" | "consulta";
}

function handleStatusEmissao(qtdSolicitados: number, qtdEmitidos: number) {
  if (qtdEmitidos >= qtdSolicitados) {
    return (
      <StatusSolicitacaoLicenciamento type="finalizado">
        Finalizado <FaCheck />
      </StatusSolicitacaoLicenciamento>
    );
  }

  return (
    <StatusSolicitacaoLicenciamento type="processando">
      Processando <FontAwesomeIcon icon={faSpinner} spin />
    </StatusSolicitacaoLicenciamento>
  );
}

function makeRedirect(path: string) {
  return (
    <DetalhesContainer>
      <RedirectDetalhes path={path} />
    </DetalhesContainer>
  );
}

export function Tabela({
  dados,
  loading,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage,
  totalRows,
  pagina = "emissão",
}: Readonly<Props>) {
  const { listarSolicitacoesLicenciamento } = useSolicitacaoLicenciamentos();

  function handleStatusEmissaoPlanilha(
    qtdConsultados: number,
    qtdSolicitados: number
  ) {
    if (qtdConsultados === qtdSolicitados) {
      return "FINALIZADO";
    }

    return "PROCESSANDO";
  }

  async function handleExport() {
    const solicitacoes = await listarSolicitacoesLicenciamento(Paginar.NAO);
    const dadosPlanilha = solicitacoes.map(
      ({
        dataHora,
        descricao,
        empresa,
        id,
        nomeUsuario,
        uf,
        qtdDisponiveis,
        qtdPlacasConsultadas,
        qtdPlacasSolicitadas,
      }: ISolicitacaoLicenciamento) => ({
        id,
        "Data/Hora Solicitação": converterDataHoraBr(new Date(dataHora)),
        Usuário: nomeUsuario,
        Empresa: empresa,
        Descrição: descricao,
        UF: uf,
        "Qtd. Placas Solicitadas": qtdPlacasSolicitadas,
        "Qtd. Placas Emitidas": qtdDisponiveis,
        "Status Emissão": handleStatusEmissaoPlanilha(
          qtdPlacasConsultadas,
          qtdPlacasSolicitadas
        ),
      })
    );
    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 5 },
      { wch: 25 },
      { wch: 30 },
      { wch: 20 },
      { wch: 30 },
      { wch: 5 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha);
    writeFileXLSX(workBook, "solicitacoes-licenciamento.xlsx");
  }

  const { verificarEmpresa } = useContext(ContextPermissao);

  const columns: IColum<ISolicitacaoLicenciamento>[] = [
    {
      name: <span>ID</span>,
      selector: ({ id }: ISolicitacaoLicenciamento) => id,
      width: "50px",
    },
    {
      name: <span>Data/Hora Solicitação</span>,
      selector: ({ dataHora }: ISolicitacaoLicenciamento) =>
        converterDataHoraBr(new Date(dataHora)),
      width: "170px",
    },
    {
      name: <span>Usuário</span>,
      selector: ({ nomeUsuario }: ISolicitacaoLicenciamento) => nomeUsuario,
      width: "150px",
    },
    {
      name: <span>Solicitante</span>,
      selector: ({ empresa }: ISolicitacaoLicenciamento) => empresa,
    },
    {
      name: <span>Empresa</span>,
      selector: ({ nomeCliente }: ISolicitacaoLicenciamento) => nomeCliente,
      width: "180px",
    },
    {
      name: <span>Descrição</span>,
      selector: ({ descricao }: ISolicitacaoLicenciamento) => descricao,
      width: "125px",
    },
    {
      name: <span>UF</span>,
      selector: ({ uf }: ISolicitacaoLicenciamento) =>
        uf ? uf.toUpperCase() : "- -",
      width: "50px",
    },
    {
      name: <span>Qtd. Placas Solicitadas</span>,
      selector: ({ qtdPlacasSolicitadas }: ISolicitacaoLicenciamento) =>
        qtdPlacasSolicitadas,
    },
    {
      name: <span>Qtd. Placas Emitidas</span>,
      selector: ({ qtdDisponiveis }: ISolicitacaoLicenciamento) =>
        qtdDisponiveis,
    },
    {
      name: <span>Recibo</span>,
      selector: ({ idsRecibos }: ISolicitacaoLicenciamento) =>
        idsRecibos ?? "- -",
      width: "120px",
    },
    {
      name: <span>Motivo da isenção de cobrança</span>,
      selector: ({ motivoIsencao }: ISolicitacaoLicenciamento) =>
        motivoIsencao?.replace(/_/g, " ") ?? "- -",
      width: "180px",
      omit: !verificarEmpresa(),
    },
    {
      name: <span>Status da Emissão</span>,
      selector: ({
        qtdPlacasSolicitadas,
        qtdPlacasConsultadas,
      }: ISolicitacaoLicenciamento) =>
        handleStatusEmissao(qtdPlacasSolicitadas, qtdPlacasConsultadas),
      width: "135px",
    },
    {
      name: <span>Detalhes</span>,
      selector: ({ id }: ISolicitacaoLicenciamento) =>
        makeRedirect(`/licenciamento/${pagina.replace(/ã/g, "a")}/${id}`),
      width: "80px",
    },
  ];

  return (
    <Table
      columns={columns}
      data={dados}
      loading={loading}
      keyField="id"
      pagination={true}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      rowsPerPage={rowsPerPage}
      totalRows={totalRows}
      exportExcel={true}
      handleExport={handleExport}
      buttonExportVariant="secondary"
    />
  );
}

