import { getEmail } from "_services/auth";
import { CobrarExtratoVeicular } from "features/extrato-veicular/enuns/CobrarExtratoVeicular.enum";
import * as yup from "yup";

export const cobrancaSchema = yup.object().shape({
  empresa: yup
    .string()
    .nullable()
    .test(
      "is-valid",
      () => "Selecione a empresa",
      (value) => {
        if (value) {
          return true;
        }
        return !value && !getEmail()?.includes("lwtecnologia");
      }
    ),
  cobrar: yup
    .string()
    .nullable()
    .test(
      "is-valid",
      () => "Selecione a opção de cobrança",
      (value) => {
        if (value) {
          return true;
        }
        return !value && !getEmail()?.includes("lwtecnologia");
      }
    ),
  motivoIsencao: yup
    .string()
    .nullable()
    .optional()
    .when("cobrar", {
      is: (cobrar: string) => cobrar === CobrarExtratoVeicular.NAO,
      then: (schema) => schema.required("Informe o motivo da isenção de cobrança"),
    }),
});

