import { RedirectDetalhesVertical } from "components/RedirectDetalhes";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { AcoesContainer, ButtonComprovante } from "./style";
import { useState } from "react";
import { ModalComprovantes } from "../../../containers/ModalComprovantes";

export function MakeAcoes({ comprovantes, id }: Readonly<{ id: number; comprovantes: string }>) {
	const [showModalComprovantes, setShowModalComprovantes] = useState(false);

	return (
		<AcoesContainer>
			<ButtonComprovante disabled={!comprovantes} onClick={() => setShowModalComprovantes(true)}>
				<FaMoneyCheckAlt />
			</ButtonComprovante>
			<RedirectDetalhesVertical path={`/debito/pagamento/${id}`} />
			{comprovantes && (
				<ModalComprovantes
					comprovantes={comprovantes.split(",")}
					handleClose={() => setShowModalComprovantes(false)}
					show={showModalComprovantes}
				/>
			)}
		</AcoesContainer>
	);
}
