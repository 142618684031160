import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  gap: 0.75rem;

  div {
    font-size: 15px;
    margin: 0;
  }
`;

export const TextoNegrito = styled.span`
  font-weight: bold;
`

export const PlacasContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  grid-gap: 0.35rem;
  max-height: 220px;
  overflow-y: auto;
  width: 100%;
  background-color: #F0F0F0;
  padding: 0.5rem;
`;

export const Placa = styled.div`
  width: 100%;
`