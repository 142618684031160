import { Row } from "react-bootstrap";
import styled, { keyframes } from "styled-components";

const fadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const DetalhesMultasContainer = styled.div`
  animation: ${fadeAnimation} ease-in-out 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transition: opacity 0.1s ease-in-out;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.light};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.neutral30};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral30};
  margin-bottom: 1rem;
`;

export const StyledRow = styled(Row)`
  margin: 1rem;
`;
