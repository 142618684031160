import { ModalCustom } from "components/ModalCustom";
import { FaExclamation } from "react-icons/fa";
import { IconeContainer, InfoContainer, TextContainer } from "./style";
import { Button } from "react-bootstrap";
import { PrimaryButton } from "components/Button/style";

interface Props {
    show: boolean;
    onClick: () => void;
}

export function ModalErroToken({
    show,
    onClick,
}: Props) {
    return (
        <ModalCustom
            handleClose={onClick}
            show={show}
            onConfirm={onClick}
            centered
            size="lg"
        >
            <InfoContainer>
                <IconeContainer>
                    <FaExclamation />
                </IconeContainer>
                <TextContainer>
                    <div><strong>Sua sessão expirou</strong></div>
                    <span>Faça login novamente para continuar usando o portal LW Débitos</span>
                </TextContainer>
                <PrimaryButton onClick={onClick}>
                    Fazer login
                </PrimaryButton>
            </InfoContainer>
        </ModalCustom>
    );
}

