import { toastErro } from "components/Toast";
import { useEffect, useState } from "react";
import api from "_services/api";
import { formatarDinheiro } from "_utils/Money";
import { IDashboardHookProps } from "../interfaces/IDashboardHookProps.interface";

export function useValoresDashboard({
  empresaSelecionada,
  dataInicial,
  dataFinal,
}: IDashboardHookProps) {
  const [totalPago, setTotalPago] = useState("R$ 0");
  const [placasConsultadas, setPlacasConsultadas] = useState("0");
  const [pagamentosRealizados, setPagamentosRealizados] = useState("0");
  const [licenciamentosEmitidos, setLicenciamentosEmitidos] = useState("0");
  const [pagamentosRecusados, setPagamentosRecusados] = useState("0");

  useEffect(() => {
    buscarDados();
  }, [empresaSelecionada, dataInicial, dataFinal]);

  async function buscarDados(): Promise<void> {
    const empresaQuery =
      empresaSelecionada && empresaSelecionada != null
        ? `&idEmpresa=${empresaSelecionada}`
        : "";

    const dataInicialQuery =
      dataInicial && dataInicial != null
        ? `&dataInicial=${dataInicial}`
        : "";

    if (!dataFinal) {
      return;
    }

    try {
      const { data } = await api.get(
        `/debito/dashboard/resumo/?${dataInicialQuery}&dataFinal=${dataFinal}${empresaQuery}`
      );

      setTotalPago(formatarDinheiro(data.totalPago / 100));
      setPlacasConsultadas(
        parseInt(data.placasConsultadas).toLocaleString("pt-br")
      );
      setPagamentosRealizados(
        parseInt(data.pagamentosRealizados).toLocaleString("pt-br")
      );
      setLicenciamentosEmitidos(
        parseInt(data.licenciamentosEmitidos).toLocaleString("pt-br")
      );
      setPagamentosRecusados(
        parseInt(data.pagamentosRecusados).toLocaleString("pt-br")
      );
    } catch (e) {
      toastErro("Não foi possível buscar os dados do resumo");
    }
  }

  return { totalPago, placasConsultadas, pagamentosRealizados, licenciamentosEmitidos, pagamentosRecusados };
}
