import { Informacao } from "components/Informacao";
import { ModalCustom } from "components/ModalCustom";
import { Table } from "containers/Table";

interface Props {
  show: boolean;
  handleClose: () => void;
  erros: any;
}

function handleWidthTableErros(key: string) {
  if (key === "UF") {
    return "80px";
  }

  if (key === "PLACA" || key === "RENAVAM") {
    return "120px";
  }

  if (key === "CHASSI") {
    return "180px";
  }
}

function handleTableErros(erros: any[]) {
  const keys = erros ? Object.keys(erros[0].dados) : [];
  const columns: any = keys?.map((key) => ({
    name: <span>{key?.toUpperCase()}</span>,
    selector: (data: any) => (
      <span>{key === "erros" ? data?.dados[key].map((item: any) => <div>{item}</div>) : data?.dados[key]}</span>
    ),
    width: handleWidthTableErros(key),
  }));

  columns.unshift({
    name: "LINHA",
    selector: (data: any) => data?.linha,
    width: "100px",
  });

  return <Table keyField="id" columns={columns} data={erros} loading={false} />;
}

export function ModalErrosPlanilha({ erros, handleClose, show }: Props) {
  return (
    <ModalCustom
      size="xl"
      handleClose={handleClose}
      show={show}
      title="Consulta não realizada"
      footer
      footerCloseButton
    >
      <div>
        <div style={{ display: "grid", gap: "2rem" }}>
          <strong>Encontramos alguns dados inconsistentes na planilha importada.</strong>
          <Informacao
            style={{ margin: 0 }}
            mensagem={
              <div>
                Não foi possível realizar a consulta devido a informações inconsistentes na planilha importada <br />
                Faça a correção dos erros listados abaixo e importe novamente a planilha para realizar a consulta
              </div>
            }
            type="erro"
            showBotaoFechar={false}
          />
        </div>
        {handleTableErros(erros)}
      </div>
    </ModalCustom>
  );
}
