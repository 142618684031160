import { Tooltip } from "components/Tooltip";
import { TooltipContent, TriggerContainer } from "./style";
import { FaInfoCircle } from "react-icons/fa";
import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";

interface Props {
  tentativas: number;
  status: number;
}

export const TentativasConsultas = ({ tentativas, status }: Props) => {
  if (tentativas === 0) {
    return <TriggerContainer>--</TriggerContainer>;
  }

  const statusComTooltip = [StatusSolicitacaoPagamentoEnumV2.Consultando, StatusSolicitacaoPagamentoEnumV2.Falha]

  if (statusComTooltip.some((item) => item === status)) {
    const bottomTooltip = -4;
    const rightTooltip = -50;
    const widthTooltip = 200;

    const mensagemTooltip = tentativas >= 3 ? `3ª tentativa sem sucesso` : `Processando ${tentativas + 1}ª tentativa`;

    return (
      <Tooltip
        trigger={<TriggerContainer cursorHelp>{`0${tentativas}`}</TriggerContainer>}
        bottom={bottomTooltip}
        right={rightTooltip}
        width={widthTooltip}
        positionRelative={false}
      >
        <TooltipContent>
          <span>
            <FaInfoCircle /> Falha na consulta
          </span>
          <strong>{mensagemTooltip}</strong>
        </TooltipContent>
      </Tooltip>
    );
  }

  return <TriggerContainer>0{tentativas}</TriggerContainer>;
};

