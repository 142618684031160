import { LoginBoxContainer } from "features/Login/container/LoginBoxContainer";
import { Container, ImgLogoDebitos, ImgLogoGrupoLw } from "./style";

export function Login() {
    return (
        <Container>
            <ImgLogoDebitos />
            <LoginBoxContainer />
        </Container>
    );
}
