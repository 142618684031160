import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { intlCurrencyFormat } from "_utils/Money";

interface Props {
  dpvats: any[];
}

export function DadosDpvatPDFNacional({ dpvats }: Readonly<Props>) {
  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>DPVAT</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor total DPVAT</Text>
            <Text style={STYLES.FONT_SIZE_09}>
              {dpvats?.length
                ? intlCurrencyFormat(dpvats.map((item) => item.valor / 100).reduce((prev, acc) => acc + prev))
                : "-"}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

