import { ModalCustom } from "components/ModalCustom";
import { FiX } from "react-icons/fi";
import { IconeContainer, ModalBody } from "./style";

interface Props {
  show: boolean;
  handleClose: () => void;
}

export function ModalCrlvsIndisponiveis({ show, handleClose }: Readonly<Props>) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Emitir licenciamentos"
      centered
    >
      <ModalBody>
        <IconeContainer>
          <FiX />
        </IconeContainer>
        <span>Nenhum licenciamento disponível para emissão encontrado</span>
      </ModalBody>
    </ModalCustom>
  );
}
