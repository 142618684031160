import { FaDownload } from "react-icons/fa";
import { Button, IconeContainer } from "./style";
import { useSolicitacaoDownload } from "features/arquivos/pages/Download/hooks/useSolicitacaoDownload";

interface Props {
  idSolicitacaoDownload: number;
  disabled: boolean;
}

export function IconeDownload({ disabled, idSolicitacaoDownload }: Props) {
  const { realizarDownload } = useSolicitacaoDownload();
  return (
    <IconeContainer>
      <Button onClick={() => realizarDownload(idSolicitacaoDownload)} disabled={disabled}>
        <FaDownload />
      </Button>
    </IconeContainer>
  )
}
