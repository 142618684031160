import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled(Row)`
  padding-top: 1rem;
  display: grid;
  gap: 1rem;
`;

export const DadosMultaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  /* gap: 2rem; */
`;

interface DadosMultaProps {
  mostrar: boolean;
}

export const DadosMultaBox = styled(Col)<DadosMultaProps>`
  font-size: 14px;
  display: ${({ mostrar }) => !mostrar && 'none'};
`;

export const DadosMultaTitle = styled.strong``;

export const DadosMultaText = styled.p``;

export const AtencaoContainer = styled.div``;