import { useCallback, useState } from "react";
import { useRequest } from "hooks/useRequest";
import { IFiltro } from "../pages/Detalhes/containers/Filtros/Interface/IFiltro";
import { IDetalhesConsulta } from "../pages/Detalhes/containers/interfaces/IDetalhesConsulta";
import { Paginar } from "_utils/enuns/Paginar.enum";

interface IEmitir {
    cobrar: NullableString,
    idCliente: NullableString,
    nomeCliente: NullableString,
    plataforma: string,
    emitir: string,
    motivoIsencao: NullableString,
    idsLicenciamentos: string[] | number[],
    exercicio: number;
}

export function useConsultaLicenciamento() {
    const { get, post, loading } = useRequest("consulta");
    const [detalhesConsulta, setDetalhesConsulta] = useState<IDetalhesConsulta[]>([]);
    const [filtrosDetalhes, setFiltrosDetalhes] = useState<IFiltro>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [idsLicenciamentos, setIdsLicenciamentos] = useState<number[]>([]);

    function handleStatusDebitos(item: any) {
        const statusConsultado = 1;
        if (
            Number(item.statusEmissao) === statusConsultado
        ) {
            item.selectable = true;
            return;
        }
        item.selectable = false;
    }

    const gerarQueryParams = useCallback(() => {
        const filtrosArray = [
            filtrosDetalhes?.placa ? `placa=${filtrosDetalhes.placa}` : null,
            filtrosDetalhes?.renavam ? `renavam=${filtrosDetalhes.renavam}` : null,
            filtrosDetalhes?.statusConsulta ? `status=${filtrosDetalhes.statusConsulta}` : null,
            filtrosDetalhes?.exercicio ? `exercicio=${filtrosDetalhes.exercicio}` : null,
            filtrosDetalhes?.codigoRetorno ? `codigoRetorno=${filtrosDetalhes.codigoRetorno}` : null,
        ].filter((item) => item !== null);

        return `${filtrosArray.join("&")}`;
    }, [filtrosDetalhes]);

    async function verificarCrlvsEmissao(idConsulta: string) {
        const { hasErro } = await get(`/licenciamento/consultas/${idConsulta}?status=1&paginate=${Paginar.NAO}`, {}, false);

        return hasErro;
    }

    async function buscarDetalhes(idConsulta: string) {
        const params = gerarQueryParams();
        const { data, hasErro } = await get(
            `/licenciamento/consultas/${idConsulta}?${params}&page=${page}&limit=${limit}&paginate=${Paginar.SIM}`,
            {},
            false,
        );

        if (hasErro) {
            setDetalhesConsulta([]);
            return;
        }
        setDetalhesConsulta(data.items)
        setTotalRows(data.meta.totalItems)
        data.items.forEach(handleStatusDebitos);
        return data;
    }

    async function consultarNovamente(idConsulta: string) {
        return post(`/licenciamento/consultas/${idConsulta}/reconsulta`, null);
    }

    async function emitirLicenciamentosConsulta(idConsulta: string, dados: IEmitir) {
        const response = await post<IEmitir, any>(`/licenciamento/consultas/${idConsulta}/emitir`, {
            cobrar: dados.cobrar,
            idCliente: dados.idCliente,
            nomeCliente: dados.nomeCliente,
            plataforma: dados.plataforma,
            emitir: dados.emitir,
            motivoIsencao: dados.motivoIsencao,
            idsLicenciamentos: dados.idsLicenciamentos,
            exercicio: dados.exercicio
        });

        return response;
    }

    return {
        buscarDetalhes,
        detalhesConsulta,
        setFiltrosDetalhes,
        filtrosDetalhes,
        page,
        limit,
        totalRows,
        setPage,
        setLimit,
        setTotalRows,
        consultarNovamente,
        loading,
        setIdsLicenciamentos,
        idsLicenciamentos,
        emitirLicenciamentosConsulta,
        verificarCrlvsEmissao,
    };
}
