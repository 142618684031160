import * as yup from "yup";
import { cobrancaSchema } from "./cobrancaSchema";

export const loteSchema = yup
  .object({
    tipoConsulta: yup.string().required("Selecione o tipo de consulta"),
    arquivo: yup
      .mixed()
      .test("file-selected", "Selecione um arquivo", (value) => {
        return value instanceof File && value.size > 0;
      })
      .required("Selecione um arquivo")
      .nonNullable(),
  })
  .concat(cobrancaSchema);

