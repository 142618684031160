import * as yup from "yup";
import { regexPlacas } from "../../Listar/validators/filtrosExtratoVeicularValidator";

export const filtrosDetalhesExtratoVeicularSchema = yup.object({
  placa: yup.string().nullable().matches(regexPlacas, {
    excludeEmptyString: true,
    message: "Placa inválida"
  }),
  chassi: yup.string().nullable(),
  tipoPendencia: yup.string().nullable(),
  statusConsulta: yup.string().nullable(),
  renavam: yup.string().nullable(),
});
