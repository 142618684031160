import { Text, View } from "@react-pdf/renderer";
import { IInspecaoVeicular } from "../../../../../DadosLeiloes/containers/BodyInspecaoVeicular";
import { STYLES } from "../../../../style";
import { StringUtils } from "_utils/String.utils";

interface Props {
  inspecaoVeicular: IInspecaoVeicular;
}

export function BodyInspecaoVeicularPdf({ inspecaoVeicular }: Readonly<Props>) {
  return (
    <>
      <View style={[STYLES.SECTION_ROW, { paddingTop: "10px" }]}>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={STYLES.FONT_SIZE_09}>Data</Text>
          <Text style={STYLES.FONT_SIZE_09}>
            {inspecaoVeicular?.dataInspecao ?? "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={STYLES.FONT_SIZE_09}>Link</Text>
          <Text style={STYLES.FONT_SIZE_09}>
            {inspecaoVeicular?.link
              ? StringUtils.primeiraLetraMaiuscula(
                  inspecaoVeicular.link.split("_").join(" ")
                )
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={STYLES.FONT_SIZE_09}>Garantia</Text>
          <Text style={STYLES.FONT_SIZE_09}>
            {inspecaoVeicular?.garantia
              ? StringUtils.primeiraLetraMaiuscula(
                  inspecaoVeicular?.garantia?.split("_").join(" ")
                )
              : "-"}
          </Text>
        </View>
      </View>
      <View>
        <Text style={[STYLES.FONT_SIZE_08, STYLES.TEXTO_QUASE_CLARO]}>
          Checklist do veículo
        </Text>
      </View>
      <View style={[STYLES.SECTION_ROW, { paddingTop: "10px" }]}>
        <View style={STYLES.SECTION_COL_2}>
          <Text style={STYLES.FONT_SIZE_09}>
            Porta malas:{" "}
            {inspecaoVeicular?.portaMalas ? inspecaoVeicular?.portaMalas : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Frente: {inspecaoVeicular?.frente ? inspecaoVeicular?.frente : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Frente direita:{" "}
            {inspecaoVeicular?.frenteDireita
              ? inspecaoVeicular?.frenteDireita
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Frente esquerda:{" "}
            {inspecaoVeicular?.frenteEsquerda
              ? inspecaoVeicular?.frenteEsquerda
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Porta frente direita:{" "}
            {inspecaoVeicular?.portaFrenteDireita
              ? inspecaoVeicular?.portaFrenteDireita
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Porta frente esquerda:{" "}
            {inspecaoVeicular?.portaFrenteEsquerda
              ? inspecaoVeicular?.portaFrenteEsquerda
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Porta traseira direita:{" "}
            {inspecaoVeicular?.portaTraseiraDireita
              ? inspecaoVeicular?.portaTraseiraDireita
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Porta traseira esquerda:{" "}
            {inspecaoVeicular?.portaTraseiraEsquerda
              ? inspecaoVeicular?.portaTraseiraEsquerda
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_2}>
          <Text style={STYLES.FONT_SIZE_09}>
            Pneu frente direita:{" "}
            {inspecaoVeicular?.pneuFrenteDireita
              ? inspecaoVeicular?.pneuFrenteDireita
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Pneu frente esquerda:{" "}
            {inspecaoVeicular?.pneuFrenteEsquerda
              ? inspecaoVeicular?.pneuFrenteEsquerda
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Pneu traseira direita:{" "}
            {inspecaoVeicular?.pneuTraseiraDireita
              ? inspecaoVeicular?.pneuTraseiraDireita
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Pneu traseira esquerda:{" "}
            {inspecaoVeicular?.pneuTraseiraEsquerda
              ? inspecaoVeicular?.pneuTraseiraEsquerda
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Bancos dianteiros:{" "}
            {inspecaoVeicular?.bancosDianteiros
              ? inspecaoVeicular?.bancosDianteiros
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Bancos traseiros:{" "}
            {inspecaoVeicular?.bancosTraseiros
              ? inspecaoVeicular?.bancosTraseiros
              : "-"}
          </Text>
          <Text style={STYLES.FONT_SIZE_09}>
            Teto: {inspecaoVeicular?.teto ? inspecaoVeicular?.teto : "-"}
          </Text>
        </View>
      </View>
      <View>
        <Text style={STYLES.FONT_SIZE_09}>Observações</Text>
        <Text style={STYLES.FONT_SIZE_09}>{inspecaoVeicular?.observacoes}</Text>
      </View>
    </>
  );
}
