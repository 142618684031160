import { ModalCustom } from "components/ModalCustom";
import { BodyPergunta, BodyTexto, ModalBodyContainer } from "../../style";
import { FaExclamationTriangle } from "react-icons/fa";

interface Props {
  showModal: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  tipoModal: "download" | "emissao";
}

export function ModalCheckbox({
  handleClose,
  onConfirm,
  showModal,
  tipoModal,
}: Readonly<Props>) {
  return (
    <ModalCustom
      show={showModal}
      handleClose={handleClose}
      title="Download CRLV"
      onConfirm={onConfirm}
      centered
      footer
      footerConfirmButton
    >
      <ModalBodyContainer>
        <BodyTexto>
          A seleção do checkbox contempla apenas os veículos listados na página
          atual, para realizar o download de todos os CRLVs disponíveis no lote,
          clique no botão <strong>Download CRLV</strong> sem selecionar nenhum
          checkbox.
        </BodyTexto>
        <BodyPergunta>
          <FaExclamationTriangle />
          Deseja realizar o download dos CRLVs selecionados?
        </BodyPergunta>
      </ModalBodyContainer>
    </ModalCustom>
  );
}

