import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const DatePickerContainer = styled.div`
  .react-datepicker__day {
    width: 32px;
    height: 32px;
    padding: 6px 0;
    border-radius: 10px;
  }
  .react-datepicker__day--today {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--in-selecting-range{
    background-color: ${({ theme }) => theme.colors.project.bgColor};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  button.react-datepicker__close-icon::after {
    background-color: ${({ theme }) => theme.colors.project.main};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 0.5rem;

  button {
    margin-top: 1.75rem;
  }
`;

export const FormContainer = styled(Row)`
  min-height: 90px;

  @media (max-width: 1370px) {
    div {
      input::placeholder {
        font-size: 12px;
      }
    }
  }
`;

export const InputContainer = styled(Col)`
  @media screen and (max-width: 1370px) and (min-width: 993px) {
    width: 12%;
  }
`;
