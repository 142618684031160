import { DropdownSelect } from "components/DropdownSelect";
import { UFS_LICENCIAMENTO_STRATEGY, UF_OPTIONS } from "features/licenciamento/emissao/constants";
import { Container, SelecioneText } from "./style";

interface Props {
  handleSelect: (dado: any) => void;
  selected: string;
  pagina?: "consulta" | "emissao"
}

export function SelecionarUf({ handleSelect, selected, pagina = "emissao" }: Readonly<Props>) {
  return (
    <Container>
      <SelecioneText>
        Selecione o estado
      </SelecioneText>
      <DropdownSelect
        onSelect={handleSelect}
        selectedValue={selected}
        title="UF"
        options={UFS_LICENCIAMENTO_STRATEGY[pagina]}
      />
    </Container>
  )
}