import { ContextPermissao } from "hooks/ContextPermissao";
import { ElementType, useContext } from "react";
import { Control, ControllerProps } from "react-hook-form";
import { Container, InputsContainer, RadioContainer, SelectContainer, SpanErro } from "./style";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { MotivosIsencaoOptions } from "features/licenciamento/emissao/constants";
import { CobrarExtratoVeicular } from "features/extrato-veicular/enuns/CobrarExtratoVeicular.enum";

interface Props {
  controller: ElementType<ControllerProps>;
  control: Control;
  errors: any;
  agrupamentoOptions: IOptions[];
  cobranca: "SIM" | "NAO" | null;
  setCobranca: (cobranca: "SIM" | "NAO" | null) => void;
  onChangeEmpresa?: (e: string) => void;
}

export function SelecionarEmpresaConsultaVeicular({
  control,
  controller: Controller,
  errors,
  agrupamentoOptions,
  cobranca,
  setCobranca,
  onChangeEmpresa = (e) => { },
}: Readonly<Props>) {
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);
  const maxHeightListaEmpresas = 250;

  return (
    <Container>
      <InputsContainer>
        <SelectContainer>
          <Controller
            name="empresa"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DropdownSelect
                onSelect={(e) => {
                  onChange(e);
                  onChangeEmpresa(e);
                }}
                options={agrupamentoOptions}
                searchable={true}
                selectedValue={value}
                title="Empresa"
                required
                mensagemErro={errors.empresa?.message}
                maxHeight={maxHeightListaEmpresas}
              />
            )}
          />
        </SelectContainer>
        {verificarUsuarioAwLw() && (
          <RadioContainer>
            <span>Gerar recibo de cobrança</span>
            <div>
              <div>
                <Controller
                  name="cobrar"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <input
                      id="radioSim"
                      type="radio"
                      value={CobrarExtratoVeicular.SIM}
                      onChange={(event: any) => {
                        setCobranca("SIM");
                        onChange(event);
                      }}
                      checked={cobranca === "SIM"}
                    />
                  )}
                />
                <label htmlFor="radioSim">Sim</label>
              </div>
              <div>
                <Controller
                  name="cobrar"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <input
                      id="radioNao"
                      type="radio"
                      value={CobrarExtratoVeicular.NAO}
                      onChange={(event: any) => {
                        setCobranca("NAO");
                        onChange(event);
                      }}
                      checked={cobranca === "NAO"}
                    />
                  )}
                />
                <label htmlFor="radioNao">Não</label>
              </div>
            </div>
            <SpanErro>{errors.cobrar?.message}</SpanErro>
          </RadioContainer>
        )}
      </InputsContainer>
      {cobranca === "NAO" && (
        <Controller
          control={control}
          name="motivoIsencao"
          render={({ field: { onChange, value } }) => (
            <DropdownSelect
              onSelect={onChange}
              options={MotivosIsencaoOptions}
              selectedValue={value}
              title="Motivo da isenção da cobrança"
              mensagemErro={errors.motivoIsencao?.message}
            />
          )}
        />
      )}
    </Container>
  );
}

