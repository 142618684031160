import { LoadingCirculo } from "components/LoadingCirculo";
import { ModalCustom } from "components/ModalCustom";
import { RecusarDebitos } from "components/RecusarDebitos";
import { useEffect } from "react";
import { IModalRecusarPagamento } from "../../interfaces/IModalRecusarPagamento";
import { LoadingContainer } from "./style";

export function ModalRecusarPagamento({
  show,
  handleClose,
  todos,
  recusarPagamentos,
  showFeedback,
  getMotivoRecusa,
  selectedRows,
  handleBuscarDebitos,
  loadingBuscarTodos,
}: IModalRecusarPagamento) {
  const quantidadeDebitosSelecionados = selectedRows.length;

  useEffect(() => {
    if (show && todos) handleBuscarDebitos();
  }, [show, todos]);

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      size="lg"
      title="Recusar Pagamento"
      centered={true}
      footerConfirmButton={true}
      footer={true}
      onConfirm={recusarPagamentos}
    >
      {loadingBuscarTodos ? (
        <LoadingContainer>
          <LoadingCirculo mostrar={loadingBuscarTodos} />
        </LoadingContainer>
      ) : (
        <RecusarDebitos
          getMotivoRecusa={getMotivoRecusa}
          todos={todos}
          showFeedback={showFeedback}
          quantidadeDebitosSelecionados={quantidadeDebitosSelecionados}
        />
      )}
    </ModalCustom>
  );
}

