import { Row } from "react-bootstrap";
import styled from "styled-components";

interface DividerProps {
  bold?: boolean;
  semMargem?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem;
  text-align: center;
`;

export const Texto = styled.p`
`;

export const Divider = styled.hr<DividerProps>`
  width: 100%;
  height: 2px;
  background: ${({ bold }) => bold ? "#000" : "#CCC"};
  ${({ semMargem }) => semMargem && `
    margin: 0;
  `}
`;

export const Strong = styled.strong``;

export const TableHeader = styled(Row)`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const TableDivider = styled.div`
  height: 1px;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  color: #000;
  background-color: #000000;
`;

export const TotalLinhas = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
`;

export const TotaisContainer = styled(Row)`
  padding: 0.3rem;
  align-items: center;
  justify-content: space-between;

  .col {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
    }
  }
`;