import { useCallback, useState } from "react";
import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError, IHttpResponse } from "_services/api/interface/HttpResponse";
import { HttpStatusCode } from "axios";

export function useDebitosDetalhes() {
  const [debitosDetalhes, setDebitosDetalhes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<IHttpResponse<IData | any>>();

  const buscarDebitosDetalhes = useCallback(async (idDebito: number) => {
    setLoading(true);
    try {
      const { data, status } = await api.get(
        `debito/consulta/debitos/${idDebito}/detalhes`
      );

      setDebitosDetalhes(data.items);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    error,
    loading,
    debitosDetalhes,
    buscarDebitosDetalhes,
  }
}
