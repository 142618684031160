import { StatusConsulta } from "features/licenciamento/consulta/enum/StatusConsulta";
import styled from "styled-components";

interface PropsContainer {
    statusConsulta: number;
}

export const Container = styled.button<PropsContainer>`
    border-radius: 15px;
    width: 150px;
    height: 24px;
    text-align: center;

    ${({ statusConsulta, theme }) =>
            StatusConsulta[statusConsulta] === "Processando" &&
            `
        background-color: ${theme.colors.tags.disabled.bgColor};
        border: solid 1px ${theme.colors.tags.disabled.textColor};
        color: ${theme.colors.tags.disabled.textColor};
    `}  

    ${({ statusConsulta, theme }) =>
            StatusConsulta[statusConsulta] === "Falha" &&
            `
        background-color: ${theme.colors.tags.danger.bgColor};
        border: solid 1px ${theme.colors.tags.danger.textColor};
        color: ${theme.colors.tags.danger.textColor}; 
        disabled: true;
    `}

    ${({ statusConsulta, theme }) =>
            StatusConsulta[statusConsulta] === "Consultado" &&
            `
        background-color: ${theme.colors.tags.info03.bgColor};
        border: solid 1px ${theme.colors.tags.info03.textColor};
        color: ${theme.colors.tags.info03.textColor}; 
        disabled: true;
    `}

    ${({ statusConsulta, theme }) =>
            StatusConsulta[statusConsulta] === "Dados inconsistentes" &&
            `
        background-color: ${theme.colors.tags.warning02.bgColor};
        border: solid 1px ${theme.colors.tags.warning02.textColor};
        color: ${theme.colors.tags.warning02.textColor}; 
        disabled: true;
    `}

    &:hover {
        cursor: default;
    }
`;

