import { Col, Row } from "react-bootstrap";
import styled, { keyframes } from "styled-components";

const fadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DetalhesRestricoesContainer = styled.div`
  animation: ${fadeAnimation} ease-in-out 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transition: opacity 0.1s ease-in-out;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.neutral30};
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.colors.light};
`;

export const ContanierComBordaInferior = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral30};
  padding-top: 0.5rem;
  padding-botton: 0.5rem;
`;

export const StyledRow = styled(Row)`
  margin: 1rem;
`;

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.375rem;
  padding: 0.5rem 0 0.5rem 0.5rem;

  div {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    scrollbar-width: none;
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
