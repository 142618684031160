import styled from "styled-components";

interface IPillButton {
    pressed: boolean;
}
export const PillButton = styled.button<IPillButton>`
 color: ${({ theme, pressed }) => pressed ? theme.colors.white : theme.colors.neutral.neutral80};
 background-color: ${({ theme, pressed }) => pressed ? theme.colors.project.main : theme.colors.white};
 border: ${({ theme, pressed }) => pressed ? `1px solid ${theme.colors.project.main}` : `1px solid ${theme.colors.neutral.neutral80}`};
 border-radius: 50px;
 width: 95px;
`;