import { ModalCustom } from "components/ModalCustom";
import { IconeContainer, MensagemSucessoContainer, ModalBody } from "./style";
import { FaCheck } from "react-icons/fa";

interface Props {
  show: boolean;
  handleClose: () => void;
}

export function ModalSucessoReconsulta({ show, handleClose }: Readonly<Props>) {
  return (
    <ModalCustom title="Consultar novamente" centered show={show} handleClose={handleClose}>
      <ModalBody>
        <MensagemSucessoContainer>
          <IconeContainer>
            <FaCheck />
          </IconeContainer>
          <span>Solicitação efetuada com sucesso!</span>
        </MensagemSucessoContainer>
        <strong>As consultas com status de falha serão reprocessadas.</strong>
      </ModalBody>
    </ModalCustom>
  );
}

