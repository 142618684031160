import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 14px;
	gap: 1rem;
`;

export const IconContainer = styled.div`
	padding: 0.5rem;
	background-color: ${({ theme }) => theme.colors.warning.bgColor};
	border-radius: 0.65rem;

	svg {
		width: 24px;
		height: 24px;
		color: ${({ theme }) => theme.colors.warning.main};
	}
`;

export const SpanText = styled.span`
	text-align: center;
`;
