import { PrimaryButton } from "components/Button/style";
import { Container } from "components/Container";
import { ButtonHeader, TooltipContainer } from "./style";
import { FaFileAlt, FaUndo } from "react-icons/fa";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { formataDataISOParaBR } from "_utils/Data";
import { IDetalhesConsulta } from "../interfaces/IDetalhesConsulta";
import { StatusConsultaLicenciamento } from "components/StatusConsultaLicenciamento";
import { Tooltip } from "components/Tooltip";
import { makeTentativasCRLV } from "features/licenciamento/emissao/pages/Detalhes/containers/ListarDetalhes";

interface Props {
    idConsulta: string | undefined;
    loading: boolean;
    dados: IDetalhesConsulta[];
    onChangePage: (page: number) => void;
    onChangeRowsPerPage: (limit: number) => void;
    totalRows: number;
    rowsPerPage: number;
    setSelectedRows: any;
    onClickConsultar: () => void;
    onClickEmitir: () => void;
    onClickExportar: () => void;
    clearSelectedRows: boolean;
    disabledBotaoConsultar: boolean;
}

export function ListarDetalhesConsulta({
    idConsulta,
    loading = false,
    dados,
    onChangePage,
    onChangeRowsPerPage,
    totalRows,
    rowsPerPage,
    setSelectedRows,
    onClickConsultar,
    onClickEmitir,
    onClickExportar,
    clearSelectedRows,
    disabledBotaoConsultar,
}: Props) {
    const quantidadeCaracteresTextoLongo = 70;
    const widthStrategy = { md: 200, lg: 480 };
    const widthObservacao = dados.length ? `${Math.max(...dados.map((item) =>
        item.observacao && item.observacao.length > quantidadeCaracteresTextoLongo ? widthStrategy.lg : widthStrategy.md
    ))}px` : `${widthStrategy.md}`;

    const columns: IColum<IDetalhesConsulta>[] = [
        {
            name: <span>ID</span>,
            selector: ({ id }: IDetalhesConsulta) => id,
            omit: true,
        },
        {
            name: <span>Placa</span>,
            selector: ({ placa }: IDetalhesConsulta) => placa,
            width: "100px",
        },
        {
            name: <span>Renavam</span>,
            selector: ({ renavam }: IDetalhesConsulta) => renavam,
            width: "100px",
        },
        {
            name: <span>Chassi</span>,
            selector: ({ chassi }: IDetalhesConsulta) => chassi ?? "--",
            width: "175px",
        },
        {
            name: "CPF/CNPJ",
            selector: ({ cpfCnpjProprietario }: IDetalhesConsulta) => cpfCnpjProprietario ?? "--",
            width: "135px",
        },
        {
            name: <span>UF</span>,
            selector: ({ uf }: IDetalhesConsulta) => uf ?? "--",
            width: "60px",
        },
        {
            name: <span>Cód. de Retorno</span>,
            selector: ({ codigoRetorno }: IDetalhesConsulta) => codigoRetorno ?? "--",
            width: widthObservacao,
        },
        {
            name: <span>Exercício</span>,
            selector: ({ ano }: IDetalhesConsulta) => ano ?? "--",
            width: "80px",
        },
        {
            name: <span>Data de emissão</span>,
            selector: ({ dataEmissao }: IDetalhesConsulta) => dataEmissao ? formataDataISOParaBR(dataEmissao) : "--",
            width: "90px",
        },
        {
            name: <span>Observação</span>,
            selector: ({ observacao }: IDetalhesConsulta) => observacao ?? "--",
            width: widthObservacao,
        },
        {
            name: <span>Status da Consulta</span>,
            selector: ({ statusEmissao }: IDetalhesConsulta) => <StatusConsultaLicenciamento statusConsulta={statusEmissao} />,
            width: "175px",
        },
        {
            name: <span>Data de Consulta</span>,
            selector: ({ dataConsulta }: IDetalhesConsulta) => dataConsulta ? formataDataISOParaBR(dataConsulta) : "--",
            width: "90px",
        },
        {
            name: <span>Tentativas de Consulta</span>,
            selector: ({ tentativas, statusEmissao }: IDetalhesConsulta) => makeTentativasCRLV(tentativas, Number(statusEmissao)),
            width: '100px',
        },
        {
            name: <span>Recibo</span>,
            selector: ({ idRecibo }: IDetalhesConsulta) => idRecibo ?? "--",
            width: "90px",
        },
    ];

    const bottomTooltipConsultar = 2.3;
    const rightTooltipConsultar = -190;
    const widthTooltipConsultar = 240;
    return (
        <Container titulo={`Detalhes Consulta de Licenciamentos - ID ${idConsulta}`}>
            <Table
                loading={loading}
                data={dados}
                columns={columns}
                keyField="id"
                paginationRowsPerPageOptions={[10, 20, 50]}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination
                totalRows={totalRows}
                rowsPerPage={rowsPerPage}
                selectableRows
                setRowsSelected={(rows) => setSelectedRows(rows)}
                clearSelectedRows={clearSelectedRows}
                exportExcel={true}
                handleExport={onClickExportar}
                buttonExportVariant="secondary"
                toolsHeader={
                    <ButtonHeader>
                        <Tooltip
                            bottom={bottomTooltipConsultar}
                            right={rightTooltipConsultar}
                            width={widthTooltipConsultar}
                            trigger={<div><PrimaryButton
                                onClick={onClickConsultar}
                                disabled={disabledBotaoConsultar}
                                outline
                                variante="primary"
                            >
                                Consultar <FaUndo />

                            </PrimaryButton></div>}
                            children={
                                <TooltipContainer>
                                    <span>Consultar novamente placa(s) com status de <strong>Falha</strong></span>
                                </TooltipContainer>
                            }
                        />
                        <PrimaryButton onClick={onClickEmitir} disabled={false}>
                            Emitir CRLV <FaFileAlt />
                        </PrimaryButton>
                    </ButtonHeader>
                }
            ></Table>
        </Container>
    );
}

