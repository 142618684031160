import { StatusDebitoPagamento } from "features/debito/pagamento/pages/Detalhes/Enum/StatusDebitoPagamento";
import { Container } from "./style";

interface StatusProps {
  status: number;
}

export function statusAdapter(status: number) {
  if (
    status === StatusDebitoPagamento.Pendente ||
    status === StatusDebitoPagamento.Erro ||
    status === StatusDebitoPagamento.Indeferido ||
    status === StatusDebitoPagamento.Processando
  ) {
    return StatusDebitoPagamento.Pendente;
  }

  if (status === StatusDebitoPagamento.Recusado) {
    return StatusDebitoPagamento.Recusado
  }

  return StatusDebitoPagamento.Pago;
}

export function Status({ status }: StatusProps) {
  const statusAdaptado = statusAdapter(Number(status));

  return (
    <Container statusPagamento={statusAdaptado}>
      {StatusDebitoPagamento[statusAdaptado]}
    </Container>
  );
}
