import { Route, Routes } from "react-router-dom";
import { DetalhesRelatorioConsultas } from "../pages/DetalhesRelatorioConsultas";
import { RelatorioConsultas } from "../pages/RelatorioConsultas";

export function RelatorioConsultaRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RelatorioConsultas />} />
      <Route path="/:id" element={<DetalhesRelatorioConsultas />}/>
    </Routes>
  )
}
