import { Text, View } from "@react-pdf/renderer";
import { StringUtils } from "_utils/String.utils";
import { STYLES } from "../../../../style";
import { IRegistrosRemarketing } from "../../../../../DadosLeiloes/containers/BodyRemarketing";

interface Props {
  remarketing: IRegistrosRemarketing;
}

export function BodyRemarketingPdf({ remarketing }: Readonly<Props>) {
  return (
    <View>
      <View style={[STYLES.SECTION_ROW, STYLES.PADDING_04]}>
        <View style={[STYLES.SECTION_COL_4, { paddingRight: "10px" }]}>
          <Text style={[STYLES.FONT_SIZE_09]}>Organizador</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {remarketing.organizador
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.organizador.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Vendedor</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {remarketing.vendedor
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.vendedor.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Item</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>{remarketing.item ?? "-"}</Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Data evento</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>{remarketing.dataEvento ?? "-"}</Text>
        </View>
      </View>
      <View style={[STYLES.SECTION_ROW, STYLES.PADDING_04]}>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Condições do veículo</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {remarketing.condicoesVeiculo
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.condicoesVeiculo.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Situação do chassi</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {remarketing.situacaoChassi
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.situacaoChassi.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Condições motor</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {remarketing.condicoesMotor
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.condicoesMotor.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Condições câmbio</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {remarketing.condicoesCambio
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.condicoesCambio.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
      </View>
      <View style={[STYLES.SECTION_ROW, STYLES.PADDING_04]}>
        <View style={STYLES.SECTION_COL_4}>
          <Text style={[STYLES.FONT_SIZE_09]}>Condições mecânica</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {remarketing.condicoesMecanica
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.condicoesMecanica.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
        <View>
          <Text style={[STYLES.FONT_SIZE_09]}>Observações</Text>
          <Text style={[STYLES.FONT_SIZE_09]}>
            {remarketing.observacao
              ? StringUtils.primeiraLetraMaiuscula(
                remarketing.observacao.split("_").join(" ")
              )
              : "-"}
          </Text>
        </View>
      </View>
    </View>
  );
}
