export const CodigoRetorno = [
    {
        codigo: "0",
        titulo: "Transação ou atualização efetuada",
        mensagem: ""
    },
    {
        codigo: "17",
        titulo: "Veículo não cadastrado e com ocorrência de roubo/furto",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "18",
        titulo: "Veículo não cadastrado e com sinalização de alarme",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "19",
        titulo: "Veículo cadastrado e com sinalização de alarme {Não criticado se tipo-condicionalidade = 2}",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "21",
        titulo: 'Existe Restrição no estado de “solicitada” ou “recebida” {Não criticado se tipo-condicionalidade = 2}',
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "28",
        titulo: "Veículo deve ser regularizado devido a ostentar nova PIV",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "41",
        titulo: "Veículo não cadastrado",
        mensagem: "Não foi possível localizar o veículo. Verifique os dados inseridos e tente novamente. Se o erro persistir, compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "42",
        titulo: "Veículo baixado",
        mensagem: "Veículo com baixa permanente. Esclarecemos que não será possível o licenciamento. Caso não reconheça a baixa do veículo e ela tenha sido feita equivocadamente, informamos que deverá comparecer a unidade de atendimento do Detran.SP do município onde o veículo está registrado com original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "48",
        titulo: "Veículo cadastrado e com ocorrência de roubo/furto {Não criticado se tipo-condicionalidade = 2}",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "51",
        titulo: "Código da restrição inválido",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "56",
        titulo: "Placa inválida",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "65",
        titulo: "Código RENAVAM inválido",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "95",
        titulo: "Procedência inválida",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "288",
        titulo: "UF origem da transação deve ser a UF de jurisdição do veículo",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "501",
        titulo: "Motivo de emissão de documento inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "502",
        titulo: "Número da via do documento inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "503",
        titulo: "Município de emplacamento inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "504",
        titulo: "Cor do veículo inválida / não informada",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "505",
        titulo: "Ano de fabricação inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "506",
        titulo: "Ano modelo inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "507",
        titulo: "Combustível inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "508",
        titulo: "Tipo de veículo inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "509",
        titulo: "Espécie do veículo inválida / não informada",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "510",
        titulo: "Categoria do veículo inválida / não informada",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "511",
        titulo: "Potência / cilindrada inválida / não informada",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "513",
        titulo: "Tipo de carroceria inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "514",
        titulo: "CMT inválida",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "515",
        titulo: "PBT inválido",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "516",
        titulo: "Capacidade de carga inválida / não informada",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "517",
        titulo: "Capacidade de passageiros inválida / não informada",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "518",
        titulo: "Número de eixos inválido",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "527",
        titulo: "Ano licenciamento inválido",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "528",
        titulo: "Validade do documento inválida",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "529",
        titulo: "Nome do proprietário não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "535",
        titulo: "Tipo de documento do arrendatário inválido",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "536",
        titulo: "Número de identificação do arrendatário inválido",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "543",
        titulo: "Via do documento já emitida para o veículo",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "546",
        titulo: "Dados das 2as vias divergentes da 1a via do documento",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "546",
        titulo: "Veículo com comunicação de venda",
        mensagem: "Veículo com comunicação de venda cadastrada. Esclarecemos que não será possível o licenciamento até a conclusão do serviço de Transferência de veículo, onde o CRLV-e ficará disponível."
    },
    {
        codigo: "579",
        titulo: "Chassi divergente da BIN",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "580",
        titulo: "Marca/Modelo divergente da BIN",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "581",
        titulo: "Tipo de documento do proprietário divergente da BIN",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "582",
        titulo: "Número do documento do proprietário divergente da BIN",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "583",
        titulo: "Município do emplacamento divergente da BIN",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "584",
        titulo: "Ano de fabricação divergente da BIN",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "585",
        titulo: "Tipo de remarcação do chassi divergente da BIN",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "586",
        titulo: "Códigos de restrição divergentes da BIN",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com original do Documento de identificação pessoal e CPF. Clique aqui para agendar o atendimento."
    },
    {
        codigo: "587",
        titulo: "Data limite da restrição tributária divergente da BIN",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "600",
        titulo: "Existência de multa ativa (exigível) no RENAINF {Não será realizada até definição DENATRAN}",
        mensagem: "Não foi possível a emissão do CRLV-e. Caso os débitos e licenciamento do veículo já tenham sido pagos há mais de 3 dias, entre em contato pelo Fale com o Detran.SP ou compareça em uma unidade de atendimento no município de registro do veículo com documento de identificação pessoal original. Clique aqui para agendar o atendimento."
    },
    {
        codigo: "603",
        titulo: "Pendência de emissão de documento",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "617",
        titulo: "Número tipográfico do documento não pertence à faixa de documentos da UF de emissão",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "618",
        titulo: "Número tipográfico do documento consta na Base de Documentos Cancelados",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "650",
        titulo: "Veículo com restrição judicial RENAJUD",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "663",
        titulo: "Espécie coleção exige ano fabricação há mais de 29 anos",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "665",
        titulo: "Indicador de detentor do veículo inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "666",
        titulo: "Indicador de detentor do veículo incompatível com dados informados",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "667",
        titulo: "Logradouro do detentor não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "668",
        titulo: "Município do detentor inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "669",
        titulo: "UF do detentor inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "670",
        titulo: "CEP do detentor não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "671",
        titulo: "Município do detentor diferente do município de emplacamento do veículo.",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "672",
        titulo: "Código RNTRC inválido",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "673",
        titulo: "Placa anterior inválida",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "674",
        titulo: "UF anterior inválida",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "675",
        titulo: "Número espelho inválido / não informado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "676",
        titulo: "Número espelho já cadastrado",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "677",
        titulo: "Origem da solicitação de emissão inválida / não informada",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "678",
        titulo: "Licenciamento Provisório com dados de identificação do proprietário incompatíveis",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "679",
        titulo: "Licenciamento Provisório com dados de identificação do arrendatário incompatíveis",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "680",
        titulo: "Licenciamento não emitido",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "681",
        titulo: "CRLV divergente do CRV ativo (placa)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "682",
        titulo: "CRLV divergente do CRV ativo (chassi)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "683",
        titulo: "CRLV divergente do CRV ativo (RENAVAM)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "684",
        titulo: "CRLV divergente do CRV ativo (tipo documento proprietário )",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "685",
        titulo: "CRLV divergente do CRV ativo (número documento do proprietário)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "686",
        titulo: "CRLV divergente do CRV ativo (nome proprietário) {Não será realizada até definição DENATRAN}",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "687",
        titulo: "CRLV divergente do CRV ativo (tipo documento arrendatário)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "688",
        titulo: "CRLV divergente do CRV ativo (número documento arrendatário)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "689",
        titulo: "CRLV divergente do CRV ativo (nome arrendatário)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "690",
        titulo: "CRLV divergente do CRV ativo (marca/modelo)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "694",
        titulo: "CRLV divergente do CRV ativo (categoria)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "695",
        titulo: "CRLV divergente do CRV ativo (cor)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "696",
        titulo: "CRLV divergente do CRV ativo (ano fabricação)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "697",
        titulo: "CRLV divergente do CRV ativo (ano modelo)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "698",
        titulo: "CRLV divergente do CRV ativo (combustível)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "699",
        titulo: "CRLV divergente do CRV ativo (capacidade carga)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "700",
        titulo: "CRLV divergente do CRV ativo (passageiros)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "701",
        titulo: "CRLV divergente do CRV ativo (potência)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "702",
        titulo: "CRLV divergente do CRV ativo (cilindrada)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "703",
        titulo: "CRLV divergente do CRV ativo (município)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "704",
        titulo: "CRLV divergente do CRV ativo (tipo de veículo)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "707",
        titulo: "Número da via adicional não obedece à seqüência relativa à última via autorizada",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "712",
        titulo: "Tipo de documento do proprietário incompatível com a indicação MRE {Será criticado somente se informada categoria diplomática}",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "713",
        titulo: "Número do Documento de identificação pessoal do proprietário incompatível com a indicação MRE {Será criticado somente se informada categoria diplomática}",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "714",
        titulo: "Categoria incompatível com a indicação MRE {Será criticado somente se informada categoria diplomática}",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "715",
        titulo: "Categoria diplomática não pode ser informada se não houver a indicação MRE",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "716",
        titulo: "Licenciamento provisório não pode ser autorizado para veículo de representação diplomática",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "736",
        titulo: "CRLV divergente do CRV ativo (espécie)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "737",
        titulo: "CRLV divergente do CRV ativo (carroceria)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "738",
        titulo: "CRLV divergente do CRV ativo (procedência)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "739",
        titulo: "CRLV divergente do CRV ativo (CMT)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "740",
        titulo: "CRLV divergente do CRV ativo (PBT)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "741",
        titulo: "CRLV divergente do CRV ativo (número de eixos)",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "745",
        titulo: "Veículo com restrição RFB",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    },
    {
        codigo: "851",
        titulo: "Erro Interno na Restrição RENAJUD",
        mensagem: "Não foi possível a emissão do CRLV-e. Compareça a uma unidade de atendimento do Detran.SP do município de sua residência com o original do Documento de identificação pessoal, CPF e Certificado de Registro de Veículo (CRV). Clique aqui para agendar o atendimento."
    }
];