import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

interface DropdownButtonProps {
  selected: boolean;
  comErro: boolean;
  height?: string;
}

interface DropdownInputProps {
  searchable: boolean;
  maxHeight?: number;
}

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;

  span {
    font-size: 14px;
  }
`;

interface ISelectTitle {
  desativado?: boolean;
}
interface ISpan {
  desativado?: boolean;
}

export const Title = styled.span<ISelectTitle>`
  color: ${({ desativado }) => (desativado ? "#9f9f9f" : "")};
`;

export const Span = styled.span<ISpan>`
  color: ${({ desativado }) => (desativado ? "#9f9f9f" : "")};
`;

export const DropdownContainer = styled(Dropdown)`
  display: grid;
  gap: 0.5rem;
`;

export const DropdownButton = styled.button<DropdownButtonProps>`
  width: 100%;
  height: ${({ height }) => (height ?? "2.2rem")};
  border: 1px solid ${({ theme, comErro }) => (comErro ? theme.colors.danger.main : theme.colors.neutral.neutral20)};
  color: ${({ selected, theme }) => (selected ? theme.colors.neutral.neutral80 : theme.colors.neutral.neutral40)};
  background-color: transparent;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  padding: 0.2rem 1rem;

  svg {
    color: ${({ theme }) => theme.colors.neutral.neutral40};
    width: 24px;
    height: 24px;
    position: absolute;
    right: 4px;
    top: 0.25rem;
  }
`;

export const DropdownMenu = styled.div<DropdownInputProps>`
  width: 100%;
  padding: 0;
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px; overflow: auto;` : "")}

  input {
    margin-top: 0.2rem;
    width: 97%;
    margin-left: 1.5%;
    display: ${({ searchable }) => (searchable ? "block" : "none")};
  }

  a {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }

  a:hover {
    background-color: ${({ theme }) => theme.colors.project.bgColor};
  }
`;

export const SpanMensagemErro = styled.span`
  color: ${({ theme }) => theme.colors.danger.main};
  font-size: 0.85rem !important;
`;
