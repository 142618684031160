import { Route, Routes } from "react-router-dom";
import { AlterarSenha } from "../pages/alterarSenha";

function UsuarioRoutes() {
  return (
    <Routes>
      <Route path="/alterarSenha" element={<AlterarSenha />} />
    </Routes>
  );
}
export { UsuarioRoutes };
