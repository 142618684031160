import { Container, ContainerBody, ContainerDadosCobranca, ContainerDadosCobrancaBody, ContainerDadosCobrancaTitle, ContainerTitle, DadosCobrancaItemBody, DadosCobrancaItemTitle, ItemBody, ItemTitle } from "../../style";
import { Col, Row } from "react-bootstrap";
import { formatarDinheiro } from "_utils/Money";
import { converterDataBr } from "_utils/Data";
import { IDadosClienteReciboAdiantamento } from "../../../ListarDetalhes/interface/IDadosClienteReciboAdiantamento";
import { useMask } from "hooks/useMask";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";

interface Props {
    formaPgto: FormaPgtoEnum | undefined;
    valorTotal: number;
    idReciboAdiantamento: number;
    dadosClienteReciboAdiantamento: IDadosClienteReciboAdiantamento;
}
export function DadosRecibo({
    formaPgto,
    idReciboAdiantamento,
    valorTotal,
    dadosClienteReciboAdiantamento,
}: Props) {
    const { mCNPJ, mCEP } = useMask();
    return (
        <Container marginTop={formaPgto === FormaPgtoEnum.TRANSFERENCIA ? "16px" : "32px"}>
            <ContainerTitle>
                Dados do recibo
            </ContainerTitle>
            <ContainerBody>
                <Row>
                    <Col>
                        <ItemTitle>Recibo</ItemTitle>
                        <ItemBody>{idReciboAdiantamento}</ItemBody>
                    </Col>
                    <Col>
                        <ItemTitle>Data de emissão</ItemTitle>
                        <ItemBody>{converterDataBr(new Date())}</ItemBody>
                    </Col>
                    <Col>
                        <ItemTitle>Valor</ItemTitle>
                        <ItemBody>{formatarDinheiro(valorTotal / 100)}</ItemBody>
                    </Col>
                </Row>
                <ContainerDadosCobranca>
                    <ContainerDadosCobrancaTitle>Dados para cobrança</ContainerDadosCobrancaTitle>
                    <ContainerDadosCobrancaBody>
                        <Row>
                            <Col>
                                <DadosCobrancaItemTitle>{dadosClienteReciboAdiantamento.razaoSocial}</DadosCobrancaItemTitle>
                                <DadosCobrancaItemBody>
                                    {dadosClienteReciboAdiantamento.cnpj}<br />{`${dadosClienteReciboAdiantamento.endereco}, ${dadosClienteReciboAdiantamento.numero} - ${dadosClienteReciboAdiantamento.bairro} - ${dadosClienteReciboAdiantamento.cidade} - ${dadosClienteReciboAdiantamento.estado} - ${mCEP(dadosClienteReciboAdiantamento.cep)}`}
                                </DadosCobrancaItemBody>
                            </Col>
                        </Row>
                    </ContainerDadosCobrancaBody>
                </ContainerDadosCobranca>
            </ContainerBody>
        </Container>
    );
}