import styled from "styled-components";

export const ButtonContainer = styled.div`
  width: 20%;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: end;

  svg {
    margin-left: 0.25rem;
  }
`;