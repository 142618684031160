import styled from "styled-components";
import backgroundImage from "../../../../_assets/images/background_debitos_login_lw_5.jpg";
import logoDebitos from "../../../../_assets/images/logo_debitos_lw_verde.png";
import logoGrupoLw from "../../../../_assets/images/logo_branca_grupo_lw.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 128px 64px 5%;
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${backgroundImage});
  background-size: 54% 100vh;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position: right;
`;

export const LoginBoxForm = styled.div`
  width: 28%;
`;

export const LoginContainer = styled.div`
  font-size: 1rem;
`;

export const ImgLogoDebitos = styled.img.attrs({
    src: logoDebitos,
})`
  height: 40px;
  width: 169.43px;
  margin-bottom: 48px;
`;

export const ImgLogoGrupoLw = styled.img.attrs({
    src: logoGrupoLw,
})`
  position: absolute;
  bottom: 64px;
  right: 80px;
`;