import { Header } from "components/Header";
import { toastErro } from "components/Toast";
import { IColum } from "containers/Table/interfaces";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verificarPermissao } from "_services/auth";
import { converterDataHoraBr, formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { useListar } from "../../hooks/useListar";
import { ISolicitacaoPagamento } from "../../interfaces/ISolicitacaoPagamento";
import { FiltrarSolicitacaoPagamento } from "./containers/FiltrosSolicitacaoPagamento";
import { IFiltrosSolicitacaoPagamento } from "./containers/FiltrosSolicitacaoPagamento/interfaces/IFiltrosSolicitacaoPagamento";
import { ListarSolicitacoes } from "./containers/ListarSolicitacoes";
import { makeStatusAdiantamento } from "./patterns/factories/makeStatusAdiantamento";
import { MakeAcoes } from "./patterns/factories/makeAcoes";
import { makeStatusSolicitacao } from "./patterns/factories/makeStatusSolicitacao";

export function Listar() {
	const {
		listarSolicitacoesPagamento,
		solicitacoesPagamento,
		loading,
		setFiltros,
		handleTableLimitChange,
		handleTablePageChange,
		page,
		filtros,
		limit,
		errorListarSolicitacoes,
		totalRows,
	} = useListar();

	const navigate = useNavigate();

	useEffect(() => {
		const permissaoFinanceiro = verificarPermissao("pagamento");
		if (!permissaoFinanceiro) {
			navigate("/401");
		}
	}, [navigate]);

	function filtrarSolicitacoesPagamento(data: IFiltrosSolicitacaoPagamento) {
		setFiltros(data);
	}

	useEffect(() => {
		if (errorListarSolicitacoes?.hasErro && Number(errorListarSolicitacoes.data.status) !== 404) {
			toastErro(errorListarSolicitacoes.data.erro);
		}
	}, [errorListarSolicitacoes]);

	useEffect(() => {
		listarSolicitacoesPagamento();
	}, [page, limit, filtros, listarSolicitacoesPagamento]);

	const columns: IColum<ISolicitacaoPagamento>[] = [
		{
			name: <span>ID Consulta</span>,
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) => solicitacaoPagamento.idConsulta,
			width: "90px",
		},
		{
			name: <span>ID Pagamento</span>,
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) => solicitacaoPagamento.id,
			width: "100px",
		},
		{
			name: "Data / Hora",
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) => converterDataHoraBr(solicitacaoPagamento.dataHora),
			width: "150px",
		},
		{
			name: "Empresa",
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) => solicitacaoPagamento.empresa,
		},
		{
			name: "Usuário",
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) => solicitacaoPagamento.nomeUsuario,
		},
		{
			name: <span>Pagamentos Solicitados</span>,
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) => solicitacaoPagamento.qtdSolicitado,
		},
		{
			name: "Valor",
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) =>
				formatarDinheiro(solicitacaoPagamento.valorTotal / 100),
		},
		{
			name: "Juros",
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) =>
				formatarDinheiro(solicitacaoPagamento.valorTotalJuros / 100 || 0),
		},
		{
			name: "Valor Total",
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) =>
				formatarDinheiro((solicitacaoPagamento.valorTotal + (solicitacaoPagamento.valorTotalJuros ?? 0)) / 100),
		},
		{
			name: <span>Prazo para Pagamento</span>,
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) =>
				formataDataISOParaBR(solicitacaoPagamento.dataMenorPrazoPagamento),
		},
		{
			name: <span>Pagamentos Realizados</span>,
			selector: ({ qtdFinalizado }: ISolicitacaoPagamento) => qtdFinalizado,
		},
		{
			name: <span>Pagamentos Não Realizados</span>,
			selector: ({ qtdFinalizado, qtdSolicitado }: ISolicitacaoPagamento) => qtdSolicitado - qtdFinalizado,
		},
		{
			name: <span>Status Pgto</span>,
			selector: (solicitacaoPagamento: ISolicitacaoPagamento) => makeStatusSolicitacao(solicitacaoPagamento.statusPgto),
			width: "120px",
		},
		{
			name: "Adiantamento",
			selector: ({ id, statusAdiantamento }: ISolicitacaoPagamento) =>
				makeStatusAdiantamento({
					idSolicitacaoPagamento: id,
					statusAdiantamento,
					listarSolicitacoesPagamento,
				}),
			width: "220px",
		},
		{
			name: "Ações",
			selector: ({ id, comprovantes}: ISolicitacaoPagamento) => MakeAcoes({ id, comprovantes }),
			width: "80px",
		},
	];

	return (
		<div>
			<Header title="Pagamento de débitos" />
			<FiltrarSolicitacaoPagamento
				listarSolicitacoesPagamento={listarSolicitacoesPagamento}
				handleSubmitFilters={filtrarSolicitacoesPagamento}
			/>
			<ListarSolicitacoes
				dados={solicitacoesPagamento}
				onChangePage={handleTablePageChange}
				onChangeRowsPerPage={handleTableLimitChange}
				loading={loading}
				columns={columns}
				rowsPerPage={limit}
				totalRows={totalRows}
			/>
		</div>
	);
}
