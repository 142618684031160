import { CompositeContainer } from "components/CompositeContainer";
import { FaFile } from "react-icons/fa";
import {
  FormContainer,
  IFiltrosListarSolicitacoesLicenciamento,
} from "./containers/FormContainer";
import { Data } from "_utils/Data";
import { ModalEmitirLicenciamento } from "../ModalEmitirLicenciamento";
import { useState } from "react";
import { verificarPermissao } from "_services/auth";

interface Props {
  handleSubmit: (dados: IFiltrosListarSolicitacoesLicenciamento) => void;
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export function FiltrosListarSolictacoesLicenciamento({ handleSubmit, setLoadingPage }: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);

  function handleSubmitFiltros(dados: any) {
    if (dados.dataSolicitacao) {
      const data = new Data(dados.dataSolicitacao);
      dados.dataSolicitacao = data.getDataEua();
    }

    handleSubmit(dados);
  }

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <>
      <CompositeContainer.Root>
        <CompositeContainer.Header>
          <CompositeContainer.Titulo text="Filtros" />
          {verificarPermissao("emitirLicenciamento") && (
            <CompositeContainer.ActionButton
              iconeBotao={FaFile}
              text="Emitir Licenciamentos"
              onClick={handleOpenModal}
            />
          )}
        </CompositeContainer.Header>
        <CompositeContainer.Body>
          <FormContainer handleSubmitFiltros={handleSubmitFiltros} />
        </CompositeContainer.Body>
      </CompositeContainer.Root>
      <ModalEmitirLicenciamento
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        listarSolicitacoesLicenciamento={async () => { 
          handleSubmitFiltros({})
        }}
        setLoadingPage={setLoadingPage}
      />
    </>
  );
}

