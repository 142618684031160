import { TooltipWrapper, TooltipContent, TooltipTrigger } from "./style";
import { ReactNode } from "react";

interface ITooltip {
  trigger: ReactNode;
  children: ReactNode;
  bottom?: number;
  right?: number;
  width?: number;
  positionRelative?: boolean,
  marginLeft?: number,
}

export function Tooltip({
  children,
  trigger,
  bottom = 0.3,
  right = -1500,
  width = 350,
  positionRelative = true,
  marginLeft
}: ITooltip) {
  return (
    <TooltipWrapper
      positionRelative={positionRelative}
    >
      <TooltipTrigger>{trigger}</TooltipTrigger>
      <TooltipContent
        bottom={bottom}
        right={right}
        width={width}
        marginLeft={marginLeft}
      >{children}</TooltipContent>
    </TooltipWrapper>
  );
}

