import { IOptions } from "components/DropdownSelect";
import { SelectComboConsultas } from "../SelectComboConsultas";
import { Control, ControllerProps } from "react-hook-form";
import FileUpload from "components/UploadArquivos";
import { InputsLoteContainer } from "./style";
import { Required } from "components/Required";
import { ElementType } from "react";
import { Col } from "react-bootstrap";

export interface BodyModalExtratoVeicularProps {
  combosConsulta: IOptions[];
  controller: ElementType<ControllerProps>;
  control: Control<any>;
  errors: any;
  isSubmitted: boolean;
}

export function BodyLote({
  combosConsulta,
  control,
  controller: Controller,
  errors,
}: Readonly<BodyModalExtratoVeicularProps>) {
  return (
    <div>
      <InputsLoteContainer>
        <Col lg="3" md="3" sm="3">
          <SelectComboConsultas controller={Controller} control={control} errors={errors} options={combosConsulta} disabled={!combosConsulta.length} />
        </Col>
        <Col lg="9" md="9" sm="9">
          <div className="arquivoContainer">
            <span>
              Arquivo <Required />
            </span>
            <Controller
              control={control}
              name="arquivo"
              render={({ field: { onChange } }) => (
                <FileUpload maxWidth="90%" onFileUpload={(file) => onChange(file)} mensagemErro={errors?.arquivo?.message as string} />
              )}
            />
          </div>
        </Col>
      </InputsLoteContainer>
    </div>
  );
}

