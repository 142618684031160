import { Text, View } from "@react-pdf/renderer";
import { IDadosRestricoes } from "../../../DadosRestricoes";
import { STYLES } from "../../style";
import { RowRestricoesPDF } from "./containers/RowRestricoesPDF";

interface Props {
  restricoes: IDadosRestricoes[];
}

export function DadosRestricoesPDF({ restricoes }: Readonly<Props>) {
  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Restrições</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          {restricoes.length > 0 ? (
            <View>
              <View style={STYLES.SECTION_ROW}>
                <View style={STYLES.SECTION_COL_2}>
                  <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Descrição</Text>
                </View>
                <View style={STYLES.SECTION_COL_2}>
                  <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Quantidade</Text>
                </View>
              </View>
              <RowRestricoesPDF restricoes={restricoes} />
            </View>
          ) : (
            <View>
              <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>Restrições</Text>
              <Text style={STYLES.FONT_SIZE_08}>Nada consta</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

