import { useCallback, useState } from "react";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
    IData,
    IHttpError,
    IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { converterDataBr, converterDataEua } from "_utils/Data";
import { HttpStatusCode } from "axios";
import { toastErro } from "components/Toast";
import { IFiltros } from "../interfaces/IFiltros";
import { IDadosResumoRelatorioExtratoVeicular, IIntervaloDatas } from "../interfaces/IDadosResumoRelatorioExtratoVeicular";
import { useRequest } from "hooks/useRequest";

export function useRelatorioExtratoVeicular() {
    const { get } = useRequest("consulta")
    const [dados, setDados] = useState<any[]>([]);
    const [loadingDados, setLoadingDados] = useState<boolean>(false);
    const [loadingDadosPlanilha, setLoadingDadosPlanilha] = useState<boolean>(false);
    const [error, setError] = useState<IHttpResponse<IData | any>>();
    const [queryParams, setQueryParams] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [telaCarregada, setTelaCarregada] = useState<boolean>(false);
    const resumoExtratoVeicularInicial = {
        totalConsultas: 0,
        valor: 0,
        idsRecibo: [],
        dataInicio: null,
        dataFim: null,
    };
    const [resumoExtratoVeicular, setResumoExtratoVeicular] = useState<IDadosResumoRelatorioExtratoVeicular>(resumoExtratoVeicularInicial);
    const [intervaloDatas, setIntervaloDatas] = useState<IIntervaloDatas>({ dataInicial: null, dataFinal: null });
    const [forceUpdate, setForceUpdate] = useState(false);

    function criarQueryParamsRelatorio(data: IFiltros) {
        const placa = data.placa ? `placa=${data.placa}` : null;
        const dataInicial = data.periodoConsulta && data.periodoConsulta[0] ? `dataInicial=${converterDataEua(data.periodoConsulta[0])}` : null;
        const dataFinal = data.periodoConsulta && data.periodoConsulta[1]
            ? `dataFinal=${converterDataEua(data.periodoConsulta[1])}`
            : data.periodoConsulta && data.periodoConsulta[0]
                ? `dataFinal=${converterDataEua(data.periodoConsulta[0])}`
                : null;
        const idConsulta = data.idConsulta ? `idConsulta=${data.idConsulta}` : null;
        const tipoConsulta = data.tipoConsulta ? `tipoConsulta=${data.tipoConsulta}` : null;
        const idRecibo = data.idRecibo ? `idRecibo=${data.idRecibo}` : null;
        const chassi = data.chassi ? `chassi=${data.chassi}` : null;
        const renavam = data.renavam ? `renavam=${data.renavam}` : null;
        const idEmpresa = data.idEmpresa ? `idEmpresa=${data.idEmpresa}` : null;
        const cobranca = data.cobranca ? `cobranca=${data.cobranca}` : null;

        const params = [placa, dataInicial, dataFinal, tipoConsulta, idConsulta, idRecibo, chassi, renavam, idEmpresa, cobranca]
            .filter((item) => item !== null)
            .join("&");
        setQueryParams(params);
        setForceUpdate(prevState => !prevState);
        setIntervaloDatas({
            dataInicial: data.periodoConsulta && data.periodoConsulta[0] ? converterDataBr(data.periodoConsulta[0]) : null,
            dataFinal: data.periodoConsulta && data.periodoConsulta[1] ? converterDataBr(data.periodoConsulta[1]) : null,
        });

        if (!params && telaCarregada) {
            toastErro("Selecione pelo menos um filtro")
        }
    }

    const buscarDados = useCallback(async () => {
        setLoadingDados(true);
        try {
            if (!telaCarregada) {
                setTelaCarregada(true)
                return;
            }
            if (!queryParams) {
                setDados([]);
                return;
            }

            const { status, data } = await get(
                `/veiculo/extrato/relatorio?${queryParams}&page=${page}&limit=${limit}`
            );

            setDados(data.items);
            setTotalRows(data.meta.totalItems);
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            if (error.status !== HttpStatusCode.NotFound) {
                setError(response);
            }
            setDados([]);
            setTotalRows(0);
            return response;
        } finally {
            setLoadingDados(false);
        }
    }, [limit, page, queryParams]);

    async function buscarDadosPlanilha() {
        setLoadingDadosPlanilha(true);
        try {
            const { status, data } = await get(
                `/veiculo/extrato/relatorio?${queryParams}&paginate=0`
            );

            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            if (error.status !== HttpStatusCode.NotFound) {
                setError(response);
            }
            return response;
        } finally {
            setLoadingDadosPlanilha(false);
        }
    }

    const gerarDadosResumo = useCallback(async () => {
        try {
            if (!queryParams) {
                setResumoExtratoVeicular(resumoExtratoVeicularInicial);
                return;
            }

            const { status, data } = await get(
                `/veiculo/extrato/relatorio/resumo?${queryParams}`
            );

            setResumoExtratoVeicular({
                totalConsultas: data.qtdConsultas ?? 0,
                valor: data.valorTotal ?? 0,
                idsRecibo: data.idsRecibo ? data.idsRecibo.split(",") : [],
                dataInicio: data.dataInicio ? converterDataBr(data.dataInicio) : null,
                dataFim: data.dataFim ? converterDataBr(data.dataFim) : null,
            });
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            if (error.status !== HttpStatusCode.NotFound) {
                setError(response);
            }
            setResumoExtratoVeicular(resumoExtratoVeicularInicial);
            return response;
        }
    }, [limit, page, queryParams]);

    return {
        buscarDados,
        dados,
        error,
        loadingDados,
        criarQueryParamsRelatorio,
        setPage,
        page,
        setLimit,
        limit,
        queryParams,
        buscarDadosPlanilha,
        loadingDadosPlanilha,
        totalRows,
        gerarDadosResumo,
        resumoExtratoVeicular,
        intervaloDatas,
        forceUpdate,
    };
}
