import { Text, View } from "@react-pdf/renderer";
import {
  IRatingSeguridade,
  RatingSeguridade,
} from "../../../../../DadosLeiloes/containers/BodyRatingSeguridade";
import { STYLES } from "../../../../style";

interface Props {
  ratingSeguridade: IRatingSeguridade;
}

export function BodyRatingSeguridadePdf({ ratingSeguridade }: Readonly<Props>) {
  return (
    <View style={{ marginTop: "5px" }}>
      <View
        style={[
          STYLES.PADDING_04,
          {
            gap: "5px",
            backgroundColor: "#FFFFFF",
            borderBottom: "0.5px solid black",
          },
        ]}
      >
        <Text style={[STYLES.FONT_SIZE_09, STYLES.TEXTO_QUASE_CLARO]}>
          {RatingSeguridade[ratingSeguridade.tipo]}
        </Text>
        <Text style={STYLES.FONT_SIZE_09}>
          Indicador de probabilidade:{" "}
          {ratingSeguridade.indicacaoProbabilidade ?? "-"}
        </Text>
        <Text style={STYLES.FONT_SIZE_09}>
          Descrição: {ratingSeguridade.descricao ?? "-"}
        </Text>
      </View>
    </View>
  );
}
